import ContactUs from '@/components/info/contactUs'
import ButtonIcon from '@/components/form/buttonIcon'

import CloseIcon from '@/components/icons/close'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'RefoundPolicy',

  components: {
    ContactUs,
    ButtonIcon,

    CloseIcon
  },

  methods: {
    close: function() {
      EventBus.$emit('hideRefoundPolicy');
    }
  },

  mounted() {
    window.scrollTo(0,0);
  }
}
