import CloseIcon from '@/components/icons/close'
import RefreshIcon from '@/components/icons/refresh'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Repeat',

  components: {
    CloseIcon,
    RefreshIcon,

    Button,
    ButtonIcon
  },

  data() {
    return {

    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hidePreviewRepeat');
    },

    restart: function() {
      this.$emit('restart');

      this.close();
    }
  }
}
