import PlusIcon from '@/components/icons/plus'
import EyeIcon from '@/components/icons/eye'
import EyeOffIcon from '@/components/icons/eyeOff'
import TrashIcon from '@/components/icons/trash'
import PencilIcon from '@/components/icons/pencil'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'TextPicker',

  components: {
    PlusIcon,
    EyeIcon,
    EyeOffIcon,
    TrashIcon,
    PencilIcon,

    Button,
    ButtonIcon
  },

  props: {
    items: {
      type: Array,
      default: []
    },

    limit: {
      type: Number,
      required: true
    }
  },

  computed: {
    isLimit: function() {
      return (this.items.length < this.limit)
        ? false
        : true;
    }
  },

  methods: {
    showTextFont: function() {
      EventBus.$emit('showTextFont');
    },

    editText: function(key) {
      this.$emit('edit', key);

      this.$forceUpdate();
    },

    deleteText: function(key) {
      this.$emit('delete', key);

      this.$forceUpdate();
    },

    selectText: function(key) {
      this.$emit('select', key);

      this.$forceUpdate();
    },

    switchVisibility: function(key) {
      this.$emit('switchVisibility', key);

      this.$forceUpdate();
    }
  }
}
