module.exports = [
  {x: 140.0000, y: 12.0000},
  {x: 132.0008, y: 14.3331},
  {x: 123.9992, y: 16.6669},
  {x: 116.0000, y: 19.0000},
  {x: 101.0015, y: 19.9999},
  {x: 85.9985, y: 21.0001},
  {x: 71.0000, y: 22.0000},
  {x: 71.0000, y: 22.3333},
  {x: 71.0000, y: 22.6667},
  {x: 71.0000, y: 23.0000},
  {x: 67.6670, y: 23.0000},
  {x: 64.3330, y: 23.0000},
  {x: 61.0000, y: 23.0000},
  {x: 61.0000, y: 23.3333},
  {x: 61.0000, y: 23.6667},
  {x: 61.0000, y: 24.0000},
  {x: 58.3336, y: 24.0000},
  {x: 55.6664, y: 24.0000},
  {x: 53.0000, y: 24.0000},
  {x: 53.0000, y: 24.3333},
  {x: 53.0000, y: 24.6667},
  {x: 53.0000, y: 25.0000},
  {x: 49.6670, y: 25.0000},
  {x: 46.3330, y: 25.0000},
  {x: 43.0000, y: 25.0000},
  {x: 43.0000, y: 25.3333},
  {x: 43.0000, y: 25.6667},
  {x: 43.0000, y: 26.0000},
  {x: 39.0004, y: 26.0000},
  {x: 34.9996, y: 26.0000},
  {x: 31.0000, y: 26.0000},
  {x: 22.2040, y: 28.5081},
  {x: 10.7565, y: 28.8556},
  {x: 0.0000, y: 29.0000},
  {x: 0.7233, y: 31.7623},
  {x: 0.2791, y: 31.2371},
  {x: 3.0000, y: 32.0000},
  {x: 5.6587, y: 34.9613},
  {x: 8.7850, y: 33.6527},
  {x: 13.0000, y: 35.0000},
  {x: 13.0000, y: 35.3333},
  {x: 13.0000, y: 35.6667},
  {x: 13.0000, y: 36.0000},
  {x: 14.9998, y: 36.0000},
  {x: 17.0002, y: 36.0000},
  {x: 19.0000, y: 36.0000},
  {x: 19.0000, y: 36.3333},
  {x: 19.0000, y: 36.6667},
  {x: 19.0000, y: 37.0000},
  {x: 20.9998, y: 37.0000},
  {x: 23.0002, y: 37.0000},
  {x: 25.0000, y: 37.0000},
  {x: 25.0000, y: 37.3333},
  {x: 25.0000, y: 37.6667},
  {x: 25.0000, y: 38.0000},
  {x: 26.9998, y: 38.0000},
  {x: 29.0002, y: 38.0000},
  {x: 31.0000, y: 38.0000},
  {x: 31.0000, y: 38.3333},
  {x: 31.0000, y: 38.6667},
  {x: 31.0000, y: 39.0000},
  {x: 32.9998, y: 39.0000},
  {x: 35.0002, y: 39.0000},
  {x: 37.0000, y: 39.0000},
  {x: 37.0000, y: 39.3333},
  {x: 37.0000, y: 39.6667},
  {x: 37.0000, y: 40.0000},
  {x: 38.9998, y: 40.0000},
  {x: 41.0002, y: 40.0000},
  {x: 43.0000, y: 40.0000},
  {x: 43.0000, y: 40.3333},
  {x: 43.0000, y: 40.6667},
  {x: 43.0000, y: 41.0000},
  {x: 45.3331, y: 41.0000},
  {x: 47.6669, y: 41.0000},
  {x: 50.0000, y: 41.0000},
  {x: 50.0000, y: 41.3333},
  {x: 50.0000, y: 41.6667},
  {x: 50.0000, y: 42.0000},
  {x: 52.9997, y: 42.0000},
  {x: 56.0003, y: 42.0000},
  {x: 59.0000, y: 42.0000},
  {x: 59.0000, y: 42.3333},
  {x: 59.0000, y: 42.6667},
  {x: 59.0000, y: 43.0000},
  {x: 61.9997, y: 43.0000},
  {x: 65.0003, y: 43.0000},
  {x: 68.0000, y: 43.0000},
  {x: 68.0000, y: 43.3333},
  {x: 68.0000, y: 43.6667},
  {x: 68.0000, y: 44.0000},
  {x: 73.3328, y: 44.3333},
  {x: 78.6672, y: 44.6667},
  {x: 84.0000, y: 45.0000},
  {x: 87.6663, y: 45.0000},
  {x: 91.3337, y: 45.0000},
  {x: 95.0000, y: 45.0000},
  {x: 102.3274, y: 47.0308},
  {x: 129.6838, y: 47.0538},
  {x: 137.0000, y: 45.0000},
  {x: 141.6662, y: 45.0000},
  {x: 146.3338, y: 45.0000},
  {x: 151.0000, y: 45.0000},
  {x: 151.0000, y: 44.6667},
  {x: 151.0000, y: 44.3333},
  {x: 151.0000, y: 44.0000},
  {x: 154.6663, y: 44.0000},
  {x: 158.3337, y: 44.0000},
  {x: 162.0000, y: 44.0000},
  {x: 162.0000, y: 43.6667},
  {x: 162.0000, y: 43.3333},
  {x: 162.0000, y: 43.0000},
  {x: 164.3331, y: 43.0000},
  {x: 166.6669, y: 43.0000},
  {x: 169.0000, y: 43.0000},
  {x: 177.0342, y: 40.6597},
  {x: 191.8558, y: 38.7087},
  {x: 194.0000, y: 31.0000},
  {x: 199.3551, y: 25.7602},
  {x: 196.4424, y: 7.4313},
  {x: 195.0000, y: 0.0000},
  {x: 194.6667, y: 0.0000},
  {x: 194.3333, y: 0.0000},
  {x: 194.0000, y: 0.0000},
  {x: 191.1275, y: 3.3636},
  {x: 186.1355, y: 3.8286},
  {x: 182.0000, y: 6.0000},
  {x: 182.0000, y: 6.3333},
  {x: 182.0000, y: 6.6667},
  {x: 182.0000, y: 7.0000},
  {x: 181.3334, y: 7.0000},
  {x: 180.6666, y: 7.0000},
  {x: 180.0000, y: 7.0000},
  {x: 180.0000, y: 7.3333},
  {x: 180.0000, y: 7.6667},
  {x: 180.0000, y: 8.0000},
  {x: 179.0001, y: 8.0000},
  {x: 177.9999, y: 8.0000},
  {x: 177.0000, y: 8.0000},
  {x: 166.8080, y: 12.4842},
  {x: 156.2255, y: 18.4593},
  {x: 142.0000, y: 16.0000},
  {x: 141.0555, y: 14.1988},
  {x: 140.6153, y: 14.4255},
  {x: 140.0000, y: 12.0000}
];
