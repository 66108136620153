import ButtonIcon from '@/components/form/buttonIcon'
import AreaDropdown from '@/components/sneakerCustom/preview/sidebar/areaDropdown'
import ColorPicker from '@/components/sneakerCustom/preview/sidebar/colorPicker'
import ImagePicker from '@/components/sneakerCustom/preview/sidebar/imagePicker'
import TextPicker from '@/components/sneakerCustom/preview/sidebar/textPicker'

import ColorIcon from '@/components/icons/color'
import PictureIcon from '@/components/icons/picture'
import FontIcon from '@/components/icons/font'
import CheckCircleIcon from '@/components/icons/checkCircle'

export default {
  name: 'SidebarCustom',

  components: {
    CheckCircleIcon,
    AreaDropdown,
    ColorPicker,
    ImagePicker,
    TextPicker,

    ButtonIcon,
    ColorIcon,
    PictureIcon,
    FontIcon
  },

  props: {
    side: {
      type: String,
      required: true,
      validator: (val) => [
        'inner',
        'outer'
      ]
    },

    sideData: {
      type: Object,
      required: true
    },

    joinOptions: {
      type: Object,
      required: true
    },

    singleOptions: {
      type: Object,
      required: true
    },

    application: {
      type: String,
      required: true,
      validator: (val) => [
        'full',
        'areas'
      ]
    },

    area: {
      type: String,
      required: true,
      validator: (val) => [
        'vamp',
        'tip',
        'quarter',
        'foxing',
        'backtab',
        'eyestay',
        'swoosh'
      ]
    },

    color: {
      type: Object,
      default: null
    },

    imageItems: {
      type: Array,
      default: []
    },

    imageLimit: {
      type: Number,
      default: null
    },

    textItems: {
      type: Array,
      default: []
    },

    textLimit: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      isColor: true,
      isImage: false,
      isText: false,

      comments: ''
    }
  },

  methods: {
    showColorField: function() {
      this.isColor = true;

      this.isImage = false;
      this.isText = false;
    },

    showImageField: function() {
      this.isImage = true;

      this.isColor = false;
      this.isText = false;
    },

    showTextField: function() {
      this.isText = true;

      this.isColor = false;
      this.isImage = false;
    },

    changeSide: function(side) {
      this.$emit('changeSide', side);
    },

    changeArea: function(area) {
      this.$emit('changeArea', area);
    },

    restartArea: function(area) {
      this.$emit('restartArea', area);
    },

    switchVisibilityArea: function(area) {
      this.$emit('switchVisibilityArea', area);
    },

    // Color
    changeColor: function(color) {
      this.$emit('changeColor', color);
    },

    // Image
    selectImage: function(key) {
      this.$emit('selectImage', key);
    },

    deleteImage: function(key) {
      this.$emit('deleteImage', key);
    },

    switchImageVisibility: function(key) {
      this.$emit('switchImageVisibility', key);
    },

    // Text
    editText: function(key) {
      this.$emit('editText', key);
    },

    selectText: function(key) {
      this.$emit('selectText', key);
    },

    deleteText: function(key) {
      this.$emit('deleteText', key);
    },

    switchTextVisibility: function(key) {
      this.$emit('switchTextVisibility', key);
    }
  },

  watch: {
    isColor: function(val) {
      if (val) {
        this.$emit('changeProperty', 'color');
      }
    },

    isImage: function(val) {
      if (val) {
        this.$emit('changeProperty', 'image');
      }
    },

    isText: function(val) {
      if (val) {
        this.$emit('changeProperty', 'text');
      }
    },

    comments: function(val) {
      this.$emit('changeComments', val);
    }
  }
}
