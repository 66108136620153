import DownloadIcon from '@/components/icons/download'
import CheckIcon from '@/components/icons/check'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'ImagePicker',

  mixins: [Mixins],

  components: {
    DownloadIcon,
    CheckIcon,

    Button,
    ButtonIcon
  },

  props: {
    items: {
      type: Array,
      default: []
    },

    limit: {
      type: Number,
      required: true
    }
  },

  computed: {
    isLimit: function() {
      return (this.items.length < this.limit)
        ? false
        : true;
    }
  },

  methods: {
    showVerifyNft: function() {
      EventBus.$emit('showVerifyNft');
    },

    selectImage: function(key) {
      this.$emit('select', key);
    },

    switchVisibility: function(key) {
      this.$emit('switchVisibility', key);
    }
  },

  watch: {
    items: function() {
      setTimeout(() => this.bgImg(), 50);
    }
  }
}
