export default {
  props: ['value'],

  data () {
    return {
      localValue: this.value
    }
  },

  watch: {
    localValue: function(value) {
      this.$emit('input', value);
    },

    value: function(value) {
      this.localValue = value;
    }
  }
}
