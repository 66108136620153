module.exports = [
  {x: 249.0000, y: 139.0000},
  {x: 248.6667, y: 138.0001},
  {x: 248.3333, y: 136.9999},
  {x: 248.0000, y: 136.0000},
  {x: 247.6667, y: 136.0000},
  {x: 247.3333, y: 136.0000},
  {x: 247.0000, y: 136.0000},
  {x: 247.0000, y: 135.0001},
  {x: 247.0000, y: 133.9999},
  {x: 247.0000, y: 133.0000},
  {x: 246.6667, y: 133.0000},
  {x: 246.3333, y: 133.0000},
  {x: 246.0000, y: 133.0000},
  {x: 246.3333, y: 130.6669},
  {x: 246.6667, y: 128.3331},
  {x: 247.0000, y: 126.0000},
  {x: 244.3336, y: 124.0002},
  {x: 241.6664, y: 121.9998},
  {x: 239.0000, y: 120.0000},
  {x: 232.6523, y: 121.5648},
  {x: 228.8432, y: 125.1151},
  {x: 222.0000, y: 121.0000},
  {x: 219.2645, y: 119.3550},
  {x: 220.0920, y: 121.8717},
  {x: 219.0000, y: 118.0000},
  {x: 218.6667, y: 118.0000},
  {x: 218.3333, y: 118.0000},
  {x: 218.0000, y: 118.0000},
  {x: 218.0000, y: 115.3336},
  {x: 218.0000, y: 112.6664},
  {x: 218.0000, y: 110.0000},
  {x: 220.1838, y: 108.7998},
  {x: 221.2943, y: 107.7959},
  {x: 222.0000, y: 105.0000},
  {x: 220.3335, y: 104.6667},
  {x: 218.6665, y: 104.3333},
  {x: 217.0000, y: 104.0000},
  {x: 217.0000, y: 103.6667},
  {x: 217.0000, y: 103.3333},
  {x: 217.0000, y: 103.0000},
  {x: 216.3334, y: 103.0000},
  {x: 215.6666, y: 103.0000},
  {x: 215.0000, y: 103.0000},
  {x: 215.0000, y: 102.6667},
  {x: 215.0000, y: 102.3333},
  {x: 215.0000, y: 102.0000},
  {x: 214.6667, y: 102.0000},
  {x: 214.3333, y: 102.0000},
  {x: 214.0000, y: 102.0000},
  {x: 214.0000, y: 102.3333},
  {x: 214.0000, y: 102.6667},
  {x: 214.0000, y: 103.0000},
  {x: 212.3335, y: 103.3333},
  {x: 210.6665, y: 103.6667},
  {x: 209.0000, y: 104.0000},
  {x: 208.6667, y: 104.6666},
  {x: 208.3333, y: 105.3334},
  {x: 208.0000, y: 106.0000},
  {x: 207.3334, y: 106.0000},
  {x: 206.6666, y: 106.0000},
  {x: 206.0000, y: 106.0000},
  {x: 203.0842, y: 108.0352},
  {x: 205.5445, y: 109.2019},
  {x: 200.0000, y: 110.0000},
  {x: 200.0000, y: 110.6666},
  {x: 200.0000, y: 111.3334},
  {x: 200.0000, y: 112.0000},
  {x: 196.8962, y: 112.1100},
  {x: 192.5894, y: 112.4702},
  {x: 191.0000, y: 111.0000},
  {x: 187.4449, y: 110.1991},
  {x: 185.4061, y: 108.0497},
  {x: 185.0000, y: 104.0000},
  {x: 184.6667, y: 104.0000},
  {x: 184.3333, y: 104.0000},
  {x: 184.0000, y: 104.0000},
  {x: 184.0000, y: 103.6667},
  {x: 184.0000, y: 103.3333},
  {x: 184.0000, y: 103.0000},
  {x: 184.3333, y: 103.0000},
  {x: 184.6667, y: 103.0000},
  {x: 185.0000, y: 103.0000},
  {x: 185.6789, y: 99.2444},
  {x: 187.3950, y: 96.8796},
  {x: 191.0000, y: 96.0000},
  {x: 191.0000, y: 95.3334},
  {x: 191.0000, y: 94.6666},
  {x: 191.0000, y: 94.0000},
  {x: 186.6019, y: 93.0169},
  {x: 183.8915, y: 90.3580},
  {x: 180.0000, y: 89.0000},
  {x: 180.0000, y: 89.3333},
  {x: 180.0000, y: 89.6667},
  {x: 180.0000, y: 90.0000},
  {x: 179.0001, y: 90.3333},
  {x: 177.9999, y: 90.6667},
  {x: 177.0000, y: 91.0000},
  {x: 177.0000, y: 91.3333},
  {x: 177.0000, y: 91.6667},
  {x: 177.0000, y: 92.0000},
  {x: 176.0001, y: 92.0000},
  {x: 174.9999, y: 92.0000},
  {x: 174.0000, y: 92.0000},
  {x: 173.3334, y: 92.9999},
  {x: 172.6666, y: 94.0001},
  {x: 172.0000, y: 95.0000},
  {x: 171.3334, y: 95.0000},
  {x: 170.6666, y: 95.0000},
  {x: 170.0000, y: 95.0000},
  {x: 170.0000, y: 95.3333},
  {x: 170.0000, y: 95.6667},
  {x: 170.0000, y: 96.0000},
  {x: 169.6667, y: 96.0000},
  {x: 169.3333, y: 96.0000},
  {x: 169.0000, y: 96.0000},
  {x: 169.0000, y: 95.6667},
  {x: 169.0000, y: 95.3333},
  {x: 169.0000, y: 95.0000},
  {x: 167.3335, y: 95.3333},
  {x: 165.6665, y: 95.6667},
  {x: 164.0000, y: 96.0000},
  {x: 162.5561, y: 94.3080},
  {x: 159.9006, y: 93.1846},
  {x: 159.0000, y: 91.0000},
  {x: 156.9922, y: 86.1295},
  {x: 160.1973, y: 79.6682},
  {x: 162.0000, y: 78.0000},
  {x: 162.6666, y: 78.0000},
  {x: 163.3334, y: 78.0000},
  {x: 164.0000, y: 78.0000},
  {x: 164.0000, y: 77.6667},
  {x: 164.0000, y: 77.3333},
  {x: 164.0000, y: 77.0000},
  {x: 163.6667, y: 77.0000},
  {x: 163.3333, y: 77.0000},
  {x: 163.0000, y: 77.0000},
  {x: 161.4704, y: 75.1436},
  {x: 160.9935, y: 76.1432},
  {x: 159.0000, y: 75.0000},
  {x: 158.6667, y: 74.3334},
  {x: 158.3333, y: 73.6666},
  {x: 158.0000, y: 73.0000},
  {x: 155.4929, y: 72.3666},
  {x: 156.0289, y: 74.3753},
  {x: 155.0000, y: 75.0000},
  {x: 154.3334, y: 75.0000},
  {x: 153.6666, y: 75.0000},
  {x: 153.0000, y: 75.0000},
  {x: 151.6668, y: 76.6665},
  {x: 150.3332, y: 78.3335},
  {x: 149.0000, y: 80.0000},
  {x: 147.6668, y: 80.3333},
  {x: 146.3332, y: 80.6667},
  {x: 145.0000, y: 81.0000},
  {x: 143.9376, y: 84.9487},
  {x: 144.6585, y: 82.3156},
  {x: 142.0000, y: 84.0000},
  {x: 142.0000, y: 84.3333},
  {x: 142.0000, y: 84.6667},
  {x: 142.0000, y: 85.0000},
  {x: 141.3334, y: 85.0000},
  {x: 140.6666, y: 85.0000},
  {x: 140.0000, y: 85.0000},
  {x: 139.3334, y: 85.9999},
  {x: 138.6666, y: 87.0001},
  {x: 138.0000, y: 88.0000},
  {x: 135.6669, y: 88.0000},
  {x: 133.3331, y: 88.0000},
  {x: 131.0000, y: 88.0000},
  {x: 129.8607, y: 86.8614},
  {x: 130.9964, y: 87.6016},
  {x: 129.0000, y: 87.0000},
  {x: 128.0555, y: 85.1988},
  {x: 127.6153, y: 85.4255},
  {x: 127.0000, y: 83.0000},
  {x: 126.6667, y: 83.0000},
  {x: 126.3333, y: 83.0000},
  {x: 126.0000, y: 83.0000},
  {x: 126.0000, y: 80.3336},
  {x: 126.0000, y: 77.6664},
  {x: 126.0000, y: 75.0000},
  {x: 127.9998, y: 72.6669},
  {x: 130.0002, y: 70.3331},
  {x: 132.0000, y: 68.0000},
  {x: 132.3333, y: 68.0000},
  {x: 132.6667, y: 68.0000},
  {x: 133.0000, y: 68.0000},
  {x: 133.0000, y: 67.3334},
  {x: 133.0000, y: 66.6666},
  {x: 133.0000, y: 66.0000},
  {x: 129.7690, y: 65.2581},
  {x: 128.8982, y: 64.1688},
  {x: 126.0000, y: 63.0000},
  {x: 126.0000, y: 62.6667},
  {x: 126.0000, y: 62.3333},
  {x: 126.0000, y: 62.0000},
  {x: 122.6670, y: 62.9999},
  {x: 119.3330, y: 64.0001},
  {x: 116.0000, y: 65.0000},
  {x: 116.0000, y: 65.3333},
  {x: 116.0000, y: 65.6667},
  {x: 116.0000, y: 66.0000},
  {x: 112.3337, y: 66.0000},
  {x: 108.6663, y: 66.0000},
  {x: 105.0000, y: 66.0000},
  {x: 102.6342, y: 61.9273},
  {x: 101.9273, y: 62.2447},
  {x: 102.0000, y: 55.0000},
  {x: 103.1386, y: 53.8607},
  {x: 102.3984, y: 54.9964},
  {x: 103.0000, y: 53.0000},
  {x: 105.9908, y: 51.6261},
  {x: 107.3827, y: 49.8208},
  {x: 111.0000, y: 49.0000},
  {x: 109.9406, y: 44.8917},
  {x: 110.4821, y: 47.8286},
  {x: 108.0000, y: 46.0000},
  {x: 107.3334, y: 45.0001},
  {x: 106.6666, y: 43.9999},
  {x: 106.0000, y: 43.0000},
  {x: 103.6669, y: 43.0000},
  {x: 101.3331, y: 43.0000},
  {x: 99.0000, y: 43.0000},
  {x: 95.2532, y: 47.3309},
  {x: 84.2422, y: 51.8091},
  {x: 76.0000, y: 52.0000},
  {x: 75.6667, y: 51.0001},
  {x: 75.3333, y: 49.9999},
  {x: 75.0000, y: 49.0000},
  {x: 71.6846, y: 45.8391},
  {x: 74.0659, y: 40.4127},
  {x: 75.0000, y: 36.0000},
  {x: 77.9997, y: 34.3335},
  {x: 81.0003, y: 32.6665},
  {x: 84.0000, y: 31.0000},
  {x: 82.8607, y: 29.8614},
  {x: 83.9964, y: 30.6016},
  {x: 82.0000, y: 30.0000},
  {x: 81.0364, y: 28.7644},
  {x: 75.1995, y: 26.5579},
  {x: 75.0000, y: 26.0000},
  {x: 75.0000, y: 26.6666},
  {x: 75.0000, y: 27.3334},
  {x: 75.0000, y: 28.0000},
  {x: 74.0001, y: 28.3333},
  {x: 72.9999, y: 28.6667},
  {x: 72.0000, y: 29.0000},
  {x: 72.0000, y: 28.6667},
  {x: 72.0000, y: 28.3333},
  {x: 72.0000, y: 28.0000},
  {x: 71.0001, y: 27.6667},
  {x: 69.9999, y: 27.3333},
  {x: 69.0000, y: 27.0000},
  {x: 69.0000, y: 26.6667},
  {x: 69.0000, y: 26.3333},
  {x: 69.0000, y: 26.0000},
  {x: 68.3334, y: 26.0000},
  {x: 67.6666, y: 26.0000},
  {x: 67.0000, y: 26.0000},
  {x: 66.6667, y: 25.3334},
  {x: 66.3333, y: 24.6666},
  {x: 66.0000, y: 24.0000},
  {x: 65.3334, y: 24.0000},
  {x: 64.6666, y: 24.0000},
  {x: 64.0000, y: 24.0000},
  {x: 63.3334, y: 23.0001},
  {x: 62.6666, y: 21.9999},
  {x: 62.0000, y: 21.0000},
  {x: 61.3334, y: 21.0000},
  {x: 60.6666, y: 21.0000},
  {x: 60.0000, y: 21.0000},
  {x: 59.6667, y: 20.3334},
  {x: 59.3333, y: 19.6666},
  {x: 59.0000, y: 19.0000},
  {x: 58.3334, y: 19.0000},
  {x: 57.6666, y: 19.0000},
  {x: 57.0000, y: 19.0000},
  {x: 56.3334, y: 18.0001},
  {x: 55.6666, y: 16.9999},
  {x: 55.0000, y: 16.0000},
  {x: 54.3334, y: 16.0000},
  {x: 53.6666, y: 16.0000},
  {x: 53.0000, y: 16.0000},
  {x: 51.6668, y: 14.3335},
  {x: 50.3332, y: 12.6665},
  {x: 49.0000, y: 11.0000},
  {x: 44.5916, y: 6.6454},
  {x: 36.5623, y: 1.1974},
  {x: 28.0000, y: 1.0000},
  {x: 28.0000, y: 1.3333},
  {x: 28.0000, y: 1.6667},
  {x: 28.0000, y: 2.0000},
  {x: 26.3335, y: 2.3333},
  {x: 24.6665, y: 2.6667},
  {x: 23.0000, y: 3.0000},
  {x: 22.6667, y: 3.6666},
  {x: 22.3333, y: 4.3334},
  {x: 22.0000, y: 5.0000},
  {x: 21.3334, y: 5.0000},
  {x: 20.6666, y: 5.0000},
  {x: 20.0000, y: 5.0000},
  {x: 19.6667, y: 5.6666},
  {x: 19.3333, y: 6.3334},
  {x: 19.0000, y: 7.0000},
  {x: 18.3334, y: 7.0000},
  {x: 17.6666, y: 7.0000},
  {x: 17.0000, y: 7.0000},
  {x: 16.0001, y: 8.3332},
  {x: 14.9999, y: 9.6668},
  {x: 14.0000, y: 11.0000},
  {x: 13.3334, y: 11.0000},
  {x: 12.6666, y: 11.0000},
  {x: 12.0000, y: 11.0000},
  {x: 10.6668, y: 12.6665},
  {x: 9.3332, y: 14.3335},
  {x: 8.0000, y: 16.0000},
  {x: 7.3334, y: 16.0000},
  {x: 6.6666, y: 16.0000},
  {x: 6.0000, y: 16.0000},
  {x: 4.3335, y: 17.9998},
  {x: 2.6665, y: 20.0002},
  {x: 1.0000, y: 22.0000},
  {x: 4.3330, y: 22.6666},
  {x: 7.6670, y: 23.3334},
  {x: 11.0000, y: 24.0000},
  {x: 11.0000, y: 24.3333},
  {x: 11.0000, y: 24.6667},
  {x: 11.0000, y: 25.0000},
  {x: 11.6666, y: 25.0000},
  {x: 12.3334, y: 25.0000},
  {x: 13.0000, y: 25.0000},
  {x: 13.0000, y: 25.3333},
  {x: 13.0000, y: 25.6667},
  {x: 13.0000, y: 26.0000},
  {x: 13.6666, y: 26.0000},
  {x: 14.3334, y: 26.0000},
  {x: 15.0000, y: 26.0000},
  {x: 15.3333, y: 26.6666},
  {x: 15.6667, y: 27.3334},
  {x: 16.0000, y: 28.0000},
  {x: 16.6666, y: 28.0000},
  {x: 17.3334, y: 28.0000},
  {x: 18.0000, y: 28.0000},
  {x: 18.0000, y: 28.3333},
  {x: 18.0000, y: 28.6667},
  {x: 18.0000, y: 29.0000},
  {x: 18.6666, y: 29.0000},
  {x: 19.3334, y: 29.0000},
  {x: 20.0000, y: 29.0000},
  {x: 20.0000, y: 29.3333},
  {x: 20.0000, y: 29.6667},
  {x: 20.0000, y: 30.0000},
  {x: 20.6666, y: 30.0000},
  {x: 21.3334, y: 30.0000},
  {x: 22.0000, y: 30.0000},
  {x: 22.0000, y: 30.3333},
  {x: 22.0000, y: 30.6667},
  {x: 22.0000, y: 31.0000},
  {x: 22.6666, y: 31.0000},
  {x: 23.3334, y: 31.0000},
  {x: 24.0000, y: 31.0000},
  {x: 24.3333, y: 31.6666},
  {x: 24.6667, y: 32.3334},
  {x: 25.0000, y: 33.0000},
  {x: 25.6666, y: 33.0000},
  {x: 26.3334, y: 33.0000},
  {x: 27.0000, y: 33.0000},
  {x: 27.3333, y: 33.6666},
  {x: 27.6667, y: 34.3334},
  {x: 28.0000, y: 35.0000},
  {x: 28.6666, y: 35.0000},
  {x: 29.3334, y: 35.0000},
  {x: 30.0000, y: 35.0000},
  {x: 30.6666, y: 35.9999},
  {x: 31.3334, y: 37.0001},
  {x: 32.0000, y: 38.0000},
  {x: 32.6666, y: 38.0000},
  {x: 33.3334, y: 38.0000},
  {x: 34.0000, y: 38.0000},
  {x: 37.9996, y: 42.3329},
  {x: 42.0004, y: 46.6671},
  {x: 46.0000, y: 51.0000},
  {x: 46.6666, y: 51.0000},
  {x: 47.3334, y: 51.0000},
  {x: 48.0000, y: 51.0000},
  {x: 48.3333, y: 51.6666},
  {x: 48.6667, y: 52.3334},
  {x: 49.0000, y: 53.0000},
  {x: 50.3332, y: 53.3333},
  {x: 51.6668, y: 53.6667},
  {x: 53.0000, y: 54.0000},
  {x: 53.6666, y: 54.9999},
  {x: 54.3334, y: 56.0001},
  {x: 55.0000, y: 57.0000},
  {x: 55.6666, y: 57.0000},
  {x: 56.3334, y: 57.0000},
  {x: 57.0000, y: 57.0000},
  {x: 57.3333, y: 57.6666},
  {x: 57.6667, y: 58.3334},
  {x: 58.0000, y: 59.0000},
  {x: 59.9998, y: 59.6666},
  {x: 62.0002, y: 60.3334},
  {x: 64.0000, y: 61.0000},
  {x: 64.3333, y: 61.6666},
  {x: 64.6667, y: 62.3334},
  {x: 65.0000, y: 63.0000},
  {x: 66.9998, y: 63.6666},
  {x: 69.0002, y: 64.3334},
  {x: 71.0000, y: 65.0000},
  {x: 71.3333, y: 65.6666},
  {x: 71.6667, y: 66.3334},
  {x: 72.0000, y: 67.0000},
  {x: 72.9999, y: 67.0000},
  {x: 74.0001, y: 67.0000},
  {x: 75.0000, y: 67.0000},
  {x: 75.0000, y: 67.3333},
  {x: 75.0000, y: 67.6667},
  {x: 75.0000, y: 68.0000},
  {x: 75.6666, y: 68.0000},
  {x: 76.3334, y: 68.0000},
  {x: 77.0000, y: 68.0000},
  {x: 77.0000, y: 68.3333},
  {x: 77.0000, y: 68.6667},
  {x: 77.0000, y: 69.0000},
  {x: 77.6666, y: 69.0000},
  {x: 78.3334, y: 69.0000},
  {x: 79.0000, y: 69.0000},
  {x: 79.3333, y: 69.6666},
  {x: 79.6667, y: 70.3334},
  {x: 80.0000, y: 71.0000},
  {x: 80.6666, y: 71.0000},
  {x: 81.3334, y: 71.0000},
  {x: 82.0000, y: 71.0000},
  {x: 82.3333, y: 71.6666},
  {x: 82.6667, y: 72.3334},
  {x: 83.0000, y: 73.0000},
  {x: 84.3332, y: 73.3333},
  {x: 85.6668, y: 73.6667},
  {x: 87.0000, y: 74.0000},
  {x: 87.6666, y: 74.9999},
  {x: 88.3334, y: 76.0001},
  {x: 89.0000, y: 77.0000},
  {x: 89.6666, y: 77.0000},
  {x: 90.3334, y: 77.0000},
  {x: 91.0000, y: 77.0000},
  {x: 91.6666, y: 77.9999},
  {x: 92.3334, y: 79.0001},
  {x: 93.0000, y: 80.0000},
  {x: 93.6666, y: 80.0000},
  {x: 94.3334, y: 80.0000},
  {x: 95.0000, y: 80.0000},
  {x: 95.6666, y: 80.9999},
  {x: 96.3334, y: 82.0001},
  {x: 97.0000, y: 83.0000},
  {x: 97.6666, y: 83.0000},
  {x: 98.3334, y: 83.0000},
  {x: 99.0000, y: 83.0000},
  {x: 99.3333, y: 83.6666},
  {x: 99.6667, y: 84.3334},
  {x: 100.0000, y: 85.0000},
  {x: 100.9999, y: 85.3333},
  {x: 102.0001, y: 85.666},
  {x: 103.0000, y: 86.0000},
  {x: 103.0000, y: 86.3333},
  {x: 103.0000, y: 86.6667},
  {x: 103.0000, y: 87.0000},
  {x: 103.6666, y: 87.0000},
  {x: 104.3334, y: 87.0000},
  {x: 105.0000, y: 87.0000},
  {x: 105.3333, y: 87.6666},
  {x: 105.6667, y: 88.3334},
  {x: 106.0000, y: 89.0000},
  {x: 106.9999, y: 89.3333},
  {x: 108.0001, y: 89.6667},
  {x: 109.0000, y: 90.0000},
  {x: 109.0000, y: 90.3333},
  {x: 109.0000, y: 90.6667},
  {x: 109.0000, y: 91.0000},
  {x: 109.6666, y: 91.0000},
  {x: 110.3334, y: 91.0000},
  {x: 111.0000, y: 91.0000},
  {x: 111.0000, y: 91.3333},
  {x: 111.0000, y: 91.6667},
  {x: 111.0000, y: 92.0000},
  {x: 111.6666, y: 92.0000},
  {x: 112.3334, y: 92.0000},
  {x: 113.0000, y: 92.0000},
  {x: 113.0000, y: 92.3333},
  {x: 113.0000, y: 92.6667},
  {x: 113.0000, y: 93.0000},
  {x: 113.9999, y: 93.0000},
  {x: 115.0001, y: 93.0000},
  {x: 116.0000, y: 93.0000},
  {x: 116.0000, y: 93.3333},
  {x: 116.0000, y: 93.6667},
  {x: 116.0000, y: 94.0000},
  {x: 116.6666, y: 94.0000},
  {x: 117.3334, y: 94.0000},
  {x: 118.0000, y: 94.0000},
  {x: 118.0000, y: 94.3333},
  {x: 118.0000, y: 94.6667},
  {x: 118.0000, y: 95.0000},
  {x: 118.6666, y: 95.0000},
  {x: 119.3334, y: 95.0000},
  {x: 120.0000, y: 95.0000},
  {x: 120.0000, y: 95.3333},
  {x: 120.0000, y: 95.6667},
  {x: 120.0000, y: 96.0000},
  {x: 121.9998, y: 96.6666},
  {x: 124.0002, y: 97.3334},
  {x: 126.0000, y: 98.0000},
  {x: 126.0000, y: 98.3333},
  {x: 126.0000, y: 98.6667},
  {x: 126.0000, y: 99.0000},
  {x: 127.3332, y: 99.0000},
  {x: 128.6668, y: 99.0000},
  {x: 130.0000, y: 99.0000},
  {x: 130.0000, y: 99.3333},
  {x: 130.0000, y: 99.6667},
  {x: 130.0000, y: 100.0000},
  {x: 131.6665, y: 100.0000},
  {x: 133.3335, y: 100.0000},
  {x: 135.0000, y: 100.0000},
  {x: 135.0000, y: 100.3333},
  {x: 135.0000, y: 100.6667},
  {x: 135.0000, y: 101.0000},
  {x: 135.9999, y: 101.0000},
  {x: 137.0001, y: 101.0000},
  {x: 138.0000, y: 101.0000},
  {x: 138.0000, y: 101.3333},
  {x: 138.0000, y: 101.6667},
  {x: 138.0000, y: 102.0000},
  {x: 138.9999, y: 102.0000},
  {x: 140.0001, y: 102.0000},
  {x: 141.0000, y: 102.0000},
  {x: 141.0000, y: 102.3333},
  {x: 141.0000, y: 102.6667},
  {x: 141.0000, y: 103.0000},
  {x: 142.9998, y: 103.6666},
  {x: 145.0002, y: 104.3334},
  {x: 147.0000, y: 105.0000},
  {x: 147.0000, y: 105.3333},
  {x: 147.0000, y: 105.6667},
  {x: 147.0000, y: 106.0000},
  {x: 147.6666, y: 106.0000},
  {x: 148.3334, y: 106.0000},
  {x: 149.0000, y: 106.0000},
  {x: 149.0000, y: 106.3333},
  {x: 149.0000, y: 106.6667},
  {x: 149.0000, y: 107.0000},
  {x: 149.9999, y: 107.0000},
  {x: 151.0001, y: 107.0000},
  {x: 152.0000, y: 107.0000},
  {x: 152.0000, y: 107.3333},
  {x: 152.0000, y: 107.6667},
  {x: 152.0000, y: 108.0000},
  {x: 152.6666, y: 108.0000},
  {x: 153.3334, y: 108.0000},
  {x: 154.0000, y: 108.0000},
  {x: 154.3333, y: 108.6666},
  {x: 154.6667, y: 109.3334},
  {x: 155.0000, y: 110.0000},
  {x: 155.6666, y: 110.0000},
  {x: 156.3334, y: 110.0000},
  {x: 157.0000, y: 110.0000},
  {x: 157.6666, y: 110.9999},
  {x: 158.3334, y: 112.0001},
  {x: 159.0000, y: 113.0000},
  {x: 160.3332, y: 113.3333},
  {x: 161.6668, y: 113.6667},
  {x: 163.0000, y: 114.0000},
  {x: 163.9999, y: 115.3332},
  {x: 165.0001, y: 116.6668},
  {x: 166.0000, y: 118.0000},
  {x: 167.3332, y: 118.3333},
  {x: 168.6668, y: 118.6667},
  {x: 170.0000, y: 119.0000},
  {x: 170.3333, y: 119.6666},
  {x: 170.6667, y: 120.3334},
  {x: 171.0000, y: 121.0000},
  {x: 171.6666, y: 121.0000},
  {x: 172.3334, y: 121.0000},
  {x: 173.0000, y: 121.0000},
  {x: 173.0000, y: 121.3333},
  {x: 173.0000, y: 121.6667},
  {x: 173.0000, y: 122.0000},
  {x: 174.3332, y: 122.3333},
  {x: 175.6668, y: 122.6667},
  {x: 177.0000, y: 123.0000},
  {x: 177.0000, y: 123.3333},
  {x: 177.0000, y: 123.6667},
  {x: 177.0000, y: 124.0000},
  {x: 178.9998, y: 124.3333},
  {x: 181.0002, y: 124.6667},
  {x: 183.0000, y: 125.0000},
  {x: 183.0000, y: 125.3333},
  {x: 183.0000, y: 125.6667},
  {x: 183.0000, y: 126.0000},
  {x: 183.9999, y: 126.0000},
  {x: 185.0001, y: 126.0000},
  {x: 186.0000, y: 126.0000},
  {x: 186.0000, y: 126.3333},
  {x: 186.0000, y: 126.6667},
  {x: 186.0000, y: 127.0000},
  {x: 187.3332, y: 127.0000},
  {x: 188.6668, y: 127.0000},
  {x: 190.0000, y: 127.0000},
  {x: 190.0000, y: 127.3333},
  {x: 190.0000, y: 127.6667},
  {x: 190.0000, y: 128.0000},
  {x: 191.3332, y: 128.0000},
  {x: 192.6668, y: 128.0000},
  {x: 194.0000, y: 128.0000},
  {x: 194.0000, y: 128.3333},
  {x: 194.0000, y: 128.6667},
  {x: 194.0000, y: 129.0000},
  {x: 195.3332, y: 129.0000},
  {x: 196.6668, y: 129.0000},
  {x: 198.0000, y: 129.0000},
  {x: 198.0000, y: 129.3333},
  {x: 198.0000, y: 129.6667},
  {x: 198.0000, y: 130.0000},
  {x: 199.3332, y: 130.0000},
  {x: 200.6668, y: 130.0000},
  {x: 202.0000, y: 130.0000},
  {x: 202.0000, y: 130.3333},
  {x: 202.0000, y: 130.6667},
  {x: 202.0000, y: 131.0000},
  {x: 203.3332, y: 131.0000},
  {x: 204.6668, y: 131.0000},
  {x: 206.0000, y: 131.0000},
  {x: 206.0000, y: 131.3333},
  {x: 206.0000, y: 131.6667},
  {x: 206.0000, y: 132.0000},
  {x: 206.9999, y: 132.0000},
  {x: 208.0001, y: 132.0000},
  {x: 209.0000, y: 132.0000},
  {x: 209.0000, y: 132.3333},
  {x: 209.0000, y: 132.6667},
  {x: 209.0000, y: 133.0000},
  {x: 209.9999, y: 133.0000},
  {x: 211.0001, y: 133.0000},
  {x: 212.0000, y: 133.0000},
  {x: 212.0000, y: 133.3333},
  {x: 212.0000, y: 133.6667},
  {x: 212.0000, y: 134.0000},
  {x: 213.3332, y: 134.0000},
  {x: 214.6668, y: 134.0000},
  {x: 216.0000, y: 134.0000},
  {x: 216.0000, y: 134.3333},
  {x: 216.0000, y: 134.6667},
  {x: 216.0000, y: 135.0000},
  {x: 218.9997, y: 135.6666},
  {x: 222.0003, y: 136.3334},
  {x: 225.0000, y: 137.0000},
  {x: 225.0000, y: 137.3333},
  {x: 225.0000, y: 137.6667},
  {x: 225.0000, y: 138.0000},
  {x: 225.6666, y: 138.0000},
  {x: 226.3334, y: 138.0000},
  {x: 227.0000, y: 138.0000},
  {x: 227.0000, y: 138.3333},
  {x: 227.0000, y: 138.6667},
  {x: 227.0000, y: 139.0000},
  {x: 227.9999, y: 139.0000},
  {x: 229.0001, y: 139.0000},
  {x: 230.0000, y: 139.0000},
  {x: 230.0000, y: 139.3333},
  {x: 230.0000, y: 139.6667},
  {x: 230.0000, y: 140.0000},
  {x: 231.9998, y: 140.6666},
  {x: 234.0002, y: 141.3334},
  {x: 236.0000, y: 142.0000},
  {x: 236.0000, y: 142.3333},
  {x: 236.0000, y: 142.6667},
  {x: 236.0000, y: 143.0000},
  {x: 236.6666, y: 143.0000},
  {x: 237.3334, y: 143.0000},
  {x: 238.0000, y: 143.0000},
  {x: 238.0000, y: 143.3333},
  {x: 238.0000, y: 143.6667},
  {x: 238.0000, y: 144.0000},
  {x: 238.6666, y: 144.0000},
  {x: 239.3334, y: 144.0000},
  {x: 240.0000, y: 144.0000},
  {x: 240.0000, y: 144.3333},
  {x: 240.0000, y: 144.6667},
  {x: 240.0000, y: 145.0000},
  {x: 240.6666, y: 145.0000},
  {x: 241.3334, y: 145.0000},
  {x: 242.0000, y: 145.0000},
  {x: 242.0000, y: 145.3333},
  {x: 242.0000, y: 145.6667},
  {x: 242.0000, y: 146.0000},
  {x: 242.6666, y: 146.0000},
  {x: 243.3334, y: 146.0000},
  {x: 244.0000, y: 146.0000},
  {x: 244.0000, y: 146.3333},
  {x: 244.0000, y: 146.6667},
  {x: 244.0000, y: 147.0000},
  {x: 245.9998, y: 147.6666},
  {x: 248.0002, y: 148.3334},
  {x: 250.0000, y: 149.0000},
  {x: 250.0000, y: 149.3333},
  {x: 250.0000, y: 149.6667},
  {x: 250.0000, y: 150.0000},
  {x: 251.9998, y: 150.3333},
  {x: 254.0002, y: 150.6667},
  {x: 256.0000, y: 151.0000},
  {x: 265.6774, y: 155.2103},
  {x: 274.3547, y: 160.2967},
  {x: 288.0000, y: 158.0000},
  {x: 288.9566, y: 156.1421},
  {x: 290.2375, y: 154.1224},
  {x: 292.0000, y: 153.0000},
  {x: 291.1558, y: 151.8654},
  {x: 292.1268, y: 152.8546},
  {x: 291.0000, y: 152.0000},
  {x: 288.8726, y: 148.5584},
  {x: 281.8376, y: 145.6902},
  {x: 278.0000, y: 144.0000},
  {x: 277.0001, y: 144.0000},
  {x: 275.9999, y: 144.0000},
  {x: 275.0000, y: 144.0000},
  {x: 275.0000, y: 143.6667},
  {x: 275.0000, y: 143.3333},
  {x: 275.0000, y: 143.0000},
  {x: 273.0002, y: 142.3334},
  {x: 270.9998, y: 141.6666},
  {x: 269.0000, y: 141.0000},
  {x: 269.0000, y: 140.6667},
  {x: 269.0000, y: 140.3333},
  {x: 269.0000, y: 140.0000},
  {x: 268.3334, y: 140.0000},
  {x: 267.6666, y: 140.0000},
  {x: 267.0000, y: 140.0000},
  {x: 266.6667, y: 139.3334},
  {x: 266.3333, y: 138.6666},
  {x: 266.0000, y: 138.0000},
  {x: 264.6668, y: 137.6667},
  {x: 263.3332, y: 137.3333},
  {x: 262.0000, y: 137.0000},
  {x: 262.0000, y: 137.3333},
  {x: 262.0000, y: 137.6667},
  {x: 262.0000, y: 138.0000},
  {x: 263.7188, y: 139.1266},
  {x: 263.3552, y: 138.6325},
  {x: 264.0000, y: 141.0000},
  {x: 262.7533, y: 140.6018},
  {x: 262.2080, y: 139.4150},
  {x: 260.0000, y: 140.0000},
  {x: 260.0000, y: 140.3333},
  {x: 260.0000, y: 140.6667},
  {x: 260.0000, y: 141.0000},
  {x: 258.0002, y: 141.3333},
  {x: 255.9998, y: 141.6667},
  {x: 254.0000, y: 142.0000},
  {x: 254.0000, y: 141.6667},
  {x: 254.0000, y: 141.3333},
  {x: 254.0000, y: 141.0000},
  {x: 253.3334, y: 141.0000},
  {x: 252.6666, y: 141.0000},
  {x: 252.0000, y: 141.0000},
  {x: 252.0000, y: 140.6667},
  {x: 252.0000, y: 140.3333},
  {x: 252.0000, y: 140.0000},
  {x: 251.0001, y: 139.6667},
  {x: 249.9999, y: 139.3333},
  {x: 249.0000, y: 139.0000}
];
