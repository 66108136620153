import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import Logo from '@/components/icons/logo'
import ArrowCircleIcon from '@/components/icons/arrowCircleRight'
import BagIcon from '@/components/icons/bag'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Header',

  components: {
    Button,
    ButtonIcon,

    Logo,
    ArrowCircleIcon,
    BagIcon
  },

  methods: {
    showPickSneakerColor: function() {
      EventBus.$emit('showPickSneakerColor');
    },

    showCart: function() {
      EventBus.$emit('showCart');
    }
  }
}
