import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: function () {
        return import('../views/home');
      }
    },
    {
      path: '/about',
      name: 'about',
      component: function () {
        return import('../views/about');
      }
    }
  ]
});
