import ShareIcon from '@/components/icons/share'
import ChevronLeft from '@/components/icons/chevronLeft'
import RefreshIcon from '@/components/icons/refresh'
import CloseIcon from '@/components/icons/close'
import ZoomInIcon from '@/components/icons/zoomIn'
import MoveIcon from '@/components/icons/move'
import RotateIcon from '@/components/icons/rotate'
import TrashIcon from '@/components/icons/trash'
import EyeIcon from '@/components/icons/eye'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import SideDropdown from '@/components/sneakerCustom/preview/sidebar/sideDropdown'
import AreaDropdown from '@/components/sneakerCustom/preview/sidebar/areaDropdown'
import SidebarContact from '@/components/sneakerCustom/contact/sidebar'

import sneakerCustom from '@/mixins/sneakerCustom'
import cart from '@/mixins/cart'
import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'SneakerCustom',

  mixins: [
    sneakerCustom,
    cart,
    Mixins
  ],

  components: {
    ShareIcon,
    ChevronLeft,
    RefreshIcon,
    CloseIcon,
    ZoomInIcon,
    MoveIcon,
    RotateIcon,
    TrashIcon,
    EyeIcon,

    Button,
    ButtonIcon,

    SideDropdown,
    AreaDropdown,
    SidebarContact
  },

  data() {
    return {
      isCustom: true,
      isSize: false,
      isContact: false,

      comments: '',

      size: '',

      contact: {
        customer: {
          first_name: '',
          last_name: '',
          email: '',
          phone: ''
        },

        shipping: {
          country: 'Mexico',
          street: '',
          reference: '',
          city: '',
          zip: '',
          state: ''
        }
      }
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideSneakerCustom');
    },

    backStep: function() {
      if (this.isCustom) {
        this.close();

        EventBus.$emit('showPickSneakerCustom');
      } else if (this.isSize) {
        this.isSize = false;
        this.isCustom = true;
      } else if (this.isContact) {
        this.isContact = false;
        this.isSize = true;
      }
    },

    nextStep: function() {
      if (this.isCustom) {
        this.isCustom = false;
        this.isSize = true;
      } else if (this.isSize) {
        this.isSize = false;
        this.isContact = true;
      } else if (this.isContact) {
        this.addToCart();
      }
    },

    bgImgStyle: function(src) {
      let style = '';

      style += 'background-image: url(' + src + ');';
      style += 'background-size: cover;';
      style += 'background-position: center center;';
      style += 'background-repeat: no-repeat;';

      return style;
    },

    putComments: function(comments) {
      this.comments = comments;
    },

    putSize: function(size) {
      this.size = size;
    },

    addToCart: async function() {
      let cartItem = {
        sneaker: 'Nike AF1 Blanco',
        image: require('@/assets/img/cart.jpg'),
        custom: {
          application: this.application,
          sneaker: this.sneaker
        },
        size: this.size,
        comments: this.comments,
        customer: this.contact.customer,
        shipping: this.contact.shipping,
        unit_price: 500,
        amount: 1
      };

      this.addCartItem(cartItem);

      this.close();
    }
  }
}
