import Input from '@/components/form/input'
import TextArea from '@/components/form/textarea'
import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import CloseIcon from '@/components/icons/close'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Help',

  components: {
    Input,
    TextArea,
    Button,
    ButtonIcon,
    CloseIcon
  },

  methods: {
    close: function() {
      EventBus.$emit('hideHelp');
    }
  },

  mounted() {
    window.scrollTo(0,0);
  }
}
