module.exports = {
  full: {
    top: 155,
    left: 15,
    width: 695,
    height: 235,
    style: null,
    color: {
      value: null,
      visible: true
    },
    images: [],
    text: [],
    points: require('@/config/sneaker/inner/_full')
  },
  areas: {
    vamp: {
      top: 297,
      left: 485,
      width: 197,
      height: 46,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_vamp'),
      visible: true
    },
    tip: {
      top: 279,
      left: 357,
      width: 353,
      height: 108,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_tip'),
      visible: true
    },
    quarter: {
      top: 208,
      left: 188,
      width: 249,
      height: 173,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_quarter'),
      visible: true
    },
    foxing: {
      top: 175,
      left: 16,
      width: 292,
      height: 188,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_foxing'),
      visible: true
    },
    backtab: {
      top: 168,
      left: 19,
      width: 77,
      height: 112,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_backtab'),
      visible: true
    },
    eyestay: {
      top: 155,
      left: 254,
      width: 292,
      height: 160,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_eyestay'),
      visible: true
    },
    swoosh: {
      top: 232,
      left: 41,
      width: 340,
      height: 95,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_swoosh'),
      visible: true
    },
    top_set: {
      top: 208,
      left: 191,
      width: 519,
      height: 183,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_top-set'),
      visible: true
    },
    bottom_set: {
      top: 177,
      left: 19,
      width: 420,
      height: 205,
      style: null,
      color: {
        value: null,
        visible: true
      },
      image: null,
      text: [],
      points: require('@/config/sneaker/inner/_bottom-set'),
      visible: true
    }
  }
}
