import CloseIcon from '@/components/icons/close'
import ReturnIcon from '@/components/icons/return'
import MetamaskIcon from '@/components/icons/metamask'

import PayPal from 'vue-paypal-checkout'
import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import cart from '@/mixins/cart'
import order from '@/mixins/order'
import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

import ensAddress from '@/config/ensAddress.js'

export default {
  name: 'Cart',

  mixins: [
    cart,
    order,
    Mixins
  ],

  components: {
    CloseIcon,
    ReturnIcon,
    MetamaskIcon,

    PayPal,
    Button,
    ButtonIcon
  },

  data() {
    return {
      step: 'list',
      steps: [
        'list',
        'payment'
      ],

      items: [],

      isMetaMask: false,
      account: null,

      stylePaypal: {
        label: 'checkout',
        size:  'responsive',
        shape: 'rect',
        color: 'black'
      },
      clientPaypal: {
        sandbox: 'AVia22VE44rXoPglARkf4f_xZSlpF22d_FkK-luFKBhpHYrFu71D5zis4sisYwVcrnlQPzKKOU8LrUk9',
        production: 'AdjmGMZ5yC3lt2_1yXXrSO6tcjJo4IkOza2gJFfLREL7aXjnXULDWZwvMZUNHYZmBXoxVwZThKJjQrpj'
      }
    }
  },

  computed: {
    subtotal: function() {
      let subtotal = 0;

      this.items.forEach(item => {
        subtotal += item.unit_price * item.amount;
      });

      return subtotal;
    },

    total: function() {
      return this.subtotal.toString();
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideCart');
    },

    addAnother: function() {
      EventBus.$emit('showPickSneakerColor');

      this.close();
    },

    getItems: async function() {
      this.items = await this.getCartItems();

      setTimeout(() => this.bgImg(), 50);
    },

    removeItem: function(key) {
      this.deleteCartItem(key);

      this.getItems();
    },

    showPayment: function() {
      this.step = 'payment';
    },

    verifyMetaMask: function() {
      if (typeof window.ethereum !== 'undefined' && ethereum.isMetaMask) {
        this.isMetaMask = true;

        ethereum.on('accountsChanged', accounts => {
          this.account = accounts[0];
        });

        ethereum.on('chainChanged', chainId => {
          window.location.reload();
        });
      }
    },

    getAccount: async function() {
      let accounts = await ethereum.request({
        method: 'eth_requestAccounts'
      });

      this.account = accounts[0];
    },

    payEth: async function() {
      if (this.account === null) {
        await this.getAccount();
      }

      this.sendEth();
    },

    sendEth: async function() {
      let txHash;
      let method = 'ethereum';
      let response;

      let ethVal = 0.12;
      let txValue = (ethVal * Math.pow(10, 18)).toString(16);

      if (this.account !== null) {
        await ethereum
          .request({
            method: 'eth_sendTransaction',
            params: [{
              from: this.account,
              to: ensAddress,
              value: txValue
            }]
          })
          .then(res => txHash = res)
          .catch(err => console.log(err));

        if (typeof txHash !== 'undefined') {
          response = await this.generateOrder(this.items, method, txHash);

          if (response) {
            EventBus.$emit('showOrderConfirmed');

            this.initCart();
            this.close();
          } else {
            this.$toast.error('Error al crear la orden');
          }
        } else {
          this.$toast.error('Error al crear la orden');
        }
      }
    },

    paymentAuthorized: function(res) {
      // Not Content
    },

    paymentCompleted: async function(res) {
      let tx = res.id;
      let method = 'paypal';

      let response = await this.generateOrder(this.items, method, tx);

      if (response) {
        EventBus.$emit('showOrderConfirmed');

        this.initCart();
        this.close();
      } else {
        this.$toast.error('Error al crear la orden');
      }
    },

    paymentCancelled: function(res) {
      // Not Content
    }
  },

  mounted() {
    this.getItems();
    this.verifyMetaMask();
  },

  filters: {
    money: function (val) {
      let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      });

      return formatter.format(val);
    }
  }
}
