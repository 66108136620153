export default {
  name: 'Button',

  props: {
    type: {
      type: String,
      default: 'primary',
      validator: (val) => [
        'primary',
        'secondary',
        'tertiary',
        'dark',
        'white',
      ].includes(val)
    },

    shape: {
      type: String,
      require: true,
      validator: (val) => [
        'circular',
        'round',
        'square'
      ].includes(val)
    },

    direction: {
      type: String,
      validator: (val) => [
        'left',
        'right'
      ].includes(val),
      default: 'left'
    },

    active: {
      type: Boolean,
      default: false
    },

    unpadding: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      require: true
    },

    disabled: {
      type: Boolean,
      default: null
    }
  }
}
