var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"formSelect",staticClass:"select"},[_c('span',{staticClass:"select__value"},[_vm._v(" "+_vm._s(_vm.localValue)+" ")]),_c('div',{class:[
      'select__arrow',
      _vm.isOptions
        ? 'select__arrow--active'
        : null
    ],on:{"click":function($event){return _vm.switchOptions()}}},[_c('ChevronDown',{staticClass:"arrow__icon"})],1),(_vm.options.length > 0)?_c('div',{class:[
      'select__options',
      _vm.isOptions
        ? 'select__options--active'
        : null
    ]},_vm._l((_vm.options),function(option){return _c('div',{class:[
        'select__option',
        option === _vm.localValue
          ? 'select__option--active'
          : null
      ],on:{"click":function($event){return _vm.selectOption(option)}}},[_c('span',{staticClass:"select__value"},[_vm._v(" "+_vm._s(option)+" ")])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }