import ArrowLeft from '@/components/icons/arrowLeft'
import ArrowLeftMobile from '@/components/icons/arrowLeft_mobile'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'
import CloseIcon from '@/components/icons/close'
import FullIcon from '@/components/icons/custom/full'
import PieceIcon from '@/components/icons/custom/piece'

import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'PickSneakerCustom',

  mixins: [Mixins],

  components: {
    ArrowLeft,
    ArrowLeftMobile,
    Button,
    ButtonIcon,
    CloseIcon,
    FullIcon,
    PieceIcon
  },

  data() {
    return {
      options: [
        {
          id: 'full',
          name: 'Full Background',
          instruction: 'Apply colour and add your NFT design to entire sneaker.',
          note: '*The design does not apply to the shoelaces and the sole.'
        },
        {
          id: 'areas',
          name: 'Pice by piece',
          instruction: 'Mix colours and NFTs by editing individually the sneaker parts.',
          note: '*The design does not apply to the shoelaces and the sole.'
        }
      ]
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hidePickSneakerCustom');
    },

    toBack: function() {
      this.close();

      EventBus.$emit('showPickSneakerColor');
    },

    showSneakerCustom: function(id) {
      this.close();
      
      EventBus.$emit('showSneakerCustom', id);
    }
  }
}
