module.exports = [
  {x: 31.0000, y: 100.0000},
  {x: 31.0000, y: 100.6666},
  {x: 31.0000, y: 101.3334},
  {x: 31.0000, y: 102.0000},
  {x: 27.2191, y: 104.3495},
  {x: 26.4686, y: 108.2975},
  {x: 24.0000, y: 112.0000},
  {x: 23.3334, y: 112.3333},
  {x: 22.6666, y: 112.6667},
  {x: 22.0000, y: 113.0000},
  {x: 22.0000, y: 113.6666},
  {x: 22.0000, y: 114.3334},
  {x: 22.0000, y: 115.0000},
  {x: 21.3334, y: 115.3333},
  {x: 20.6666, y: 115.6667},
  {x: 20.0000, y: 116.0000},
  {x: 20.0000, y: 116.6666},
  {x: 20.0000, y: 117.3334},
  {x: 20.0000, y: 118.0000},
  {x: 19.3334, y: 118.3333},
  {x: 18.6666, y: 118.6667},
  {x: 18.0000, y: 119.0000},
  {x: 17.6667, y: 120.3332},
  {x: 17.3333, y: 121.6668},
  {x: 17.0000, y: 123.0000},
  {x: 16.3334, y: 123.3333},
  {x: 15.6666, y: 123.6667},
  {x: 15.0000, y: 124.0000},
  {x: 15.0000, y: 124.6666},
  {x: 15.0000, y: 125.3334},
  {x: 15.0000, y: 126.0000},
  {x: 14.6667, y: 126.0000},
  {x: 14.3333, y: 126.0000},
  {x: 14.0000, y: 126.0000},
  {x: 14.0000, y: 126.6666},
  {x: 14.0000, y: 127.3334},
  {x: 14.0000, y: 128.0000},
  {x: 13.6667, y: 128.0000},
  {x: 13.3333, y: 128.0000},
  {x: 13.0000, y: 128.0000},
  {x: 13.0000, y: 128.6666},
  {x: 13.0000, y: 129.3334},
  {x: 13.0000, y: 130.0000},
  {x: 12.6667, y: 130.0000},
  {x: 12.3333, y: 130.0000},
  {x: 12.0000, y: 130.0000},
  {x: 12.0000, y: 130.6666},
  {x: 12.0000, y: 131.3334},
  {x: 12.0000, y: 132.0000},
  {x: 11.6667, y: 132.0000},
  {x: 11.3333, y: 132.0000},
  {x: 11.0000, y: 132.0000},
  {x: 11.0000, y: 132.6666},
  {x: 11.0000, y: 133.3334},
  {x: 11.0000, y: 134.0000},
  {x: 10.6667, y: 134.0000},
  {x: 10.3333, y: 134.0000},
  {x: 10.0000, y: 134.0000},
  {x: 10.0000, y: 134.6666},
  {x: 10.0000, y: 135.3334},
  {x: 10.0000, y: 136.0000},
  {x: 9.6667, y: 136.0000},
  {x: 9.3333, y: 136.0000},
  {x: 9.0000, y: 136.0000},
  {x: 9.0000, y: 136.6666},
  {x: 9.0000, y: 137.3334},
  {x: 9.0000, y: 138.0000},
  {x: 6.3659, y: 142.9378},
  {x: 2.7592, y: 148.0911},
  {x: 2.0000, y: 155.0000},
  {x: 10.8587, y: 154.8899},
  {x: 22.8460, y: 153.9804},
  {x: 30.0000, y: 156.0000},
  {x: 35.4860, y: 157.5487},
  {x: 44.1353, y: 155.6234},
  {x: 49.0000, y: 157.0000},
  {x: 52.3330, y: 157.0000},
  {x: 55.6670, y: 157.0000},
  {x: 59.0000, y: 157.0000},
  {x: 59.0000, y: 157.3333},
  {x: 59.0000, y: 157.6667},
  {x: 59.0000, y: 158.0000},
  {x: 62.3330, y: 158.0000},
  {x: 65.6670, y: 158.0000},
  {x: 69.0000, y: 158.0000},
  {x: 69.0000, y: 158.3333},
  {x: 69.0000, y: 158.6667},
  {x: 69.0000, y: 159.0000},
  {x: 72.3330, y: 159.0000},
  {x: 75.6670, y: 159.0000},
  {x: 79.0000, y: 159.0000},
  {x: 79.0000, y: 159.3333},
  {x: 79.0000, y: 159.6667},
  {x: 79.0000, y: 160.0000},
  {x: 84.9994, y: 160.3333},
  {x: 91.0006, y: 160.6667},
  {x: 97.0000, y: 161.0000},
  {x: 97.0000, y: 161.3333},
  {x: 97.0000, y: 161.6667},
  {x: 97.0000, y: 162.0000},
  {x: 100.3330, y: 162.0000},
  {x: 103.6670, y: 162.0000},
  {x: 107.0000, y: 162.0000},
  {x: 107.0000, y: 162.3333},
  {x: 107.0000, y: 162.6667},
  {x: 107.0000, y: 163.0000},
  {x: 109.9997, y: 163.0000},
  {x: 113.0003, y: 163.0000},
  {x: 116.0000, y: 163.0000},
  {x: 116.0000, y: 163.3333},
  {x: 116.0000, y: 163.6667},
  {x: 116.0000, y: 164.0000},
  {x: 118.3331, y: 164.0000},
  {x: 120.6669, y: 164.0000},
  {x: 123.0000, y: 164.0000},
  {x: 123.0000, y: 164.3333},
  {x: 123.0000, y: 164.6667},
  {x: 123.0000, y: 165.0000},
  {x: 125.6664, y: 165.0000},
  {x: 128.3336, y: 165.0000},
  {x: 131.0000, y: 165.0000},
  {x: 131.0000, y: 165.3333},
  {x: 131.0000, y: 165.6667},
  {x: 131.0000, y: 166.0000},
  {x: 132.9998, y: 166.0000},
  {x: 135.0002, y: 166.0000},
  {x: 137.0000, y: 166.0000},
  {x: 137.0000, y: 166.3333},
  {x: 137.0000, y: 166.6667},
  {x: 137.0000, y: 167.0000},
  {x: 139.3331, y: 167.0000},
  {x: 141.6669, y: 167.0000},
  {x: 144.0000, y: 167.0000},
  {x: 144.0000, y: 167.3333},
  {x: 144.0000, y: 167.6667},
  {x: 144.0000, y: 168.0000},
  {x: 145.6665, y: 168.0000},
  {x: 147.3335, y: 168.0000},
  {x: 149.0000, y: 168.0000},
  {x: 155.0873, y: 169.8954},
  {x: 160.0716, y: 171.8455},
  {x: 168.0000, y: 172.0000},
  {x: 168.3333, y: 171.0001},
  {x: 168.6667, y: 169.9999},
  {x: 169.0000, y: 169.0000},
  {x: 169.3333, y: 169.0000},
  {x: 169.6667, y: 169.0000},
  {x: 170.0000, y: 169.0000},
  {x: 170.0000, y: 168.3334},
  {x: 170.0000, y: 167.6666},
  {x: 170.0000, y: 167.0000},
  {x: 170.3333, y: 167.0000},
  {x: 170.6667, y: 167.0000},
  {x: 171.0000, y: 167.0000},
  {x: 171.0000, y: 166.3334},
  {x: 171.0000, y: 165.6666},
  {x: 171.0000, y: 165.0000},
  {x: 171.3333, y: 165.0000},
  {x: 171.6667, y: 165.0000},
  {x: 172.0000, y: 165.0000},
  {x: 172.0000, y: 164.3334},
  {x: 172.0000, y: 163.6666},
  {x: 172.0000, y: 163.0000},
  {x: 172.3333, y: 163.0000},
  {x: 172.6667, y: 163.0000},
  {x: 173.0000, y: 163.0000},
  {x: 173.3333, y: 161.3335},
  {x: 173.6667, y: 159.6665},
  {x: 174.0000, y: 158.0000},
  {x: 174.6666, y: 157.6667},
  {x: 175.3334, y: 157.3333},
  {x: 176.0000, y: 157.0000},
  {x: 176.0000, y: 156.3334},
  {x: 176.0000, y: 155.6666},
  {x: 176.0000, y: 155.0000},
  {x: 176.3333, y: 155.0000},
  {x: 176.6667, y: 155.0000},
  {x: 177.0000, y: 155.0000},
  {x: 177.6666, y: 153.0002},
  {x: 178.3334, y: 150.9998},
  {x: 179.0000, y: 149.0000},
  {x: 179.3333, y: 149.0000},
  {x: 179.6667, y: 149.0000},
  {x: 180.0000, y: 149.0000},
  {x: 180.3333, y: 148.0001},
  {x: 180.6667, y: 146.9999},
  {x: 181.0000, y: 146.0000},
  {x: 181.6666, y: 145.6667},
  {x: 182.3334, y: 145.3333},
  {x: 183.0000, y: 145.0000},
  {x: 183.3333, y: 143.6668},
  {x: 183.6667, y: 142.3332},
  {x: 184.0000, y: 141.0000},
  {x: 184.9999, y: 140.3334},
  {x: 186.0001, y: 139.6666},
  {x: 187.0000, y: 139.0000},
  {x: 187.0000, y: 138.3334},
  {x: 187.0000, y: 137.6666},
  {x: 187.0000, y: 137.0000},
  {x: 187.9999, y: 136.3334},
  {x: 189.0001, y: 135.6666},
  {x: 190.0000, y: 135.0000},
  {x: 190.0000, y: 134.3334},
  {x: 190.0000, y: 133.6666},
  {x: 190.0000, y: 133.0000},
  {x: 191.9998, y: 131.3335},
  {x: 194.0002, y: 129.6665},
  {x: 196.0000, y: 128.0000},
  {x: 196.0000, y: 127.3334},
  {x: 196.0000, y: 126.6666},
  {x: 196.0000, y: 126.0000},
  {x: 197.6665, y: 124.6668},
  {x: 199.3335, y: 123.3332},
  {x: 201.0000, y: 122.0000},
  {x: 201.3333, y: 121.3334},
  {x: 201.6667, y: 120.6666},
  {x: 202.0000, y: 120.0000},
  {x: 202.6666, y: 120.0000},
  {x: 203.3334, y: 120.0000},
  {x: 204.0000, y: 120.0000},
  {x: 205.6665, y: 118.0002},
  {x: 207.3335, y: 115.9998},
  {x: 209.0000, y: 114.0000},
  {x: 209.6666, y: 114.0000},
  {x: 210.3334, y: 114.0000},
  {x: 211.0000, y: 114.0000},
  {x: 211.6666, y: 113.0001},
  {x: 212.3334, y: 111.9999},
  {x: 213.0000, y: 111.0000},
  {x: 213.6666, y: 111.0000},
  {x: 214.3334, y: 111.0000},
  {x: 215.0000, y: 111.0000},
  {x: 215.3333, y: 110.3334},
  {x: 215.6667, y: 109.6666},
  {x: 216.0000, y: 109.0000},
  {x: 216.9999, y: 108.6667},
  {x: 218.0001, y: 108.3333},
  {x: 219.0000, y: 108.0000},
  {x: 219.0000, y: 107.6667},
  {x: 219.0000, y: 107.3333},
  {x: 219.0000, y: 107.0000},
  {x: 219.6666, y: 107.0000},
  {x: 220.3334, y: 107.0000},
  {x: 221.0000, y: 107.0000},
  {x: 221.6666, y: 106.0001},
  {x: 222.3334, y: 104.9999},
  {x: 223.0000, y: 104.0000},
  {x: 223.6666, y: 104.0000},
  {x: 224.3334, y: 104.0000},
  {x: 225.0000, y: 104.0000},
  {x: 227.9997, y: 100.6670},
  {x: 231.0003, y: 97.3330},
  {x: 234.0000, y: 94.0000},
  {x: 235.9998, y: 92.3335},
  {x: 238.0002, y: 90.6665},
  {x: 240.0000, y: 89.0000},
  {x: 240.0000, y: 88.3334},
  {x: 240.0000, y: 87.6666},
  {x: 240.0000, y: 87.0000},
  {x: 240.6666, y: 86.6667},
  {x: 241.3334, y: 86.3333},
  {x: 242.0000, y: 86.0000},
  {x: 242.0000, y: 85.3334},
  {x: 242.0000, y: 84.6666},
  {x: 242.0000, y: 84.0000},
  {x: 242.6666, y: 83.6667},
  {x: 243.3334, y: 83.3333},
  {x: 244.0000, y: 83.0000},
  {x: 244.3333, y: 81.6668},
  {x: 244.6667, y: 80.3332},
  {x: 245.0000, y: 79.0000},
  {x: 246.0448, y: 77.0704},
  {x: 247.9158, y: 74.9907},
  {x: 249.0000, y: 73.0000},
  {x: 244.3338, y: 71.3335},
  {x: 239.6662, y: 69.6665},
  {x: 235.0000, y: 68.0000},
  {x: 234.6667, y: 67.3334},
  {x: 234.3333, y: 66.6666},
  {x: 234.0000, y: 66.0000},
  {x: 232.6668, y: 65.6667},
  {x: 231.3332, y: 65.3333},
  {x: 230.0000, y: 65.0000},
  {x: 229.0001, y: 63.6668},
  {x: 227.9999, y: 62.3332},
  {x: 227.0000, y: 61.0000},
  {x: 225.6668, y: 60.6667},
  {x: 224.3332, y: 60.3333},
  {x: 223.0000, y: 60.0000},
  {x: 222.3334, y: 59.0001},
  {x: 221.6666, y: 57.9999},
  {x: 221.0000, y: 57.0000},
  {x: 220.3334, y: 57.0000},
  {x: 219.6666, y: 57.0000},
  {x: 219.0000, y: 57.0000},
  {x: 218.6667, y: 56.3334},
  {x: 218.3333, y: 55.6666},
  {x: 218.0000, y: 55.0000},
  {x: 217.3334, y: 55.0000},
  {x: 216.6666, y: 55.0000},
  {x: 216.0000, y: 55.0000},
  {x: 216.0000, y: 54.6667},
  {x: 216.0000, y: 54.3333},
  {x: 216.0000, y: 54.0000},
  {x: 215.3334, y: 54.0000},
  {x: 214.6666, y: 54.0000},
  {x: 214.0000, y: 54.0000},
  {x: 214.0000, y: 53.6667},
  {x: 214.0000, y: 53.3333},
  {x: 214.0000, y: 53.0000},
  {x: 213.0001, y: 53.0000},
  {x: 211.9999, y: 53.0000},
  {x: 211.0000, y: 53.0000},
  {x: 211.0000, y: 52.6667},
  {x: 211.0000, y: 52.3333},
  {x: 211.0000, y: 52.0000},
  {x: 210.3334, y: 52.0000},
  {x: 209.6666, y: 52.0000},
  {x: 209.0000, y: 52.0000},
  {x: 209.0000, y: 51.6667},
  {x: 209.0000, y: 51.3333},
  {x: 209.0000, y: 51.0000},
  {x: 207.0002, y: 50.3334},
  {x: 204.9998, y: 49.6666},
  {x: 203.0000, y: 49.0000},
  {x: 203.0000, y: 48.6667},
  {x: 203.0000, y: 48.3333},
  {x: 203.0000, y: 48.0000},
  {x: 201.6668, y: 48.0000},
  {x: 200.3332, y: 48.0000},
  {x: 199.0000, y: 48.0000},
  {x: 199.0000, y: 47.6667},
  {x: 199.0000, y: 47.3333},
  {x: 199.0000, y: 47.0000},
  {x: 197.6668, y: 47.0000},
  {x: 196.3332, y: 47.0000},
  {x: 195.0000, y: 47.0000},
  {x: 195.0000, y: 46.6667},
  {x: 195.0000, y: 46.3333},
  {x: 195.0000, y: 46.0000},
  {x: 193.6668, y: 46.0000},
  {x: 192.3332, y: 46.0000},
  {x: 191.0000, y: 46.0000},
  {x: 191.0000, y: 45.6667},
  {x: 191.0000, y: 45.3333},
  {x: 191.0000, y: 45.0000},
  {x: 190.3334, y: 45.0000},
  {x: 189.6666, y: 45.0000},
  {x: 189.0000, y: 45.0000},
  {x: 189.0000, y: 44.6667},
  {x: 189.0000, y: 44.3333},
  {x: 189.0000, y: 44.0000},
  {x: 188.0001, y: 44.0000},
  {x: 186.9999, y: 44.0000},
  {x: 186.0000, y: 44.0000},
  {x: 186.0000, y: 43.6667},
  {x: 186.0000, y: 43.3333},
  {x: 186.0000, y: 43.0000},
  {x: 185.3334, y: 43.0000},
  {x: 184.6666, y: 43.0000},
  {x: 184.0000, y: 43.0000},
  {x: 184.0000, y: 42.6667},
  {x: 184.0000, y: 42.3333},
  {x: 184.0000, y: 42.0000},
  {x: 183.3334, y: 42.0000},
  {x: 182.6666, y: 42.0000},
  {x: 182.0000, y: 42.0000},
  {x: 182.0000, y: 41.6667},
  {x: 182.0000, y: 41.3333},
  {x: 182.0000, y: 41.0000},
  {x: 181.3334, y: 41.0000},
  {x: 180.6666, y: 41.0000},
  {x: 180.0000, y: 41.0000},
  {x: 180.0000, y: 40.6667},
  {x: 180.0000, y: 40.3333},
  {x: 180.0000, y: 40.0000},
  {x: 179.3334, y: 40.0000},
  {x: 178.6666, y: 40.0000},
  {x: 178.0000, y: 40.0000},
  {x: 178.0000, y: 39.6667},
  {x: 178.0000, y: 39.3333},
  {x: 178.0000, y: 39.0000},
  {x: 177.3334, y: 39.0000},
  {x: 176.6666, y: 39.0000},
  {x: 176.0000, y: 39.0000},
  {x: 176.0000, y: 38.6667},
  {x: 176.0000, y: 38.3333},
  {x: 176.0000, y: 38.0000},
  {x: 174.0002, y: 37.3334},
  {x: 171.9998, y: 36.6666},
  {x: 170.0000, y: 36.0000},
  {x: 169.6667, y: 35.3334},
  {x: 169.3333, y: 34.6666},
  {x: 169.0000, y: 34.0000},
  {x: 168.3334, y: 34.0000},
  {x: 167.6666, y: 34.0000},
  {x: 167.0000, y: 34.0000},
  {x: 166.6667, y: 33.3334},
  {x: 166.3333, y: 32.6666},
  {x: 166.0000, y: 32.0000},
  {x: 165.3334, y: 32.0000},
  {x: 164.6666, y: 32.0000},
  {x: 164.0000, y: 32.0000},
  {x: 163.3334, y: 31.0001},
  {x: 162.6666, y: 29.9999},
  {x: 162.0000, y: 29.0000},
  {x: 161.3334, y: 29.0000},
  {x: 160.6666, y: 29.0000},
  {x: 160.0000, y: 29.0000},
  {x: 159.6667, y: 28.3334},
  {x: 159.3333, y: 27.6666},
  {x: 159.0000, y: 27.0000},
  {x: 158.3334, y: 27.0000},
  {x: 157.6666, y: 27.0000},
  {x: 157.0000, y: 27.0000},
  {x: 156.3334, y: 26.0001},
  {x: 155.6666, y: 24.9999},
  {x: 155.0000, y: 24.0000},
  {x: 154.0001, y: 23.6667},
  {x: 152.9999, y: 23.3333},
  {x: 152.0000, y: 23.0000},
  {x: 151.6667, y: 22.3334},
  {x: 151.3333, y: 21.6666},
  {x: 151.0000, y: 21.0000},
  {x: 149.6668, y: 20.6667},
  {x: 148.3332, y: 20.3333},
  {x: 147.0000, y: 20.0000},
  {x: 146.6667, y: 19.3334},
  {x: 146.3333, y: 18.6666},
  {x: 146.0000, y: 18.0000},
  {x: 145.0001, y: 17.6667},
  {x: 143.9999, y: 17.3333},
  {x: 143.0000, y: 17.0000},
  {x: 143.0000, y: 16.6667},
  {x: 143.0000, y: 16.3333},
  {x: 143.0000, y: 16.0000},
  {x: 141.6668, y: 15.6667},
  {x: 140.3332, y: 15.3333},
  {x: 139.0000, y: 15.0000},
  {x: 139.0000, y: 14.6667},
  {x: 139.0000, y: 14.3333},
  {x: 139.0000, y: 14.0000},
  {x: 138.3334, y: 14.0000},
  {x: 137.6666, y: 14.0000},
  {x: 137.0000, y: 14.0000},
  {x: 137.0000, y: 13.6667},
  {x: 137.0000, y: 13.3333},
  {x: 137.0000, y: 13.0000},
  {x: 136.3334, y: 13.0000},
  {x: 135.6666, y: 13.0000},
  {x: 135.0000, y: 13.0000},
  {x: 135.0000, y: 12.6667},
  {x: 135.0000, y: 12.3333},
  {x: 135.0000, y: 12.0000},
  {x: 134.3334, y: 12.0000},
  {x: 133.6666, y: 12.0000},
  {x: 133.0000, y: 12.0000},
  {x: 133.0000, y: 11.6667},
  {x: 133.0000, y: 11.3333},
  {x: 133.0000, y: 11.0000},
  {x: 131.6668, y: 10.6667},
  {x: 130.3332, y: 10.3333},
  {x: 129.0000, y: 10.0000},
  {x: 128.6667, y: 9.3334},
  {x: 128.3333, y: 8.6666},
  {x: 128.0000, y: 8.0000},
  {x: 126.6668, y: 7.6667},
  {x: 125.3332, y: 7.3333},
  {x: 124.0000, y: 7.0000},
  {x: 123.6667, y: 6.3334},
  {x: 123.3333, y: 5.6666},
  {x: 123.0000, y: 5.0000},
  {x: 121.6668, y: 4.6667},
  {x: 120.3332, y: 4.3333},
  {x: 119.0000, y: 4.0000},
  {x: 118.3334, y: 3.0001},
  {x: 117.6666, y: 1.9999},
  {x: 117.0000, y: 1.0000},
  {x: 116.6667, y: 1.0000},
  {x: 116.3333, y: 1.0000},
  {x: 116.0000, y: 1.0000},
  {x: 116.0000, y: 1.3333},
  {x: 116.0000, y: 1.6667},
  {x: 116.0000, y: 2.0000},
  {x: 113.1351, y: 4.5477},
  {x: 113.0083, y: 8.7981},
  {x: 111.0000, y: 12.0000},
  {x: 110.3334, y: 12.3333},
  {x: 109.6666, y: 12.6667},
  {x: 109.0000, y: 13.0000},
  {x: 109.0000, y: 13.6666},
  {x: 109.0000, y: 14.3334},
  {x: 109.0000, y: 15.0000},
  {x: 108.6667, y: 15.0000},
  {x: 108.3333, y: 15.0000},
  {x: 108.0000, y: 15.0000},
  {x: 108.0000, y: 15.6666},
  {x: 108.0000, y: 16.3334},
  {x: 108.0000, y: 17.0000},
  {x: 107.6667, y: 17.0000},
  {x: 107.3333, y: 17.0000},
  {x: 107.0000, y: 17.0000},
  {x: 107.0000, y: 17.6666},
  {x: 107.0000, y: 18.3334},
  {x: 107.0000, y: 19.0000},
  {x: 106.3334, y: 19.3333},
  {x: 105.6666, y: 19.6667},
  {x: 105.0000, y: 20.0000},
  {x: 105.0000, y: 20.6666},
  {x: 105.0000, y: 21.3334},
  {x: 105.0000, y: 22.0000},
  {x: 104.0001, y: 22.6666},
  {x: 102.9999, y: 23.3334},
  {x: 102.0000, y: 24.0000},
  {x: 101.6667, y: 25.3332},
  {x: 101.3333, y: 26.6668},
  {x: 101.0000, y: 28.0000},
  {x: 100.3334, y: 28.3333},
  {x: 99.6666, y: 28.6667},
  {x: 99.0000, y: 29.0000},
  {x: 99.0000, y: 29.6666},
  {x: 99.0000, y: 30.3334},
  {x: 99.0000, y: 31.0000},
  {x: 98.0001, y: 31.6666},
  {x: 96.9999, y: 32.3334},
  {x: 96.0000, y: 33.0000},
  {x: 96.0000, y: 33.6666},
  {x: 96.0000, y: 34.3334},
  {x: 96.0000, y: 35.0000},
  {x: 95.0001, y: 35.6666},
  {x: 93.9999, y: 36.3334},
  {x: 93.0000, y: 37.0000},
  {x: 93.0000, y: 37.6666},
  {x: 93.0000, y: 38.3334},
  {x: 93.0000, y: 39.0000},
  {x: 92.0001, y: 39.6666},
  {x: 90.9999, y: 40.3334},
  {x: 90.0000, y: 41.0000},
  {x: 90.0000, y: 41.6666},
  {x: 90.0000, y: 42.3334},
  {x: 90.0000, y: 43.0000},
  {x: 88.3335, y: 44.3332},
  {x: 86.6665, y: 45.6668},
  {x: 85.0000, y: 47.0000},
  {x: 85.0000, y: 47.6666},
  {x: 85.0000, y: 48.3334},
  {x: 85.0000, y: 49.0000},
  {x: 81.6670, y: 51.9997},
  {x: 78.3330, y: 55.0003},
  {x: 75.0000, y: 58.0000},
  {x: 73.3335, y: 59.9998},
  {x: 71.6665, y: 62.0002},
  {x: 70.0000, y: 64.0000},
  {x: 69.3334, y: 64.0000},
  {x: 68.6666, y: 64.0000},
  {x: 68.0000, y: 64.0000},
  {x: 67.0001, y: 65.3332},
  {x: 65.9999, y: 66.6668},
  {x: 65.0000, y: 68.0000},
  {x: 64.3334, y: 68.0000},
  {x: 63.6666, y: 68.0000},
  {x: 63.0000, y: 68.0000},
  {x: 62.3334, y: 68.9999},
  {x: 61.6666, y: 70.0001},
  {x: 61.0000, y: 71.0000},
  {x: 76.9691, y: 71.4277},
  {x: 101.2897, y: 78.2516},
  {x: 117.0000, y: 72.0000},
  {x: 117.0000, y: 71.6667},
  {x: 117.0000, y: 71.3333},
  {x: 117.0000, y: 71.0000},
  {x: 118.6665, y: 70.6667},
  {x: 120.3335, y: 70.3333},
  {x: 122.0000, y: 70.0000},
  {x: 122.3333, y: 69.3334},
  {x: 122.6667, y: 68.6666},
  {x: 123.0000, y: 68.0000},
  {x: 126.3826, y: 65.7641},
  {x: 128.7297, y: 66.6737},
  {x: 130.0000, y: 62.0000},
  {x: 132.5950, y: 59.7541},
  {x: 132.2296, y: 53.9747},
  {x: 132.0000, y: 49.0000},
  {x: 131.6031, y: 40.3992},
  {x: 127.6317, y: 34.5859},
  {x: 127.0000, y: 26.0000},
  {x: 128.1346, y: 25.1558},
  {x: 127.1454, y: 26.1268},
  {x: 128.0000, y: 25.0000},
  {x: 130.0098, y: 25.5736},
  {x: 128.8652, y: 24.8800},
  {x: 130.0000, y: 26.0000},
  {x: 130.9999, y: 26.3333},
  {x: 132.0001, y: 26.6667},
  {x: 133.0000, y: 27.0000},
  {x: 133.0000, y: 27.3333},
  {x: 133.0000, y: 27.6667},
  {x: 133.0000, y: 28.0000},
  {x: 133.6666, y: 28.0000},
  {x: 134.3334, y: 28.0000},
  {x: 135.0000, y: 28.0000},
  {x: 135.0000, y: 28.3333},
  {x: 135.0000, y: 28.6667},
  {x: 135.0000, y: 29.0000},
  {x: 135.6666, y: 29.0000},
  {x: 136.3334, y: 29.0000},
  {x: 137.0000, y: 29.0000},
  {x: 137.3333, y: 29.6666},
  {x: 137.6667, y: 30.3334},
  {x: 138.0000, y: 31.0000},
  {x: 139.3332, y: 31.3333},
  {x: 140.6668, y: 31.6667},
  {x: 142.0000, y: 32.0000},
  {x: 142.3333, y: 32.6666},
  {x: 142.6667, y: 33.3334},
  {x: 143.0000, y: 34.0000},
  {x: 144.3332, y: 34.3333},
  {x: 145.6668, y: 34.6667},
  {x: 147.0000, y: 35.0000},
  {x: 147.3333, y: 35.6666},
  {x: 147.6667, y: 36.3334},
  {x: 148.0000, y: 37.0000},
  {x: 148.6666, y: 37.0000},
  {x: 149.3334, y: 37.0000},
  {x: 150.0000, y: 37.0000},
  {x: 150.3333, y: 37.6666},
  {x: 150.6667, y: 38.3334},
  {x: 151.0000, y: 39.0000},
  {x: 152.3332, y: 39.3333},
  {x: 153.6668, y: 39.6667},
  {x: 155.0000, y: 40.0000},
  {x: 155.0000, y: 40.3333},
  {x: 155.0000, y: 40.6667},
  {x: 155.0000, y: 41.0000},
  {x: 155.9999, y: 41.3333},
  {x: 157.0001, y: 41.6667},
  {x: 158.0000, y: 42.0000},
  {x: 158.3333, y: 42.6666},
  {x: 158.6667, y: 43.3334},
  {x: 159.0000, y: 44.0000},
  {x: 159.6666, y: 44.0000},
  {x: 160.3334, y: 44.0000},
  {x: 161.0000, y: 44.0000},
  {x: 161.3333, y: 44.6666},
  {x: 161.6667, y: 45.3334},
  {x: 162.0000, y: 46.0000},
  {x: 162.6666, y: 46.0000},
  {x: 163.3334, y: 46.0000},
  {x: 164.0000, y: 46.0000},
  {x: 164.3333, y: 46.6666},
  {x: 164.6667, y: 47.3334},
  {x: 165.0000, y: 48.0000},
  {x: 165.6666, y: 48.0000},
  {x: 166.3334, y: 48.0000},
  {x: 167.0000, y: 48.0000},
  {x: 167.6666, y: 48.9999},
  {x: 168.3334, y: 50.0001},
  {x: 169.0000, y: 51.0000},
  {x: 169.6666, y: 51.0000},
  {x: 170.3334, y: 51.0000},
  {x: 171.0000, y: 51.0000},
  {x: 171.6666, y: 51.9999},
  {x: 172.3334, y: 53.0001},
  {x: 173.0000, y: 54.0000},
  {x: 173.6666, y: 54.0000},
  {x: 174.3334, y: 54.0000},
  {x: 175.0000, y: 54.0000},
  {x: 178.9996, y: 58.3329},
  {x: 183.0004, y: 62.6671},
  {x: 187.0000, y: 67.0000},
  {x: 187.0000, y: 67.6666},
  {x: 187.0000, y: 68.3334},
  {x: 187.0000, y: 69.0000},
  {x: 187.3333, y: 69.0000},
  {x: 187.6667, y: 69.0000},
  {x: 188.0000, y: 69.0000},
  {x: 188.0000, y: 69.6666},
  {x: 188.0000, y: 70.3334},
  {x: 188.0000, y: 71.0000},
  {x: 188.3333, y: 71.0000},
  {x: 188.6667, y: 71.0000},
  {x: 189.0000, y: 71.0000},
  {x: 189.0000, y: 71.9999},
  {x: 189.0000, y: 73.0001},
  {x: 189.0000, y: 74.0000},
  {x: 189.3333, y: 74.0000},
  {x: 189.6667, y: 74.0000},
  {x: 190.0000, y: 74.0000},
  {x: 190.0000, y: 75.3332},
  {x: 190.0000, y: 76.6668},
  {x: 190.0000, y: 78.0000},
  {x: 190.3333, y: 78.0000},
  {x: 190.6667, y: 78.0000},
  {x: 191.0000, y: 78.0000},
  {x: 190.6667, y: 83.3328},
  {x: 190.3333, y: 88.6672},
  {x: 190.0000, y: 94.0000},
  {x: 189.6667, y: 94.0000},
  {x: 189.3333, y: 94.0000},
  {x: 189.0000, y: 94.0000},
  {x: 188.6667, y: 95.3332},
  {x: 188.3333, y: 96.6668},
  {x: 188.0000, y: 98.0000},
  {x: 187.6667, y: 98.0000},
  {x: 187.3333, y: 98.0000},
  {x: 187.0000, y: 98.0000},
  {x: 187.0000, y: 98.6666},
  {x: 187.0000, y: 99.3334},
  {x: 187.0000, y: 100.0000},
  {x: 186.3334, y: 100.3333},
  {x: 185.6666, y: 100.6667},
  {x: 185.0000, y: 101.0000},
  {x: 185.0000, y: 101.6666},
  {x: 185.0000, y: 102.3334},
  {x: 185.0000, y: 103.0000},
  {x: 184.3334, y: 103.3333},
  {x: 183.6666, y: 103.6667},
  {x: 183.0000, y: 104.0000},
  {x: 182.0001, y: 105.3332},
  {x: 180.9999, y: 106.6668},
  {x: 180.0000, y: 108.0000},
  {x: 179.3334, y: 108.0000},
  {x: 178.6666, y: 108.0000},
  {x: 178.0000, y: 108.0000},
  {x: 177.6667, y: 108.6666},
  {x: 177.3333, y: 109.3334},
  {x: 177.0000, y: 110.0000},
  {x: 176.3334, y: 110.0000},
  {x: 175.6666, y: 110.0000},
  {x: 175.0000, y: 110.0000},
  {x: 174.6667, y: 110.6666},
  {x: 174.3333, y: 111.3334},
  {x: 174.0000, y: 112.0000},
  {x: 173.3334, y: 112.0000},
  {x: 172.6666, y: 112.0000},
  {x: 172.0000, y: 112.0000},
  {x: 172.0000, y: 112.3333},
  {x: 172.0000, y: 112.6667},
  {x: 172.0000, y: 113.0000},
  {x: 171.3334, y: 113.0000},
  {x: 170.6666, y: 113.0000},
  {x: 170.0000, y: 113.0000},
  {x: 170.0000, y: 113.3333},
  {x: 170.0000, y: 113.6667},
  {x: 170.0000, y: 114.0000},
  {x: 169.0001, y: 114.0000},
  {x: 167.9999, y: 114.0000},
  {x: 167.0000, y: 114.0000},
  {x: 167.0000, y: 114.3333},
  {x: 167.0000, y: 114.6667},
  {x: 167.0000, y: 115.0000},
  {x: 166.0001, y: 115.0000},
  {x: 164.9999, y: 115.0000},
  {x: 164.0000, y: 115.0000},
  {x: 164.0000, y: 115.3333},
  {x: 164.0000, y: 115.6667},
  {x: 164.0000, y: 116.0000},
  {x: 163.0001, y: 116.0000},
  {x: 161.9999, y: 116.0000},
  {x: 161.0000, y: 116.0000},
  {x: 161.0000, y: 116.3333},
  {x: 161.0000, y: 116.6667},
  {x: 161.0000, y: 117.0000},
  {x: 159.6668, y: 117.0000},
  {x: 158.3332, y: 117.0000},
  {x: 157.0000, y: 117.0000},
  {x: 157.0000, y: 117.3333},
  {x: 157.0000, y: 117.6667},
  {x: 157.0000, y: 118.0000},
  {x: 153.6670, y: 118.0000},
  {x: 150.3330, y: 118.0000},
  {x: 147.0000, y: 118.0000},
  {x: 147.0000, y: 118.3333},
  {x: 147.0000, y: 118.6667},
  {x: 147.0000, y: 119.0000},
  {x: 138.6675, y: 118.6667},
  {x: 130.3325, y: 118.3333},
  {x: 122.0000, y: 118.0000},
  {x: 122.0000, y: 117.6667},
  {x: 122.0000, y: 117.3333},
  {x: 122.0000, y: 117.0000},
  {x: 119.0003, y: 117.0000},
  {x: 115.9997, y: 117.0000},
  {x: 113.0000, y: 117.0000},
  {x: 113.0000, y: 116.6667},
  {x: 113.0000, y: 116.3333},
  {x: 113.0000, y: 116.0000},
  {x: 110.6669, y: 116.0000},
  {x: 108.3331, y: 116.0000},
  {x: 106.0000, y: 116.0000},
  {x: 101.3030, y: 114.6139},
  {x: 94.5572, y: 113.3750},
  {x: 90.0000, y: 112.0000},
  {x: 88.0002, y: 112.0000},
  {x: 85.9998, y: 112.0000},
  {x: 84.0000, y: 112.0000},
  {x: 84.0000, y: 111.6667},
  {x: 84.0000, y: 111.3333},
  {x: 84.0000, y: 111.0000},
  {x: 82.3335, y: 111.0000},
  {x: 80.6665, y: 111.0000},
  {x: 79.0000, y: 111.0000},
  {x: 79.0000, y: 110.6667},
  {x: 79.0000, y: 110.3333},
  {x: 79.0000, y: 110.0000},
  {x: 77.3335, y: 110.0000},
  {x: 75.6665, y: 110.0000},
  {x: 74.0000, y: 110.0000},
  {x: 74.0000, y: 109.6667},
  {x: 74.0000, y: 109.3333},
  {x: 74.0000, y: 109.0000},
  {x: 72.3335, y: 109.0000},
  {x: 70.6665, y: 109.0000},
  {x: 69.0000, y: 109.0000},
  {x: 69.0000, y: 108.6667},
  {x: 69.0000, y: 108.3333},
  {x: 69.0000, y: 108.0000},
  {x: 67.6668, y: 108.0000},
  {x: 66.3332, y: 108.0000},
  {x: 65.0000, y: 108.0000},
  {x: 65.0000, y: 107.6667},
  {x: 65.0000, y: 107.3333},
  {x: 65.0000, y: 107.0000},
  {x: 63.6668, y: 107.0000},
  {x: 62.3332, y: 107.0000},
  {x: 61.0000, y: 107.0000},
  {x: 61.0000, y: 106.6667},
  {x: 61.0000, y: 106.3333},
  {x: 61.0000, y: 106.0000},
  {x: 59.3335, y: 106.0000},
  {x: 57.6665, y: 106.0000},
  {x: 56.0000, y: 106.0000},
  {x: 48.7921, y: 103.6777},
  {x: 39.6550, y: 100.5547},
  {x: 31.0000, y: 100.0000}
];
