module.exports = [
  {x:2514.0000,y:608.0000},
  {x:2514.0000,y:609.3332},
  {x:2514.0000,y:610.6668},
  {x:2514.0000,y:612.0000},
  {x:2511.3969,y:612.6340},
  {x:2510.7342,y:613.5885},
  {x:2509.0000,y:615.0000},
  {x:2509.0000,y:615.3333},
  {x:2509.0000,y:615.6667},
  {x:2509.0000,y:616.0000},
  {x:2508.3334,y:616.0000},
  {x:2507.6666,y:616.0000},
  {x:2507.0000,y:616.0000},
  {x:2506.6667,y:616.6666},
  {x:2506.3333,y:617.3334},
  {x:2506.0000,y:618.0000},
  {x:2505.3334,y:618.0000},
  {x:2504.6666,y:618.0000},
  {x:2504.0000,y:618.0000},
  {x:2503.6667,y:618.6666},
  {x:2503.3333,y:619.3334},
  {x:2503.0000,y:620.0000},
  {x:2500.0003,y:620.9999},
  {x:2496.9997,y:622.0001},
  {x:2494.0000,y:623.0000},
  {x:2494.0000,y:623.3333},
  {x:2494.0000,y:623.6667},
  {x:2494.0000,y:624.0000},
  {x:2493.3334,y:624.0000},
  {x:2492.6666,y:624.0000},
  {x:2492.0000,y:624.0000},
  {x:2492.0000,y:624.3333},
  {x:2492.0000,y:624.6667},
  {x:2492.0000,y:625.0000},
  {x:2491.0001,y:625.0000},
  {x:2489.9999,y:625.0000},
  {x:2489.0000,y:625.0000},
  {x:2489.0000,y:625.3333},
  {x:2489.0000,y:625.6667},
  {x:2489.0000,y:626.0000},
  {x:2488.3334,y:626.0000},
  {x:2487.6666,y:626.0000},
  {x:2487.0000,y:626.0000},
  {x:2487.0000,y:626.3333},
  {x:2487.0000,y:626.6667},
  {x:2487.0000,y:627.0000},
  {x:2485.6668,y:627.0000},
  {x:2484.3332,y:627.0000},
  {x:2483.0000,y:627.0000},
  {x:2483.0000,y:627.3333},
  {x:2483.0000,y:627.6667},
  {x:2483.0000,y:628.0000},
  {x:2482.0001,y:628.0000},
  {x:2480.9999,y:628.0000},
  {x:2480.0000,y:628.0000},
  {x:2480.0000,y:628.3333},
  {x:2480.0000,y:628.6667},
  {x:2480.0000,y:629.0000},
  {x:2479.0001,y:629.0000},
  {x:2477.9999,y:629.0000},
  {x:2477.0000,y:629.0000},
  {x:2477.0000,y:629.3333},
  {x:2477.0000,y:629.6667},
  {x:2477.0000,y:630.0000},
  {x:2474.3336,y:630.3333},
  {x:2471.6664,y:630.6667},
  {x:2469.0000,y:631.0000},
  {x:2469.0000,y:631.3333},
  {x:2469.0000,y:631.6667},
  {x:2469.0000,y:632.0000},
  {x:2466.3336,y:632.3333},
  {x:2463.6664,y:632.6667},
  {x:2461.0000,y:633.0000},
  {x:2461.0000,y:633.3333},
  {x:2461.0000,y:633.6667},
  {x:2461.0000,y:634.0000},
  {x:2459.3335,y:634.0000},
  {x:2457.6665,y:634.0000},
  {x:2456.0000,y:634.0000},
  {x:2450.0807,y:635.8711},
  {x:2441.9420,y:637.1916},
  {x:2436.0000,y:639.0000},
  {x:2377.3392,y:648.3324},
  {x:2318.6608,y:657.6676},
  {x:2260.0000,y:667.0000},
  {x:2257.3336,y:667.0000},
  {x:2254.6664,y:667.0000},
  {x:2252.0000,y:667.0000},
  {x:2239.4075,y:670.6401},
  {x:2222.6881,y:669.3748},
  {x:2210.0000,y:673.0000},
  {x:2200.3343,y:673.6666},
  {x:2190.6657,y:674.3334},
  {x:2181.0000,y:675.0000},
  {x:2181.0000,y:675.3333},
  {x:2181.0000,y:675.6667},
  {x:2181.0000,y:676.0000},
  {x:2177.6670,y:676.0000},
  {x:2174.3330,y:676.0000},
  {x:2171.0000,y:676.0000},
  {x:2171.0000,y:676.3333},
  {x:2171.0000,y:676.6667},
  {x:2171.0000,y:677.0000},
  {x:2167.6670,y:677.0000},
  {x:2164.3330,y:677.0000},
  {x:2161.0000,y:677.0000},
  {x:2161.0000,y:677.3333},
  {x:2161.0000,y:677.6667},
  {x:2161.0000,y:678.0000},
  {x:2157.0004,y:678.0000},
  {x:2152.9996,y:678.0000},
  {x:2149.0000,y:678.0000},
  {x:2149.0000,y:678.3333},
  {x:2149.0000,y:678.6667},
  {x:2149.0000,y:679.0000},
  {x:2145.3337,y:679.0000},
  {x:2141.6663,y:679.0000},
  {x:2138.0000,y:679.0000},
  {x:2138.0000,y:679.3333},
  {x:2138.0000,y:679.6667},
  {x:2138.0000,y:680.0000},
  {x:2134.0004,y:680.0000},
  {x:2129.9996,y:680.0000},
  {x:2126.0000,y:680.0000},
  {x:2126.0000,y:680.3333},
  {x:2126.0000,y:680.6667},
  {x:2126.0000,y:681.0000},
  {x:2121.6671,y:681.0000},
  {x:2117.3329,y:681.0000},
  {x:2113.0000,y:681.0000},
  {x:2113.0000,y:681.3333},
  {x:2113.0000,y:681.6667},
  {x:2113.0000,y:682.0000},
  {x:2109.6670,y:682.0000},
  {x:2106.3330,y:682.0000},
  {x:2103.0000,y:682.0000},
  {x:2102.0001,y:682.0000},
  {x:2100.9999,y:682.0000},
  {x:2100.0000,y:682.0000},
  {x:2100.0000,y:682.3333},
  {x:2100.0000,y:682.6667},
  {x:2100.0000,y:683.0000},
  {x:2095.0005,y:683.0000},
  {x:2089.9995,y:683.0000},
  {x:2085.0000,y:683.0000},
  {x:2085.0000,y:683.3333},
  {x:2085.0000,y:683.6667},
  {x:2085.0000,y:684.0000},
  {x:2081.3337,y:684.0000},
  {x:2077.6663,y:684.0000},
  {x:2074.0000,y:684.0000},
  {x:2066.6674,y:684.3333},
  {x:2059.3326,y:684.6667},
  {x:2052.0000,y:685.0000},
  {x:2052.0000,y:685.3333},
  {x:2052.0000,y:685.6667},
  {x:2052.0000,y:686.0000},
  {x:2038.0014,y:686.3333},
  {x:2023.9986,y:686.6667},
  {x:2010.0000,y:687.0000},
  {x:2010.0000,y:687.3333},
  {x:2010.0000,y:687.6667},
  {x:2010.0000,y:688.0000},
  {x:2000.3343,y:688.0000},
  {x:1990.6657,y:688.0000},
  {x:1981.0000,y:688.0000},
  {x:1981.0000,y:688.3333},
  {x:1981.0000,y:688.6667},
  {x:1981.0000,y:689.0000},
  {x:1964.6683,y:689.0000},
  {x:1948.3317,y:689.0000},
  {x:1932.0000,y:689.0000},
  {x:1920.6678,y:689.3333},
  {x:1909.3322,y:689.6667},
  {x:1898.0000,y:690.0000},
  {x:1898.0000,y:689.6667},
  {x:1898.0000,y:689.3333},
  {x:1898.0000,y:689.0000},
  {x:1892.0006,y:689.0000},
  {x:1885.9994,y:689.0000},
  {x:1880.0000,y:689.0000},
  {x:1870.7978,y:689.0011},
  {x:1858.4919,y:690.1118},
  {x:1851.0000,y:688.0000},
  {x:1845.3339,y:688.0000},
  {x:1839.6661,y:688.0000},
  {x:1834.0000,y:688.0000},
  {x:1834.0000,y:687.6667},
  {x:1834.0000,y:687.3333},
  {x:1834.0000,y:687.0000},
  {x:1828.3339,y:687.0000},
  {x:1822.6661,y:687.0000},
  {x:1817.0000,y:687.0000},
  {x:1817.0000,y:686.6667},
  {x:1817.0000,y:686.3333},
  {x:1817.0000,y:686.0000},
  {x:1810.0007,y:685.6667},
  {x:1802.9993,y:685.3333},
  {x:1796.0000,y:685.0000},
  {x:1792.6670,y:685.0000},
  {x:1789.3330,y:685.0000},
  {x:1786.0000,y:685.0000},
  {x:1786.0000,y:684.6667},
  {x:1786.0000,y:684.3333},
  {x:1786.0000,y:684.0000},
  {x:1764.3355,y:682.6668},
  {x:1742.6645,y:681.3332},
  {x:1721.0000,y:680.0000},
  {x:1721.0000,y:679.6667},
  {x:1721.0000,y:679.3333},
  {x:1721.0000,y:679.0000},
  {x:1717.6670,y:679.0000},
  {x:1714.3330,y:679.0000},
  {x:1711.0000,y:679.0000},
  {x:1699.8687,y:675.8036},
  {x:1684.9256,y:677.1482},
  {x:1674.0000,y:674.0000},
  {x:1671.3336,y:674.0000},
  {x:1668.6664,y:674.0000},
  {x:1666.0000,y:674.0000},
  {x:1666.0000,y:673.6667},
  {x:1666.0000,y:673.3333},
  {x:1666.0000,y:673.0000},
  {x:1663.6669,y:673.0000},
  {x:1661.3331,y:673.0000},
  {x:1659.0000,y:673.0000},
  {x:1659.0000,y:672.6667},
  {x:1659.0000,y:672.3333},
  {x:1659.0000,y:672.0000},
  {x:1656.6669,y:672.0000},
  {x:1654.3331,y:672.0000},
  {x:1652.0000,y:672.0000},
  {x:1652.0000,y:671.6667},
  {x:1652.0000,y:671.3333},
  {x:1652.0000,y:671.0000},
  {x:1650.0002,y:671.0000},
  {x:1647.9998,y:671.0000},
  {x:1646.0000,y:671.0000},
  {x:1646.0000,y:670.6667},
  {x:1646.0000,y:670.3333},
  {x:1646.0000,y:670.0000},
  {x:1644.0002,y:670.0000},
  {x:1641.9998,y:670.0000},
  {x:1640.0000,y:670.0000},
  {x:1640.0000,y:669.6667},
  {x:1640.0000,y:669.3333},
  {x:1640.0000,y:669.0000},
  {x:1638.3335,y:669.0000},
  {x:1636.6665,y:669.0000},
  {x:1635.0000,y:669.0000},
  {x:1635.0000,y:668.6667},
  {x:1635.0000,y:668.3333},
  {x:1635.0000,y:668.0000},
  {x:1631.3337,y:667.6667},
  {x:1627.6663,y:667.3333},
  {x:1624.0000,y:667.0000},
  {x:1624.0000,y:666.6667},
  {x:1624.0000,y:666.3333},
  {x:1624.0000,y:666.0000},
  {x:1621.0003,y:665.6667},
  {x:1617.9997,y:665.3333},
  {x:1615.0000,y:665.0000},
  {x:1615.0000,y:664.6667},
  {x:1615.0000,y:664.3333},
  {x:1615.0000,y:664.0000},
  {x:1613.6668,y:664.0000},
  {x:1612.3332,y:664.0000},
  {x:1611.0000,y:664.0000},
  {x:1611.0000,y:663.6667},
  {x:1611.0000,y:663.3333},
  {x:1611.0000,y:663.0000},
  {x:1609.6668,y:663.0000},
  {x:1608.3332,y:663.0000},
  {x:1607.0000,y:663.0000},
  {x:1607.0000,y:662.6667},
  {x:1607.0000,y:662.3333},
  {x:1607.0000,y:662.0000},
  {x:1606.0001,y:662.0000},
  {x:1604.9999,y:662.0000},
  {x:1604.0000,y:662.0000},
  {x:1604.0000,y:661.6667},
  {x:1604.0000,y:661.3333},
  {x:1604.0000,y:661.0000},
  {x:1602.6668,y:661.0000},
  {x:1601.3332,y:661.0000},
  {x:1600.0000,y:661.0000},
  {x:1600.0000,y:660.6667},
  {x:1600.0000,y:660.3333},
  {x:1600.0000,y:660.0000},
  {x:1599.0001,y:660.0000},
  {x:1597.9999,y:660.0000},
  {x:1597.0000,y:660.0000},
  {x:1597.0000,y:659.6667},
  {x:1597.0000,y:659.3333},
  {x:1597.0000,y:659.0000},
  {x:1596.0001,y:659.0000},
  {x:1594.9999,y:659.0000},
  {x:1594.0000,y:659.0000},
  {x:1594.0000,y:658.6667},
  {x:1594.0000,y:658.3333},
  {x:1594.0000,y:658.0000},
  {x:1593.0001,y:658.0000},
  {x:1591.9999,y:658.0000},
  {x:1591.0000,y:658.0000},
  {x:1591.0000,y:657.6667},
  {x:1591.0000,y:657.3333},
  {x:1591.0000,y:657.0000},
  {x:1590.0001,y:657.0000},
  {x:1588.9999,y:657.0000},
  {x:1588.0000,y:657.0000},
  {x:1588.0000,y:656.6667},
  {x:1588.0000,y:656.3333},
  {x:1588.0000,y:656.0000},
  {x:1586.0002,y:655.6667},
  {x:1583.9998,y:655.3333},
  {x:1582.0000,y:655.0000},
  {x:1582.0000,y:654.6667},
  {x:1582.0000,y:654.3333},
  {x:1582.0000,y:654.0000},
  {x:1581.3334,y:654.0000},
  {x:1580.6666,y:654.0000},
  {x:1580.0000,y:654.0000},
  {x:1580.0000,y:653.6667},
  {x:1580.0000,y:653.3333},
  {x:1580.0000,y:653.0000},
  {x:1579.0001,y:653.0000},
  {x:1577.9999,y:653.0000},
  {x:1577.0000,y:653.0000},
  {x:1577.0000,y:652.6667},
  {x:1577.0000,y:652.3333},
  {x:1577.0000,y:652.0000},
  {x:1575.6668,y:651.6667},
  {x:1574.3332,y:651.3333},
  {x:1573.0000,y:651.0000},
  {x:1573.0000,y:650.6667},
  {x:1573.0000,y:650.3333},
  {x:1573.0000,y:650.0000},
  {x:1572.0001,y:650.0000},
  {x:1570.9999,y:650.0000},
  {x:1570.0000,y:650.0000},
  {x:1570.0000,y:649.6667},
  {x:1570.0000,y:649.3333},
  {x:1570.0000,y:649.0000},
  {x:1568.6668,y:648.6667},
  {x:1567.3332,y:648.3333},
  {x:1566.0000,y:648.0000},
  {x:1566.0000,y:647.6667},
  {x:1566.0000,y:647.3333},
  {x:1566.0000,y:647.0000},
  {x:1564.6668,y:646.6667},
  {x:1563.3332,y:646.3333},
  {x:1562.0000,y:646.0000},
  {x:1561.6667,y:645.3334},
  {x:1561.3333,y:644.6666},
  {x:1561.0000,y:644.0000},
  {x:1559.6668,y:643.6667},
  {x:1558.3332,y:643.3333},
  {x:1557.0000,y:643.0000},
  {x:1556.6667,y:642.3334},
  {x:1556.3333,y:641.6666},
  {x:1556.0000,y:641.0000},
  {x:1554.6668,y:640.6667},
  {x:1553.3332,y:640.3333},
  {x:1552.0000,y:640.0000},
  {x:1551.6667,y:639.3334},
  {x:1551.3333,y:638.6666},
  {x:1551.0000,y:638.0000},
  {x:1550.3334,y:638.0000},
  {x:1549.6666,y:638.0000},
  {x:1549.0000,y:638.0000},
  {x:1548.3334,y:637.0001},
  {x:1547.6666,y:635.9999},
  {x:1547.0000,y:635.0000},
  {x:1546.3334,y:635.0000},
  {x:1545.6666,y:635.0000},
  {x:1545.0000,y:635.0000},
  {x:1544.3334,y:634.0001},
  {x:1543.6666,y:632.9999},
  {x:1543.0000,y:632.0000},
  {x:1542.3334,y:632.0000},
  {x:1541.6666,y:632.0000},
  {x:1541.0000,y:632.0000},
  {x:1540.3334,y:631.0001},
  {x:1539.6666,y:629.9999},
  {x:1539.0000,y:629.0000},
  {x:1538.3334,y:629.0000},
  {x:1537.6666,y:629.0000},
  {x:1537.0000,y:629.0000},
  {x:1536.6667,y:628.3334},
  {x:1536.3333,y:627.6666},
  {x:1536.0000,y:627.0000},
  {x:1534.6668,y:626.0001},
  {x:1533.3332,y:624.9999},
  {x:1532.0000,y:624.0000},
  {x:1532.0000,y:623.3334},
  {x:1532.0000,y:622.6666},
  {x:1532.0000,y:622.0000},
  {x:1531.0001,y:621.3334},
  {x:1529.9999,y:620.6666},
  {x:1529.0000,y:620.0000},
  {x:1528.6667,y:618.6668},
  {x:1528.3333,y:617.3332},
  {x:1528.0000,y:616.0000},
  {x:1527.6667,y:616.0000},
  {x:1527.3333,y:616.0000},
  {x:1527.0000,y:616.0000},
  {x:1527.0000,y:615.3334},
  {x:1527.0000,y:614.6666},
  {x:1527.0000,y:614.0000},
  {x:1526.6667,y:614.0000},
  {x:1526.3333,y:614.0000},
  {x:1526.0000,y:614.0000},
  {x:1525.3334,y:612.0002},
  {x:1524.6666,y:609.9998},
  {x:1524.0000,y:608.0000},
  {x:1523.6667,y:608.0000},
  {x:1523.3333,y:608.0000},
  {x:1523.0000,y:608.0000},
  {x:1523.0000,y:607.0001},
  {x:1523.0000,y:605.9999},
  {x:1523.0000,y:605.0000},
  {x:1522.6667,y:605.0000},
  {x:1522.3333,y:605.0000},
  {x:1522.0000,y:605.0000},
  {x:1522.0000,y:604.0001},
  {x:1522.0000,y:602.9999},
  {x:1522.0000,y:602.0000},
  {x:1521.6667,y:602.0000},
  {x:1521.3333,y:602.0000},
  {x:1521.0000,y:602.0000},
  {x:1521.0000,y:601.0001},
  {x:1521.0000,y:599.9999},
  {x:1521.0000,y:599.0000},
  {x:1520.6667,y:599.0000},
  {x:1520.3333,y:599.0000},
  {x:1520.0000,y:599.0000},
  {x:1520.0000,y:597.6668},
  {x:1520.0000,y:596.3332},
  {x:1520.0000,y:595.0000},
  {x:1519.6667,y:595.0000},
  {x:1519.3333,y:595.0000},
  {x:1519.0000,y:595.0000},
  {x:1519.0000,y:593.6668},
  {x:1519.0000,y:592.3332},
  {x:1519.0000,y:591.0000},
  {x:1518.6667,y:591.0000},
  {x:1518.3333,y:591.0000},
  {x:1518.0000,y:591.0000},
  {x:1518.0000,y:589.3335},
  {x:1518.0000,y:587.6665},
  {x:1518.0000,y:586.0000},
  {x:1517.6667,y:586.0000},
  {x:1517.3333,y:586.0000},
  {x:1517.0000,y:586.0000},
  {x:1517.0000,y:584.0002},
  {x:1517.0000,y:581.9998},
  {x:1517.0000,y:580.0000},
  {x:1516.6667,y:580.0000},
  {x:1516.3333,y:580.0000},
  {x:1516.0000,y:580.0000},
  {x:1515.6667,y:572.3341},
  {x:1515.3333,y:564.6659},
  {x:1515.0000,y:557.0000},
  {x:1514.2548,y:552.5007},
  {x:1512.3598,y:538.7980},
  {x:1514.0000,y:533.0000},
  {x:1514.3333,y:533.0000},
  {x:1514.6667,y:533.0000},
  {x:1515.0000,y:533.0000},
  {x:1515.3333,y:522.6677},
  {x:1515.6667,y:512.3323},
  {x:1516.0000,y:502.0000},
  {x:1516.3333,y:502.0000},
  {x:1516.6667,y:502.0000},
  {x:1517.0000,y:502.0000},
  {x:1517.0000,y:499.0003},
  {x:1517.0000,y:495.9997},
  {x:1517.0000,y:493.0000},
  {x:1517.3333,y:493.0000},
  {x:1517.6667,y:493.0000},
  {x:1518.0000,y:493.0000},
  {x:1518.0000,y:490.0003},
  {x:1518.0000,y:486.9997},
  {x:1518.0000,y:484.0000},
  {x:1518.3333,y:484.0000},
  {x:1518.6667,y:484.0000},
  {x:1519.0000,y:484.0000},
  {x:1519.9999,y:475.6675},
  {x:1521.0001,y:467.3325},
  {x:1522.0000,y:459.0000},
  {x:1525.1596,y:459.7629},
  {x:1525.6505,y:461.2268},
  {x:1529.0000,y:462.0000},
  {x:1529.0000,y:461.6667},
  {x:1529.0000,y:461.3333},
  {x:1529.0000,y:461.0000},
  {x:1524.4299,y:458.6921},
  {x:1521.6379,y:454.1156},
  {x:1517.0000,y:452.0000},
  {x:1516.0001,y:452.0000},
  {x:1514.9999,y:452.0000},
  {x:1514.0000,y:452.0000},
  {x:1514.0000,y:451.6667},
  {x:1514.0000,y:451.3333},
  {x:1514.0000,y:451.0000},
  {x:1512.6668,y:450.6667},
  {x:1511.3332,y:450.3333},
  {x:1510.0000,y:450.0000},
  {x:1510.0000,y:449.6667},
  {x:1510.0000,y:449.3333},
  {x:1510.0000,y:449.0000},
  {x:1508.3335,y:448.6667},
  {x:1506.6665,y:448.3333},
  {x:1505.0000,y:448.0000},
  {x:1505.0000,y:447.6667},
  {x:1505.0000,y:447.3333},
  {x:1505.0000,y:447.0000},
  {x:1504.0001,y:447.0000},
  {x:1502.9999,y:447.0000},
  {x:1502.0000,y:447.0000},
  {x:1502.0000,y:446.6667},
  {x:1502.0000,y:446.3333},
  {x:1502.0000,y:446.0000},
  {x:1501.3334,y:446.0000},
  {x:1500.6666,y:446.0000},
  {x:1500.0000,y:446.0000},
  {x:1500.0000,y:445.6667},
  {x:1500.0000,y:445.3333},
  {x:1500.0000,y:445.0000},
  {x:1499.0001,y:445.0000},
  {x:1497.9999,y:445.0000},
  {x:1497.0000,y:445.0000},
  {x:1497.0000,y:444.6667},
  {x:1497.0000,y:444.3333},
  {x:1497.0000,y:444.0000},
  {x:1496.3334,y:444.0000},
  {x:1495.6666,y:444.0000},
  {x:1495.0000,y:444.0000},
  {x:1495.0000,y:443.6667},
  {x:1495.0000,y:443.3333},
  {x:1495.0000,y:443.0000},
  {x:1494.0001,y:443.0000},
  {x:1492.9999,y:443.0000},
  {x:1492.0000,y:443.0000},
  {x:1492.0000,y:442.6667},
  {x:1492.0000,y:442.3333},
  {x:1492.0000,y:442.0000},
  {x:1491.3334,y:442.0000},
  {x:1490.6666,y:442.0000},
  {x:1490.0000,y:442.0000},
  {x:1490.0000,y:441.6667},
  {x:1490.0000,y:441.3333},
  {x:1490.0000,y:441.0000},
  {x:1488.3335,y:440.6667},
  {x:1486.6665,y:440.3333},
  {x:1485.0000,y:440.0000},
  {x:1485.0000,y:439.6667},
  {x:1485.0000,y:439.3333},
  {x:1485.0000,y:439.0000},
  {x:1483.0002,y:438.6667},
  {x:1480.9998,y:438.3333},
  {x:1479.0000,y:438.0000},
  {x:1457.1492,y:428.4156},
  {x:1432.1549,y:421.3743},
  {x:1409.0000,y:413.0000},
  {x:1407.6668,y:413.0000},
  {x:1406.3332,y:413.0000},
  {x:1405.0000,y:413.0000},
  {x:1405.0000,y:412.6667},
  {x:1405.0000,y:412.3333},
  {x:1405.0000,y:412.0000},
  {x:1404.0001,y:412.0000},
  {x:1402.9999,y:412.0000},
  {x:1402.0000,y:412.0000},
  {x:1402.0000,y:411.6667},
  {x:1402.0000,y:411.3333},
  {x:1402.0000,y:411.0000},
  {x:1401.0001,y:411.0000},
  {x:1399.9999,y:411.0000},
  {x:1399.0000,y:411.0000},
  {x:1399.0000,y:410.6667},
  {x:1399.0000,y:410.3333},
  {x:1399.0000,y:410.0000},
  {x:1396.6669,y:409.6667},
  {x:1394.3331,y:409.3333},
  {x:1392.0000,y:409.0000},
  {x:1392.0000,y:408.6667},
  {x:1392.0000,y:408.3333},
  {x:1392.0000,y:408.0000},
  {x:1389.6669,y:407.6667},
  {x:1387.3331,y:407.3333},
  {x:1385.0000,y:407.0000},
  {x:1385.0000,y:406.6667},
  {x:1385.0000,y:406.3333},
  {x:1385.0000,y:406.0000},
  {x:1382.6669,y:405.6667},
  {x:1380.3331,y:405.3333},
  {x:1378.0000,y:405.0000},
  {x:1378.0000,y:404.6667},
  {x:1378.0000,y:404.3333},
  {x:1378.0000,y:404.0000},
  {x:1374.3337,y:403.3334},
  {x:1370.6663,y:402.6666},
  {x:1367.0000,y:402.0000},
  {x:1367.0000,y:401.6667},
  {x:1367.0000,y:401.3333},
  {x:1367.0000,y:401.0000},
  {x:1363.3337,y:400.3334},
  {x:1359.6663,y:399.6666},
  {x:1356.0000,y:399.0000},
  {x:1356.0000,y:398.6667},
  {x:1356.0000,y:398.3333},
  {x:1356.0000,y:398.0000},
  {x:1354.6668,y:398.0000},
  {x:1353.3332,y:398.0000},
  {x:1352.0000,y:398.0000},
  {x:1346.1701,y:395.9918},
  {x:1338.8361,y:393.9886},
  {x:1333.0000,y:392.0000},
  {x:1330.3336,y:391.6667},
  {x:1327.6664,y:391.3333},
  {x:1325.0000,y:391.0000},
  {x:1310.3878,y:385.9797},
  {x:1292.8864,y:382.8041},
  {x:1278.0000,y:378.0000},
  {x:1276.3335,y:378.0000},
  {x:1274.6665,y:378.0000},
  {x:1273.0000,y:378.0000},
  {x:1273.0000,y:377.6667},
  {x:1273.0000,y:377.3333},
  {x:1273.0000,y:377.0000},
  {x:1271.6668,y:377.0000},
  {x:1270.3332,y:377.0000},
  {x:1269.0000,y:377.0000},
  {x:1269.0000,y:376.6667},
  {x:1269.0000,y:376.3333},
  {x:1269.0000,y:376.0000},
  {x:1267.3335,y:376.0000},
  {x:1265.6665,y:376.0000},
  {x:1264.0000,y:376.0000},
  {x:1264.0000,y:375.6667},
  {x:1264.0000,y:375.3333},
  {x:1264.0000,y:375.0000},
  {x:1262.6668,y:374.6667},
  {x:1261.3332,y:374.3333},
  {x:1260.0000,y:374.0000},
  {x:1260.0000,y:373.6667},
  {x:1260.0000,y:373.3333},
  {x:1260.0000,y:373.0000},
  {x:1259.0001,y:373.0000},
  {x:1257.9999,y:373.0000},
  {x:1257.0000,y:373.0000},
  {x:1257.0000,y:372.6667},
  {x:1257.0000,y:372.3333},
  {x:1257.0000,y:372.0000},
  {x:1256.3334,y:372.0000},
  {x:1255.6666,y:372.0000},
  {x:1255.0000,y:372.0000},
  {x:1255.0000,y:371.6667},
  {x:1255.0000,y:371.3333},
  {x:1255.0000,y:371.0000},
  {x:1253.3335,y:370.6667},
  {x:1251.6665,y:370.3333},
  {x:1250.0000,y:370.0000},
  {x:1250.0000,y:369.6667},
  {x:1250.0000,y:369.3333},
  {x:1250.0000,y:369.0000},
  {x:1248.0002,y:368.6667},
  {x:1245.9998,y:368.3333},
  {x:1244.0000,y:368.0000},
  {x:1244.0000,y:367.6667},
  {x:1244.0000,y:367.3333},
  {x:1244.0000,y:367.0000},
  {x:1243.3334,y:367.0000},
  {x:1242.6666,y:367.0000},
  {x:1242.0000,y:367.0000},
  {x:1242.0000,y:366.6667},
  {x:1242.0000,y:366.3333},
  {x:1242.0000,y:366.0000},
  {x:1239.0003,y:365.3334},
  {x:1235.9997,y:364.6666},
  {x:1233.0000,y:364.0000},
  {x:1233.0000,y:363.6667},
  {x:1233.0000,y:363.3333},
  {x:1233.0000,y:363.0000},
  {x:1232.3334,y:363.0000},
  {x:1231.6666,y:363.0000},
  {x:1231.0000,y:363.0000},
  {x:1231.0000,y:362.6667},
  {x:1231.0000,y:362.3333},
  {x:1231.0000,y:362.0000},
  {x:1230.0001,y:362.0000},
  {x:1228.9999,y:362.0000},
  {x:1228.0000,y:362.0000},
  {x:1228.0000,y:361.6667},
  {x:1228.0000,y:361.3333},
  {x:1228.0000,y:361.0000},
  {x:1227.3334,y:361.0000},
  {x:1226.6666,y:361.0000},
  {x:1226.0000,y:361.0000},
  {x:1226.0000,y:360.6667},
  {x:1226.0000,y:360.3333},
  {x:1226.0000,y:360.0000},
  {x:1225.0001,y:360.0000},
  {x:1223.9999,y:360.0000},
  {x:1223.0000,y:360.0000},
  {x:1223.0000,y:359.6667},
  {x:1223.0000,y:359.3333},
  {x:1223.0000,y:359.0000},
  {x:1222.3334,y:359.0000},
  {x:1221.6666,y:359.0000},
  {x:1221.0000,y:359.0000},
  {x:1221.0000,y:358.6667},
  {x:1221.0000,y:358.3333},
  {x:1221.0000,y:358.0000},
  {x:1219.6668,y:357.6667},
  {x:1218.3332,y:357.3333},
  {x:1217.0000,y:357.0000},
  {x:1217.0000,y:356.6667},
  {x:1217.0000,y:356.3333},
  {x:1217.0000,y:356.0000},
  {x:1216.3334,y:356.0000},
  {x:1215.6666,y:356.0000},
  {x:1215.0000,y:356.0000},
  {x:1215.0000,y:355.6667},
  {x:1215.0000,y:355.3333},
  {x:1215.0000,y:355.0000},
  {x:1214.3334,y:355.0000},
  {x:1213.6666,y:355.0000},
  {x:1213.0000,y:355.0000},
  {x:1213.0000,y:354.6667},
  {x:1213.0000,y:354.3333},
  {x:1213.0000,y:354.0000},
  {x:1212.3334,y:354.0000},
  {x:1211.6666,y:354.0000},
  {x:1211.0000,y:354.0000},
  {x:1211.0000,y:353.6667},
  {x:1211.0000,y:353.3333},
  {x:1211.0000,y:353.0000},
  {x:1210.3334,y:353.0000},
  {x:1209.6666,y:353.0000},
  {x:1209.0000,y:353.0000},
  {x:1209.0000,y:352.6667},
  {x:1209.0000,y:352.3333},
  {x:1209.0000,y:352.0000},
  {x:1208.3334,y:352.0000},
  {x:1207.6666,y:352.0000},
  {x:1207.0000,y:352.0000},
  {x:1207.0000,y:351.6667},
  {x:1207.0000,y:351.3333},
  {x:1207.0000,y:351.0000},
  {x:1206.3334,y:351.0000},
  {x:1205.6666,y:351.0000},
  {x:1205.0000,y:351.0000},
  {x:1205.0000,y:350.6667},
  {x:1205.0000,y:350.3333},
  {x:1205.0000,y:350.0000},
  {x:1204.3334,y:350.0000},
  {x:1203.6666,y:350.0000},
  {x:1203.0000,y:350.0000},
  {x:1203.0000,y:349.6667},
  {x:1203.0000,y:349.3333},
  {x:1203.0000,y:349.0000},
  {x:1202.3334,y:349.0000},
  {x:1201.6666,y:349.0000},
  {x:1201.0000,y:349.0000},
  {x:1201.0000,y:348.6667},
  {x:1201.0000,y:348.3333},
  {x:1201.0000,y:348.0000},
  {x:1200.3334,y:348.0000},
  {x:1199.6666,y:348.0000},
  {x:1199.0000,y:348.0000},
  {x:1198.6667,y:347.3334},
  {x:1198.3333,y:346.6666},
  {x:1198.0000,y:346.0000},
  {x:1196.6668,y:345.6667},
  {x:1195.3332,y:345.3333},
  {x:1194.0000,y:345.0000},
  {x:1193.6667,y:344.3334},
  {x:1193.3333,y:343.6666},
  {x:1193.0000,y:343.0000},
  {x:1191.6668,y:342.6667},
  {x:1190.3332,y:342.3333},
  {x:1189.0000,y:342.0000},
  {x:1188.6667,y:341.3334},
  {x:1188.3333,y:340.6666},
  {x:1188.0000,y:340.0000},
  {x:1186.6668,y:339.6667},
  {x:1185.3332,y:339.3333},
  {x:1184.0000,y:339.0000},
  {x:1184.0000,y:338.6667},
  {x:1184.0000,y:338.3333},
  {x:1184.0000,y:338.0000},
  {x:1183.0001,y:337.6667},
  {x:1181.9999,y:337.3333},
  {x:1181.0000,y:337.0000},
  {x:1180.6667,y:336.3334},
  {x:1180.3333,y:335.6666},
  {x:1180.0000,y:335.0000},
  {x:1178.6668,y:334.6667},
  {x:1177.3332,y:334.3333},
  {x:1176.0000,y:334.0000},
  {x:1175.6667,y:333.3334},
  {x:1175.3333,y:332.6666},
  {x:1175.0000,y:332.0000},
  {x:1174.3334,y:332.0000},
  {x:1173.6666,y:332.0000},
  {x:1173.0000,y:332.0000},
  {x:1172.6667,y:331.3334},
  {x:1172.3333,y:330.6666},
  {x:1172.0000,y:330.0000},
  {x:1171.0001,y:329.6667},
  {x:1169.9999,y:329.3333},
  {x:1169.0000,y:329.0000},
  {x:1167.3335,y:327.0002},
  {x:1165.6665,y:324.9998},
  {x:1164.0000,y:323.0000},
  {x:1163.0001,y:322.6667},
  {x:1161.9999,y:322.3333},
  {x:1161.0000,y:322.0000},
  {x:1159.6668,y:320.3335},
  {x:1158.3332,y:318.6665},
  {x:1157.0000,y:317.0000},
  {x:1156.3334,y:317.0000},
  {x:1155.6666,y:317.0000},
  {x:1155.0000,y:317.0000},
  {x:1154.6667,y:316.3334},
  {x:1154.3333,y:315.6666},
  {x:1154.0000,y:315.0000},
  {x:1152.3335,y:314.6667},
  {x:1150.6665,y:314.3333},
  {x:1149.0000,y:314.0000},
  {x:1149.0000,y:313.6667},
  {x:1149.0000,y:313.3333},
  {x:1149.0000,y:313.0000},
  {x:1148.3334,y:313.0000},
  {x:1147.6666,y:313.0000},
  {x:1147.0000,y:313.0000},
  {x:1147.0000,y:312.6667},
  {x:1147.0000,y:312.3333},
  {x:1147.0000,y:312.0000},
  {x:1146.0001,y:312.0000},
  {x:1144.9999,y:312.0000},
  {x:1144.0000,y:312.0000},
  {x:1143.6667,y:311.3334},
  {x:1143.3333,y:310.6666},
  {x:1143.0000,y:310.0000},
  {x:1142.3334,y:310.0000},
  {x:1141.6666,y:310.0000},
  {x:1141.0000,y:310.0000},
  {x:1140.6667,y:309.3334},
  {x:1140.3333,y:308.6666},
  {x:1140.0000,y:308.0000},
  {x:1139.3334,y:308.0000},
  {x:1138.6666,y:308.0000},
  {x:1138.0000,y:308.0000},
  {x:1137.0001,y:306.6668},
  {x:1135.9999,y:305.3332},
  {x:1135.0000,y:304.0000},
  {x:1134.3334,y:304.0000},
  {x:1133.6666,y:304.0000},
  {x:1133.0000,y:304.0000},
  {x:1132.0001,y:302.6668},
  {x:1130.9999,y:301.3332},
  {x:1130.0000,y:300.0000},
  {x:1129.3334,y:300.0000},
  {x:1128.6666,y:300.0000},
  {x:1128.0000,y:300.0000},
  {x:1127.0001,y:298.6668},
  {x:1125.9999,y:297.3332},
  {x:1125.0000,y:296.0000},
  {x:1124.3334,y:296.0000},
  {x:1123.6666,y:296.0000},
  {x:1123.0000,y:296.0000},
  {x:1122.0001,y:294.6668},
  {x:1120.9999,y:293.3332},
  {x:1120.0000,y:292.0000},
  {x:1119.3334,y:292.0000},
  {x:1118.6666,y:292.0000},
  {x:1118.0000,y:292.0000},
  {x:1117.3334,y:291.0001},
  {x:1116.6666,y:289.9999},
  {x:1116.0000,y:289.0000},
  {x:1115.3334,y:289.0000},
  {x:1114.6666,y:289.0000},
  {x:1114.0000,y:289.0000},
  {x:1113.3334,y:288.0001},
  {x:1112.6666,y:286.9999},
  {x:1112.0000,y:286.0000},
  {x:1110.6668,y:285.6667},
  {x:1109.3332,y:285.3333},
  {x:1108.0000,y:285.0000},
  {x:1107.6667,y:284.3334},
  {x:1107.3333,y:283.6666},
  {x:1107.0000,y:283.0000},
  {x:1106.3334,y:283.0000},
  {x:1105.6666,y:283.0000},
  {x:1105.0000,y:283.0000},
  {x:1105.0000,y:282.6667},
  {x:1105.0000,y:282.3333},
  {x:1105.0000,y:282.0000},
  {x:1104.0001,y:282.0000},
  {x:1102.9999,y:282.0000},
  {x:1102.0000,y:282.0000},
  {x:1102.0000,y:281.6667},
  {x:1102.0000,y:281.3333},
  {x:1102.0000,y:281.0000},
  {x:1101.3334,y:281.0000},
  {x:1100.6666,y:281.0000},
  {x:1100.0000,y:281.0000},
  {x:1100.0000,y:280.6667},
  {x:1100.0000,y:280.3333},
  {x:1100.0000,y:280.0000},
  {x:1097.0003,y:279.3334},
  {x:1093.9997,y:278.6666},
  {x:1091.0000,y:278.0000},
  {x:1091.0000,y:277.6667},
  {x:1091.0000,y:277.3333},
  {x:1091.0000,y:277.0000},
  {x:1090.3334,y:277.0000},
  {x:1089.6666,y:277.0000},
  {x:1089.0000,y:277.0000},
  {x:1089.0000,y:276.6667},
  {x:1089.0000,y:276.3333},
  {x:1089.0000,y:276.0000},
  {x:1088.0001,y:276.0000},
  {x:1086.9999,y:276.0000},
  {x:1086.0000,y:276.0000},
  {x:1086.0000,y:275.6667},
  {x:1086.0000,y:275.3333},
  {x:1086.0000,y:275.0000},
  {x:1084.6668,y:274.6667},
  {x:1083.3332,y:274.3333},
  {x:1082.0000,y:274.0000},
  {x:1082.0000,y:273.6667},
  {x:1082.0000,y:273.3333},
  {x:1082.0000,y:273.0000},
  {x:1081.0001,y:273.0000},
  {x:1079.9999,y:273.0000},
  {x:1079.0000,y:273.0000},
  {x:1079.0000,y:272.6667},
  {x:1079.0000,y:272.3333},
  {x:1079.0000,y:272.0000},
  {x:1077.0002,y:271.3334},
  {x:1074.9998,y:270.6666},
  {x:1073.0000,y:270.0000},
  {x:1073.0000,y:269.6667},
  {x:1073.0000,y:269.3333},
  {x:1073.0000,y:269.0000},
  {x:1072.3334,y:269.0000},
  {x:1071.6666,y:269.0000},
  {x:1071.0000,y:269.0000},
  {x:1071.0000,y:268.6667},
  {x:1071.0000,y:268.3333},
  {x:1071.0000,y:268.0000},
  {x:1070.3334,y:268.0000},
  {x:1069.6666,y:268.0000},
  {x:1069.0000,y:268.0000},
  {x:1069.0000,y:267.6667},
  {x:1069.0000,y:267.3333},
  {x:1069.0000,y:267.0000},
  {x:1068.0001,y:267.0000},
  {x:1066.9999,y:267.0000},
  {x:1066.0000,y:267.0000},
  {x:1066.0000,y:266.6667},
  {x:1066.0000,y:266.3333},
  {x:1066.0000,y:266.0000},
  {x:1065.3334,y:266.0000},
  {x:1064.6666,y:266.0000},
  {x:1064.0000,y:266.0000},
  {x:1063.6667,y:265.3334},
  {x:1063.3333,y:264.6666},
  {x:1063.0000,y:264.0000},
  {x:1062.3334,y:264.0000},
  {x:1061.6666,y:264.0000},
  {x:1061.0000,y:264.0000},
  {x:1061.0000,y:263.6667},
  {x:1061.0000,y:263.3333},
  {x:1061.0000,y:263.0000},
  {x:1060.3334,y:263.0000},
  {x:1059.6666,y:263.0000},
  {x:1059.0000,y:263.0000},
  {x:1059.0000,y:262.6667},
  {x:1059.0000,y:262.3333},
  {x:1059.0000,y:262.0000},
  {x:1058.3334,y:262.0000},
  {x:1057.6666,y:262.0000},
  {x:1057.0000,y:262.0000},
  {x:1057.0000,y:261.6667},
  {x:1057.0000,y:261.3333},
  {x:1057.0000,y:261.0000},
  {x:1056.3334,y:261.0000},
  {x:1055.6666,y:261.0000},
  {x:1055.0000,y:261.0000},
  {x:1055.0000,y:260.6667},
  {x:1055.0000,y:260.3333},
  {x:1055.0000,y:260.0000},
  {x:1054.3334,y:260.0000},
  {x:1053.6666,y:260.0000},
  {x:1053.0000,y:260.0000},
  {x:1053.0000,y:259.6667},
  {x:1053.0000,y:259.3333},
  {x:1053.0000,y:259.0000},
  {x:1052.3334,y:259.0000},
  {x:1051.6666,y:259.0000},
  {x:1051.0000,y:259.0000},
  {x:1051.0000,y:258.6667},
  {x:1051.0000,y:258.3333},
  {x:1051.0000,y:258.0000},
  {x:1050.3334,y:258.0000},
  {x:1049.6666,y:258.0000},
  {x:1049.0000,y:258.0000},
  {x:1049.0000,y:257.6667},
  {x:1049.0000,y:257.3333},
  {x:1049.0000,y:257.0000},
  {x:1048.0001,y:257.0000},
  {x:1046.9999,y:257.0000},
  {x:1046.0000,y:257.0000},
  {x:1046.0000,y:256.6667},
  {x:1046.0000,y:256.3333},
  {x:1046.0000,y:256.0000},
  {x:1045.3334,y:256.0000},
  {x:1044.6666,y:256.0000},
  {x:1044.0000,y:256.0000},
  {x:1044.0000,y:255.6667},
  {x:1044.0000,y:255.3333},
  {x:1044.0000,y:255.0000},
  {x:1043.0001,y:255.0000},
  {x:1041.9999,y:255.0000},
  {x:1041.0000,y:255.0000},
  {x:1041.0000,y:254.6667},
  {x:1041.0000,y:254.3333},
  {x:1041.0000,y:254.0000},
  {x:1040.3334,y:254.0000},
  {x:1039.6666,y:254.0000},
  {x:1039.0000,y:254.0000},
  {x:1039.0000,y:253.6667},
  {x:1039.0000,y:253.3333},
  {x:1039.0000,y:253.0000},
  {x:1038.0001,y:253.0000},
  {x:1036.9999,y:253.0000},
  {x:1036.0000,y:253.0000},
  {x:1036.0000,y:252.6667},
  {x:1036.0000,y:252.3333},
  {x:1036.0000,y:252.0000},
  {x:1035.3334,y:252.0000},
  {x:1034.6666,y:252.0000},
  {x:1034.0000,y:252.0000},
  {x:1034.0000,y:251.6667},
  {x:1034.0000,y:251.3333},
  {x:1034.0000,y:251.0000},
  {x:1033.0001,y:251.0000},
  {x:1031.9999,y:251.0000},
  {x:1031.0000,y:251.0000},
  {x:1031.0000,y:250.6667},
  {x:1031.0000,y:250.3333},
  {x:1031.0000,y:250.0000},
  {x:1030.0001,y:250.0000},
  {x:1028.9999,y:250.0000},
  {x:1028.0000,y:250.0000},
  {x:1028.0000,y:249.6667},
  {x:1028.0000,y:249.3333},
  {x:1028.0000,y:249.0000},
  {x:1027.0001,y:249.0000},
  {x:1025.9999,y:249.0000},
  {x:1025.0000,y:249.0000},
  {x:1025.0000,y:248.6667},
  {x:1025.0000,y:248.3333},
  {x:1025.0000,y:248.0000},
  {x:1023.6668,y:248.0000},
  {x:1022.3332,y:248.0000},
  {x:1021.0000,y:248.0000},
  {x:1021.0000,y:247.6667},
  {x:1021.0000,y:247.3333},
  {x:1021.0000,y:247.0000},
  {x:1020.0001,y:247.0000},
  {x:1018.9999,y:247.0000},
  {x:1018.0000,y:247.0000},
  {x:1018.0000,y:246.6667},
  {x:1018.0000,y:246.3333},
  {x:1018.0000,y:246.0000},
  {x:1015.6669,y:245.6667},
  {x:1013.3331,y:245.3333},
  {x:1011.0000,y:245.0000},
  {x:1011.0000,y:244.6667},
  {x:1011.0000,y:244.3333},
  {x:1011.0000,y:244.0000},
  {x:1008.3336,y:243.6667},
  {x:1005.6664,y:243.3333},
  {x:1003.0000,y:243.0000},
  {x:1003.0000,y:242.6667},
  {x:1003.0000,y:242.3333},
  {x:1003.0000,y:242.0000},
  {x:998.0005,y:241.3334},
  {x:992.9995,y:240.6666},
  {x:988.0000,y:240.0000},
  {x:988.0000,y:239.6667},
  {x:988.0000,y:239.3333},
  {x:988.0000,y:239.0000},
  {x:984.3337,y:238.6667},
  {x:980.6663,y:238.3333},
  {x:977.0000,y:238.0000},
  {x:977.0000,y:237.6667},
  {x:977.0000,y:237.3333},
  {x:977.0000,y:237.0000},
  {x:976.0001,y:237.0000},
  {x:974.9999,y:237.0000},
  {x:974.0000,y:237.0000},
  {x:974.0000,y:236.6667},
  {x:974.0000,y:236.3333},
  {x:974.0000,y:236.0000},
  {x:972.3335,y:235.6667},
  {x:970.6665,y:235.3333},
  {x:969.0000,y:235.0000},
  {x:969.0000,y:234.6667},
  {x:969.0000,y:234.3333},
  {x:969.0000,y:234.0000},
  {x:968.3334,y:234.0000},
  {x:967.6666,y:234.0000},
  {x:967.0000,y:234.0000},
  {x:967.0000,y:233.6667},
  {x:967.0000,y:233.3333},
  {x:967.0000,y:233.0000},
  {x:965.3335,y:232.6667},
  {x:963.6665,y:232.3333},
  {x:962.0000,y:232.0000},
  {x:962.0000,y:231.6667},
  {x:962.0000,y:231.3333},
  {x:962.0000,y:231.0000},
  {x:961.3334,y:231.0000},
  {x:960.6666,y:231.0000},
  {x:960.0000,y:231.0000},
  {x:960.0000,y:230.6667},
  {x:960.0000,y:230.3333},
  {x:960.0000,y:230.0000},
  {x:959.0001,y:230.0000},
  {x:957.9999,y:230.0000},
  {x:957.0000,y:230.0000},
  {x:957.0000,y:229.6667},
  {x:957.0000,y:229.3333},
  {x:957.0000,y:229.0000},
  {x:956.3334,y:229.0000},
  {x:955.6666,y:229.0000},
  {x:955.0000,y:229.0000},
  {x:955.0000,y:228.6667},
  {x:955.0000,y:228.3333},
  {x:955.0000,y:228.0000},
  {x:953.0002,y:227.3334},
  {x:950.9998,y:226.6666},
  {x:949.0000,y:226.0000},
  {x:949.0000,y:225.6667},
  {x:949.0000,y:225.3333},
  {x:949.0000,y:225.0000},
  {x:948.0001,y:225.0000},
  {x:946.9999,y:225.0000},
  {x:946.0000,y:225.0000},
  {x:946.0000,y:224.6667},
  {x:946.0000,y:224.3333},
  {x:946.0000,y:224.0000},
  {x:945.3334,y:224.0000},
  {x:944.6666,y:224.0000},
  {x:944.0000,y:224.0000},
  {x:944.0000,y:223.6667},
  {x:944.0000,y:223.3333},
  {x:944.0000,y:223.0000},
  {x:942.0002,y:222.3334},
  {x:939.9998,y:221.6666},
  {x:938.0000,y:221.0000},
  {x:938.0000,y:220.6667},
  {x:938.0000,y:220.3333},
  {x:938.0000,y:220.0000},
  {x:937.3334,y:220.0000},
  {x:936.6666,y:220.0000},
  {x:936.0000,y:220.0000},
  {x:936.0000,y:219.6667},
  {x:936.0000,y:219.3333},
  {x:936.0000,y:219.0000},
  {x:933.0003,y:218.0001},
  {x:929.9997,y:216.9999},
  {x:927.0000,y:216.0000},
  {x:927.0000,y:215.6667},
  {x:927.0000,y:215.3333},
  {x:927.0000,y:215.0000},
  {x:926.3334,y:215.0000},
  {x:925.6666,y:215.0000},
  {x:925.0000,y:215.0000},
  {x:925.0000,y:214.6667},
  {x:925.0000,y:214.3333},
  {x:925.0000,y:214.0000},
  {x:923.0002,y:213.3334},
  {x:920.9998,y:212.6666},
  {x:919.0000,y:212.0000},
  {x:919.0000,y:211.6667},
  {x:919.0000,y:211.3333},
  {x:919.0000,y:211.0000},
  {x:918.0001,y:211.0000},
  {x:916.9999,y:211.0000},
  {x:916.0000,y:211.0000},
  {x:916.0000,y:210.6667},
  {x:916.0000,y:210.3333},
  {x:916.0000,y:210.0000},
  {x:915.3334,y:210.0000},
  {x:914.6666,y:210.0000},
  {x:914.0000,y:210.0000},
  {x:914.0000,y:209.6667},
  {x:914.0000,y:209.3333},
  {x:914.0000,y:209.0000},
  {x:912.6668,y:208.6667},
  {x:911.3332,y:208.3333},
  {x:910.0000,y:208.0000},
  {x:910.0000,y:207.6667},
  {x:910.0000,y:207.3333},
  {x:910.0000,y:207.0000},
  {x:909.3334,y:207.0000},
  {x:908.6666,y:207.0000},
  {x:908.0000,y:207.0000},
  {x:908.0000,y:206.6667},
  {x:908.0000,y:206.3333},
  {x:908.0000,y:206.0000},
  {x:907.3334,y:206.0000},
  {x:906.6666,y:206.0000},
  {x:906.0000,y:206.0000},
  {x:906.0000,y:205.6667},
  {x:906.0000,y:205.3333},
  {x:906.0000,y:205.0000},
  {x:905.3334,y:205.0000},
  {x:904.6666,y:205.0000},
  {x:904.0000,y:205.0000},
  {x:904.0000,y:204.6667},
  {x:904.0000,y:204.3333},
  {x:904.0000,y:204.0000},
  {x:903.3334,y:204.0000},
  {x:902.6666,y:204.0000},
  {x:902.0000,y:204.0000},
  {x:902.0000,y:203.6667},
  {x:902.0000,y:203.3333},
  {x:902.0000,y:203.0000},
  {x:901.3334,y:203.0000},
  {x:900.6666,y:203.0000},
  {x:900.0000,y:203.0000},
  {x:900.0000,y:202.6667},
  {x:900.0000,y:202.3333},
  {x:900.0000,y:202.0000},
  {x:898.6668,y:201.6667},
  {x:897.3332,y:201.3333},
  {x:896.0000,y:201.0000},
  {x:896.0000,y:200.6667},
  {x:896.0000,y:200.3333},
  {x:896.0000,y:200.0000},
  {x:894.3335,y:199.6667},
  {x:892.6665,y:199.3333},
  {x:891.0000,y:199.0000},
  {x:891.0000,y:198.6667},
  {x:891.0000,y:198.3333},
  {x:891.0000,y:198.0000},
  {x:890.3334,y:198.0000},
  {x:889.6666,y:198.0000},
  {x:889.0000,y:198.0000},
  {x:888.6667,y:197.3334},
  {x:888.3333,y:196.6666},
  {x:888.0000,y:196.0000},
  {x:887.3334,y:196.0000},
  {x:886.6666,y:196.0000},
  {x:886.0000,y:196.0000},
  {x:886.0000,y:195.6667},
  {x:886.0000,y:195.3333},
  {x:886.0000,y:195.0000},
  {x:885.3334,y:195.0000},
  {x:884.6666,y:195.0000},
  {x:884.0000,y:195.0000},
  {x:884.0000,y:194.6667},
  {x:884.0000,y:194.3333},
  {x:884.0000,y:194.0000},
  {x:883.3334,y:194.0000},
  {x:882.6666,y:194.0000},
  {x:882.0000,y:194.0000},
  {x:882.0000,y:193.6667},
  {x:882.0000,y:193.3333},
  {x:882.0000,y:193.0000},
  {x:881.3334,y:193.0000},
  {x:880.6666,y:193.0000},
  {x:880.0000,y:193.0000},
  {x:879.6667,y:192.3334},
  {x:879.3333,y:191.6666},
  {x:879.0000,y:191.0000},
  {x:878.3334,y:191.0000},
  {x:877.6666,y:191.0000},
  {x:877.0000,y:191.0000},
  {x:877.0000,y:190.6667},
  {x:877.0000,y:190.3333},
  {x:877.0000,y:190.0000},
  {x:876.3334,y:190.0000},
  {x:875.6666,y:190.0000},
  {x:875.0000,y:190.0000},
  {x:874.6667,y:189.3334},
  {x:874.3333,y:188.6666},
  {x:874.0000,y:188.0000},
  {x:872.6668,y:187.6667},
  {x:871.3332,y:187.3333},
  {x:870.0000,y:187.0000},
  {x:869.6667,y:186.3334},
  {x:869.3333,y:185.6666},
  {x:869.0000,y:185.0000},
  {x:868.3334,y:185.0000},
  {x:867.6666,y:185.0000},
  {x:867.0000,y:185.0000},
  {x:866.6667,y:184.3334},
  {x:866.3333,y:183.6666},
  {x:866.0000,y:183.0000},
  {x:865.3334,y:183.0000},
  {x:864.6666,y:183.0000},
  {x:864.0000,y:183.0000},
  {x:863.6667,y:182.3334},
  {x:863.3333,y:181.6666},
  {x:863.0000,y:181.0000},
  {x:862.3334,y:181.0000},
  {x:861.6666,y:181.0000},
  {x:861.0000,y:181.0000},
  {x:861.0000,y:180.6667},
  {x:861.0000,y:180.3333},
  {x:861.0000,y:180.0000},
  {x:860.0001,y:179.6667},
  {x:858.9999,y:179.3333},
  {x:858.0000,y:179.0000},
  {x:857.6667,y:178.3334},
  {x:857.3333,y:177.6666},
  {x:857.0000,y:177.0000},
  {x:856.3334,y:177.0000},
  {x:855.6666,y:177.0000},
  {x:855.0000,y:177.0000},
  {x:854.6667,y:176.3334},
  {x:854.3333,y:175.6666},
  {x:854.0000,y:175.0000},
  {x:853.3334,y:175.0000},
  {x:852.6666,y:175.0000},
  {x:852.0000,y:175.0000},
  {x:851.6667,y:174.3334},
  {x:851.3333,y:173.6666},
  {x:851.0000,y:173.0000},
  {x:850.3334,y:173.0000},
  {x:849.6666,y:173.0000},
  {x:849.0000,y:173.0000},
  {x:849.0000,y:172.6667},
  {x:849.0000,y:172.3333},
  {x:849.0000,y:172.0000},
  {x:848.0001,y:171.6667},
  {x:846.9999,y:171.3333},
  {x:846.0000,y:171.0000},
  {x:845.6667,y:170.3334},
  {x:845.3333,y:169.6666},
  {x:845.0000,y:169.0000},
  {x:844.3334,y:169.0000},
  {x:843.6666,y:169.0000},
  {x:843.0000,y:169.0000},
  {x:842.3334,y:168.0001},
  {x:841.6666,y:166.9999},
  {x:841.0000,y:166.0000},
  {x:840.3334,y:166.0000},
  {x:839.6666,y:166.0000},
  {x:839.0000,y:166.0000},
  {x:839.0000,y:165.6667},
  {x:839.0000,y:165.3333},
  {x:839.0000,y:165.0000},
  {x:838.0001,y:164.6667},
  {x:836.9999,y:164.3333},
  {x:836.0000,y:164.0000},
  {x:835.6667,y:163.3334},
  {x:835.3333,y:162.6666},
  {x:835.0000,y:162.0000},
  {x:834.3334,y:162.0000},
  {x:833.6666,y:162.0000},
  {x:833.0000,y:162.0000},
  {x:832.3334,y:161.0001},
  {x:831.6666,y:159.9999},
  {x:831.0000,y:159.0000},
  {x:830.3334,y:159.0000},
  {x:829.6666,y:159.0000},
  {x:829.0000,y:159.0000},
  {x:828.6667,y:158.3334},
  {x:828.3333,y:157.6666},
  {x:828.0000,y:157.0000},
  {x:827.3334,y:157.0000},
  {x:826.6666,y:157.0000},
  {x:826.0000,y:157.0000},
  {x:825.3334,y:156.0001},
  {x:824.6666,y:154.9999},
  {x:824.0000,y:154.0000},
  {x:823.3334,y:154.0000},
  {x:822.6666,y:154.0000},
  {x:822.0000,y:154.0000},
  {x:821.6667,y:153.3334},
  {x:821.3333,y:152.6666},
  {x:821.0000,y:152.0000},
  {x:820.3334,y:152.0000},
  {x:819.6666,y:152.0000},
  {x:819.0000,y:152.0000},
  {x:818.3334,y:151.0001},
  {x:817.6666,y:149.9999},
  {x:817.0000,y:149.0000},
  {x:816.3334,y:149.0000},
  {x:815.6666,y:149.0000},
  {x:815.0000,y:149.0000},
  {x:814.6667,y:148.3334},
  {x:814.3333,y:147.6666},
  {x:814.0000,y:147.0000},
  {x:813.3334,y:147.0000},
  {x:812.6666,y:147.0000},
  {x:812.0000,y:147.0000},
  {x:811.3334,y:146.0001},
  {x:810.6666,y:144.9999},
  {x:810.0000,y:144.0000},
  {x:809.3334,y:144.0000},
  {x:808.6666,y:144.0000},
  {x:808.0000,y:144.0000},
  {x:807.6667,y:143.3334},
  {x:807.3333,y:142.6666},
  {x:807.0000,y:142.0000},
  {x:806.3334,y:142.0000},
  {x:805.6666,y:142.0000},
  {x:805.0000,y:142.0000},
  {x:804.3334,y:141.0001},
  {x:803.6666,y:139.9999},
  {x:803.0000,y:139.0000},
  {x:802.3334,y:139.0000},
  {x:801.6666,y:139.0000},
  {x:801.0000,y:139.0000},
  {x:800.3334,y:138.0001},
  {x:799.6666,y:136.9999},
  {x:799.0000,y:136.0000},
  {x:798.3334,y:136.0000},
  {x:797.6666,y:136.0000},
  {x:797.0000,y:136.0000},
  {x:796.3334,y:135.0001},
  {x:795.6666,y:133.9999},
  {x:795.0000,y:133.0000},
  {x:794.3334,y:133.0000},
  {x:793.6666,y:133.0000},
  {x:793.0000,y:133.0000},
  {x:792.3334,y:132.0001},
  {x:791.6666,y:130.9999},
  {x:791.0000,y:130.0000},
  {x:790.3334,y:130.0000},
  {x:789.6666,y:130.0000},
  {x:789.0000,y:130.0000},
  {x:788.6667,y:129.3334},
  {x:788.3333,y:128.6666},
  {x:788.0000,y:128.0000},
  {x:787.3334,y:128.0000},
  {x:786.6666,y:128.0000},
  {x:786.0000,y:128.0000},
  {x:785.3334,y:127.0001},
  {x:784.6666,y:125.9999},
  {x:784.0000,y:125.0000},
  {x:783.3334,y:125.0000},
  {x:782.6666,y:125.0000},
  {x:782.0000,y:125.0000},
  {x:781.3334,y:124.0001},
  {x:780.6666,y:122.9999},
  {x:780.0000,y:122.0000},
  {x:779.3334,y:122.0000},
  {x:778.6666,y:122.0000},
  {x:778.0000,y:122.0000},
  {x:777.3334,y:121.0001},
  {x:776.6666,y:119.9999},
  {x:776.0000,y:119.0000},
  {x:775.3334,y:119.0000},
  {x:774.6666,y:119.0000},
  {x:774.0000,y:119.0000},
  {x:773.3334,y:118.0001},
  {x:772.6666,y:116.9999},
  {x:772.0000,y:116.0000},
  {x:771.3334,y:116.0000},
  {x:770.6666,y:116.0000},
  {x:770.0000,y:116.0000},
  {x:769.6667,y:115.3334},
  {x:769.3333,y:114.6666},
  {x:769.0000,y:114.0000},
  {x:768.3334,y:114.0000},
  {x:767.6666,y:114.0000},
  {x:767.0000,y:114.0000},
  {x:766.6667,y:113.3334},
  {x:766.3333,y:112.6666},
  {x:766.0000,y:112.0000},
  {x:765.3334,y:112.0000},
  {x:764.6666,y:112.0000},
  {x:764.0000,y:112.0000},
  {x:763.6667,y:111.3334},
  {x:763.3333,y:110.6666},
  {x:763.0000,y:110.0000},
  {x:762.3334,y:110.0000},
  {x:761.6666,y:110.0000},
  {x:761.0000,y:110.0000},
  {x:760.6667,y:109.3334},
  {x:760.3333,y:108.6666},
  {x:760.0000,y:108.0000},
  {x:758.6668,y:107.6667},
  {x:757.3332,y:107.3333},
  {x:756.0000,y:107.0000},
  {x:756.0000,y:106.6667},
  {x:756.0000,y:106.3333},
  {x:756.0000,y:106.0000},
  {x:755.0001,y:105.6667},
  {x:753.9999,y:105.3333},
  {x:753.0000,y:105.0000},
  {x:752.6667,y:104.3334},
  {x:752.3333,y:103.6666},
  {x:752.0000,y:103.0000},
  {x:750.6668,y:102.6667},
  {x:749.3332,y:102.3333},
  {x:748.0000,y:102.0000},
  {x:747.6667,y:101.3334},
  {x:747.3333,y:100.6666},
  {x:747.0000,y:100.0000},
  {x:745.6668,y:99.6667},
  {x:744.3332,y:99.3333},
  {x:743.0000,y:99.0000},
  {x:742.6667,y:98.3334},
  {x:742.3333,y:97.6666},
  {x:742.0000,y:97.0000},
  {x:741.3334,y:97.0000},
  {x:740.6666,y:97.0000},
  {x:740.0000,y:97.0000},
  {x:739.6667,y:96.3334},
  {x:739.3333,y:95.6666},
  {x:739.0000,y:95.0000},
  {x:738.3334,y:95.0000},
  {x:737.6666,y:95.0000},
  {x:737.0000,y:95.0000},
  {x:736.3334,y:94.0001},
  {x:735.6666,y:92.9999},
  {x:735.0000,y:92.0000},
  {x:734.3334,y:92.0000},
  {x:733.6666,y:92.0000},
  {x:733.0000,y:92.0000},
  {x:732.6667,y:91.3334},
  {x:732.3333,y:90.6666},
  {x:732.0000,y:90.0000},
  {x:731.0001,y:89.6667},
  {x:729.9999,y:89.3333},
  {x:729.0000,y:89.0000},
  {x:729.0000,y:88.6667},
  {x:729.0000,y:88.3333},
  {x:729.0000,y:88.0000},
  {x:727.6668,y:87.6667},
  {x:726.3332,y:87.3333},
  {x:725.0000,y:87.0000},
  {x:724.6667,y:86.3334},
  {x:724.3333,y:85.6666},
  {x:724.0000,y:85.0000},
  {x:714.6676,y:81.0004},
  {x:705.3324,y:76.9996},
  {x:696.0000,y:73.0000},
  {x:696.0000,y:72.6667},
  {x:696.0000,y:72.3333},
  {x:696.0000,y:72.0000},
  {x:695.3334,y:72.0000},
  {x:694.6666,y:72.0000},
  {x:694.0000,y:72.0000},
  {x:694.0000,y:71.6667},
  {x:694.0000,y:71.3333},
  {x:694.0000,y:71.0000},
  {x:693.3334,y:71.0000},
  {x:692.6666,y:71.0000},
  {x:692.0000,y:71.0000},
  {x:692.0000,y:70.6667},
  {x:692.0000,y:70.3333},
  {x:692.0000,y:70.0000},
  {x:691.3334,y:70.0000},
  {x:690.6666,y:70.0000},
  {x:690.0000,y:70.0000},
  {x:690.0000,y:69.6667},
  {x:690.0000,y:69.3333},
  {x:690.0000,y:69.0000},
  {x:689.3334,y:69.0000},
  {x:688.6666,y:69.0000},
  {x:688.0000,y:69.0000},
  {x:687.6667,y:68.3334},
  {x:687.3333,y:67.6666},
  {x:687.0000,y:67.0000},
  {x:686.3334,y:67.0000},
  {x:685.6666,y:67.0000},
  {x:685.0000,y:67.0000},
  {x:685.0000,y:66.6667},
  {x:685.0000,y:66.3333},
  {x:685.0000,y:66.0000},
  {x:684.3334,y:66.0000},
  {x:683.6666,y:66.0000},
  {x:683.0000,y:66.0000},
  {x:683.0000,y:65.6667},
  {x:683.0000,y:65.3333},
  {x:683.0000,y:65.0000},
  {x:682.3334,y:65.0000},
  {x:681.6666,y:65.0000},
  {x:681.0000,y:65.0000},
  {x:681.0000,y:64.6667},
  {x:681.0000,y:64.3333},
  {x:681.0000,y:64.0000},
  {x:680.3334,y:64.0000},
  {x:679.6666,y:64.0000},
  {x:679.0000,y:64.0000},
  {x:679.0000,y:63.6667},
  {x:679.0000,y:63.3333},
  {x:679.0000,y:63.0000},
  {x:678.3334,y:63.0000},
  {x:677.6666,y:63.0000},
  {x:677.0000,y:63.0000},
  {x:676.6667,y:62.3334},
  {x:676.3333,y:61.6666},
  {x:676.0000,y:61.0000},
  {x:674.0002,y:60.3334},
  {x:671.9998,y:59.6666},
  {x:670.0000,y:59.0000},
  {x:669.6667,y:58.3334},
  {x:669.3333,y:57.6666},
  {x:669.0000,y:57.0000},
  {x:667.0002,y:56.3334},
  {x:664.9998,y:55.6666},
  {x:663.0000,y:55.0000},
  {x:662.6667,y:54.3334},
  {x:662.3333,y:53.6666},
  {x:662.0000,y:53.0000},
  {x:660.0002,y:52.3334},
  {x:657.9998,y:51.6666},
  {x:656.0000,y:51.0000},
  {x:655.6667,y:50.3334},
  {x:655.3333,y:49.6666},
  {x:655.0000,y:49.0000},
  {x:653.0002,y:48.3334},
  {x:650.9998,y:47.6666},
  {x:649.0000,y:47.0000},
  {x:648.6667,y:46.3334},
  {x:648.3333,y:45.6666},
  {x:648.0000,y:45.0000},
  {x:646.6668,y:44.6667},
  {x:645.3332,y:44.3333},
  {x:644.0000,y:44.0000},
  {x:643.6667,y:43.3334},
  {x:643.3333,y:42.6666},
  {x:643.0000,y:42.0000},
  {x:641.0002,y:41.3334},
  {x:638.9998,y:40.6666},
  {x:637.0000,y:40.0000},
  {x:636.6667,y:39.3334},
  {x:636.3333,y:38.6666},
  {x:636.0000,y:38.0000},
  {x:634.6668,y:37.6667},
  {x:633.3332,y:37.3333},
  {x:632.0000,y:37.0000},
  {x:631.6667,y:36.3334},
  {x:631.3333,y:35.6666},
  {x:631.0000,y:35.0000},
  {x:629.6668,y:34.6667},
  {x:628.3332,y:34.3333},
  {x:627.0000,y:34.0000},
  {x:626.6667,y:33.3334},
  {x:626.3333,y:32.6666},
  {x:626.0000,y:32.0000},
  {x:624.0002,y:31.3334},
  {x:621.9998,y:30.6666},
  {x:620.0000,y:30.0000},
  {x:619.6667,y:29.3334},
  {x:619.3333,y:28.6666},
  {x:619.0000,y:28.0000},
  {x:617.6668,y:27.6667},
  {x:616.3332,y:27.3333},
  {x:615.0000,y:27.0000},
  {x:614.3334,y:26.0001},
  {x:613.6666,y:24.9999},
  {x:613.0000,y:24.0000},
  {x:612.3334,y:24.0000},
  {x:611.6666,y:24.0000},
  {x:611.0000,y:24.0000},
  {x:610.6667,y:23.3334},
  {x:610.3333,y:22.6666},
  {x:610.0000,y:22.0000},
  {x:609.3334,y:22.0000},
  {x:608.6666,y:22.0000},
  {x:608.0000,y:22.0000},
  {x:607.3334,y:21.0001},
  {x:606.6666,y:19.9999},
  {x:606.0000,y:19.0000},
  {x:605.3334,y:19.0000},
  {x:604.6666,y:19.0000},
  {x:604.0000,y:19.0000},
  {x:603.6667,y:18.3334},
  {x:603.3333,y:17.6666},
  {x:603.0000,y:17.0000},
  {x:602.3334,y:17.0000},
  {x:601.6666,y:17.0000},
  {x:601.0000,y:17.0000},
  {x:600.6667,y:16.3334},
  {x:600.3333,y:15.6666},
  {x:600.0000,y:15.0000},
  {x:597.3229,y:13.1834},
  {x:594.7324,y:12.7037},
  {x:593.0000,y:10.0000},
  {x:592.6667,y:10.0000},
  {x:592.3333,y:10.0000},
  {x:592.0000,y:10.0000},
  {x:589.6669,y:15.3328},
  {x:587.3331,y:20.6672},
  {x:585.0000,y:26.0000},
  {x:584.3334,y:26.3333},
  {x:583.6666,y:26.6667},
  {x:583.0000,y:27.0000},
  {x:583.0000,y:27.6666},
  {x:583.0000,y:28.3334},
  {x:583.0000,y:29.0000},
  {x:582.6667,y:29.0000},
  {x:582.3333,y:29.0000},
  {x:582.0000,y:29.0000},
  {x:582.0000,y:29.6666},
  {x:582.0000,y:30.3334},
  {x:582.0000,y:31.0000},
  {x:581.6667,y:31.0000},
  {x:581.3333,y:31.0000},
  {x:581.0000,y:31.0000},
  {x:581.0000,y:31.6666},
  {x:581.0000,y:32.3334},
  {x:581.0000,y:33.0000},
  {x:580.6667,y:33.0000},
  {x:580.3333,y:33.0000},
  {x:580.0000,y:33.0000},
  {x:580.0000,y:33.6666},
  {x:580.0000,y:34.3334},
  {x:580.0000,y:35.0000},
  {x:579.6667,y:35.0000},
  {x:579.3333,y:35.0000},
  {x:579.0000,y:35.0000},
  {x:579.0000,y:35.6666},
  {x:579.0000,y:36.3334},
  {x:579.0000,y:37.0000},
  {x:578.6667,y:37.0000},
  {x:578.3333,y:37.0000},
  {x:578.0000,y:37.0000},
  {x:578.0000,y:37.6666},
  {x:578.0000,y:38.3334},
  {x:578.0000,y:39.0000},
  {x:577.6667,y:39.0000},
  {x:577.3333,y:39.0000},
  {x:577.0000,y:39.0000},
  {x:577.0000,y:39.6666},
  {x:577.0000,y:40.3334},
  {x:577.0000,y:41.0000},
  {x:576.6667,y:41.0000},
  {x:576.3333,y:41.0000},
  {x:576.0000,y:41.0000},
  {x:576.0000,y:41.6666},
  {x:576.0000,y:42.3334},
  {x:576.0000,y:43.0000},
  {x:575.6667,y:43.0000},
  {x:575.3333,y:43.0000},
  {x:575.0000,y:43.0000},
  {x:575.0000,y:43.6666},
  {x:575.0000,y:44.3334},
  {x:575.0000,y:45.0000},
  {x:574.3334,y:45.3333},
  {x:573.6666,y:45.6667},
  {x:573.0000,y:46.0000},
  {x:573.0000,y:46.6666},
  {x:573.0000,y:47.3334},
  {x:573.0000,y:48.0000},
  {x:572.6667,y:48.0000},
  {x:572.3333,y:48.0000},
  {x:572.0000,y:48.0000},
  {x:572.0000,y:48.6666},
  {x:572.0000,y:49.3334},
  {x:572.0000,y:50.0000},
  {x:571.6667,y:50.0000},
  {x:571.3333,y:50.0000},
  {x:571.0000,y:50.0000},
  {x:571.0000,y:50.6666},
  {x:571.0000,y:51.3334},
  {x:571.0000,y:52.0000},
  {x:570.6667,y:52.0000},
  {x:570.3333,y:52.0000},
  {x:570.0000,y:52.0000},
  {x:570.0000,y:52.6666},
  {x:570.0000,y:53.3334},
  {x:570.0000,y:54.0000},
  {x:569.6667,y:54.0000},
  {x:569.3333,y:54.0000},
  {x:569.0000,y:54.0000},
  {x:569.0000,y:54.6666},
  {x:569.0000,y:55.3334},
  {x:569.0000,y:56.0000},
  {x:568.3334,y:56.3333},
  {x:567.6666,y:56.6667},
  {x:567.0000,y:57.0000},
  {x:566.3334,y:58.9998},
  {x:565.6666,y:61.0002},
  {x:565.0000,y:63.0000},
  {x:564.3334,y:63.3333},
  {x:563.6666,y:63.6667},
  {x:563.0000,y:64.0000},
  {x:562.3334,y:65.9998},
  {x:561.6666,y:68.0002},
  {x:561.0000,y:70.0000},
  {x:560.3334,y:70.3333},
  {x:559.6666,y:70.6667},
  {x:559.0000,y:71.0000},
  {x:558.3334,y:72.9998},
  {x:557.6666,y:75.0002},
  {x:557.0000,y:77.0000},
  {x:556.3334,y:77.3333},
  {x:555.6666,y:77.6667},
  {x:555.0000,y:78.0000},
  {x:554.6667,y:79.3332},
  {x:554.3333,y:80.6668},
  {x:554.0000,y:82.0000},
  {x:553.3334,y:82.3333},
  {x:552.6666,y:82.6667},
  {x:552.0000,y:83.0000},
  {x:551.6667,y:84.3332},
  {x:551.3333,y:85.6668},
  {x:551.0000,y:87.0000},
  {x:550.6667,y:87.0000},
  {x:550.3333,y:87.0000},
  {x:550.0000,y:87.0000},
  {x:549.6667,y:87.9999},
  {x:549.3333,y:89.0001},
  {x:549.0000,y:90.0000},
  {x:548.3334,y:90.3333},
  {x:547.6666,y:90.6667},
  {x:547.0000,y:91.0000},
  {x:547.0000,y:91.6666},
  {x:547.0000,y:92.3334},
  {x:547.0000,y:93.0000},
  {x:546.6667,y:93.0000},
  {x:546.3333,y:93.0000},
  {x:546.0000,y:93.0000},
  {x:546.0000,y:93.6666},
  {x:546.0000,y:94.3334},
  {x:546.0000,y:95.0000},
  {x:545.3334,y:95.3333},
  {x:544.6666,y:95.6667},
  {x:544.0000,y:96.0000},
  {x:543.6667,y:97.3332},
  {x:543.3333,y:98.6668},
  {x:543.0000,y:100.0000},
  {x:542.3334,y:100.3333},
  {x:541.6666,y:100.6667},
  {x:541.0000,y:101.0000},
  {x:541.0000,y:101.6666},
  {x:541.0000,y:102.3334},
  {x:541.0000,y:103.0000},
  {x:540.3334,y:103.3333},
  {x:539.6666,y:103.6667},
  {x:539.0000,y:104.0000},
  {x:539.0000,y:104.6666},
  {x:539.0000,y:105.3334},
  {x:539.0000,y:106.0000},
  {x:538.3334,y:106.3333},
  {x:537.6666,y:106.6667},
  {x:537.0000,y:107.0000},
  {x:537.0000,y:107.6666},
  {x:537.0000,y:108.3334},
  {x:537.0000,y:109.0000},
  {x:536.6667,y:109.0000},
  {x:536.3333,y:109.0000},
  {x:536.0000,y:109.0000},
  {x:535.6667,y:109.9999},
  {x:535.3333,y:111.0001},
  {x:535.0000,y:112.0000},
  {x:534.3334,y:112.3333},
  {x:533.6666,y:112.6667},
  {x:533.0000,y:113.0000},
  {x:533.0000,y:113.6666},
  {x:533.0000,y:114.3334},
  {x:533.0000,y:115.0000},
  {x:532.3334,y:115.3333},
  {x:531.6666,y:115.6667},
  {x:531.0000,y:116.0000},
  {x:531.0000,y:116.6666},
  {x:531.0000,y:117.3334},
  {x:531.0000,y:118.0000},
  {x:530.3334,y:118.3333},
  {x:529.6666,y:118.6667},
  {x:529.0000,y:119.0000},
  {x:529.0000,y:119.6666},
  {x:529.0000,y:120.3334},
  {x:529.0000,y:121.0000},
  {x:528.6667,y:121.0000},
  {x:528.3333,y:121.0000},
  {x:528.0000,y:121.0000},
  {x:527.6667,y:121.9999},
  {x:527.3333,y:123.0001},
  {x:527.0000,y:124.0000},
  {x:526.3334,y:124.3333},
  {x:525.6666,y:124.6667},
  {x:525.0000,y:125.0000},
  {x:525.0000,y:125.6666},
  {x:525.0000,y:126.3334},
  {x:525.0000,y:127.0000},
  {x:524.0001,y:127.6666},
  {x:522.9999,y:128.3334},
  {x:522.0000,y:129.0000},
  {x:521.6667,y:130.3332},
  {x:521.3333,y:131.6668},
  {x:521.0000,y:133.0000},
  {x:520.3334,y:133.3333},
  {x:519.6666,y:133.6667},
  {x:519.0000,y:134.0000},
  {x:519.0000,y:134.6666},
  {x:519.0000,y:135.3334},
  {x:519.0000,y:136.0000},
  {x:518.3334,y:136.3333},
  {x:517.6666,y:136.6667},
  {x:517.0000,y:137.0000},
  {x:517.0000,y:137.6666},
  {x:517.0000,y:138.3334},
  {x:517.0000,y:139.0000},
  {x:516.3334,y:139.3333},
  {x:515.6666,y:139.6667},
  {x:515.0000,y:140.0000},
  {x:515.0000,y:140.6666},
  {x:515.0000,y:141.3334},
  {x:515.0000,y:142.0000},
  {x:514.6667,y:142.0000},
  {x:514.3333,y:142.0000},
  {x:514.0000,y:142.0000},
  {x:513.6667,y:142.9999},
  {x:513.3333,y:144.0001},
  {x:513.0000,y:145.0000},
  {x:512.3334,y:145.3333},
  {x:511.6666,y:145.6667},
  {x:511.0000,y:146.0000},
  {x:511.0000,y:146.6666},
  {x:511.0000,y:147.3334},
  {x:511.0000,y:148.0000},
  {x:510.3334,y:148.3333},
  {x:509.6666,y:148.6667},
  {x:509.0000,y:149.0000},
  {x:509.0000,y:149.6666},
  {x:509.0000,y:150.3334},
  {x:509.0000,y:151.0000},
  {x:508.3334,y:151.3333},
  {x:507.6666,y:151.6667},
  {x:507.0000,y:152.0000},
  {x:507.0000,y:152.6666},
  {x:507.0000,y:153.3334},
  {x:507.0000,y:154.0000},
  {x:506.6667,y:154.0000},
  {x:506.3333,y:154.0000},
  {x:506.0000,y:154.0000},
  {x:505.6667,y:154.9999},
  {x:505.3333,y:156.0001},
  {x:505.0000,y:157.0000},
  {x:504.3334,y:157.3333},
  {x:503.6666,y:157.6667},
  {x:503.0000,y:158.0000},
  {x:503.0000,y:158.6666},
  {x:503.0000,y:159.3334},
  {x:503.0000,y:160.0000},
  {x:502.0001,y:160.6666},
  {x:500.9999,y:161.3334},
  {x:500.0000,y:162.0000},
  {x:500.0000,y:162.6666},
  {x:500.0000,y:163.3334},
  {x:500.0000,y:164.0000},
  {x:499.6667,y:164.0000},
  {x:499.3333,y:164.0000},
  {x:499.0000,y:164.0000},
  {x:498.6667,y:164.9999},
  {x:498.3333,y:166.0001},
  {x:498.0000,y:167.0000},
  {x:497.3334,y:167.3333},
  {x:496.6666,y:167.6667},
  {x:496.0000,y:168.0000},
  {x:496.0000,y:168.6666},
  {x:496.0000,y:169.3334},
  {x:496.0000,y:170.0000},
  {x:495.0001,y:170.6666},
  {x:493.9999,y:171.3334},
  {x:493.0000,y:172.0000},
  {x:493.0000,y:172.6666},
  {x:493.0000,y:173.3334},
  {x:493.0000,y:174.0000},
  {x:492.3334,y:174.3333},
  {x:491.6666,y:174.6667},
  {x:491.0000,y:175.0000},
  {x:491.0000,y:175.6666},
  {x:491.0000,y:176.3334},
  {x:491.0000,y:177.0000},
  {x:490.3334,y:177.3333},
  {x:489.6666,y:177.6667},
  {x:489.0000,y:178.0000},
  {x:488.6667,y:178.9999},
  {x:488.3333,y:180.0001},
  {x:488.0000,y:181.0000},
  {x:487.0001,y:181.6666},
  {x:485.9999,y:182.3334},
  {x:485.0000,y:183.0000},
  {x:485.0000,y:183.6666},
  {x:485.0000,y:184.3334},
  {x:485.0000,y:185.0000},
  {x:484.0001,y:185.6666},
  {x:482.9999,y:186.3334},
  {x:482.0000,y:187.0000},
  {x:482.0000,y:187.6666},
  {x:482.0000,y:188.3334},
  {x:482.0000,y:189.0000},
  {x:481.0001,y:189.6666},
  {x:479.9999,y:190.3334},
  {x:479.0000,y:191.0000},
  {x:479.0000,y:191.6666},
  {x:479.0000,y:192.3334},
  {x:479.0000,y:193.0000},
  {x:478.0001,y:193.6666},
  {x:476.9999,y:194.3334},
  {x:476.0000,y:195.0000},
  {x:476.0000,y:195.6666},
  {x:476.0000,y:196.3334},
  {x:476.0000,y:197.0000},
  {x:475.0001,y:197.6666},
  {x:473.9999,y:198.3334},
  {x:473.0000,y:199.0000},
  {x:473.0000,y:199.6666},
  {x:473.0000,y:200.3334},
  {x:473.0000,y:201.0000},
  {x:472.0001,y:201.6666},
  {x:470.9999,y:202.3334},
  {x:470.0000,y:203.0000},
  {x:470.0000,y:203.6666},
  {x:470.0000,y:204.3334},
  {x:470.0000,y:205.0000},
  {x:469.3334,y:205.3333},
  {x:468.6666,y:205.6667},
  {x:468.0000,y:206.0000},
  {x:468.0000,y:206.3333},
  {x:468.0000,y:206.6667},
  {x:468.0000,y:207.0000},
  {x:467.3334,y:207.3333},
  {x:466.6666,y:207.6667},
  {x:466.0000,y:208.0000},
  {x:466.0000,y:208.6666},
  {x:466.0000,y:209.3334},
  {x:466.0000,y:210.0000},
  {x:464.6668,y:210.9999},
  {x:463.3332,y:212.0001},
  {x:462.0000,y:213.0000},
  {x:462.0000,y:213.6666},
  {x:462.0000,y:214.3334},
  {x:462.0000,y:215.0000},
  {x:461.0001,y:215.6666},
  {x:459.9999,y:216.3334},
  {x:459.0000,y:217.0000},
  {x:459.0000,y:217.6666},
  {x:459.0000,y:218.3334},
  {x:459.0000,y:219.0000},
  {x:457.3335,y:220.3332},
  {x:455.6665,y:221.6668},
  {x:454.0000,y:223.0000},
  {x:454.0000,y:223.6666},
  {x:454.0000,y:224.3334},
  {x:454.0000,y:225.0000},
  {x:452.6668,y:225.9999},
  {x:451.3332,y:227.0001},
  {x:450.0000,y:228.0000},
  {x:450.0000,y:228.6666},
  {x:450.0000,y:229.3334},
  {x:450.0000,y:230.0000},
  {x:448.3335,y:231.3332},
  {x:446.6665,y:232.6668},
  {x:445.0000,y:234.0000},
  {x:445.0000,y:234.6666},
  {x:445.0000,y:235.3334},
  {x:445.0000,y:236.0000},
  {x:443.3335,y:237.3332},
  {x:441.6665,y:238.6668},
  {x:440.0000,y:240.0000},
  {x:440.0000,y:240.6666},
  {x:440.0000,y:241.3334},
  {x:440.0000,y:242.0000},
  {x:439.0001,y:242.6666},
  {x:437.9999,y:243.3334},
  {x:437.0000,y:244.0000},
  {x:436.3334,y:244.9999},
  {x:435.6666,y:246.0001},
  {x:435.0000,y:247.0000},
  {x:434.6667,y:247.0000},
  {x:434.3333,y:247.0000},
  {x:434.0000,y:247.0000},
  {x:434.0000,y:247.6666},
  {x:434.0000,y:248.3334},
  {x:434.0000,y:249.0000},
  {x:431.6669,y:250.9998},
  {x:429.3331,y:253.0002},
  {x:427.0000,y:255.0000},
  {x:427.0000,y:255.6666},
  {x:427.0000,y:256.3334},
  {x:427.0000,y:257.0000},
  {x:424.0003,y:259.6664},
  {x:420.9997,y:262.3336},
  {x:418.0000,y:265.0000},
  {x:418.0000,y:265.6666},
  {x:418.0000,y:266.3334},
  {x:418.0000,y:267.0000},
  {x:417.6667,y:267.0000},
  {x:417.3333,y:267.0000},
  {x:417.0000,y:267.0000},
  {x:416.3334,y:267.9999},
  {x:415.6666,y:269.0001},
  {x:415.0000,y:270.0000},
  {x:411.3337,y:273.3330},
  {x:407.6663,y:276.6670},
  {x:404.0000,y:280.0000},
  {x:404.0000,y:280.6666},
  {x:404.0000,y:281.3334},
  {x:404.0000,y:282.0000},
  {x:401.0003,y:284.6664},
  {x:397.9997,y:287.3336},
  {x:395.0000,y:290.0000},
  {x:389.3339,y:295.9994},
  {x:383.6661,y:302.0006},
  {x:378.0000,y:308.0000},
  {x:377.3334,y:308.0000},
  {x:376.6666,y:308.0000},
  {x:376.0000,y:308.0000},
  {x:372.6670,y:311.6663},
  {x:369.3330,y:315.3337},
  {x:366.0000,y:319.0000},
  {x:365.3334,y:319.0000},
  {x:364.6666,y:319.0000},
  {x:364.0000,y:319.0000},
  {x:364.0000,y:319.3333},
  {x:364.0000,y:319.6667},
  {x:364.0000,y:320.0000},
  {x:362.6668,y:320.9999},
  {x:361.3332,y:322.0001},
  {x:360.0000,y:323.0000},
  {x:359.0001,y:324.3332},
  {x:357.9999,y:325.6668},
  {x:357.0000,y:327.0000},
  {x:356.3334,y:327.0000},
  {x:355.6666,y:327.0000},
  {x:355.0000,y:327.0000},
  {x:353.6668,y:328.6665},
  {x:352.3332,y:330.3335},
  {x:351.0000,y:332.0000},
  {x:350.3334,y:332.0000},
  {x:349.6666,y:332.0000},
  {x:349.0000,y:332.0000},
  {x:347.6668,y:333.6665},
  {x:346.3332,y:335.3335},
  {x:345.0000,y:337.0000},
  {x:344.3334,y:337.0000},
  {x:343.6666,y:337.0000},
  {x:343.0000,y:337.0000},
  {x:342.0001,y:338.3332},
  {x:340.9999,y:339.6668},
  {x:340.0000,y:341.0000},
  {x:339.3334,y:341.0000},
  {x:338.6666,y:341.0000},
  {x:338.0000,y:341.0000},
  {x:337.3334,y:341.9999},
  {x:336.6666,y:343.0001},
  {x:336.0000,y:344.0000},
  {x:335.3334,y:344.0000},
  {x:334.6666,y:344.0000},
  {x:334.0000,y:344.0000},
  {x:333.0001,y:345.3332},
  {x:331.9999,y:346.6668},
  {x:331.0000,y:348.0000},
  {x:330.3334,y:348.0000},
  {x:329.6666,y:348.0000},
  {x:329.0000,y:348.0000},
  {x:328.6667,y:348.6666},
  {x:328.3333,y:349.3334},
  {x:328.0000,y:350.0000},
  {x:327.3334,y:350.0000},
  {x:326.6666,y:350.0000},
  {x:326.0000,y:350.0000},
  {x:325.3334,y:350.9999},
  {x:324.6666,y:352.0001},
  {x:324.0000,y:353.0000},
  {x:323.3334,y:353.0000},
  {x:322.6666,y:353.0000},
  {x:322.0000,y:353.0000},
  {x:321.6667,y:353.6666},
  {x:321.3333,y:354.3334},
  {x:321.0000,y:355.0000},
  {x:320.3334,y:355.0000},
  {x:319.6666,y:355.0000},
  {x:319.0000,y:355.0000},
  {x:318.6667,y:355.6666},
  {x:318.3333,y:356.3334},
  {x:318.0000,y:357.0000},
  {x:317.3334,y:357.0000},
  {x:316.6666,y:357.0000},
  {x:316.0000,y:357.0000},
  {x:316.0000,y:357.3333},
  {x:316.0000,y:357.6667},
  {x:316.0000,y:358.0000},
  {x:315.0001,y:358.3333},
  {x:313.9999,y:358.6667},
  {x:313.0000,y:359.0000},
  {x:312.6667,y:359.6666},
  {x:312.3333,y:360.3334},
  {x:312.0000,y:361.0000},
  {x:311.3334,y:361.0000},
  {x:310.6666,y:361.0000},
  {x:310.0000,y:361.0000},
  {x:310.0000,y:361.3333},
  {x:310.0000,y:361.6667},
  {x:310.0000,y:362.0000},
  {x:309.3334,y:362.3333},
  {x:308.6666,y:362.6667},
  {x:308.0000,y:363.0000},
  {x:308.0000,y:363.6666},
  {x:308.0000,y:364.3334},
  {x:308.0000,y:365.0000},
  {x:305.3336,y:367.3331},
  {x:302.6664,y:369.6669},
  {x:300.0000,y:372.0000},
  {x:300.0000,y:372.6666},
  {x:300.0000,y:373.3334},
  {x:300.0000,y:374.0000},
  {x:297.6669,y:375.9998},
  {x:295.3331,y:378.0002},
  {x:293.0000,y:380.0000},
  {x:293.0000,y:380.6666},
  {x:293.0000,y:381.3334},
  {x:293.0000,y:382.0000},
  {x:290.3336,y:384.3331},
  {x:287.6664,y:386.6669},
  {x:285.0000,y:389.0000},
  {x:285.0000,y:389.6666},
  {x:285.0000,y:390.3334},
  {x:285.0000,y:391.0000},
  {x:282.0003,y:393.6664},
  {x:278.9997,y:396.3336},
  {x:276.0000,y:399.0000},
  {x:276.0000,y:399.6666},
  {x:276.0000,y:400.3334},
  {x:276.0000,y:401.0000},
  {x:273.0003,y:403.6664},
  {x:269.9997,y:406.3336},
  {x:267.0000,y:409.0000},
  {x:267.0000,y:409.6666},
  {x:267.0000,y:410.3334},
  {x:267.0000,y:411.0000},
  {x:264.0003,y:413.6664},
  {x:260.9997,y:416.3336},
  {x:258.0000,y:419.0000},
  {x:258.0000,y:419.3333},
  {x:258.0000,y:419.6667},
  {x:258.0000,y:420.0000},
  {x:257.3334,y:420.3333},
  {x:256.6666,y:420.6667},
  {x:256.0000,y:421.0000},
  {x:256.0000,y:421.6666},
  {x:256.0000,y:422.3334},
  {x:256.0000,y:423.0000},
  {x:252.0004,y:426.6663},
  {x:247.9996,y:430.3337},
  {x:244.0000,y:434.0000},
  {x:244.0000,y:434.6666},
  {x:244.0000,y:435.3334},
  {x:244.0000,y:436.0000},
  {x:239.6671,y:439.9996},
  {x:235.3329,y:444.0004},
  {x:231.0000,y:448.0000},
  {x:230.3334,y:448.9999},
  {x:229.6666,y:450.0001},
  {x:229.0000,y:451.0000},
  {x:228.6667,y:451.0000},
  {x:228.3333,y:451.0000},
  {x:228.0000,y:451.0000},
  {x:228.0000,y:451.6666},
  {x:228.0000,y:452.3334},
  {x:228.0000,y:453.0000},
  {x:220.6674,y:459.9993},
  {x:213.3326,y:467.0007},
  {x:206.0000,y:474.0000},
  {x:197.6675,y:482.6658},
  {x:189.3325,y:491.3342},
  {x:181.0000,y:500.0000},
  {x:180.3334,y:500.0000},
  {x:179.6666,y:500.0000},
  {x:179.0000,y:500.0000},
  {x:178.3334,y:500.9999},
  {x:177.6666,y:502.0001},
  {x:177.0000,y:503.0000},
  {x:175.0002,y:504.6665},
  {x:172.9998,y:506.3335},
  {x:171.0000,y:508.0000},
  {x:169.6668,y:509.6665},
  {x:168.3332,y:511.3335},
  {x:167.0000,y:513.0000},
  {x:165.0412,y:513.9213},
  {x:164.0456,y:512.2141},
  {x:163.0000,y:512.0000},
  {x:162.6667,y:512.6666},
  {x:162.3333,y:513.3334},
  {x:162.0000,y:514.0000},
  {x:161.3334,y:514.3333},
  {x:160.6666,y:514.6667},
  {x:160.0000,y:515.0000},
  {x:160.0000,y:515.6666},
  {x:160.0000,y:516.3334},
  {x:160.0000,y:517.0000},
  {x:159.0001,y:517.6666},
  {x:157.9999,y:518.3334},
  {x:157.0000,y:519.0000},
  {x:157.0000,y:519.6666},
  {x:157.0000,y:520.3334},
  {x:157.0000,y:521.0000},
  {x:156.3334,y:521.3333},
  {x:155.6666,y:521.6667},
  {x:155.0000,y:522.0000},
  {x:155.0000,y:522.6666},
  {x:155.0000,y:523.3334},
  {x:155.0000,y:524.0000},
  {x:154.0001,y:524.6666},
  {x:152.9999,y:525.3334},
  {x:152.0000,y:526.0000},
  {x:152.0000,y:526.6666},
  {x:152.0000,y:527.3334},
  {x:152.0000,y:528.0000},
  {x:151.0001,y:528.6666},
  {x:149.9999,y:529.3334},
  {x:149.0000,y:530.0000},
  {x:149.0000,y:530.6666},
  {x:149.0000,y:531.3334},
  {x:149.0000,y:532.0000},
  {x:148.0001,y:532.6666},
  {x:146.9999,y:533.3334},
  {x:146.0000,y:534.0000},
  {x:146.0000,y:534.6666},
  {x:146.0000,y:535.3334},
  {x:146.0000,y:536.0000},
  {x:145.3334,y:536.3333},
  {x:144.6666,y:536.6667},
  {x:144.0000,y:537.0000},
  {x:144.0000,y:537.6666},
  {x:144.0000,y:538.3334},
  {x:144.0000,y:539.0000},
  {x:143.3334,y:539.3333},
  {x:142.6666,y:539.6667},
  {x:142.0000,y:540.0000},
  {x:141.6667,y:540.9999},
  {x:141.3333,y:542.0001},
  {x:141.0000,y:543.0000},
  {x:140.0001,y:543.6666},
  {x:138.9999,y:544.3334},
  {x:138.0000,y:545.0000},
  {x:138.0000,y:545.6666},
  {x:138.0000,y:546.3334},
  {x:138.0000,y:547.0000},
  {x:137.6667,y:547.0000},
  {x:137.3333,y:547.0000},
  {x:137.0000,y:547.0000},
  {x:136.6667,y:547.9999},
  {x:136.3333,y:549.0001},
  {x:136.0000,y:550.0000},
  {x:135.3334,y:550.3333},
  {x:134.6666,y:550.6667},
  {x:134.0000,y:551.0000},
  {x:134.0000,y:551.6666},
  {x:134.0000,y:552.3334},
  {x:134.0000,y:553.0000},
  {x:133.0001,y:553.6666},
  {x:131.9999,y:554.3334},
  {x:131.0000,y:555.0000},
  {x:131.0000,y:555.6666},
  {x:131.0000,y:556.3334},
  {x:131.0000,y:557.0000},
  {x:130.3334,y:557.3333},
  {x:129.6666,y:557.6667},
  {x:129.0000,y:558.0000},
  {x:128.6667,y:558.9999},
  {x:128.3333,y:560.0001},
  {x:128.0000,y:561.0000},
  {x:127.6667,y:561.0000},
  {x:127.3333,y:561.0000},
  {x:127.0000,y:561.0000},
  {x:127.0000,y:561.6666},
  {x:127.0000,y:562.3334},
  {x:127.0000,y:563.0000},
  {x:126.3334,y:563.3333},
  {x:125.6666,y:563.6667},
  {x:125.0000,y:564.0000},
  {x:125.0000,y:564.6666},
  {x:125.0000,y:565.3334},
  {x:125.0000,y:566.0000},
  {x:124.3334,y:566.3333},
  {x:123.6666,y:566.6667},
  {x:123.0000,y:567.0000},
  {x:123.0000,y:567.6666},
  {x:123.0000,y:568.3334},
  {x:123.0000,y:569.0000},
  {x:122.3334,y:569.3333},
  {x:121.6666,y:569.6667},
  {x:121.0000,y:570.0000},
  {x:121.0000,y:570.6666},
  {x:121.0000,y:571.3334},
  {x:121.0000,y:572.0000},
  {x:120.3334,y:572.3333},
  {x:119.6666,y:572.6667},
  {x:119.0000,y:573.0000},
  {x:119.0000,y:573.6666},
  {x:119.0000,y:574.3334},
  {x:119.0000,y:575.0000},
  {x:118.3334,y:575.3333},
  {x:117.6666,y:575.6667},
  {x:117.0000,y:576.0000},
  {x:117.0000,y:576.6666},
  {x:117.0000,y:577.3334},
  {x:117.0000,y:578.0000},
  {x:116.3334,y:578.3333},
  {x:115.6666,y:578.6667},
  {x:115.0000,y:579.0000},
  {x:115.0000,y:579.6666},
  {x:115.0000,y:580.3334},
  {x:115.0000,y:581.0000},
  {x:114.3334,y:581.3333},
  {x:113.6666,y:581.6667},
  {x:113.0000,y:582.0000},
  {x:113.0000,y:582.6666},
  {x:113.0000,y:583.3334},
  {x:113.0000,y:584.0000},
  {x:112.3334,y:584.3333},
  {x:111.6666,y:584.6667},
  {x:111.0000,y:585.0000},
  {x:111.0000,y:585.6666},
  {x:111.0000,y:586.3334},
  {x:111.0000,y:587.0000},
  {x:110.3334,y:587.3333},
  {x:109.6666,y:587.6667},
  {x:109.0000,y:588.0000},
  {x:109.0000,y:588.6666},
  {x:109.0000,y:589.3334},
  {x:109.0000,y:590.0000},
  {x:108.3334,y:590.3333},
  {x:107.6666,y:590.6667},
  {x:107.0000,y:591.0000},
  {x:106.6667,y:592.3332},
  {x:106.3333,y:593.6668},
  {x:106.0000,y:595.0000},
  {x:105.3334,y:595.3333},
  {x:104.6666,y:595.6667},
  {x:104.0000,y:596.0000},
  {x:104.0000,y:596.6666},
  {x:104.0000,y:597.3334},
  {x:104.0000,y:598.0000},
  {x:103.3334,y:598.3333},
  {x:102.6666,y:598.6667},
  {x:102.0000,y:599.0000},
  {x:101.6667,y:600.3332},
  {x:101.3333,y:601.6668},
  {x:101.0000,y:603.0000},
  {x:100.6667,y:603.0000},
  {x:100.3333,y:603.0000},
  {x:100.0000,y:603.0000},
  {x:99.6667,y:603.9999},
  {x:99.3333,y:605.0001},
  {x:99.0000,y:606.0000},
  {x:98.3334,y:606.3333},
  {x:97.6666,y:606.6667},
  {x:97.0000,y:607.0000},
  {x:97.0000,y:607.6666},
  {x:97.0000,y:608.3334},
  {x:97.0000,y:609.0000},
  {x:96.6667,y:609.0000},
  {x:96.3333,y:609.0000},
  {x:96.0000,y:609.0000},
  {x:96.0000,y:609.6666},
  {x:96.0000,y:610.3334},
  {x:96.0000,y:611.0000},
  {x:95.3334,y:611.3333},
  {x:94.6666,y:611.6667},
  {x:94.0000,y:612.0000},
  {x:93.6667,y:613.3332},
  {x:93.3333,y:614.6668},
  {x:93.0000,y:616.0000},
  {x:92.3334,y:616.3333},
  {x:91.6666,y:616.6667},
  {x:91.0000,y:617.0000},
  {x:90.6667,y:618.3332},
  {x:90.3333,y:619.6668},
  {x:90.0000,y:621.0000},
  {x:89.3334,y:621.3333},
  {x:88.6666,y:621.6667},
  {x:88.0000,y:622.0000},
  {x:87.6667,y:623.3332},
  {x:87.3333,y:624.6668},
  {x:87.0000,y:626.0000},
  {x:86.3334,y:626.3333},
  {x:85.6666,y:626.6667},
  {x:85.0000,y:627.0000},
  {x:84.3334,y:628.9998},
  {x:83.6666,y:631.0002},
  {x:83.0000,y:633.0000},
  {x:82.3334,y:633.3333},
  {x:81.6666,y:633.6667},
  {x:81.0000,y:634.0000},
  {x:81.0000,y:634.6666},
  {x:81.0000,y:635.3334},
  {x:81.0000,y:636.0000},
  {x:80.6667,y:636.0000},
  {x:80.3333,y:636.0000},
  {x:80.0000,y:636.0000},
  {x:80.0000,y:636.6666},
  {x:80.0000,y:637.3334},
  {x:80.0000,y:638.0000},
  {x:79.3334,y:638.3333},
  {x:78.6666,y:638.6667},
  {x:78.0000,y:639.0000},
  {x:78.0000,y:639.6666},
  {x:78.0000,y:640.3334},
  {x:78.0000,y:641.0000},
  {x:77.6667,y:641.0000},
  {x:77.3333,y:641.0000},
  {x:77.0000,y:641.0000},
  {x:77.0000,y:641.6666},
  {x:77.0000,y:642.3334},
  {x:77.0000,y:643.0000},
  {x:76.6667,y:643.0000},
  {x:76.3333,y:643.0000},
  {x:76.0000,y:643.0000},
  {x:76.0000,y:643.6666},
  {x:76.0000,y:644.3334},
  {x:76.0000,y:645.0000},
  {x:75.6667,y:645.0000},
  {x:75.3333,y:645.0000},
  {x:75.0000,y:645.0000},
  {x:75.0000,y:645.6666},
  {x:75.0000,y:646.3334},
  {x:75.0000,y:647.0000},
  {x:74.3334,y:647.3333},
  {x:73.6666,y:647.6667},
  {x:73.0000,y:648.0000},
  {x:72.6667,y:649.3332},
  {x:72.3333,y:650.6668},
  {x:72.0000,y:652.0000},
  {x:71.6667,y:652.0000},
  {x:71.3333,y:652.0000},
  {x:71.0000,y:652.0000},
  {x:71.0000,y:652.6666},
  {x:71.0000,y:653.3334},
  {x:71.0000,y:654.0000},
  {x:70.6667,y:654.0000},
  {x:70.3333,y:654.0000},
  {x:70.0000,y:654.0000},
  {x:70.0000,y:654.6666},
  {x:70.0000,y:655.3334},
  {x:70.0000,y:656.0000},
  {x:69.3334,y:656.3333},
  {x:68.6666,y:656.6667},
  {x:68.0000,y:657.0000},
  {x:68.0000,y:657.6666},
  {x:68.0000,y:658.3334},
  {x:68.0000,y:659.0000},
  {x:67.6667,y:659.0000},
  {x:67.3333,y:659.0000},
  {x:67.0000,y:659.0000},
  {x:67.0000,y:659.6666},
  {x:67.0000,y:660.3334},
  {x:67.0000,y:661.0000},
  {x:66.6667,y:661.0000},
  {x:66.3333,y:661.0000},
  {x:66.0000,y:661.0000},
  {x:66.0000,y:661.6666},
  {x:66.0000,y:662.3334},
  {x:66.0000,y:663.0000},
  {x:65.6667,y:663.0000},
  {x:65.3333,y:663.0000},
  {x:65.0000,y:663.0000},
  {x:65.0000,y:663.6666},
  {x:65.0000,y:664.3334},
  {x:65.0000,y:665.0000},
  {x:64.6667,y:665.0000},
  {x:64.3333,y:665.0000},
  {x:64.0000,y:665.0000},
  {x:64.0000,y:665.6666},
  {x:64.0000,y:666.3334},
  {x:64.0000,y:667.0000},
  {x:63.3334,y:667.3333},
  {x:62.6666,y:667.6667},
  {x:62.0000,y:668.0000},
  {x:61.3334,y:669.9998},
  {x:60.6666,y:672.0002},
  {x:60.0000,y:674.0000},
  {x:59.6667,y:674.0000},
  {x:59.3333,y:674.0000},
  {x:59.0000,y:674.0000},
  {x:58.6667,y:675.3332},
  {x:58.3333,y:676.6668},
  {x:58.0000,y:678.0000},
  {x:57.6667,y:678.0000},
  {x:57.3333,y:678.0000},
  {x:57.0000,y:678.0000},
  {x:56.6667,y:679.3332},
  {x:56.3333,y:680.6668},
  {x:56.0000,y:682.0000},
  {x:55.6667,y:682.0000},
  {x:55.3333,y:682.0000},
  {x:55.0000,y:682.0000},
  {x:55.0000,y:682.6666},
  {x:55.0000,y:683.3334},
  {x:55.0000,y:684.0000},
  {x:54.6667,y:684.0000},
  {x:54.3333,y:684.0000},
  {x:54.0000,y:684.0000},
  {x:54.0000,y:684.6666},
  {x:54.0000,y:685.3334},
  {x:54.0000,y:686.0000},
  {x:53.6667,y:686.0000},
  {x:53.3333,y:686.0000},
  {x:53.0000,y:686.0000},
  {x:53.0000,y:686.6666},
  {x:53.0000,y:687.3334},
  {x:53.0000,y:688.0000},
  {x:52.6667,y:688.0000},
  {x:52.3333,y:688.0000},
  {x:52.0000,y:688.0000},
  {x:52.0000,y:688.6666},
  {x:52.0000,y:689.3334},
  {x:52.0000,y:690.0000},
  {x:51.6667,y:690.0000},
  {x:51.3333,y:690.0000},
  {x:51.0000,y:690.0000},
  {x:50.3334,y:691.9998},
  {x:49.6666,y:694.0002},
  {x:49.0000,y:696.0000},
  {x:48.6667,y:696.0000},
  {x:48.3333,y:696.0000},
  {x:48.0000,y:696.0000},
  {x:47.6667,y:697.3332},
  {x:47.3333,y:698.6668},
  {x:47.0000,y:700.0000},
  {x:46.6667,y:700.0000},
  {x:46.3333,y:700.0000},
  {x:46.0000,y:700.0000},
  {x:45.6667,y:701.3332},
  {x:45.3333,y:702.6668},
  {x:45.0000,y:704.0000},
  {x:44.6667,y:704.0000},
  {x:44.3333,y:704.0000},
  {x:44.0000,y:704.0000},
  {x:44.0000,y:704.6666},
  {x:44.0000,y:705.3334},
  {x:44.0000,y:706.0000},
  {x:43.6667,y:706.0000},
  {x:43.3333,y:706.0000},
  {x:43.0000,y:706.0000},
  {x:43.0000,y:706.6666},
  {x:43.0000,y:707.3334},
  {x:43.0000,y:708.0000},
  {x:42.6667,y:708.0000},
  {x:42.3333,y:708.0000},
  {x:42.0000,y:708.0000},
  {x:42.0000,y:708.6666},
  {x:42.0000,y:709.3334},
  {x:42.0000,y:710.0000},
  {x:41.6667,y:710.0000},
  {x:41.3333,y:710.0000},
  {x:41.0000,y:710.0000},
  {x:41.0000,y:710.6666},
  {x:41.0000,y:711.3334},
  {x:41.0000,y:712.0000},
  {x:40.6667,y:712.0000},
  {x:40.3333,y:712.0000},
  {x:40.0000,y:712.0000},
  {x:39.6667,y:713.3332},
  {x:39.3333,y:714.6668},
  {x:39.0000,y:716.0000},
  {x:38.6667,y:716.0000},
  {x:38.3333,y:716.0000},
  {x:38.0000,y:716.0000},
  {x:38.0000,y:716.6666},
  {x:38.0000,y:717.3334},
  {x:38.0000,y:718.0000},
  {x:37.6667,y:718.0000},
  {x:37.3333,y:718.0000},
  {x:37.0000,y:718.0000},
  {x:37.0000,y:718.9999},
  {x:37.0000,y:720.0001},
  {x:37.0000,y:721.0000},
  {x:36.6667,y:721.0000},
  {x:36.3333,y:721.0000},
  {x:36.0000,y:721.0000},
  {x:36.0000,y:721.6666},
  {x:36.0000,y:722.3334},
  {x:36.0000,y:723.0000},
  {x:35.6667,y:723.0000},
  {x:35.3333,y:723.0000},
  {x:35.0000,y:723.0000},
  {x:34.3334,y:724.9998},
  {x:33.6666,y:727.0002},
  {x:33.0000,y:729.0000},
  {x:32.6667,y:729.0000},
  {x:32.3333,y:729.0000},
  {x:32.0000,y:729.0000},
  {x:32.0000,y:729.9999},
  {x:32.0000,y:731.0001},
  {x:32.0000,y:732.0000},
  {x:31.6667,y:732.0000},
  {x:31.3333,y:732.0000},
  {x:31.0000,y:732.0000},
  {x:31.0000,y:732.6666},
  {x:31.0000,y:733.3334},
  {x:31.0000,y:734.0000},
  {x:30.6667,y:734.0000},
  {x:30.3333,y:734.0000},
  {x:30.0000,y:734.0000},
  {x:29.6667,y:735.3332},
  {x:29.3333,y:736.6668},
  {x:29.0000,y:738.0000},
  {x:28.6667,y:738.0000},
  {x:28.3333,y:738.0000},
  {x:28.0000,y:738.0000},
  {x:28.0000,y:738.9999},
  {x:28.0000,y:740.0001},
  {x:28.0000,y:741.0000},
  {x:27.6667,y:741.0000},
  {x:27.3333,y:741.0000},
  {x:27.0000,y:741.0000},
  {x:26.6667,y:742.3332},
  {x:26.3333,y:743.6668},
  {x:26.0000,y:745.0000},
  {x:25.6667,y:745.0000},
  {x:25.3333,y:745.0000},
  {x:25.0000,y:745.0000},
  {x:25.0000,y:745.9999},
  {x:25.0000,y:747.0001},
  {x:25.0000,y:748.0000},
  {x:24.6667,y:748.0000},
  {x:24.3333,y:748.0000},
  {x:24.0000,y:748.0000},
  {x:23.6667,y:749.3332},
  {x:23.3333,y:750.6668},
  {x:23.0000,y:752.0000},
  {x:22.6667,y:752.0000},
  {x:22.3333,y:752.0000},
  {x:22.0000,y:752.0000},
  {x:20.0002,y:757.9994},
  {x:17.9998,y:764.0006},
  {x:16.0000,y:770.0000},
  {x:13.5336,y:775.5888},
  {x:9.8161,y:782.8192},
  {x:9.0000,y:790.0000},
  {x:6.9902,y:790.5736},
  {x:8.1348,y:789.8800},
  {x:7.0000,y:791.0000},
  {x:25.1067,y:790.8887},
  {x:44.7642,y:789.8642},
  {x:59.0000,y:794.0000},
  {x:62.9996,y:794.0000},
  {x:67.0004,y:794.0000},
  {x:71.0000,y:794.0000},
  {x:82.5163,y:794.0010},
  {x:98.3118,y:792.1883},
  {x:108.0000,y:795.0000},
  {x:113.9994,y:795.0000},
  {x:120.0006,y:795.0000},
  {x:126.0000,y:795.0000},
  {x:135.1717,y:794.9994},
  {x:147.5488,y:793.8349},
  {x:155.0000,y:796.0000},
  {x:161.2349,y:797.8117},
  {x:171.8537,y:795.2132},
  {x:178.0000,y:797.0000},
  {x:184.9993,y:797.0000},
  {x:192.0007,y:797.0000},
  {x:199.0000,y:797.0000},
  {x:204.1493,y:798.4721},
  {x:213.2517,y:796.3547},
  {x:219.0000,y:798.0000},
  {x:219.0000,y:798.3333},
  {x:219.0000,y:798.6667},
  {x:219.0000,y:799.0000},
  {x:222.3330,y:799.0000},
  {x:225.6670,y:799.0000},
  {x:229.0000,y:799.0000},
  {x:235.6660,y:799.3333},
  {x:242.3340,y:799.6667},
  {x:249.0000,y:800.0000},
  {x:249.0000,y:800.3333},
  {x:249.0000,y:800.6667},
  {x:249.0000,y:801.0000},
  {x:256.6659,y:801.3333},
  {x:264.3341,y:801.6667},
  {x:272.0000,y:802.0000},
  {x:272.0000,y:802.3333},
  {x:272.0000,y:802.6667},
  {x:272.0000,y:803.0000},
  {x:275.3330,y:803.0000},
  {x:278.6670,y:803.0000},
  {x:282.0000,y:803.0000},
  {x:293.3670,y:806.2691},
  {x:308.6553,y:804.6880},
  {x:320.0000,y:808.0000},
  {x:323.3330,y:808.0000},
  {x:326.6670,y:808.0000},
  {x:330.0000,y:808.0000},
  {x:330.0000,y:808.3333},
  {x:330.0000,y:808.6667},
  {x:330.0000,y:809.0000},
  {x:333.3330,y:809.0000},
  {x:336.6670,y:809.0000},
  {x:340.0000,y:809.0000},
  {x:340.0000,y:809.3333},
  {x:340.0000,y:809.6667},
  {x:340.0000,y:810.0000},
  {x:343.3330,y:810.0000},
  {x:346.6670,y:810.0000},
  {x:350.0000,y:810.0000},
  {x:350.0000,y:810.3333},
  {x:350.0000,y:810.6667},
  {x:350.0000,y:811.0000},
  {x:364.3319,y:811.9999},
  {x:378.6681,y:813.0001},
  {x:393.0000,y:814.0000},
  {x:402.3950,y:816.7020},
  {x:415.6230,y:815.2637},
  {x:425.0000,y:818.0000},
  {x:428.6663,y:818.0000},
  {x:432.3337,y:818.0000},
  {x:436.0000,y:818.0000},
  {x:436.0000,y:818.3333},
  {x:436.0000,y:818.6667},
  {x:436.0000,y:819.0000},
  {x:439.6663,y:819.0000},
  {x:443.3337,y:819.0000},
  {x:447.0000,y:819.0000},
  {x:447.0000,y:819.3333},
  {x:447.0000,y:819.6667},
  {x:447.0000,y:820.0000},
  {x:449.9997,y:820.0000},
  {x:453.0003,y:820.0000},
  {x:456.0000,y:820.0000},
  {x:456.0000,y:820.3333},
  {x:456.0000,y:820.6667},
  {x:456.0000,y:821.0000},
  {x:461.3328,y:821.3333},
  {x:466.6672,y:821.6667},
  {x:472.0000,y:822.0000},
  {x:483.1023,y:825.2424},
  {x:497.8213,y:824.7063},
  {x:509.0000,y:828.0000},
  {x:511.6664,y:828.0000},
  {x:514.3336,y:828.0000},
  {x:517.0000,y:828.0000},
  {x:517.0000,y:828.3333},
  {x:517.0000,y:828.6667},
  {x:517.0000,y:829.0000},
  {x:519.6664,y:829.0000},
  {x:522.3336,y:829.0000},
  {x:525.0000,y:829.0000},
  {x:525.0000,y:829.3333},
  {x:525.0000,y:829.6667},
  {x:525.0000,y:830.0000},
  {x:532.3326,y:830.3333},
  {x:539.6674,y:830.6667},
  {x:547.0000,y:831.0000},
  {x:547.0000,y:831.3333},
  {x:547.0000,y:831.6667},
  {x:547.0000,y:832.0000},
  {x:550.6663,y:832.0000},
  {x:554.3337,y:832.0000},
  {x:558.0000,y:832.0000},
  {x:570.7155,y:835.6746},
  {x:587.4480,y:834.2692},
  {x:600.0000,y:838.0000},
  {x:604.9995,y:838.3333},
  {x:610.0005,y:838.6667},
  {x:615.0000,y:839.0000},
  {x:615.0000,y:839.3333},
  {x:615.0000,y:839.6667},
  {x:615.0000,y:840.0000},
  {x:617.3331,y:840.0000},
  {x:619.6669,y:840.0000},
  {x:622.0000,y:840.0000},
  {x:622.0000,y:840.3333},
  {x:622.0000,y:840.6667},
  {x:622.0000,y:841.0000},
  {x:624.6664,y:841.0000},
  {x:627.3336,y:841.0000},
  {x:630.0000,y:841.0000},
  {x:645.2403,y:845.4677},
  {x:664.8315,y:844.4719},
  {x:680.0000,y:849.0000},
  {x:684.6662,y:849.3333},
  {x:689.3338,y:849.6667},
  {x:694.0000,y:850.0000},
  {x:704.2499,y:853.0312},
  {x:717.8663,y:852.9524},
  {x:728.0000,y:856.0000},
  {x:732.3329,y:856.3333},
  {x:736.6671,y:856.6667},
  {x:741.0000,y:857.0000},
  {x:748.2501,y:859.2238},
  {x:757.5796,y:860.7072},
  {x:765.0000,y:863.0000},
  {x:768.6663,y:863.3333},
  {x:772.3337,y:863.6667},
  {x:776.0000,y:864.0000},
  {x:790.3423,y:868.8881},
  {x:807.2426,y:872.4554},
  {x:822.0000,y:877.0000},
  {x:825.6663,y:877.0000},
  {x:829.3337,y:877.0000},
  {x:833.0000,y:877.0000},
  {x:833.0000,y:877.3333},
  {x:833.0000,y:877.6667},
  {x:833.0000,y:878.0000},
  {x:836.9996,y:878.0000},
  {x:841.0004,y:878.0000},
  {x:845.0000,y:878.0000},
  {x:845.3333,y:878.0000},
  {x:845.6667,y:878.0000},
  {x:846.0000,y:878.0000},
  {x:846.0000,y:878.3333},
  {x:846.0000,y:878.6667},
  {x:846.0000,y:879.0000},
  {x:849.9737,y:880.1571},
  {x:856.4735,y:878.6148},
  {x:859.0000,y:878.0000},
  {x:859.9999,y:879.3332},
  {x:861.0001,y:880.6668},
  {x:862.0000,y:882.0000},
  {x:862.6666,y:882.0000},
  {x:863.3334,y:882.0000},
  {x:864.0000,y:882.0000},
  {x:864.0000,y:882.3333},
  {x:864.0000,y:882.6667},
  {x:864.0000,y:883.0000},
  {x:865.3332,y:883.0000},
  {x:866.6668,y:883.0000},
  {x:868.0000,y:883.0000},
  {x:868.0000,y:883.3333},
  {x:868.0000,y:883.6667},
  {x:868.0000,y:884.0000},
  {x:873.6661,y:883.6667},
  {x:879.3339,y:883.3333},
  {x:885.0000,y:883.0000},
  {x:885.0000,y:882.6667},
  {x:885.0000,y:882.3333},
  {x:885.0000,y:882.0000},
  {x:885.3333,y:882.0000},
  {x:885.6667,y:882.0000},
  {x:886.0000,y:882.0000},
  {x:886.0000,y:882.3333},
  {x:886.0000,y:882.6667},
  {x:886.0000,y:883.0000},
  {x:887.6665,y:883.0000},
  {x:889.3335,y:883.0000},
  {x:891.0000,y:883.0000},
  {x:891.0000,y:883.3333},
  {x:891.0000,y:883.6667},
  {x:891.0000,y:884.0000},
  {x:894.9996,y:884.3333},
  {x:899.0004,y:884.6667},
  {x:903.0000,y:885.0000},
  {x:903.0000,y:885.3333},
  {x:903.0000,y:885.6667},
  {x:903.0000,y:886.0000},
  {x:907.9995,y:886.3333},
  {x:913.0005,y:886.6667},
  {x:918.0000,y:887.0000},
  {x:931.1386,y:890.9520},
  {x:947.7730,y:891.0328},
  {x:961.0000,y:895.0000},
  {x:965.3329,y:895.3333},
  {x:969.6671,y:895.6667},
  {x:974.0000,y:896.0000},
  {x:974.0000,y:896.3333},
  {x:974.0000,y:896.6667},
  {x:974.0000,y:897.0000},
  {x:976.3331,y:897.0000},
  {x:978.6669,y:897.0000},
  {x:981.0000,y:897.0000},
  {x:981.0000,y:897.3333},
  {x:981.0000,y:897.6667},
  {x:981.0000,y:898.0000},
  {x:983.3331,y:898.0000},
  {x:985.6669,y:898.0000},
  {x:988.0000,y:898.0000},
  {x:988.0000,y:898.3333},
  {x:988.0000,y:898.6667},
  {x:988.0000,y:899.0000},
  {x:990.3331,y:899.0000},
  {x:992.6669,y:899.0000},
  {x:995.0000,y:899.0000},
  {x:995.0000,y:899.3333},
  {x:995.0000,y:899.6667},
  {x:995.0000,y:900.0000},
  {x:997.3331,y:900.0000},
  {x:999.6669,y:900.0000},
  {x:1002.0000,y:900.0000},
  {x:1008.6241,y:901.9449},
  {x:1018.2990,y:902.0281},
  {x:1025.0000,y:904.0000},
  {x:1027.6664,y:904.0000},
  {x:1030.3336,y:904.0000},
  {x:1033.0000,y:904.0000},
  {x:1043.0634,y:906.9172},
  {x:1056.8466,y:906.0316},
  {x:1067.0000,y:909.0000},
  {x:1083.6650,y:910.3332},
  {x:1100.3350,y:911.6668},
  {x:1117.0000,y:913.0000},
  {x:1117.0000,y:913.3333},
  {x:1117.0000,y:913.6667},
  {x:1117.0000,y:914.0000},
  {x:1120.6663,y:914.0000},
  {x:1124.3337,y:914.0000},
  {x:1128.0000,y:914.0000},
  {x:1128.0000,y:914.3333},
  {x:1128.0000,y:914.6667},
  {x:1128.0000,y:915.0000},
  {x:1131.3330,y:915.0000},
  {x:1134.6670,y:915.0000},
  {x:1138.0000,y:915.0000},
  {x:1138.9999,y:915.0000},
  {x:1140.0001,y:915.0000},
  {x:1141.0000,y:915.0000},
  {x:1141.0000,y:915.3333},
  {x:1141.0000,y:915.6667},
  {x:1141.0000,y:916.0000},
  {x:1145.3329,y:916.0000},
  {x:1149.6671,y:916.0000},
  {x:1154.0000,y:916.0000},
  {x:1154.0000,y:916.3333},
  {x:1154.0000,y:916.6667},
  {x:1154.0000,y:917.0000},
  {x:1158.6662,y:917.0000},
  {x:1163.3338,y:917.0000},
  {x:1168.0000,y:917.0000},
  {x:1168.0000,y:917.3333},
  {x:1168.0000,y:917.6667},
  {x:1168.0000,y:918.0000},
  {x:1173.3328,y:918.0000},
  {x:1178.6672,y:918.0000},
  {x:1184.0000,y:918.0000},
  {x:1184.0000,y:918.3333},
  {x:1184.0000,y:918.6667},
  {x:1184.0000,y:919.0000},
  {x:1192.3325,y:919.3333},
  {x:1200.6675,y:919.6667},
  {x:1209.0000,y:920.0000},
  {x:1212.6663,y:920.0000},
  {x:1216.3337,y:920.0000},
  {x:1220.0000,y:920.0000},
  {x:1226.1682,y:921.7457},
  {x:1236.6983,y:919.2194},
  {x:1243.0000,y:921.0000},
  {x:1243.0000,y:921.3333},
  {x:1243.0000,y:921.6667},
  {x:1243.0000,y:922.0000},
  {x:1253.3323,y:922.0000},
  {x:1263.6677,y:922.0000},
  {x:1274.0000,y:922.0000},
  {x:1274.0000,y:922.3333},
  {x:1274.0000,y:922.6667},
  {x:1274.0000,y:923.0000},
  {x:1291.9982,y:923.0000},
  {x:1310.0018,y:923.0000},
  {x:1328.0000,y:923.0000},
  {x:1348.3313,y:923.0000},
  {x:1368.6687,y:923.0000},
  {x:1389.0000,y:923.0000},
  {x:1398.4659,y:923.0007},
  {x:1411.2677,y:924.2436},
  {x:1419.0000,y:922.0000},
  {x:1429.3323,y:922.0000},
  {x:1439.6677,y:922.0000},
  {x:1450.0000,y:922.0000},
  {x:1450.0000,y:921.6667},
  {x:1450.0000,y:921.3333},
  {x:1450.0000,y:921.0000},
  {x:1454.9995,y:921.0000},
  {x:1460.0005,y:921.0000},
  {x:1465.0000,y:921.0000},
  {x:1469.3329,y:921.0000},
  {x:1473.6671,y:921.0000},
  {x:1478.0000,y:921.0000},
  {x:1478.0000,y:920.6667},
  {x:1478.0000,y:920.3333},
  {x:1478.0000,y:920.0000},
  {x:1486.6658,y:920.0000},
  {x:1495.3342,y:920.0000},
  {x:1504.0000,y:920.0000},
  {x:1504.0000,y:919.6667},
  {x:1504.0000,y:919.3333},
  {x:1504.0000,y:919.0000},
  {x:1511.9992,y:919.0000},
  {x:1520.0008,y:919.0000},
  {x:1528.0000,y:919.0000},
  {x:1528.0000,y:918.6667},
  {x:1528.0000,y:918.3333},
  {x:1528.0000,y:918.0000},
  {x:1541.6653,y:917.6667},
  {x:1555.3347,y:917.3333},
  {x:1569.0000,y:917.0000},
  {x:1573.8599,y:915.6138},
  {x:1582.5318,y:917.5606},
  {x:1588.0000,y:916.0000},
  {x:1588.0000,y:915.6667},
  {x:1588.0000,y:915.3333},
  {x:1588.0000,y:915.0000},
  {x:1593.6661,y:915.0000},
  {x:1599.3339,y:915.0000},
  {x:1605.0000,y:915.0000},
  {x:1605.0000,y:914.6667},
  {x:1605.0000,y:914.3333},
  {x:1605.0000,y:914.0000},
  {x:1610.6661,y:914.0000},
  {x:1616.3339,y:914.0000},
  {x:1622.0000,y:914.0000},
  {x:1622.0000,y:913.6667},
  {x:1622.0000,y:913.3333},
  {x:1622.0000,y:913.0000},
  {x:1626.9995,y:913.0000},
  {x:1632.0005,y:913.0000},
  {x:1637.0000,y:913.0000},
  {x:1637.0000,y:912.6667},
  {x:1637.0000,y:912.3333},
  {x:1637.0000,y:912.0000},
  {x:1642.3328,y:912.0000},
  {x:1647.6672,y:912.0000},
  {x:1653.0000,y:912.0000},
  {x:1653.0000,y:911.6667},
  {x:1653.0000,y:911.3333},
  {x:1653.0000,y:911.0000},
  {x:1656.3330,y:911.0000},
  {x:1659.6670,y:911.0000},
  {x:1663.0000,y:911.0000},
  {x:1664.3332,y:911.0000},
  {x:1665.6668,y:911.0000},
  {x:1667.0000,y:911.0000},
  {x:1667.0000,y:910.6667},
  {x:1667.0000,y:910.3333},
  {x:1667.0000,y:910.0000},
  {x:1671.6662,y:910.0000},
  {x:1676.3338,y:910.0000},
  {x:1681.0000,y:910.0000},
  {x:1681.0000,y:909.6667},
  {x:1681.0000,y:909.3333},
  {x:1681.0000,y:909.0000},
  {x:1685.6662,y:909.0000},
  {x:1690.3338,y:909.0000},
  {x:1695.0000,y:909.0000},
  {x:1695.0000,y:908.6667},
  {x:1695.0000,y:908.3333},
  {x:1695.0000,y:908.0000},
  {x:1699.3329,y:908.0000},
  {x:1703.6671,y:908.0000},
  {x:1708.0000,y:908.0000},
  {x:1708.0000,y:907.6667},
  {x:1708.0000,y:907.3333},
  {x:1708.0000,y:907.0000},
  {x:1712.3329,y:907.0000},
  {x:1716.6671,y:907.0000},
  {x:1721.0000,y:907.0000},
  {x:1721.0000,y:906.6667},
  {x:1721.0000,y:906.3333},
  {x:1721.0000,y:906.0000},
  {x:1724.9996,y:906.0000},
  {x:1729.0004,y:906.0000},
  {x:1733.0000,y:906.0000},
  {x:1733.0000,y:905.6667},
  {x:1733.0000,y:905.3333},
  {x:1733.0000,y:905.0000},
  {x:1736.6663,y:905.0000},
  {x:1740.3337,y:905.0000},
  {x:1744.0000,y:905.0000},
  {x:1744.3333,y:905.0000},
  {x:1744.6667,y:905.0000},
  {x:1745.0000,y:905.0000},
  {x:1745.0000,y:904.6667},
  {x:1745.0000,y:904.3333},
  {x:1745.0000,y:904.0000},
  {x:1748.9996,y:904.0000},
  {x:1753.0004,y:904.0000},
  {x:1757.0000,y:904.0000},
  {x:1757.0000,y:903.6667},
  {x:1757.0000,y:903.3333},
  {x:1757.0000,y:903.0000},
  {x:1760.9996,y:903.0000},
  {x:1765.0004,y:903.0000},
  {x:1769.0000,y:903.0000},
  {x:1769.0000,y:902.6667},
  {x:1769.0000,y:902.3333},
  {x:1769.0000,y:902.0000},
  {x:1772.6663,y:902.0000},
  {x:1776.3337,y:902.0000},
  {x:1780.0000,y:902.0000},
  {x:1780.0000,y:901.6667},
  {x:1780.0000,y:901.3333},
  {x:1780.0000,y:901.0000},
  {x:1783.6663,y:901.0000},
  {x:1787.3337,y:901.0000},
  {x:1791.0000,y:901.0000},
  {x:1800.3636,y:898.3101},
  {x:1813.7080,y:899.7136},
  {x:1823.0000,y:897.0000},
  {x:1826.3330,y:897.0000},
  {x:1829.6670,y:897.0000},
  {x:1833.0000,y:897.0000},
  {x:1844.8791,y:893.5802},
  {x:1861.1469,y:895.4691},
  {x:1873.0000,y:892.0000},
  {x:1875.9997,y:892.0000},
  {x:1879.0003,y:892.0000},
  {x:1882.0000,y:892.0000},
  {x:1890.2375,y:889.6226},
  {x:1901.8396,y:890.3905},
  {x:1910.0000,y:888.0000},
  {x:1912.9997,y:888.0000},
  {x:1916.0003,y:888.0000},
  {x:1919.0000,y:888.0000},
  {x:1919.0000,y:887.6667},
  {x:1919.0000,y:887.3333},
  {x:1919.0000,y:887.0000},
  {x:1921.9997,y:887.0000},
  {x:1925.0003,y:887.0000},
  {x:1928.0000,y:887.0000},
  {x:1928.0000,y:886.6667},
  {x:1928.0000,y:886.3333},
  {x:1928.0000,y:886.0000},
  {x:1930.9997,y:886.0000},
  {x:1934.0003,y:886.0000},
  {x:1937.0000,y:886.0000},
  {x:1937.0000,y:885.6667},
  {x:1937.0000,y:885.3333},
  {x:1937.0000,y:885.0000},
  {x:1939.9997,y:885.0000},
  {x:1943.0003,y:885.0000},
  {x:1946.0000,y:885.0000},
  {x:1946.0000,y:884.6667},
  {x:1946.0000,y:884.3333},
  {x:1946.0000,y:884.0000},
  {x:1948.9997,y:884.0000},
  {x:1952.0003,y:884.0000},
  {x:1955.0000,y:884.0000},
  {x:1955.0000,y:883.6667},
  {x:1955.0000,y:883.3333},
  {x:1955.0000,y:883.0000},
  {x:1957.9997,y:883.0000},
  {x:1961.0003,y:883.0000},
  {x:1964.0000,y:883.0000},
  {x:1976.5874,y:879.3559},
  {x:1993.4339,y:880.6866},
  {x:2006.0000,y:877.0000},
  {x:2008.9997,y:877.0000},
  {x:2012.0003,y:877.0000},
  {x:2015.0000,y:877.0000},
  {x:2015.0000,y:876.6667},
  {x:2015.0000,y:876.3333},
  {x:2015.0000,y:876.0000},
  {x:2017.9997,y:876.0000},
  {x:2021.0003,y:876.0000},
  {x:2024.0000,y:876.0000},
  {x:2024.0000,y:875.6667},
  {x:2024.0000,y:875.3333},
  {x:2024.0000,y:875.0000},
  {x:2026.9997,y:875.0000},
  {x:2030.0003,y:875.0000},
  {x:2033.0000,y:875.0000},
  {x:2033.0000,y:874.6667},
  {x:2033.0000,y:874.3333},
  {x:2033.0000,y:874.0000},
  {x:2035.9997,y:874.0000},
  {x:2039.0003,y:874.0000},
  {x:2042.0000,y:874.0000},
  {x:2042.0000,y:873.6667},
  {x:2042.0000,y:873.3333},
  {x:2042.0000,y:873.0000},
  {x:2044.9997,y:873.0000},
  {x:2048.0003,y:873.0000},
  {x:2051.0000,y:873.0000},
  {x:2051.0000,y:872.6667},
  {x:2051.0000,y:872.3333},
  {x:2051.0000,y:872.0000},
  {x:2062.3322,y:871.3334},
  {x:2073.6678,y:870.6666},
  {x:2085.0000,y:870.0000},
  {x:2085.0000,y:869.6667},
  {x:2085.0000,y:869.3333},
  {x:2085.0000,y:869.0000},
  {x:2088.3330,y:869.0000},
  {x:2091.6670,y:869.0000},
  {x:2095.0000,y:869.0000},
  {x:2095.0000,y:868.6667},
  {x:2095.0000,y:868.3333},
  {x:2095.0000,y:868.0000},
  {x:2097.9997,y:868.0000},
  {x:2101.0003,y:868.0000},
  {x:2104.0000,y:868.0000},
  {x:2117.3305,y:864.1442},
  {x:2134.8459,y:865.8799},
  {x:2148.0000,y:862.0000},
  {x:2153.3328,y:861.6667},
  {x:2158.6672,y:861.3333},
  {x:2164.0000,y:861.0000},
  {x:2164.0000,y:860.6667},
  {x:2164.0000,y:860.3333},
  {x:2164.0000,y:860.0000},
  {x:2168.9995,y:859.6667},
  {x:2174.0005,y:859.3333},
  {x:2179.0000,y:859.0000},
  {x:2192.4117,y:855.0588},
  {x:2209.7122,y:855.9352},
  {x:2223.0000,y:852.0000},
  {x:2230.3326,y:851.3334},
  {x:2237.6674,y:850.6666},
  {x:2245.0000,y:850.0000},
  {x:2259.3093,y:845.7741},
  {x:2277.7451,y:846.2827},
  {x:2292.0000,y:842.0000},
  {x:2296.6662,y:841.6667},
  {x:2301.3338,y:841.3333},
  {x:2306.0000,y:841.0000},
  {x:2319.7249,y:836.9143},
  {x:2337.2912,y:837.1200},
  {x:2351.0000,y:833.0000},
  {x:2353.3331,y:833.0000},
  {x:2355.6669,y:833.0000},
  {x:2358.0000,y:833.0000},
  {x:2367.3699,y:830.2093},
  {x:2379.7027,y:829.7947},
  {x:2389.0000,y:827.0000},
  {x:2391.3331,y:827.0000},
  {x:2393.6669,y:827.0000},
  {x:2396.0000,y:827.0000},
  {x:2401.4771,y:825.3681},
  {x:2409.4316,y:824.6865},
  {x:2415.0000,y:823.0000},
  {x:2416.9998,y:823.0000},
  {x:2419.0002,y:823.0000},
  {x:2421.0000,y:823.0000},
  {x:2421.0000,y:822.6667},
  {x:2421.0000,y:822.3333},
  {x:2421.0000,y:822.0000},
  {x:2424.9996,y:821.6667},
  {x:2429.0004,y:821.3333},
  {x:2433.0000,y:821.0000},
  {x:2433.0000,y:820.6667},
  {x:2433.0000,y:820.3333},
  {x:2433.0000,y:820.0000},
  {x:2439.3327,y:819.3334},
  {x:2445.6673,y:818.6666},
  {x:2452.0000,y:818.0000},
  {x:2462.9863,y:814.7260},
  {x:2477.0433,y:814.3407},
  {x:2488.0000,y:811.0000},
  {x:2489.9998,y:811.0000},
  {x:2492.0002,y:811.0000},
  {x:2494.0000,y:811.0000},
  {x:2499.1958,y:809.4300},
  {x:2506.8090,y:808.5826},
  {x:2512.0000,y:807.0000},
  {x:2519.6659,y:806.0001},
  {x:2527.3341,y:804.9999},
  {x:2535.0000,y:804.0000},
  {x:2540.1958,y:802.4302},
  {x:2547.8090,y:801.5827},
  {x:2553.0000,y:800.0000},
  {x:2554.9998,y:800.0000},
  {x:2557.0002,y:800.0000},
  {x:2559.0000,y:800.0000},
  {x:2574.4693,y:795.2625},
  {x:2593.4487,y:794.6018},
  {x:2609.0000,y:790.0000},
  {x:2615.3327,y:790.0000},
  {x:2621.6673,y:790.0000},
  {x:2628.0000,y:790.0000},
  {x:2628.0000,y:789.6667},
  {x:2628.0000,y:789.3333},
  {x:2628.0000,y:789.0000},
  {x:2632.3329,y:788.6667},
  {x:2636.6671,y:788.3333},
  {x:2641.0000,y:788.0000},
  {x:2641.0000,y:787.6667},
  {x:2641.0000,y:787.3333},
  {x:2641.0000,y:787.0000},
  {x:2642.3332,y:786.6667},
  {x:2643.6668,y:786.3333},
  {x:2645.0000,y:786.0000},
  {x:2645.0000,y:785.6667},
  {x:2645.0000,y:785.3333},
  {x:2645.0000,y:785.0000},
  {x:2645.6666,y:785.0000},
  {x:2646.3334,y:785.0000},
  {x:2647.0000,y:785.0000},
  {x:2647.3333,y:784.3334},
  {x:2647.6667,y:783.6666},
  {x:2648.0000,y:783.0000},
  {x:2650.7890,y:781.0986},
  {x:2652.0038,y:781.8227},
  {x:2653.0000,y:778.0000},
  {x:2653.3333,y:778.0000},
  {x:2653.6667,y:778.0000},
  {x:2654.0000,y:778.0000},
  {x:2653.3334,y:775.0003},
  {x:2652.6666,y:771.9997},
  {x:2652.0000,y:769.0000},
  {x:2651.3334,y:768.6667},
  {x:2650.6666,y:768.3333},
  {x:2650.0000,y:768.0000},
  {x:2649.6667,y:766.6668},
  {x:2649.3333,y:765.3332},
  {x:2649.0000,y:764.0000},
  {x:2648.3334,y:763.6667},
  {x:2647.6666,y:763.3333},
  {x:2647.0000,y:763.0000},
  {x:2647.0000,y:762.3334},
  {x:2647.0000,y:761.6666},
  {x:2647.0000,y:761.0000},
  {x:2646.3334,y:760.6667},
  {x:2645.6666,y:760.3333},
  {x:2645.0000,y:760.0000},
  {x:2645.0000,y:759.3334},
  {x:2645.0000,y:758.6666},
  {x:2645.0000,y:758.0000},
  {x:2644.3334,y:757.6667},
  {x:2643.6666,y:757.3333},
  {x:2643.0000,y:757.0000},
  {x:2643.0000,y:756.3334},
  {x:2643.0000,y:755.6666},
  {x:2643.0000,y:755.0000},
  {x:2642.6667,y:755.0000},
  {x:2642.3333,y:755.0000},
  {x:2642.0000,y:755.0000},
  {x:2642.0000,y:754.3334},
  {x:2642.0000,y:753.6666},
  {x:2642.0000,y:753.0000},
  {x:2641.6667,y:753.0000},
  {x:2641.3333,y:753.0000},
  {x:2641.0000,y:753.0000},
  {x:2641.0000,y:752.3334},
  {x:2641.0000,y:751.6666},
  {x:2641.0000,y:751.0000},
  {x:2640.6667,y:751.0000},
  {x:2640.3333,y:751.0000},
  {x:2640.0000,y:751.0000},
  {x:2640.0000,y:750.3334},
  {x:2640.0000,y:749.6666},
  {x:2640.0000,y:749.0000},
  {x:2639.6667,y:749.0000},
  {x:2639.3333,y:749.0000},
  {x:2639.0000,y:749.0000},
  {x:2639.0000,y:748.3334},
  {x:2639.0000,y:747.6666},
  {x:2639.0000,y:747.0000},
  {x:2638.3334,y:746.6667},
  {x:2637.6666,y:746.3333},
  {x:2637.0000,y:746.0000},
  {x:2636.3334,y:744.0002},
  {x:2635.6666,y:741.9998},
  {x:2635.0000,y:740.0000},
  {x:2634.6667,y:740.0000},
  {x:2634.3333,y:740.0000},
  {x:2634.0000,y:740.0000},
  {x:2633.6667,y:738.6668},
  {x:2633.3333,y:737.3332},
  {x:2633.0000,y:736.0000},
  {x:2632.6667,y:736.0000},
  {x:2632.3333,y:736.0000},
  {x:2632.0000,y:736.0000},
  {x:2632.0000,y:735.3334},
  {x:2632.0000,y:734.6666},
  {x:2632.0000,y:734.0000},
  {x:2631.6667,y:734.0000},
  {x:2631.3333,y:734.0000},
  {x:2631.0000,y:734.0000},
  {x:2631.0000,y:733.3334},
  {x:2631.0000,y:732.6666},
  {x:2631.0000,y:732.0000},
  {x:2630.6667,y:732.0000},
  {x:2630.3333,y:732.0000},
  {x:2630.0000,y:732.0000},
  {x:2630.0000,y:731.3334},
  {x:2630.0000,y:730.6666},
  {x:2630.0000,y:730.0000},
  {x:2629.6667,y:730.0000},
  {x:2629.3333,y:730.0000},
  {x:2629.0000,y:730.0000},
  {x:2629.0000,y:729.3334},
  {x:2629.0000,y:728.6666},
  {x:2629.0000,y:728.0000},
  {x:2628.6667,y:728.0000},
  {x:2628.3333,y:728.0000},
  {x:2628.0000,y:728.0000},
  {x:2627.6667,y:726.6668},
  {x:2627.3333,y:725.3332},
  {x:2627.0000,y:724.0000},
  {x:2626.6667,y:724.0000},
  {x:2626.3333,y:724.0000},
  {x:2626.0000,y:724.0000},
  {x:2625.6667,y:722.6668},
  {x:2625.3333,y:721.3332},
  {x:2625.0000,y:720.0000},
  {x:2624.6667,y:720.0000},
  {x:2624.3333,y:720.0000},
  {x:2624.0000,y:720.0000},
  {x:2624.0000,y:719.0001},
  {x:2624.0000,y:717.9999},
  {x:2624.0000,y:717.0000},
  {x:2623.6667,y:717.0000},
  {x:2623.3333,y:717.0000},
  {x:2623.0000,y:717.0000},
  {x:2623.0000,y:716.3334},
  {x:2623.0000,y:715.6666},
  {x:2623.0000,y:715.0000},
  {x:2622.6667,y:715.0000},
  {x:2622.3333,y:715.0000},
  {x:2622.0000,y:715.0000},
  {x:2621.6667,y:713.6668},
  {x:2621.3333,y:712.3332},
  {x:2621.0000,y:711.0000},
  {x:2620.6667,y:711.0000},
  {x:2620.3333,y:711.0000},
  {x:2620.0000,y:711.0000},
  {x:2619.6667,y:709.6668},
  {x:2619.3333,y:708.3332},
  {x:2619.0000,y:707.0000},
  {x:2618.6667,y:707.0000},
  {x:2618.3333,y:707.0000},
  {x:2618.0000,y:707.0000},
  {x:2618.0000,y:706.3334},
  {x:2618.0000,y:705.6666},
  {x:2618.0000,y:705.0000},
  {x:2617.6667,y:705.0000},
  {x:2617.3333,y:705.0000},
  {x:2617.0000,y:705.0000},
  {x:2617.0000,y:704.3334},
  {x:2617.0000,y:703.6666},
  {x:2617.0000,y:703.0000},
  {x:2616.6667,y:703.0000},
  {x:2616.3333,y:703.0000},
  {x:2616.0000,y:703.0000},
  {x:2616.0000,y:702.3334},
  {x:2616.0000,y:701.6666},
  {x:2616.0000,y:701.0000},
  {x:2615.6667,y:701.0000},
  {x:2615.3333,y:701.0000},
  {x:2615.0000,y:701.0000},
  {x:2615.0000,y:700.3334},
  {x:2615.0000,y:699.6666},
  {x:2615.0000,y:699.0000},
  {x:2614.6667,y:699.0000},
  {x:2614.3333,y:699.0000},
  {x:2614.0000,y:699.0000},
  {x:2611.6669,y:693.6672},
  {x:2609.3331,y:688.3328},
  {x:2607.0000,y:683.0000},
  {x:2606.3334,y:682.6667},
  {x:2605.6666,y:682.3333},
  {x:2605.0000,y:682.0000},
  {x:2604.3334,y:680.0002},
  {x:2603.6666,y:677.9998},
  {x:2603.0000,y:676.0000},
  {x:2602.6667,y:676.0000},
  {x:2602.3333,y:676.0000},
  {x:2602.0000,y:676.0000},
  {x:2601.6667,y:675.0001},
  {x:2601.3333,y:673.9999},
  {x:2601.0000,y:673.0000},
  {x:2600.3334,y:672.6667},
  {x:2599.6666,y:672.3333},
  {x:2599.0000,y:672.0000},
  {x:2599.0000,y:671.3334},
  {x:2599.0000,y:670.6666},
  {x:2599.0000,y:670.0000},
  {x:2598.3334,y:669.6667},
  {x:2597.6666,y:669.3333},
  {x:2597.0000,y:669.0000},
  {x:2596.6667,y:668.0001},
  {x:2596.3333,y:666.9999},
  {x:2596.0000,y:666.0000},
  {x:2594.6668,y:665.0001},
  {x:2593.3332,y:663.9999},
  {x:2592.0000,y:663.0000},
  {x:2592.0000,y:662.3334},
  {x:2592.0000,y:661.6666},
  {x:2592.0000,y:661.0000},
  {x:2589.3336,y:658.6669},
  {x:2586.6664,y:656.3331},
  {x:2584.0000,y:654.0000},
  {x:2581.6669,y:651.3336},
  {x:2579.3331,y:648.6664},
  {x:2577.0000,y:646.0000},
  {x:2576.3334,y:646.0000},
  {x:2575.6666,y:646.0000},
  {x:2575.0000,y:646.0000},
  {x:2574.0001,y:644.6668},
  {x:2572.9999,y:643.3332},
  {x:2572.0000,y:642.0000},
  {x:2571.3334,y:642.0000},
  {x:2570.6666,y:642.0000},
  {x:2570.0000,y:642.0000},
  {x:2569.3334,y:641.0001},
  {x:2568.6666,y:639.9999},
  {x:2568.0000,y:639.0000},
  {x:2567.3334,y:639.0000},
  {x:2566.6666,y:639.0000},
  {x:2566.0000,y:639.0000},
  {x:2565.6667,y:638.3334},
  {x:2565.3333,y:637.6666},
  {x:2565.0000,y:637.0000},
  {x:2564.3334,y:637.0000},
  {x:2563.6666,y:637.0000},
  {x:2563.0000,y:637.0000},
  {x:2562.3334,y:636.0001},
  {x:2561.6666,y:634.9999},
  {x:2561.0000,y:634.0000},
  {x:2559.6668,y:633.6667},
  {x:2558.3332,y:633.3333},
  {x:2557.0000,y:633.0000},
  {x:2557.0000,y:632.6667},
  {x:2557.0000,y:632.3333},
  {x:2557.0000,y:632.0000},
  {x:2556.0001,y:631.6667},
  {x:2554.9999,y:631.3333},
  {x:2554.0000,y:631.0000},
  {x:2553.6667,y:630.3334},
  {x:2553.3333,y:629.6666},
  {x:2553.0000,y:629.0000},
  {x:2552.3334,y:629.0000},
  {x:2551.6666,y:629.0000},
  {x:2551.0000,y:629.0000},
  {x:2550.3334,y:628.0001},
  {x:2549.6666,y:626.9999},
  {x:2549.0000,y:626.0000},
  {x:2548.3334,y:626.0000},
  {x:2547.6666,y:626.0000},
  {x:2547.0000,y:626.0000},
  {x:2546.3334,y:625.0001},
  {x:2545.6666,y:623.9999},
  {x:2545.0000,y:623.0000},
  {x:2544.3334,y:623.0000},
  {x:2543.6666,y:623.0000},
  {x:2543.0000,y:623.0000},
  {x:2542.3334,y:622.0001},
  {x:2541.6666,y:620.9999},
  {x:2541.0000,y:620.0000},
  {x:2540.3334,y:620.0000},
  {x:2539.6666,y:620.0000},
  {x:2539.0000,y:620.0000},
  {x:2538.6667,y:619.3334},
  {x:2538.3333,y:618.6666},
  {x:2538.0000,y:618.0000},
  {x:2537.3334,y:618.0000},
  {x:2536.6666,y:618.0000},
  {x:2536.0000,y:618.0000},
  {x:2535.6667,y:617.3334},
  {x:2535.3333,y:616.6666},
  {x:2535.0000,y:616.0000},
  {x:2534.3334,y:616.0000},
  {x:2533.6666,y:616.0000},
  {x:2533.0000,y:616.0000},
  {x:2532.6667,y:615.3334},
  {x:2532.3333,y:614.6666},
  {x:2532.0000,y:614.0000},
  {x:2531.3334,y:614.0000},
  {x:2530.6666,y:614.0000},
  {x:2530.0000,y:614.0000},
  {x:2529.6667,y:613.3334},
  {x:2529.3333,y:612.6666},
  {x:2529.0000,y:612.0000},
  {x:2527.6668,y:611.6667},
  {x:2526.3332,y:611.3333},
  {x:2525.0000,y:611.0000},
  {x:2525.0000,y:610.6667},
  {x:2525.0000,y:610.3333},
  {x:2525.0000,y:610.0000},
  {x:2524.3334,y:610.0000},
  {x:2523.6666,y:610.0000},
  {x:2523.0000,y:610.0000},
  {x:2523.0000,y:609.6667},
  {x:2523.0000,y:609.3333},
  {x:2523.0000,y:609.0000},
  {x:2519.6008,y:607.2032},
  {x:2515.6989,y:606.2143},
  {x:2511.0000,y:606.0000},
  {x:2511.0000,y:606.3333},
  {x:2511.0000,y:606.6667},
  {x:2511.0000,y:607.0000},
  {x:2511.3333,y:607.0000},
  {x:2511.6667,y:607.0000},
  {x:2512.0000,y:607.0000},
  {x:2513.1393,y:608.1386},
  {x:2512.0036,y:607.3984},
  {x:2514.0000,y:608.0000}
];
