import ContactUs from '@/components/info/contactUs'
import ButtonIcon from '@/components/form/buttonIcon'

import CloseIcon from '@/components/icons/close'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Faqs',

  components: {
    ContactUs,
    ButtonIcon,
    
    CloseIcon
  },

  data() {
    return {
      sectionId: 'nfts',

      sections: [
        {
          id: 'nfts',
          name: 'Nfts'
        },
        {
          id: 'sneakers',
          name: 'Sneakers'
        }
      ]
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideFAQs');
    },

    selectSection: function(id) {
      this.sectionId = id;
    }
  },

  mounted() {
    window.scrollTo(0,0);
  }
}
