module.exports = [
  {x:2146.0000,y:896.0000},
  {x:2146.3333,y:890.0006},
  {x:2146.6667,y:883.9994},
  {x:2147.0000,y:878.0000},
  {x:2146.6667,y:878.0000},
  {x:2146.3333,y:878.0000},
  {x:2146.0000,y:878.0000},
  {x:2146.0000,y:876.6668},
  {x:2146.0000,y:875.3332},
  {x:2146.0000,y:874.0000},
  {x:2145.6667,y:874.0000},
  {x:2145.3333,y:874.0000},
  {x:2145.0000,y:874.0000},
  {x:2145.0000,y:853.3354},
  {x:2145.0000,y:832.6646},
  {x:2145.0000,y:812.0000},
  {x:2145.0005,y:799.2276},
  {x:2146.9305,y:777.4147},
  {x:2144.0000,y:767.0000},
  {x:2144.0000,y:759.6674},
  {x:2144.0000,y:752.3326},
  {x:2144.0000,y:745.0000},
  {x:2144.0000,y:741.3337},
  {x:2144.0000,y:737.6663},
  {x:2144.0000,y:734.0000},
  {x:2143.6667,y:734.0000},
  {x:2143.3333,y:734.0000},
  {x:2143.0000,y:734.0000},
  {x:2141.2238,y:727.6923},
  {x:2143.7383,y:717.1693},
  {x:2142.0000,y:711.0000},
  {x:2140.4542,y:705.5140},
  {x:2142.3709,y:696.8627},
  {x:2141.0000,y:692.0000},
  {x:2141.0000,y:686.0006},
  {x:2141.0000,y:679.9994},
  {x:2141.0000,y:674.0000},
  {x:2140.6667,y:674.0000},
  {x:2140.3333,y:674.0000},
  {x:2140.0000,y:674.0000},
  {x:2140.0000,y:668.0006},
  {x:2140.0000,y:661.9994},
  {x:2140.0000,y:656.0000},
  {x:2139.6667,y:656.0000},
  {x:2139.3333,y:656.0000},
  {x:2139.0000,y:656.0000},
  {x:2138.6667,y:649.0007},
  {x:2138.3333,y:641.9993},
  {x:2138.0000,y:635.0000},
  {x:2138.0000,y:631.3337},
  {x:2138.0000,y:627.6663},
  {x:2138.0000,y:624.0000},
  {x:2137.6667,y:624.0000},
  {x:2137.3333,y:624.0000},
  {x:2137.0000,y:624.0000},
  {x:2137.0000,y:619.0005},
  {x:2137.0000,y:613.9995},
  {x:2137.0000,y:609.0000},
  {x:2136.6667,y:609.0000},
  {x:2136.3333,y:609.0000},
  {x:2136.0000,y:609.0000},
  {x:2136.0000,y:604.0005},
  {x:2136.0000,y:598.9995},
  {x:2136.0000,y:594.0000},
  {x:2135.6667,y:594.0000},
  {x:2135.3333,y:594.0000},
  {x:2135.0000,y:594.0000},
  {x:2135.0000,y:589.0005},
  {x:2135.0000,y:583.9995},
  {x:2135.0000,y:579.0000},
  {x:2134.6667,y:579.0000},
  {x:2134.3333,y:579.0000},
  {x:2134.0000,y:579.0000},
  {x:2134.0000,y:578.0001},
  {x:2134.0000,y:576.9999},
  {x:2134.0000,y:576.0000},
  {x:2134.0000,y:572.6670},
  {x:2134.0000,y:569.3330},
  {x:2134.0000,y:566.0000},
  {x:2133.6667,y:566.0000},
  {x:2133.3333,y:566.0000},
  {x:2133.0000,y:566.0000},
  {x:2133.0000,y:561.3338},
  {x:2133.0000,y:556.6662},
  {x:2133.0000,y:552.0000},
  {x:2132.6667,y:552.0000},
  {x:2132.3333,y:552.0000},
  {x:2132.0000,y:552.0000},
  {x:2131.6667,y:544.3341},
  {x:2131.3333,y:536.6659},
  {x:2131.0000,y:529.0000},
  {x:2130.6667,y:529.0000},
  {x:2130.3333,y:529.0000},
  {x:2130.0000,y:529.0000},
  {x:2130.0000,y:528.3334},
  {x:2130.0000,y:527.6666},
  {x:2130.0000,y:527.0000},
  {x:2129.3334,y:526.6667},
  {x:2128.6666,y:526.3333},
  {x:2128.0000,y:526.0000},
  {x:2127.6667,y:524.6668},
  {x:2127.3333,y:523.3332},
  {x:2127.0000,y:522.0000},
  {x:2126.6667,y:522.0000},
  {x:2126.3333,y:522.0000},
  {x:2126.0000,y:522.0000},
  {x:2125.6667,y:521.0001},
  {x:2125.3333,y:519.9999},
  {x:2125.0000,y:519.0000},
  {x:2124.3334,y:518.6667},
  {x:2123.6666,y:518.3333},
  {x:2123.0000,y:518.0000},
  {x:2122.6667,y:516.6668},
  {x:2122.3333,y:515.3332},
  {x:2122.0000,y:514.0000},
  {x:2121.3334,y:513.6667},
  {x:2120.6666,y:513.3333},
  {x:2120.0000,y:513.0000},
  {x:2119.6667,y:511.6668},
  {x:2119.3333,y:510.3332},
  {x:2119.0000,y:509.0000},
  {x:2118.3334,y:508.6667},
  {x:2117.6666,y:508.3333},
  {x:2117.0000,y:508.0000},
  {x:2116.6667,y:507.0001},
  {x:2116.3333,y:505.9999},
  {x:2116.0000,y:505.0000},
  {x:2115.6667,y:505.0000},
  {x:2115.3333,y:505.0000},
  {x:2115.0000,y:505.0000},
  {x:2114.3334,y:503.0002},
  {x:2113.6666,y:500.9998},
  {x:2113.0000,y:499.0000},
  {x:2112.3334,y:498.6667},
  {x:2111.6666,y:498.3333},
  {x:2111.0000,y:498.0000},
  {x:2110.6667,y:496.6668},
  {x:2110.3333,y:495.3332},
  {x:2110.0000,y:494.0000},
  {x:2109.3334,y:493.6667},
  {x:2108.6666,y:493.3333},
  {x:2108.0000,y:493.0000},
  {x:2108.0000,y:492.3334},
  {x:2108.0000,y:491.6666},
  {x:2108.0000,y:491.0000},
  {x:2107.6667,y:491.0000},
  {x:2107.3333,y:491.0000},
  {x:2107.0000,y:491.0000},
  {x:2107.0000,y:490.3334},
  {x:2107.0000,y:489.6666},
  {x:2107.0000,y:489.0000},
  {x:2106.3334,y:488.6667},
  {x:2105.6666,y:488.3333},
  {x:2105.0000,y:488.0000},
  {x:2104.3334,y:486.0002},
  {x:2103.6666,y:483.9998},
  {x:2103.0000,y:482.0000},
  {x:2102.3334,y:481.6667},
  {x:2101.6666,y:481.3333},
  {x:2101.0000,y:481.0000},
  {x:2100.6667,y:479.6668},
  {x:2100.3333,y:478.3332},
  {x:2100.0000,y:477.0000},
  {x:2099.3334,y:476.6667},
  {x:2098.6666,y:476.3333},
  {x:2098.0000,y:476.0000},
  {x:2097.6667,y:474.6668},
  {x:2097.3333,y:473.3332},
  {x:2097.0000,y:472.0000},
  {x:2096.3334,y:471.6667},
  {x:2095.6666,y:471.3333},
  {x:2095.0000,y:471.0000},
  {x:2094.3334,y:469.0002},
  {x:2093.6666,y:466.9998},
  {x:2093.0000,y:465.0000},
  {x:2092.3334,y:464.6667},
  {x:2091.6666,y:464.3333},
  {x:2091.0000,y:464.0000},
  {x:2090.6667,y:462.6668},
  {x:2090.3333,y:461.3332},
  {x:2090.0000,y:460.0000},
  {x:2089.3334,y:459.6667},
  {x:2088.6666,y:459.3333},
  {x:2088.0000,y:459.0000},
  {x:2087.6667,y:457.6668},
  {x:2087.3333,y:456.3332},
  {x:2087.0000,y:455.0000},
  {x:2086.3334,y:454.6667},
  {x:2085.6666,y:454.3333},
  {x:2085.0000,y:454.0000},
  {x:2084.6667,y:452.6668},
  {x:2084.3333,y:451.3332},
  {x:2084.0000,y:450.0000},
  {x:2083.3334,y:449.6667},
  {x:2082.6666,y:449.3333},
  {x:2082.0000,y:449.0000},
  {x:2081.6667,y:447.6668},
  {x:2081.3333,y:446.3332},
  {x:2081.0000,y:445.0000},
  {x:2080.6667,y:445.0000},
  {x:2080.3333,y:445.0000},
  {x:2080.0000,y:445.0000},
  {x:2079.6667,y:444.0001},
  {x:2079.3333,y:442.9999},
  {x:2079.0000,y:442.0000},
  {x:2078.3334,y:441.6667},
  {x:2077.6666,y:441.3333},
  {x:2077.0000,y:441.0000},
  {x:2076.6667,y:439.6668},
  {x:2076.3333,y:438.3332},
  {x:2076.0000,y:437.0000},
  {x:2075.6667,y:437.0000},
  {x:2075.3333,y:437.0000},
  {x:2075.0000,y:437.0000},
  {x:2074.6667,y:436.0001},
  {x:2074.3333,y:434.9999},
  {x:2074.0000,y:434.0000},
  {x:2073.3334,y:433.6667},
  {x:2072.6666,y:433.3333},
  {x:2072.0000,y:433.0000},
  {x:2071.6667,y:431.6668},
  {x:2071.3333,y:430.3332},
  {x:2071.0000,y:429.0000},
  {x:2070.3334,y:428.6667},
  {x:2069.6666,y:428.3333},
  {x:2069.0000,y:428.0000},
  {x:2069.0000,y:427.3334},
  {x:2069.0000,y:426.6666},
  {x:2069.0000,y:426.0000},
  {x:2068.3334,y:425.6667},
  {x:2067.6666,y:425.3333},
  {x:2067.0000,y:425.0000},
  {x:2067.0000,y:424.3334},
  {x:2067.0000,y:423.6666},
  {x:2067.0000,y:423.0000},
  {x:2066.3334,y:422.6667},
  {x:2065.6666,y:422.3333},
  {x:2065.0000,y:422.0000},
  {x:2065.0000,y:421.3334},
  {x:2065.0000,y:420.6666},
  {x:2065.0000,y:420.0000},
  {x:2064.6667,y:420.0000},
  {x:2064.3333,y:420.0000},
  {x:2064.0000,y:420.0000},
  {x:2063.6667,y:419.0001},
  {x:2063.3333,y:417.9999},
  {x:2063.0000,y:417.0000},
  {x:2062.3334,y:416.6667},
  {x:2061.6666,y:416.3333},
  {x:2061.0000,y:416.0000},
  {x:2061.0000,y:415.3334},
  {x:2061.0000,y:414.6666},
  {x:2061.0000,y:414.0000},
  {x:2060.0001,y:413.3334},
  {x:2058.9999,y:412.6666},
  {x:2058.0000,y:412.0000},
  {x:2058.0000,y:411.3334},
  {x:2058.0000,y:410.6666},
  {x:2058.0000,y:410.0000},
  {x:2057.3334,y:409.6667},
  {x:2056.6666,y:409.3333},
  {x:2056.0000,y:409.0000},
  {x:2056.0000,y:408.3334},
  {x:2056.0000,y:407.6666},
  {x:2056.0000,y:407.0000},
  {x:2055.0001,y:406.3334},
  {x:2053.9999,y:405.6666},
  {x:2053.0000,y:405.0000},
  {x:2053.0000,y:404.3334},
  {x:2053.0000,y:403.6666},
  {x:2053.0000,y:403.0000},
  {x:2052.0001,y:402.3334},
  {x:2050.9999,y:401.6666},
  {x:2050.0000,y:401.0000},
  {x:2050.0000,y:400.3334},
  {x:2050.0000,y:399.6666},
  {x:2050.0000,y:399.0000},
  {x:2049.0001,y:398.3334},
  {x:2047.9999,y:397.6666},
  {x:2047.0000,y:397.0000},
  {x:2047.0000,y:396.3334},
  {x:2047.0000,y:395.6666},
  {x:2047.0000,y:395.0000},
  {x:2046.0001,y:394.3334},
  {x:2044.9999,y:393.6666},
  {x:2044.0000,y:393.0000},
  {x:2044.0000,y:392.3334},
  {x:2044.0000,y:391.6666},
  {x:2044.0000,y:391.0000},
  {x:2042.3335,y:389.6668},
  {x:2040.6665,y:388.3332},
  {x:2039.0000,y:387.0000},
  {x:2039.0000,y:386.3334},
  {x:2039.0000,y:385.6666},
  {x:2039.0000,y:385.0000},
  {x:2037.0002,y:383.3335},
  {x:2034.9998,y:381.6665},
  {x:2033.0000,y:380.0000},
  {x:2033.0000,y:379.3334},
  {x:2033.0000,y:378.6666},
  {x:2033.0000,y:378.0000},
  {x:2029.3337,y:374.6670},
  {x:2025.6663,y:371.3330},
  {x:2022.0000,y:368.0000},
  {x:2018.0004,y:363.6671},
  {x:2013.9996,y:359.3329},
  {x:2010.0000,y:355.0000},
  {x:2009.3334,y:355.0000},
  {x:2008.6666,y:355.0000},
  {x:2008.0000,y:355.0000},
  {x:2007.0001,y:353.6668},
  {x:2005.9999,y:352.3332},
  {x:2005.0000,y:351.0000},
  {x:2004.3334,y:351.0000},
  {x:2003.6666,y:351.0000},
  {x:2003.0000,y:351.0000},
  {x:2002.6667,y:350.3334},
  {x:2002.3333,y:349.6666},
  {x:2002.0000,y:349.0000},
  {x:2001.3334,y:349.0000},
  {x:2000.6666,y:349.0000},
  {x:2000.0000,y:349.0000},
  {x:1999.6667,y:348.3334},
  {x:1999.3333,y:347.6666},
  {x:1999.0000,y:347.0000},
  {x:1997.0002,y:346.3334},
  {x:1994.9998,y:345.6666},
  {x:1993.0000,y:345.0000},
  {x:1993.0000,y:344.6667},
  {x:1993.0000,y:344.3333},
  {x:1993.0000,y:344.0000},
  {x:1991.0002,y:343.3334},
  {x:1988.9998,y:342.6666},
  {x:1987.0000,y:342.0000},
  {x:1987.0000,y:341.6667},
  {x:1987.0000,y:341.3333},
  {x:1987.0000,y:341.0000},
  {x:1986.0001,y:341.0000},
  {x:1984.9999,y:341.0000},
  {x:1984.0000,y:341.0000},
  {x:1984.0000,y:340.6667},
  {x:1984.0000,y:340.3333},
  {x:1984.0000,y:340.0000},
  {x:1983.3334,y:340.0000},
  {x:1982.6666,y:340.0000},
  {x:1982.0000,y:340.0000},
  {x:1982.0000,y:339.6667},
  {x:1982.0000,y:339.3333},
  {x:1982.0000,y:339.0000},
  {x:1971.6677,y:336.3336},
  {x:1961.3323,y:333.6664},
  {x:1951.0000,y:331.0000},
  {x:1948.0003,y:331.0000},
  {x:1944.9997,y:331.0000},
  {x:1942.0000,y:331.0000},
  {x:1942.0000,y:330.6667},
  {x:1942.0000,y:330.3333},
  {x:1942.0000,y:330.0000},
  {x:1939.3336,y:330.0000},
  {x:1936.6664,y:330.0000},
  {x:1934.0000,y:330.0000},
  {x:1934.0000,y:329.6667},
  {x:1934.0000,y:329.3333},
  {x:1934.0000,y:329.0000},
  {x:1931.3336,y:329.0000},
  {x:1928.6664,y:329.0000},
  {x:1926.0000,y:329.0000},
  {x:1926.0000,y:328.6667},
  {x:1926.0000,y:328.3333},
  {x:1926.0000,y:328.0000},
  {x:1916.3343,y:327.0001},
  {x:1906.6657,y:325.9999},
  {x:1897.0000,y:325.0000},
  {x:1897.0000,y:324.6667},
  {x:1897.0000,y:324.3333},
  {x:1897.0000,y:324.0000},
  {x:1895.0002,y:324.0000},
  {x:1892.9998,y:324.0000},
  {x:1891.0000,y:324.0000},
  {x:1891.0000,y:323.6667},
  {x:1891.0000,y:323.3333},
  {x:1891.0000,y:323.0000},
  {x:1889.0002,y:323.0000},
  {x:1886.9998,y:323.0000},
  {x:1885.0000,y:323.0000},
  {x:1885.0000,y:322.6667},
  {x:1885.0000,y:322.3333},
  {x:1885.0000,y:322.0000},
  {x:1883.3335,y:322.0000},
  {x:1881.6665,y:322.0000},
  {x:1880.0000,y:322.0000},
  {x:1880.0000,y:321.6667},
  {x:1880.0000,y:321.3333},
  {x:1880.0000,y:321.0000},
  {x:1878.0002,y:321.0000},
  {x:1875.9998,y:321.0000},
  {x:1874.0000,y:321.0000},
  {x:1874.0000,y:320.6667},
  {x:1874.0000,y:320.3333},
  {x:1874.0000,y:320.0000},
  {x:1871.0003,y:319.6667},
  {x:1867.9997,y:319.3333},
  {x:1865.0000,y:319.0000},
  {x:1865.0000,y:318.6667},
  {x:1865.0000,y:318.3333},
  {x:1865.0000,y:318.0000},
  {x:1863.6668,y:318.0000},
  {x:1862.3332,y:318.0000},
  {x:1861.0000,y:318.0000},
  {x:1861.0000,y:317.6667},
  {x:1861.0000,y:317.3333},
  {x:1861.0000,y:317.0000},
  {x:1859.6668,y:317.0000},
  {x:1858.3332,y:317.0000},
  {x:1857.0000,y:317.0000},
  {x:1857.0000,y:316.6667},
  {x:1857.0000,y:316.3333},
  {x:1857.0000,y:316.0000},
  {x:1856.0001,y:316.0000},
  {x:1854.9999,y:316.0000},
  {x:1854.0000,y:316.0000},
  {x:1854.0000,y:315.6667},
  {x:1854.0000,y:315.3333},
  {x:1854.0000,y:315.0000},
  {x:1850.6670,y:314.6667},
  {x:1847.3330,y:314.3333},
  {x:1844.0000,y:314.0000},
  {x:1844.0000,y:313.6667},
  {x:1844.0000,y:313.3333},
  {x:1844.0000,y:313.0000},
  {x:1843.3334,y:313.0000},
  {x:1842.6666,y:313.0000},
  {x:1842.0000,y:313.0000},
  {x:1842.0000,y:312.6667},
  {x:1842.0000,y:312.3333},
  {x:1842.0000,y:312.0000},
  {x:1835.0007,y:310.0002},
  {x:1827.9993,y:307.9998},
  {x:1821.0000,y:306.0000},
  {x:1808.6679,y:300.3339},
  {x:1796.3321,y:294.6661},
  {x:1784.0000,y:289.0000},
  {x:1783.6667,y:288.3334},
  {x:1783.3333,y:287.6666},
  {x:1783.0000,y:287.0000},
  {x:1782.3334,y:287.0000},
  {x:1781.6666,y:287.0000},
  {x:1781.0000,y:287.0000},
  {x:1781.0000,y:286.6667},
  {x:1781.0000,y:286.3333},
  {x:1781.0000,y:286.0000},
  {x:1780.3334,y:286.0000},
  {x:1779.6666,y:286.0000},
  {x:1779.0000,y:286.0000},
  {x:1779.0000,y:285.6667},
  {x:1779.0000,y:285.3333},
  {x:1779.0000,y:285.0000},
  {x:1778.3334,y:285.0000},
  {x:1777.6666,y:285.0000},
  {x:1777.0000,y:285.0000},
  {x:1777.0000,y:284.6667},
  {x:1777.0000,y:284.3333},
  {x:1777.0000,y:284.0000},
  {x:1776.3334,y:284.0000},
  {x:1775.6666,y:284.0000},
  {x:1775.0000,y:284.0000},
  {x:1775.0000,y:283.6667},
  {x:1775.0000,y:283.3333},
  {x:1775.0000,y:283.0000},
  {x:1774.0001,y:282.6667},
  {x:1772.9999,y:282.3333},
  {x:1772.0000,y:282.0000},
  {x:1771.6667,y:281.3334},
  {x:1771.3333,y:280.6666},
  {x:1771.0000,y:280.0000},
  {x:1769.6668,y:279.6667},
  {x:1768.3332,y:279.3333},
  {x:1767.0000,y:279.0000},
  {x:1766.6667,y:278.3334},
  {x:1766.3333,y:277.6666},
  {x:1766.0000,y:277.0000},
  {x:1765.3334,y:277.0000},
  {x:1764.6666,y:277.0000},
  {x:1764.0000,y:277.0000},
  {x:1763.3334,y:276.0001},
  {x:1762.6666,y:274.9999},
  {x:1762.0000,y:274.0000},
  {x:1761.3334,y:274.0000},
  {x:1760.6666,y:274.0000},
  {x:1760.0000,y:274.0000},
  {x:1759.0001,y:272.6668},
  {x:1757.9999,y:271.3332},
  {x:1757.0000,y:270.0000},
  {x:1756.3334,y:270.0000},
  {x:1755.6666,y:270.0000},
  {x:1755.0000,y:270.0000},
  {x:1754.0001,y:268.6668},
  {x:1752.9999,y:267.3332},
  {x:1752.0000,y:266.0000},
  {x:1751.6667,y:266.0000},
  {x:1751.3333,y:266.0000},
  {x:1751.0000,y:266.0000},
  {x:1751.0000,y:266.3333},
  {x:1751.0000,y:266.6667},
  {x:1751.0000,y:267.0000},
  {x:1749.6668,y:267.0000},
  {x:1748.3332,y:267.0000},
  {x:1747.0000,y:267.0000},
  {x:1742.0005,y:269.9997},
  {x:1736.9995,y:273.0003},
  {x:1732.0000,y:276.0000},
  {x:1731.3334,y:276.0000},
  {x:1730.6666,y:276.0000},
  {x:1730.0000,y:276.0000},
  {x:1730.0000,y:276.3333},
  {x:1730.0000,y:276.6667},
  {x:1730.0000,y:277.0000},
  {x:1729.3334,y:277.0000},
  {x:1728.6666,y:277.0000},
  {x:1728.0000,y:277.0000},
  {x:1728.0000,y:277.3333},
  {x:1728.0000,y:277.6667},
  {x:1728.0000,y:278.0000},
  {x:1727.3334,y:278.0000},
  {x:1726.6666,y:278.0000},
  {x:1726.0000,y:278.0000},
  {x:1726.0000,y:278.3333},
  {x:1726.0000,y:278.6667},
  {x:1726.0000,y:279.0000},
  {x:1725.3334,y:279.0000},
  {x:1724.6666,y:279.0000},
  {x:1724.0000,y:279.0000},
  {x:1724.0000,y:279.3333},
  {x:1724.0000,y:279.6667},
  {x:1724.0000,y:280.0000},
  {x:1723.3334,y:280.0000},
  {x:1722.6666,y:280.0000},
  {x:1722.0000,y:280.0000},
  {x:1722.0000,y:280.3333},
  {x:1722.0000,y:280.6667},
  {x:1722.0000,y:281.0000},
  {x:1721.3334,y:281.0000},
  {x:1720.6666,y:281.0000},
  {x:1720.0000,y:281.0000},
  {x:1720.0000,y:281.3333},
  {x:1720.0000,y:281.6667},
  {x:1720.0000,y:282.0000},
  {x:1718.6668,y:282.3333},
  {x:1717.3332,y:282.6667},
  {x:1716.0000,y:283.0000},
  {x:1716.0000,y:283.3333},
  {x:1716.0000,y:283.6667},
  {x:1716.0000,y:284.0000},
  {x:1715.0001,y:284.0000},
  {x:1713.9999,y:284.0000},
  {x:1713.0000,y:284.0000},
  {x:1713.0000,y:284.3333},
  {x:1713.0000,y:284.6667},
  {x:1713.0000,y:285.0000},
  {x:1712.3334,y:285.0000},
  {x:1711.6666,y:285.0000},
  {x:1711.0000,y:285.0000},
  {x:1711.0000,y:285.3333},
  {x:1711.0000,y:285.6667},
  {x:1711.0000,y:286.0000},
  {x:1709.0002,y:286.6666},
  {x:1706.9998,y:287.3334},
  {x:1705.0000,y:288.0000},
  {x:1705.0000,y:288.3333},
  {x:1705.0000,y:288.6667},
  {x:1705.0000,y:289.0000},
  {x:1704.0001,y:289.0000},
  {x:1702.9999,y:289.0000},
  {x:1702.0000,y:289.0000},
  {x:1702.0000,y:289.3333},
  {x:1702.0000,y:289.6667},
  {x:1702.0000,y:290.0000},
  {x:1701.3334,y:290.0000},
  {x:1700.6666,y:290.0000},
  {x:1700.0000,y:290.0000},
  {x:1700.0000,y:290.3333},
  {x:1700.0000,y:290.6667},
  {x:1700.0000,y:291.0000},
  {x:1699.3334,y:291.0000},
  {x:1698.6666,y:291.0000},
  {x:1698.0000,y:291.0000},
  {x:1698.0000,y:291.3333},
  {x:1698.0000,y:291.6667},
  {x:1698.0000,y:292.0000},
  {x:1697.3334,y:292.0000},
  {x:1696.6666,y:292.0000},
  {x:1696.0000,y:292.0000},
  {x:1696.0000,y:292.3333},
  {x:1696.0000,y:292.6667},
  {x:1696.0000,y:293.0000},
  {x:1695.0001,y:293.0000},
  {x:1693.9999,y:293.0000},
  {x:1693.0000,y:293.0000},
  {x:1693.0000,y:293.3333},
  {x:1693.0000,y:293.6667},
  {x:1693.0000,y:294.0000},
  {x:1692.3334,y:294.0000},
  {x:1691.6666,y:294.0000},
  {x:1691.0000,y:294.0000},
  {x:1691.0000,y:294.3333},
  {x:1691.0000,y:294.6667},
  {x:1691.0000,y:295.0000},
  {x:1690.0001,y:295.0000},
  {x:1688.9999,y:295.0000},
  {x:1688.0000,y:295.0000},
  {x:1688.0000,y:295.3333},
  {x:1688.0000,y:295.6667},
  {x:1688.0000,y:296.0000},
  {x:1686.6668,y:296.3333},
  {x:1685.3332,y:296.6667},
  {x:1684.0000,y:297.0000},
  {x:1684.0000,y:297.3333},
  {x:1684.0000,y:297.6667},
  {x:1684.0000,y:298.0000},
  {x:1683.0001,y:298.0000},
  {x:1681.9999,y:298.0000},
  {x:1681.0000,y:298.0000},
  {x:1681.0000,y:298.3333},
  {x:1681.0000,y:298.6667},
  {x:1681.0000,y:299.0000},
  {x:1680.3334,y:299.0000},
  {x:1679.6666,y:299.0000},
  {x:1679.0000,y:299.0000},
  {x:1679.0000,y:299.3333},
  {x:1679.0000,y:299.6667},
  {x:1679.0000,y:300.0000},
  {x:1677.0002,y:300.3333},
  {x:1674.9998,y:300.6667},
  {x:1673.0000,y:301.0000},
  {x:1673.0000,y:301.3333},
  {x:1673.0000,y:301.6667},
  {x:1673.0000,y:302.0000},
  {x:1672.3334,y:302.0000},
  {x:1671.6666,y:302.0000},
  {x:1671.0000,y:302.0000},
  {x:1671.0000,y:302.3333},
  {x:1671.0000,y:302.6667},
  {x:1671.0000,y:303.0000},
  {x:1658.0013,y:307.3329},
  {x:1644.9987,y:311.6671},
  {x:1632.0000,y:316.0000},
  {x:1630.0002,y:316.0000},
  {x:1627.9998,y:316.0000},
  {x:1626.0000,y:316.0000},
  {x:1626.0000,y:316.3333},
  {x:1626.0000,y:316.6667},
  {x:1626.0000,y:317.0000},
  {x:1624.3335,y:317.0000},
  {x:1622.6665,y:317.0000},
  {x:1621.0000,y:317.0000},
  {x:1621.0000,y:317.3333},
  {x:1621.0000,y:317.6667},
  {x:1621.0000,y:318.0000},
  {x:1619.0002,y:318.0000},
  {x:1616.9998,y:318.0000},
  {x:1615.0000,y:318.0000},
  {x:1615.0000,y:318.3333},
  {x:1615.0000,y:318.6667},
  {x:1615.0000,y:319.0000},
  {x:1613.0002,y:319.0000},
  {x:1610.9998,y:319.0000},
  {x:1609.0000,y:319.0000},
  {x:1609.0000,y:319.3333},
  {x:1609.0000,y:319.6667},
  {x:1609.0000,y:320.0000},
  {x:1607.0002,y:320.0000},
  {x:1604.9998,y:320.0000},
  {x:1603.0000,y:320.0000},
  {x:1603.0000,y:320.3333},
  {x:1603.0000,y:320.6667},
  {x:1603.0000,y:321.0000},
  {x:1601.0002,y:321.0000},
  {x:1598.9998,y:321.0000},
  {x:1597.0000,y:321.0000},
  {x:1597.0000,y:321.3333},
  {x:1597.0000,y:321.6667},
  {x:1597.0000,y:322.0000},
  {x:1595.0002,y:322.0000},
  {x:1592.9998,y:322.0000},
  {x:1591.0000,y:322.0000},
  {x:1591.0000,y:322.3333},
  {x:1591.0000,y:322.6667},
  {x:1591.0000,y:323.0000},
  {x:1589.0002,y:323.0000},
  {x:1586.9998,y:323.0000},
  {x:1585.0000,y:323.0000},
  {x:1585.0000,y:323.3333},
  {x:1585.0000,y:323.6667},
  {x:1585.0000,y:324.0000},
  {x:1582.3336,y:324.0000},
  {x:1579.6664,y:324.0000},
  {x:1577.0000,y:324.0000},
  {x:1577.0000,y:324.3333},
  {x:1577.0000,y:324.6667},
  {x:1577.0000,y:325.0000},
  {x:1574.3336,y:325.0000},
  {x:1571.6664,y:325.0000},
  {x:1569.0000,y:325.0000},
  {x:1569.0000,y:325.3333},
  {x:1569.0000,y:325.6667},
  {x:1569.0000,y:326.0000},
  {x:1565.6670,y:326.0000},
  {x:1562.3330,y:326.0000},
  {x:1559.0000,y:326.0000},
  {x:1559.0000,y:326.3333},
  {x:1559.0000,y:326.6667},
  {x:1559.0000,y:327.0000},
  {x:1555.0004,y:327.0000},
  {x:1550.9996,y:327.0000},
  {x:1547.0000,y:327.0000},
  {x:1544.0003,y:327.0000},
  {x:1540.9997,y:327.0000},
  {x:1538.0000,y:327.0000},
  {x:1538.0000,y:327.3333},
  {x:1538.0000,y:327.6667},
  {x:1538.0000,y:328.0000},
  {x:1533.6671,y:327.6667},
  {x:1529.3329,y:327.3333},
  {x:1525.0000,y:327.0000},
  {x:1507.7281,y:326.2412},
  {x:1489.1763,y:328.0369},
  {x:1475.0000,y:324.0000},
  {x:1472.0003,y:324.0000},
  {x:1468.9997,y:324.0000},
  {x:1466.0000,y:324.0000},
  {x:1456.9951,y:321.3907},
  {x:1444.8631,y:321.6264},
  {x:1436.0000,y:319.0000},
  {x:1428.3341,y:318.0001},
  {x:1420.6659,y:316.9999},
  {x:1413.0000,y:316.0000},
  {x:1413.0000,y:315.6667},
  {x:1413.0000,y:315.3333},
  {x:1413.0000,y:315.0000},
  {x:1410.0003,y:314.6667},
  {x:1406.9997,y:314.3333},
  {x:1404.0000,y:314.0000},
  {x:1404.0000,y:313.6667},
  {x:1404.0000,y:313.3333},
  {x:1404.0000,y:313.0000},
  {x:1402.6668,y:313.0000},
  {x:1401.3332,y:313.0000},
  {x:1400.0000,y:313.0000},
  {x:1400.0000,y:312.6667},
  {x:1400.0000,y:312.3333},
  {x:1400.0000,y:312.0000},
  {x:1398.6668,y:312.0000},
  {x:1397.3332,y:312.0000},
  {x:1396.0000,y:312.0000},
  {x:1396.0000,y:311.6667},
  {x:1396.0000,y:311.3333},
  {x:1396.0000,y:311.0000},
  {x:1394.6668,y:311.0000},
  {x:1393.3332,y:311.0000},
  {x:1392.0000,y:311.0000},
  {x:1392.0000,y:310.6667},
  {x:1392.0000,y:310.3333},
  {x:1392.0000,y:310.0000},
  {x:1389.3336,y:309.6667},
  {x:1386.6664,y:309.3333},
  {x:1384.0000,y:309.0000},
  {x:1373.7985,y:305.3254},
  {x:1361.8664,y:301.9324},
  {x:1352.0000,y:298.0000},
  {x:1351.0001,y:298.0000},
  {x:1349.9999,y:298.0000},
  {x:1349.0000,y:298.0000},
  {x:1349.0000,y:297.6667},
  {x:1349.0000,y:297.3333},
  {x:1349.0000,y:297.0000},
  {x:1348.0001,y:297.0000},
  {x:1346.9999,y:297.0000},
  {x:1346.0000,y:297.0000},
  {x:1346.0000,y:296.6667},
  {x:1346.0000,y:296.3333},
  {x:1346.0000,y:296.0000},
  {x:1345.0001,y:296.0000},
  {x:1343.9999,y:296.0000},
  {x:1343.0000,y:296.0000},
  {x:1343.0000,y:295.6667},
  {x:1343.0000,y:295.3333},
  {x:1343.0000,y:295.0000},
  {x:1342.0001,y:295.0000},
  {x:1340.9999,y:295.0000},
  {x:1340.0000,y:295.0000},
  {x:1340.0000,y:294.6667},
  {x:1340.0000,y:294.3333},
  {x:1340.0000,y:294.0000},
  {x:1339.3334,y:294.0000},
  {x:1338.6666,y:294.0000},
  {x:1338.0000,y:294.0000},
  {x:1338.0000,y:293.6667},
  {x:1338.0000,y:293.3333},
  {x:1338.0000,y:293.0000},
  {x:1335.0003,y:292.3334},
  {x:1331.9997,y:291.6666},
  {x:1329.0000,y:291.0000},
  {x:1329.0000,y:290.6667},
  {x:1329.0000,y:290.3333},
  {x:1329.0000,y:290.0000},
  {x:1328.3334,y:290.0000},
  {x:1327.6666,y:290.0000},
  {x:1327.0000,y:290.0000},
  {x:1327.0000,y:289.6667},
  {x:1327.0000,y:289.3333},
  {x:1327.0000,y:289.0000},
  {x:1325.0002,y:288.6667},
  {x:1322.9998,y:288.3333},
  {x:1321.0000,y:288.0000},
  {x:1321.0000,y:287.6667},
  {x:1321.0000,y:287.3333},
  {x:1321.0000,y:287.0000},
  {x:1319.3335,y:286.6667},
  {x:1317.6665,y:286.3333},
  {x:1316.0000,y:286.0000},
  {x:1316.0000,y:285.6667},
  {x:1316.0000,y:285.3333},
  {x:1316.0000,y:285.0000},
  {x:1315.3334,y:285.0000},
  {x:1314.6666,y:285.0000},
  {x:1314.0000,y:285.0000},
  {x:1314.0000,y:284.6667},
  {x:1314.0000,y:284.3333},
  {x:1314.0000,y:284.0000},
  {x:1313.0001,y:284.0000},
  {x:1311.9999,y:284.0000},
  {x:1311.0000,y:284.0000},
  {x:1311.0000,y:283.6667},
  {x:1311.0000,y:283.3333},
  {x:1311.0000,y:283.0000},
  {x:1309.6668,y:282.6667},
  {x:1308.3332,y:282.3333},
  {x:1307.0000,y:282.0000},
  {x:1307.0000,y:281.6667},
  {x:1307.0000,y:281.3333},
  {x:1307.0000,y:281.0000},
  {x:1306.0001,y:281.0000},
  {x:1304.9999,y:281.0000},
  {x:1304.0000,y:281.0000},
  {x:1304.0000,y:280.6667},
  {x:1304.0000,y:280.3333},
  {x:1304.0000,y:280.0000},
  {x:1303.3334,y:280.0000},
  {x:1302.6666,y:280.0000},
  {x:1302.0000,y:280.0000},
  {x:1302.0000,y:279.6667},
  {x:1302.0000,y:279.3333},
  {x:1302.0000,y:279.0000},
  {x:1301.0001,y:279.0000},
  {x:1299.9999,y:279.0000},
  {x:1299.0000,y:279.0000},
  {x:1299.0000,y:278.6667},
  {x:1299.0000,y:278.3333},
  {x:1299.0000,y:278.0000},
  {x:1297.6668,y:277.6667},
  {x:1296.3332,y:277.3333},
  {x:1295.0000,y:277.0000},
  {x:1295.0000,y:276.6667},
  {x:1295.0000,y:276.3333},
  {x:1295.0000,y:276.0000},
  {x:1294.0001,y:276.0000},
  {x:1292.9999,y:276.0000},
  {x:1292.0000,y:276.0000},
  {x:1292.0000,y:275.6667},
  {x:1292.0000,y:275.3333},
  {x:1292.0000,y:275.0000},
  {x:1291.3334,y:275.0000},
  {x:1290.6666,y:275.0000},
  {x:1290.0000,y:275.0000},
  {x:1290.0000,y:274.6667},
  {x:1290.0000,y:274.3333},
  {x:1290.0000,y:274.0000},
  {x:1288.6668,y:273.6667},
  {x:1287.3332,y:273.3333},
  {x:1286.0000,y:273.0000},
  {x:1286.0000,y:272.6667},
  {x:1286.0000,y:272.3333},
  {x:1286.0000,y:272.0000},
  {x:1285.0001,y:272.0000},
  {x:1283.9999,y:272.0000},
  {x:1283.0000,y:272.0000},
  {x:1283.0000,y:271.6667},
  {x:1283.0000,y:271.3333},
  {x:1283.0000,y:271.0000},
  {x:1282.3334,y:271.0000},
  {x:1281.6666,y:271.0000},
  {x:1281.0000,y:271.0000},
  {x:1281.0000,y:270.6667},
  {x:1281.0000,y:270.3333},
  {x:1281.0000,y:270.0000},
  {x:1280.3334,y:270.0000},
  {x:1279.6666,y:270.0000},
  {x:1279.0000,y:270.0000},
  {x:1279.0000,y:269.6667},
  {x:1279.0000,y:269.3333},
  {x:1279.0000,y:269.0000},
  {x:1278.3334,y:269.0000},
  {x:1277.6666,y:269.0000},
  {x:1277.0000,y:269.0000},
  {x:1277.0000,y:268.6667},
  {x:1277.0000,y:268.3333},
  {x:1277.0000,y:268.0000},
  {x:1276.0001,y:268.0000},
  {x:1274.9999,y:268.0000},
  {x:1274.0000,y:268.0000},
  {x:1274.0000,y:267.6667},
  {x:1274.0000,y:267.3333},
  {x:1274.0000,y:267.0000},
  {x:1273.3334,y:267.0000},
  {x:1272.6666,y:267.0000},
  {x:1272.0000,y:267.0000},
  {x:1272.0000,y:266.6667},
  {x:1272.0000,y:266.3333},
  {x:1272.0000,y:266.0000},
  {x:1270.0002,y:265.3334},
  {x:1267.9998,y:264.6666},
  {x:1266.0000,y:264.0000},
  {x:1266.0000,y:263.6667},
  {x:1266.0000,y:263.3333},
  {x:1266.0000,y:263.0000},
  {x:1265.0001,y:263.0000},
  {x:1263.9999,y:263.0000},
  {x:1263.0000,y:263.0000},
  {x:1263.0000,y:262.6667},
  {x:1263.0000,y:262.3333},
  {x:1263.0000,y:262.0000},
  {x:1262.3334,y:262.0000},
  {x:1261.6666,y:262.0000},
  {x:1261.0000,y:262.0000},
  {x:1261.0000,y:261.6667},
  {x:1261.0000,y:261.3333},
  {x:1261.0000,y:261.0000},
  {x:1259.6668,y:260.6667},
  {x:1258.3332,y:260.3333},
  {x:1257.0000,y:260.0000},
  {x:1257.0000,y:259.6667},
  {x:1257.0000,y:259.3333},
  {x:1257.0000,y:259.0000},
  {x:1255.6668,y:258.6667},
  {x:1254.3332,y:258.3333},
  {x:1253.0000,y:258.0000},
  {x:1253.0000,y:257.6667},
  {x:1253.0000,y:257.3333},
  {x:1253.0000,y:257.0000},
  {x:1251.6668,y:256.6667},
  {x:1250.3332,y:256.3333},
  {x:1249.0000,y:256.0000},
  {x:1249.0000,y:255.6667},
  {x:1249.0000,y:255.3333},
  {x:1249.0000,y:255.0000},
  {x:1248.3334,y:255.0000},
  {x:1247.6666,y:255.0000},
  {x:1247.0000,y:255.0000},
  {x:1247.0000,y:254.6667},
  {x:1247.0000,y:254.3333},
  {x:1247.0000,y:254.0000},
  {x:1246.3334,y:254.0000},
  {x:1245.6666,y:254.0000},
  {x:1245.0000,y:254.0000},
  {x:1245.0000,y:253.6667},
  {x:1245.0000,y:253.3333},
  {x:1245.0000,y:253.0000},
  {x:1244.3334,y:253.0000},
  {x:1243.6666,y:253.0000},
  {x:1243.0000,y:253.0000},
  {x:1243.0000,y:252.6667},
  {x:1243.0000,y:252.3333},
  {x:1243.0000,y:252.0000},
  {x:1242.3334,y:252.0000},
  {x:1241.6666,y:252.0000},
  {x:1241.0000,y:252.0000},
  {x:1241.0000,y:251.6667},
  {x:1241.0000,y:251.3333},
  {x:1241.0000,y:251.0000},
  {x:1240.3334,y:251.0000},
  {x:1239.6666,y:251.0000},
  {x:1239.0000,y:251.0000},
  {x:1239.0000,y:250.6667},
  {x:1239.0000,y:250.3333},
  {x:1239.0000,y:250.0000},
  {x:1237.0002,y:249.3334},
  {x:1234.9998,y:248.6666},
  {x:1233.0000,y:248.0000},
  {x:1233.0000,y:247.6667},
  {x:1233.0000,y:247.3333},
  {x:1233.0000,y:247.0000},
  {x:1231.6668,y:246.6667},
  {x:1230.3332,y:246.3333},
  {x:1229.0000,y:246.0000},
  {x:1229.0000,y:245.6667},
  {x:1229.0000,y:245.3333},
  {x:1229.0000,y:245.0000},
  {x:1227.6668,y:244.6667},
  {x:1226.3332,y:244.3333},
  {x:1225.0000,y:244.0000},
  {x:1225.0000,y:243.6667},
  {x:1225.0000,y:243.3333},
  {x:1225.0000,y:243.0000},
  {x:1224.3334,y:243.0000},
  {x:1223.6666,y:243.0000},
  {x:1223.0000,y:243.0000},
  {x:1223.0000,y:242.6667},
  {x:1223.0000,y:242.3333},
  {x:1223.0000,y:242.0000},
  {x:1222.3334,y:242.0000},
  {x:1221.6666,y:242.0000},
  {x:1221.0000,y:242.0000},
  {x:1221.0000,y:241.6667},
  {x:1221.0000,y:241.3333},
  {x:1221.0000,y:241.0000},
  {x:1220.3334,y:241.0000},
  {x:1219.6666,y:241.0000},
  {x:1219.0000,y:241.0000},
  {x:1219.0000,y:240.6667},
  {x:1219.0000,y:240.3333},
  {x:1219.0000,y:240.0000},
  {x:1218.3334,y:240.0000},
  {x:1217.6666,y:240.0000},
  {x:1217.0000,y:240.0000},
  {x:1217.0000,y:239.6667},
  {x:1217.0000,y:239.3333},
  {x:1217.0000,y:239.0000},
  {x:1215.0002,y:238.3334},
  {x:1212.9998,y:237.6666},
  {x:1211.0000,y:237.0000},
  {x:1210.6667,y:236.3334},
  {x:1210.3333,y:235.6666},
  {x:1210.0000,y:235.0000},
  {x:1209.3334,y:235.0000},
  {x:1208.6666,y:235.0000},
  {x:1208.0000,y:235.0000},
  {x:1208.0000,y:234.6667},
  {x:1208.0000,y:234.3333},
  {x:1208.0000,y:234.0000},
  {x:1207.3334,y:234.0000},
  {x:1206.6666,y:234.0000},
  {x:1206.0000,y:234.0000},
  {x:1206.0000,y:233.6667},
  {x:1206.0000,y:233.3333},
  {x:1206.0000,y:233.0000},
  {x:1205.3334,y:233.0000},
  {x:1204.6666,y:233.0000},
  {x:1204.0000,y:233.0000},
  {x:1204.0000,y:232.6667},
  {x:1204.0000,y:232.3333},
  {x:1204.0000,y:232.0000},
  {x:1203.3334,y:232.0000},
  {x:1202.6666,y:232.0000},
  {x:1202.0000,y:232.0000},
  {x:1202.0000,y:231.6667},
  {x:1202.0000,y:231.3333},
  {x:1202.0000,y:231.0000},
  {x:1201.3334,y:231.0000},
  {x:1200.6666,y:231.0000},
  {x:1200.0000,y:231.0000},
  {x:1199.6667,y:230.3334},
  {x:1199.3333,y:229.6666},
  {x:1199.0000,y:229.0000},
  {x:1198.3334,y:229.0000},
  {x:1197.6666,y:229.0000},
  {x:1197.0000,y:229.0000},
  {x:1197.0000,y:228.6667},
  {x:1197.0000,y:228.3333},
  {x:1197.0000,y:228.0000},
  {x:1196.3334,y:228.0000},
  {x:1195.6666,y:228.0000},
  {x:1195.0000,y:228.0000},
  {x:1195.0000,y:227.6667},
  {x:1195.0000,y:227.3333},
  {x:1195.0000,y:227.0000},
  {x:1194.3334,y:227.0000},
  {x:1193.6666,y:227.0000},
  {x:1193.0000,y:227.0000},
  {x:1193.0000,y:226.6667},
  {x:1193.0000,y:226.3333},
  {x:1193.0000,y:226.0000},
  {x:1192.3334,y:226.0000},
  {x:1191.6666,y:226.0000},
  {x:1191.0000,y:226.0000},
  {x:1190.6667,y:225.3334},
  {x:1190.3333,y:224.6666},
  {x:1190.0000,y:224.0000},
  {x:1189.3334,y:224.0000},
  {x:1188.6666,y:224.0000},
  {x:1188.0000,y:224.0000},
  {x:1188.0000,y:223.6667},
  {x:1188.0000,y:223.3333},
  {x:1188.0000,y:223.0000},
  {x:1187.3334,y:223.0000},
  {x:1186.6666,y:223.0000},
  {x:1186.0000,y:223.0000},
  {x:1185.6667,y:222.3334},
  {x:1185.3333,y:221.6666},
  {x:1185.0000,y:221.0000},
  {x:1183.0002,y:220.3334},
  {x:1180.9998,y:219.6666},
  {x:1179.0000,y:219.0000},
  {x:1178.6667,y:218.3334},
  {x:1178.3333,y:217.6666},
  {x:1178.0000,y:217.0000},
  {x:1176.6668,y:216.6667},
  {x:1175.3332,y:216.3333},
  {x:1174.0000,y:216.0000},
  {x:1173.6667,y:215.3334},
  {x:1173.3333,y:214.6666},
  {x:1173.0000,y:214.0000},
  {x:1171.6668,y:213.6667},
  {x:1170.3332,y:213.3333},
  {x:1169.0000,y:213.0000},
  {x:1169.0000,y:212.6667},
  {x:1169.0000,y:212.3333},
  {x:1169.0000,y:212.0000},
  {x:1168.0001,y:211.6667},
  {x:1166.9999,y:211.3333},
  {x:1166.0000,y:211.0000},
  {x:1165.6667,y:210.3334},
  {x:1165.3333,y:209.6666},
  {x:1165.0000,y:209.0000},
  {x:1163.6668,y:208.6667},
  {x:1162.3332,y:208.3333},
  {x:1161.0000,y:208.0000},
  {x:1161.0000,y:207.6667},
  {x:1161.0000,y:207.3333},
  {x:1161.0000,y:207.0000},
  {x:1160.0001,y:206.6667},
  {x:1158.9999,y:206.3333},
  {x:1158.0000,y:206.0000},
  {x:1157.6667,y:205.3334},
  {x:1157.3333,y:204.6666},
  {x:1157.0000,y:204.0000},
  {x:1155.6668,y:203.6667},
  {x:1154.3332,y:203.3333},
  {x:1153.0000,y:203.0000},
  {x:1152.6667,y:202.3334},
  {x:1152.3333,y:201.6666},
  {x:1152.0000,y:201.0000},
  {x:1151.3334,y:201.0000},
  {x:1150.6666,y:201.0000},
  {x:1150.0000,y:201.0000},
  {x:1149.6667,y:200.3334},
  {x:1149.3333,y:199.6666},
  {x:1149.0000,y:199.0000},
  {x:1148.3334,y:199.0000},
  {x:1147.6666,y:199.0000},
  {x:1147.0000,y:199.0000},
  {x:1146.6667,y:198.3334},
  {x:1146.3333,y:197.6666},
  {x:1146.0000,y:197.0000},
  {x:1145.3334,y:197.0000},
  {x:1144.6666,y:197.0000},
  {x:1144.0000,y:197.0000},
  {x:1144.0000,y:196.6667},
  {x:1144.0000,y:196.3333},
  {x:1144.0000,y:196.0000},
  {x:1143.0001,y:195.6667},
  {x:1141.9999,y:195.3333},
  {x:1141.0000,y:195.0000},
  {x:1140.6667,y:194.3334},
  {x:1140.3333,y:193.6666},
  {x:1140.0000,y:193.0000},
  {x:1139.3334,y:193.0000},
  {x:1138.6666,y:193.0000},
  {x:1138.0000,y:193.0000},
  {x:1138.0000,y:192.6667},
  {x:1138.0000,y:192.3333},
  {x:1138.0000,y:192.0000},
  {x:1137.0001,y:191.6667},
  {x:1135.9999,y:191.3333},
  {x:1135.0000,y:191.0000},
  {x:1134.6667,y:190.3334},
  {x:1134.3333,y:189.6666},
  {x:1134.0000,y:189.0000},
  {x:1133.3334,y:189.0000},
  {x:1132.6666,y:189.0000},
  {x:1132.0000,y:189.0000},
  {x:1131.3334,y:188.0001},
  {x:1130.6666,y:186.9999},
  {x:1130.0000,y:186.0000},
  {x:1129.3334,y:186.0000},
  {x:1128.6666,y:186.0000},
  {x:1128.0000,y:186.0000},
  {x:1127.6667,y:185.3334},
  {x:1127.3333,y:184.6666},
  {x:1127.0000,y:184.0000},
  {x:1126.3334,y:184.0000},
  {x:1125.6666,y:184.0000},
  {x:1125.0000,y:184.0000},
  {x:1124.3334,y:183.0001},
  {x:1123.6666,y:181.9999},
  {x:1123.0000,y:181.0000},
  {x:1122.3334,y:181.0000},
  {x:1121.6666,y:181.0000},
  {x:1121.0000,y:181.0000},
  {x:1120.6667,y:180.3334},
  {x:1120.3333,y:179.6666},
  {x:1120.0000,y:179.0000},
  {x:1119.3334,y:179.0000},
  {x:1118.6666,y:179.0000},
  {x:1118.0000,y:179.0000},
  {x:1117.6667,y:178.3334},
  {x:1117.3333,y:177.6666},
  {x:1117.0000,y:177.0000},
  {x:1116.0001,y:176.6667},
  {x:1114.9999,y:176.3333},
  {x:1114.0000,y:176.0000},
  {x:1113.3334,y:175.0001},
  {x:1112.6666,y:173.9999},
  {x:1112.0000,y:173.0000},
  {x:1111.3334,y:173.0000},
  {x:1110.6666,y:173.0000},
  {x:1110.0000,y:173.0000},
  {x:1109.0001,y:171.6668},
  {x:1107.9999,y:170.3332},
  {x:1107.0000,y:169.0000},
  {x:1106.3334,y:169.0000},
  {x:1105.6666,y:169.0000},
  {x:1105.0000,y:169.0000},
  {x:1104.3334,y:168.0001},
  {x:1103.6666,y:166.9999},
  {x:1103.0000,y:166.0000},
  {x:1102.3334,y:166.0000},
  {x:1101.6666,y:166.0000},
  {x:1101.0000,y:166.0000},
  {x:1100.0001,y:164.6668},
  {x:1098.9999,y:163.3332},
  {x:1098.0000,y:162.0000},
  {x:1097.3334,y:162.0000},
  {x:1096.6666,y:162.0000},
  {x:1096.0000,y:162.0000},
  {x:1095.0001,y:160.6668},
  {x:1093.9999,y:159.3332},
  {x:1093.0000,y:158.0000},
  {x:1092.3334,y:158.0000},
  {x:1091.6666,y:158.0000},
  {x:1091.0000,y:158.0000},
  {x:1089.3335,y:156.0002},
  {x:1087.6665,y:153.9998},
  {x:1086.0000,y:152.0000},
  {x:1085.3334,y:152.0000},
  {x:1084.6666,y:152.0000},
  {x:1084.0000,y:152.0000},
  {x:1082.3335,y:150.0002},
  {x:1080.6665,y:147.9998},
  {x:1079.0000,y:146.0000},
  {x:1078.3334,y:146.0000},
  {x:1077.6666,y:146.0000},
  {x:1077.0000,y:146.0000},
  {x:1074.6669,y:143.3336},
  {x:1072.3331,y:140.6664},
  {x:1070.0000,y:138.0000},
  {x:1061.8525,y:132.1015},
  {x:1055.9747,y:124.9766},
  {x:1049.0000,y:118.0000},
  {x:1042.6673,y:112.0006},
  {x:1036.3327,y:105.9994},
  {x:1030.0000,y:100.0000},
  {x:1030.0000,y:99.3334},
  {x:1030.0000,y:98.6666},
  {x:1030.0000,y:98.0000},
  {x:1027.0003,y:95.3336},
  {x:1023.9997,y:92.6664},
  {x:1021.0000,y:90.0000},
  {x:1021.0000,y:89.3334},
  {x:1021.0000,y:88.6666},
  {x:1021.0000,y:88.0000},
  {x:1018.6669,y:86.0002},
  {x:1016.3331,y:83.9998},
  {x:1014.0000,y:82.0000},
  {x:1014.0000,y:81.3334},
  {x:1014.0000,y:80.6666},
  {x:1014.0000,y:80.0000},
  {x:1011.6669,y:78.0002},
  {x:1009.3331,y:75.9998},
  {x:1007.0000,y:74.0000},
  {x:1007.0000,y:73.3334},
  {x:1007.0000,y:72.6666},
  {x:1007.0000,y:72.0000},
  {x:1005.0002,y:70.3335},
  {x:1002.9998,y:68.6665},
  {x:1001.0000,y:67.0000},
  {x:1001.0000,y:66.3334},
  {x:1001.0000,y:65.6666},
  {x:1001.0000,y:65.0000},
  {x:999.3335,y:63.6668},
  {x:997.6665,y:62.3332},
  {x:996.0000,y:61.0000},
  {x:996.0000,y:60.3334},
  {x:996.0000,y:59.6666},
  {x:996.0000,y:59.0000},
  {x:994.0002,y:57.3335},
  {x:991.9998,y:55.6665},
  {x:990.0000,y:54.0000},
  {x:990.0000,y:53.3334},
  {x:990.0000,y:52.6666},
  {x:990.0000,y:52.0000},
  {x:988.0002,y:50.3335},
  {x:985.9998,y:48.6665},
  {x:984.0000,y:47.0000},
  {x:984.0000,y:46.3334},
  {x:984.0000,y:45.6666},
  {x:984.0000,y:45.0000},
  {x:982.0002,y:43.3335},
  {x:979.9998,y:41.6665},
  {x:978.0000,y:40.0000},
  {x:977.6667,y:39.0001},
  {x:977.3333,y:37.9999},
  {x:977.0000,y:37.0000},
  {x:973.3337,y:33.6670},
  {x:969.6663,y:30.3330},
  {x:966.0000,y:27.0000},
  {x:964.6668,y:25.3335},
  {x:963.3332,y:23.6665},
  {x:962.0000,y:22.0000},
  {x:961.3334,y:22.0000},
  {x:960.6666,y:22.0000},
  {x:960.0000,y:22.0000},
  {x:958.6668,y:20.3335},
  {x:957.3332,y:18.6665},
  {x:956.0000,y:17.0000},
  {x:955.3334,y:17.0000},
  {x:954.6666,y:17.0000},
  {x:954.0000,y:17.0000},
  {x:953.6667,y:16.3334},
  {x:953.3333,y:15.6666},
  {x:953.0000,y:15.0000},
  {x:952.3334,y:15.0000},
  {x:951.6666,y:15.0000},
  {x:951.0000,y:15.0000},
  {x:950.6667,y:14.3334},
  {x:950.3333,y:13.6666},
  {x:950.0000,y:13.0000},
  {x:948.6668,y:12.6667},
  {x:947.3332,y:12.3333},
  {x:946.0000,y:12.0000},
  {x:945.6667,y:11.3334},
  {x:945.3333,y:10.6666},
  {x:945.0000,y:10.0000},
  {x:944.0001,y:10.0000},
  {x:942.9999,y:10.0000},
  {x:942.0000,y:10.0000},
  {x:942.0000,y:9.6667},
  {x:942.0000,y:9.3333},
  {x:942.0000,y:9.0000},
  {x:941.3334,y:9.0000},
  {x:940.6666,y:9.0000},
  {x:940.0000,y:9.0000},
  {x:940.0000,y:8.6667},
  {x:940.0000,y:8.3333},
  {x:940.0000,y:8.0000},
  {x:939.0001,y:8.0000},
  {x:937.9999,y:8.0000},
  {x:937.0000,y:8.0000},
  {x:937.0000,y:7.6667},
  {x:937.0000,y:7.3333},
  {x:937.0000,y:7.0000},
  {x:932.1435,y:5.4591},
  {x:920.1347,y:9.0185},
  {x:917.0000,y:10.0000},
  {x:913.0004,y:10.6666},
  {x:908.9996,y:11.3334},
  {x:905.0000,y:12.0000},
  {x:905.0000,y:12.3333},
  {x:905.0000,y:12.6667},
  {x:905.0000,y:13.0000},
  {x:904.0001,y:13.0000},
  {x:902.9999,y:13.0000},
  {x:902.0000,y:13.0000},
  {x:902.0000,y:13.3333},
  {x:902.0000,y:13.6667},
  {x:902.0000,y:14.0000},
  {x:901.0001,y:14.0000},
  {x:899.9999,y:14.0000},
  {x:899.0000,y:14.0000},
  {x:899.0000,y:14.3333},
  {x:899.0000,y:14.6667},
  {x:899.0000,y:15.0000},
  {x:898.0001,y:15.0000},
  {x:896.9999,y:15.0000},
  {x:896.0000,y:15.0000},
  {x:896.0000,y:15.3333},
  {x:896.0000,y:15.6667},
  {x:896.0000,y:16.0000},
  {x:895.0001,y:16.0000},
  {x:893.9999,y:16.0000},
  {x:893.0000,y:16.0000},
  {x:893.0000,y:16.3333},
  {x:893.0000,y:16.6667},
  {x:893.0000,y:17.0000},
  {x:892.3334,y:17.0000},
  {x:891.6666,y:17.0000},
  {x:891.0000,y:17.0000},
  {x:891.0000,y:17.3333},
  {x:891.0000,y:17.6667},
  {x:891.0000,y:18.0000},
  {x:890.0001,y:18.0000},
  {x:888.9999,y:18.0000},
  {x:888.0000,y:18.0000},
  {x:888.0000,y:18.3333},
  {x:888.0000,y:18.6667},
  {x:888.0000,y:19.0000},
  {x:886.0002,y:19.6666},
  {x:883.9998,y:20.3334},
  {x:882.0000,y:21.0000},
  {x:882.0000,y:21.3333},
  {x:882.0000,y:21.6667},
  {x:882.0000,y:22.0000},
  {x:881.3334,y:22.0000},
  {x:880.6666,y:22.0000},
  {x:880.0000,y:22.0000},
  {x:880.0000,y:22.3333},
  {x:880.0000,y:22.6667},
  {x:880.0000,y:23.0000},
  {x:879.3334,y:23.0000},
  {x:878.6666,y:23.0000},
  {x:878.0000,y:23.0000},
  {x:878.0000,y:23.3333},
  {x:878.0000,y:23.6667},
  {x:878.0000,y:24.0000},
  {x:876.0002,y:24.6666},
  {x:873.9998,y:25.3334},
  {x:872.0000,y:26.0000},
  {x:871.6667,y:26.6666},
  {x:871.3333,y:27.3334},
  {x:871.0000,y:28.0000},
  {x:869.6668,y:28.3333},
  {x:868.3332,y:28.6667},
  {x:867.0000,y:29.0000},
  {x:867.0000,y:29.3333},
  {x:867.0000,y:29.6667},
  {x:867.0000,y:30.0000},
  {x:866.3334,y:30.0000},
  {x:865.6666,y:30.0000},
  {x:865.0000,y:30.0000},
  {x:865.0000,y:30.3333},
  {x:865.0000,y:30.6667},
  {x:865.0000,y:31.0000},
  {x:864.3334,y:31.0000},
  {x:863.6666,y:31.0000},
  {x:863.0000,y:31.0000},
  {x:863.0000,y:31.3333},
  {x:863.0000,y:31.6667},
  {x:863.0000,y:32.0000},
  {x:862.3334,y:32.0000},
  {x:861.6666,y:32.0000},
  {x:861.0000,y:32.0000},
  {x:860.6667,y:32.6666},
  {x:860.3333,y:33.3334},
  {x:860.0000,y:34.0000},
  {x:859.3334,y:34.0000},
  {x:858.6666,y:34.0000},
  {x:858.0000,y:34.0000},
  {x:858.0000,y:34.3333},
  {x:858.0000,y:34.6667},
  {x:858.0000,y:35.0000},
  {x:857.3334,y:35.0000},
  {x:856.6666,y:35.0000},
  {x:856.0000,y:35.0000},
  {x:856.0000,y:35.3333},
  {x:856.0000,y:35.6667},
  {x:856.0000,y:36.0000},
  {x:855.3334,y:36.0000},
  {x:854.6666,y:36.0000},
  {x:854.0000,y:36.0000},
  {x:854.0000,y:36.3333},
  {x:854.0000,y:36.6667},
  {x:854.0000,y:37.0000},
  {x:853.3334,y:37.0000},
  {x:852.6666,y:37.0000},
  {x:852.0000,y:37.0000},
  {x:852.0000,y:37.3333},
  {x:852.0000,y:37.6667},
  {x:852.0000,y:38.0000},
  {x:851.3334,y:38.0000},
  {x:850.6666,y:38.0000},
  {x:850.0000,y:38.0000},
  {x:849.6667,y:38.6666},
  {x:849.3333,y:39.3334},
  {x:849.0000,y:40.0000},
  {x:847.0002,y:40.6666},
  {x:844.9998,y:41.3334},
  {x:843.0000,y:42.0000},
  {x:842.6667,y:42.6666},
  {x:842.3333,y:43.3334},
  {x:842.0000,y:44.0000},
  {x:840.0002,y:44.6666},
  {x:837.9998,y:45.3334},
  {x:836.0000,y:46.0000},
  {x:835.6667,y:46.6666},
  {x:835.3333,y:47.3334},
  {x:835.0000,y:48.0000},
  {x:833.0002,y:48.6666},
  {x:830.9998,y:49.3334},
  {x:829.0000,y:50.0000},
  {x:828.6667,y:50.6666},
  {x:828.3333,y:51.3334},
  {x:828.0000,y:52.0000},
  {x:827.3334,y:52.0000},
  {x:826.6666,y:52.0000},
  {x:826.0000,y:52.0000},
  {x:826.0000,y:52.3333},
  {x:826.0000,y:52.6667},
  {x:826.0000,y:53.0000},
  {x:825.3334,y:53.0000},
  {x:824.6666,y:53.0000},
  {x:824.0000,y:53.0000},
  {x:824.0000,y:53.3333},
  {x:824.0000,y:53.6667},
  {x:824.0000,y:54.0000},
  {x:823.0001,y:54.3333},
  {x:821.9999,y:54.6667},
  {x:821.0000,y:55.0000},
  {x:820.6667,y:55.6666},
  {x:820.3333,y:56.3334},
  {x:820.0000,y:57.0000},
  {x:818.6668,y:57.3333},
  {x:817.3332,y:57.6667},
  {x:816.0000,y:58.0000},
  {x:815.6667,y:58.6666},
  {x:815.3333,y:59.3334},
  {x:815.0000,y:60.0000},
  {x:814.3334,y:60.0000},
  {x:813.6666,y:60.0000},
  {x:813.0000,y:60.0000},
  {x:812.6667,y:60.6666},
  {x:812.3333,y:61.3334},
  {x:812.0000,y:62.0000},
  {x:811.3334,y:62.0000},
  {x:810.6666,y:62.0000},
  {x:810.0000,y:62.0000},
  {x:809.6667,y:62.6666},
  {x:809.3333,y:63.3334},
  {x:809.0000,y:64.0000},
  {x:808.3334,y:64.0000},
  {x:807.6666,y:64.0000},
  {x:807.0000,y:64.0000},
  {x:806.6667,y:64.6666},
  {x:806.3333,y:65.3334},
  {x:806.0000,y:66.0000},
  {x:805.3334,y:66.0000},
  {x:804.6666,y:66.0000},
  {x:804.0000,y:66.0000},
  {x:803.6667,y:66.6666},
  {x:803.3333,y:67.3334},
  {x:803.0000,y:68.0000},
  {x:802.3334,y:68.0000},
  {x:801.6666,y:68.0000},
  {x:801.0000,y:68.0000},
  {x:801.0000,y:68.3333},
  {x:801.0000,y:68.6667},
  {x:801.0000,y:69.0000},
  {x:800.0001,y:69.3333},
  {x:798.9999,y:69.6667},
  {x:798.0000,y:70.0000},
  {x:797.6667,y:70.6666},
  {x:797.3333,y:71.3334},
  {x:797.0000,y:72.0000},
  {x:796.3334,y:72.0000},
  {x:795.6666,y:72.0000},
  {x:795.0000,y:72.0000},
  {x:794.6667,y:72.6666},
  {x:794.3333,y:73.3334},
  {x:794.0000,y:74.0000},
  {x:793.3334,y:74.0000},
  {x:792.6666,y:74.0000},
  {x:792.0000,y:74.0000},
  {x:791.3334,y:74.9999},
  {x:790.6666,y:76.0001},
  {x:790.0000,y:77.0000},
  {x:789.3334,y:77.0000},
  {x:788.6666,y:77.0000},
  {x:788.0000,y:77.0000},
  {x:787.6667,y:77.6666},
  {x:787.3333,y:78.3334},
  {x:787.0000,y:79.0000},
  {x:786.3334,y:79.0000},
  {x:785.6666,y:79.0000},
  {x:785.0000,y:79.0000},
  {x:784.3334,y:79.9999},
  {x:783.6666,y:81.0001},
  {x:783.0000,y:82.0000},
  {x:782.3334,y:82.0000},
  {x:781.6666,y:82.0000},
  {x:781.0000,y:82.0000},
  {x:780.6667,y:82.6666},
  {x:780.3333,y:83.3334},
  {x:780.0000,y:84.0000},
  {x:779.0001,y:84.3333},
  {x:777.9999,y:84.6667},
  {x:777.0000,y:85.0000},
  {x:776.0001,y:86.3332},
  {x:774.9999,y:87.6668},
  {x:774.0000,y:89.0000},
  {x:753.8309,y:103.5361},
  {x:739.2946,y:121.7627},
  {x:722.0000,y:139.0000},
  {x:713.6675,y:145.6660},
  {x:705.3325,y:152.3340},
  {x:697.0000,y:159.0000},
  {x:695.6668,y:159.3333},
  {x:694.3332,y:159.6667},
  {x:693.0000,y:160.0000},
  {x:692.6667,y:160.6666},
  {x:692.3333,y:161.3334},
  {x:692.0000,y:162.0000},
  {x:691.3334,y:162.0000},
  {x:690.6666,y:162.0000},
  {x:690.0000,y:162.0000},
  {x:690.0000,y:162.3333},
  {x:690.0000,y:162.6667},
  {x:690.0000,y:163.0000},
  {x:688.0002,y:163.6666},
  {x:685.9998,y:164.3334},
  {x:684.0000,y:165.0000},
  {x:684.0000,y:165.3333},
  {x:684.0000,y:165.6667},
  {x:684.0000,y:166.0000},
  {x:683.0001,y:166.0000},
  {x:681.9999,y:166.0000},
  {x:681.0000,y:166.0000},
  {x:681.0000,y:166.3333},
  {x:681.0000,y:166.6667},
  {x:681.0000,y:167.0000},
  {x:680.0001,y:167.0000},
  {x:678.9999,y:167.0000},
  {x:678.0000,y:167.0000},
  {x:677.0001,y:168.3332},
  {x:675.9999,y:169.6668},
  {x:675.0000,y:171.0000},
  {x:674.3334,y:171.0000},
  {x:673.6666,y:171.0000},
  {x:673.0000,y:171.0000},
  {x:672.3334,y:171.9999},
  {x:671.6666,y:173.0001},
  {x:671.0000,y:174.0000},
  {x:670.3334,y:174.0000},
  {x:669.6666,y:174.0000},
  {x:669.0000,y:174.0000},
  {x:669.0000,y:174.3333},
  {x:669.0000,y:174.6667},
  {x:669.0000,y:175.0000},
  {x:668.0001,y:175.3333},
  {x:666.9999,y:175.6667},
  {x:666.0000,y:176.0000},
  {x:665.6667,y:176.6666},
  {x:665.3333,y:177.3334},
  {x:665.0000,y:178.0000},
  {x:664.3334,y:178.0000},
  {x:663.6666,y:178.0000},
  {x:663.0000,y:178.0000},
  {x:662.3334,y:178.9999},
  {x:661.6666,y:180.0001},
  {x:661.0000,y:181.0000},
  {x:660.3334,y:181.0000},
  {x:659.6666,y:181.0000},
  {x:659.0000,y:181.0000},
  {x:658.6667,y:181.6666},
  {x:658.3333,y:182.3334},
  {x:658.0000,y:183.0000},
  {x:657.3334,y:183.0000},
  {x:656.6666,y:183.0000},
  {x:656.0000,y:183.0000},
  {x:655.3334,y:183.9999},
  {x:654.6666,y:185.0001},
  {x:654.0000,y:186.0000},
  {x:653.3334,y:186.0000},
  {x:652.6666,y:186.0000},
  {x:652.0000,y:186.0000},
  {x:652.0000,y:186.3333},
  {x:652.0000,y:186.6667},
  {x:652.0000,y:187.0000},
  {x:651.3334,y:187.0000},
  {x:650.6666,y:187.0000},
  {x:650.0000,y:187.0000},
  {x:649.6667,y:187.6666},
  {x:649.3333,y:188.3334},
  {x:649.0000,y:189.0000},
  {x:647.0002,y:189.6666},
  {x:644.9998,y:190.3334},
  {x:643.0000,y:191.0000},
  {x:642.6667,y:191.6666},
  {x:642.3333,y:192.3334},
  {x:642.0000,y:193.0000},
  {x:640.6668,y:193.3333},
  {x:639.3332,y:193.6667},
  {x:638.0000,y:194.0000},
  {x:637.6667,y:194.6666},
  {x:637.3333,y:195.3334},
  {x:637.0000,y:196.0000},
  {x:635.6668,y:196.3333},
  {x:634.3332,y:196.6667},
  {x:633.0000,y:197.0000},
  {x:632.6667,y:197.6666},
  {x:632.3333,y:198.3334},
  {x:632.0000,y:199.0000},
  {x:630.0002,y:199.6666},
  {x:627.9998,y:200.3334},
  {x:626.0000,y:201.0000},
  {x:625.6667,y:201.6666},
  {x:625.3333,y:202.3334},
  {x:625.0000,y:203.0000},
  {x:624.3334,y:203.0000},
  {x:623.6666,y:203.0000},
  {x:623.0000,y:203.0000},
  {x:623.0000,y:203.3333},
  {x:623.0000,y:203.6667},
  {x:623.0000,y:204.0000},
  {x:622.3334,y:204.0000},
  {x:621.6666,y:204.0000},
  {x:621.0000,y:204.0000},
  {x:620.6667,y:204.6666},
  {x:620.3333,y:205.3334},
  {x:620.0000,y:206.0000},
  {x:618.0002,y:206.6666},
  {x:615.9998,y:207.3334},
  {x:614.0000,y:208.0000},
  {x:613.6667,y:208.6666},
  {x:613.3333,y:209.3334},
  {x:613.0000,y:210.0000},
  {x:611.0002,y:210.6666},
  {x:608.9998,y:211.3334},
  {x:607.0000,y:212.0000},
  {x:606.6667,y:212.6666},
  {x:606.3333,y:213.3334},
  {x:606.0000,y:214.0000},
  {x:605.3334,y:214.0000},
  {x:604.6666,y:214.0000},
  {x:604.0000,y:214.0000},
  {x:604.0000,y:214.3333},
  {x:604.0000,y:214.6667},
  {x:604.0000,y:215.0000},
  {x:603.3334,y:215.0000},
  {x:602.6666,y:215.0000},
  {x:602.0000,y:215.0000},
  {x:602.0000,y:215.3333},
  {x:602.0000,y:215.6667},
  {x:602.0000,y:216.0000},
  {x:601.3334,y:216.0000},
  {x:600.6666,y:216.0000},
  {x:600.0000,y:216.0000},
  {x:599.6667,y:216.6666},
  {x:599.3333,y:217.3334},
  {x:599.0000,y:218.0000},
  {x:597.0002,y:218.6666},
  {x:594.9998,y:219.3334},
  {x:593.0000,y:220.0000},
  {x:592.6667,y:220.6666},
  {x:592.3333,y:221.3334},
  {x:592.0000,y:222.0000},
  {x:591.3334,y:222.0000},
  {x:590.6666,y:222.0000},
  {x:590.0000,y:222.0000},
  {x:590.0000,y:222.3333},
  {x:590.0000,y:222.6667},
  {x:590.0000,y:223.0000},
  {x:589.3334,y:223.0000},
  {x:588.6666,y:223.0000},
  {x:588.0000,y:223.0000},
  {x:588.0000,y:223.3333},
  {x:588.0000,y:223.6667},
  {x:588.0000,y:224.0000},
  {x:587.3334,y:224.0000},
  {x:586.6666,y:224.0000},
  {x:586.0000,y:224.0000},
  {x:586.0000,y:224.3333},
  {x:586.0000,y:224.6667},
  {x:586.0000,y:225.0000},
  {x:585.3334,y:225.0000},
  {x:584.6666,y:225.0000},
  {x:584.0000,y:225.0000},
  {x:584.0000,y:225.3333},
  {x:584.0000,y:225.6667},
  {x:584.0000,y:226.0000},
  {x:583.3334,y:226.0000},
  {x:582.6666,y:226.0000},
  {x:582.0000,y:226.0000},
  {x:581.6667,y:226.6666},
  {x:581.3333,y:227.3334},
  {x:581.0000,y:228.0000},
  {x:579.0002,y:228.6666},
  {x:576.9998,y:229.3334},
  {x:575.0000,y:230.0000},
  {x:575.0000,y:230.3333},
  {x:575.0000,y:230.6667},
  {x:575.0000,y:231.0000},
  {x:574.3334,y:231.0000},
  {x:573.6666,y:231.0000},
  {x:573.0000,y:231.0000},
  {x:573.0000,y:231.3333},
  {x:573.0000,y:231.6667},
  {x:573.0000,y:232.0000},
  {x:572.3334,y:232.0000},
  {x:571.6666,y:232.0000},
  {x:571.0000,y:232.0000},
  {x:571.0000,y:232.3333},
  {x:571.0000,y:232.6667},
  {x:571.0000,y:233.0000},
  {x:569.0002,y:233.6666},
  {x:566.9998,y:234.3334},
  {x:565.0000,y:235.0000},
  {x:565.0000,y:235.3333},
  {x:565.0000,y:235.6667},
  {x:565.0000,y:236.0000},
  {x:564.0001,y:236.0000},
  {x:562.9999,y:236.0000},
  {x:562.0000,y:236.0000},
  {x:562.0000,y:236.3333},
  {x:562.0000,y:236.6667},
  {x:562.0000,y:237.0000},
  {x:561.3334,y:237.0000},
  {x:560.6666,y:237.0000},
  {x:560.0000,y:237.0000},
  {x:560.0000,y:237.3333},
  {x:560.0000,y:237.6667},
  {x:560.0000,y:238.0000},
  {x:559.0001,y:238.0000},
  {x:557.9999,y:238.0000},
  {x:557.0000,y:238.0000},
  {x:557.0000,y:238.3333},
  {x:557.0000,y:238.6667},
  {x:557.0000,y:239.0000},
  {x:555.0002,y:239.6666},
  {x:552.9998,y:240.3334},
  {x:551.0000,y:241.0000},
  {x:551.0000,y:241.3333},
  {x:551.0000,y:241.6667},
  {x:551.0000,y:242.0000},
  {x:550.3334,y:242.0000},
  {x:549.6666,y:242.0000},
  {x:549.0000,y:242.0000},
  {x:549.0000,y:242.3333},
  {x:549.0000,y:242.6667},
  {x:549.0000,y:243.0000},
  {x:547.6668,y:243.3333},
  {x:546.3332,y:243.6667},
  {x:545.0000,y:244.0000},
  {x:544.6667,y:244.6666},
  {x:544.3333,y:245.3334},
  {x:544.0000,y:246.0000},
  {x:542.6668,y:246.3333},
  {x:541.3332,y:246.6667},
  {x:540.0000,y:247.0000},
  {x:540.0000,y:247.3333},
  {x:540.0000,y:247.6667},
  {x:540.0000,y:248.0000},
  {x:539.0001,y:248.3333},
  {x:537.9999,y:248.6667},
  {x:537.0000,y:249.0000},
  {x:536.6667,y:249.6666},
  {x:536.3333,y:250.3334},
  {x:536.0000,y:251.0000},
  {x:535.3334,y:251.0000},
  {x:534.6666,y:251.0000},
  {x:534.0000,y:251.0000},
  {x:533.3334,y:251.9999},
  {x:532.6666,y:253.0001},
  {x:532.0000,y:254.0000},
  {x:531.3334,y:254.0000},
  {x:530.6666,y:254.0000},
  {x:530.0000,y:254.0000},
  {x:529.6667,y:254.6666},
  {x:529.3333,y:255.3334},
  {x:529.0000,y:256.0000},
  {x:528.3334,y:256.0000},
  {x:527.6666,y:256.0000},
  {x:527.0000,y:256.0000},
  {x:526.6667,y:256.6666},
  {x:526.3333,y:257.3334},
  {x:526.0000,y:258.0000},
  {x:524.6668,y:258.3333},
  {x:523.3332,y:258.6667},
  {x:522.0000,y:259.0000},
  {x:521.6667,y:259.6666},
  {x:521.3333,y:260.3334},
  {x:521.0000,y:261.0000},
  {x:519.6668,y:261.3333},
  {x:518.3332,y:261.6667},
  {x:517.0000,y:262.0000},
  {x:517.0000,y:262.3333},
  {x:517.0000,y:262.6667},
  {x:517.0000,y:263.0000},
  {x:516.0001,y:263.3333},
  {x:514.9999,y:263.6667},
  {x:514.0000,y:264.0000},
  {x:513.6667,y:264.6666},
  {x:513.3333,y:265.3334},
  {x:513.0000,y:266.0000},
  {x:511.6668,y:266.3333},
  {x:510.3332,y:266.6667},
  {x:509.0000,y:267.0000},
  {x:508.6667,y:267.6666},
  {x:508.3333,y:268.3334},
  {x:508.0000,y:269.0000},
  {x:507.3334,y:269.0000},
  {x:506.6666,y:269.0000},
  {x:506.0000,y:269.0000},
  {x:506.0000,y:269.3333},
  {x:506.0000,y:269.6667},
  {x:506.0000,y:270.0000},
  {x:505.0001,y:270.3333},
  {x:503.9999,y:270.6667},
  {x:503.0000,y:271.0000},
  {x:502.6667,y:271.6666},
  {x:502.3333,y:272.3334},
  {x:502.0000,y:273.0000},
  {x:501.3334,y:273.0000},
  {x:500.6666,y:273.0000},
  {x:500.0000,y:273.0000},
  {x:499.6667,y:273.6666},
  {x:499.3333,y:274.3334},
  {x:499.0000,y:275.0000},
  {x:498.3334,y:275.0000},
  {x:497.6666,y:275.0000},
  {x:497.0000,y:275.0000},
  {x:496.3334,y:275.9999},
  {x:495.6666,y:277.0001},
  {x:495.0000,y:278.0000},
  {x:494.3334,y:278.0000},
  {x:493.6666,y:278.0000},
  {x:493.0000,y:278.0000},
  {x:492.3334,y:278.9999},
  {x:491.6666,y:280.0001},
  {x:491.0000,y:281.0000},
  {x:490.3334,y:281.0000},
  {x:489.6666,y:281.0000},
  {x:489.0000,y:281.0000},
  {x:488.3334,y:281.9999},
  {x:487.6666,y:283.0001},
  {x:487.0000,y:284.0000},
  {x:486.3334,y:284.0000},
  {x:485.6666,y:284.0000},
  {x:485.0000,y:284.0000},
  {x:484.3334,y:284.9999},
  {x:483.6666,y:286.0001},
  {x:483.0000,y:287.0000},
  {x:482.3334,y:287.0000},
  {x:481.6666,y:287.0000},
  {x:481.0000,y:287.0000},
  {x:480.6667,y:287.6666},
  {x:480.3333,y:288.3334},
  {x:480.0000,y:289.0000},
  {x:479.3334,y:289.0000},
  {x:478.6666,y:289.0000},
  {x:478.0000,y:289.0000},
  {x:477.3334,y:289.9999},
  {x:476.6666,y:291.0001},
  {x:476.0000,y:292.0000},
  {x:475.3334,y:292.0000},
  {x:474.6666,y:292.0000},
  {x:474.0000,y:292.0000},
  {x:473.3334,y:292.9999},
  {x:472.6666,y:294.0001},
  {x:472.0000,y:295.0000},
  {x:471.3334,y:295.0000},
  {x:470.6666,y:295.0000},
  {x:470.0000,y:295.0000},
  {x:469.3334,y:295.9999},
  {x:468.6666,y:297.0001},
  {x:468.0000,y:298.0000},
  {x:467.3334,y:298.0000},
  {x:466.6666,y:298.0000},
  {x:466.0000,y:298.0000},
  {x:465.3334,y:298.9999},
  {x:464.6666,y:300.0001},
  {x:464.0000,y:301.0000},
  {x:463.3334,y:301.0000},
  {x:462.6666,y:301.0000},
  {x:462.0000,y:301.0000},
  {x:461.6667,y:301.6666},
  {x:461.3333,y:302.3334},
  {x:461.0000,y:303.0000},
  {x:460.3334,y:303.0000},
  {x:459.6666,y:303.0000},
  {x:459.0000,y:303.0000},
  {x:458.3334,y:303.9999},
  {x:457.6666,y:305.0001},
  {x:457.0000,y:306.0000},
  {x:456.3334,y:306.0000},
  {x:455.6666,y:306.0000},
  {x:455.0000,y:306.0000},
  {x:454.6667,y:306.6666},
  {x:454.3333,y:307.3334},
  {x:454.0000,y:308.0000},
  {x:453.3334,y:308.0000},
  {x:452.6666,y:308.0000},
  {x:452.0000,y:308.0000},
  {x:451.3334,y:308.9999},
  {x:450.6666,y:310.0001},
  {x:450.0000,y:311.0000},
  {x:449.3334,y:311.0000},
  {x:448.6666,y:311.0000},
  {x:448.0000,y:311.0000},
  {x:447.6667,y:311.6666},
  {x:447.3333,y:312.3334},
  {x:447.0000,y:313.0000},
  {x:446.3334,y:313.0000},
  {x:445.6666,y:313.0000},
  {x:445.0000,y:313.0000},
  {x:444.3334,y:313.9999},
  {x:443.6666,y:315.0001},
  {x:443.0000,y:316.0000},
  {x:442.3334,y:316.0000},
  {x:441.6666,y:316.0000},
  {x:441.0000,y:316.0000},
  {x:440.6667,y:316.6666},
  {x:440.3333,y:317.3334},
  {x:440.0000,y:318.0000},
  {x:439.3334,y:318.0000},
  {x:438.6666,y:318.0000},
  {x:438.0000,y:318.0000},
  {x:437.3334,y:318.9999},
  {x:436.6666,y:320.0001},
  {x:436.0000,y:321.0000},
  {x:435.3334,y:321.0000},
  {x:434.6666,y:321.0000},
  {x:434.0000,y:321.0000},
  {x:434.0000,y:321.3333},
  {x:434.0000,y:321.6667},
  {x:434.0000,y:322.0000},
  {x:433.0001,y:322.3333},
  {x:431.9999,y:322.6667},
  {x:431.0000,y:323.0000},
  {x:430.6667,y:323.6666},
  {x:430.3333,y:324.3334},
  {x:430.0000,y:325.0000},
  {x:429.3334,y:325.0000},
  {x:428.6666,y:325.0000},
  {x:428.0000,y:325.0000},
  {x:427.3334,y:325.9999},
  {x:426.6666,y:327.0001},
  {x:426.0000,y:328.0000},
  {x:425.3334,y:328.0000},
  {x:424.6666,y:328.0000},
  {x:424.0000,y:328.0000},
  {x:423.6667,y:328.6666},
  {x:423.3333,y:329.3334},
  {x:423.0000,y:330.0000},
  {x:422.0001,y:330.3333},
  {x:420.9999,y:330.6667},
  {x:420.0000,y:331.0000},
  {x:420.0000,y:331.3333},
  {x:420.0000,y:331.6667},
  {x:420.0000,y:332.0000},
  {x:419.3334,y:332.0000},
  {x:418.6666,y:332.0000},
  {x:418.0000,y:332.0000},
  {x:417.6667,y:332.6666},
  {x:417.3333,y:333.3334},
  {x:417.0000,y:334.0000},
  {x:416.3334,y:334.0000},
  {x:415.6666,y:334.0000},
  {x:415.0000,y:334.0000},
  {x:414.6667,y:334.6666},
  {x:414.3333,y:335.3334},
  {x:414.0000,y:336.0000},
  {x:413.3334,y:336.0000},
  {x:412.6666,y:336.0000},
  {x:412.0000,y:336.0000},
  {x:411.6667,y:336.6666},
  {x:411.3333,y:337.3334},
  {x:411.0000,y:338.0000},
  {x:410.3334,y:338.0000},
  {x:409.6666,y:338.0000},
  {x:409.0000,y:338.0000},
  {x:408.6667,y:338.6666},
  {x:408.3333,y:339.3334},
  {x:408.0000,y:340.0000},
  {x:407.3334,y:340.0000},
  {x:406.6666,y:340.0000},
  {x:406.0000,y:340.0000},
  {x:405.6667,y:340.6666},
  {x:405.3333,y:341.3334},
  {x:405.0000,y:342.0000},
  {x:404.3334,y:342.0000},
  {x:403.6666,y:342.0000},
  {x:403.0000,y:342.0000},
  {x:402.6667,y:342.6666},
  {x:402.3333,y:343.3334},
  {x:402.0000,y:344.0000},
  {x:401.3334,y:344.0000},
  {x:400.6666,y:344.0000},
  {x:400.0000,y:344.0000},
  {x:399.6667,y:344.6666},
  {x:399.3333,y:345.3334},
  {x:399.0000,y:346.0000},
  {x:397.6668,y:346.3333},
  {x:396.3332,y:346.6667},
  {x:395.0000,y:347.0000},
  {x:394.6667,y:347.6666},
  {x:394.3333,y:348.3334},
  {x:394.0000,y:349.0000},
  {x:393.3334,y:349.0000},
  {x:392.6666,y:349.0000},
  {x:392.0000,y:349.0000},
  {x:392.0000,y:349.3333},
  {x:392.0000,y:349.6667},
  {x:392.0000,y:350.0000},
  {x:391.3334,y:350.0000},
  {x:390.6666,y:350.0000},
  {x:390.0000,y:350.0000},
  {x:389.6667,y:350.6666},
  {x:389.3333,y:351.3334},
  {x:389.0000,y:352.0000},
  {x:388.3334,y:352.0000},
  {x:387.6666,y:352.0000},
  {x:387.0000,y:352.0000},
  {x:387.0000,y:352.3333},
  {x:387.0000,y:352.6667},
  {x:387.0000,y:353.0000},
  {x:386.3334,y:353.0000},
  {x:385.6666,y:353.0000},
  {x:385.0000,y:353.0000},
  {x:385.0000,y:353.3333},
  {x:385.0000,y:353.6667},
  {x:385.0000,y:354.0000},
  {x:384.3334,y:354.0000},
  {x:383.6666,y:354.0000},
  {x:383.0000,y:354.0000},
  {x:383.0000,y:354.3333},
  {x:383.0000,y:354.6667},
  {x:383.0000,y:355.0000},
  {x:382.3334,y:355.0000},
  {x:381.6666,y:355.0000},
  {x:381.0000,y:355.0000},
  {x:380.6667,y:355.6666},
  {x:380.3333,y:356.3334},
  {x:380.0000,y:357.0000},
  {x:379.3334,y:357.0000},
  {x:378.6666,y:357.0000},
  {x:378.0000,y:357.0000},
  {x:378.0000,y:357.3333},
  {x:378.0000,y:357.6667},
  {x:378.0000,y:358.0000},
  {x:376.3335,y:358.3333},
  {x:374.6665,y:358.6667},
  {x:373.0000,y:359.0000},
  {x:373.0000,y:359.3333},
  {x:373.0000,y:359.6667},
  {x:373.0000,y:360.0000},
  {x:371.6668,y:360.3333},
  {x:370.3332,y:360.6667},
  {x:369.0000,y:361.0000},
  {x:369.0000,y:361.3333},
  {x:369.0000,y:361.6667},
  {x:369.0000,y:362.0000},
  {x:368.3334,y:362.0000},
  {x:367.6666,y:362.0000},
  {x:367.0000,y:362.0000},
  {x:367.0000,y:362.3333},
  {x:367.0000,y:362.6667},
  {x:367.0000,y:363.0000},
  {x:366.3334,y:363.0000},
  {x:365.6666,y:363.0000},
  {x:365.0000,y:363.0000},
  {x:365.0000,y:363.3333},
  {x:365.0000,y:363.6667},
  {x:365.0000,y:364.0000},
  {x:364.3334,y:364.0000},
  {x:363.6666,y:364.0000},
  {x:363.0000,y:364.0000},
  {x:363.0000,y:364.3333},
  {x:363.0000,y:364.6667},
  {x:363.0000,y:365.0000},
  {x:362.3334,y:365.0000},
  {x:361.6666,y:365.0000},
  {x:361.0000,y:365.0000},
  {x:361.0000,y:365.3333},
  {x:361.0000,y:365.6667},
  {x:361.0000,y:366.0000},
  {x:360.3334,y:366.0000},
  {x:359.6666,y:366.0000},
  {x:359.0000,y:366.0000},
  {x:359.0000,y:366.3333},
  {x:359.0000,y:366.6667},
  {x:359.0000,y:367.0000},
  {x:357.6668,y:367.3333},
  {x:356.3332,y:367.6667},
  {x:355.0000,y:368.0000},
  {x:355.0000,y:368.3333},
  {x:355.0000,y:368.6667},
  {x:355.0000,y:369.0000},
  {x:354.3334,y:369.0000},
  {x:353.6666,y:369.0000},
  {x:353.0000,y:369.0000},
  {x:353.0000,y:369.3333},
  {x:353.0000,y:369.6667},
  {x:353.0000,y:370.0000},
  {x:352.0001,y:370.0000},
  {x:350.9999,y:370.0000},
  {x:350.0000,y:370.0000},
  {x:350.0000,y:370.3333},
  {x:350.0000,y:370.6667},
  {x:350.0000,y:371.0000},
  {x:348.6668,y:371.3333},
  {x:347.3332,y:371.6667},
  {x:346.0000,y:372.0000},
  {x:346.0000,y:372.3333},
  {x:346.0000,y:372.6667},
  {x:346.0000,y:373.0000},
  {x:345.3334,y:373.0000},
  {x:344.6666,y:373.0000},
  {x:344.0000,y:373.0000},
  {x:344.0000,y:373.3333},
  {x:344.0000,y:373.6667},
  {x:344.0000,y:374.0000},
  {x:343.3334,y:374.0000},
  {x:342.6666,y:374.0000},
  {x:342.0000,y:374.0000},
  {x:342.0000,y:374.3333},
  {x:342.0000,y:374.6667},
  {x:342.0000,y:375.0000},
  {x:340.0002,y:375.6666},
  {x:337.9998,y:376.3334},
  {x:336.0000,y:377.0000},
  {x:336.0000,y:377.3333},
  {x:336.0000,y:377.6667},
  {x:336.0000,y:378.0000},
  {x:335.0001,y:378.0000},
  {x:333.9999,y:378.0000},
  {x:333.0000,y:378.0000},
  {x:333.0000,y:378.3333},
  {x:333.0000,y:378.6667},
  {x:333.0000,y:379.0000},
  {x:332.3334,y:379.0000},
  {x:331.6666,y:379.0000},
  {x:331.0000,y:379.0000},
  {x:331.0000,y:379.3333},
  {x:331.0000,y:379.6667},
  {x:331.0000,y:380.0000},
  {x:330.3334,y:380.0000},
  {x:329.6666,y:380.0000},
  {x:329.0000,y:380.0000},
  {x:329.0000,y:380.3333},
  {x:329.0000,y:380.6667},
  {x:329.0000,y:381.0000},
  {x:327.0002,y:381.6666},
  {x:324.9998,y:382.3334},
  {x:323.0000,y:383.0000},
  {x:323.0000,y:383.3333},
  {x:323.0000,y:383.6667},
  {x:323.0000,y:384.0000},
  {x:322.0001,y:384.0000},
  {x:320.9999,y:384.0000},
  {x:320.0000,y:384.0000},
  {x:320.0000,y:384.3333},
  {x:320.0000,y:384.6667},
  {x:320.0000,y:385.0000},
  {x:319.3334,y:385.0000},
  {x:318.6666,y:385.0000},
  {x:318.0000,y:385.0000},
  {x:318.0000,y:385.3333},
  {x:318.0000,y:385.6667},
  {x:318.0000,y:386.0000},
  {x:316.0002,y:386.6666},
  {x:313.9998,y:387.3334},
  {x:312.0000,y:388.0000},
  {x:312.0000,y:388.3333},
  {x:312.0000,y:388.6667},
  {x:312.0000,y:389.0000},
  {x:311.0001,y:389.0000},
  {x:309.9999,y:389.0000},
  {x:309.0000,y:389.0000},
  {x:309.0000,y:389.3333},
  {x:309.0000,y:389.6667},
  {x:309.0000,y:390.0000},
  {x:307.6668,y:390.3333},
  {x:306.3332,y:390.6667},
  {x:305.0000,y:391.0000},
  {x:305.0000,y:391.3333},
  {x:305.0000,y:391.6667},
  {x:305.0000,y:392.0000},
  {x:304.0001,y:392.0000},
  {x:302.9999,y:392.0000},
  {x:302.0000,y:392.0000},
  {x:302.0000,y:392.3333},
  {x:302.0000,y:392.6667},
  {x:302.0000,y:393.0000},
  {x:301.3334,y:393.0000},
  {x:300.6666,y:393.0000},
  {x:300.0000,y:393.0000},
  {x:300.0000,y:393.3333},
  {x:300.0000,y:393.6667},
  {x:300.0000,y:394.0000},
  {x:298.3335,y:394.3333},
  {x:296.6665,y:394.6667},
  {x:295.0000,y:395.0000},
  {x:295.0000,y:395.3333},
  {x:295.0000,y:395.6667},
  {x:295.0000,y:396.0000},
  {x:294.0001,y:396.0000},
  {x:292.9999,y:396.0000},
  {x:292.0000,y:396.0000},
  {x:292.0000,y:396.3333},
  {x:292.0000,y:396.6667},
  {x:292.0000,y:397.0000},
  {x:286.6672,y:397.6666},
  {x:281.3328,y:398.3334},
  {x:276.0000,y:399.0000},
  {x:276.0000,y:399.3333},
  {x:276.0000,y:399.6667},
  {x:276.0000,y:400.0000},
  {x:272.6670,y:400.3333},
  {x:269.3330,y:400.6667},
  {x:266.0000,y:401.0000},
  {x:266.0000,y:401.3333},
  {x:266.0000,y:401.6667},
  {x:266.0000,y:402.0000},
  {x:263.3336,y:402.3333},
  {x:260.6664,y:402.6667},
  {x:258.0000,y:403.0000},
  {x:258.0000,y:403.3333},
  {x:258.0000,y:403.6667},
  {x:258.0000,y:404.0000},
  {x:257.0001,y:404.0000},
  {x:255.9999,y:404.0000},
  {x:255.0000,y:404.0000},
  {x:255.0000,y:404.3333},
  {x:255.0000,y:404.6667},
  {x:255.0000,y:405.0000},
  {x:252.6669,y:405.3333},
  {x:250.3331,y:405.6667},
  {x:248.0000,y:406.0000},
  {x:248.0000,y:406.3333},
  {x:248.0000,y:406.6667},
  {x:248.0000,y:407.0000},
  {x:246.6668,y:407.0000},
  {x:245.3332,y:407.0000},
  {x:244.0000,y:407.0000},
  {x:244.0000,y:407.3333},
  {x:244.0000,y:407.6667},
  {x:244.0000,y:408.0000},
  {x:243.0001,y:408.0000},
  {x:241.9999,y:408.0000},
  {x:241.0000,y:408.0000},
  {x:241.0000,y:408.3333},
  {x:241.0000,y:408.6667},
  {x:241.0000,y:409.0000},
  {x:240.0001,y:409.0000},
  {x:238.9999,y:409.0000},
  {x:238.0000,y:409.0000},
  {x:238.0000,y:409.3333},
  {x:238.0000,y:409.6667},
  {x:238.0000,y:410.0000},
  {x:237.0001,y:410.0000},
  {x:235.9999,y:410.0000},
  {x:235.0000,y:410.0000},
  {x:235.0000,y:410.3333},
  {x:235.0000,y:410.6667},
  {x:235.0000,y:411.0000},
  {x:234.3334,y:411.0000},
  {x:233.6666,y:411.0000},
  {x:233.0000,y:411.0000},
  {x:233.0000,y:411.3333},
  {x:233.0000,y:411.6667},
  {x:233.0000,y:412.0000},
  {x:232.0001,y:412.0000},
  {x:230.9999,y:412.0000},
  {x:230.0000,y:412.0000},
  {x:230.0000,y:412.3333},
  {x:230.0000,y:412.6667},
  {x:230.0000,y:413.0000},
  {x:229.3334,y:413.0000},
  {x:228.6666,y:413.0000},
  {x:228.0000,y:413.0000},
  {x:228.0000,y:413.3333},
  {x:228.0000,y:413.6667},
  {x:228.0000,y:414.0000},
  {x:227.0001,y:414.0000},
  {x:225.9999,y:414.0000},
  {x:225.0000,y:414.0000},
  {x:225.0000,y:414.3333},
  {x:225.0000,y:414.6667},
  {x:225.0000,y:415.0000},
  {x:224.3334,y:415.0000},
  {x:223.6666,y:415.0000},
  {x:223.0000,y:415.0000},
  {x:223.0000,y:415.3333},
  {x:223.0000,y:415.6667},
  {x:223.0000,y:416.0000},
  {x:222.0001,y:416.0000},
  {x:220.9999,y:416.0000},
  {x:220.0000,y:416.0000},
  {x:220.0000,y:416.3333},
  {x:220.0000,y:416.6667},
  {x:220.0000,y:417.0000},
  {x:218.0002,y:417.6666},
  {x:215.9998,y:418.3334},
  {x:214.0000,y:419.0000},
  {x:214.0000,y:419.3333},
  {x:214.0000,y:419.6667},
  {x:214.0000,y:420.0000},
  {x:213.3334,y:420.0000},
  {x:212.6666,y:420.0000},
  {x:212.0000,y:420.0000},
  {x:212.0000,y:420.3333},
  {x:212.0000,y:420.6667},
  {x:212.0000,y:421.0000},
  {x:210.0002,y:421.6666},
  {x:207.9998,y:422.3334},
  {x:206.0000,y:423.0000},
  {x:205.6667,y:423.6666},
  {x:205.3333,y:424.3334},
  {x:205.0000,y:425.0000},
  {x:203.3335,y:425.3333},
  {x:201.6665,y:425.6667},
  {x:200.0000,y:426.0000},
  {x:200.0000,y:426.3333},
  {x:200.0000,y:426.6667},
  {x:200.0000,y:427.0000},
  {x:198.6668,y:427.3333},
  {x:197.3332,y:427.6667},
  {x:196.0000,y:428.0000},
  {x:196.0000,y:428.3333},
  {x:196.0000,y:428.6667},
  {x:196.0000,y:429.0000},
  {x:194.6668,y:429.3333},
  {x:193.3332,y:429.6667},
  {x:192.0000,y:430.0000},
  {x:192.0000,y:430.3333},
  {x:192.0000,y:430.6667},
  {x:192.0000,y:431.0000},
  {x:191.3334,y:431.0000},
  {x:190.6666,y:431.0000},
  {x:190.0000,y:431.0000},
  {x:190.0000,y:431.3333},
  {x:190.0000,y:431.6667},
  {x:190.0000,y:432.0000},
  {x:189.0001,y:432.0000},
  {x:187.9999,y:432.0000},
  {x:187.0000,y:432.0000},
  {x:187.0000,y:432.3333},
  {x:187.0000,y:432.6667},
  {x:187.0000,y:433.0000},
  {x:185.6668,y:433.3333},
  {x:184.3332,y:433.6667},
  {x:183.0000,y:434.0000},
  {x:183.0000,y:434.3333},
  {x:183.0000,y:434.6667},
  {x:183.0000,y:435.0000},
  {x:182.0001,y:435.0000},
  {x:180.9999,y:435.0000},
  {x:180.0000,y:435.0000},
  {x:180.0000,y:435.3333},
  {x:180.0000,y:435.6667},
  {x:180.0000,y:436.0000},
  {x:179.3334,y:436.0000},
  {x:178.6666,y:436.0000},
  {x:178.0000,y:436.0000},
  {x:178.0000,y:436.3333},
  {x:178.0000,y:436.6667},
  {x:178.0000,y:437.0000},
  {x:175.0003,y:437.6666},
  {x:171.9997,y:438.3334},
  {x:169.0000,y:439.0000},
  {x:169.0000,y:439.3333},
  {x:169.0000,y:439.6667},
  {x:169.0000,y:440.0000},
  {x:168.3334,y:440.0000},
  {x:167.6666,y:440.0000},
  {x:167.0000,y:440.0000},
  {x:167.0000,y:440.3333},
  {x:167.0000,y:440.6667},
  {x:167.0000,y:441.0000},
  {x:166.0001,y:441.0000},
  {x:164.9999,y:441.0000},
  {x:164.0000,y:441.0000},
  {x:164.0000,y:441.3333},
  {x:164.0000,y:441.6667},
  {x:164.0000,y:442.0000},
  {x:163.3334,y:442.0000},
  {x:162.6666,y:442.0000},
  {x:162.0000,y:442.0000},
  {x:161.6667,y:442.6666},
  {x:161.3333,y:443.3334},
  {x:161.0000,y:444.0000},
  {x:159.6668,y:444.3333},
  {x:158.3332,y:444.6667},
  {x:157.0000,y:445.0000},
  {x:156.3334,y:445.9999},
  {x:155.6666,y:447.0001},
  {x:155.0000,y:448.0000},
  {x:154.3334,y:448.0000},
  {x:153.6666,y:448.0000},
  {x:153.0000,y:448.0000},
  {x:152.3334,y:448.9999},
  {x:151.6666,y:450.0001},
  {x:151.0000,y:451.0000},
  {x:150.3334,y:451.0000},
  {x:149.6666,y:451.0000},
  {x:149.0000,y:451.0000},
  {x:148.0001,y:452.3332},
  {x:146.9999,y:453.6668},
  {x:146.0000,y:455.0000},
  {x:145.3334,y:455.0000},
  {x:144.6666,y:455.0000},
  {x:144.0000,y:455.0000},
  {x:143.0001,y:456.3332},
  {x:141.9999,y:457.6668},
  {x:141.0000,y:459.0000},
  {x:140.3334,y:459.0000},
  {x:139.6666,y:459.0000},
  {x:139.0000,y:459.0000},
  {x:138.0001,y:460.3332},
  {x:136.9999,y:461.6668},
  {x:136.0000,y:463.0000},
  {x:135.3334,y:463.0000},
  {x:134.6666,y:463.0000},
  {x:134.0000,y:463.0000},
  {x:133.0001,y:464.3332},
  {x:131.9999,y:465.6668},
  {x:131.0000,y:467.0000},
  {x:130.3334,y:467.0000},
  {x:129.6666,y:467.0000},
  {x:129.0000,y:467.0000},
  {x:128.6667,y:467.6666},
  {x:128.3333,y:468.3334},
  {x:128.0000,y:469.0000},
  {x:127.0001,y:469.3333},
  {x:125.9999,y:469.6667},
  {x:125.0000,y:470.0000},
  {x:125.0000,y:470.3333},
  {x:125.0000,y:470.6667},
  {x:125.0000,y:471.0000},
  {x:124.0001,y:471.0000},
  {x:122.9999,y:471.0000},
  {x:122.0000,y:471.0000},
  {x:122.0000,y:471.3333},
  {x:122.0000,y:471.6667},
  {x:122.0000,y:472.0000},
  {x:121.3334,y:472.0000},
  {x:120.6666,y:472.0000},
  {x:120.0000,y:472.0000},
  {x:120.0000,y:472.3333},
  {x:120.0000,y:472.6667},
  {x:120.0000,y:473.0000},
  {x:118.3335,y:473.3333},
  {x:116.6665,y:473.6667},
  {x:115.0000,y:474.0000},
  {x:114.6667,y:474.6666},
  {x:114.3333,y:475.3334},
  {x:114.0000,y:476.0000},
  {x:113.3334,y:476.0000},
  {x:112.6666,y:476.0000},
  {x:112.0000,y:476.0000},
  {x:111.3334,y:476.9999},
  {x:110.6666,y:478.0001},
  {x:110.0000,y:479.0000},
  {x:109.0001,y:479.6666},
  {x:107.9999,y:480.3334},
  {x:107.0000,y:481.0000},
  {x:107.0000,y:481.3333},
  {x:107.0000,y:481.6667},
  {x:107.0000,y:482.0000},
  {x:106.3334,y:482.0000},
  {x:105.6666,y:482.0000},
  {x:105.0000,y:482.0000},
  {x:103.3335,y:483.9998},
  {x:101.6665,y:486.0002},
  {x:100.0000,y:488.0000},
  {x:99.3334,y:488.0000},
  {x:98.6666,y:488.0000},
  {x:98.0000,y:488.0000},
  {x:97.3334,y:488.9999},
  {x:96.6666,y:490.0001},
  {x:96.0000,y:491.0000},
  {x:95.3334,y:491.0000},
  {x:94.6666,y:491.0000},
  {x:94.0000,y:491.0000},
  {x:93.6667,y:491.6666},
  {x:93.3333,y:492.3334},
  {x:93.0000,y:493.0000},
  {x:91.6668,y:493.3333},
  {x:90.3332,y:493.6667},
  {x:89.0000,y:494.0000},
  {x:89.0000,y:494.3333},
  {x:89.0000,y:494.6667},
  {x:89.0000,y:495.0000},
  {x:88.0001,y:495.3333},
  {x:86.9999,y:495.6667},
  {x:86.0000,y:496.0000},
  {x:85.6667,y:496.6666},
  {x:85.3333,y:497.3334},
  {x:85.0000,y:498.0000},
  {x:84.3334,y:498.0000},
  {x:83.6666,y:498.0000},
  {x:83.0000,y:498.0000},
  {x:83.0000,y:498.3333},
  {x:83.0000,y:498.6667},
  {x:83.0000,y:499.0000},
  {x:82.3334,y:499.0000},
  {x:81.6666,y:499.0000},
  {x:81.0000,y:499.0000},
  {x:80.6667,y:499.6666},
  {x:80.3333,y:500.3334},
  {x:80.0000,y:501.0000},
  {x:78.6668,y:501.3333},
  {x:77.3332,y:501.6667},
  {x:76.0000,y:502.0000},
  {x:75.6667,y:502.6666},
  {x:75.3333,y:503.3334},
  {x:75.0000,y:504.0000},
  {x:73.6668,y:504.3333},
  {x:72.3332,y:504.6667},
  {x:71.0000,y:505.0000},
  {x:70.6667,y:505.6666},
  {x:70.3333,y:506.3334},
  {x:70.0000,y:507.0000},
  {x:69.3334,y:507.0000},
  {x:68.6666,y:507.0000},
  {x:68.0000,y:507.0000},
  {x:68.0000,y:507.3333},
  {x:68.0000,y:507.6667},
  {x:68.0000,y:508.0000},
  {x:67.3334,y:508.0000},
  {x:66.6666,y:508.0000},
  {x:66.0000,y:508.0000},
  {x:66.0000,y:508.3333},
  {x:66.0000,y:508.6667},
  {x:66.0000,y:509.0000},
  {x:65.3334,y:509.0000},
  {x:64.6666,y:509.0000},
  {x:64.0000,y:509.0000},
  {x:64.0000,y:509.3333},
  {x:64.0000,y:509.6667},
  {x:64.0000,y:510.0000},
  {x:63.3334,y:510.0000},
  {x:62.6666,y:510.0000},
  {x:62.0000,y:510.0000},
  {x:62.0000,y:510.3333},
  {x:62.0000,y:510.6667},
  {x:62.0000,y:511.0000},
  {x:61.3334,y:511.0000},
  {x:60.6666,y:511.0000},
  {x:60.0000,y:511.0000},
  {x:60.0000,y:511.3333},
  {x:60.0000,y:511.6667},
  {x:60.0000,y:512.0000},
  {x:59.3334,y:512.0000},
  {x:58.6666,y:512.0000},
  {x:58.0000,y:512.0000},
  {x:58.0000,y:512.3333},
  {x:58.0000,y:512.6667},
  {x:58.0000,y:513.0000},
  {x:57.3334,y:513.0000},
  {x:56.6666,y:513.0000},
  {x:56.0000,y:513.0000},
  {x:56.0000,y:513.3333},
  {x:56.0000,y:513.6667},
  {x:56.0000,y:514.0000},
  {x:55.3334,y:514.0000},
  {x:54.6666,y:514.0000},
  {x:54.0000,y:514.0000},
  {x:54.0000,y:514.3333},
  {x:54.0000,y:514.6667},
  {x:54.0000,y:515.0000},
  {x:53.3334,y:515.0000},
  {x:52.6666,y:515.0000},
  {x:52.0000,y:515.0000},
  {x:52.0000,y:515.3333},
  {x:52.0000,y:515.6667},
  {x:52.0000,y:516.0000},
  {x:50.6668,y:516.3333},
  {x:49.3332,y:516.6667},
  {x:48.0000,y:517.0000},
  {x:48.0000,y:517.3333},
  {x:48.0000,y:517.6667},
  {x:48.0000,y:518.0000},
  {x:47.3334,y:518.0000},
  {x:46.6666,y:518.0000},
  {x:46.0000,y:518.0000},
  {x:46.0000,y:518.3333},
  {x:46.0000,y:518.6667},
  {x:46.0000,y:519.0000},
  {x:45.0001,y:519.0000},
  {x:43.9999,y:519.0000},
  {x:43.0000,y:519.0000},
  {x:43.0000,y:519.3333},
  {x:43.0000,y:519.6667},
  {x:43.0000,y:520.0000},
  {x:42.3334,y:520.0000},
  {x:41.6666,y:520.0000},
  {x:41.0000,y:520.0000},
  {x:41.0000,y:520.3333},
  {x:41.0000,y:520.6667},
  {x:41.0000,y:521.0000},
  {x:39.3335,y:521.3333},
  {x:37.6665,y:521.6667},
  {x:36.0000,y:522.0000},
  {x:36.0000,y:522.3333},
  {x:36.0000,y:522.6667},
  {x:36.0000,y:523.0000},
  {x:33.0003,y:523.6666},
  {x:29.9997,y:524.3334},
  {x:27.0000,y:525.0000},
  {x:27.0000,y:525.3333},
  {x:27.0000,y:525.6667},
  {x:27.0000,y:526.0000},
  {x:26.3334,y:526.0000},
  {x:25.6666,y:526.0000},
  {x:25.0000,y:526.0000},
  {x:25.0000,y:526.3333},
  {x:25.0000,y:526.6667},
  {x:25.0000,y:527.0000},
  {x:24.0001,y:527.0000},
  {x:22.9999,y:527.0000},
  {x:22.0000,y:527.0000},
  {x:22.0000,y:527.3333},
  {x:22.0000,y:527.6667},
  {x:22.0000,y:528.0000},
  {x:21.0001,y:528.0000},
  {x:19.9999,y:528.0000},
  {x:19.0000,y:528.0000},
  {x:19.0000,y:528.3333},
  {x:19.0000,y:528.6667},
  {x:19.0000,y:529.0000},
  {x:18.3334,y:529.0000},
  {x:17.6666,y:529.0000},
  {x:17.0000,y:529.0000},
  {x:17.0000,y:529.3333},
  {x:17.0000,y:529.6667},
  {x:17.0000,y:530.0000},
  {x:12.1481,y:532.1405},
  {x:6.1686,y:532.2963},
  {x:3.0000,y:536.0000},
  {x:5.4376,y:538.2801},
  {x:3.7915,y:538.0835},
  {x:5.0000,y:541.0000},
  {x:5.3333,y:541.0000},
  {x:5.6667,y:541.0000},
  {x:6.0000,y:541.0000},
  {x:6.0000,y:541.9999},
  {x:6.0000,y:543.0001},
  {x:6.0000,y:544.0000},
  {x:6.3333,y:544.0000},
  {x:6.6667,y:544.0000},
  {x:7.0000,y:544.0000},
  {x:7.0000,y:544.6666},
  {x:7.0000,y:545.3334},
  {x:7.0000,y:546.0000},
  {x:7.3333,y:546.0000},
  {x:7.6667,y:546.0000},
  {x:8.0000,y:546.0000},
  {x:8.0000,y:549.0000},
  {x:8.3333,y:549.0000},
  {x:8.6667,y:549.0000},
  {x:9.0000,y:549.0000},
  {x:9.3333,y:550.3332},
  {x:9.6667,y:551.6668},
  {x:10.0000,y:553.0000},
  {x:10.3333,y:553.0000},
  {x:10.6667,y:553.0000},
  {x:11.0000,y:553.0000},
  {x:11.0000,y:553.9999},
  {x:11.0000,y:555.0001},
  {x:11.0000,y:556.0000},
  {x:11.3333,y:556.0000},
  {x:11.6667,y:556.0000},
  {x:12.0000,y:556.0000},
  {x:12.6666,y:557.9998},
  {x:13.3334,y:560.0002},
  {x:14.0000,y:562.0000},
  {x:14.3333,y:562.0000},
  {x:14.6667,y:562.0000},
  {x:15.0000,y:562.0000},
  {x:15.0000,y:562.6666},
  {x:15.0000,y:563.3334},
  {x:15.0000,y:564.0000},
  {x:15.3333,y:564.0000},
  {x:15.6667,y:564.0000},
  {x:16.0000,y:564.0000},
  {x:16.6666,y:565.9998},
  {x:17.3334,y:568.0002},
  {x:18.0000,y:570.0000},
  {x:18.6666,y:570.3333},
  {x:19.3334,y:570.6667},
  {x:20.0000,y:571.0000},
  {x:20.6666,y:572.9998},
  {x:21.3334,y:575.0002},
  {x:22.0000,y:577.0000},
  {x:22.6666,y:577.3333},
  {x:23.3334,y:577.6667},
  {x:24.0000,y:578.0000},
  {x:24.3333,y:579.3332},
  {x:24.6667,y:580.6668},
  {x:25.0000,y:582.0000},
  {x:25.6666,y:582.3333},
  {x:26.3334,y:582.6667},
  {x:27.0000,y:583.0000},
  {x:27.3333,y:584.3332},
  {x:27.6667,y:585.6668},
  {x:28.0000,y:587.0000},
  {x:28.6666,y:587.3333},
  {x:29.3334,y:587.6667},
  {x:30.0000,y:588.0000},
  {x:30.0000,y:588.6666},
  {x:30.0000,y:589.3334},
  {x:30.0000,y:590.0000},
  {x:30.3333,y:590.0000},
  {x:30.6667,y:590.0000},
  {x:31.0000,y:590.0000},
  {x:31.3333,y:590.9999},
  {x:31.6667,y:592.0001},
  {x:32.0000,y:593.0000},
  {x:32.6666,y:593.3333},
  {x:33.3334,y:593.6667},
  {x:34.0000,y:594.0000},
  {x:34.0000,y:594.6666},
  {x:34.0000,y:595.3334},
  {x:34.0000,y:596.0000},
  {x:34.3333,y:596.0000},
  {x:34.6667,y:596.0000},
  {x:35.0000,y:596.0000},
  {x:35.3333,y:596.9999},
  {x:35.6667,y:598.0001},
  {x:36.0000,y:599.0000},
  {x:36.6666,y:599.3333},
  {x:37.3334,y:599.6667},
  {x:38.0000,y:600.0000},
  {x:38.0000,y:600.6666},
  {x:38.0000,y:601.3334},
  {x:38.0000,y:602.0000},
  {x:38.6666,y:602.3333},
  {x:39.3334,y:602.6667},
  {x:40.0000,y:603.0000},
  {x:40.3333,y:603.9999},
  {x:40.6667,y:605.0001},
  {x:41.0000,y:606.0000},
  {x:41.9999,y:606.6666},
  {x:43.0001,y:607.3334},
  {x:44.0000,y:608.0000},
  {x:44.0000,y:608.6666},
  {x:44.0000,y:609.3334},
  {x:44.0000,y:610.0000},
  {x:44.9999,y:610.6666},
  {x:46.0001,y:611.3334},
  {x:47.0000,y:612.0000},
  {x:47.0000,y:612.6666},
  {x:47.0000,y:613.3334},
  {x:47.0000,y:614.0000},
  {x:47.6666,y:614.3333},
  {x:48.3334,y:614.6667},
  {x:49.0000,y:615.0000},
  {x:49.3333,y:615.9999},
  {x:49.6667,y:617.0001},
  {x:50.0000,y:618.0000},
  {x:51.3332,y:618.9999},
  {x:52.6668,y:620.0001},
  {x:54.0000,y:621.0000},
  {x:54.0000,y:621.6666},
  {x:54.0000,y:622.3334},
  {x:54.0000,y:623.0000},
  {x:55.6665,y:624.3332},
  {x:57.3335,y:625.6668},
  {x:59.0000,y:627.0000},
  {x:59.0000,y:627.6666},
  {x:59.0000,y:628.3334},
  {x:59.0000,y:629.0000},
  {x:61.3331,y:630.9998},
  {x:63.6669,y:633.0002},
  {x:66.0000,y:635.0000},
  {x:66.0000,y:635.6666},
  {x:66.0000,y:636.3334},
  {x:66.0000,y:637.0000},
  {x:70.6662,y:641.3329},
  {x:75.3338,y:645.6671},
  {x:80.0000,y:650.0000},
  {x:86.3327,y:656.6660},
  {x:92.6673,y:663.3340},
  {x:99.0000,y:670.0000},
  {x:99.6666,y:670.0000},
  {x:100.3334,y:670.0000},
  {x:101.0000,y:670.0000},
  {x:102.9998,y:672.3331},
  {x:105.0002,y:674.6669},
  {x:107.0000,y:677.0000},
  {x:107.6666,y:677.0000},
  {x:108.3334,y:677.0000},
  {x:109.0000,y:677.0000},
  {x:110.3332,y:678.6665},
  {x:111.6668,y:680.3335},
  {x:113.0000,y:682.0000},
  {x:113.6666,y:682.0000},
  {x:114.3334,y:682.0000},
  {x:115.0000,y:682.0000},
  {x:115.0000,y:682.3333},
  {x:115.0000,y:682.6667},
  {x:115.0000,y:683.0000},
  {x:115.9999,y:683.6666},
  {x:117.0001,y:684.3334},
  {x:118.0000,y:685.0000},
  {x:118.3333,y:685.6666},
  {x:118.6667,y:686.3334},
  {x:119.0000,y:687.0000},
  {x:119.6666,y:687.0000},
  {x:120.3334,y:687.0000},
  {x:121.0000,y:687.0000},
  {x:121.3333,y:687.6666},
  {x:121.6667,y:688.3334},
  {x:122.0000,y:689.0000},
  {x:122.9999,y:689.3333},
  {x:124.0001,y:689.6667},
  {x:125.0000,y:690.0000},
  {x:125.9999,y:691.3332},
  {x:127.0001,y:692.6668},
  {x:128.0000,y:694.0000},
  {x:128.6666,y:694.0000},
  {x:129.3334,y:694.0000},
  {x:130.0000,y:694.0000},
  {x:130.6666,y:694.9999},
  {x:131.3334,y:696.0001},
  {x:132.0000,y:697.0000},
  {x:132.6666,y:697.0000},
  {x:133.3334,y:697.0000},
  {x:134.0000,y:697.0000},
  {x:134.6666,y:697.9999},
  {x:135.3334,y:699.0001},
  {x:136.0000,y:700.0000},
  {x:136.6666,y:700.0000},
  {x:137.3334,y:700.0000},
  {x:138.0000,y:700.0000},
  {x:138.6666,y:700.9999},
  {x:139.3334,y:702.0001},
  {x:140.0000,y:703.0000},
  {x:140.6666,y:703.0000},
  {x:141.3334,y:703.0000},
  {x:142.0000,y:703.0000},
  {x:142.3333,y:703.6666},
  {x:142.6667,y:704.3334},
  {x:143.0000,y:705.0000},
  {x:143.6666,y:705.0000},
  {x:144.3334,y:705.0000},
  {x:145.0000,y:705.0000},
  {x:145.6666,y:705.9999},
  {x:146.3334,y:707.0001},
  {x:147.0000,y:708.0000},
  {x:147.6666,y:708.0000},
  {x:148.3334,y:708.0000},
  {x:149.0000,y:708.0000},
  {x:149.3333,y:708.6666},
  {x:149.6667,y:709.3334},
  {x:150.0000,y:710.0000},
  {x:150.9999,y:710.3333},
  {x:152.0001,y:710.6667},
  {x:153.0000,y:711.0000},
  {x:153.0000,y:711.3333},
  {x:153.0000,y:711.6667},
  {x:153.0000,y:712.0000},
  {x:153.6666,y:712.0000},
  {x:154.3334,y:712.0000},
  {x:155.0000,y:712.0000},
  {x:155.3333,y:712.6666},
  {x:155.6667,y:713.3334},
  {x:156.0000,y:714.0000},
  {x:156.6666,y:714.0000},
  {x:157.3334,y:714.0000},
  {x:158.0000,y:714.0000},
  {x:158.3333,y:714.6666},
  {x:158.6667,y:715.3334},
  {x:159.0000,y:716.0000},
  {x:159.6666,y:716.0000},
  {x:160.3334,y:716.0000},
  {x:161.0000,y:716.0000},
  {x:161.0000,y:716.3333},
  {x:161.0000,y:716.6667},
  {x:161.0000,y:717.0000},
  {x:161.9999,y:717.3333},
  {x:163.0001,y:717.6667},
  {x:164.0000,y:718.0000},
  {x:164.3333,y:718.6666},
  {x:164.6667,y:719.3334},
  {x:165.0000,y:720.0000},
  {x:165.6666,y:720.0000},
  {x:166.3334,y:720.0000},
  {x:167.0000,y:720.0000},
  {x:167.3333,y:720.6666},
  {x:167.6667,y:721.3334},
  {x:168.0000,y:722.0000},
  {x:168.6666,y:722.0000},
  {x:169.3334,y:722.0000},
  {x:170.0000,y:722.0000},
  {x:170.6666,y:722.9999},
  {x:171.3334,y:724.0001},
  {x:172.0000,y:725.0000},
  {x:172.6666,y:725.0000},
  {x:173.3334,y:725.0000},
  {x:174.0000,y:725.0000},
  {x:174.3333,y:725.6666},
  {x:174.6667,y:726.3334},
  {x:175.0000,y:727.0000},
  {x:175.6666,y:727.0000},
  {x:176.3334,y:727.0000},
  {x:177.0000,y:727.0000},
  {x:177.6666,y:727.9999},
  {x:178.3334,y:729.0001},
  {x:179.0000,y:730.0000},
  {x:179.6666,y:730.0000},
  {x:180.3334,y:730.0000},
  {x:181.0000,y:730.0000},
  {x:181.3333,y:730.6666},
  {x:181.6667,y:731.3334},
  {x:182.0000,y:732.0000},
  {x:182.6666,y:732.0000},
  {x:183.3334,y:732.0000},
  {x:184.0000,y:732.0000},
  {x:184.3333,y:732.6666},
  {x:184.6667,y:733.3334},
  {x:185.0000,y:734.0000},
  {x:185.9999,y:734.3333},
  {x:187.0001,y:734.6667},
  {x:188.0000,y:735.0000},
  {x:188.6666,y:735.9999},
  {x:189.3334,y:737.0001},
  {x:190.0000,y:738.0000},
  {x:190.6666,y:738.0000},
  {x:191.3334,y:738.0000},
  {x:192.0000,y:738.0000},
  {x:192.9999,y:739.3332},
  {x:194.0001,y:740.6668},
  {x:195.0000,y:742.0000},
  {x:195.6666,y:742.0000},
  {x:196.3334,y:742.0000},
  {x:197.0000,y:742.0000},
  {x:197.9999,y:743.3332},
  {x:199.0001,y:744.6668},
  {x:200.0000,y:746.0000},
  {x:200.6666,y:746.0000},
  {x:201.3334,y:746.0000},
  {x:202.0000,y:746.0000},
  {x:202.9999,y:747.3332},
  {x:204.0001,y:748.6668},
  {x:205.0000,y:750.0000},
  {x:205.6666,y:750.0000},
  {x:206.3334,y:750.0000},
  {x:207.0000,y:750.0000},
  {x:207.9999,y:751.3332},
  {x:209.0001,y:752.6668},
  {x:210.0000,y:754.0000},
  {x:210.6666,y:754.0000},
  {x:211.3334,y:754.0000},
  {x:212.0000,y:754.0000},
  {x:213.6665,y:755.9998},
  {x:215.3335,y:758.0002},
  {x:217.0000,y:760.0000},
  {x:217.6666,y:760.0000},
  {x:218.3334,y:760.0000},
  {x:219.0000,y:760.0000},
  {x:220.9998,y:762.3331},
  {x:223.0002,y:764.6669},
  {x:225.0000,y:767.0000},
  {x:236.3719,y:775.2288},
  {x:245.2347,y:785.2313},
  {x:255.0000,y:795.0000},
  {x:259.3329,y:798.9996},
  {x:263.6671,y:803.0004},
  {x:268.0000,y:807.0000},
  {x:268.0000,y:807.6666},
  {x:268.0000,y:808.3334},
  {x:268.0000,y:809.0000},
  {x:271.3330,y:811.9997},
  {x:274.6670,y:815.0003},
  {x:278.0000,y:818.0000},
  {x:278.0000,y:818.6666},
  {x:278.0000,y:819.3334},
  {x:278.0000,y:820.0000},
  {x:279.9998,y:821.6665},
  {x:282.0002,y:823.3335},
  {x:284.0000,y:825.0000},
  {x:284.0000,y:825.6666},
  {x:284.0000,y:826.3334},
  {x:284.0000,y:827.0000},
  {x:285.9998,y:828.6665},
  {x:288.0002,y:830.3335},
  {x:290.0000,y:832.0000},
  {x:290.0000,y:832.6666},
  {x:290.0000,y:833.3334},
  {x:290.0000,y:834.0000},
  {x:291.6665,y:835.3332},
  {x:293.3335,y:836.6668},
  {x:295.0000,y:838.0000},
  {x:295.0000,y:838.6666},
  {x:295.0000,y:839.3334},
  {x:295.0000,y:840.0000},
  {x:295.6666,y:840.3333},
  {x:296.3334,y:840.6667},
  {x:297.0000,y:841.0000},
  {x:297.0000,y:841.3333},
  {x:297.0000,y:841.6667},
  {x:297.0000,y:842.0000},
  {x:297.6666,y:842.3333},
  {x:298.3334,y:842.6667},
  {x:299.0000,y:843.0000},
  {x:299.0000,y:843.6666},
  {x:299.0000,y:844.3334},
  {x:299.0000,y:845.0000},
  {x:300.3332,y:845.9999},
  {x:301.6668,y:847.0001},
  {x:303.0000,y:848.0000},
  {x:303.0000,y:848.6666},
  {x:303.0000,y:849.3334},
  {x:303.0000,y:850.0000},
  {x:303.6666,y:850.3333},
  {x:304.3334,y:850.6667},
  {x:305.0000,y:851.0000},
  {x:305.3333,y:851.9999},
  {x:305.6667,y:853.0001},
  {x:306.0000,y:854.0000},
  {x:307.3332,y:854.9999},
  {x:308.6668,y:856.0001},
  {x:310.0000,y:857.0000},
  {x:310.0000,y:857.6666},
  {x:310.0000,y:858.3334},
  {x:310.0000,y:859.0000},
  {x:310.9999,y:859.6666},
  {x:312.0001,y:860.3334},
  {x:313.0000,y:861.0000},
  {x:313.0000,y:861.6666},
  {x:313.0000,y:862.3334},
  {x:313.0000,y:863.0000},
  {x:313.6666,y:863.3333},
  {x:314.3334,y:863.6667},
  {x:315.0000,y:864.0000},
  {x:315.0000,y:864.6666},
  {x:315.0000,y:865.3334},
  {x:315.0000,y:866.0000},
  {x:315.6666,y:866.3333},
  {x:316.3334,y:866.6667},
  {x:317.0000,y:867.0000},
  {x:317.3333,y:867.9999},
  {x:317.6667,y:869.0001},
  {x:318.0000,y:870.0000},
  {x:318.9999,y:870.6666},
  {x:320.0001,y:871.3334},
  {x:321.0000,y:872.0000},
  {x:321.0000,y:872.6666},
  {x:321.0000,y:873.3334},
  {x:321.0000,y:874.0000},
  {x:321.6666,y:874.3333},
  {x:322.3334,y:874.6667},
  {x:323.0000,y:875.0000},
  {x:323.0000,y:875.6666},
  {x:323.0000,y:876.3334},
  {x:323.0000,y:877.0000},
  {x:323.9999,y:877.6666},
  {x:325.0001,y:878.3334},
  {x:326.0000,y:879.0000},
  {x:326.0000,y:879.6666},
  {x:326.0000,y:880.3334},
  {x:326.0000,y:881.0000},
  {x:326.6666,y:881.3333},
  {x:327.3334,y:881.6667},
  {x:328.0000,y:882.0000},
  {x:328.3333,y:882.9999},
  {x:328.6667,y:884.0001},
  {x:329.0000,y:885.0000},
  {x:329.3333,y:885.0000},
  {x:329.6667,y:885.0000},
  {x:330.0000,y:885.0000},
  {x:330.0000,y:885.6666},
  {x:330.0000,y:886.3334},
  {x:330.0000,y:887.0000},
  {x:330.6666,y:887.3333},
  {x:331.3334,y:887.6667},
  {x:332.0000,y:888.0000},
  {x:332.0000,y:888.6666},
  {x:332.0000,y:889.3334},
  {x:332.0000,y:890.0000},
  {x:332.3333,y:890.0000},
  {x:332.6667,y:890.0000},
  {x:333.0000,y:890.0000},
  {x:333.3333,y:890.9999},
  {x:333.6667,y:892.0001},
  {x:334.0000,y:893.0000},
  {x:334.6666,y:893.3333},
  {x:335.3334,y:893.6667},
  {x:336.0000,y:894.0000},
  {x:336.0000,y:894.6666},
  {x:336.0000,y:895.3334},
  {x:336.0000,y:896.0000},
  {x:336.6666,y:896.3333},
  {x:337.3334,y:896.6667},
  {x:338.0000,y:897.0000},
  {x:338.0000,y:897.6666},
  {x:338.0000,y:898.3334},
  {x:338.0000,y:899.0000},
  {x:338.6666,y:899.3333},
  {x:339.3334,y:899.6667},
  {x:340.0000,y:900.0000},
  {x:340.0000,y:900.6666},
  {x:340.0000,y:901.3334},
  {x:340.0000,y:902.0000},
  {x:340.6666,y:902.3333},
  {x:341.3334,y:902.6667},
  {x:342.0000,y:903.0000},
  {x:342.3333,y:904.3332},
  {x:342.6667,y:905.6668},
  {x:343.0000,y:907.0000},
  {x:343.3333,y:907.0000},
  {x:343.6667,y:907.0000},
  {x:344.0000,y:907.0000},
  {x:344.3333,y:907.9999},
  {x:344.6667,y:909.0001},
  {x:345.0000,y:910.0000},
  {x:345.6666,y:910.3333},
  {x:346.3334,y:910.6667},
  {x:347.0000,y:911.0000},
  {x:347.3333,y:912.3332},
  {x:347.6667,y:913.6668},
  {x:348.0000,y:915.0000},
  {x:348.3333,y:915.0000},
  {x:348.6667,y:915.0000},
  {x:349.0000,y:915.0000},
  {x:349.3333,y:915.9999},
  {x:349.6667,y:917.0001},
  {x:350.0000,y:918.0000},
  {x:350.6666,y:918.3333},
  {x:351.3334,y:918.6667},
  {x:352.0000,y:919.0000},
  {x:352.3333,y:920.3332},
  {x:352.6667,y:921.6668},
  {x:353.0000,y:923.0000},
  {x:353.6666,y:923.3333},
  {x:354.3334,y:923.6667},
  {x:355.0000,y:924.0000},
  {x:355.0000,y:924.6666},
  {x:355.0000,y:925.3334},
  {x:355.0000,y:926.0000},
  {x:355.3333,y:926.0000},
  {x:355.6667,y:926.0000},
  {x:356.0000,y:926.0000},
  {x:356.0000,y:926.6666},
  {x:356.0000,y:927.3334},
  {x:356.0000,y:928.0000},
  {x:356.3333,y:928.0000},
  {x:356.6667,y:928.0000},
  {x:357.0000,y:928.0000},
  {x:357.0000,y:928.6666},
  {x:357.0000,y:929.3334},
  {x:357.0000,y:930.0000},
  {x:357.6666,y:930.3333},
  {x:358.3334,y:930.6667},
  {x:359.0000,y:931.0000},
  {x:359.6666,y:932.9998},
  {x:360.3334,y:935.0002},
  {x:361.0000,y:937.0000},
  {x:361.6666,y:937.3333},
  {x:362.3334,y:937.6667},
  {x:363.0000,y:938.0000},
  {x:363.3333,y:939.3332},
  {x:363.6667,y:940.6668},
  {x:364.0000,y:942.0000},
  {x:364.3333,y:942.0000},
  {x:364.6667,y:942.0000},
  {x:365.0000,y:942.0000},
  {x:365.0000,y:942.6666},
  {x:365.0000,y:943.3334},
  {x:365.0000,y:944.0000},
  {x:365.3333,y:944.0000},
  {x:365.6667,y:944.0000},
  {x:366.0000,y:944.0000},
  {x:366.0000,y:944.6666},
  {x:366.0000,y:945.3334},
  {x:366.0000,y:946.0000},
  {x:366.6666,y:946.3333},
  {x:367.3334,y:946.6667},
  {x:368.0000,y:947.0000},
  {x:368.0000,y:947.6666},
  {x:368.0000,y:948.3334},
  {x:368.0000,y:949.0000},
  {x:368.3333,y:949.0000},
  {x:368.6667,y:949.0000},
  {x:369.0000,y:949.0000},
  {x:369.0000,y:949.6666},
  {x:369.0000,y:950.3334},
  {x:369.0000,y:951.0000},
  {x:369.3333,y:951.0000},
  {x:369.6667,y:951.0000},
  {x:370.0000,y:951.0000},
  {x:370.0000,y:951.6666},
  {x:370.0000,y:952.3334},
  {x:370.0000,y:953.0000},
  {x:370.3333,y:953.0000},
  {x:370.6667,y:953.0000},
  {x:371.0000,y:953.0000},
  {x:371.0000,y:953.6666},
  {x:371.0000,y:954.3334},
  {x:371.0000,y:955.0000},
  {x:371.3333,y:955.0000},
  {x:371.6667,y:955.0000},
  {x:372.0000,y:955.0000},
  {x:372.3333,y:956.3332},
  {x:372.6667,y:957.6668},
  {x:373.0000,y:959.0000},
  {x:373.6666,y:959.3333},
  {x:374.3334,y:959.6667},
  {x:375.0000,y:960.0000},
  {x:375.0000,y:960.6666},
  {x:375.0000,y:961.3334},
  {x:375.0000,y:962.0000},
  {x:375.3333,y:962.0000},
  {x:375.6667,y:962.0000},
  {x:376.0000,y:962.0000},
  {x:376.0000,y:962.6666},
  {x:376.0000,y:963.3334},
  {x:376.0000,y:964.0000},
  {x:376.3333,y:964.0000},
  {x:376.6667,y:964.0000},
  {x:377.0000,y:964.0000},
  {x:377.0000,y:964.6666},
  {x:377.0000,y:965.3334},
  {x:377.0000,y:966.0000},
  {x:377.3333,y:966.0000},
  {x:377.6667,y:966.0000},
  {x:378.0000,y:966.0000},
  {x:378.0000,y:966.6666},
  {x:378.0000,y:967.3334},
  {x:378.0000,y:968.0000},
  {x:378.3333,y:968.0000},
  {x:378.6667,y:968.0000},
  {x:379.0000,y:968.0000},
  {x:379.0000,y:968.6666},
  {x:379.0000,y:969.3334},
  {x:379.0000,y:970.0000},
  {x:379.3333,y:970.0000},
  {x:379.6667,y:970.0000},
  {x:380.0000,y:970.0000},
  {x:380.0000,y:970.6666},
  {x:380.0000,y:971.3334},
  {x:380.0000,y:972.0000},
  {x:380.3333,y:972.0000},
  {x:380.6667,y:972.0000},
  {x:381.0000,y:972.0000},
  {x:381.0000,y:972.6666},
  {x:381.0000,y:973.3334},
  {x:381.0000,y:974.0000},
  {x:381.3333,y:974.0000},
  {x:381.6667,y:974.0000},
  {x:382.0000,y:974.0000},
  {x:382.0000,y:974.6666},
  {x:382.0000,y:975.3334},
  {x:382.0000,y:976.0000},
  {x:382.3333,y:976.0000},
  {x:382.6667,y:976.0000},
  {x:383.0000,y:976.0000},
  {x:383.0000,y:976.6666},
  {x:383.0000,y:977.3334},
  {x:383.0000,y:978.0000},
  {x:383.3333,y:978.0000},
  {x:383.6667,y:978.0000},
  {x:384.0000,y:978.0000},
  {x:384.0000,y:978.6666},
  {x:384.0000,y:979.3334},
  {x:384.0000,y:980.0000},
  {x:384.3333,y:980.0000},
  {x:384.6667,y:980.0000},
  {x:385.0000,y:980.0000},
  {x:385.3333,y:981.3332},
  {x:385.6667,y:982.6668},
  {x:386.0000,y:984.0000},
  {x:386.3333,y:984.0000},
  {x:386.6667,y:984.0000},
  {x:387.0000,y:984.0000},
  {x:387.0000,y:984.6666},
  {x:387.0000,y:985.3334},
  {x:387.0000,y:986.0000},
  {x:387.3333,y:986.0000},
  {x:387.6667,y:986.0000},
  {x:388.0000,y:986.0000},
  {x:388.0000,y:986.9999},
  {x:388.0000,y:988.0001},
  {x:388.0000,y:989.0000},
  {x:388.3333,y:989.0000},
  {x:388.6667,y:989.0000},
  {x:389.0000,y:989.0000},
  {x:389.0000,y:989.6666},
  {x:389.0000,y:990.3334},
  {x:389.0000,y:991.0000},
  {x:389.3333,y:991.0000},
  {x:389.6667,y:991.0000},
  {x:390.0000,y:991.0000},
  {x:390.0000,y:991.6666},
  {x:390.0000,y:992.3334},
  {x:390.0000,y:993.0000},
  {x:390.3333,y:993.0000},
  {x:390.6667,y:993.0000},
  {x:391.0000,y:993.0000},
  {x:391.0000,y:993.6666},
  {x:391.0000,y:994.3334},
  {x:391.0000,y:995.0000},
  {x:391.3333,y:995.0000},
  {x:391.6667,y:995.0000},
  {x:392.0000,y:995.0000},
  {x:392.6666,y:996.9998},
  {x:393.3334,y:999.0002},
  {x:394.0000,y:1001.0000},
  {x:394.3333,y:1001.0000},
  {x:394.6667,y:1001.0000},
  {x:395.0000,y:1001.0000},
  {x:395.0000,y:1001.9999},
  {x:395.0000,y:1003.0001},
  {x:395.0000,y:1004.0000},
  {x:395.3333,y:1004.0000},
  {x:395.6667,y:1004.0000},
  {x:396.0000,y:1004.0000},
  {x:396.6666,y:1005.9998},
  {x:397.3334,y:1008.0002},
  {x:398.0000,y:1010.0000},
  {x:398.3333,y:1010.0000},
  {x:398.6667,y:1010.0000},
  {x:399.0000,y:1010.0000},
  {x:399.0000,y:1010.6666},
  {x:399.0000,y:1011.3334},
  {x:399.0000,y:1012.0000},
  {x:399.3333,y:1012.0000},
  {x:399.6667,y:1012.0000},
  {x:400.0000,y:1012.0000},
  {x:400.0000,y:1012.6666},
  {x:400.0000,y:1013.3334},
  {x:400.0000,y:1014.0000},
  {x:400.3333,y:1014.0000},
  {x:400.6667,y:1014.0000},
  {x:401.0000,y:1014.0000},
  {x:401.6666,y:1015.9998},
  {x:402.3334,y:1018.0002},
  {x:403.0000,y:1020.0000},
  {x:403.9999,y:1020.6666},
  {x:405.0001,y:1021.3334},
  {x:406.0000,y:1022.0000},
  {x:406.0000,y:1022.6666},
  {x:406.0000,y:1023.3334},
  {x:406.0000,y:1024.0000},
  {x:407.7959,y:1025.7635},
  {x:408.0000,y:1026.0000},
  {x:408.6666,y:1026.3333},
  {x:409.3334,y:1026.6667},
  {x:410.0000,y:1027.0000},
  {x:412.2548,y:1031.6520},
  {x:407.0354,y:1035.8836},
  {x:411.0000,y:1038.0000},
  {x:414.9996,y:1038.0000},
  {x:419.0004,y:1038.0000},
  {x:423.0000,y:1038.0000},
  {x:426.9547,y:1034.7536},
  {x:440.9713,y:1037.8475},
  {x:447.0000,y:1036.0000},
  {x:461.7637,y:1031.4757},
  {x:478.6462,y:1027.8523},
  {x:493.0000,y:1023.0000},
  {x:500.3326,y:1022.0001},
  {x:507.6674,y:1020.9999},
  {x:515.0000,y:1020.0000},
  {x:515.0000,y:1019.6667},
  {x:515.0000,y:1019.3333},
  {x:515.0000,y:1019.0000},
  {x:516.3332,y:1019.0000},
  {x:517.6668,y:1019.0000},
  {x:519.0000,y:1019.0000},
  {x:519.0000,y:1018.6667},
  {x:519.0000,y:1018.3333},
  {x:519.0000,y:1018.0000},
  {x:520.3332,y:1018.0000},
  {x:521.6668,y:1018.0000},
  {x:523.0000,y:1018.0000},
  {x:523.0000,y:1017.6667},
  {x:523.0000,y:1017.3333},
  {x:523.0000,y:1017.0000},
  {x:524.6665,y:1017.0000},
  {x:526.3335,y:1017.0000},
  {x:528.0000,y:1017.0000},
  {x:528.0000,y:1016.6667},
  {x:528.0000,y:1016.3333},
  {x:528.0000,y:1016.0000},
  {x:529.9998,y:1016.0000},
  {x:532.0002,y:1016.0000},
  {x:534.0000,y:1016.0000},
  {x:534.0000,y:1015.6667},
  {x:534.0000,y:1015.3333},
  {x:534.0000,y:1015.0000},
  {x:536.3331,y:1015.0000},
  {x:538.6669,y:1015.0000},
  {x:541.0000,y:1015.0000},
  {x:541.0000,y:1014.6667},
  {x:541.0000,y:1014.3333},
  {x:541.0000,y:1014.0000},
  {x:542.9998,y:1014.0000},
  {x:545.0002,y:1014.0000},
  {x:547.0000,y:1014.0000},
  {x:547.0000,y:1013.6667},
  {x:547.0000,y:1013.3333},
  {x:547.0000,y:1013.0000},
  {x:549.3331,y:1013.0000},
  {x:551.6669,y:1013.0000},
  {x:554.0000,y:1013.0000},
  {x:554.0000,y:1012.6667},
  {x:554.0000,y:1012.3333},
  {x:554.0000,y:1012.0000},
  {x:556.3331,y:1012.0000},
  {x:558.6669,y:1012.0000},
  {x:561.0000,y:1012.0000},
  {x:561.0000,y:1011.6667},
  {x:561.0000,y:1011.3333},
  {x:561.0000,y:1011.0000},
  {x:563.3331,y:1011.0000},
  {x:565.6669,y:1011.0000},
  {x:568.0000,y:1011.0000},
  {x:568.0000,y:1010.6667},
  {x:568.0000,y:1010.3333},
  {x:568.0000,y:1010.0000},
  {x:570.3331,y:1010.0000},
  {x:572.6669,y:1010.0000},
  {x:575.0000,y:1010.0000},
  {x:575.0000,y:1009.6667},
  {x:575.0000,y:1009.3333},
  {x:575.0000,y:1009.0000},
  {x:577.3331,y:1009.0000},
  {x:579.6669,y:1009.0000},
  {x:582.0000,y:1009.0000},
  {x:582.0000,y:1008.6667},
  {x:582.0000,y:1008.3333},
  {x:582.0000,y:1008.0000},
  {x:584.3331,y:1008.0000},
  {x:586.6669,y:1008.0000},
  {x:589.0000,y:1008.0000},
  {x:589.0000,y:1007.6667},
  {x:589.0000,y:1007.3333},
  {x:589.0000,y:1007.0000},
  {x:591.3331,y:1007.0000},
  {x:593.6669,y:1007.0000},
  {x:596.0000,y:1007.0000},
  {x:596.0000,y:1006.6667},
  {x:596.0000,y:1006.3333},
  {x:596.0000,y:1006.0000},
  {x:598.3331,y:1006.0000},
  {x:600.6669,y:1006.0000},
  {x:603.0000,y:1006.0000},
  {x:603.0000,y:1005.6667},
  {x:603.0000,y:1005.3333},
  {x:603.0000,y:1005.0000},
  {x:605.3331,y:1005.0000},
  {x:607.6669,y:1005.0000},
  {x:610.0000,y:1005.0000},
  {x:616.3344,y:1003.1420},
  {x:625.6603,y:1002.8453},
  {x:632.0000,y:1001.0000},
  {x:634.3331,y:1001.0000},
  {x:636.6669,y:1001.0000},
  {x:639.0000,y:1001.0000},
  {x:647.9642,y:998.3831},
  {x:660.0775,y:998.5744},
  {x:669.0000,y:996.0000},
  {x:671.6664,y:996.0000},
  {x:674.3336,y:996.0000},
  {x:677.0000,y:996.0000},
  {x:687.0708,y:993.0857},
  {x:700.8052,y:993.9002},
  {x:711.0000,y:991.0000},
  {x:718.9992,y:990.6667},
  {x:727.0008,y:990.3333},
  {x:735.0000,y:990.0000},
  {x:735.0000,y:989.6667},
  {x:735.0000,y:989.3333},
  {x:735.0000,y:989.0000},
  {x:737.9997,y:989.0000},
  {x:741.0003,y:989.0000},
  {x:744.0000,y:989.0000},
  {x:744.0000,y:988.6667},
  {x:744.0000,y:988.3333},
  {x:744.0000,y:988.0000},
  {x:746.6664,y:988.0000},
  {x:749.3336,y:988.0000},
  {x:752.0000,y:988.0000},
  {x:765.7356,y:984.0308},
  {x:783.3890,y:984.9216},
  {x:797.0000,y:981.0000},
  {x:799.6664,y:981.0000},
  {x:802.3336,y:981.0000},
  {x:805.0000,y:981.0000},
  {x:805.0000,y:980.6667},
  {x:805.0000,y:980.3333},
  {x:805.0000,y:980.0000},
  {x:807.6664,y:980.0000},
  {x:810.3336,y:980.0000},
  {x:813.0000,y:980.0000},
  {x:813.0000,y:979.6667},
  {x:813.0000,y:979.3333},
  {x:813.0000,y:979.0000},
  {x:815.9997,y:979.0000},
  {x:819.0003,y:979.0000},
  {x:822.0000,y:979.0000},
  {x:822.0000,y:978.6667},
  {x:822.0000,y:978.3333},
  {x:822.0000,y:978.0000},
  {x:825.6663,y:978.0000},
  {x:829.3337,y:978.0000},
  {x:833.0000,y:978.0000},
  {x:833.0000,y:977.6667},
  {x:833.0000,y:977.3333},
  {x:833.0000,y:977.0000},
  {x:836.6663,y:977.0000},
  {x:840.3337,y:977.0000},
  {x:844.0000,y:977.0000},
  {x:844.0000,y:976.6667},
  {x:844.0000,y:976.3333},
  {x:844.0000,y:976.0000},
  {x:847.6663,y:976.0000},
  {x:851.3337,y:976.0000},
  {x:855.0000,y:976.0000},
  {x:855.0000,y:975.6667},
  {x:855.0000,y:975.3333},
  {x:855.0000,y:975.0000},
  {x:858.3330,y:975.0000},
  {x:861.6670,y:975.0000},
  {x:865.0000,y:975.0000},
  {x:865.0000,y:974.6667},
  {x:865.0000,y:974.3333},
  {x:865.0000,y:974.0000},
  {x:868.6663,y:974.0000},
  {x:872.3337,y:974.0000},
  {x:876.0000,y:974.0000},
  {x:876.0000,y:973.6667},
  {x:876.0000,y:973.3333},
  {x:876.0000,y:973.0000},
  {x:879.6663,y:973.0000},
  {x:883.3337,y:973.0000},
  {x:887.0000,y:973.0000},
  {x:887.0000,y:972.6667},
  {x:887.0000,y:972.3333},
  {x:887.0000,y:972.0000},
  {x:890.6663,y:972.0000},
  {x:894.3337,y:972.0000},
  {x:898.0000,y:972.0000},
  {x:898.0000,y:971.6667},
  {x:898.0000,y:971.3333},
  {x:898.0000,y:971.0000},
  {x:901.3330,y:971.0000},
  {x:904.6670,y:971.0000},
  {x:908.0000,y:971.0000},
  {x:908.0000,y:970.6667},
  {x:908.0000,y:970.3333},
  {x:908.0000,y:970.0000},
  {x:911.6663,y:970.0000},
  {x:915.3337,y:970.0000},
  {x:919.0000,y:970.0000},
  {x:919.0000,y:969.6667},
  {x:919.0000,y:969.3333},
  {x:919.0000,y:969.0000},
  {x:922.3330,y:969.0000},
  {x:925.6670,y:969.0000},
  {x:929.0000,y:969.0000},
  {x:929.0000,y:968.6667},
  {x:929.0000,y:968.3333},
  {x:929.0000,y:968.0000},
  {x:932.3330,y:968.0000},
  {x:935.6670,y:968.0000},
  {x:939.0000,y:968.0000},
  {x:939.0000,y:967.6667},
  {x:939.0000,y:967.3333},
  {x:939.0000,y:967.0000},
  {x:942.3330,y:967.0000},
  {x:945.6670,y:967.0000},
  {x:949.0000,y:967.0000},
  {x:949.0000,y:966.6667},
  {x:949.0000,y:966.3333},
  {x:949.0000,y:966.0000},
  {x:952.3330,y:966.0000},
  {x:955.6670,y:966.0000},
  {x:959.0000,y:966.0000},
  {x:959.0000,y:965.6667},
  {x:959.0000,y:965.3333},
  {x:959.0000,y:965.0000},
  {x:961.9997,y:965.0000},
  {x:965.0003,y:965.0000},
  {x:968.0000,y:965.0000},
  {x:968.0000,y:964.6667},
  {x:968.0000,y:964.3333},
  {x:968.0000,y:964.0000},
  {x:970.9997,y:964.0000},
  {x:974.0003,y:964.0000},
  {x:977.0000,y:964.0000},
  {x:977.0000,y:963.6667},
  {x:977.0000,y:963.3333},
  {x:977.0000,y:963.0000},
  {x:980.3330,y:963.0000},
  {x:983.6670,y:963.0000},
  {x:987.0000,y:963.0000},
  {x:987.0000,y:962.6667},
  {x:987.0000,y:962.3333},
  {x:987.0000,y:962.0000},
  {x:990.3330,y:962.0000},
  {x:993.6670,y:962.0000},
  {x:997.0000,y:962.0000},
  {x:997.0000,y:961.6667},
  {x:997.0000,y:961.3333},
  {x:997.0000,y:961.0000},
  {x:1000.6663,y:961.0000},
  {x:1004.3337,y:961.0000},
  {x:1008.0000,y:961.0000},
  {x:1008.0000,y:960.6667},
  {x:1008.0000,y:960.3333},
  {x:1008.0000,y:960.0000},
  {x:1011.9996,y:960.0000},
  {x:1016.0004,y:960.0000},
  {x:1020.0000,y:960.0000},
  {x:1020.0000,y:959.6667},
  {x:1020.0000,y:959.3333},
  {x:1020.0000,y:959.0000},
  {x:1024.6662,y:959.0000},
  {x:1029.3338,y:959.0000},
  {x:1034.0000,y:959.0000},
  {x:1034.0000,y:958.6667},
  {x:1034.0000,y:958.3333},
  {x:1034.0000,y:958.0000},
  {x:1042.3325,y:957.6667},
  {x:1050.6675,y:957.3333},
  {x:1059.0000,y:957.0000},
  {x:1062.6663,y:957.0000},
  {x:1066.3337,y:957.0000},
  {x:1070.0000,y:957.0000},
  {x:1070.0000,y:956.6667},
  {x:1070.0000,y:956.3333},
  {x:1070.0000,y:956.0000},
  {x:1076.9993,y:956.0000},
  {x:1084.0007,y:956.0000},
  {x:1091.0000,y:956.0000},
  {x:1097.1652,y:954.2563},
  {x:1107.7040,y:956.7810},
  {x:1114.0000,y:955.0000},
  {x:1121.2062,y:952.9615},
  {x:1133.0954,y:953.9998},
  {x:1142.0000,y:954.0000},
  {x:1148.2580,y:954.0002},
  {x:1156.3227,y:954.3173},
  {x:1161.0000,y:953.0000},
  {x:1172.3322,y:953.0000},
  {x:1183.6678,y:953.0000},
  {x:1195.0000,y:953.0000},
  {x:1210.3703,y:953.0006},
  {x:1220.7655,y:950.0117},
  {x:1235.0000,y:950.0000},
  {x:1244.3324,y:950.0000},
  {x:1253.6676,y:950.0000},
  {x:1263.0000,y:950.0000},
  {x:1263.0000,y:949.6667},
  {x:1263.0000,y:949.3333},
  {x:1263.0000,y:949.0000},
  {x:1264.9998,y:949.0000},
  {x:1267.0002,y:949.0000},
  {x:1269.0000,y:949.0000},
  {x:1269.0000,y:948.6667},
  {x:1269.0000,y:948.3333},
  {x:1269.0000,y:948.0000},
  {x:1271.9997,y:948.0000},
  {x:1275.0003,y:948.0000},
  {x:1278.0000,y:948.0000},
  {x:1278.0000,y:947.6667},
  {x:1278.0000,y:947.3333},
  {x:1278.0000,y:947.0000},
  {x:1280.3331,y:947.0000},
  {x:1282.6669,y:947.0000},
  {x:1285.0000,y:947.0000},
  {x:1285.0000,y:946.6667},
  {x:1285.0000,y:946.3333},
  {x:1285.0000,y:946.0000},
  {x:1288.9996,y:945.6667},
  {x:1293.0004,y:945.3333},
  {x:1297.0000,y:945.0000},
  {x:1297.0000,y:944.6667},
  {x:1297.0000,y:944.3333},
  {x:1297.0000,y:944.0000},
  {x:1298.6665,y:944.0000},
  {x:1300.3335,y:944.0000},
  {x:1302.0000,y:944.0000},
  {x:1302.0000,y:943.6667},
  {x:1302.0000,y:943.3333},
  {x:1302.0000,y:943.0000},
  {x:1303.6665,y:943.0000},
  {x:1305.3335,y:943.0000},
  {x:1307.0000,y:943.0000},
  {x:1307.0000,y:942.6667},
  {x:1307.0000,y:942.3333},
  {x:1307.0000,y:942.0000},
  {x:1308.6665,y:942.0000},
  {x:1310.3335,y:942.0000},
  {x:1312.0000,y:942.0000},
  {x:1312.0000,y:941.6667},
  {x:1312.0000,y:941.3333},
  {x:1312.0000,y:941.0000},
  {x:1313.3332,y:941.0000},
  {x:1314.6668,y:941.0000},
  {x:1316.0000,y:941.0000},
  {x:1316.0000,y:940.6667},
  {x:1316.0000,y:940.3333},
  {x:1316.0000,y:940.0000},
  {x:1317.3332,y:940.0000},
  {x:1318.6668,y:940.0000},
  {x:1320.0000,y:940.0000},
  {x:1320.0000,y:939.6667},
  {x:1320.0000,y:939.3333},
  {x:1320.0000,y:939.0000},
  {x:1321.6665,y:939.0000},
  {x:1323.3335,y:939.0000},
  {x:1325.0000,y:939.0000},
  {x:1325.0000,y:938.6667},
  {x:1325.0000,y:938.3333},
  {x:1325.0000,y:938.0000},
  {x:1327.9997,y:938.0000},
  {x:1331.0003,y:938.0000},
  {x:1334.0000,y:938.0000},
  {x:1334.0000,y:937.6667},
  {x:1334.0000,y:937.3333},
  {x:1334.0000,y:937.0000},
  {x:1338.6662,y:937.0000},
  {x:1343.3338,y:937.0000},
  {x:1348.0000,y:937.0000},
  {x:1348.0000,y:936.6667},
  {x:1348.0000,y:936.3333},
  {x:1348.0000,y:936.0000},
  {x:1352.6662,y:936.0000},
  {x:1357.3338,y:936.0000},
  {x:1362.0000,y:936.0000},
  {x:1362.0000,y:935.6667},
  {x:1362.0000,y:935.3333},
  {x:1362.0000,y:935.0000},
  {x:1362.9999,y:935.0000},
  {x:1364.0001,y:935.0000},
  {x:1365.0000,y:935.0000},
  {x:1368.3330,y:935.0000},
  {x:1371.6670,y:935.0000},
  {x:1375.0000,y:935.0000},
  {x:1375.0000,y:934.6667},
  {x:1375.0000,y:934.3333},
  {x:1375.0000,y:934.0000},
  {x:1379.9995,y:934.0000},
  {x:1385.0005,y:934.0000},
  {x:1390.0000,y:934.0000},
  {x:1390.0000,y:933.6667},
  {x:1390.0000,y:933.3333},
  {x:1390.0000,y:933.0000},
  {x:1394.6662,y:933.0000},
  {x:1399.3338,y:933.0000},
  {x:1404.0000,y:933.0000},
  {x:1404.0000,y:932.6667},
  {x:1404.0000,y:932.3333},
  {x:1404.0000,y:932.0000},
  {x:1410.6660,y:931.6667},
  {x:1417.3340,y:931.3333},
  {x:1424.0000,y:931.0000},
  {x:1427.3330,y:931.0000},
  {x:1430.6670,y:931.0000},
  {x:1434.0000,y:931.0000},
  {x:1434.0000,y:930.6667},
  {x:1434.0000,y:930.3333},
  {x:1434.0000,y:930.0000},
  {x:1438.9995,y:930.0000},
  {x:1444.0005,y:930.0000},
  {x:1449.0000,y:930.0000},
  {x:1449.0000,y:929.6667},
  {x:1449.0000,y:929.3333},
  {x:1449.0000,y:929.0000},
  {x:1453.9995,y:929.0000},
  {x:1459.0005,y:929.0000},
  {x:1464.0000,y:929.0000},
  {x:1464.0000,y:928.6667},
  {x:1464.0000,y:928.3333},
  {x:1464.0000,y:928.0000},
  {x:1469.3328,y:928.0000},
  {x:1474.6672,y:928.0000},
  {x:1480.0000,y:928.0000},
  {x:1480.0000,y:927.6667},
  {x:1480.0000,y:927.3333},
  {x:1480.0000,y:927.0000},
  {x:1486.9993,y:926.6667},
  {x:1494.0007,y:926.3333},
  {x:1501.0000,y:926.0000},
  {x:1504.3330,y:926.0000},
  {x:1507.6670,y:926.0000},
  {x:1511.0000,y:926.0000},
  {x:1511.0000,y:925.6667},
  {x:1511.0000,y:925.3333},
  {x:1511.0000,y:925.0000},
  {x:1515.9995,y:925.0000},
  {x:1521.0005,y:925.0000},
  {x:1526.0000,y:925.0000},
  {x:1526.0000,y:924.6667},
  {x:1526.0000,y:924.3333},
  {x:1526.0000,y:924.0000},
  {x:1559.6633,y:922.3335},
  {x:1593.3367,y:920.6665},
  {x:1627.0000,y:919.0000},
  {x:1627.0000,y:918.6667},
  {x:1627.0000,y:918.3333},
  {x:1627.0000,y:918.0000},
  {x:1632.6661,y:918.0000},
  {x:1638.3339,y:918.0000},
  {x:1644.0000,y:918.0000},
  {x:1644.0000,y:917.6667},
  {x:1644.0000,y:917.3333},
  {x:1644.0000,y:917.0000},
  {x:1645.3332,y:917.0000},
  {x:1646.6668,y:917.0000},
  {x:1648.0000,y:917.0000},
  {x:1651.6663,y:917.0000},
  {x:1655.3337,y:917.0000},
  {x:1659.0000,y:917.0000},
  {x:1659.0000,y:916.6667},
  {x:1659.0000,y:916.3333},
  {x:1659.0000,y:916.0000},
  {x:1663.6662,y:916.0000},
  {x:1668.3338,y:916.0000},
  {x:1673.0000,y:916.0000},
  {x:1673.0000,y:915.6667},
  {x:1673.0000,y:915.3333},
  {x:1673.0000,y:915.0000},
  {x:1677.3329,y:915.0000},
  {x:1681.6671,y:915.0000},
  {x:1686.0000,y:915.0000},
  {x:1686.0000,y:914.6667},
  {x:1686.0000,y:914.3333},
  {x:1686.0000,y:914.0000},
  {x:1690.3329,y:914.0000},
  {x:1694.6671,y:914.0000},
  {x:1699.0000,y:914.0000},
  {x:1699.0000,y:913.6667},
  {x:1699.0000,y:913.3333},
  {x:1699.0000,y:913.0000},
  {x:1703.6662,y:913.0000},
  {x:1708.3338,y:913.0000},
  {x:1713.0000,y:913.0000},
  {x:1713.0000,y:912.6667},
  {x:1713.0000,y:912.3333},
  {x:1713.0000,y:912.0000},
  {x:1713.9999,y:912.0000},
  {x:1715.0001,y:912.0000},
  {x:1716.0000,y:912.0000},
  {x:1719.3330,y:912.0000},
  {x:1722.6670,y:912.0000},
  {x:1726.0000,y:912.0000},
  {x:1726.0000,y:911.6667},
  {x:1726.0000,y:911.3333},
  {x:1726.0000,y:911.0000},
  {x:1730.3329,y:911.0000},
  {x:1734.6671,y:911.0000},
  {x:1739.0000,y:911.0000},
  {x:1739.0000,y:910.6667},
  {x:1739.0000,y:910.3333},
  {x:1739.0000,y:910.0000},
  {x:1751.6654,y:909.3334},
  {x:1764.3346,y:908.6666},
  {x:1777.0000,y:908.0000},
  {x:1777.0000,y:907.6667},
  {x:1777.0000,y:907.3333},
  {x:1777.0000,y:907.0000},
  {x:1781.6662,y:907.0000},
  {x:1786.3338,y:907.0000},
  {x:1791.0000,y:907.0000},
  {x:1791.0000,y:906.6667},
  {x:1791.0000,y:906.3333},
  {x:1791.0000,y:906.0000},
  {x:1791.9999,y:906.0000},
  {x:1793.0001,y:906.0000},
  {x:1794.0000,y:906.0000},
  {x:1797.3330,y:906.0000},
  {x:1800.6670,y:906.0000},
  {x:1804.0000,y:906.0000},
  {x:1804.0000,y:905.6667},
  {x:1804.0000,y:905.3333},
  {x:1804.0000,y:905.0000},
  {x:1808.9995,y:905.0000},
  {x:1814.0005,y:905.0000},
  {x:1819.0000,y:905.0000},
  {x:1819.0000,y:904.6667},
  {x:1819.0000,y:904.3333},
  {x:1819.0000,y:904.0000},
  {x:1823.9995,y:904.0000},
  {x:1829.0005,y:904.0000},
  {x:1834.0000,y:904.0000},
  {x:1834.0000,y:903.6667},
  {x:1834.0000,y:903.3333},
  {x:1834.0000,y:903.0000},
  {x:1838.9995,y:903.0000},
  {x:1844.0005,y:903.0000},
  {x:1849.0000,y:903.0000},
  {x:1849.0000,y:902.6667},
  {x:1849.0000,y:902.3333},
  {x:1849.0000,y:902.0000},
  {x:1858.3324,y:901.6667},
  {x:1867.6676,y:901.3333},
  {x:1877.0000,y:901.0000},
  {x:1877.0000,y:900.6667},
  {x:1877.0000,y:900.3333},
  {x:1877.0000,y:900.0000},
  {x:1883.3327,y:899.6667},
  {x:1889.6673,y:899.3333},
  {x:1896.0000,y:899.0000},
  {x:1899.3330,y:899.0000},
  {x:1902.6670,y:899.0000},
  {x:1906.0000,y:899.0000},
  {x:1906.0000,y:898.6667},
  {x:1906.0000,y:898.3333},
  {x:1906.0000,y:898.0000},
  {x:1911.9994,y:898.0000},
  {x:1918.0006,y:898.0000},
  {x:1924.0000,y:898.0000},
  {x:1933.4664,y:895.3324},
  {x:1948.7335,y:897.0004},
  {x:1960.0000,y:897.0000},
  {x:1970.6656,y:897.0000},
  {x:1981.3344,y:897.0000},
  {x:1992.0000,y:897.0000},
  {x:1997.1553,y:895.5500},
  {x:2006.2242,y:897.6231},
  {x:2012.0000,y:896.0000},
  {x:2016.8636,y:894.6332},
  {x:2025.5090,y:896.5416},
  {x:2031.0000,y:895.0000},
  {x:2031.0000,y:894.6667},
  {x:2031.0000,y:894.3333},
  {x:2031.0000,y:894.0000},
  {x:2034.3330,y:894.0000},
  {x:2037.6670,y:894.0000},
  {x:2041.0000,y:894.0000},
  {x:2047.3327,y:893.6667},
  {x:2053.6673,y:893.3333},
  {x:2060.0000,y:893.0000},
  {x:2060.0000,y:892.6667},
  {x:2060.0000,y:892.3333},
  {x:2060.0000,y:892.0000},
  {x:2067.6659,y:891.6667},
  {x:2075.3341,y:891.3333},
  {x:2083.0000,y:891.0000},
  {x:2083.0000,y:890.6667},
  {x:2083.0000,y:890.3333},
  {x:2083.0000,y:890.0000},
  {x:2085.6664,y:890.0000},
  {x:2088.3336,y:890.0000},
  {x:2091.0000,y:890.0000},
  {x:2091.0000,y:889.6667},
  {x:2091.0000,y:889.3333},
  {x:2091.0000,y:889.0000},
  {x:2093.3331,y:889.0000},
  {x:2095.6669,y:889.0000},
  {x:2098.0000,y:889.0000},
  {x:2098.0000,y:888.6667},
  {x:2098.0000,y:888.3333},
  {x:2098.0000,y:888.0000},
  {x:2100.6664,y:888.0000},
  {x:2103.3336,y:888.0000},
  {x:2106.0000,y:888.0000},
  {x:2106.0000,y:887.6667},
  {x:2106.0000,y:887.3333},
  {x:2106.0000,y:887.0000},
  {x:2110.9995,y:887.0000},
  {x:2116.0005,y:887.0000},
  {x:2121.0000,y:887.0000},
  {x:2121.0000,y:887.3333},
  {x:2121.0000,y:887.6667},
  {x:2121.0000,y:888.0000},
  {x:2122.9998,y:888.0000},
  {x:2125.0002,y:888.0000},
  {x:2127.0000,y:888.0000},
  {x:2127.0000,y:888.3333},
  {x:2127.0000,y:888.6667},
  {x:2127.0000,y:889.0000},
  {x:2128.3332,y:889.0000},
  {x:2129.6668,y:889.0000},
  {x:2131.0000,y:889.0000},
  {x:2131.0000,y:889.3333},
  {x:2131.0000,y:889.6667},
  {x:2131.0000,y:890.0000},
  {x:2131.9999,y:890.0000},
  {x:2133.0001,y:890.0000},
  {x:2134.0000,y:890.0000},
  {x:2134.0000,y:890.3333},
  {x:2134.0000,y:890.6667},
  {x:2134.0000,y:891.0000},
  {x:2135.9998,y:891.6666},
  {x:2138.0002,y:892.3334},
  {x:2140.0000,y:893.0000},
  {x:2140.3333,y:893.6666},
  {x:2140.6667,y:894.3334},
  {x:2141.0000,y:895.0000},
  {x:2142.6665,y:895.3333},
  {x:2144.3335,y:895.6667},
  {x:2146.0000,y:896.0000}
];
