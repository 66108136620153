import socialMedia from '@/config/socialMedia'

import Suscribe from '@/components/footer/suscribe'

import ButtonIcon from '@/components/form/buttonIcon'
import FacebookIcon from '@/components/icons/social/facebook'
import InstagramIcon from '@/components/icons/social/instagram'
import TwitterIcon from '@/components/icons/social/twitter'
import DiscordIcon from '@/components/icons/social/discord'

import WlcIcon from '@/components/icons/wlc'
import AmexIcon from '@/components/icons/pay/amex'
import BoletoIcon from '@/components/icons/pay/boleto'
import MastercardIcon from '@/components/icons/pay/mastercard'
import PaypalIcon from '@/components/icons/pay/paypal'
import VisaIcon from '@/components/icons/pay/visa'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Footer',

  components: {
    Suscribe,

    ButtonIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    DiscordIcon,

    WlcIcon,
    AmexIcon,
    BoletoIcon,
    MastercardIcon,
    PaypalIcon,
    VisaIcon
  },

  data() {
    return {
      instagram: socialMedia.instagram,
      facebook: socialMedia.facebook,
      twitter: socialMedia.twitter,
      discord: socialMedia.discord
    }
  },

  methods: {
    showFAQs: function() {
      EventBus.$emit('showFAQs');
    },

    showShippingInfo: function() {
      EventBus.$emit('showShippingInfo');
    },

    showRefoundPolicy: function() {
      EventBus.$emit('showRefoundPolicy');
    },

    showPrivatePolicy: function() {
      EventBus.$emit('showPrivatePolicy');
    },

    showHelp: function() {
      EventBus.$emit('showHelp');
    }
  }
}
