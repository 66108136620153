import axios from 'axios'
import api from '@/config/apiServer.js'

export default {
  data() {
    return {
      sides: [
        'inner',
        'outer'
      ],

      areas: [
        'vamp',
        'tip',
        'quarter',
        'foxing',
        'backtab',
        'eyestay',
        'swoosh',
        'top_set',
        'bottom_set'
      ]
    }
  },

  methods: {
    urlToFile: async function(url, fileName, mimeType) {
      let file = await fetch(url)
        .then(res => {
          return res.arrayBuffer()
        })
        .then(buf => {
          return new File([buf], fileName, {
            type: mimeType
          })
        });

      return file;
    },

    getCustomFull: async function(order, sneaker) {
      let full = {};

      for (let i = 0; i < this.sides.length; i++) {
        let key = this.sides[i];
        let side = sneaker[key].full;

        full[key] = {};

        full[key].color = side.color.visible
          ? side.color.value
          : '';

        full[key].images = [];

        if (side.images.length) {
          for (let j = 0; j < side.images.length; j++) {
            let index = full[key].images.length;
            let image = side.images[j];

            if (image !== null && image.file !== null && image.visible) {
              let imageFile = await this.urlToFile(
                image.src, image.file.name, image.file.type
              );

              order.append(key + '_image_' + index, imageFile);

              full[key].images.push({
                x: image.x,
                y: image.y,
                scaleX: image.scaleX,
                scaleY: image.scaleY,
                angle: image.angle
              });
            }
          }
        }

        full[key].text = [];

        if (side.text.length) {
          for (let j = 0; j < side.text.length; j++) {
            let text = side.text[j];

            if (text.value !== '' && text.font !== '' && text.visible) {
              full[key].text.push({
                x: text.x,
                y: text.y,
                scaleX: text.scaleX,
                scaleY: text.scaleY,
                angle: text.angle,
                value: text.value,
                font: text.font
              });
            }
          }
        }
      }

      order.append('custom', JSON.stringify(full));
    },

    getCustomAreas: async function(order, sneaker) {
      let areas = {};

      for (let i = 0; i < this.sides.length; i++) {
        let side = this.sides[i];

        areas[side] = {};

        for (let j = 0; j < this.areas.length; j++) {
          let key = this.areas[j];
          let area = sneaker[side].areas[key];

          areas[side][key] = {};

          areas[side][key].color = area.color.visible
            ? area.color.value
            : '';

          if (area.image !== null && area.image.file !== null && area.image.visible) {
            let imageFile = await this.urlToFile(
              area.image.src, area.image.file.name, area.image.file.type
            );

            order.append(side + '_' + key + '_image', imageFile);

            areas[side][key].image = {
              x: area.image.x,
              y: area.image.y,
              scaleX: area.image.scaleX,
              scaleY: area.image.scaleY,
              angle: area.image.angle
            };
          } else {
            areas[side][key].image = null;
          }

          areas[side][key].text = [];

          if (area.text.length) {
            for (let k = 0; k < area.text.length; k++) {
              let text = area.text[k];

              if (text.value !== '' && text.font !== '' && text.visible) {
                areas[side][key].text.push({
                  x: text.x,
                  y: text.y,
                  scaleX: text.scaleX,
                  scaleY: text.scaleY,
                  angle: text.angle,
                  value: text.value,
                  font: text.font
                });
              }
            }
          }
        }
      }

      order.append('custom', JSON.stringify(areas));
    },

    generateOrder: async function(items, method, tx) {
      let response;

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        let order = new FormData();

        order.append('payment_method', method);

        switch (method) {
          case 'paypal':
            order.append('paypal_id', tx);
            break;
          case 'ethereum':
            order.append('ethereum_tx', tx);
            break;
        }

        order.append('sneaker', item.sneaker);
        order.append('size', item.size);
        order.append('comments', item.comments);

        order.append('customer', JSON.stringify(item.customer));
        order.append('shipping', JSON.stringify(item.shipping));

        order.append('custom_application', item.custom.application);

        if (item.custom.application === 'full') {
          await this.getCustomFull(order, item.custom.sneaker);
        } else {
          await this.getCustomAreas(order, item.custom.sneaker);
        }

        await axios
          .post(api + '/order', order, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => response = res)
          .catch(err => console.log(err));
      }

      return (typeof response !== 'undefined' && response.status === 200);
    }
  }
}
