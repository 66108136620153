module.exports = [
  {x:4.0000,y:896.0000},
  {x:5.6665,y:895.6667},
  {x:7.3335,y:895.3333},
  {x:9.0000,y:895.0000},
  {x:9.3333,y:894.3334},
  {x:9.6667,y:893.6666},
  {x:10.0000,y:893.0000},
  {x:11.9998,y:892.3334},
  {x:14.0002,y:891.6666},
  {x:16.0000,y:891.0000},
  {x:16.0000,y:890.6667},
  {x:16.0000,y:890.3333},
  {x:16.0000,y:890.0000},
  {x:16.9999,y:890.0000},
  {x:18.0001,y:890.0000},
  {x:19.0000,y:890.0000},
  {x:19.0000,y:889.6667},
  {x:19.0000,y:889.3333},
  {x:19.0000,y:889.0000},
  {x:20.3332,y:889.0000},
  {x:21.6668,y:889.0000},
  {x:23.0000,y:889.0000},
  {x:23.0000,y:888.6667},
  {x:23.0000,y:888.3333},
  {x:23.0000,y:888.0000},
  {x:24.9998,y:888.0000},
  {x:27.0002,y:888.0000},
  {x:29.0000,y:888.0000},
  {x:29.0000,y:887.6667},
  {x:29.0000,y:887.3333},
  {x:29.0000,y:887.0000},
  {x:33.9995,y:887.0000},
  {x:39.0005,y:887.0000},
  {x:44.0000,y:887.0000},
  {x:44.0000,y:887.3333},
  {x:44.0000,y:887.6667},
  {x:44.0000,y:888.0000},
  {x:46.6664,y:888.0000},
  {x:49.3336,y:888.0000},
  {x:52.0000,y:888.0000},
  {x:52.0000,y:888.3333},
  {x:52.0000,y:888.6667},
  {x:52.0000,y:889.0000},
  {x:54.3331,y:889.0000},
  {x:56.6669,y:889.0000},
  {x:59.0000,y:889.0000},
  {x:59.0000,y:889.3333},
  {x:59.0000,y:889.6667},
  {x:59.0000,y:890.0000},
  {x:61.6664,y:890.0000},
  {x:64.3336,y:890.0000},
  {x:67.0000,y:890.0000},
  {x:67.0000,y:890.3333},
  {x:67.0000,y:890.6667},
  {x:67.0000,y:891.0000},
  {x:74.6659,y:891.3333},
  {x:82.3341,y:891.6667},
  {x:90.0000,y:892.0000},
  {x:90.0000,y:892.3333},
  {x:90.0000,y:892.6667},
  {x:90.0000,y:893.0000},
  {x:96.3327,y:893.3333},
  {x:102.6673,y:893.6667},
  {x:109.0000,y:894.0000},
  {x:112.3330,y:894.0000},
  {x:115.6670,y:894.0000},
  {x:119.0000,y:894.0000},
  {x:119.0000,y:894.3333},
  {x:119.0000,y:894.6667},
  {x:119.0000,y:895.0000},
  {x:124.4894,y:896.5418},
  {x:133.1374,y:894.6341},
  {x:138.0000,y:896.0000},
  {x:143.7755,y:897.6224},
  {x:152.8454,y:895.5518},
  {x:158.0000,y:897.0000},
  {x:168.6656,y:897.0000},
  {x:179.3344,y:897.0000},
  {x:190.0000,y:897.0000},
  {x:201.2691,y:896.9997},
  {x:216.5300,y:895.3370},
  {x:226.0000,y:898.0000},
  {x:231.9994,y:898.0000},
  {x:238.0006,y:898.0000},
  {x:244.0000,y:898.0000},
  {x:244.0000,y:898.3333},
  {x:244.0000,y:898.6667},
  {x:244.0000,y:899.0000},
  {x:247.3330,y:899.0000},
  {x:250.6670,y:899.0000},
  {x:254.0000,y:899.0000},
  {x:260.3327,y:899.3333},
  {x:266.6673,y:899.6667},
  {x:273.0000,y:900.0000},
  {x:273.0000,y:900.3333},
  {x:273.0000,y:900.6667},
  {x:273.0000,y:901.0000},
  {x:277.6662,y:901.0000},
  {x:282.3338,y:901.0000},
  {x:287.0000,y:901.0000},
  {x:287.0000,y:901.3333},
  {x:287.0000,y:901.6667},
  {x:287.0000,y:902.0000},
  {x:291.6662,y:902.0000},
  {x:296.3338,y:902.0000},
  {x:301.0000,y:902.0000},
  {x:301.0000,y:902.3333},
  {x:301.0000,y:902.6667},
  {x:301.0000,y:903.0000},
  {x:305.9995,y:903.0000},
  {x:311.0005,y:903.0000},
  {x:316.0000,y:903.0000},
  {x:316.0000,y:903.3333},
  {x:316.0000,y:903.6667},
  {x:316.0000,y:904.0000},
  {x:322.6660,y:904.3333},
  {x:329.3340,y:904.6667},
  {x:336.0000,y:905.0000},
  {x:339.3330,y:905.0000},
  {x:342.6670,y:905.0000},
  {x:346.0000,y:905.0000},
  {x:346.0000,y:905.3333},
  {x:346.0000,y:905.6667},
  {x:346.0000,y:906.0000},
  {x:350.3329,y:906.0000},
  {x:354.6671,y:906.0000},
  {x:359.0000,y:906.0000},
  {x:359.0000,y:906.3333},
  {x:359.0000,y:906.6667},
  {x:359.0000,y:907.0000},
  {x:367.9991,y:907.3333},
  {x:377.0009,y:907.6667},
  {x:386.0000,y:908.0000},
  {x:386.0000,y:908.3333},
  {x:386.0000,y:908.6667},
  {x:386.0000,y:909.0000},
  {x:389.9996,y:909.0000},
  {x:394.0004,y:909.0000},
  {x:398.0000,y:909.0000},
  {x:398.0687,y:909.8341},
  {x:400.0000,y:910.0000},
  {x:403.6663,y:910.0000},
  {x:407.3337,y:910.0000},
  {x:411.0000,y:910.0000},
  {x:411.0000,y:910.3333},
  {x:411.0000,y:910.6667},
  {x:411.0000,y:911.0000},
  {x:415.3329,y:911.0000},
  {x:419.6671,y:911.0000},
  {x:424.0000,y:911.0000},
  {x:424.0000,y:911.3333},
  {x:424.0000,y:911.6667},
  {x:424.0000,y:912.0000},
  {x:428.3329,y:912.0000},
  {x:432.6671,y:912.0000},
  {x:437.0000,y:912.0000},
  {x:437.0000,y:912.3333},
  {x:437.0000,y:912.6667},
  {x:437.0000,y:913.0000},
  {x:441.6662,y:913.0000},
  {x:446.3338,y:913.0000},
  {x:451.0000,y:913.0000},
  {x:451.0000,y:913.3333},
  {x:451.0000,y:913.6667},
  {x:451.0000,y:914.0000},
  {x:454.6663,y:914.0000},
  {x:458.3337,y:914.0000},
  {x:462.0000,y:914.0000},
  {x:463.9132,y:914.1668},
  {x:464.0000,y:915.0000},
  {x:468.3329,y:915.0000},
  {x:472.6671,y:915.0000},
  {x:477.0000,y:915.0000},
  {x:477.0000,y:915.3333},
  {x:477.0000,y:915.6667},
  {x:477.0000,y:916.0000},
  {x:480.3330,y:916.0000},
  {x:483.6670,y:916.0000},
  {x:487.0000,y:916.0000},
  {x:488.3332,y:916.0000},
  {x:489.6668,y:916.0000},
  {x:491.0000,y:916.0000},
  {x:491.0000,y:916.3333},
  {x:491.0000,y:916.6667},
  {x:491.0000,y:917.0000},
  {x:495.9995,y:917.0000},
  {x:501.0005,y:917.0000},
  {x:506.0000,y:917.0000},
  {x:506.0000,y:917.3333},
  {x:506.0000,y:917.6667},
  {x:506.0000,y:918.0000},
  {x:539.9966,y:919.6665},
  {x:574.0034,y:921.3335},
  {x:608.0000,y:923.0000},
  {x:608.0000,y:923.3333},
  {x:608.0000,y:923.6667},
  {x:608.0000,y:924.0000},
  {x:613.3328,y:924.0000},
  {x:618.6672,y:924.0000},
  {x:624.0000,y:924.0000},
  {x:624.0000,y:924.3333},
  {x:624.0000,y:924.6667},
  {x:624.0000,y:925.0000},
  {x:630.9993,y:925.3333},
  {x:638.0007,y:925.6667},
  {x:645.0000,y:926.0000},
  {x:648.3330,y:926.0000},
  {x:651.6670,y:926.0000},
  {x:655.0000,y:926.0000},
  {x:655.0000,y:926.3333},
  {x:655.0000,y:926.6667},
  {x:655.0000,y:927.0000},
  {x:659.9995,y:927.0000},
  {x:665.0005,y:927.0000},
  {x:670.0000,y:927.0000},
  {x:670.0000,y:927.3333},
  {x:670.0000,y:927.6667},
  {x:670.0000,y:928.0000},
  {x:675.3328,y:928.0000},
  {x:680.6672,y:928.0000},
  {x:686.0000,y:928.0000},
  {x:686.0000,y:928.3333},
  {x:686.0000,y:928.6667},
  {x:686.0000,y:929.0000},
  {x:692.6660,y:929.3333},
  {x:699.3340,y:929.6667},
  {x:706.0000,y:930.0000},
  {x:709.3330,y:930.0000},
  {x:712.6670,y:930.0000},
  {x:716.0000,y:930.0000},
  {x:716.0000,y:930.3333},
  {x:716.0000,y:930.6667},
  {x:716.0000,y:931.0000},
  {x:720.9995,y:931.0000},
  {x:726.0005,y:931.0000},
  {x:731.0000,y:931.0000},
  {x:731.0000,y:931.3333},
  {x:731.0000,y:931.6667},
  {x:731.0000,y:932.0000},
  {x:735.9995,y:932.0000},
  {x:741.0005,y:932.0000},
  {x:746.0000,y:932.0000},
  {x:746.0000,y:932.3333},
  {x:746.0000,y:932.6667},
  {x:746.0000,y:933.0000},
  {x:752.3327,y:933.3333},
  {x:758.6673,y:933.6667},
  {x:765.0000,y:934.0000},
  {x:768.3330,y:934.0000},
  {x:771.6670,y:934.0000},
  {x:775.0000,y:934.0000},
  {x:775.0000,y:934.3333},
  {x:775.0000,y:934.6667},
  {x:775.0000,y:935.0000},
  {x:779.3329,y:935.0000},
  {x:783.6671,y:935.0000},
  {x:788.0000,y:935.0000},
  {x:788.0000,y:935.3333},
  {x:788.0000,y:935.6667},
  {x:788.0000,y:936.0000},
  {x:792.6662,y:936.0000},
  {x:797.3338,y:936.0000},
  {x:802.0000,y:936.0000},
  {x:802.0000,y:936.3333},
  {x:802.0000,y:936.6667},
  {x:802.0000,y:937.0000},
  {x:806.6662,y:937.0000},
  {x:811.3338,y:937.0000},
  {x:816.0000,y:937.0000},
  {x:816.0000,y:937.3333},
  {x:816.0000,y:937.6667},
  {x:816.0000,y:938.0000},
  {x:818.9997,y:938.0000},
  {x:822.0003,y:938.0000},
  {x:825.0000,y:938.0000},
  {x:833.6139,y:940.5339},
  {x:844.2474,y:942.3709},
  {x:853.0000,y:945.0000},
  {x:856.9996,y:945.3333},
  {x:861.0004,y:945.6667},
  {x:865.0000,y:946.0000},
  {x:865.0000,y:946.3333},
  {x:865.0000,y:946.6667},
  {x:865.0000,y:947.0000},
  {x:867.3331,y:947.0000},
  {x:869.6669,y:947.0000},
  {x:872.0000,y:947.0000},
  {x:872.0000,y:947.3333},
  {x:872.0000,y:947.6667},
  {x:872.0000,y:948.0000},
  {x:876.9995,y:948.3333},
  {x:882.0005,y:948.6667},
  {x:887.0000,y:949.0000},
  {x:887.0000,y:949.3333},
  {x:887.0000,y:949.6667},
  {x:887.0000,y:950.0000},
  {x:899.3321,y:950.0000},
  {x:911.6679,y:950.0000},
  {x:924.0000,y:950.0000},
  {x:938.6687,y:954.1845},
  {x:959.3343,y:952.9956},
  {x:978.0000,y:953.0000},
  {x:986.9991,y:953.3333},
  {x:996.0009,y:953.6667},
  {x:1005.0000,y:954.0000},
  {x:1015.3323,y:954.0000},
  {x:1025.6677,y:954.0000},
  {x:1036.0000,y:954.0000},
  {x:1042.1636,y:955.7451},
  {x:1052.7082,y:953.2175},
  {x:1059.0000,y:955.0000},
  {x:1064.4427,y:956.5420},
  {x:1073.9524,y:954.2856},
  {x:1080.0000,y:956.0000},
  {x:1080.0000,y:956.3333},
  {x:1080.0000,y:956.6667},
  {x:1080.0000,y:957.0000},
  {x:1086.6660,y:957.0000},
  {x:1093.3340,y:957.0000},
  {x:1100.0000,y:957.0000},
  {x:1100.0000,y:957.3333},
  {x:1100.0000,y:957.6667},
  {x:1100.0000,y:958.0000},
  {x:1101.6665,y:958.0000},
  {x:1103.3335,y:958.0000},
  {x:1105.0000,y:958.0000},
  {x:1108.6663,y:958.0000},
  {x:1112.3337,y:958.0000},
  {x:1116.0000,y:958.0000},
  {x:1116.0000,y:958.3333},
  {x:1116.0000,y:958.6667},
  {x:1116.0000,y:959.0000},
  {x:1120.6662,y:959.0000},
  {x:1125.3338,y:959.0000},
  {x:1130.0000,y:959.0000},
  {x:1130.0000,y:959.3333},
  {x:1130.0000,y:959.6667},
  {x:1130.0000,y:960.0000},
  {x:1133.9996,y:960.0000},
  {x:1138.0004,y:960.0000},
  {x:1142.0000,y:960.0000},
  {x:1142.0000,y:960.3333},
  {x:1142.0000,y:960.6667},
  {x:1142.0000,y:961.0000},
  {x:1145.6663,y:961.0000},
  {x:1149.3337,y:961.0000},
  {x:1153.0000,y:961.0000},
  {x:1153.0000,y:961.3333},
  {x:1153.0000,y:961.6667},
  {x:1153.0000,y:962.0000},
  {x:1156.3330,y:962.0000},
  {x:1159.6670,y:962.0000},
  {x:1163.0000,y:962.0000},
  {x:1163.0000,y:962.3333},
  {x:1163.0000,y:962.6667},
  {x:1163.0000,y:963.0000},
  {x:1166.3330,y:963.0000},
  {x:1169.6670,y:963.0000},
  {x:1173.0000,y:963.0000},
  {x:1173.0000,y:963.3333},
  {x:1173.0000,y:963.6667},
  {x:1173.0000,y:964.0000},
  {x:1175.9997,y:964.0000},
  {x:1179.0003,y:964.0000},
  {x:1182.0000,y:964.0000},
  {x:1193.5360,y:967.3023},
  {x:1209.3869,y:965.7079},
  {x:1221.0000,y:969.0000},
  {x:1224.3330,y:969.0000},
  {x:1227.6670,y:969.0000},
  {x:1231.0000,y:969.0000},
  {x:1231.0000,y:969.3333},
  {x:1231.0000,y:969.6667},
  {x:1231.0000,y:970.0000},
  {x:1234.6663,y:970.0000},
  {x:1238.3337,y:970.0000},
  {x:1242.0000,y:970.0000},
  {x:1242.0000,y:970.3333},
  {x:1242.0000,y:970.6667},
  {x:1242.0000,y:971.0000},
  {x:1245.3330,y:971.0000},
  {x:1248.6670,y:971.0000},
  {x:1252.0000,y:971.0000},
  {x:1252.0000,y:971.3333},
  {x:1252.0000,y:971.6667},
  {x:1252.0000,y:972.0000},
  {x:1255.6663,y:972.0000},
  {x:1259.3337,y:972.0000},
  {x:1263.0000,y:972.0000},
  {x:1263.0000,y:972.3333},
  {x:1263.0000,y:972.6667},
  {x:1263.0000,y:973.0000},
  {x:1266.6663,y:973.0000},
  {x:1270.3337,y:973.0000},
  {x:1274.0000,y:973.0000},
  {x:1274.0000,y:973.3333},
  {x:1274.0000,y:973.6667},
  {x:1274.0000,y:974.0000},
  {x:1277.6663,y:974.0000},
  {x:1281.3337,y:974.0000},
  {x:1285.0000,y:974.0000},
  {x:1285.0000,y:974.3333},
  {x:1285.0000,y:974.6667},
  {x:1285.0000,y:975.0000},
  {x:1288.3330,y:975.0000},
  {x:1291.6670,y:975.0000},
  {x:1295.0000,y:975.0000},
  {x:1295.0000,y:975.3333},
  {x:1295.0000,y:975.6667},
  {x:1295.0000,y:976.0000},
  {x:1298.6663,y:976.0000},
  {x:1302.3337,y:976.0000},
  {x:1306.0000,y:976.0000},
  {x:1306.0000,y:976.3333},
  {x:1306.0000,y:976.6667},
  {x:1306.0000,y:977.0000},
  {x:1309.6663,y:977.0000},
  {x:1313.3337,y:977.0000},
  {x:1317.0000,y:977.0000},
  {x:1317.0000,y:977.3333},
  {x:1317.0000,y:977.6667},
  {x:1317.0000,y:978.0000},
  {x:1320.6663,y:978.0000},
  {x:1324.3337,y:978.0000},
  {x:1328.0000,y:978.0000},
  {x:1328.0000,y:978.3333},
  {x:1328.0000,y:978.6667},
  {x:1328.0000,y:979.0000},
  {x:1330.9997,y:979.0000},
  {x:1334.0003,y:979.0000},
  {x:1337.0000,y:979.0000},
  {x:1337.0000,y:979.3333},
  {x:1337.0000,y:979.6667},
  {x:1337.0000,y:980.0000},
  {x:1339.6664,y:980.0000},
  {x:1342.3336,y:980.0000},
  {x:1345.0000,y:980.0000},
  {x:1356.2203,y:983.2433},
  {x:1370.8139,y:982.7789},
  {x:1382.0000,y:986.0000},
  {x:1384.6664,y:986.0000},
  {x:1387.3336,y:986.0000},
  {x:1390.0000,y:986.0000},
  {x:1390.0000,y:986.3333},
  {x:1390.0000,y:986.6667},
  {x:1390.0000,y:987.0000},
  {x:1392.6664,y:987.0000},
  {x:1395.3336,y:987.0000},
  {x:1398.0000,y:987.0000},
  {x:1398.0000,y:987.3333},
  {x:1398.0000,y:987.6667},
  {x:1398.0000,y:988.0000},
  {x:1400.6664,y:988.0000},
  {x:1403.3336,y:988.0000},
  {x:1406.0000,y:988.0000},
  {x:1406.0000,y:988.3333},
  {x:1406.0000,y:988.6667},
  {x:1406.0000,y:989.0000},
  {x:1408.9997,y:989.0000},
  {x:1412.0003,y:989.0000},
  {x:1415.0000,y:989.0000},
  {x:1415.0000,y:989.3333},
  {x:1415.0000,y:989.6667},
  {x:1415.0000,y:990.0000},
  {x:1419.3329,y:990.0000},
  {x:1423.6671,y:990.0000},
  {x:1428.0000,y:990.0000},
  {x:1428.0000,y:990.3333},
  {x:1428.0000,y:990.6667},
  {x:1428.0000,y:991.0000},
  {x:1431.6663,y:991.0000},
  {x:1435.3337,y:991.0000},
  {x:1439.0000,y:991.0000},
  {x:1439.0000,y:991.3333},
  {x:1439.0000,y:991.6667},
  {x:1439.0000,y:992.0000},
  {x:1441.9997,y:992.0000},
  {x:1445.0003,y:992.0000},
  {x:1448.0000,y:992.0000},
  {x:1448.0000,y:992.3333},
  {x:1448.0000,y:992.6667},
  {x:1448.0000,y:993.0000},
  {x:1450.9997,y:993.0000},
  {x:1454.0003,y:993.0000},
  {x:1457.0000,y:993.0000},
  {x:1457.0000,y:993.3333},
  {x:1457.0000,y:993.6667},
  {x:1457.0000,y:994.0000},
  {x:1459.6664,y:994.0000},
  {x:1462.3336,y:994.0000},
  {x:1465.0000,y:994.0000},
  {x:1465.0000,y:994.3333},
  {x:1465.0000,y:994.6667},
  {x:1465.0000,y:995.0000},
  {x:1467.6664,y:995.0000},
  {x:1470.3336,y:995.0000},
  {x:1473.0000,y:995.0000},
  {x:1473.0000,y:995.3333},
  {x:1473.0000,y:995.6667},
  {x:1473.0000,y:996.0000},
  {x:1475.6664,y:996.0000},
  {x:1478.3336,y:996.0000},
  {x:1481.0000,y:996.0000},
  {x:1481.0000,y:996.3333},
  {x:1481.0000,y:996.6667},
  {x:1481.0000,y:997.0000},
  {x:1483.3331,y:997.0000},
  {x:1485.6669,y:997.0000},
  {x:1488.0000,y:997.0000},
  {x:1488.0000,y:997.3333},
  {x:1488.0000,y:997.6667},
  {x:1488.0000,y:998.0000},
  {x:1490.6664,y:998.0000},
  {x:1493.3336,y:998.0000},
  {x:1496.0000,y:998.0000},
  {x:1496.0000,y:998.3333},
  {x:1496.0000,y:998.6667},
  {x:1496.0000,y:999.0000},
  {x:1498.3331,y:999.0000},
  {x:1500.6669,y:999.0000},
  {x:1503.0000,y:999.0000},
  {x:1503.0000,y:999.3333},
  {x:1503.0000,y:999.6667},
  {x:1503.0000,y:1000.0000},
  {x:1505.6664,y:1000.0000},
  {x:1508.3336,y:1000.0000},
  {x:1511.0000,y:1000.0000},
  {x:1524.0334,y:1003.7920},
  {x:1540.9884,y:1003.2164},
  {x:1554.0000,y:1007.0000},
  {x:1558.6662,y:1007.3333},
  {x:1563.3338,y:1007.6667},
  {x:1568.0000,y:1008.0000},
  {x:1568.0000,y:1008.3333},
  {x:1568.0000,y:1008.6667},
  {x:1568.0000,y:1009.0000},
  {x:1579.6655,y:1010.3332},
  {x:1591.3345,y:1011.6668},
  {x:1603.0000,y:1013.0000},
  {x:1603.0000,y:1013.3333},
  {x:1603.0000,y:1013.6667},
  {x:1603.0000,y:1014.0000},
  {x:1604.9998,y:1014.0000},
  {x:1607.0002,y:1014.0000},
  {x:1609.0000,y:1014.0000},
  {x:1609.0000,y:1014.3333},
  {x:1609.0000,y:1014.6667},
  {x:1609.0000,y:1015.0000},
  {x:1611.3331,y:1015.0000},
  {x:1613.6669,y:1015.0000},
  {x:1616.0000,y:1015.0000},
  {x:1616.0000,y:1015.3333},
  {x:1616.0000,y:1015.6667},
  {x:1616.0000,y:1016.0000},
  {x:1617.9998,y:1016.0000},
  {x:1620.0002,y:1016.0000},
  {x:1622.0000,y:1016.0000},
  {x:1629.2552,y:1018.2067},
  {x:1638.5587,y:1019.7548},
  {x:1646.0000,y:1022.0000},
  {x:1649.6663,y:1022.3333},
  {x:1653.3337,y:1022.6667},
  {x:1657.0000,y:1023.0000},
  {x:1681.5271,y:1031.3084},
  {x:1706.4775,y:1037.5418},
  {x:1739.0000,y:1038.0000},
  {x:1739.3333,y:1037.3334},
  {x:1739.6667,y:1036.6666},
  {x:1740.0000,y:1036.0000},
  {x:1740.3333,y:1036.0000},
  {x:1740.6667,y:1036.0000},
  {x:1741.0000,y:1036.0000},
  {x:1739.4646,y:1028.0871},
  {x:1740.0000,y:1027.0000},
  {x:1740.6666,y:1026.6667},
  {x:1741.3334,y:1026.3333},
  {x:1742.0000,y:1026.0000},
  {x:1742.2139,y:1025.7527},
  {x:1744.0000,y:1024.0000},
  {x:1744.0000,y:1023.3334},
  {x:1744.0000,y:1022.6666},
  {x:1744.0000,y:1022.0000},
  {x:1744.9999,y:1021.3334},
  {x:1746.0001,y:1020.6666},
  {x:1747.0000,y:1020.0000},
  {x:1747.6666,y:1018.0002},
  {x:1748.3334,y:1015.9998},
  {x:1749.0000,y:1014.0000},
  {x:1749.3333,y:1014.0000},
  {x:1749.6667,y:1014.0000},
  {x:1750.0000,y:1014.0000},
  {x:1750.0000,y:1013.3334},
  {x:1750.0000,y:1012.6666},
  {x:1750.0000,y:1012.0000},
  {x:1750.3333,y:1012.0000},
  {x:1750.6667,y:1012.0000},
  {x:1751.0000,y:1012.0000},
  {x:1751.0000,y:1011.3334},
  {x:1751.0000,y:1010.6666},
  {x:1751.0000,y:1010.0000},
  {x:1751.3333,y:1010.0000},
  {x:1751.6667,y:1010.0000},
  {x:1752.0000,y:1010.0000},
  {x:1752.6666,y:1008.0002},
  {x:1753.3334,y:1005.9998},
  {x:1754.0000,y:1004.0000},
  {x:1754.3333,y:1004.0000},
  {x:1754.6667,y:1004.0000},
  {x:1755.0000,y:1004.0000},
  {x:1755.0000,y:1003.0001},
  {x:1755.0000,y:1001.9999},
  {x:1755.0000,y:1001.0000},
  {x:1755.3333,y:1001.0000},
  {x:1755.6667,y:1001.0000},
  {x:1756.0000,y:1001.0000},
  {x:1756.0000,y:1000.3334},
  {x:1756.0000,y:999.6666},
  {x:1756.0000,y:999.0000},
  {x:1756.3333,y:999.0000},
  {x:1756.6667,y:999.0000},
  {x:1757.0000,y:999.0000},
  {x:1757.0000,y:998.3334},
  {x:1757.0000,y:997.6666},
  {x:1757.0000,y:997.0000},
  {x:1757.3333,y:997.0000},
  {x:1757.6667,y:997.0000},
  {x:1758.0000,y:997.0000},
  {x:1758.0000,y:996.3334},
  {x:1758.0000,y:995.6666},
  {x:1758.0000,y:995.0000},
  {x:1758.3333,y:995.0000},
  {x:1758.6667,y:995.0000},
  {x:1759.0000,y:995.0000},
  {x:1759.6666,y:993.0002},
  {x:1760.3334,y:990.9998},
  {x:1761.0000,y:989.0000},
  {x:1761.3333,y:989.0000},
  {x:1761.6667,y:989.0000},
  {x:1762.0000,y:989.0000},
  {x:1762.0000,y:988.0001},
  {x:1762.0000,y:986.9999},
  {x:1762.0000,y:986.0000},
  {x:1762.3333,y:986.0000},
  {x:1762.6667,y:986.0000},
  {x:1763.0000,y:986.0000},
  {x:1763.0000,y:985.3334},
  {x:1763.0000,y:984.6666},
  {x:1763.0000,y:984.0000},
  {x:1763.3333,y:984.0000},
  {x:1763.6667,y:984.0000},
  {x:1764.0000,y:984.0000},
  {x:1764.3333,y:982.6668},
  {x:1764.6667,y:981.3332},
  {x:1765.0000,y:980.0000},
  {x:1765.3333,y:980.0000},
  {x:1765.6667,y:980.0000},
  {x:1766.0000,y:980.0000},
  {x:1766.0000,y:979.3334},
  {x:1766.0000,y:978.6666},
  {x:1766.0000,y:978.0000},
  {x:1766.3333,y:978.0000},
  {x:1766.6667,y:978.0000},
  {x:1767.0000,y:978.0000},
  {x:1767.0000,y:977.3334},
  {x:1767.0000,y:976.6666},
  {x:1767.0000,y:976.0000},
  {x:1767.3333,y:976.0000},
  {x:1767.6667,y:976.0000},
  {x:1768.0000,y:976.0000},
  {x:1768.0000,y:975.3334},
  {x:1768.0000,y:974.6666},
  {x:1768.0000,y:974.0000},
  {x:1768.3333,y:974.0000},
  {x:1768.6667,y:974.0000},
  {x:1769.0000,y:974.0000},
  {x:1769.0000,y:973.3334},
  {x:1769.0000,y:972.6666},
  {x:1769.0000,y:972.0000},
  {x:1769.3333,y:972.0000},
  {x:1769.6667,y:972.0000},
  {x:1770.0000,y:972.0000},
  {x:1770.0000,y:971.3334},
  {x:1770.0000,y:970.6666},
  {x:1770.0000,y:970.0000},
  {x:1770.3333,y:970.0000},
  {x:1770.6667,y:970.0000},
  {x:1771.0000,y:970.0000},
  {x:1771.0000,y:969.3334},
  {x:1771.0000,y:968.6666},
  {x:1771.0000,y:968.0000},
  {x:1771.3333,y:968.0000},
  {x:1771.6667,y:968.0000},
  {x:1772.0000,y:968.0000},
  {x:1772.0000,y:967.3334},
  {x:1772.0000,y:966.6666},
  {x:1772.0000,y:966.0000},
  {x:1772.3333,y:966.0000},
  {x:1772.6667,y:966.0000},
  {x:1773.0000,y:966.0000},
  {x:1773.0000,y:965.3334},
  {x:1773.0000,y:964.6666},
  {x:1773.0000,y:964.0000},
  {x:1773.3333,y:964.0000},
  {x:1773.6667,y:964.0000},
  {x:1774.0000,y:964.0000},
  {x:1774.0000,y:963.3334},
  {x:1774.0000,y:962.6666},
  {x:1774.0000,y:962.0000},
  {x:1774.3333,y:962.0000},
  {x:1774.6667,y:962.0000},
  {x:1775.0000,y:962.0000},
  {x:1775.0000,y:961.3334},
  {x:1775.0000,y:960.6666},
  {x:1775.0000,y:960.0000},
  {x:1775.6666,y:959.6667},
  {x:1776.3334,y:959.3333},
  {x:1777.0000,y:959.0000},
  {x:1777.3333,y:957.6668},
  {x:1777.6667,y:956.3332},
  {x:1778.0000,y:955.0000},
  {x:1778.3333,y:955.0000},
  {x:1778.6667,y:955.0000},
  {x:1779.0000,y:955.0000},
  {x:1779.0000,y:954.3334},
  {x:1779.0000,y:953.6666},
  {x:1779.0000,y:953.0000},
  {x:1779.3333,y:953.0000},
  {x:1779.6667,y:953.0000},
  {x:1780.0000,y:953.0000},
  {x:1780.0000,y:952.3334},
  {x:1780.0000,y:951.6666},
  {x:1780.0000,y:951.0000},
  {x:1780.3333,y:951.0000},
  {x:1780.6667,y:951.0000},
  {x:1781.0000,y:951.0000},
  {x:1781.0000,y:950.3334},
  {x:1781.0000,y:949.6666},
  {x:1781.0000,y:949.0000},
  {x:1781.3333,y:949.0000},
  {x:1781.6667,y:949.0000},
  {x:1782.0000,y:949.0000},
  {x:1782.0000,y:948.3334},
  {x:1782.0000,y:947.6666},
  {x:1782.0000,y:947.0000},
  {x:1782.6666,y:946.6667},
  {x:1783.3334,y:946.3333},
  {x:1784.0000,y:946.0000},
  {x:1784.0000,y:945.3334},
  {x:1784.0000,y:944.6666},
  {x:1784.0000,y:944.0000},
  {x:1784.3333,y:944.0000},
  {x:1784.6667,y:944.0000},
  {x:1785.0000,y:944.0000},
  {x:1785.0000,y:943.3334},
  {x:1785.0000,y:942.6666},
  {x:1785.0000,y:942.0000},
  {x:1785.3333,y:942.0000},
  {x:1785.6667,y:942.0000},
  {x:1786.0000,y:942.0000},
  {x:1786.0000,y:941.3334},
  {x:1786.0000,y:940.6666},
  {x:1786.0000,y:940.0000},
  {x:1786.3333,y:940.0000},
  {x:1786.6667,y:940.0000},
  {x:1787.0000,y:940.0000},
  {x:1787.0000,y:939.3334},
  {x:1787.0000,y:938.6666},
  {x:1787.0000,y:938.0000},
  {x:1787.6666,y:937.6667},
  {x:1788.3334,y:937.3333},
  {x:1789.0000,y:937.0000},
  {x:1789.0000,y:936.3334},
  {x:1789.0000,y:935.6666},
  {x:1789.0000,y:935.0000},
  {x:1789.3333,y:935.0000},
  {x:1789.6667,y:935.0000},
  {x:1790.0000,y:935.0000},
  {x:1790.0000,y:934.3334},
  {x:1790.0000,y:933.6666},
  {x:1790.0000,y:933.0000},
  {x:1790.3333,y:933.0000},
  {x:1790.6667,y:933.0000},
  {x:1791.0000,y:933.0000},
  {x:1791.0000,y:932.3334},
  {x:1791.0000,y:931.6666},
  {x:1791.0000,y:931.0000},
  {x:1791.6666,y:930.6667},
  {x:1792.3334,y:930.3333},
  {x:1793.0000,y:930.0000},
  {x:1793.6666,y:928.0002},
  {x:1794.3334,y:925.9998},
  {x:1795.0000,y:924.0000},
  {x:1795.6666,y:923.6667},
  {x:1796.3334,y:923.3333},
  {x:1797.0000,y:923.0000},
  {x:1797.3333,y:921.6668},
  {x:1797.6667,y:920.3332},
  {x:1798.0000,y:919.0000},
  {x:1798.6666,y:918.6667},
  {x:1799.3334,y:918.3333},
  {x:1800.0000,y:918.0000},
  {x:1800.0000,y:917.3334},
  {x:1800.0000,y:916.6666},
  {x:1800.0000,y:916.0000},
  {x:1800.6666,y:915.6667},
  {x:1801.3334,y:915.3333},
  {x:1802.0000,y:915.0000},
  {x:1802.3333,y:913.6668},
  {x:1802.6667,y:912.3332},
  {x:1803.0000,y:911.0000},
  {x:1803.3333,y:911.0000},
  {x:1803.6667,y:911.0000},
  {x:1804.0000,y:911.0000},
  {x:1804.3333,y:910.0001},
  {x:1804.6667,y:908.9999},
  {x:1805.0000,y:908.0000},
  {x:1805.6666,y:907.6667},
  {x:1806.3334,y:907.3333},
  {x:1807.0000,y:907.0000},
  {x:1807.3333,y:905.6668},
  {x:1807.6667,y:904.3332},
  {x:1808.0000,y:903.0000},
  {x:1808.6666,y:902.6667},
  {x:1809.3334,y:902.3333},
  {x:1810.0000,y:902.0000},
  {x:1810.0000,y:901.3334},
  {x:1810.0000,y:900.6666},
  {x:1810.0000,y:900.0000},
  {x:1810.6666,y:899.6667},
  {x:1811.3334,y:899.3333},
  {x:1812.0000,y:899.0000},
  {x:1812.0000,y:898.3334},
  {x:1812.0000,y:897.6666},
  {x:1812.0000,y:897.0000},
  {x:1812.6666,y:896.6667},
  {x:1813.3334,y:896.3333},
  {x:1814.0000,y:896.0000},
  {x:1814.0000,y:895.3334},
  {x:1814.0000,y:894.6666},
  {x:1814.0000,y:894.0000},
  {x:1814.6666,y:893.6667},
  {x:1815.3334,y:893.3333},
  {x:1816.0000,y:893.0000},
  {x:1816.0000,y:892.3334},
  {x:1816.0000,y:891.6666},
  {x:1816.0000,y:891.0000},
  {x:1816.3333,y:891.0000},
  {x:1816.6667,y:891.0000},
  {x:1817.0000,y:891.0000},
  {x:1817.3333,y:890.0001},
  {x:1817.6667,y:888.9999},
  {x:1818.0000,y:888.0000},
  {x:1818.6666,y:887.6667},
  {x:1819.3334,y:887.3333},
  {x:1820.0000,y:887.0000},
  {x:1820.0000,y:886.3334},
  {x:1820.0000,y:885.6666},
  {x:1820.0000,y:885.0000},
  {x:1820.3333,y:885.0000},
  {x:1820.6667,y:885.0000},
  {x:1821.0000,y:885.0000},
  {x:1821.3333,y:884.0001},
  {x:1821.6667,y:882.9999},
  {x:1822.0000,y:882.0000},
  {x:1822.6666,y:881.6667},
  {x:1823.3334,y:881.3333},
  {x:1824.0000,y:881.0000},
  {x:1824.0000,y:880.3334},
  {x:1824.0000,y:879.6666},
  {x:1824.0000,y:879.0000},
  {x:1824.9999,y:878.3334},
  {x:1826.0001,y:877.6666},
  {x:1827.0000,y:877.0000},
  {x:1827.0000,y:876.3334},
  {x:1827.0000,y:875.6666},
  {x:1827.0000,y:875.0000},
  {x:1827.6666,y:874.6667},
  {x:1828.3334,y:874.3333},
  {x:1829.0000,y:874.0000},
  {x:1829.0000,y:873.3334},
  {x:1829.0000,y:872.6666},
  {x:1829.0000,y:872.0000},
  {x:1829.6666,y:871.6667},
  {x:1830.3334,y:871.3333},
  {x:1831.0000,y:871.0000},
  {x:1831.3333,y:870.0001},
  {x:1831.6667,y:868.9999},
  {x:1832.0000,y:868.0000},
  {x:1832.9999,y:867.3334},
  {x:1834.0001,y:866.6666},
  {x:1835.0000,y:866.0000},
  {x:1835.0000,y:865.3334},
  {x:1835.0000,y:864.6666},
  {x:1835.0000,y:864.0000},
  {x:1835.6666,y:863.6667},
  {x:1836.3334,y:863.3333},
  {x:1837.0000,y:863.0000},
  {x:1837.0000,y:862.3334},
  {x:1837.0000,y:861.6666},
  {x:1837.0000,y:861.0000},
  {x:1837.9999,y:860.3334},
  {x:1839.0001,y:859.6666},
  {x:1840.0000,y:859.0000},
  {x:1840.0000,y:858.3334},
  {x:1840.0000,y:857.6666},
  {x:1840.0000,y:857.0000},
  {x:1841.3332,y:856.0001},
  {x:1842.6668,y:854.9999},
  {x:1844.0000,y:854.0000},
  {x:1844.3333,y:853.0001},
  {x:1844.6667,y:851.9999},
  {x:1845.0000,y:851.0000},
  {x:1845.6666,y:850.6667},
  {x:1846.3334,y:850.3333},
  {x:1847.0000,y:850.0000},
  {x:1847.0000,y:849.3334},
  {x:1847.0000,y:848.6666},
  {x:1847.0000,y:848.0000},
  {x:1848.3332,y:847.0001},
  {x:1849.6668,y:845.9999},
  {x:1851.0000,y:845.0000},
  {x:1851.0000,y:844.3334},
  {x:1851.0000,y:843.6666},
  {x:1851.0000,y:843.0000},
  {x:1852.3332,y:842.0001},
  {x:1853.6668,y:840.9999},
  {x:1855.0000,y:840.0000},
  {x:1855.0000,y:839.3334},
  {x:1855.0000,y:838.6666},
  {x:1855.0000,y:838.0000},
  {x:1856.6665,y:836.6668},
  {x:1858.3335,y:835.3332},
  {x:1860.0000,y:834.0000},
  {x:1860.0000,y:833.3334},
  {x:1860.0000,y:832.6666},
  {x:1860.0000,y:832.0000},
  {x:1861.9998,y:830.3335},
  {x:1864.0002,y:828.6665},
  {x:1866.0000,y:827.0000},
  {x:1866.0000,y:826.3334},
  {x:1866.0000,y:825.6666},
  {x:1866.0000,y:825.0000},
  {x:1867.9998,y:823.3335},
  {x:1870.0002,y:821.6665},
  {x:1872.0000,y:820.0000},
  {x:1872.0000,y:819.3334},
  {x:1872.0000,y:818.6666},
  {x:1872.0000,y:818.0000},
  {x:1875.3330,y:815.0003},
  {x:1878.6670,y:811.9997},
  {x:1882.0000,y:809.0000},
  {x:1882.0000,y:808.3334},
  {x:1882.0000,y:807.6666},
  {x:1882.0000,y:807.0000},
  {x:1885.6663,y:803.6670},
  {x:1889.3337,y:800.3330},
  {x:1893.0000,y:797.0000},
  {x:1900.2959,y:789.7064},
  {x:1906.4838,y:782.1589},
  {x:1915.0000,y:776.0000},
  {x:1917.6664,y:773.0003},
  {x:1920.3336,y:769.9997},
  {x:1923.0000,y:767.0000},
  {x:1923.6666,y:767.0000},
  {x:1924.3334,y:767.0000},
  {x:1925.0000,y:767.0000},
  {x:1926.9998,y:764.6669},
  {x:1929.0002,y:762.3331},
  {x:1931.0000,y:760.0000},
  {x:1931.6666,y:760.0000},
  {x:1932.3334,y:760.0000},
  {x:1933.0000,y:760.0000},
  {x:1934.6665,y:758.0002},
  {x:1936.3335,y:755.9998},
  {x:1938.0000,y:754.0000},
  {x:1938.6666,y:754.0000},
  {x:1939.3334,y:754.0000},
  {x:1940.0000,y:754.0000},
  {x:1940.9999,y:752.6668},
  {x:1942.0001,y:751.3332},
  {x:1943.0000,y:750.0000},
  {x:1943.6666,y:750.0000},
  {x:1944.3334,y:750.0000},
  {x:1945.0000,y:750.0000},
  {x:1945.9999,y:748.6668},
  {x:1947.0001,y:747.3332},
  {x:1948.0000,y:746.0000},
  {x:1948.6666,y:746.0000},
  {x:1949.3334,y:746.0000},
  {x:1950.0000,y:746.0000},
  {x:1950.9999,y:744.6668},
  {x:1952.0001,y:743.3332},
  {x:1953.0000,y:742.0000},
  {x:1953.6666,y:742.0000},
  {x:1954.3334,y:742.0000},
  {x:1955.0000,y:742.0000},
  {x:1955.9999,y:740.6668},
  {x:1957.0001,y:739.3332},
  {x:1958.0000,y:738.0000},
  {x:1958.6666,y:738.0000},
  {x:1959.3334,y:738.0000},
  {x:1960.0000,y:738.0000},
  {x:1960.6666,y:737.0001},
  {x:1961.3334,y:735.9999},
  {x:1962.0000,y:735.0000},
  {x:1962.9999,y:734.6667},
  {x:1964.0001,y:734.3333},
  {x:1965.0000,y:734.0000},
  {x:1965.3333,y:733.3334},
  {x:1965.6667,y:732.6666},
  {x:1966.0000,y:732.0000},
  {x:1966.6666,y:732.0000},
  {x:1967.3334,y:732.0000},
  {x:1968.0000,y:732.0000},
  {x:1968.3333,y:731.3334},
  {x:1968.6667,y:730.6666},
  {x:1969.0000,y:730.0000},
  {x:1969.6666,y:730.0000},
  {x:1970.3334,y:730.0000},
  {x:1971.0000,y:730.0000},
  {x:1971.6666,y:729.0001},
  {x:1972.3334,y:727.9999},
  {x:1973.0000,y:727.0000},
  {x:1973.6666,y:727.0000},
  {x:1974.3334,y:727.0000},
  {x:1975.0000,y:727.0000},
  {x:1975.3333,y:726.3334},
  {x:1975.6667,y:725.6666},
  {x:1976.0000,y:725.0000},
  {x:1976.6666,y:725.0000},
  {x:1977.3334,y:725.0000},
  {x:1978.0000,y:725.0000},
  {x:1978.6666,y:724.0001},
  {x:1979.3334,y:722.9999},
  {x:1980.0000,y:722.0000},
  {x:1980.6666,y:722.0000},
  {x:1981.3334,y:722.0000},
  {x:1982.0000,y:722.0000},
  {x:1982.3333,y:721.3334},
  {x:1982.6667,y:720.6666},
  {x:1983.0000,y:720.0000},
  {x:1983.6666,y:720.0000},
  {x:1984.3334,y:720.0000},
  {x:1985.0000,y:720.0000},
  {x:1985.3333,y:719.3334},
  {x:1985.6667,y:718.6666},
  {x:1986.0000,y:718.0000},
  {x:1986.6666,y:718.0000},
  {x:1987.3334,y:718.0000},
  {x:1988.0000,y:718.0000},
  {x:1988.3333,y:717.3334},
  {x:1988.6667,y:716.6666},
  {x:1989.0000,y:716.0000},
  {x:1989.6666,y:716.0000},
  {x:1990.3334,y:716.0000},
  {x:1991.0000,y:716.0000},
  {x:1991.0000,y:715.6667},
  {x:1991.0000,y:715.3333},
  {x:1991.0000,y:715.0000},
  {x:1991.9999,y:714.6667},
  {x:1993.0001,y:714.3333},
  {x:1994.0000,y:714.0000},
  {x:1994.3333,y:713.3334},
  {x:1994.6667,y:712.6666},
  {x:1995.0000,y:712.0000},
  {x:1995.6666,y:712.0000},
  {x:1996.3334,y:712.0000},
  {x:1997.0000,y:712.0000},
  {x:1997.0000,y:711.6667},
  {x:1997.0000,y:711.3333},
  {x:1997.0000,y:711.0000},
  {x:1997.9999,y:710.6667},
  {x:1999.0001,y:710.3333},
  {x:2000.0000,y:710.0000},
  {x:2000.3333,y:709.3334},
  {x:2000.6667,y:708.6666},
  {x:2001.0000,y:708.0000},
  {x:2001.6666,y:708.0000},
  {x:2002.3334,y:708.0000},
  {x:2003.0000,y:708.0000},
  {x:2003.6666,y:707.0001},
  {x:2004.3334,y:705.9999},
  {x:2005.0000,y:705.0000},
  {x:2005.6666,y:705.0000},
  {x:2006.3334,y:705.0000},
  {x:2007.0000,y:705.0000},
  {x:2007.3333,y:704.3334},
  {x:2007.6667,y:703.6666},
  {x:2008.0000,y:703.0000},
  {x:2008.6666,y:703.0000},
  {x:2009.3334,y:703.0000},
  {x:2010.0000,y:703.0000},
  {x:2010.6666,y:702.0001},
  {x:2011.3334,y:700.9999},
  {x:2012.0000,y:700.0000},
  {x:2012.6666,y:700.0000},
  {x:2013.3334,y:700.0000},
  {x:2014.0000,y:700.0000},
  {x:2014.6666,y:699.0001},
  {x:2015.3334,y:697.9999},
  {x:2016.0000,y:697.0000},
  {x:2016.6666,y:697.0000},
  {x:2017.3334,y:697.0000},
  {x:2018.0000,y:697.0000},
  {x:2018.6666,y:696.0001},
  {x:2019.3334,y:694.9999},
  {x:2020.0000,y:694.0000},
  {x:2020.6666,y:694.0000},
  {x:2021.3334,y:694.0000},
  {x:2022.0000,y:694.0000},
  {x:2022.9999,y:692.6668},
  {x:2024.0001,y:691.3332},
  {x:2025.0000,y:690.0000},
  {x:2025.9999,y:689.6667},
  {x:2027.0001,y:689.3333},
  {x:2028.0000,y:689.0000},
  {x:2028.3333,y:688.3334},
  {x:2028.6667,y:687.6666},
  {x:2029.0000,y:687.0000},
  {x:2029.6666,y:687.0000},
  {x:2030.3334,y:687.0000},
  {x:2031.0000,y:687.0000},
  {x:2032.3332,y:685.3335},
  {x:2033.6668,y:683.6665},
  {x:2035.0000,y:682.0000},
  {x:2035.6666,y:682.0000},
  {x:2036.3334,y:682.0000},
  {x:2037.0000,y:682.0000},
  {x:2038.3332,y:680.3335},
  {x:2039.6668,y:678.6665},
  {x:2041.0000,y:677.0000},
  {x:2041.6666,y:677.0000},
  {x:2042.3334,y:677.0000},
  {x:2043.0000,y:677.0000},
  {x:2044.9998,y:674.6669},
  {x:2047.0002,y:672.3331},
  {x:2049.0000,y:670.0000},
  {x:2049.6666,y:670.0000},
  {x:2050.3334,y:670.0000},
  {x:2051.0000,y:670.0000},
  {x:2055.6662,y:665.0005},
  {x:2060.3338,y:659.9995},
  {x:2065.0000,y:655.0000},
  {x:2071.3327,y:649.0006},
  {x:2077.6673,y:642.9994},
  {x:2084.0000,y:637.0000},
  {x:2084.0000,y:636.3334},
  {x:2084.0000,y:635.6666},
  {x:2084.0000,y:635.0000},
  {x:2086.3331,y:633.0002},
  {x:2088.6669,y:630.9998},
  {x:2091.0000,y:629.0000},
  {x:2091.0000,y:628.3334},
  {x:2091.0000,y:627.6666},
  {x:2091.0000,y:627.0000},
  {x:2092.6665,y:625.6668},
  {x:2094.3335,y:624.3332},
  {x:2096.0000,y:623.0000},
  {x:2096.0000,y:622.3334},
  {x:2096.0000,y:621.6666},
  {x:2096.0000,y:621.0000},
  {x:2097.3332,y:620.0001},
  {x:2098.6668,y:618.9999},
  {x:2100.0000,y:618.0000},
  {x:2100.0000,y:617.3334},
  {x:2100.0000,y:616.6666},
  {x:2100.0000,y:616.0000},
  {x:2100.9999,y:615.3334},
  {x:2102.0001,y:614.6666},
  {x:2103.0000,y:614.0000},
  {x:2103.0000,y:613.3334},
  {x:2103.0000,y:612.6666},
  {x:2103.0000,y:612.0000},
  {x:2103.9999,y:611.3334},
  {x:2105.0001,y:610.6666},
  {x:2106.0000,y:610.0000},
  {x:2106.0000,y:609.3334},
  {x:2106.0000,y:608.6666},
  {x:2106.0000,y:608.0000},
  {x:2106.9999,y:607.3334},
  {x:2108.0001,y:606.6666},
  {x:2109.0000,y:606.0000},
  {x:2109.0000,y:605.3334},
  {x:2109.0000,y:604.6666},
  {x:2109.0000,y:604.0000},
  {x:2109.9999,y:603.3334},
  {x:2111.0001,y:602.6666},
  {x:2112.0000,y:602.0000},
  {x:2112.0000,y:601.3334},
  {x:2112.0000,y:600.6666},
  {x:2112.0000,y:600.0000},
  {x:2112.6666,y:599.6667},
  {x:2113.3334,y:599.3333},
  {x:2114.0000,y:599.0000},
  {x:2114.0000,y:598.3334},
  {x:2114.0000,y:597.6666},
  {x:2114.0000,y:597.0000},
  {x:2114.6666,y:596.6667},
  {x:2115.3334,y:596.3333},
  {x:2116.0000,y:596.0000},
  {x:2116.0000,y:595.3334},
  {x:2116.0000,y:594.6666},
  {x:2116.0000,y:594.0000},
  {x:2116.6666,y:593.6667},
  {x:2117.3334,y:593.3333},
  {x:2118.0000,y:593.0000},
  {x:2118.0000,y:592.3334},
  {x:2118.0000,y:591.6666},
  {x:2118.0000,y:591.0000},
  {x:2118.6666,y:590.6667},
  {x:2119.3334,y:590.3333},
  {x:2120.0000,y:590.0000},
  {x:2120.0000,y:589.3334},
  {x:2120.0000,y:588.6666},
  {x:2120.0000,y:588.0000},
  {x:2120.6666,y:587.6667},
  {x:2121.3334,y:587.3333},
  {x:2122.0000,y:587.0000},
  {x:2122.3333,y:585.6668},
  {x:2122.6667,y:584.3332},
  {x:2123.0000,y:583.0000},
  {x:2123.6666,y:582.6667},
  {x:2124.3334,y:582.3333},
  {x:2125.0000,y:582.0000},
  {x:2125.3333,y:580.6668},
  {x:2125.6667,y:579.3332},
  {x:2126.0000,y:578.0000},
  {x:2126.6666,y:577.6667},
  {x:2127.3334,y:577.3333},
  {x:2128.0000,y:577.0000},
  {x:2128.6666,y:575.0002},
  {x:2129.3334,y:572.9998},
  {x:2130.0000,y:571.0000},
  {x:2130.6666,y:570.6667},
  {x:2131.3334,y:570.3333},
  {x:2132.0000,y:570.0000},
  {x:2132.6666,y:568.0002},
  {x:2133.3334,y:565.9998},
  {x:2134.0000,y:564.0000},
  {x:2134.3333,y:564.0000},
  {x:2134.6667,y:564.0000},
  {x:2135.0000,y:564.0000},
  {x:2135.0000,y:563.3334},
  {x:2135.0000,y:562.6666},
  {x:2135.0000,y:562.0000},
  {x:2135.3333,y:562.0000},
  {x:2135.6667,y:562.0000},
  {x:2136.0000,y:562.0000},
  {x:2136.6666,y:560.0002},
  {x:2137.3334,y:557.9998},
  {x:2138.0000,y:556.0000},
  {x:2138.3333,y:556.0000},
  {x:2138.6667,y:556.0000},
  {x:2139.0000,y:556.0000},
  {x:2139.0000,y:555.0001},
  {x:2139.0000,y:553.9999},
  {x:2139.0000,y:553.0000},
  {x:2139.3333,y:553.0000},
  {x:2139.6667,y:553.0000},
  {x:2140.0000,y:553.0000},
  {x:2140.3333,y:551.6668},
  {x:2140.6667,y:550.3332},
  {x:2141.0000,y:549.0000},
  {x:2141.3333,y:549.0000},
  {x:2141.6667,y:549.0000},
  {x:2142.0000,y:549.0000},
  {x:2142.0000,y:548.0001},
  {x:2142.0000,y:546.9999},
  {x:2142.0000,y:546.0000},
  {x:2142.3333,y:546.0000},
  {x:2142.6667,y:546.0000},
  {x:2143.0000,y:546.0000},
  {x:2143.0000,y:545.3334},
  {x:2143.0000,y:544.6666},
  {x:2143.0000,y:544.0000},
  {x:2143.3333,y:544.0000},
  {x:2143.6667,y:544.0000},
  {x:2144.0000,y:544.0000},
  {x:2144.0000,y:543.0001},
  {x:2144.0000,y:541.9999},
  {x:2144.0000,y:541.0000},
  {x:2144.3333,y:541.0000},
  {x:2144.6667,y:541.0000},
  {x:2145.0000,y:541.0000},
  {x:2145.0000,y:540.0001},
  {x:2145.0000,y:538.9999},
  {x:2145.0000,y:538.0000},
  {x:2145.3333,y:538.0000},
  {x:2145.6667,y:538.0000},
  {x:2146.0000,y:538.0000},
  {x:2146.3333,y:537.0001},
  {x:2146.6667,y:535.9999},
  {x:2147.0000,y:535.0000},
  {x:2145.0002,y:534.3334},
  {x:2142.9998,y:533.6666},
  {x:2141.0000,y:533.0000},
  {x:2141.0000,y:532.6667},
  {x:2141.0000,y:532.3333},
  {x:2141.0000,y:532.0000},
  {x:2140.0001,y:532.0000},
  {x:2138.9999,y:532.0000},
  {x:2138.0000,y:532.0000},
  {x:2138.0000,y:531.6667},
  {x:2138.0000,y:531.3333},
  {x:2138.0000,y:531.0000},
  {x:2137.3334,y:531.0000},
  {x:2136.6666,y:531.0000},
  {x:2136.0000,y:531.0000},
  {x:2136.0000,y:530.6667},
  {x:2136.0000,y:530.3333},
  {x:2136.0000,y:530.0000},
  {x:2134.3335,y:529.6667},
  {x:2132.6665,y:529.3333},
  {x:2131.0000,y:529.0000},
  {x:2131.0000,y:528.6667},
  {x:2131.0000,y:528.3333},
  {x:2131.0000,y:528.0000},
  {x:2129.0002,y:527.6667},
  {x:2126.9998,y:527.3333},
  {x:2125.0000,y:527.0000},
  {x:2125.0000,y:526.6667},
  {x:2125.0000,y:526.3333},
  {x:2125.0000,y:526.0000},
  {x:2124.3334,y:526.0000},
  {x:2123.6666,y:526.0000},
  {x:2123.0000,y:526.0000},
  {x:2123.0000,y:525.6667},
  {x:2123.0000,y:525.3333},
  {x:2123.0000,y:525.0000},
  {x:2120.0003,y:524.3334},
  {x:2116.9997,y:523.6666},
  {x:2114.0000,y:523.0000},
  {x:2114.0000,y:522.6667},
  {x:2114.0000,y:522.3333},
  {x:2114.0000,y:522.0000},
  {x:2112.3335,y:521.6667},
  {x:2110.6665,y:521.3333},
  {x:2109.0000,y:521.0000},
  {x:2109.0000,y:520.6667},
  {x:2109.0000,y:520.3333},
  {x:2109.0000,y:520.0000},
  {x:2108.3334,y:520.0000},
  {x:2107.6666,y:520.0000},
  {x:2107.0000,y:520.0000},
  {x:2107.0000,y:519.6667},
  {x:2107.0000,y:519.3333},
  {x:2107.0000,y:519.0000},
  {x:2106.0001,y:519.0000},
  {x:2104.9999,y:519.0000},
  {x:2104.0000,y:519.0000},
  {x:2104.0000,y:518.6667},
  {x:2104.0000,y:518.3333},
  {x:2104.0000,y:518.0000},
  {x:2103.3334,y:518.0000},
  {x:2102.6666,y:518.0000},
  {x:2102.0000,y:518.0000},
  {x:2102.0000,y:517.6667},
  {x:2102.0000,y:517.3333},
  {x:2102.0000,y:517.0000},
  {x:2101.3334,y:517.0000},
  {x:2100.6666,y:517.0000},
  {x:2100.0000,y:517.0000},
  {x:2100.0000,y:516.6667},
  {x:2100.0000,y:516.3333},
  {x:2100.0000,y:516.0000},
  {x:2099.3334,y:516.0000},
  {x:2098.6666,y:516.0000},
  {x:2098.0000,y:516.0000},
  {x:2098.0000,y:515.6667},
  {x:2098.0000,y:515.3333},
  {x:2098.0000,y:515.0000},
  {x:2097.3334,y:515.0000},
  {x:2096.6666,y:515.0000},
  {x:2096.0000,y:515.0000},
  {x:2096.0000,y:514.6667},
  {x:2096.0000,y:514.3333},
  {x:2096.0000,y:514.0000},
  {x:2095.3334,y:514.0000},
  {x:2094.6666,y:514.0000},
  {x:2094.0000,y:514.0000},
  {x:2094.0000,y:513.6667},
  {x:2094.0000,y:513.3333},
  {x:2094.0000,y:513.0000},
  {x:2093.3334,y:513.0000},
  {x:2092.6666,y:513.0000},
  {x:2092.0000,y:513.0000},
  {x:2092.0000,y:512.6667},
  {x:2092.0000,y:512.3333},
  {x:2092.0000,y:512.0000},
  {x:2091.3334,y:512.0000},
  {x:2090.6666,y:512.0000},
  {x:2090.0000,y:512.0000},
  {x:2090.0000,y:511.6667},
  {x:2090.0000,y:511.3333},
  {x:2090.0000,y:511.0000},
  {x:2089.3334,y:511.0000},
  {x:2088.6666,y:511.0000},
  {x:2088.0000,y:511.0000},
  {x:2088.0000,y:510.6667},
  {x:2088.0000,y:510.3333},
  {x:2088.0000,y:510.0000},
  {x:2087.3334,y:510.0000},
  {x:2086.6666,y:510.0000},
  {x:2086.0000,y:510.0000},
  {x:2086.0000,y:509.6667},
  {x:2086.0000,y:509.3333},
  {x:2086.0000,y:509.0000},
  {x:2085.3334,y:509.0000},
  {x:2084.6666,y:509.0000},
  {x:2084.0000,y:509.0000},
  {x:2084.0000,y:508.6667},
  {x:2084.0000,y:508.3333},
  {x:2084.0000,y:508.0000},
  {x:2083.3334,y:508.0000},
  {x:2082.6666,y:508.0000},
  {x:2082.0000,y:508.0000},
  {x:2082.0000,y:507.6667},
  {x:2082.0000,y:507.3333},
  {x:2082.0000,y:507.0000},
  {x:2081.3334,y:507.0000},
  {x:2080.6666,y:507.0000},
  {x:2080.0000,y:507.0000},
  {x:2079.6667,y:506.3334},
  {x:2079.3333,y:505.6666},
  {x:2079.0000,y:505.0000},
  {x:2077.6668,y:504.6667},
  {x:2076.3332,y:504.3333},
  {x:2075.0000,y:504.0000},
  {x:2074.6667,y:503.3334},
  {x:2074.3333,y:502.6666},
  {x:2074.0000,y:502.0000},
  {x:2072.6668,y:501.6667},
  {x:2071.3332,y:501.3333},
  {x:2070.0000,y:501.0000},
  {x:2069.6667,y:500.3334},
  {x:2069.3333,y:499.6666},
  {x:2069.0000,y:499.0000},
  {x:2067.6668,y:498.6667},
  {x:2066.3332,y:498.3333},
  {x:2065.0000,y:498.0000},
  {x:2065.0000,y:497.6667},
  {x:2065.0000,y:497.3333},
  {x:2065.0000,y:497.0000},
  {x:2064.0001,y:496.6667},
  {x:2062.9999,y:496.3333},
  {x:2062.0000,y:496.0000},
  {x:2061.6667,y:495.3334},
  {x:2061.3333,y:494.6666},
  {x:2061.0000,y:494.0000},
  {x:2059.6668,y:493.6667},
  {x:2058.3332,y:493.3333},
  {x:2057.0000,y:493.0000},
  {x:2056.6667,y:492.3334},
  {x:2056.3333,y:491.6666},
  {x:2056.0000,y:491.0000},
  {x:2055.3334,y:491.0000},
  {x:2054.6666,y:491.0000},
  {x:2054.0000,y:491.0000},
  {x:2053.3334,y:490.0001},
  {x:2052.6666,y:488.9999},
  {x:2052.0000,y:488.0000},
  {x:2051.3334,y:488.0000},
  {x:2050.6666,y:488.0000},
  {x:2050.0000,y:488.0000},
  {x:2048.3335,y:486.0002},
  {x:2046.6665,y:483.9998},
  {x:2045.0000,y:482.0000},
  {x:2044.3334,y:482.0000},
  {x:2043.6666,y:482.0000},
  {x:2043.0000,y:482.0000},
  {x:2041.3335,y:480.0002},
  {x:2039.6665,y:477.9998},
  {x:2038.0000,y:476.0000},
  {x:2037.3334,y:476.0000},
  {x:2036.6666,y:476.0000},
  {x:2036.0000,y:476.0000},
  {x:2035.6667,y:475.3334},
  {x:2035.3333,y:474.6666},
  {x:2035.0000,y:474.0000},
  {x:2033.3335,y:473.6667},
  {x:2031.6665,y:473.3333},
  {x:2030.0000,y:473.0000},
  {x:2030.0000,y:472.6667},
  {x:2030.0000,y:472.3333},
  {x:2030.0000,y:472.0000},
  {x:2029.3334,y:472.0000},
  {x:2028.6666,y:472.0000},
  {x:2028.0000,y:472.0000},
  {x:2028.0000,y:471.6667},
  {x:2028.0000,y:471.3333},
  {x:2028.0000,y:471.0000},
  {x:2027.0001,y:471.0000},
  {x:2025.9999,y:471.0000},
  {x:2025.0000,y:471.0000},
  {x:2024.6667,y:470.3334},
  {x:2024.3333,y:469.6666},
  {x:2024.0000,y:469.0000},
  {x:2023.3334,y:469.0000},
  {x:2022.6666,y:469.0000},
  {x:2022.0000,y:469.0000},
  {x:2021.6667,y:468.3334},
  {x:2021.3333,y:467.6666},
  {x:2021.0000,y:467.0000},
  {x:2020.3334,y:467.0000},
  {x:2019.6666,y:467.0000},
  {x:2019.0000,y:467.0000},
  {x:2018.0001,y:465.6668},
  {x:2016.9999,y:464.3332},
  {x:2016.0000,y:463.0000},
  {x:2015.3334,y:463.0000},
  {x:2014.6666,y:463.0000},
  {x:2014.0000,y:463.0000},
  {x:2013.0001,y:461.6668},
  {x:2011.9999,y:460.3332},
  {x:2011.0000,y:459.0000},
  {x:2010.3334,y:459.0000},
  {x:2009.6666,y:459.0000},
  {x:2009.0000,y:459.0000},
  {x:2008.0001,y:457.6668},
  {x:2006.9999,y:456.3332},
  {x:2006.0000,y:455.0000},
  {x:2005.3334,y:455.0000},
  {x:2004.6666,y:455.0000},
  {x:2004.0000,y:455.0000},
  {x:2003.0001,y:453.6668},
  {x:2001.9999,y:452.3332},
  {x:2001.0000,y:451.0000},
  {x:2000.3334,y:451.0000},
  {x:1999.6666,y:451.0000},
  {x:1999.0000,y:451.0000},
  {x:1998.3334,y:450.0001},
  {x:1997.6666,y:448.9999},
  {x:1997.0000,y:448.0000},
  {x:1996.3334,y:448.0000},
  {x:1995.6666,y:448.0000},
  {x:1995.0000,y:448.0000},
  {x:1994.3334,y:447.0001},
  {x:1993.6666,y:445.9999},
  {x:1993.0000,y:445.0000},
  {x:1991.6668,y:444.6667},
  {x:1990.3332,y:444.3333},
  {x:1989.0000,y:444.0000},
  {x:1988.6667,y:443.3334},
  {x:1988.3333,y:442.6666},
  {x:1988.0000,y:442.0000},
  {x:1987.3334,y:442.0000},
  {x:1986.6666,y:442.0000},
  {x:1986.0000,y:442.0000},
  {x:1986.0000,y:441.6667},
  {x:1986.0000,y:441.3333},
  {x:1986.0000,y:441.0000},
  {x:1985.0001,y:441.0000},
  {x:1983.9999,y:441.0000},
  {x:1983.0000,y:441.0000},
  {x:1983.0000,y:440.6667},
  {x:1983.0000,y:440.3333},
  {x:1983.0000,y:440.0000},
  {x:1982.3334,y:440.0000},
  {x:1981.6666,y:440.0000},
  {x:1981.0000,y:440.0000},
  {x:1981.0000,y:439.6667},
  {x:1981.0000,y:439.3333},
  {x:1981.0000,y:439.0000},
  {x:1978.0003,y:438.3334},
  {x:1974.9997,y:437.6666},
  {x:1972.0000,y:437.0000},
  {x:1972.0000,y:436.6667},
  {x:1972.0000,y:436.3333},
  {x:1972.0000,y:436.0000},
  {x:1971.3334,y:436.0000},
  {x:1970.6666,y:436.0000},
  {x:1970.0000,y:436.0000},
  {x:1970.0000,y:435.6667},
  {x:1970.0000,y:435.3333},
  {x:1970.0000,y:435.0000},
  {x:1969.0001,y:435.0000},
  {x:1967.9999,y:435.0000},
  {x:1967.0000,y:435.0000},
  {x:1967.0000,y:434.6667},
  {x:1967.0000,y:434.3333},
  {x:1967.0000,y:434.0000},
  {x:1965.6668,y:433.6667},
  {x:1964.3332,y:433.3333},
  {x:1963.0000,y:433.0000},
  {x:1963.0000,y:432.6667},
  {x:1963.0000,y:432.3333},
  {x:1963.0000,y:432.0000},
  {x:1962.0001,y:432.0000},
  {x:1960.9999,y:432.0000},
  {x:1960.0000,y:432.0000},
  {x:1960.0000,y:431.6667},
  {x:1960.0000,y:431.3333},
  {x:1960.0000,y:431.0000},
  {x:1959.3334,y:431.0000},
  {x:1958.6666,y:431.0000},
  {x:1958.0000,y:431.0000},
  {x:1958.0000,y:430.6667},
  {x:1958.0000,y:430.3333},
  {x:1958.0000,y:430.0000},
  {x:1956.6668,y:429.6667},
  {x:1955.3332,y:429.3333},
  {x:1954.0000,y:429.0000},
  {x:1954.0000,y:428.6667},
  {x:1954.0000,y:428.3333},
  {x:1954.0000,y:428.0000},
  {x:1952.6668,y:427.6667},
  {x:1951.3332,y:427.3333},
  {x:1950.0000,y:427.0000},
  {x:1950.0000,y:426.6667},
  {x:1950.0000,y:426.3333},
  {x:1950.0000,y:426.0000},
  {x:1948.3335,y:425.6667},
  {x:1946.6665,y:425.3333},
  {x:1945.0000,y:425.0000},
  {x:1944.6667,y:424.3334},
  {x:1944.3333,y:423.6666},
  {x:1944.0000,y:423.0000},
  {x:1942.0002,y:422.3334},
  {x:1939.9998,y:421.6666},
  {x:1938.0000,y:421.0000},
  {x:1938.0000,y:420.6667},
  {x:1938.0000,y:420.3333},
  {x:1938.0000,y:420.0000},
  {x:1937.3334,y:420.0000},
  {x:1936.6666,y:420.0000},
  {x:1936.0000,y:420.0000},
  {x:1936.0000,y:419.6667},
  {x:1936.0000,y:419.3333},
  {x:1936.0000,y:419.0000},
  {x:1934.0002,y:418.3334},
  {x:1931.9998,y:417.6666},
  {x:1930.0000,y:417.0000},
  {x:1930.0000,y:416.6667},
  {x:1930.0000,y:416.3333},
  {x:1930.0000,y:416.0000},
  {x:1929.0001,y:416.0000},
  {x:1927.9999,y:416.0000},
  {x:1927.0000,y:416.0000},
  {x:1927.0000,y:415.6667},
  {x:1927.0000,y:415.3333},
  {x:1927.0000,y:415.0000},
  {x:1926.3334,y:415.0000},
  {x:1925.6666,y:415.0000},
  {x:1925.0000,y:415.0000},
  {x:1925.0000,y:414.6667},
  {x:1925.0000,y:414.3333},
  {x:1925.0000,y:414.0000},
  {x:1924.0001,y:414.0000},
  {x:1922.9999,y:414.0000},
  {x:1922.0000,y:414.0000},
  {x:1922.0000,y:413.6667},
  {x:1922.0000,y:413.3333},
  {x:1922.0000,y:413.0000},
  {x:1921.3334,y:413.0000},
  {x:1920.6666,y:413.0000},
  {x:1920.0000,y:413.0000},
  {x:1920.0000,y:412.6667},
  {x:1920.0000,y:412.3333},
  {x:1920.0000,y:412.0000},
  {x:1919.0001,y:412.0000},
  {x:1917.9999,y:412.0000},
  {x:1917.0000,y:412.0000},
  {x:1917.0000,y:411.6667},
  {x:1917.0000,y:411.3333},
  {x:1917.0000,y:411.0000},
  {x:1916.3334,y:411.0000},
  {x:1915.6666,y:411.0000},
  {x:1915.0000,y:411.0000},
  {x:1915.0000,y:410.6667},
  {x:1915.0000,y:410.3333},
  {x:1915.0000,y:410.0000},
  {x:1914.0001,y:410.0000},
  {x:1912.9999,y:410.0000},
  {x:1912.0000,y:410.0000},
  {x:1912.0000,y:409.6667},
  {x:1912.0000,y:409.3333},
  {x:1912.0000,y:409.0000},
  {x:1911.0001,y:409.0000},
  {x:1909.9999,y:409.0000},
  {x:1909.0000,y:409.0000},
  {x:1909.0000,y:408.6667},
  {x:1909.0000,y:408.3333},
  {x:1909.0000,y:408.0000},
  {x:1908.0001,y:408.0000},
  {x:1906.9999,y:408.0000},
  {x:1906.0000,y:408.0000},
  {x:1906.0000,y:407.6667},
  {x:1906.0000,y:407.3333},
  {x:1906.0000,y:407.0000},
  {x:1904.6668,y:407.0000},
  {x:1903.3332,y:407.0000},
  {x:1902.0000,y:407.0000},
  {x:1902.0000,y:406.6667},
  {x:1902.0000,y:406.3333},
  {x:1902.0000,y:406.0000},
  {x:1901.0001,y:406.0000},
  {x:1899.9999,y:406.0000},
  {x:1899.0000,y:406.0000},
  {x:1899.0000,y:405.6667},
  {x:1899.0000,y:405.3333},
  {x:1899.0000,y:405.0000},
  {x:1896.6669,y:404.6667},
  {x:1894.3331,y:404.3333},
  {x:1892.0000,y:404.0000},
  {x:1892.0000,y:403.6667},
  {x:1892.0000,y:403.3333},
  {x:1892.0000,y:403.0000},
  {x:1889.3336,y:402.6667},
  {x:1886.6664,y:402.3333},
  {x:1884.0000,y:402.0000},
  {x:1884.0000,y:401.6667},
  {x:1884.0000,y:401.3333},
  {x:1884.0000,y:401.0000},
  {x:1882.3335,y:401.0000},
  {x:1880.6665,y:401.0000},
  {x:1879.0000,y:401.0000},
  {x:1879.0000,y:400.6667},
  {x:1879.0000,y:400.3333},
  {x:1879.0000,y:400.0000},
  {x:1877.3335,y:400.0000},
  {x:1875.6665,y:400.0000},
  {x:1874.0000,y:400.0000},
  {x:1874.0000,y:399.6667},
  {x:1874.0000,y:399.3333},
  {x:1874.0000,y:399.0000},
  {x:1872.3335,y:399.0000},
  {x:1870.6665,y:399.0000},
  {x:1869.0000,y:399.0000},
  {x:1869.0000,y:398.6667},
  {x:1869.0000,y:398.3333},
  {x:1869.0000,y:398.0000},
  {x:1864.3338,y:397.3334},
  {x:1859.6662,y:396.6666},
  {x:1855.0000,y:396.0000},
  {x:1855.0000,y:395.6667},
  {x:1855.0000,y:395.3333},
  {x:1855.0000,y:395.0000},
  {x:1854.3334,y:395.0000},
  {x:1853.6666,y:395.0000},
  {x:1853.0000,y:395.0000},
  {x:1853.0000,y:394.6667},
  {x:1853.0000,y:394.3333},
  {x:1853.0000,y:394.0000},
  {x:1852.0001,y:394.0000},
  {x:1850.9999,y:394.0000},
  {x:1850.0000,y:394.0000},
  {x:1850.0000,y:393.6667},
  {x:1850.0000,y:393.3333},
  {x:1850.0000,y:393.0000},
  {x:1849.3334,y:393.0000},
  {x:1848.6666,y:393.0000},
  {x:1848.0000,y:393.0000},
  {x:1848.0000,y:392.6667},
  {x:1848.0000,y:392.3333},
  {x:1848.0000,y:392.0000},
  {x:1847.0001,y:392.0000},
  {x:1845.9999,y:392.0000},
  {x:1845.0000,y:392.0000},
  {x:1845.0000,y:391.6667},
  {x:1845.0000,y:391.3333},
  {x:1845.0000,y:391.0000},
  {x:1843.6668,y:390.6667},
  {x:1842.3332,y:390.3333},
  {x:1841.0000,y:390.0000},
  {x:1841.0000,y:389.6667},
  {x:1841.0000,y:389.3333},
  {x:1841.0000,y:389.0000},
  {x:1840.0001,y:389.0000},
  {x:1838.9999,y:389.0000},
  {x:1838.0000,y:389.0000},
  {x:1838.0000,y:388.6667},
  {x:1838.0000,y:388.3333},
  {x:1838.0000,y:388.0000},
  {x:1836.0002,y:387.3334},
  {x:1833.9998,y:386.6666},
  {x:1832.0000,y:386.0000},
  {x:1832.0000,y:385.6667},
  {x:1832.0000,y:385.3333},
  {x:1832.0000,y:385.0000},
  {x:1831.3334,y:385.0000},
  {x:1830.6666,y:385.0000},
  {x:1830.0000,y:385.0000},
  {x:1830.0000,y:384.6667},
  {x:1830.0000,y:384.3333},
  {x:1830.0000,y:384.0000},
  {x:1829.0001,y:384.0000},
  {x:1827.9999,y:384.0000},
  {x:1827.0000,y:384.0000},
  {x:1827.0000,y:383.6667},
  {x:1827.0000,y:383.3333},
  {x:1827.0000,y:383.0000},
  {x:1825.0002,y:382.3334},
  {x:1822.9998,y:381.6666},
  {x:1821.0000,y:381.0000},
  {x:1821.0000,y:380.6667},
  {x:1821.0000,y:380.3333},
  {x:1821.0000,y:380.0000},
  {x:1820.3334,y:380.0000},
  {x:1819.6666,y:380.0000},
  {x:1819.0000,y:380.0000},
  {x:1819.0000,y:379.6667},
  {x:1819.0000,y:379.3333},
  {x:1819.0000,y:379.0000},
  {x:1818.3334,y:379.0000},
  {x:1817.6666,y:379.0000},
  {x:1817.0000,y:379.0000},
  {x:1817.0000,y:378.6667},
  {x:1817.0000,y:378.3333},
  {x:1817.0000,y:378.0000},
  {x:1816.0001,y:378.0000},
  {x:1814.9999,y:378.0000},
  {x:1814.0000,y:378.0000},
  {x:1814.0000,y:377.6667},
  {x:1814.0000,y:377.3333},
  {x:1814.0000,y:377.0000},
  {x:1812.0002,y:376.3334},
  {x:1809.9998,y:375.6666},
  {x:1808.0000,y:375.0000},
  {x:1808.0000,y:374.6667},
  {x:1808.0000,y:374.3333},
  {x:1808.0000,y:374.0000},
  {x:1807.3334,y:374.0000},
  {x:1806.6666,y:374.0000},
  {x:1806.0000,y:374.0000},
  {x:1806.0000,y:373.6667},
  {x:1806.0000,y:373.3333},
  {x:1806.0000,y:373.0000},
  {x:1805.3334,y:373.0000},
  {x:1804.6666,y:373.0000},
  {x:1804.0000,y:373.0000},
  {x:1804.0000,y:372.6667},
  {x:1804.0000,y:372.3333},
  {x:1804.0000,y:372.0000},
  {x:1802.6668,y:371.6667},
  {x:1801.3332,y:371.3333},
  {x:1800.0000,y:371.0000},
  {x:1800.0000,y:370.6667},
  {x:1800.0000,y:370.3333},
  {x:1800.0000,y:370.0000},
  {x:1799.0001,y:370.0000},
  {x:1797.9999,y:370.0000},
  {x:1797.0000,y:370.0000},
  {x:1797.0000,y:369.6667},
  {x:1797.0000,y:369.3333},
  {x:1797.0000,y:369.0000},
  {x:1796.3334,y:369.0000},
  {x:1795.6666,y:369.0000},
  {x:1795.0000,y:369.0000},
  {x:1795.0000,y:368.6667},
  {x:1795.0000,y:368.3333},
  {x:1795.0000,y:368.0000},
  {x:1794.3334,y:368.0000},
  {x:1793.6666,y:368.0000},
  {x:1793.0000,y:368.0000},
  {x:1793.0000,y:367.6667},
  {x:1793.0000,y:367.3333},
  {x:1793.0000,y:367.0000},
  {x:1792.3334,y:367.0000},
  {x:1791.6666,y:367.0000},
  {x:1791.0000,y:367.0000},
  {x:1791.0000,y:366.6667},
  {x:1791.0000,y:366.3333},
  {x:1791.0000,y:366.0000},
  {x:1790.3334,y:366.0000},
  {x:1789.6666,y:366.0000},
  {x:1789.0000,y:366.0000},
  {x:1789.0000,y:365.6667},
  {x:1789.0000,y:365.3333},
  {x:1789.0000,y:365.0000},
  {x:1788.3334,y:365.0000},
  {x:1787.6666,y:365.0000},
  {x:1787.0000,y:365.0000},
  {x:1787.0000,y:364.6667},
  {x:1787.0000,y:364.3333},
  {x:1787.0000,y:364.0000},
  {x:1786.3334,y:364.0000},
  {x:1785.6666,y:364.0000},
  {x:1785.0000,y:364.0000},
  {x:1785.0000,y:363.6667},
  {x:1785.0000,y:363.3333},
  {x:1785.0000,y:363.0000},
  {x:1784.3334,y:363.0000},
  {x:1783.6666,y:363.0000},
  {x:1783.0000,y:363.0000},
  {x:1783.0000,y:362.6667},
  {x:1783.0000,y:362.3333},
  {x:1783.0000,y:362.0000},
  {x:1782.3334,y:362.0000},
  {x:1781.6666,y:362.0000},
  {x:1781.0000,y:362.0000},
  {x:1781.0000,y:361.6667},
  {x:1781.0000,y:361.3333},
  {x:1781.0000,y:361.0000},
  {x:1779.0002,y:360.3334},
  {x:1776.9998,y:359.6666},
  {x:1775.0000,y:359.0000},
  {x:1775.0000,y:358.6667},
  {x:1775.0000,y:358.3333},
  {x:1775.0000,y:358.0000},
  {x:1774.0001,y:358.0000},
  {x:1772.9999,y:358.0000},
  {x:1772.0000,y:358.0000},
  {x:1772.0000,y:357.6667},
  {x:1772.0000,y:357.3333},
  {x:1772.0000,y:357.0000},
  {x:1771.3334,y:357.0000},
  {x:1770.6666,y:357.0000},
  {x:1770.0000,y:357.0000},
  {x:1769.6667,y:356.3334},
  {x:1769.3333,y:355.6666},
  {x:1769.0000,y:355.0000},
  {x:1768.3334,y:355.0000},
  {x:1767.6666,y:355.0000},
  {x:1767.0000,y:355.0000},
  {x:1767.0000,y:354.6667},
  {x:1767.0000,y:354.3333},
  {x:1767.0000,y:354.0000},
  {x:1766.3334,y:354.0000},
  {x:1765.6666,y:354.0000},
  {x:1765.0000,y:354.0000},
  {x:1765.0000,y:353.6667},
  {x:1765.0000,y:353.3333},
  {x:1765.0000,y:353.0000},
  {x:1764.3334,y:353.0000},
  {x:1763.6666,y:353.0000},
  {x:1763.0000,y:353.0000},
  {x:1763.0000,y:352.6667},
  {x:1763.0000,y:352.3333},
  {x:1763.0000,y:352.0000},
  {x:1762.3334,y:352.0000},
  {x:1761.6666,y:352.0000},
  {x:1761.0000,y:352.0000},
  {x:1760.6667,y:351.3334},
  {x:1760.3333,y:350.6666},
  {x:1760.0000,y:350.0000},
  {x:1758.6668,y:349.6667},
  {x:1757.3332,y:349.3333},
  {x:1756.0000,y:349.0000},
  {x:1755.6667,y:348.3334},
  {x:1755.3333,y:347.6666},
  {x:1755.0000,y:347.0000},
  {x:1754.3334,y:347.0000},
  {x:1753.6666,y:347.0000},
  {x:1753.0000,y:347.0000},
  {x:1753.0000,y:346.6667},
  {x:1753.0000,y:346.3333},
  {x:1753.0000,y:346.0000},
  {x:1752.3334,y:346.0000},
  {x:1751.6666,y:346.0000},
  {x:1751.0000,y:346.0000},
  {x:1750.6667,y:345.3334},
  {x:1750.3333,y:344.6666},
  {x:1750.0000,y:344.0000},
  {x:1749.3334,y:344.0000},
  {x:1748.6666,y:344.0000},
  {x:1748.0000,y:344.0000},
  {x:1747.6667,y:343.3334},
  {x:1747.3333,y:342.6666},
  {x:1747.0000,y:342.0000},
  {x:1746.3334,y:342.0000},
  {x:1745.6666,y:342.0000},
  {x:1745.0000,y:342.0000},
  {x:1744.6667,y:341.3334},
  {x:1744.3333,y:340.6666},
  {x:1744.0000,y:340.0000},
  {x:1743.3334,y:340.0000},
  {x:1742.6666,y:340.0000},
  {x:1742.0000,y:340.0000},
  {x:1742.0000,y:339.6667},
  {x:1742.0000,y:339.3333},
  {x:1742.0000,y:339.0000},
  {x:1741.0001,y:338.6667},
  {x:1739.9999,y:338.3333},
  {x:1739.0000,y:338.0000},
  {x:1738.6667,y:337.3334},
  {x:1738.3333,y:336.6666},
  {x:1738.0000,y:336.0000},
  {x:1737.3334,y:336.0000},
  {x:1736.6666,y:336.0000},
  {x:1736.0000,y:336.0000},
  {x:1735.6667,y:335.3334},
  {x:1735.3333,y:334.6666},
  {x:1735.0000,y:334.0000},
  {x:1734.3334,y:334.0000},
  {x:1733.6666,y:334.0000},
  {x:1733.0000,y:334.0000},
  {x:1732.6667,y:333.3334},
  {x:1732.3333,y:332.6666},
  {x:1732.0000,y:332.0000},
  {x:1731.3334,y:332.0000},
  {x:1730.6666,y:332.0000},
  {x:1730.0000,y:332.0000},
  {x:1730.0000,y:331.6667},
  {x:1730.0000,y:331.3333},
  {x:1730.0000,y:331.0000},
  {x:1729.0001,y:330.6667},
  {x:1727.9999,y:330.3333},
  {x:1727.0000,y:330.0000},
  {x:1726.6667,y:329.3334},
  {x:1726.3333,y:328.6666},
  {x:1726.0000,y:328.0000},
  {x:1725.3334,y:328.0000},
  {x:1724.6666,y:328.0000},
  {x:1724.0000,y:328.0000},
  {x:1723.3334,y:327.0001},
  {x:1722.6666,y:325.9999},
  {x:1722.0000,y:325.0000},
  {x:1721.3334,y:325.0000},
  {x:1720.6666,y:325.0000},
  {x:1720.0000,y:325.0000},
  {x:1720.0000,y:324.6667},
  {x:1720.0000,y:324.3333},
  {x:1720.0000,y:324.0000},
  {x:1719.0001,y:323.6667},
  {x:1717.9999,y:323.3333},
  {x:1717.0000,y:323.0000},
  {x:1716.6667,y:322.3334},
  {x:1716.3333,y:321.6666},
  {x:1716.0000,y:321.0000},
  {x:1715.3334,y:321.0000},
  {x:1714.6666,y:321.0000},
  {x:1714.0000,y:321.0000},
  {x:1713.3334,y:320.0001},
  {x:1712.6666,y:318.9999},
  {x:1712.0000,y:318.0000},
  {x:1711.3334,y:318.0000},
  {x:1710.6666,y:318.0000},
  {x:1710.0000,y:318.0000},
  {x:1709.6667,y:317.3334},
  {x:1709.3333,y:316.6666},
  {x:1709.0000,y:316.0000},
  {x:1708.3334,y:316.0000},
  {x:1707.6666,y:316.0000},
  {x:1707.0000,y:316.0000},
  {x:1706.3334,y:315.0001},
  {x:1705.6666,y:313.9999},
  {x:1705.0000,y:313.0000},
  {x:1704.3334,y:313.0000},
  {x:1703.6666,y:313.0000},
  {x:1703.0000,y:313.0000},
  {x:1702.6667,y:312.3334},
  {x:1702.3333,y:311.6666},
  {x:1702.0000,y:311.0000},
  {x:1701.3334,y:311.0000},
  {x:1700.6666,y:311.0000},
  {x:1700.0000,y:311.0000},
  {x:1699.3334,y:310.0001},
  {x:1698.6666,y:308.9999},
  {x:1698.0000,y:308.0000},
  {x:1697.3334,y:308.0000},
  {x:1696.6666,y:308.0000},
  {x:1696.0000,y:308.0000},
  {x:1695.6667,y:307.3334},
  {x:1695.3333,y:306.6666},
  {x:1695.0000,y:306.0000},
  {x:1694.3334,y:306.0000},
  {x:1693.6666,y:306.0000},
  {x:1693.0000,y:306.0000},
  {x:1692.3334,y:305.0001},
  {x:1691.6666,y:303.9999},
  {x:1691.0000,y:303.0000},
  {x:1690.3334,y:303.0000},
  {x:1689.6666,y:303.0000},
  {x:1689.0000,y:303.0000},
  {x:1688.6667,y:302.3334},
  {x:1688.3333,y:301.6666},
  {x:1688.0000,y:301.0000},
  {x:1687.3334,y:301.0000},
  {x:1686.6666,y:301.0000},
  {x:1686.0000,y:301.0000},
  {x:1685.6667,y:300.3334},
  {x:1685.3333,y:299.6666},
  {x:1685.0000,y:299.0000},
  {x:1684.0001,y:298.6667},
  {x:1682.9999,y:298.3333},
  {x:1682.0000,y:298.0000},
  {x:1681.3334,y:297.0001},
  {x:1680.6666,y:295.9999},
  {x:1680.0000,y:295.0000},
  {x:1679.3334,y:295.0000},
  {x:1678.6666,y:295.0000},
  {x:1678.0000,y:295.0000},
  {x:1677.3334,y:294.0001},
  {x:1676.6666,y:292.9999},
  {x:1676.0000,y:292.0000},
  {x:1675.0001,y:291.6667},
  {x:1673.9999,y:291.3333},
  {x:1673.0000,y:291.0000},
  {x:1672.6667,y:290.3334},
  {x:1672.3333,y:289.6666},
  {x:1672.0000,y:289.0000},
  {x:1671.3334,y:289.0000},
  {x:1670.6666,y:289.0000},
  {x:1670.0000,y:289.0000},
  {x:1669.6667,y:288.3334},
  {x:1669.3333,y:287.6666},
  {x:1669.0000,y:287.0000},
  {x:1668.3334,y:287.0000},
  {x:1667.6666,y:287.0000},
  {x:1667.0000,y:287.0000},
  {x:1666.3334,y:286.0001},
  {x:1665.6666,y:284.9999},
  {x:1665.0000,y:284.0000},
  {x:1664.3334,y:284.0000},
  {x:1663.6666,y:284.0000},
  {x:1663.0000,y:284.0000},
  {x:1662.3334,y:283.0001},
  {x:1661.6666,y:281.9999},
  {x:1661.0000,y:281.0000},
  {x:1660.3334,y:281.0000},
  {x:1659.6666,y:281.0000},
  {x:1659.0000,y:281.0000},
  {x:1658.3334,y:280.0001},
  {x:1657.6666,y:278.9999},
  {x:1657.0000,y:278.0000},
  {x:1656.3334,y:278.0000},
  {x:1655.6666,y:278.0000},
  {x:1655.0000,y:278.0000},
  {x:1654.3334,y:277.0001},
  {x:1653.6666,y:275.9999},
  {x:1653.0000,y:275.0000},
  {x:1652.3334,y:275.0000},
  {x:1651.6666,y:275.0000},
  {x:1651.0000,y:275.0000},
  {x:1650.6667,y:274.3334},
  {x:1650.3333,y:273.6666},
  {x:1650.0000,y:273.0000},
  {x:1649.3334,y:273.0000},
  {x:1648.6666,y:273.0000},
  {x:1648.0000,y:273.0000},
  {x:1647.6667,y:272.3334},
  {x:1647.3333,y:271.6666},
  {x:1647.0000,y:271.0000},
  {x:1646.3334,y:271.0000},
  {x:1645.6666,y:271.0000},
  {x:1645.0000,y:271.0000},
  {x:1644.6667,y:270.3334},
  {x:1644.3333,y:269.6666},
  {x:1644.0000,y:269.0000},
  {x:1643.3334,y:269.0000},
  {x:1642.6666,y:269.0000},
  {x:1642.0000,y:269.0000},
  {x:1641.6667,y:268.3334},
  {x:1641.3333,y:267.6666},
  {x:1641.0000,y:267.0000},
  {x:1639.6668,y:266.6667},
  {x:1638.3332,y:266.3333},
  {x:1637.0000,y:266.0000},
  {x:1637.0000,y:265.6667},
  {x:1637.0000,y:265.3333},
  {x:1637.0000,y:265.0000},
  {x:1636.0001,y:264.6667},
  {x:1634.9999,y:264.3333},
  {x:1634.0000,y:264.0000},
  {x:1633.6667,y:263.3334},
  {x:1633.3333,y:262.6666},
  {x:1633.0000,y:262.0000},
  {x:1632.3334,y:262.0000},
  {x:1631.6666,y:262.0000},
  {x:1631.0000,y:262.0000},
  {x:1631.0000,y:261.6667},
  {x:1631.0000,y:261.3333},
  {x:1631.0000,y:261.0000},
  {x:1630.3334,y:261.0000},
  {x:1629.6666,y:261.0000},
  {x:1629.0000,y:261.0000},
  {x:1628.6667,y:260.3334},
  {x:1628.3333,y:259.6666},
  {x:1628.0000,y:259.0000},
  {x:1626.6668,y:258.6667},
  {x:1625.3332,y:258.3333},
  {x:1624.0000,y:258.0000},
  {x:1623.6667,y:257.3334},
  {x:1623.3333,y:256.6666},
  {x:1623.0000,y:256.0000},
  {x:1622.3334,y:256.0000},
  {x:1621.6666,y:256.0000},
  {x:1621.0000,y:256.0000},
  {x:1620.6667,y:255.3334},
  {x:1620.3333,y:254.6666},
  {x:1620.0000,y:254.0000},
  {x:1619.3334,y:254.0000},
  {x:1618.6666,y:254.0000},
  {x:1618.0000,y:254.0000},
  {x:1617.3334,y:253.0001},
  {x:1616.6666,y:251.9999},
  {x:1616.0000,y:251.0000},
  {x:1615.3334,y:251.0000},
  {x:1614.6666,y:251.0000},
  {x:1614.0000,y:251.0000},
  {x:1613.6667,y:250.3334},
  {x:1613.3333,y:249.6666},
  {x:1613.0000,y:249.0000},
  {x:1612.0001,y:248.6667},
  {x:1610.9999,y:248.3333},
  {x:1610.0000,y:248.0000},
  {x:1610.0000,y:247.6667},
  {x:1610.0000,y:247.3333},
  {x:1610.0000,y:247.0000},
  {x:1608.6668,y:246.6667},
  {x:1607.3332,y:246.3333},
  {x:1606.0000,y:246.0000},
  {x:1605.6667,y:245.3334},
  {x:1605.3333,y:244.6666},
  {x:1605.0000,y:244.0000},
  {x:1603.6668,y:243.6667},
  {x:1602.3332,y:243.3333},
  {x:1601.0000,y:243.0000},
  {x:1601.0000,y:242.6667},
  {x:1601.0000,y:242.3333},
  {x:1601.0000,y:242.0000},
  {x:1600.3334,y:242.0000},
  {x:1599.6666,y:242.0000},
  {x:1599.0000,y:242.0000},
  {x:1599.0000,y:241.6667},
  {x:1599.0000,y:241.3333},
  {x:1599.0000,y:241.0000},
  {x:1597.0002,y:240.3334},
  {x:1594.9998,y:239.6666},
  {x:1593.0000,y:239.0000},
  {x:1593.0000,y:238.6667},
  {x:1593.0000,y:238.3333},
  {x:1593.0000,y:238.0000},
  {x:1592.0001,y:238.0000},
  {x:1590.9999,y:238.0000},
  {x:1590.0000,y:238.0000},
  {x:1590.0000,y:237.6667},
  {x:1590.0000,y:237.3333},
  {x:1590.0000,y:237.0000},
  {x:1589.3334,y:237.0000},
  {x:1588.6666,y:237.0000},
  {x:1588.0000,y:237.0000},
  {x:1588.0000,y:236.6667},
  {x:1588.0000,y:236.3333},
  {x:1588.0000,y:236.0000},
  {x:1587.0001,y:236.0000},
  {x:1585.9999,y:236.0000},
  {x:1585.0000,y:236.0000},
  {x:1585.0000,y:235.6667},
  {x:1585.0000,y:235.3333},
  {x:1585.0000,y:235.0000},
  {x:1583.0002,y:234.3334},
  {x:1580.9998,y:233.6666},
  {x:1579.0000,y:233.0000},
  {x:1579.0000,y:232.6667},
  {x:1579.0000,y:232.3333},
  {x:1579.0000,y:232.0000},
  {x:1578.3334,y:232.0000},
  {x:1577.6666,y:232.0000},
  {x:1577.0000,y:232.0000},
  {x:1577.0000,y:231.6667},
  {x:1577.0000,y:231.3333},
  {x:1577.0000,y:231.0000},
  {x:1576.3334,y:231.0000},
  {x:1575.6666,y:231.0000},
  {x:1575.0000,y:231.0000},
  {x:1575.0000,y:230.6667},
  {x:1575.0000,y:230.3333},
  {x:1575.0000,y:230.0000},
  {x:1573.0002,y:229.3334},
  {x:1570.9998,y:228.6666},
  {x:1569.0000,y:228.0000},
  {x:1568.6667,y:227.3334},
  {x:1568.3333,y:226.6666},
  {x:1568.0000,y:226.0000},
  {x:1566.6668,y:225.6667},
  {x:1565.3332,y:225.3333},
  {x:1564.0000,y:225.0000},
  {x:1564.0000,y:224.6667},
  {x:1564.0000,y:224.3333},
  {x:1564.0000,y:224.0000},
  {x:1563.3334,y:224.0000},
  {x:1562.6666,y:224.0000},
  {x:1562.0000,y:224.0000},
  {x:1562.0000,y:223.6667},
  {x:1562.0000,y:223.3333},
  {x:1562.0000,y:223.0000},
  {x:1561.3334,y:223.0000},
  {x:1560.6666,y:223.0000},
  {x:1560.0000,y:223.0000},
  {x:1560.0000,y:222.6667},
  {x:1560.0000,y:222.3333},
  {x:1560.0000,y:222.0000},
  {x:1559.3334,y:222.0000},
  {x:1558.6666,y:222.0000},
  {x:1558.0000,y:222.0000},
  {x:1557.6667,y:221.3334},
  {x:1557.3333,y:220.6666},
  {x:1557.0000,y:220.0000},
  {x:1555.0002,y:219.3334},
  {x:1552.9998,y:218.6666},
  {x:1551.0000,y:218.0000},
  {x:1550.6667,y:217.3334},
  {x:1550.3333,y:216.6666},
  {x:1550.0000,y:216.0000},
  {x:1548.6668,y:215.6667},
  {x:1547.3332,y:215.3333},
  {x:1546.0000,y:215.0000},
  {x:1546.0000,y:214.6667},
  {x:1546.0000,y:214.3333},
  {x:1546.0000,y:214.0000},
  {x:1545.3334,y:214.0000},
  {x:1544.6666,y:214.0000},
  {x:1544.0000,y:214.0000},
  {x:1543.6667,y:213.3334},
  {x:1543.3333,y:212.6666},
  {x:1543.0000,y:212.0000},
  {x:1541.0002,y:211.3334},
  {x:1538.9998,y:210.6666},
  {x:1537.0000,y:210.0000},
  {x:1536.6667,y:209.3334},
  {x:1536.3333,y:208.6666},
  {x:1536.0000,y:208.0000},
  {x:1534.0002,y:207.3334},
  {x:1531.9998,y:206.6666},
  {x:1530.0000,y:206.0000},
  {x:1529.6667,y:205.3334},
  {x:1529.3333,y:204.6666},
  {x:1529.0000,y:204.0000},
  {x:1528.3334,y:204.0000},
  {x:1527.6666,y:204.0000},
  {x:1527.0000,y:204.0000},
  {x:1527.0000,y:203.6667},
  {x:1527.0000,y:203.3333},
  {x:1527.0000,y:203.0000},
  {x:1526.3334,y:203.0000},
  {x:1525.6666,y:203.0000},
  {x:1525.0000,y:203.0000},
  {x:1524.6667,y:202.3334},
  {x:1524.3333,y:201.6666},
  {x:1524.0000,y:201.0000},
  {x:1522.0002,y:200.3334},
  {x:1519.9998,y:199.6666},
  {x:1518.0000,y:199.0000},
  {x:1517.6667,y:198.3334},
  {x:1517.3333,y:197.6666},
  {x:1517.0000,y:197.0000},
  {x:1515.6668,y:196.6667},
  {x:1514.3332,y:196.3333},
  {x:1513.0000,y:196.0000},
  {x:1512.6667,y:195.3334},
  {x:1512.3333,y:194.6666},
  {x:1512.0000,y:194.0000},
  {x:1510.6668,y:193.6667},
  {x:1509.3332,y:193.3333},
  {x:1508.0000,y:193.0000},
  {x:1507.6667,y:192.3334},
  {x:1507.3333,y:191.6666},
  {x:1507.0000,y:191.0000},
  {x:1505.0002,y:190.3334},
  {x:1502.9998,y:189.6666},
  {x:1501.0000,y:189.0000},
  {x:1500.6667,y:188.3334},
  {x:1500.3333,y:187.6666},
  {x:1500.0000,y:187.0000},
  {x:1499.3334,y:187.0000},
  {x:1498.6666,y:187.0000},
  {x:1498.0000,y:187.0000},
  {x:1498.0000,y:186.6667},
  {x:1498.0000,y:186.3333},
  {x:1498.0000,y:186.0000},
  {x:1497.3334,y:186.0000},
  {x:1496.6666,y:186.0000},
  {x:1496.0000,y:186.0000},
  {x:1495.3334,y:185.0001},
  {x:1494.6666,y:183.9999},
  {x:1494.0000,y:183.0000},
  {x:1493.3334,y:183.0000},
  {x:1492.6666,y:183.0000},
  {x:1492.0000,y:183.0000},
  {x:1491.6667,y:182.3334},
  {x:1491.3333,y:181.6666},
  {x:1491.0000,y:181.0000},
  {x:1490.3334,y:181.0000},
  {x:1489.6666,y:181.0000},
  {x:1489.0000,y:181.0000},
  {x:1488.3334,y:180.0001},
  {x:1487.6666,y:178.9999},
  {x:1487.0000,y:178.0000},
  {x:1486.3334,y:178.0000},
  {x:1485.6666,y:178.0000},
  {x:1485.0000,y:178.0000},
  {x:1485.0000,y:177.6667},
  {x:1485.0000,y:177.3333},
  {x:1485.0000,y:177.0000},
  {x:1484.0001,y:176.6667},
  {x:1482.9999,y:176.3333},
  {x:1482.0000,y:176.0000},
  {x:1481.6667,y:175.3334},
  {x:1481.3333,y:174.6666},
  {x:1481.0000,y:174.0000},
  {x:1480.3334,y:174.0000},
  {x:1479.6666,y:174.0000},
  {x:1479.0000,y:174.0000},
  {x:1478.3334,y:173.0001},
  {x:1477.6666,y:171.9999},
  {x:1477.0000,y:171.0000},
  {x:1476.3334,y:171.0000},
  {x:1475.6666,y:171.0000},
  {x:1475.0000,y:171.0000},
  {x:1474.0001,y:169.6668},
  {x:1472.9999,y:168.3332},
  {x:1472.0000,y:167.0000},
  {x:1470.0002,y:166.6667},
  {x:1467.9998,y:166.3333},
  {x:1466.0000,y:166.0000},
  {x:1466.0000,y:165.6667},
  {x:1466.0000,y:165.3333},
  {x:1466.0000,y:165.0000},
  {x:1464.6668,y:164.6667},
  {x:1463.3332,y:164.3333},
  {x:1462.0000,y:164.0000},
  {x:1462.0000,y:163.6667},
  {x:1462.0000,y:163.3333},
  {x:1462.0000,y:163.0000},
  {x:1460.6668,y:162.6667},
  {x:1459.3332,y:162.3333},
  {x:1458.0000,y:162.0000},
  {x:1457.6667,y:161.3334},
  {x:1457.3333,y:160.6666},
  {x:1457.0000,y:160.0000},
  {x:1455.6668,y:159.6667},
  {x:1454.3332,y:159.3333},
  {x:1453.0000,y:159.0000},
  {x:1452.6667,y:158.3334},
  {x:1452.3333,y:157.6666},
  {x:1452.0000,y:157.0000},
  {x:1451.3334,y:157.0000},
  {x:1450.6666,y:157.0000},
  {x:1450.0000,y:157.0000},
  {x:1449.6667,y:156.3334},
  {x:1449.3333,y:155.6666},
  {x:1449.0000,y:155.0000},
  {x:1448.3334,y:155.0000},
  {x:1447.6666,y:155.0000},
  {x:1447.0000,y:155.0000},
  {x:1446.3334,y:154.0001},
  {x:1445.6666,y:152.9999},
  {x:1445.0000,y:152.0000},
  {x:1444.3334,y:152.0000},
  {x:1443.6666,y:152.0000},
  {x:1443.0000,y:152.0000},
  {x:1442.0001,y:150.6668},
  {x:1440.9999,y:149.3332},
  {x:1440.0000,y:148.0000},
  {x:1439.3334,y:148.0000},
  {x:1438.6666,y:148.0000},
  {x:1438.0000,y:148.0000},
  {x:1436.6668,y:146.3335},
  {x:1435.3332,y:144.6665},
  {x:1434.0000,y:143.0000},
  {x:1433.3334,y:143.0000},
  {x:1432.6666,y:143.0000},
  {x:1432.0000,y:143.0000},
  {x:1427.6671,y:138.3338},
  {x:1423.3329,y:133.6662},
  {x:1419.0000,y:129.0000},
  {x:1415.0004,y:125.3337},
  {x:1410.9996,y:121.6663},
  {x:1407.0000,y:118.0000},
  {x:1407.0000,y:117.3334},
  {x:1407.0000,y:116.6666},
  {x:1407.0000,y:116.0000},
  {x:1405.3335,y:114.6668},
  {x:1403.6665,y:113.3332},
  {x:1402.0000,y:112.0000},
  {x:1399.0003,y:108.6670},
  {x:1395.9997,y:105.3330},
  {x:1393.0000,y:102.0000},
  {x:1392.3334,y:102.0000},
  {x:1391.6666,y:102.0000},
  {x:1391.0000,y:102.0000},
  {x:1388.6669,y:99.3336},
  {x:1386.3331,y:96.6664},
  {x:1384.0000,y:94.0000},
  {x:1383.3334,y:94.0000},
  {x:1382.6666,y:94.0000},
  {x:1382.0000,y:94.0000},
  {x:1380.6668,y:92.3335},
  {x:1379.3332,y:90.6665},
  {x:1378.0000,y:89.0000},
  {x:1377.3334,y:89.0000},
  {x:1376.6666,y:89.0000},
  {x:1376.0000,y:89.0000},
  {x:1375.6667,y:88.3334},
  {x:1375.3333,y:87.6666},
  {x:1375.0000,y:87.0000},
  {x:1374.6667,y:87.0000},
  {x:1374.3333,y:87.0000},
  {x:1374.0000,y:87.0000},
  {x:1373.6667,y:86.3334},
  {x:1373.3333,y:85.6666},
  {x:1373.0000,y:85.0000},
  {x:1372.3334,y:85.0000},
  {x:1371.6666,y:85.0000},
  {x:1371.0000,y:85.0000},
  {x:1370.3334,y:84.0001},
  {x:1369.6666,y:82.9999},
  {x:1369.0000,y:82.0000},
  {x:1368.0001,y:81.6667},
  {x:1366.9999,y:81.3333},
  {x:1366.0000,y:81.0000},
  {x:1365.6667,y:80.3334},
  {x:1365.3333,y:79.6666},
  {x:1365.0000,y:79.0000},
  {x:1364.3334,y:79.0000},
  {x:1363.6666,y:79.0000},
  {x:1363.0000,y:79.0000},
  {x:1362.6667,y:78.3334},
  {x:1362.3333,y:77.6666},
  {x:1362.0000,y:77.0000},
  {x:1361.3334,y:77.0000},
  {x:1360.6666,y:77.0000},
  {x:1360.0000,y:77.0000},
  {x:1359.3334,y:76.0001},
  {x:1358.6666,y:74.9999},
  {x:1358.0000,y:74.0000},
  {x:1357.3334,y:74.0000},
  {x:1356.6666,y:74.0000},
  {x:1356.0000,y:74.0000},
  {x:1355.6667,y:73.3334},
  {x:1355.3333,y:72.6666},
  {x:1355.0000,y:72.0000},
  {x:1354.0001,y:71.6667},
  {x:1352.9999,y:71.3333},
  {x:1352.0000,y:71.0000},
  {x:1352.0000,y:70.6667},
  {x:1352.0000,y:70.3333},
  {x:1352.0000,y:70.0000},
  {x:1351.3334,y:70.0000},
  {x:1350.6666,y:70.0000},
  {x:1350.0000,y:70.0000},
  {x:1349.6667,y:69.3334},
  {x:1349.3333,y:68.6666},
  {x:1349.0000,y:68.0000},
  {x:1348.3334,y:68.0000},
  {x:1347.6666,y:68.0000},
  {x:1347.0000,y:68.0000},
  {x:1346.6667,y:67.3334},
  {x:1346.3333,y:66.6666},
  {x:1346.0000,y:66.0000},
  {x:1345.3334,y:66.0000},
  {x:1344.6666,y:66.0000},
  {x:1344.0000,y:66.0000},
  {x:1343.6667,y:65.3334},
  {x:1343.3333,y:64.6666},
  {x:1343.0000,y:64.0000},
  {x:1342.3334,y:64.0000},
  {x:1341.6666,y:64.0000},
  {x:1341.0000,y:64.0000},
  {x:1340.6667,y:63.3334},
  {x:1340.3333,y:62.6666},
  {x:1340.0000,y:62.0000},
  {x:1339.3334,y:62.0000},
  {x:1338.6666,y:62.0000},
  {x:1338.0000,y:62.0000},
  {x:1337.6667,y:61.3334},
  {x:1337.3333,y:60.6666},
  {x:1337.0000,y:60.0000},
  {x:1336.3334,y:60.0000},
  {x:1335.6666,y:60.0000},
  {x:1335.0000,y:60.0000},
  {x:1334.6667,y:59.3334},
  {x:1334.3333,y:58.6666},
  {x:1334.0000,y:58.0000},
  {x:1332.6668,y:57.6667},
  {x:1331.3332,y:57.3333},
  {x:1330.0000,y:57.0000},
  {x:1330.0000,y:56.6667},
  {x:1330.0000,y:56.3333},
  {x:1330.0000,y:56.0000},
  {x:1329.0001,y:55.6667},
  {x:1327.9999,y:55.3333},
  {x:1327.0000,y:55.0000},
  {x:1326.6667,y:54.3334},
  {x:1326.3333,y:53.6666},
  {x:1326.0000,y:53.0000},
  {x:1325.3334,y:53.0000},
  {x:1324.6666,y:53.0000},
  {x:1324.0000,y:53.0000},
  {x:1324.0000,y:52.6667},
  {x:1324.0000,y:52.3333},
  {x:1324.0000,y:52.0000},
  {x:1323.3334,y:52.0000},
  {x:1322.6666,y:52.0000},
  {x:1322.0000,y:52.0000},
  {x:1321.6667,y:51.3334},
  {x:1321.3333,y:50.6666},
  {x:1321.0000,y:50.0000},
  {x:1319.0002,y:49.3334},
  {x:1316.9998,y:48.6666},
  {x:1315.0000,y:48.0000},
  {x:1314.6667,y:47.3334},
  {x:1314.3333,y:46.6666},
  {x:1314.0000,y:46.0000},
  {x:1312.0002,y:45.3334},
  {x:1309.9998,y:44.6666},
  {x:1308.0000,y:44.0000},
  {x:1307.6667,y:43.3334},
  {x:1307.3333,y:42.6666},
  {x:1307.0000,y:42.0000},
  {x:1305.0002,y:41.3334},
  {x:1302.9998,y:40.6666},
  {x:1301.0000,y:40.0000},
  {x:1300.6667,y:39.3334},
  {x:1300.3333,y:38.6666},
  {x:1300.0000,y:38.0000},
  {x:1299.3334,y:38.0000},
  {x:1298.6666,y:38.0000},
  {x:1298.0000,y:38.0000},
  {x:1298.0000,y:37.6667},
  {x:1298.0000,y:37.3333},
  {x:1298.0000,y:37.0000},
  {x:1297.3334,y:37.0000},
  {x:1296.6666,y:37.0000},
  {x:1296.0000,y:37.0000},
  {x:1296.0000,y:36.6667},
  {x:1296.0000,y:36.3333},
  {x:1296.0000,y:36.0000},
  {x:1295.3334,y:36.0000},
  {x:1294.6666,y:36.0000},
  {x:1294.0000,y:36.0000},
  {x:1294.0000,y:35.6667},
  {x:1294.0000,y:35.3333},
  {x:1294.0000,y:35.0000},
  {x:1293.3334,y:35.0000},
  {x:1292.6666,y:35.0000},
  {x:1292.0000,y:35.0000},
  {x:1292.0000,y:34.6667},
  {x:1292.0000,y:34.3333},
  {x:1292.0000,y:34.0000},
  {x:1291.3334,y:34.0000},
  {x:1290.6666,y:34.0000},
  {x:1290.0000,y:34.0000},
  {x:1289.6667,y:33.3334},
  {x:1289.3333,y:32.6666},
  {x:1289.0000,y:32.0000},
  {x:1288.3334,y:32.0000},
  {x:1287.6666,y:32.0000},
  {x:1287.0000,y:32.0000},
  {x:1287.0000,y:31.6667},
  {x:1287.0000,y:31.3333},
  {x:1287.0000,y:31.0000},
  {x:1286.3334,y:31.0000},
  {x:1285.6666,y:31.0000},
  {x:1285.0000,y:31.0000},
  {x:1285.0000,y:30.6667},
  {x:1285.0000,y:30.3333},
  {x:1285.0000,y:30.0000},
  {x:1284.3334,y:30.0000},
  {x:1283.6666,y:30.0000},
  {x:1283.0000,y:30.0000},
  {x:1283.0000,y:29.6667},
  {x:1283.0000,y:29.3333},
  {x:1283.0000,y:29.0000},
  {x:1282.3334,y:29.0000},
  {x:1281.6666,y:29.0000},
  {x:1281.0000,y:29.0000},
  {x:1281.0000,y:28.6667},
  {x:1281.0000,y:28.3333},
  {x:1281.0000,y:28.0000},
  {x:1280.3334,y:28.0000},
  {x:1279.6666,y:28.0000},
  {x:1279.0000,y:28.0000},
  {x:1278.6667,y:27.3334},
  {x:1278.3333,y:26.6666},
  {x:1278.0000,y:26.0000},
  {x:1276.0002,y:25.3334},
  {x:1273.9998,y:24.6666},
  {x:1272.0000,y:24.0000},
  {x:1272.0000,y:23.6667},
  {x:1272.0000,y:23.3333},
  {x:1272.0000,y:23.0000},
  {x:1271.3334,y:23.0000},
  {x:1270.6666,y:23.0000},
  {x:1270.0000,y:23.0000},
  {x:1270.0000,y:22.6667},
  {x:1270.0000,y:22.3333},
  {x:1270.0000,y:22.0000},
  {x:1269.3334,y:22.0000},
  {x:1268.6666,y:22.0000},
  {x:1268.0000,y:22.0000},
  {x:1268.0000,y:21.6667},
  {x:1268.0000,y:21.3333},
  {x:1268.0000,y:21.0000},
  {x:1266.0002,y:20.3334},
  {x:1263.9998,y:19.6666},
  {x:1262.0000,y:19.0000},
  {x:1262.0000,y:18.6667},
  {x:1262.0000,y:18.3333},
  {x:1262.0000,y:18.0000},
  {x:1261.0001,y:18.0000},
  {x:1259.9999,y:18.0000},
  {x:1259.0000,y:18.0000},
  {x:1259.0000,y:17.6667},
  {x:1259.0000,y:17.3333},
  {x:1259.0000,y:17.0000},
  {x:1258.3334,y:17.0000},
  {x:1257.6666,y:17.0000},
  {x:1257.0000,y:17.0000},
  {x:1257.0000,y:16.6667},
  {x:1257.0000,y:16.3333},
  {x:1257.0000,y:16.0000},
  {x:1255.0002,y:15.6667},
  {x:1252.9998,y:15.3333},
  {x:1251.0000,y:15.0000},
  {x:1251.0000,y:14.6667},
  {x:1251.0000,y:14.3333},
  {x:1251.0000,y:14.0000},
  {x:1250.0001,y:14.0000},
  {x:1248.9999,y:14.0000},
  {x:1248.0000,y:14.0000},
  {x:1248.0000,y:13.6667},
  {x:1248.0000,y:13.3333},
  {x:1248.0000,y:13.0000},
  {x:1247.0001,y:13.0000},
  {x:1245.9999,y:13.0000},
  {x:1245.0000,y:13.0000},
  {x:1245.0000,y:12.6667},
  {x:1245.0000,y:12.3333},
  {x:1245.0000,y:12.0000},
  {x:1242.6669,y:11.6667},
  {x:1240.3331,y:11.3333},
  {x:1238.0000,y:11.0000},
  {x:1238.0000,y:10.6667},
  {x:1238.0000,y:10.3333},
  {x:1238.0000,y:10.0000},
  {x:1236.3335,y:10.0000},
  {x:1234.6665,y:10.0000},
  {x:1233.0000,y:10.0000},
  {x:1229.4291,y:8.9015},
  {x:1218.4274,y:5.2417},
  {x:1213.0000,y:7.0000},
  {x:1213.0000,y:7.3333},
  {x:1213.0000,y:7.6667},
  {x:1213.0000,y:8.0000},
  {x:1212.0001,y:8.0000},
  {x:1210.9999,y:8.0000},
  {x:1210.0000,y:8.0000},
  {x:1210.0000,y:8.3333},
  {x:1210.0000,y:8.6667},
  {x:1210.0000,y:9.0000},
  {x:1209.3334,y:9.0000},
  {x:1208.6666,y:9.0000},
  {x:1208.0000,y:9.0000},
  {x:1208.0000,y:9.3333},
  {x:1208.0000,y:9.6667},
  {x:1208.0000,y:10.0000},
  {x:1207.0001,y:10.0000},
  {x:1205.9999,y:10.0000},
  {x:1205.0000,y:10.0000},
  {x:1204.6667,y:10.6666},
  {x:1204.3333,y:11.3334},
  {x:1204.0000,y:12.0000},
  {x:1202.6668,y:12.3333},
  {x:1201.3332,y:12.6667},
  {x:1200.0000,y:13.0000},
  {x:1199.6667,y:13.6666},
  {x:1199.3333,y:14.3334},
  {x:1199.0000,y:15.0000},
  {x:1198.3334,y:15.0000},
  {x:1197.6666,y:15.0000},
  {x:1197.0000,y:15.0000},
  {x:1196.6667,y:15.6666},
  {x:1196.3333,y:16.3334},
  {x:1196.0000,y:17.0000},
  {x:1195.3334,y:17.0000},
  {x:1194.6666,y:17.0000},
  {x:1194.0000,y:17.0000},
  {x:1193.0001,y:18.3332},
  {x:1191.9999,y:19.6668},
  {x:1191.0000,y:21.0000},
  {x:1190.0001,y:21.3333},
  {x:1188.9999,y:21.6667},
  {x:1188.0000,y:22.0000},
  {x:1186.0002,y:24.3331},
  {x:1183.9998,y:26.6669},
  {x:1182.0000,y:29.0000},
  {x:1179.0003,y:31.6664},
  {x:1175.9997,y:34.3336},
  {x:1173.0000,y:37.0000},
  {x:1173.0000,y:37.6666},
  {x:1173.0000,y:38.3334},
  {x:1173.0000,y:39.0000},
  {x:1170.6669,y:40.9998},
  {x:1168.3331,y:43.0002},
  {x:1166.0000,y:45.0000},
  {x:1166.0000,y:45.6666},
  {x:1166.0000,y:46.3334},
  {x:1166.0000,y:47.0000},
  {x:1165.6667,y:47.0000},
  {x:1165.3333,y:47.0000},
  {x:1165.0000,y:47.0000},
  {x:1164.3334,y:47.9999},
  {x:1163.6666,y:49.0001},
  {x:1163.0000,y:50.0000},
  {x:1162.0001,y:50.6666},
  {x:1160.9999,y:51.3334},
  {x:1160.0000,y:52.0000},
  {x:1160.0000,y:52.6666},
  {x:1160.0000,y:53.3334},
  {x:1160.0000,y:54.0000},
  {x:1158.0002,y:55.6665},
  {x:1155.9998,y:57.3335},
  {x:1154.0000,y:59.0000},
  {x:1154.0000,y:59.6666},
  {x:1154.0000,y:60.3334},
  {x:1154.0000,y:61.0000},
  {x:1152.3335,y:62.3332},
  {x:1150.6665,y:63.6668},
  {x:1149.0000,y:65.0000},
  {x:1149.0000,y:65.6666},
  {x:1149.0000,y:66.3334},
  {x:1149.0000,y:67.0000},
  {x:1147.0002,y:68.6665},
  {x:1144.9998,y:70.3335},
  {x:1143.0000,y:72.0000},
  {x:1143.0000,y:72.6666},
  {x:1143.0000,y:73.3334},
  {x:1143.0000,y:74.0000},
  {x:1140.6669,y:75.9998},
  {x:1138.3331,y:78.0002},
  {x:1136.0000,y:80.0000},
  {x:1136.0000,y:80.6666},
  {x:1136.0000,y:81.3334},
  {x:1136.0000,y:82.0000},
  {x:1133.6669,y:83.9998},
  {x:1131.3331,y:86.0002},
  {x:1129.0000,y:88.0000},
  {x:1129.0000,y:88.6666},
  {x:1129.0000,y:89.3334},
  {x:1129.0000,y:90.0000},
  {x:1126.0003,y:92.6664},
  {x:1122.9997,y:95.3336},
  {x:1120.0000,y:98.0000},
  {x:1112.2513,y:108.7055},
  {x:1102.2136,y:116.7896},
  {x:1093.0000,y:126.0000},
  {x:1089.3337,y:129.9996},
  {x:1085.6663,y:134.0004},
  {x:1082.0000,y:138.0000},
  {x:1081.0001,y:138.3333},
  {x:1079.9999,y:138.6667},
  {x:1079.0000,y:139.0000},
  {x:1077.0002,y:141.3331},
  {x:1074.9998,y:143.6669},
  {x:1073.0000,y:146.0000},
  {x:1072.3334,y:146.0000},
  {x:1071.6666,y:146.0000},
  {x:1071.0000,y:146.0000},
  {x:1069.3335,y:147.9998},
  {x:1067.6665,y:150.0002},
  {x:1066.0000,y:152.0000},
  {x:1065.3334,y:152.0000},
  {x:1064.6666,y:152.0000},
  {x:1064.0000,y:152.0000},
  {x:1062.3335,y:153.9998},
  {x:1060.6665,y:156.0002},
  {x:1059.0000,y:158.0000},
  {x:1058.3334,y:158.0000},
  {x:1057.6666,y:158.0000},
  {x:1057.0000,y:158.0000},
  {x:1056.0001,y:159.3332},
  {x:1054.9999,y:160.6668},
  {x:1054.0000,y:162.0000},
  {x:1053.3334,y:162.0000},
  {x:1052.6666,y:162.0000},
  {x:1052.0000,y:162.0000},
  {x:1051.0001,y:163.3332},
  {x:1049.9999,y:164.6668},
  {x:1049.0000,y:166.0000},
  {x:1048.3334,y:166.0000},
  {x:1047.6666,y:166.0000},
  {x:1047.0000,y:166.0000},
  {x:1046.3334,y:166.9999},
  {x:1045.6666,y:168.0001},
  {x:1045.0000,y:169.0000},
  {x:1044.3334,y:169.0000},
  {x:1043.6666,y:169.0000},
  {x:1043.0000,y:169.0000},
  {x:1042.0001,y:170.3332},
  {x:1040.9999,y:171.6668},
  {x:1040.0000,y:173.0000},
  {x:1039.3334,y:173.0000},
  {x:1038.6666,y:173.0000},
  {x:1038.0000,y:173.0000},
  {x:1037.3334,y:173.9999},
  {x:1036.6666,y:175.0001},
  {x:1036.0000,y:176.0000},
  {x:1035.0001,y:176.3333},
  {x:1033.9999,y:176.6667},
  {x:1033.0000,y:177.0000},
  {x:1032.6667,y:177.6666},
  {x:1032.3333,y:178.3334},
  {x:1032.0000,y:179.0000},
  {x:1031.3334,y:179.0000},
  {x:1030.6666,y:179.0000},
  {x:1030.0000,y:179.0000},
  {x:1029.6667,y:179.6666},
  {x:1029.3333,y:180.3334},
  {x:1029.0000,y:181.0000},
  {x:1028.3334,y:181.0000},
  {x:1027.6666,y:181.0000},
  {x:1027.0000,y:181.0000},
  {x:1026.3334,y:181.9999},
  {x:1025.6666,y:183.0001},
  {x:1025.0000,y:184.0000},
  {x:1024.3334,y:184.0000},
  {x:1023.6666,y:184.0000},
  {x:1023.0000,y:184.0000},
  {x:1022.6667,y:184.6666},
  {x:1022.3333,y:185.3334},
  {x:1022.0000,y:186.0000},
  {x:1021.3334,y:186.0000},
  {x:1020.6666,y:186.0000},
  {x:1020.0000,y:186.0000},
  {x:1019.3334,y:186.9999},
  {x:1018.6666,y:188.0001},
  {x:1018.0000,y:189.0000},
  {x:1017.3334,y:189.0000},
  {x:1016.6666,y:189.0000},
  {x:1016.0000,y:189.0000},
  {x:1015.6667,y:189.6666},
  {x:1015.3333,y:190.3334},
  {x:1015.0000,y:191.0000},
  {x:1014.0001,y:191.3333},
  {x:1012.9999,y:191.6667},
  {x:1012.0000,y:192.0000},
  {x:1012.0000,y:192.3333},
  {x:1012.0000,y:192.6667},
  {x:1012.0000,y:193.0000},
  {x:1011.3334,y:193.0000},
  {x:1010.6666,y:193.0000},
  {x:1010.0000,y:193.0000},
  {x:1009.6667,y:193.6666},
  {x:1009.3333,y:194.3334},
  {x:1009.0000,y:195.0000},
  {x:1008.3334,y:195.0000},
  {x:1007.6666,y:195.0000},
  {x:1007.0000,y:195.0000},
  {x:1006.6667,y:195.6666},
  {x:1006.3333,y:196.3334},
  {x:1006.0000,y:197.0000},
  {x:1005.3334,y:197.0000},
  {x:1004.6666,y:197.0000},
  {x:1004.0000,y:197.0000},
  {x:1003.6667,y:197.6666},
  {x:1003.3333,y:198.3334},
  {x:1003.0000,y:199.0000},
  {x:1002.3334,y:199.0000},
  {x:1001.6666,y:199.0000},
  {x:1001.0000,y:199.0000},
  {x:1000.6667,y:199.6666},
  {x:1000.3333,y:200.3334},
  {x:1000.0000,y:201.0000},
  {x:999.3334,y:201.0000},
  {x:998.6666,y:201.0000},
  {x:998.0000,y:201.0000},
  {x:997.6667,y:201.6666},
  {x:997.3333,y:202.3334},
  {x:997.0000,y:203.0000},
  {x:996.3334,y:203.0000},
  {x:995.6666,y:203.0000},
  {x:995.0000,y:203.0000},
  {x:995.0000,y:203.3333},
  {x:995.0000,y:203.6667},
  {x:995.0000,y:204.0000},
  {x:994.3334,y:204.0000},
  {x:993.6666,y:204.0000},
  {x:993.0000,y:204.0000},
  {x:992.6667,y:204.6666},
  {x:992.3333,y:205.3334},
  {x:992.0000,y:206.0000},
  {x:991.3334,y:206.0000},
  {x:990.6666,y:206.0000},
  {x:990.0000,y:206.0000},
  {x:989.6667,y:206.6666},
  {x:989.3333,y:207.3334},
  {x:989.0000,y:208.0000},
  {x:987.6668,y:208.3333},
  {x:986.3332,y:208.6667},
  {x:985.0000,y:209.0000},
  {x:985.0000,y:209.3333},
  {x:985.0000,y:209.6667},
  {x:985.0000,y:210.0000},
  {x:984.0001,y:210.3333},
  {x:982.9999,y:210.6667},
  {x:982.0000,y:211.0000},
  {x:981.6667,y:211.6666},
  {x:981.3333,y:212.3334},
  {x:981.0000,y:213.0000},
  {x:979.6668,y:213.3333},
  {x:978.3332,y:213.6667},
  {x:977.0000,y:214.0000},
  {x:976.6667,y:214.6666},
  {x:976.3333,y:215.3334},
  {x:976.0000,y:216.0000},
  {x:974.6668,y:216.3333},
  {x:973.3332,y:216.6667},
  {x:972.0000,y:217.0000},
  {x:971.6667,y:217.6666},
  {x:971.3333,y:218.3334},
  {x:971.0000,y:219.0000},
  {x:969.0002,y:219.6666},
  {x:966.9998,y:220.3334},
  {x:965.0000,y:221.0000},
  {x:923.5223,y:247.1200},
  {x:875.9535,y:267.3878},
  {x:829.0000,y:288.0000},
  {x:808.0021,y:294.9993},
  {x:786.9979,y:302.0007},
  {x:766.0000,y:309.0000},
  {x:750.6682,y:312.3330},
  {x:735.3318,y:315.6670},
  {x:720.0000,y:319.0000},
  {x:715.6671,y:319.3333},
  {x:711.3329,y:319.6667},
  {x:707.0000,y:320.0000},
  {x:697.5536,y:322.7376},
  {x:684.6079,y:322.2196},
  {x:675.0000,y:325.0000},
  {x:671.6670,y:325.0000},
  {x:668.3330,y:325.0000},
  {x:665.0000,y:325.0000},
  {x:665.0000,y:325.3333},
  {x:665.0000,y:325.6667},
  {x:665.0000,y:326.0000},
  {x:660.3338,y:326.0000},
  {x:655.6662,y:326.0000},
  {x:651.0000,y:326.0000},
  {x:651.0000,y:326.3333},
  {x:651.0000,y:326.6667},
  {x:651.0000,y:327.0000},
  {x:641.3343,y:327.0000},
  {x:631.6657,y:327.0000},
  {x:622.0000,y:327.0000},
  {x:622.0000,y:327.3333},
  {x:622.0000,y:327.6667},
  {x:622.0000,y:328.0000},
  {x:618.3546,y:329.0425},
  {x:613.2011,y:327.3564},
  {x:611.0000,y:327.0000},
  {x:600.3675,y:325.2785},
  {x:589.8890,y:327.6229},
  {x:581.0000,y:325.0000},
  {x:578.3336,y:325.0000},
  {x:575.6664,y:325.0000},
  {x:573.0000,y:325.0000},
  {x:567.0354,y:323.2514},
  {x:558.7485,y:322.7567},
  {x:553.0000,y:321.0000},
  {x:551.0002,y:321.0000},
  {x:548.9998,y:321.0000},
  {x:547.0000,y:321.0000},
  {x:547.0000,y:320.6667},
  {x:547.0000,y:320.3333},
  {x:547.0000,y:320.0000},
  {x:545.0002,y:320.0000},
  {x:542.9998,y:320.0000},
  {x:541.0000,y:320.0000},
  {x:541.0000,y:319.6667},
  {x:541.0000,y:319.3333},
  {x:541.0000,y:319.0000},
  {x:539.0002,y:319.0000},
  {x:536.9998,y:319.0000},
  {x:535.0000,y:319.0000},
  {x:535.0000,y:318.6667},
  {x:535.0000,y:318.3333},
  {x:535.0000,y:318.0000},
  {x:533.0002,y:318.0000},
  {x:530.9998,y:318.0000},
  {x:529.0000,y:318.0000},
  {x:529.0000,y:317.6667},
  {x:529.0000,y:317.3333},
  {x:529.0000,y:317.0000},
  {x:527.3335,y:317.0000},
  {x:525.6665,y:317.0000},
  {x:524.0000,y:317.0000},
  {x:524.0000,y:316.6667},
  {x:524.0000,y:316.3333},
  {x:524.0000,y:316.0000},
  {x:522.0002,y:316.0000},
  {x:519.9998,y:316.0000},
  {x:518.0000,y:316.0000},
  {x:505.6878,y:312.0970},
  {x:493.1397,y:307.9503},
  {x:482.0000,y:303.0000},
  {x:476.6672,y:301.3335},
  {x:471.3328,y:299.6665},
  {x:466.0000,y:298.0000},
  {x:466.0000,y:297.6667},
  {x:466.0000,y:297.3333},
  {x:466.0000,y:297.0000},
  {x:464.6668,y:296.6667},
  {x:463.3332,y:296.3333},
  {x:462.0000,y:296.0000},
  {x:462.0000,y:295.6667},
  {x:462.0000,y:295.3333},
  {x:462.0000,y:295.0000},
  {x:461.0001,y:295.0000},
  {x:459.9999,y:295.0000},
  {x:459.0000,y:295.0000},
  {x:459.0000,y:294.6667},
  {x:459.0000,y:294.3333},
  {x:459.0000,y:294.0000},
  {x:458.3334,y:294.0000},
  {x:457.6666,y:294.0000},
  {x:457.0000,y:294.0000},
  {x:457.0000,y:293.6667},
  {x:457.0000,y:293.3333},
  {x:457.0000,y:293.0000},
  {x:456.0001,y:293.0000},
  {x:454.9999,y:293.0000},
  {x:454.0000,y:293.0000},
  {x:454.0000,y:292.6667},
  {x:454.0000,y:292.3333},
  {x:454.0000,y:292.0000},
  {x:452.6668,y:291.6667},
  {x:451.3332,y:291.3333},
  {x:450.0000,y:291.0000},
  {x:450.0000,y:290.6667},
  {x:450.0000,y:290.3333},
  {x:450.0000,y:290.0000},
  {x:449.3334,y:290.0000},
  {x:448.6666,y:290.0000},
  {x:448.0000,y:290.0000},
  {x:448.0000,y:289.6667},
  {x:448.0000,y:289.3333},
  {x:448.0000,y:289.0000},
  {x:447.0001,y:289.0000},
  {x:445.9999,y:289.0000},
  {x:445.0000,y:289.0000},
  {x:445.0000,y:288.6667},
  {x:445.0000,y:288.3333},
  {x:445.0000,y:288.0000},
  {x:443.0002,y:287.3334},
  {x:440.9998,y:286.6666},
  {x:439.0000,y:286.0000},
  {x:439.0000,y:285.6667},
  {x:439.0000,y:285.3333},
  {x:439.0000,y:285.0000},
  {x:437.3335,y:284.6667},
  {x:435.6665,y:284.3333},
  {x:434.0000,y:284.0000},
  {x:434.0000,y:283.6667},
  {x:434.0000,y:283.3333},
  {x:434.0000,y:283.0000},
  {x:433.3334,y:283.0000},
  {x:432.6666,y:283.0000},
  {x:432.0000,y:283.0000},
  {x:432.0000,y:282.6667},
  {x:432.0000,y:282.3333},
  {x:432.0000,y:282.0000},
  {x:430.6668,y:281.6667},
  {x:429.3332,y:281.3333},
  {x:428.0000,y:281.0000},
  {x:428.0000,y:280.6667},
  {x:428.0000,y:280.3333},
  {x:428.0000,y:280.0000},
  {x:427.3334,y:280.0000},
  {x:426.6666,y:280.0000},
  {x:426.0000,y:280.0000},
  {x:426.0000,y:279.6667},
  {x:426.0000,y:279.3333},
  {x:426.0000,y:279.0000},
  {x:425.3334,y:279.0000},
  {x:424.6666,y:279.0000},
  {x:424.0000,y:279.0000},
  {x:424.0000,y:278.6667},
  {x:424.0000,y:278.3333},
  {x:424.0000,y:278.0000},
  {x:423.3334,y:278.0000},
  {x:422.6666,y:278.0000},
  {x:422.0000,y:278.0000},
  {x:422.0000,y:277.6667},
  {x:422.0000,y:277.3333},
  {x:422.0000,y:277.0000},
  {x:421.3334,y:277.0000},
  {x:420.6666,y:277.0000},
  {x:420.0000,y:277.0000},
  {x:420.0000,y:276.6667},
  {x:420.0000,y:276.3333},
  {x:420.0000,y:276.0000},
  {x:419.3334,y:276.0000},
  {x:418.6666,y:276.0000},
  {x:418.0000,y:276.0000},
  {x:418.0000,y:275.6667},
  {x:418.0000,y:275.3333},
  {x:418.0000,y:275.0000},
  {x:417.3334,y:275.0000},
  {x:416.6666,y:275.0000},
  {x:416.0000,y:275.0000},
  {x:416.0000,y:274.6667},
  {x:416.0000,y:274.3333},
  {x:416.0000,y:274.0000},
  {x:415.3334,y:274.0000},
  {x:414.6666,y:274.0000},
  {x:414.0000,y:274.0000},
  {x:414.0000,y:273.6667},
  {x:414.0000,y:273.3333},
  {x:414.0000,y:273.0000},
  {x:413.3334,y:273.0000},
  {x:412.6666,y:273.0000},
  {x:412.0000,y:273.0000},
  {x:412.0000,y:272.6667},
  {x:412.0000,y:272.3333},
  {x:412.0000,y:272.0000},
  {x:411.3334,y:272.0000},
  {x:410.6666,y:272.0000},
  {x:410.0000,y:272.0000},
  {x:409.6667,y:271.3334},
  {x:409.3333,y:270.6666},
  {x:409.0000,y:270.0000},
  {x:408.3334,y:270.0000},
  {x:407.6666,y:270.0000},
  {x:407.0000,y:270.0000},
  {x:407.0000,y:269.6667},
  {x:407.0000,y:269.3333},
  {x:407.0000,y:269.0000},
  {x:405.6668,y:268.6667},
  {x:404.3332,y:268.3333},
  {x:403.0000,y:268.0000},
  {x:403.0000,y:267.6667},
  {x:403.0000,y:267.3333},
  {x:403.0000,y:267.0000},
  {x:400.8427,y:266.1880},
  {x:399.7997,y:267.0711},
  {x:398.0000,y:266.0000},
  {x:398.0000,y:266.3333},
  {x:398.0000,y:266.6667},
  {x:398.0000,y:267.0000},
  {x:395.6669,y:268.6665},
  {x:393.3331,y:270.3335},
  {x:391.0000,y:272.0000},
  {x:390.6667,y:272.6666},
  {x:390.3333,y:273.3334},
  {x:390.0000,y:274.0000},
  {x:389.3334,y:274.0000},
  {x:388.6666,y:274.0000},
  {x:388.0000,y:274.0000},
  {x:387.3334,y:274.9999},
  {x:386.6666,y:276.0001},
  {x:386.0000,y:277.0000},
  {x:385.3334,y:277.0000},
  {x:384.6666,y:277.0000},
  {x:384.0000,y:277.0000},
  {x:383.6667,y:277.6666},
  {x:383.3333,y:278.3334},
  {x:383.0000,y:279.0000},
  {x:381.6668,y:279.3333},
  {x:380.3332,y:279.6667},
  {x:379.0000,y:280.0000},
  {x:378.6667,y:280.6666},
  {x:378.3333,y:281.3334},
  {x:378.0000,y:282.0000},
  {x:377.0001,y:282.3333},
  {x:375.9999,y:282.6667},
  {x:375.0000,y:283.0000},
  {x:375.0000,y:283.3333},
  {x:375.0000,y:283.6667},
  {x:375.0000,y:284.0000},
  {x:374.3334,y:284.0000},
  {x:373.6666,y:284.0000},
  {x:373.0000,y:284.0000},
  {x:373.0000,y:284.3333},
  {x:373.0000,y:284.6667},
  {x:373.0000,y:285.0000},
  {x:372.3334,y:285.0000},
  {x:371.6666,y:285.0000},
  {x:371.0000,y:285.0000},
  {x:371.0000,y:285.3333},
  {x:371.0000,y:285.6667},
  {x:371.0000,y:286.0000},
  {x:370.3334,y:286.0000},
  {x:369.6666,y:286.0000},
  {x:369.0000,y:286.0000},
  {x:369.0000,y:286.3333},
  {x:369.0000,y:286.6667},
  {x:369.0000,y:287.0000},
  {x:368.3334,y:287.0000},
  {x:367.6666,y:287.0000},
  {x:367.0000,y:287.0000},
  {x:366.6667,y:287.6666},
  {x:366.3333,y:288.3334},
  {x:366.0000,y:289.0000},
  {x:365.3334,y:289.0000},
  {x:364.6666,y:289.0000},
  {x:364.0000,y:289.0000},
  {x:364.0000,y:289.3333},
  {x:364.0000,y:289.6667},
  {x:364.0000,y:290.0000},
  {x:363.3334,y:290.0000},
  {x:362.6666,y:290.0000},
  {x:362.0000,y:290.0000},
  {x:362.0000,y:290.3333},
  {x:362.0000,y:290.6667},
  {x:362.0000,y:291.0000},
  {x:361.3334,y:291.0000},
  {x:360.6666,y:291.0000},
  {x:360.0000,y:291.0000},
  {x:360.0000,y:291.3333},
  {x:360.0000,y:291.6667},
  {x:360.0000,y:292.0000},
  {x:359.3334,y:292.0000},
  {x:358.6666,y:292.0000},
  {x:358.0000,y:292.0000},
  {x:358.0000,y:292.3333},
  {x:358.0000,y:292.6667},
  {x:358.0000,y:293.0000},
  {x:357.3334,y:293.0000},
  {x:356.6666,y:293.0000},
  {x:356.0000,y:293.0000},
  {x:356.0000,y:293.3333},
  {x:356.0000,y:293.6667},
  {x:356.0000,y:294.0000},
  {x:355.3334,y:294.0000},
  {x:354.6666,y:294.0000},
  {x:354.0000,y:294.0000},
  {x:354.0000,y:294.3333},
  {x:354.0000,y:294.6667},
  {x:354.0000,y:295.0000},
  {x:353.3334,y:295.0000},
  {x:352.6666,y:295.0000},
  {x:352.0000,y:295.0000},
  {x:352.0000,y:295.3333},
  {x:352.0000,y:295.6667},
  {x:352.0000,y:296.0000},
  {x:350.0002,y:296.6666},
  {x:347.9998,y:297.3334},
  {x:346.0000,y:298.0000},
  {x:346.0000,y:298.3333},
  {x:346.0000,y:298.6667},
  {x:346.0000,y:299.0000},
  {x:344.3335,y:299.3333},
  {x:342.6665,y:299.6667},
  {x:341.0000,y:300.0000},
  {x:341.0000,y:300.3333},
  {x:341.0000,y:300.6667},
  {x:341.0000,y:301.0000},
  {x:339.6668,y:301.3333},
  {x:338.3332,y:301.6667},
  {x:337.0000,y:302.0000},
  {x:337.0000,y:302.3333},
  {x:337.0000,y:302.6667},
  {x:337.0000,y:303.0000},
  {x:335.0002,y:303.3333},
  {x:332.9998,y:303.6667},
  {x:331.0000,y:304.0000},
  {x:331.0000,y:304.3333},
  {x:331.0000,y:304.6667},
  {x:331.0000,y:305.0000},
  {x:330.3334,y:305.0000},
  {x:329.6666,y:305.0000},
  {x:329.0000,y:305.0000},
  {x:329.0000,y:305.3333},
  {x:329.0000,y:305.6667},
  {x:329.0000,y:306.0000},
  {x:322.0007,y:307.9998},
  {x:314.9993,y:310.0002},
  {x:308.0000,y:312.0000},
  {x:308.0000,y:312.3333},
  {x:308.0000,y:312.6667},
  {x:308.0000,y:313.0000},
  {x:307.3334,y:313.0000},
  {x:306.6666,y:313.0000},
  {x:306.0000,y:313.0000},
  {x:306.0000,y:313.3333},
  {x:306.0000,y:313.6667},
  {x:306.0000,y:314.0000},
  {x:304.0002,y:314.0000},
  {x:301.9998,y:314.0000},
  {x:300.0000,y:314.0000},
  {x:295.5352,y:315.4190},
  {x:289.5365,y:317.5374},
  {x:285.0000,y:319.0000},
  {x:283.3335,y:319.0000},
  {x:281.6665,y:319.0000},
  {x:280.0000,y:319.0000},
  {x:280.0000,y:319.3333},
  {x:280.0000,y:319.6667},
  {x:280.0000,y:320.0000},
  {x:278.6668,y:320.0000},
  {x:277.3332,y:320.0000},
  {x:276.0000,y:320.0000},
  {x:276.0000,y:320.3333},
  {x:276.0000,y:320.6667},
  {x:276.0000,y:321.0000},
  {x:274.0002,y:321.0000},
  {x:271.9998,y:321.0000},
  {x:270.0000,y:321.0000},
  {x:270.0000,y:321.3333},
  {x:270.0000,y:321.6667},
  {x:270.0000,y:322.0000},
  {x:268.3335,y:322.0000},
  {x:266.6665,y:322.0000},
  {x:265.0000,y:322.0000},
  {x:265.0000,y:322.3333},
  {x:265.0000,y:322.6667},
  {x:265.0000,y:323.0000},
  {x:263.0002,y:323.0000},
  {x:260.9998,y:323.0000},
  {x:259.0000,y:323.0000},
  {x:246.1075,y:326.8785},
  {x:229.2030,y:326.1820},
  {x:216.0000,y:330.0000},
  {x:210.3339,y:330.3333},
  {x:204.6661,y:330.6667},
  {x:199.0000,y:331.0000},
  {x:188.6677,y:333.6664},
  {x:178.3323,y:336.3336},
  {x:168.0000,y:339.0000},
  {x:168.0000,y:339.3333},
  {x:168.0000,y:339.6667},
  {x:168.0000,y:340.0000},
  {x:167.3334,y:340.0000},
  {x:166.6666,y:340.0000},
  {x:166.0000,y:340.0000},
  {x:166.0000,y:340.3333},
  {x:166.0000,y:340.6667},
  {x:166.0000,y:341.0000},
  {x:165.0001,y:341.0000},
  {x:163.9999,y:341.0000},
  {x:163.0000,y:341.0000},
  {x:163.0000,y:341.3333},
  {x:163.0000,y:341.6667},
  {x:163.0000,y:342.0000},
  {x:161.0002,y:342.6666},
  {x:158.9998,y:343.3334},
  {x:157.0000,y:344.0000},
  {x:157.0000,y:344.3333},
  {x:157.0000,y:344.6667},
  {x:157.0000,y:345.0000},
  {x:155.0002,y:345.6666},
  {x:152.9998,y:346.3334},
  {x:151.0000,y:347.0000},
  {x:150.6667,y:347.6666},
  {x:150.3333,y:348.3334},
  {x:150.0000,y:349.0000},
  {x:149.3334,y:349.0000},
  {x:148.6666,y:349.0000},
  {x:148.0000,y:349.0000},
  {x:147.6667,y:349.6666},
  {x:147.3333,y:350.3334},
  {x:147.0000,y:351.0000},
  {x:146.3334,y:351.0000},
  {x:145.6666,y:351.0000},
  {x:145.0000,y:351.0000},
  {x:144.0001,y:352.3332},
  {x:142.9999,y:353.6668},
  {x:142.0000,y:355.0000},
  {x:141.3334,y:355.0000},
  {x:140.6666,y:355.0000},
  {x:140.0000,y:355.0000},
  {x:136.0004,y:359.3329},
  {x:131.9996,y:363.6671},
  {x:128.0000,y:368.0000},
  {x:124.3337,y:371.3330},
  {x:120.6663,y:374.6670},
  {x:117.0000,y:378.0000},
  {x:117.0000,y:378.6666},
  {x:117.0000,y:379.3334},
  {x:117.0000,y:380.0000},
  {x:115.0002,y:381.6665},
  {x:112.9998,y:383.3335},
  {x:111.0000,y:385.0000},
  {x:111.0000,y:385.6666},
  {x:111.0000,y:386.3334},
  {x:111.0000,y:387.0000},
  {x:109.3335,y:388.3332},
  {x:107.6665,y:389.6668},
  {x:106.0000,y:391.0000},
  {x:106.0000,y:391.6666},
  {x:106.0000,y:392.3334},
  {x:106.0000,y:393.0000},
  {x:105.0001,y:393.6666},
  {x:103.9999,y:394.3334},
  {x:103.0000,y:395.0000},
  {x:103.0000,y:395.6666},
  {x:103.0000,y:396.3334},
  {x:103.0000,y:397.0000},
  {x:102.0001,y:397.6666},
  {x:100.9999,y:398.3334},
  {x:100.0000,y:399.0000},
  {x:100.0000,y:399.6666},
  {x:100.0000,y:400.3334},
  {x:100.0000,y:401.0000},
  {x:99.0001,y:401.6666},
  {x:97.9999,y:402.3334},
  {x:97.0000,y:403.0000},
  {x:97.0000,y:403.6666},
  {x:97.0000,y:404.3334},
  {x:97.0000,y:405.0000},
  {x:96.0001,y:405.6666},
  {x:94.9999,y:406.3334},
  {x:94.0000,y:407.0000},
  {x:94.0000,y:407.6666},
  {x:94.0000,y:408.3334},
  {x:94.0000,y:409.0000},
  {x:93.3334,y:409.3333},
  {x:92.6666,y:409.6667},
  {x:92.0000,y:410.0000},
  {x:92.0000,y:410.6666},
  {x:92.0000,y:411.3334},
  {x:92.0000,y:412.0000},
  {x:91.0001,y:412.6666},
  {x:89.9999,y:413.3334},
  {x:89.0000,y:414.0000},
  {x:89.0000,y:414.6666},
  {x:89.0000,y:415.3334},
  {x:89.0000,y:416.0000},
  {x:88.3334,y:416.3333},
  {x:87.6666,y:416.6667},
  {x:87.0000,y:417.0000},
  {x:86.6667,y:417.9999},
  {x:86.3333,y:419.0001},
  {x:86.0000,y:420.0000},
  {x:85.6667,y:420.0000},
  {x:85.3333,y:420.0000},
  {x:85.0000,y:420.0000},
  {x:85.0000,y:420.6666},
  {x:85.0000,y:421.3334},
  {x:85.0000,y:422.0000},
  {x:84.3334,y:422.3333},
  {x:83.6666,y:422.6667},
  {x:83.0000,y:423.0000},
  {x:83.0000,y:423.6666},
  {x:83.0000,y:424.3334},
  {x:83.0000,y:425.0000},
  {x:82.3334,y:425.3333},
  {x:81.6666,y:425.6667},
  {x:81.0000,y:426.0000},
  {x:81.0000,y:426.6666},
  {x:81.0000,y:427.3334},
  {x:81.0000,y:428.0000},
  {x:80.3334,y:428.3333},
  {x:79.6666,y:428.6667},
  {x:79.0000,y:429.0000},
  {x:78.6667,y:430.3332},
  {x:78.3333,y:431.6668},
  {x:78.0000,y:433.0000},
  {x:77.6667,y:433.0000},
  {x:77.3333,y:433.0000},
  {x:77.0000,y:433.0000},
  {x:76.6667,y:433.9999},
  {x:76.3333,y:435.0001},
  {x:76.0000,y:436.0000},
  {x:75.3334,y:436.3333},
  {x:74.6666,y:436.6667},
  {x:74.0000,y:437.0000},
  {x:73.6667,y:438.3332},
  {x:73.3333,y:439.6668},
  {x:73.0000,y:441.0000},
  {x:72.6667,y:441.0000},
  {x:72.3333,y:441.0000},
  {x:72.0000,y:441.0000},
  {x:71.6667,y:441.9999},
  {x:71.3333,y:443.0001},
  {x:71.0000,y:444.0000},
  {x:70.3334,y:444.3333},
  {x:69.6666,y:444.6667},
  {x:69.0000,y:445.0000},
  {x:68.6667,y:446.3332},
  {x:68.3333,y:447.6668},
  {x:68.0000,y:449.0000},
  {x:67.3334,y:449.3333},
  {x:66.6666,y:449.6667},
  {x:66.0000,y:450.0000},
  {x:65.6667,y:451.3332},
  {x:65.3333,y:452.6668},
  {x:65.0000,y:454.0000},
  {x:64.3334,y:454.3333},
  {x:63.6666,y:454.6667},
  {x:63.0000,y:455.0000},
  {x:62.6667,y:456.3332},
  {x:62.3333,y:457.6668},
  {x:62.0000,y:459.0000},
  {x:61.3334,y:459.3333},
  {x:60.6666,y:459.6667},
  {x:60.0000,y:460.0000},
  {x:59.6667,y:461.3332},
  {x:59.3333,y:462.6668},
  {x:59.0000,y:464.0000},
  {x:58.3334,y:464.3333},
  {x:57.6666,y:464.6667},
  {x:57.0000,y:465.0000},
  {x:56.3334,y:466.9998},
  {x:55.6666,y:469.0002},
  {x:55.0000,y:471.0000},
  {x:54.3334,y:471.3333},
  {x:53.6666,y:471.6667},
  {x:53.0000,y:472.0000},
  {x:53.0000,y:472.6666},
  {x:53.0000,y:473.3334},
  {x:53.0000,y:474.0000},
  {x:52.6667,y:474.0000},
  {x:52.3333,y:474.0000},
  {x:52.0000,y:474.0000},
  {x:52.0000,y:474.6666},
  {x:52.0000,y:475.3334},
  {x:52.0000,y:476.0000},
  {x:51.3334,y:476.3333},
  {x:50.6666,y:476.6667},
  {x:50.0000,y:477.0000},
  {x:50.0000,y:477.6666},
  {x:50.0000,y:478.3334},
  {x:50.0000,y:479.0000},
  {x:49.6667,y:479.0000},
  {x:49.3333,y:479.0000},
  {x:49.0000,y:479.0000},
  {x:49.0000,y:479.6666},
  {x:49.0000,y:480.3334},
  {x:49.0000,y:481.0000},
  {x:48.3334,y:481.3333},
  {x:47.6666,y:481.6667},
  {x:47.0000,y:482.0000},
  {x:46.3334,y:483.9998},
  {x:45.6666,y:486.0002},
  {x:45.0000,y:488.0000},
  {x:44.3334,y:488.3333},
  {x:43.6666,y:488.6667},
  {x:43.0000,y:489.0000},
  {x:42.6667,y:490.3332},
  {x:42.3333,y:491.6668},
  {x:42.0000,y:493.0000},
  {x:41.3334,y:493.3333},
  {x:40.6666,y:493.6667},
  {x:40.0000,y:494.0000},
  {x:39.6667,y:495.3332},
  {x:39.3333,y:496.6668},
  {x:39.0000,y:498.0000},
  {x:38.3334,y:498.3333},
  {x:37.6666,y:498.6667},
  {x:37.0000,y:499.0000},
  {x:36.3334,y:500.9998},
  {x:35.6666,y:503.0002},
  {x:35.0000,y:505.0000},
  {x:34.6667,y:505.0000},
  {x:34.3333,y:505.0000},
  {x:34.0000,y:505.0000},
  {x:33.6667,y:505.9999},
  {x:33.3333,y:507.0001},
  {x:33.0000,y:508.0000},
  {x:32.3334,y:508.3333},
  {x:31.6666,y:508.6667},
  {x:31.0000,y:509.0000},
  {x:30.6667,y:510.3332},
  {x:30.3333,y:511.6668},
  {x:30.0000,y:513.0000},
  {x:29.3334,y:513.3333},
  {x:28.6666,y:513.6667},
  {x:28.0000,y:514.0000},
  {x:27.6667,y:515.3332},
  {x:27.3333,y:516.6668},
  {x:27.0000,y:518.0000},
  {x:26.6667,y:518.0000},
  {x:26.3333,y:518.0000},
  {x:26.0000,y:518.0000},
  {x:25.6667,y:518.9999},
  {x:25.3333,y:520.0001},
  {x:25.0000,y:521.0000},
  {x:24.3334,y:521.3333},
  {x:23.6666,y:521.6667},
  {x:23.0000,y:522.0000},
  {x:22.6667,y:523.3332},
  {x:22.3333,y:524.6668},
  {x:22.0000,y:526.0000},
  {x:21.3334,y:526.3333},
  {x:20.6666,y:526.6667},
  {x:20.0000,y:527.0000},
  {x:20.0000,y:527.6666},
  {x:20.0000,y:528.3334},
  {x:20.0000,y:529.0000},
  {x:19.6667,y:529.0000},
  {x:19.3333,y:529.0000},
  {x:19.0000,y:529.0000},
  {x:18.6667,y:536.6659},
  {x:18.3333,y:544.3341},
  {x:18.0000,y:552.0000},
  {x:17.6667,y:552.0000},
  {x:17.3333,y:552.0000},
  {x:17.0000,y:552.0000},
  {x:17.0000,y:556.6662},
  {x:17.0000,y:561.3338},
  {x:17.0000,y:566.0000},
  {x:16.6667,y:566.0000},
  {x:16.3333,y:566.0000},
  {x:16.0000,y:566.0000},
  {x:16.0000,y:566.9999},
  {x:16.0000,y:568.0001},
  {x:16.0000,y:569.0000},
  {x:16.0000,y:572.3330},
  {x:16.0000,y:575.6670},
  {x:16.0000,y:579.0000},
  {x:15.6667,y:579.0000},
  {x:15.3333,y:579.0000},
  {x:15.0000,y:579.0000},
  {x:15.0000,y:583.9995},
  {x:15.0000,y:589.0005},
  {x:15.0000,y:594.0000},
  {x:14.6667,y:594.0000},
  {x:14.3333,y:594.0000},
  {x:14.0000,y:594.0000},
  {x:14.0000,y:598.9995},
  {x:14.0000,y:604.0005},
  {x:14.0000,y:609.0000},
  {x:13.6667,y:609.0000},
  {x:13.3333,y:609.0000},
  {x:13.0000,y:609.0000},
  {x:13.0000,y:613.9995},
  {x:13.0000,y:619.0005},
  {x:13.0000,y:624.0000},
  {x:12.6667,y:624.0000},
  {x:12.3333,y:624.0000},
  {x:12.0000,y:624.0000},
  {x:12.0000,y:627.6663},
  {x:12.0000,y:631.3337},
  {x:12.0000,y:635.0000},
  {x:11.6667,y:641.9993},
  {x:11.3333,y:649.0007},
  {x:11.0000,y:656.0000},
  {x:10.6667,y:656.0000},
  {x:10.3333,y:656.0000},
  {x:10.0000,y:656.0000},
  {x:10.0000,y:661.9994},
  {x:10.0000,y:668.0006},
  {x:10.0000,y:674.0000},
  {x:9.6667,y:674.0000},
  {x:9.3333,y:674.0000},
  {x:9.0000,y:674.0000},
  {x:9.0000,y:679.9994},
  {x:9.0000,y:686.0006},
  {x:9.0000,y:692.0000},
  {x:7.6282,y:696.8630},
  {x:9.5462,y:705.5141},
  {x:8.0000,y:711.0000},
  {x:6.2611,y:717.1697},
  {x:8.7763,y:727.6918},
  {x:7.0000,y:734.0000},
  {x:6.6667,y:734.0000},
  {x:6.3333,y:734.0000},
  {x:6.0000,y:734.0000},
  {x:6.0000,y:737.6663},
  {x:6.0000,y:741.3337},
  {x:6.0000,y:745.0000},
  {x:6.0000,y:752.3326},
  {x:6.0000,y:759.6674},
  {x:6.0000,y:767.0000},
  {x:3.0696,y:777.4181},
  {x:5.0003,y:799.2248},
  {x:5.0000,y:812.0000},
  {x:5.0000,y:832.6646},
  {x:5.0000,y:853.3354},
  {x:5.0000,y:874.0000},
  {x:4.6667,y:874.0000},
  {x:4.3333,y:874.0000},
  {x:4.0000,y:874.0000},
  {x:4.0000,y:875.3332},
  {x:4.0000,y:876.6668},
  {x:4.0000,y:878.0000},
  {x:3.6667,y:878.0000},
  {x:3.3333,y:878.0000},
  {x:3.0000,y:878.0000},
  {x:3.3333,y:883.9994},
  {x:3.6667,y:890.0006},
  {x:4.0000,y:896.0000}
];
