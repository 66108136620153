module.exports = [
  {x: 249.0000, y: 155.0000},
  {x: 248.5222, y: 148.9505},
  {x: 245.2690, y: 144.2424},
  {x: 243.0000, y: 140.0000},
  {x: 243.0000, y: 139.3334},
  {x: 243.0000, y: 138.6666},
  {x: 243.0000, y: 138.0000},
  {x: 242.6667, y: 138.0000},
  {x: 242.3333, y: 138.0000},
  {x: 242.0000, y: 138.0000},
  {x: 242.0000, y: 137.3334},
  {x: 242.0000, y: 136.6666},
  {x: 242.0000, y: 136.0000},
  {x: 241.6667, y: 136.0000},
  {x: 241.3333, y: 136.0000},
  {x: 241.0000, y: 136.0000},
  {x: 241.0000, y: 135.3334},
  {x: 241.0000, y: 134.6666},
  {x: 241.0000, y: 134.0000},
  {x: 240.6667, y: 134.0000},
  {x: 240.3333, y: 134.0000},
  {x: 240.0000, y: 134.0000},
  {x: 240.0000, y: 133.3334},
  {x: 240.0000, y: 132.6666},
  {x: 240.0000, y: 132.0000},
  {x: 239.6667, y: 132.0000},
  {x: 239.3333, y: 132.0000},
  {x: 239.0000, y: 132.0000},
  {x: 239.0000, y: 131.3334},
  {x: 239.0000, y: 130.6666},
  {x: 239.0000, y: 130.0000},
  {x: 238.6667, y: 130.0000},
  {x: 238.3333, y: 130.0000},
  {x: 238.0000, y: 130.0000},
  {x: 238.0000, y: 129.3334},
  {x: 238.0000, y: 128.6666},
  {x: 238.0000, y: 128.0000},
  {x: 237.6667, y: 128.0000},
  {x: 237.3333, y: 128.0000},
  {x: 237.0000, y: 128.0000},
  {x: 237.0000, y: 127.3334},
  {x: 237.0000, y: 126.6666},
  {x: 237.0000, y: 126.0000},
  {x: 236.6667, y: 126.0000},
  {x: 236.3333, y: 126.0000},
  {x: 236.0000, y: 126.0000},
  {x: 236.0000, y: 125.3334},
  {x: 236.0000, y: 124.6666},
  {x: 236.0000, y: 124.0000},
  {x: 235.3334, y: 123.6667},
  {x: 234.6666, y: 123.3333},
  {x: 234.0000, y: 123.0000},
  {x: 233.6667, y: 121.6668},
  {x: 233.3333, y: 120.3332},
  {x: 233.0000, y: 119.0000},
  {x: 232.6667, y: 119.0000},
  {x: 232.3333, y: 119.0000},
  {x: 232.0000, y: 119.0000},
  {x: 231.6667, y: 118.0001},
  {x: 231.3333, y: 116.9999},
  {x: 231.0000, y: 116.0000},
  {x: 230.3334, y: 115.6667},
  {x: 229.6666, y: 115.3333},
  {x: 229.0000, y: 115.0000},
  {x: 229.0000, y: 114.3334},
  {x: 229.0000, y: 113.6666},
  {x: 229.0000, y: 113.0000},
  {x: 228.3334, y: 112.6667},
  {x: 227.6666, y: 112.3333},
  {x: 227.0000, y: 112.0000},
  {x: 227.0000, y: 111.3334},
  {x: 227.0000, y: 110.6666},
  {x: 227.0000, y: 110.0000},
  {x: 226.3334, y: 109.6667},
  {x: 225.6666, y: 109.3333},
  {x: 225.0000, y: 109.0000},
  {x: 225.0000, y: 108.3334},
  {x: 225.0000, y: 107.6666},
  {x: 225.0000, y: 107.0000},
  {x: 224.3334, y: 106.6667},
  {x: 223.6666, y: 106.3333},
  {x: 223.0000, y: 106.0000},
  {x: 223.0000, y: 105.3334},
  {x: 223.0000, y: 104.6666},
  {x: 223.0000, y: 104.0000},
  {x: 222.6667, y: 104.0000},
  {x: 222.3333, y: 104.0000},
  {x: 222.0000, y: 104.0000},
  {x: 220.8235, y: 102.5060},
  {x: 220.6313, y: 102.2196},
  {x: 220.0000, y: 100.0000},
  {x: 212.5631, y: 100.3165},
  {x: 205.0390, y: 102.9755},
  {x: 199.0000, y: 105.0000},
  {x: 196.0003, y: 105.3333},
  {x: 192.9997, y: 105.6667},
  {x: 190.0000, y: 106.0000},
  {x: 190.0000, y: 106.3333},
  {x: 190.0000, y: 106.6667},
  {x: 190.0000, y: 107.0000},
  {x: 188.6668, y: 107.0000},
  {x: 187.3332, y: 107.0000},
  {x: 186.0000, y: 107.0000},
  {x: 186.0000, y: 107.3333},
  {x: 186.0000, y: 107.6667},
  {x: 186.0000, y: 108.0000},
  {x: 184.6668, y: 108.0000},
  {x: 183.3332, y: 108.0000},
  {x: 182.0000, y: 108.0000},
  {x: 182.0000, y: 108.3333},
  {x: 182.0000, y: 108.6667},
  {x: 182.0000, y: 109.0000},
  {x: 180.3335, y: 109.0000},
  {x: 178.6665, y: 109.0000},
  {x: 177.0000, y: 109.0000},
  {x: 177.0000, y: 109.3333},
  {x: 177.0000, y: 109.6667},
  {x: 177.0000, y: 110.0000},
  {x: 175.3335, y: 110.0000},
  {x: 173.6665, y: 110.0000},
  {x: 172.0000, y: 110.0000},
  {x: 172.0000, y: 110.3333},
  {x: 172.0000, y: 110.6667},
  {x: 172.0000, y: 111.0000},
  {x: 170.3335, y: 111.0000},
  {x: 168.6665, y: 111.0000},
  {x: 167.0000, y: 111.0000},
  {x: 167.0000, y: 111.3333},
  {x: 167.0000, y: 111.6667},
  {x: 167.0000, y: 112.0000},
  {x: 165.0002, y: 112.0000},
  {x: 162.9998, y: 112.0000},
  {x: 161.0000, y: 112.0000},
  {x: 141.1142, y: 117.9597},
  {x: 100.6530, y: 122.6599},
  {x: 81.0000, y: 114.0000},
  {x: 81.0000, y: 113.6667},
  {x: 81.0000, y: 113.3333},
  {x: 81.0000, y: 113.0000},
  {x: 80.3334, y: 113.0000},
  {x: 79.6666, y: 113.0000},
  {x: 79.0000, y: 113.0000},
  {x: 79.0000, y: 112.6667},
  {x: 79.0000, y: 112.3333},
  {x: 79.0000, y: 112.0000},
  {x: 78.3334, y: 112.0000},
  {x: 77.6666, y: 112.0000},
  {x: 77.0000, y: 112.0000},
  {x: 76.6667, y: 111.3334},
  {x: 76.3333, y: 110.6666},
  {x: 76.0000, y: 110.0000},
  {x: 75.3334, y: 110.0000},
  {x: 74.6666, y: 110.0000},
  {x: 74.0000, y: 110.0000},
  {x: 73.6667, y: 109.3334},
  {x: 73.3333, y: 108.6666},
  {x: 73.0000, y: 108.0000},
  {x: 72.3334, y: 108.0000},
  {x: 71.6666, y: 108.0000},
  {x: 71.0000, y: 108.0000},
  {x: 70.6667, y: 107.3334},
  {x: 70.3333, y: 106.6666},
  {x: 70.0000, y: 106.0000},
  {x: 68.6668, y: 105.0001},
  {x: 67.3332, y: 103.9999},
  {x: 66.0000, y: 103.0000},
  {x: 66.0000, y: 102.3334},
  {x: 66.0000, y: 101.6666},
  {x: 66.0000, y: 101.0000},
  {x: 65.3334, y: 100.6667},
  {x: 64.6666, y: 100.3333},
  {x: 64.0000, y: 100.0000},
  {x: 64.0000, y: 99.3334},
  {x: 64.0000, y: 98.6666},
  {x: 64.0000, y: 98.0000},
  {x: 63.6667, y: 98.0000},
  {x: 63.3333, y: 98.0000},
  {x: 63.0000, y: 98.0000},
  {x: 62.6667, y: 96.6668},
  {x: 62.3333, y: 95.3332},
  {x: 62.0000, y: 94.0000},
  {x: 61.6667, y: 94.0000},
  {x: 61.3333, y: 94.0000},
  {x: 61.0000, y: 94.0000},
  {x: 61.0000, y: 92.6668},
  {x: 61.0000, y: 91.3332},
  {x: 61.0000, y: 90.0000},
  {x: 60.6667, y: 90.0000},
  {x: 60.3333, y: 90.0000},
  {x: 60.0000, y: 90.0000},
  {x: 58.3329, y: 84.4412},
  {x: 60.8108, y: 77.1218},
  {x: 62.0000, y: 74.0000},
  {x: 62.0000, y: 73.0001},
  {x: 62.0000, y: 71.9999},
  {x: 62.0000, y: 71.0000},
  {x: 62.3333, y: 71.0000},
  {x: 62.6667, y: 71.0000},
  {x: 63.0000, y: 71.0000},
  {x: 63.3333, y: 69.6668},
  {x: 63.6667, y: 68.3332},
  {x: 64.0000, y: 67.0000},
  {x: 66.4687, y: 63.0872},
  {x: 70.7438, y: 60.1988},
  {x: 74.0000, y: 57.0000},
  {x: 74.6666, y: 56.0001},
  {x: 75.3334, y: 54.9999},
  {x: 76.0000, y: 54.0000},
  {x: 76.6666, y: 54.0000},
  {x: 77.3334, y: 54.0000},
  {x: 78.0000, y: 54.0000},
  {x: 78.6666, y: 53.0001},
  {x: 79.3334, y: 51.9999},
  {x: 80.0000, y: 51.0000},
  {x: 80.6666, y: 51.0000},
  {x: 81.3334, y: 51.0000},
  {x: 82.0000, y: 51.0000},
  {x: 82.6666, y: 50.0001},
  {x: 83.3334, y: 48.9999},
  {x: 84.0000, y: 48.0000},
  {x: 84.6666, y: 48.0000},
  {x: 85.3334, y: 48.0000},
  {x: 86.0000, y: 48.0000},
  {x: 86.3333, y: 47.3334},
  {x: 86.6667, y: 46.6666},
  {x: 87.0000, y: 46.0000},
  {x: 87.6666, y: 46.0000},
  {x: 88.3334, y: 46.0000},
  {x: 89.0000, y: 46.0000},
  {x: 89.3333, y: 45.3334},
  {x: 89.6667, y: 44.6666},
  {x: 90.0000, y: 44.0000},
  {x: 90.6666, y: 44.0000},
  {x: 91.3334, y: 44.0000},
  {x: 92.0000, y: 44.0000},
  {x: 92.3333, y: 43.3334},
  {x: 92.6667, y: 42.6666},
  {x: 93.0000, y: 42.0000},
  {x: 93.9999, y: 41.6667},
  {x: 95.0001, y: 41.3333},
  {x: 96.0000, y: 41.0000},
  {x: 96.0000, y: 40.6667},
  {x: 96.0000, y: 40.3333},
  {x: 96.0000, y: 40.0000},
  {x: 97.3332, y: 39.6667},
  {x: 98.6668, y: 39.3333},
  {x: 100.0000, y: 39.0000},
  {x: 100.3333, y: 38.3334},
  {x: 100.6667, y: 37.6666},
  {x: 101.0000, y: 37.0000},
  {x: 101.9999, y: 36.6667},
  {x: 103.0001, y: 36.3333},
  {x: 104.0000, y: 36.0000},
  {x: 104.0000, y: 35.6667},
  {x: 104.0000, y: 35.3333},
  {x: 104.0000, y: 35.0000},
  {x: 105.3332, y: 34.6667},
  {x: 106.6668, y: 34.3333},
  {x: 108.0000, y: 34.0000},
  {x: 108.3333, y: 33.3334},
  {x: 108.6667, y: 32.6666},
  {x: 109.0000, y: 32.0000},
  {x: 109.6666, y: 32.0000},
  {x: 110.3334, y: 32.0000},
  {x: 111.0000, y: 32.0000},
  {x: 111.0000, y: 31.6667},
  {x: 111.0000, y: 31.3333},
  {x: 111.0000, y: 31.0000},
  {x: 111.6666, y: 31.0000},
  {x: 112.3334, y: 31.0000},
  {x: 113.0000, y: 31.0000},
  {x: 113.3333, y: 30.3334},
  {x: 113.6667, y: 29.6666},
  {x: 114.0000, y: 29.0000},
  {x: 114.6666, y: 29.0000},
  {x: 115.3334, y: 29.0000},
  {x: 116.0000, y: 29.0000},
  {x: 116.0000, y: 28.6667},
  {x: 116.0000, y: 28.3333},
  {x: 116.0000, y: 28.0000},
  {x: 116.6666, y: 28.0000},
  {x: 117.3334, y: 28.0000},
  {x: 118.0000, y: 28.0000},
  {x: 118.3333, y: 27.3334},
  {x: 118.6667, y: 26.6666},
  {x: 119.0000, y: 26.0000},
  {x: 119.6666, y: 26.0000},
  {x: 120.3334, y: 26.0000},
  {x: 121.0000, y: 26.0000},
  {x: 121.0000, y: 25.6667},
  {x: 121.0000, y: 25.3333},
  {x: 121.0000, y: 25.0000},
  {x: 123.0098, y: 25.5736},
  {x: 121.8652, y: 24.8800},
  {x: 123.0000, y: 26.0000},
  {x: 123.3333, y: 26.0000},
  {x: 123.6667, y: 26.0000},
  {x: 124.0000, y: 26.0000},
  {x: 124.0000, y: 27.3332},
  {x: 124.0000, y: 28.6668},
  {x: 124.0000, y: 30.0000},
  {x: 123.6667, y: 30.0000},
  {x: 123.3333, y: 30.0000},
  {x: 123.0000, y: 30.0000},
  {x: 122.6667, y: 32.3331},
  {x: 122.3333, y: 34.6669},
  {x: 122.0000, y: 37.0000},
  {x: 120.7656, y: 40.3496},
  {x: 116.8554, y: 53.9571},
  {x: 119.0000, y: 60.0000},
  {x: 119.3333, y: 60.0000},
  {x: 119.6667, y: 60.0000},
  {x: 120.0000, y: 60.0000},
  {x: 120.3333, y: 61.3332},
  {x: 120.6667, y: 62.6668},
  {x: 121.0000, y: 64.0000},
  {x: 122.2139, y: 64.6650},
  {x: 122.3954, y: 65.5973},
  {x: 123.0000, y: 66.0000},
  {x: 123.6666, y: 66.0000},
  {x: 124.3334, y: 66.0000},
  {x: 125.0000, y: 66.0000},
  {x: 125.3333, y: 66.6666},
  {x: 125.6667, y: 67.3334},
  {x: 126.0000, y: 68.0000},
  {x: 126.6666, y: 68.0000},
  {x: 127.3334, y: 68.0000},
  {x: 128.0000, y: 68.0000},
  {x: 128.3333, y: 68.6666},
  {x: 128.6667, y: 69.3334},
  {x: 129.0000, y: 70.0000},
  {x: 132.9996, y: 71.3332},
  {x: 137.0004, y: 72.6668},
  {x: 141.0000, y: 74.0000},
  {x: 145.3329, y: 74.0000},
  {x: 149.6671, y: 74.0000},
  {x: 154.0000, y: 74.0000},
  {x: 158.6662, y: 74.0000},
  {x: 163.3338, y: 74.0000},
  {x: 168.0000, y: 74.0000},
  {x: 174.0484, y: 72.2975},
  {x: 182.7395, y: 71.1076},
  {x: 190.0000, y: 71.0000},
  {x: 190.0000, y: 70.6667},
  {x: 190.0000, y: 70.3333},
  {x: 190.0000, y: 70.0000},
  {x: 182.3037, y: 65.7406},
  {x: 176.9783, y: 58.9889},
  {x: 171.0000, y: 53.0000},
  {x: 169.3335, y: 51.6668},
  {x: 167.6665, y: 50.3332},
  {x: 166.0000, y: 49.0000},
  {x: 166.0000, y: 48.3334},
  {x: 166.0000, y: 47.6666},
  {x: 166.0000, y: 47.0000},
  {x: 164.3335, y: 45.6668},
  {x: 162.6665, y: 44.3332},
  {x: 161.0000, y: 43.0000},
  {x: 161.0000, y: 42.3334},
  {x: 161.0000, y: 41.6666},
  {x: 161.0000, y: 41.0000},
  {x: 160.0001, y: 40.3334},
  {x: 158.9999, y: 39.6666},
  {x: 158.0000, y: 39.0000},
  {x: 158.0000, y: 38.3334},
  {x: 158.0000, y: 37.6666},
  {x: 158.0000, y: 37.0000},
  {x: 157.0001, y: 36.3334},
  {x: 155.9999, y: 35.6666},
  {x: 155.0000, y: 35.0000},
  {x: 155.0000, y: 34.3334},
  {x: 155.0000, y: 33.6666},
  {x: 155.0000, y: 33.0000},
  {x: 154.0001, y: 32.3334},
  {x: 152.9999, y: 31.6666},
  {x: 152.0000, y: 31.0000},
  {x: 152.0000, y: 30.3334},
  {x: 152.0000, y: 29.6666},
  {x: 152.0000, y: 29.0000},
  {x: 151.3334, y: 28.6667},
  {x: 150.6666, y: 28.3333},
  {x: 150.0000, y: 28.0000},
  {x: 149.6667, y: 26.6668},
  {x: 149.3333, y: 25.3332},
  {x: 149.0000, y: 24.0000},
  {x: 148.0001, y: 23.3334},
  {x: 146.9999, y: 22.6666},
  {x: 146.0000, y: 22.0000},
  {x: 142.3337, y: 15.0007},
  {x: 138.6663, y: 7.9993},
  {x: 135.0000, y: 1.0000},
  {x: 134.0001, y: 1.6666},
  {x: 132.9999, y: 2.3334},
  {x: 132.0000, y: 3.0000},
  {x: 132.0000, y: 3.3333},
  {x: 132.0000, y: 3.6667},
  {x: 132.0000, y: 4.0000},
  {x: 130.6668, y: 4.3333},
  {x: 129.3332, y: 4.6667},
  {x: 128.0000, y: 5.0000},
  {x: 127.6667, y: 5.6666},
  {x: 127.3333, y: 6.3334},
  {x: 127.0000, y: 7.0000},
  {x: 125.6668, y: 7.3333},
  {x: 124.3332, y: 7.6667},
  {x: 123.0000, y: 8.0000},
  {x: 122.6667, y: 8.6666},
  {x: 122.3333, y: 9.3334},
  {x: 122.0000, y: 10.0000},
  {x: 120.6668, y: 10.3333},
  {x: 119.3332, y: 10.6667},
  {x: 118.0000, y: 11.0000},
  {x: 118.0000, y: 11.3333},
  {x: 118.0000, y: 11.6667},
  {x: 118.0000, y: 12.0000},
  {x: 117.3334, y: 12.0000},
  {x: 116.6666, y: 12.0000},
  {x: 116.0000, y: 12.0000},
  {x: 116.0000, y: 12.3333},
  {x: 116.0000, y: 12.6667},
  {x: 116.0000, y: 13.0000},
  {x: 115.3334, y: 13.0000},
  {x: 114.6666, y: 13.0000},
  {x: 114.0000, y: 13.0000},
  {x: 114.0000, y: 13.3333},
  {x: 114.0000, y: 13.6667},
  {x: 114.0000, y: 14.0000},
  {x: 113.3334, y: 14.0000},
  {x: 112.6666, y: 14.0000},
  {x: 112.0000, y: 14.0000},
  {x: 112.0000, y: 14.3333},
  {x: 112.0000, y: 14.6667},
  {x: 112.0000, y: 15.0000},
  {x: 111.3334, y: 15.0000},
  {x: 110.6666, y: 15.0000},
  {x: 110.0000, y: 15.0000},
  {x: 110.0000, y: 15.3333},
  {x: 110.0000, y: 15.6667},
  {x: 110.0000, y: 16.0000},
  {x: 109.3334, y: 16.0000},
  {x: 108.6666, y: 16.0000},
  {x: 108.0000, y: 16.0000},
  {x: 108.0000, y: 16.3333},
  {x: 108.0000, y: 16.6667},
  {x: 108.0000, y: 17.0000},
  {x: 107.0001, y: 17.3333},
  {x: 105.9999, y: 17.6667},
  {x: 105.0000, y: 18.0000},
  {x: 104.6667, y: 18.6666},
  {x: 104.3333, y: 19.3334},
  {x: 104.0000, y: 20.0000},
  {x: 102.6668, y: 20.3333},
  {x: 101.3332, y: 20.6667},
  {x: 100.0000, y: 21.0000},
  {x: 99.6667, y: 21.6666},
  {x: 99.3333, y: 22.3334},
  {x: 99.0000, y: 23.0000},
  {x: 98.0001, y: 23.3333},
  {x: 96.9999, y: 23.6667},
  {x: 96.0000, y: 24.0000},
  {x: 95.3334, y: 24.9999},
  {x: 94.6666, y: 26.0001},
  {x: 94.0000, y: 27.0000},
  {x: 93.3334, y: 27.0000},
  {x: 92.6666, y: 27.0000},
  {x: 92.0000, y: 27.0000},
  {x: 91.6667, y: 27.6666},
  {x: 91.3333, y: 28.3334},
  {x: 91.0000, y: 29.0000},
  {x: 90.3334, y: 29.0000},
  {x: 89.6666, y: 29.0000},
  {x: 89.0000, y: 29.0000},
  {x: 88.3334, y: 29.9999},
  {x: 87.6666, y: 31.0001},
  {x: 87.0000, y: 32.0000},
  {x: 86.3334, y: 32.0000},
  {x: 85.6666, y: 32.0000},
  {x: 85.0000, y: 32.0000},
  {x: 84.6667, y: 32.6666},
  {x: 84.3333, y: 33.3334},
  {x: 84.0000, y: 34.0000},
  {x: 83.3334, y: 34.0000},
  {x: 82.6666, y: 34.0000},
  {x: 82.0000, y: 34.0000},
  {x: 81.6667, y: 34.6666},
  {x: 81.3333, y: 35.3334},
  {x: 81.0000, y: 36.0000},
  {x: 79.0002, y: 36.6666},
  {x: 76.9998, y: 37.3334},
  {x: 75.0000, y: 38.0000},
  {x: 75.0000, y: 38.3333},
  {x: 75.0000, y: 38.6667},
  {x: 75.0000, y: 39.0000},
  {x: 74.3334, y: 39.0000},
  {x: 73.6666, y: 39.0000},
  {x: 73.0000, y: 39.0000},
  {x: 73.0000, y: 39.3333},
  {x: 73.0000, y: 39.6667},
  {x: 73.0000, y: 40.0000},
  {x: 72.3334, y: 40.0000},
  {x: 71.6666, y: 40.0000},
  {x: 71.0000, y: 40.0000},
  {x: 71.0000, y: 40.3333},
  {x: 71.0000, y: 40.6667},
  {x: 71.0000, y: 41.0000},
  {x: 69.0002, y: 41.6666},
  {x: 66.9998, y: 42.3334},
  {x: 65.0000, y: 43.0000},
  {x: 65.0000, y: 43.3333},
  {x: 65.0000, y: 43.6667},
  {x: 65.0000, y: 44.0000},
  {x: 64.0001, y: 44.0000},
  {x: 62.9999, y: 44.0000},
  {x: 62.0000, y: 44.0000},
  {x: 62.0000, y: 44.3333},
  {x: 62.0000, y: 44.6667},
  {x: 62.0000, y: 45.0000},
  {x: 61.3334, y: 45.0000},
  {x: 60.6666, y: 45.0000},
  {x: 60.0000, y: 45.0000},
  {x: 60.0000, y: 45.3333},
  {x: 60.0000, y: 45.6667},
  {x: 60.0000, y: 46.0000},
  {x: 58.6668, y: 46.0000},
  {x: 57.3332, y: 46.0000},
  {x: 56.0000, y: 46.0000},
  {x: 56.0000, y: 46.3333},
  {x: 56.0000, y: 46.6667},
  {x: 56.0000, y: 47.0000},
  {x: 54.6668, y: 47.0000},
  {x: 53.3332, y: 47.0000},
  {x: 52.0000, y: 47.0000},
  {x: 52.0000, y: 47.3333},
  {x: 52.0000, y: 47.6667},
  {x: 52.0000, y: 48.0000},
  {x: 50.6668, y: 48.0000},
  {x: 49.3332, y: 48.0000},
  {x: 48.0000, y: 48.0000},
  {x: 48.0000, y: 48.3333},
  {x: 48.0000, y: 48.6667},
  {x: 48.0000, y: 49.0000},
  {x: 46.0002, y: 49.6666},
  {x: 43.9998, y: 50.3334},
  {x: 42.0000, y: 51.0000},
  {x: 42.0000, y: 51.3333},
  {x: 42.0000, y: 51.6667},
  {x: 42.0000, y: 52.0000},
  {x: 41.3334, y: 52.0000},
  {x: 40.6666, y: 52.0000},
  {x: 40.0000, y: 52.0000},
  {x: 40.0000, y: 52.3333},
  {x: 40.0000, y: 52.6667},
  {x: 40.0000, y: 53.0000},
  {x: 39.0001, y: 53.0000},
  {x: 37.9999, y: 53.0000},
  {x: 37.0000, y: 53.0000},
  {x: 37.0000, y: 53.3333},
  {x: 37.0000, y: 53.6667},
  {x: 37.0000, y: 54.0000},
  {x: 36.3334, y: 54.0000},
  {x: 35.6666, y: 54.0000},
  {x: 35.0000, y: 54.0000},
  {x: 35.0000, y: 54.3333},
  {x: 35.0000, y: 54.6667},
  {x: 35.0000, y: 55.0000},
  {x: 34.3334, y: 55.0000},
  {x: 33.6666, y: 55.0000},
  {x: 33.0000, y: 55.0000},
  {x: 32.6667, y: 55.6666},
  {x: 32.3333, y: 56.3334},
  {x: 32.0000, y: 57.0000},
  {x: 31.3334, y: 57.0000},
  {x: 30.6666, y: 57.0000},
  {x: 30.0000, y: 57.0000},
  {x: 29.3334, y: 57.9999},
  {x: 28.6666, y: 59.0001},
  {x: 28.0000, y: 60.0000},
  {x: 26.6668, y: 60.3333},
  {x: 25.3332, y: 60.6667},
  {x: 24.0000, y: 61.0000},
  {x: 23.0001, y: 62.3332},
  {x: 21.9999, y: 63.6668},
  {x: 21.0000, y: 65.0000},
  {x: 19.6668, y: 65.3333},
  {x: 18.3332, y: 65.6667},
  {x: 17.0000, y: 66.0000},
  {x: 16.6667, y: 66.6666},
  {x: 16.3333, y: 67.3334},
  {x: 16.0000, y: 68.0000},
  {x: 12.0696, y: 70.4720},
  {x: 4.9149, y: 70.6525},
  {x: 2.0000, y: 74.0000},
  {x: 5.4508, y: 76.3484},
  {x: 4.8450, y: 79.5972},
  {x: 7.0000, y: 83.0000},
  {x: 7.6666, y: 83.3333},
  {x: 8.3334, y: 83.6667},
  {x: 9.0000, y: 84.0000},
  {x: 9.0000, y: 84.6666},
  {x: 9.0000, y: 85.3334},
  {x: 9.0000, y: 86.0000},
  {x: 9.6666, y: 86.3333},
  {x: 10.3334, y: 86.6667},
  {x: 11.0000, y: 87.0000},
  {x: 11.0000, y: 87.6666},
  {x: 11.0000, y: 88.3334},
  {x: 11.0000, y: 89.0000},
  {x: 11.9999, y: 89.6666},
  {x: 13.0001, y: 90.3334},
  {x: 14.0000, y: 91.0000},
  {x: 16.3331, y: 93.6664},
  {x: 18.6669, y: 96.3336},
  {x: 21.0000, y: 99.0000},
  {x: 24.9996, y: 102.3330},
  {x: 29.0004, y: 105.6670},
  {x: 33.0000, y: 109.0000},
  {x: 33.6666, y: 109.0000},
  {x: 34.3334, y: 109.0000},
  {x: 35.0000, y: 109.0000},
  {x: 35.3333, y: 109.6666},
  {x: 35.6667, y: 110.3334},
  {x: 36.0000, y: 111.0000},
  {x: 36.6666, y: 111.0000},
  {x: 37.3334, y: 111.0000},
  {x: 38.0000, y: 111.0000},
  {x: 38.6666, y: 111.9999},
  {x: 39.3334, y: 113.0001},
  {x: 40.0000, y: 114.0000},
  {x: 40.6666, y: 114.0000},
  {x: 41.3334, y: 114.0000},
  {x: 42.0000, y: 114.0000},
  {x: 43.6665, y: 115.9998},
  {x: 45.3335, y: 118.0002},
  {x: 47.0000, y: 120.0000},
  {x: 47.6666, y: 120.0000},
  {x: 48.3334, y: 120.0000},
  {x: 49.0000, y: 120.0000},
  {x: 49.3333, y: 120.6666},
  {x: 49.6667, y: 121.3334},
  {x: 50.0000, y: 122.0000},
  {x: 51.6665, y: 123.3332},
  {x: 53.3335, y: 124.6668},
  {x: 55.0000, y: 126.0000},
  {x: 55.0000, y: 126.6666},
  {x: 55.0000, y: 127.3334},
  {x: 55.0000, y: 128.0000},
  {x: 56.9998, y: 129.6665},
  {x: 59.0002, y: 131.3335},
  {x: 61.0000, y: 133.0000},
  {x: 61.3333, y: 133.9999},
  {x: 61.6667, y: 135.0001},
  {x: 62.0000, y: 136.0000},
  {x: 62.6666, y: 136.3333},
  {x: 63.3334, y: 136.6667},
  {x: 64.0000, y: 137.0000},
  {x: 64.0000, y: 137.6666},
  {x: 64.0000, y: 138.3334},
  {x: 64.0000, y: 139.0000},
  {x: 64.9999, y: 139.6666},
  {x: 66.0001, y: 140.3334},
  {x: 67.0000, y: 141.0000},
  {x: 67.3333, y: 142.3332},
  {x: 67.6667, y: 143.6668},
  {x: 68.0000, y: 145.0000},
  {x: 68.6666, y: 145.3333},
  {x: 69.3334, y: 145.6667},
  {x: 70.0000, y: 146.0000},
  {x: 70.3333, y: 146.9999},
  {x: 70.6667, y: 148.0001},
  {x: 71.0000, y: 149.0000},
  {x: 71.3333, y: 149.0000},
  {x: 71.6667, y: 149.0000},
  {x: 72.0000, y: 149.0000},
  {x: 72.0000, y: 149.6666},
  {x: 72.0000, y: 150.3334},
  {x: 72.0000, y: 151.0000},
  {x: 72.3333, y: 151.0000},
  {x: 72.6667, y: 151.0000},
  {x: 73.0000, y: 151.0000},
  {x: 73.0000, y: 151.6666},
  {x: 73.0000, y: 152.3334},
  {x: 73.0000, y: 153.0000},
  {x: 73.3333, y: 153.0000},
  {x: 73.6667, y: 153.0000},
  {x: 74.0000, y: 153.0000},
  {x: 74.0000, y: 153.6666},
  {x: 74.0000, y: 154.3334},
  {x: 74.0000, y: 155.0000},
  {x: 74.3333, y: 155.0000},
  {x: 74.6667, y: 155.0000},
  {x: 75.0000, y: 155.0000},
  {x: 75.0000, y: 155.6666},
  {x: 75.0000, y: 156.3334},
  {x: 75.0000, y: 157.0000},
  {x: 75.6666, y: 157.3333},
  {x: 76.3334, y: 157.6667},
  {x: 77.0000, y: 158.0000},
  {x: 77.0000, y: 158.6666},
  {x: 77.0000, y: 159.3334},
  {x: 77.0000, y: 160.0000},
  {x: 77.3333, y: 160.0000},
  {x: 77.6667, y: 160.0000},
  {x: 78.0000, y: 160.0000},
  {x: 78.0000, y: 160.9999},
  {x: 78.0000, y: 162.0001},
  {x: 78.0000, y: 163.0000},
  {x: 78.3333, y: 163.0000},
  {x: 78.6667, y: 163.0000},
  {x: 79.0000, y: 163.0000},
  {x: 79.7842, y: 164.5955},
  {x: 81.9986, y: 171.4447},
  {x: 83.0000, y: 172.0000},
  {x: 85.3331, y: 172.0000},
  {x: 87.6669, y: 172.0000},
  {x: 90.0000, y: 172.0000},
  {x: 90.0000, y: 171.6667},
  {x: 90.0000, y: 171.3333},
  {x: 90.0000, y: 171.0000},
  {x: 92.3331, y: 170.6667},
  {x: 94.6669, y: 170.3333},
  {x: 97.0000, y: 170.0000},
  {x: 97.0000, y: 169.6667},
  {x: 97.0000, y: 169.3333},
  {x: 97.0000, y: 169.0000},
  {x: 98.6665, y: 169.0000},
  {x: 100.3335, y: 169.0000},
  {x: 102.0000, y: 169.0000},
  {x: 102.0000, y: 168.6667},
  {x: 102.0000, y: 168.3333},
  {x: 102.0000, y: 168.0000},
  {x: 103.6665, y: 168.0000},
  {x: 105.3335, y: 168.0000},
  {x: 107.0000, y: 168.0000},
  {x: 107.0000, y: 167.6667},
  {x: 107.0000, y: 167.3333},
  {x: 107.0000, y: 167.0000},
  {x: 109.3331, y: 167.0000},
  {x: 111.6669, y: 167.0000},
  {x: 114.0000, y: 167.0000},
  {x: 114.0000, y: 166.6667},
  {x: 114.0000, y: 166.3333},
  {x: 114.0000, y: 166.0000},
  {x: 115.9998, y: 166.0000},
  {x: 118.0002, y: 166.0000},
  {x: 120.0000, y: 166.0000},
  {x: 120.0000, y: 165.6667},
  {x: 120.0000, y: 165.3333},
  {x: 120.0000, y: 165.0000},
  {x: 122.6664, y: 165.0000},
  {x: 125.3336, y: 165.0000},
  {x: 128.0000, y: 165.0000},
  {x: 137.6078, y: 162.2361},
  {x: 151.3484, y: 162.7416},
  {x: 161.0000, y: 160.0000},
  {x: 164.6663, y: 160.0000},
  {x: 168.3337, y: 160.0000},
  {x: 172.0000, y: 160.0000},
  {x: 172.0000, y: 159.6667},
  {x: 172.0000, y: 159.3333},
  {x: 172.0000, y: 159.0000},
  {x: 175.3330, y: 159.0000},
  {x: 178.6670, y: 159.0000},
  {x: 182.0000, y: 159.0000},
  {x: 182.0000, y: 158.6667},
  {x: 182.0000, y: 158.3333},
  {x: 182.0000, y: 158.0000},
  {x: 185.3330, y: 158.0000},
  {x: 188.6670, y: 158.0000},
  {x: 192.0000, y: 158.0000},
  {x: 196.9346, y: 156.5996},
  {x: 203.7465, y: 156.0121},
  {x: 210.0000, y: 156.0000},
  {x: 213.6663, y: 156.0000},
  {x: 217.3337, y: 156.0000},
  {x: 221.0000, y: 156.0000},
  {x: 228.2424, y: 153.9520},
  {x: 240.0651, y: 155.0756},
  {x: 249.0000, y: 155.0000}
];
