import { styler, translate, scale, rotate } from 'free-transform'

export default {
  name: 'FreeTfCtrl',

  props: {
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    top: {
      type: Number,
      required: true
    },
    left: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    scaleX: {
      type: Number,
      required: true
    },
    scaleY: {
      type: Number,
      required: true
    },
    angle: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      offsetX: 0,
      offsetY: 0,
      scaleLimit: 0.1,
      aspectRatio: false,
      disableScale: false,
      scaleFromCenter: false
    }
  },

  computed: {
    style: function() {
      const { controls } = styler({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        angle: this.angle,
        disableScale: this.disableScale
      });

      return {
        ...controls,
        top: `${this.top}px`,
        left: `${this.left}px`,
        width: `${controls.width}px`,
        height: `${controls.height}px`
      }
    }
  },

  methods: {
    onDrag: function(drag) {
      const up = () => {
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', up);
      };

      document.addEventListener('mousemove', drag);
      document.addEventListener('mouseup', up);
    },

    handleTranslation: function(event) {
      event.stopPropagation();

      const drag = translate({
        x: this.x,
        y: this.y,
        startX: event.pageX,
        startY: event.pageY
      }, (payload) => {
        this.$emit('update', payload);
      });

      this.onDrag(drag);
    },

    handleRotation: function(event) {
      event.stopPropagation();

      const drag = rotate({
        startX: event.pageX,
        startY: event.pageY,
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.width,
        height: this.height,
        angle: this.angle,
        offsetX: this.offsetX,
        offsetY: this.offsetY
      }, (payload) => {
        this.$emit('update', payload);
      });

      this.onDrag(drag);
    },

    handleScale: function(scaleType, event) {
      event.stopPropagation();
      event.preventDefault();

      const drag = scale(scaleType, {
        startX: event.pageX,
        startY: event.pageY,
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.width,
        height: this.height,
        angle: this.angle,
        scaleLimit: this.scaleLimit,
        scaleFromCenter: this.scaleFromCenter && event.altKey,
        enableScaleFromCenter: this.scaleFromCenter,
        aspectRatio: this.aspectRatio && event.shiftKey,
        enableAspectRatio: this.aspectRatio
      }, (payload) => {
        this.$emit('update', payload);
      });

      this.onDrag(drag);
    }
  }
}
