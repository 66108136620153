var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"areaDropdown",staticClass:"area-dropdown"},[_c('SneakerIcon',{staticClass:"area-dropdown__icon",attrs:{"area":_vm.option,"active":true}}),(_vm.section == 'join')?_c('span',{staticClass:"area-dropdown__name full-version"},[_vm._v(" "+_vm._s(_vm.joinOptions[_vm.option].name)+" ")]):_vm._e(),(_vm.section == 'single')?_c('span',{staticClass:"area-dropdown__name full-version"},[_vm._v(" "+_vm._s(_vm.singleOptions[_vm.option].name)+" ")]):_vm._e(),_c('div',{class:[
      'area-dropdown__chevron',
      _vm.isOptions
        ? 'area-dropdown__chevron--active'
        : null
    ],on:{"click":function($event){return _vm.switchOptions()}}},[_c('ChevronDown',{staticClass:"chevron__icon"})],1),_c('div',{class:[
      'area-dropdown__options',
      _vm.isOptions
        ? 'area-dropdown__options--active'
        : null
    ]},[(Object.keys(_vm.joinOptions).length !== 0)?_c('div',{staticClass:"area-dropdown__header"},[_c('span',{staticClass:"header__name"},[_vm._v(" Join Pieces ")])]):_vm._e(),_vm._l((_vm.joinOptions),function(option,key){return _c('div',{key:'area-dropdown-' + key,staticClass:"area-dropdown__option"},[_c('div',{staticClass:"option__selectable",on:{"click":function($event){return _vm.selectOption(key, 'join')}}},[_c('SneakerIcon',{staticClass:"option__icon",attrs:{"area":key,"active":option.hasData}}),_c('span',{staticClass:"option__name"},[_vm._v(" "+_vm._s(option.name)+" ")])],1),(option.hasData)?_c('div',{staticClass:"option__actions"},[_c('div',{staticClass:"actions__item",on:{"click":function($event){return _vm.switchVisibility(key)}}},[(!option.visible)?_c('EyeIcon',{staticClass:"actions__icon"}):_c('EyeOffIcon',{staticClass:"actions__icon"})],1),_c('div',{staticClass:"actions__item",on:{"click":function($event){return _vm.restartArea(key)}}},[_c('TrashIcon',{staticClass:"actions__icon"})],1)]):_vm._e()])}),_vm._m(0),_vm._l((_vm.singleOptions),function(option,key){return _c('div',{key:'area-dropdown-' + key,staticClass:"area-dropdown__option"},[_c('div',{staticClass:"option__selectable",on:{"click":function($event){return _vm.selectOption(key, 'single')}}},[_c('SneakerIcon',{staticClass:"option__icon",attrs:{"area":key,"active":option.hasData}}),_c('span',{staticClass:"option__name"},[_vm._v(" "+_vm._s(option.name)+" ")])],1),(option.hasData)?_c('div',{staticClass:"option__actions"},[_c('div',{staticClass:"actions__item",on:{"click":function($event){return _vm.switchVisibility(key)}}},[(!option.visible)?_c('EyeIcon',{staticClass:"actions__icon"}):_c('EyeOffIcon',{staticClass:"actions__icon"})],1),_c('div',{staticClass:"actions__item",on:{"click":function($event){return _vm.restartArea(key)}}},[_c('TrashIcon',{staticClass:"actions__icon"})],1)]):_vm._e()])})],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"area-dropdown__header"},[_c('span',{staticClass:"header__name"},[_vm._v(" Single Pieces ")])])}]

export { render, staticRenderFns }