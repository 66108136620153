var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-picker"},[_c('div',{staticClass:"text-picker__container"},_vm._l((_vm.items),function(item,key){return _c('div',{class:[
        'text-picker__item',
        item.selected
          ? 'text-picker__item--active'
          : null
      ],on:{"click":function($event){return _vm.selectText(key)}}},[_c('span',{class:[
          'item__text',
          'item__text--' + item.font
        ]},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"item__actions"},[_c('div',{staticClass:"actions__control",on:{"click":function($event){return _vm.switchVisibility(key)}}},[(item.visible)?_c('EyeOffIcon',{staticClass:"control__icon"}):_c('EyeIcon',{staticClass:"control__icon"})],1),_c('div',{staticClass:"actions__control",on:{"click":function($event){return _vm.deleteText(key)}}},[_c('TrashIcon',{staticClass:"control__icon"})],1),_c('ButtonIcon',{staticClass:"actions__edit",attrs:{"type":"light","shape":"square"},on:{"click":function($event){return _vm.editText(key)}}},[_c('PencilIcon',{staticClass:"edit__icon"})],1)],1)])}),0),(!_vm.isLimit && !_vm.items.length)?_c('Button',{staticClass:"text-picker__add text-picker__add--full",attrs:{"type":"dark","shape":"square","value":"Add Text","direction":"right"},on:{"click":function($event){return _vm.showTextFont()}}},[_c('PlusIcon',{staticClass:"add__icon"})],1):_vm._e(),(!_vm.isLimit && _vm.items.length)?_c('ButtonIcon',{staticClass:"text-picker__add text-picker__add--small",attrs:{"type":"dark"},on:{"click":function($event){return _vm.showTextFont()}}},[_c('PlusIcon',{staticClass:"add__icon"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }