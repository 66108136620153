module.exports = [
  {x: 218.0000, y: 28.0000},
  {x: 218.0000, y: 27.3334},
  {x: 218.0000, y: 26.6666},
  {x: 218.0000, y: 26.0000},
  {x: 215.2661, y: 27.1340},
  {x: 210.8352, y: 28.8251},
  {x: 209.0000, y: 31.0000},
  {x: 209.6666, y: 31.0000},
  {x: 210.3334, y: 31.0000},
  {x: 211.0000, y: 31.0000},
  {x: 213.0801, y: 34.0792},
  {x: 216.8140, y: 33.9344},
  {x: 218.0000, y: 38.0000},
  {x: 218.3333, y: 38.0000},
  {x: 218.6667, y: 38.0000},
  {x: 219.0000, y: 38.0000},
  {x: 219.0000, y: 39.3332},
  {x: 219.0000, y: 40.6668},
  {x: 219.0000, y: 42.0000},
  {x: 219.5959, y: 45.4651},
  {x: 219.9856, y: 48.2927},
  {x: 217.0000, y: 51.0000},
  {x: 217.0000, y: 51.3333},
  {x: 217.0000, y: 51.6667},
  {x: 217.0000, y: 52.0000},
  {x: 215.3335, y: 52.0000},
  {x: 213.6665, y: 52.0000},
  {x: 212.0000, y: 52.0000},
  {x: 212.0000, y: 51.6667},
  {x: 212.0000, y: 51.3333},
  {x: 212.0000, y: 51.0000},
  {x: 210.6064, y: 50.1791},
  {x: 206.6183, y: 50.8226},
  {x: 205.0000, y: 50.0000},
  {x: 205.0000, y: 49.6667},
  {x: 205.0000, y: 49.3333},
  {x: 205.0000, y: 49.0000},
  {x: 203.0002, y: 48.3334},
  {x: 200.9998, y: 47.6666},
  {x: 199.0000, y: 47.0000},
  {x: 198.6667, y: 46.3334},
  {x: 198.3333, y: 45.6666},
  {x: 198.0000, y: 45.0000},
  {x: 195.0132, y: 43.1464},
  {x: 191.8198, y: 43.0413},
  {x: 187.0000, y: 43.0000},
  {x: 185.3407, y: 45.7002},
  {x: 183.0050, y: 45.5655},
  {x: 182.0000, y: 49.0000},
  {x: 183.4067, y: 49.2483},
  {x: 189.6820, y: 52.4435},
  {x: 190.0000, y: 53.0000},
  {x: 190.0000, y: 53.6666},
  {x: 190.0000, y: 54.3334},
  {x: 190.0000, y: 55.0000},
  {x: 190.3333, y: 55.0000},
  {x: 190.6667, y: 55.0000},
  {x: 191.0000, y: 55.0000},
  {x: 191.0000, y: 57.6664},
  {x: 191.0000, y: 60.3336},
  {x: 191.0000, y: 63.0000},
  {x: 190.1011, y: 63.5200},
  {x: 188.0000, y: 66.0000},
  {x: 184.3337, y: 66.0000},
  {x: 180.6663, y: 66.0000},
  {x: 177.0000, y: 66.0000},
  {x: 177.0000, y: 65.6667},
  {x: 177.0000, y: 65.3333},
  {x: 177.0000, y: 65.0000},
  {x: 173.6670, y: 64.0001},
  {x: 170.3330, y: 62.9999},
  {x: 167.0000, y: 62.0000},
  {x: 167.0000, y: 62.3333},
  {x: 167.0000, y: 62.6667},
  {x: 167.0000, y: 63.0000},
  {x: 163.8404, y: 63.7629},
  {x: 163.3495, y: 65.2268},
  {x: 160.0000, y: 66.0000},
  {x: 161.0174, y: 69.3353},
  {x: 163.0826, y: 70.5893},
  {x: 165.0000, y: 73.0000},
  {x: 165.3333, y: 73.0000},
  {x: 165.6667, y: 73.0000},
  {x: 166.0000, y: 73.0000},
  {x: 166.0000, y: 73.6666},
  {x: 166.0000, y: 74.3334},
  {x: 166.0000, y: 75.0000},
  {x: 166.3333, y: 75.0000},
  {x: 166.6667, y: 75.0000},
  {x: 167.0000, y: 75.0000},
  {x: 169.1884, y: 80.9153},
  {x: 165.0134, y: 86.8651},
  {x: 162.0000, y: 88.0000},
  {x: 159.6669, y: 88.0000},
  {x: 157.3331, y: 88.0000},
  {x: 155.0000, y: 88.0000},
  {x: 154.3334, y: 87.0001},
  {x: 153.6666, y: 85.9999},
  {x: 153.0000, y: 85.0000},
  {x: 151.8728, y: 84.1966},
  {x: 148.7398, y: 84.2051},
  {x: 148.0000, y: 83.0000},
  {x: 148.0000, y: 82.3334},
  {x: 148.0000, y: 81.6666},
  {x: 148.0000, y: 81.0000},
  {x: 141.9634, y: 79.7081},
  {x: 140.8372, y: 74.5600},
  {x: 135.0000, y: 73.0000},
  {x: 133.3984, y: 75.3931},
  {x: 130.5865, y: 75.5956},
  {x: 129.0000, y: 78.0000},
  {x: 132.3146, y: 78.8506},
  {x: 131.9006, y: 80.2633},
  {x: 134.0000, y: 82.0000},
  {x: 134.0593, y: 86.3237},
  {x: 133.5881, y: 89.9071},
  {x: 133.0000, y: 93.0000},
  {x: 131.1988, y: 93.9445},
  {x: 131.4255, y: 94.3847},
  {x: 129.0000, y: 95.0000},
  {x: 128.0860, y: 95.7388},
  {x: 128.5345, y: 96.4533},
  {x: 126.0000, y: 96.0000},
  {x: 126.0000, y: 95.6667},
  {x: 126.0000, y: 95.3333},
  {x: 126.0000, y: 95.0000},
  {x: 125.0001, y: 95.3333},
  {x: 123.9999, y: 95.6667},
  {x: 123.0000, y: 96.0000},
  {x: 123.0000, y: 95.6667},
  {x: 123.0000, y: 95.3333},
  {x: 123.0000, y: 95.0000},
  {x: 122.3334, y: 95.0000},
  {x: 121.6666, y: 95.0000},
  {x: 121.0000, y: 95.0000},
  {x: 120.3334, y: 94.0001},
  {x: 119.6666, y: 92.9999},
  {x: 119.0000, y: 92.0000},
  {x: 118.0001, y: 92.0000},
  {x: 116.9999, y: 92.0000},
  {x: 116.0000, y: 92.0000},
  {x: 115.6667, y: 91.3334},
  {x: 115.3333, y: 90.6666},
  {x: 115.0000, y: 90.0000},
  {x: 114.3334, y: 90.0000},
  {x: 113.6666, y: 90.0000},
  {x: 113.0000, y: 90.0000},
  {x: 113.0000, y: 89.6667},
  {x: 113.0000, y: 89.3333},
  {x: 113.0000, y: 89.0000},
  {x: 112.0001, y: 89.3333},
  {x: 110.9999, y: 89.6667},
  {x: 110.0000, y: 90.0000},
  {x: 110.0000, y: 90.3333},
  {x: 110.0000, y: 90.6667},
  {x: 110.0000, y: 91.0000},
  {x: 109.3334, y: 91.0000},
  {x: 108.6666, y: 91.0000},
  {x: 108.0000, y: 91.0000},
  {x: 108.0000, y: 91.3333},
  {x: 108.0000, y: 91.6667},
  {x: 108.0000, y: 92.0000},
  {x: 106.0002, y: 92.6666},
  {x: 103.9998, y: 93.3334},
  {x: 102.0000, y: 94.0000},
  {x: 102.0000, y: 94.6666},
  {x: 102.0000, y: 95.3334},
  {x: 102.0000, y: 96.0000},
  {x: 102.6666, y: 96.0000},
  {x: 103.3334, y: 96.0000},
  {x: 104.0000, y: 96.0000},
  {x: 105.5051, y: 98.6300},
  {x: 107.1892, y: 99.3777},
  {x: 108.0000, y: 103.0000},
  {x: 108.3333, y: 103.0000},
  {x: 108.6667, y: 103.0000},
  {x: 109.0000, y: 103.0000},
  {x: 109.0208, y: 103.5960},
  {x: 108.1125, y: 104.8496},
  {x: 108.0000, y: 107.0000},
  {x: 106.0002, y: 108.6665},
  {x: 103.9998, y: 110.3335},
  {x: 102.0000, y: 112.0000},
  {x: 99.0003, y: 112.0000},
  {x: 95.9997, y: 112.0000},
  {x: 93.0000, y: 112.0000},
  {x: 93.0000, y: 111.3334},
  {x: 93.0000, y: 110.6666},
  {x: 93.0000, y: 110.0000},
  {x: 87.4692, y: 109.2328},
  {x: 89.8994, y: 108.0244},
  {x: 87.0000, y: 106.0000},
  {x: 84.5625, y: 104.2981},
  {x: 82.4344, y: 103.7352},
  {x: 79.0000, y: 103.0000},
  {x: 79.0000, y: 102.6667},
  {x: 79.0000, y: 102.3333},
  {x: 79.0000, y: 102.0000},
  {x: 78.6667, y: 102.0000},
  {x: 78.3333, y: 102.0000},
  {x: 78.0000, y: 102.0000},
  {x: 78.0000, y: 102.3333},
  {x: 78.0000, y: 102.6667},
  {x: 78.0000, y: 103.0000},
  {x: 75.6669, y: 103.6666},
  {x: 73.3331, y: 104.3334},
  {x: 71.0000, y: 105.0000},
  {x: 71.7435, y: 107.7177},
  {x: 72.6533, y: 108.0445},
  {x: 74.0000, y: 110.0000},
  {x: 74.3333, y: 110.0000},
  {x: 74.6667, y: 110.0000},
  {x: 75.0000, y: 110.0000},
  {x: 75.0000, y: 112.6664},
  {x: 75.0000, y: 115.3336},
  {x: 75.0000, y: 118.0000},
  {x: 73.8614, y: 119.1393},
  {x: 74.6016, y: 118.0036},
  {x: 74.0000, y: 120.0000},
  {x: 73.0001, y: 120.3333},
  {x: 71.9999, y: 120.6667},
  {x: 71.0000, y: 121.0000},
  {x: 71.0000, y: 121.3333},
  {x: 71.0000, y: 121.6667},
  {x: 71.0000, y: 122.0000},
  {x: 70.3334, y: 122.0000},
  {x: 69.6666, y: 122.0000},
  {x: 69.0000, y: 122.0000},
  {x: 69.0000, y: 122.3333},
  {x: 69.0000, y: 122.6667},
  {x: 69.0000, y: 123.0000},
  {x: 62.5823, y: 125.2914},
  {x: 56.8835, y: 119.0882},
  {x: 54.0000, y: 121.0000},
  {x: 53.0001, y: 121.3333},
  {x: 51.9999, y: 121.6667},
  {x: 51.0000, y: 122.0000},
  {x: 51.0000, y: 122.3333},
  {x: 51.0000, y: 122.6667},
  {x: 51.0000, y: 123.0000},
  {x: 47.9360, y: 125.0142},
  {x: 46.4870, y: 124.2371},
  {x: 46.0000, y: 129.0000},
  {x: 46.3333, y: 129.0000},
  {x: 46.6667, y: 129.0000},
  {x: 47.0000, y: 129.0000},
  {x: 48.3410, y: 131.4866},
  {x: 44.5036, y: 137.2225},
  {x: 44.0000, y: 139.0000},
  {x: 40.0335, y: 139.9918},
  {x: 40.3730, y: 141.6720},
  {x: 35.0000, y: 142.0000},
  {x: 35.0000, y: 141.6667},
  {x: 35.0000, y: 141.3333},
  {x: 35.0000, y: 141.0000},
  {x: 34.3334, y: 141.0000},
  {x: 33.6666, y: 141.0000},
  {x: 33.0000, y: 141.0000},
  {x: 33.0000, y: 140.6667},
  {x: 33.0000, y: 140.3333},
  {x: 33.0000, y: 140.0000},
  {x: 31.4634, y: 139.3861},
  {x: 29.1517, y: 141.1697},
  {x: 29.0000, y: 141.0000},
  {x: 29.0000, y: 140.3334},
  {x: 29.0000, y: 139.6666},
  {x: 29.0000, y: 139.0000},
  {x: 30.3145, y: 138.1908},
  {x: 30.1777, y: 138.2958},
  {x: 31.0000, y: 137.0000},
  {x: 30.3334, y: 137.0000},
  {x: 29.6666, y: 137.0000},
  {x: 29.0000, y: 137.0000},
  {x: 22.5099, y: 144.4332},
  {x: 5.9087, y: 144.5226},
  {x: 1.0000, y: 153.0000},
  {x: 3.6661, y: 154.7732},
  {x: 3.3013, y: 156.9901},
  {x: 7.0000, y: 158.0000},
  {x: 9.8831, y: 160.7219},
  {x: 24.0240, y: 157.2072},
  {x: 27.0000, y: 156.0000},
  {x: 27.0000, y: 155.6667},
  {x: 27.0000, y: 155.3333},
  {x: 27.0000, y: 155.0000},
  {x: 27.9999, y: 155.0000},
  {x: 29.0001, y: 155.0000},
  {x: 30.0000, y: 155.0000},
  {x: 30.0000, y: 154.6667},
  {x: 30.0000, y: 154.3333},
  {x: 30.0000, y: 154.0000},
  {x: 31.6665, y: 153.6667},
  {x: 33.3335, y: 153.3333},
  {x: 35.0000, y: 153.0000},
  {x: 35.0000, y: 152.6667},
  {x: 35.0000, y: 152.3333},
  {x: 35.0000, y: 152.0000},
  {x: 35.6666, y: 152.0000},
  {x: 36.3334, y: 152.0000},
  {x: 37.0000, y: 152.0000},
  {x: 37.0000, y: 151.6667},
  {x: 37.0000, y: 151.3333},
  {x: 37.0000, y: 151.0000},
  {x: 38.9998, y: 150.6667},
  {x: 41.0002, y: 150.3333},
  {x: 43.0000, y: 150.0000},
  {x: 43.0000, y: 149.6667},
  {x: 43.0000, y: 149.3333},
  {x: 43.0000, y: 149.0000},
  {x: 43.6666, y: 149.0000},
  {x: 44.3334, y: 149.0000},
  {x: 45.0000, y: 149.0000},
  {x: 45.0000, y: 148.6667},
  {x: 45.0000, y: 148.3333},
  {x: 45.0000, y: 148.0000},
  {x: 45.6666, y: 148.0000},
  {x: 46.3334, y: 148.0000},
  {x: 47.0000, y: 148.0000},
  {x: 47.0000, y: 147.6667},
  {x: 47.0000, y: 147.3333},
  {x: 47.0000, y: 147.0000},
  {x: 47.6666, y: 147.0000},
  {x: 48.3334, y: 147.0000},
  {x: 49.0000, y: 147.0000},
  {x: 49.0000, y: 146.6667},
  {x: 49.0000, y: 146.3333},
  {x: 49.0000, y: 146.0000},
  {x: 49.6666, y: 146.0000},
  {x: 50.3334, y: 146.0000},
  {x: 51.0000, y: 146.0000},
  {x: 51.0000, y: 145.6667},
  {x: 51.0000, y: 145.3333},
  {x: 51.0000, y: 145.0000},
  {x: 51.6666, y: 145.0000},
  {x: 52.3334, y: 145.0000},
  {x: 53.0000, y: 145.0000},
  {x: 53.0000, y: 144.6667},
  {x: 53.0000, y: 144.3333},
  {x: 53.0000, y: 144.0000},
  {x: 53.6666, y: 144.0000},
  {x: 54.3334, y: 144.0000},
  {x: 55.0000, y: 144.0000},
  {x: 55.0000, y: 143.6667},
  {x: 55.0000, y: 143.3333},
  {x: 55.0000, y: 143.0000},
  {x: 55.6666, y: 143.0000},
  {x: 56.3334, y: 143.0000},
  {x: 57.0000, y: 143.0000},
  {x: 57.0000, y: 142.6667},
  {x: 57.0000, y: 142.3333},
  {x: 57.0000, y: 142.0000},
  {x: 58.9998, y: 141.3334},
  {x: 61.0002, y: 140.6666},
  {x: 63.0000, y: 140.0000},
  {x: 63.0000, y: 139.6667},
  {x: 63.0000, y: 139.3333},
  {x: 63.0000, y: 139.0000},
  {x: 68.9994, y: 137.0002},
  {x: 75.0006, y: 134.9998},
  {x: 81.0000, y: 133.0000},
  {x: 92.6655, y: 130.0003},
  {x: 104.3345, y: 126.9997},
  {x: 116.0000, y: 124.0000},
  {x: 116.0000, y: 123.6667},
  {x: 116.0000, y: 123.3333},
  {x: 116.0000, y: 123.0000},
  {x: 117.3332, y: 122.6667},
  {x: 118.6668, y: 122.3333},
  {x: 120.0000, y: 122.0000},
  {x: 120.0000, y: 121.6667},
  {x: 120.0000, y: 121.3333},
  {x: 120.0000, y: 121.0000},
  {x: 120.6666, y: 121.0000},
  {x: 121.3334, y: 121.0000},
  {x: 122.0000, y: 121.0000},
  {x: 122.3333, y: 120.3334},
  {x: 122.6667, y: 119.6666},
  {x: 123.0000, y: 119.0000},
  {x: 124.3332, y: 118.6667},
  {x: 125.6668, y: 118.3333},
  {x: 127.0000, y: 118.0000},
  {x: 127.9999, y: 116.6668},
  {x: 129.0001, y: 115.3332},
  {x: 130.0000, y: 114.0000},
  {x: 131.3332, y: 113.6667},
  {x: 132.6668, y: 113.3333},
  {x: 134.0000, y: 113.0000},
  {x: 134.6666, y: 112.0001},
  {x: 135.3334, y: 110.9999},
  {x: 136.0000, y: 110.0000},
  {x: 136.6666, y: 110.0000},
  {x: 137.3334, y: 110.0000},
  {x: 138.0000, y: 110.0000},
  {x: 138.3333, y: 109.3334},
  {x: 138.6667, y: 108.6666},
  {x: 139.0000, y: 108.0000},
  {x: 139.6666, y: 108.0000},
  {x: 140.3334, y: 108.0000},
  {x: 141.0000, y: 108.0000},
  {x: 141.0000, y: 107.6667},
  {x: 141.0000, y: 107.3333},
  {x: 141.0000, y: 107.0000},
  {x: 141.9999, y: 107.0000},
  {x: 143.0001, y: 107.0000},
  {x: 144.0000, y: 107.0000},
  {x: 144.0000, y: 106.6667},
  {x: 144.0000, y: 106.3333},
  {x: 144.0000, y: 106.0000},
  {x: 144.6666, y: 106.0000},
  {x: 145.3334, y: 106.0000},
  {x: 146.0000, y: 106.0000},
  {x: 146.0000, y: 105.6667},
  {x: 146.0000, y: 105.3333},
  {x: 146.0000, y: 105.0000},
  {x: 147.9998, y: 104.3334},
  {x: 150.0002, y: 103.6666},
  {x: 152.0000, y: 103.0000},
  {x: 152.0000, y: 102.6667},
  {x: 152.0000, y: 102.3333},
  {x: 152.0000, y: 102.0000},
  {x: 152.9999, y: 102.0000},
  {x: 154.0001, y: 102.0000},
  {x: 155.0000, y: 102.0000},
  {x: 155.0000, y: 101.6667},
  {x: 155.0000, y: 101.3333},
  {x: 155.0000, y: 101.0000},
  {x: 155.9999, y: 101.0000},
  {x: 157.0001, y: 101.0000},
  {x: 158.0000, y: 101.0000},
  {x: 158.0000, y: 100.6667},
  {x: 158.0000, y: 100.3333},
  {x: 158.0000, y: 100.0000},
  {x: 159.6665, y: 100.0000},
  {x: 161.3335, y: 100.0000},
  {x: 163.0000, y: 100.0000},
  {x: 163.0000, y: 99.6667},
  {x: 163.0000, y: 99.3333},
  {x: 163.0000, y: 99.0000},
  {x: 164.3332, y: 99.0000},
  {x: 165.6668, y: 99.0000},
  {x: 167.0000, y: 99.0000},
  {x: 167.0000, y: 98.6667},
  {x: 167.0000, y: 98.3333},
  {x: 167.0000, y: 98.0000},
  {x: 168.9998, y: 97.3334},
  {x: 171.0002, y: 96.6666},
  {x: 173.0000, y: 96.0000},
  {x: 173.0000, y: 95.6667},
  {x: 173.0000, y: 95.3333},
  {x: 173.0000, y: 95.0000},
  {x: 173.6666, y: 95.0000},
  {x: 174.3334, y: 95.0000},
  {x: 175.0000, y: 95.0000},
  {x: 175.0000, y: 94.6667},
  {x: 175.0000, y: 94.3333},
  {x: 175.0000, y: 94.0000},
  {x: 175.6666, y: 94.0000},
  {x: 176.3334, y: 94.0000},
  {x: 177.0000, y: 94.0000},
  {x: 177.0000, y: 93.6667},
  {x: 177.0000, y: 93.3333},
  {x: 177.0000, y: 93.0000},
  {x: 177.9999, y: 93.0000},
  {x: 179.0001, y: 93.0000},
  {x: 180.0000, y: 93.0000},
  {x: 180.0000, y: 92.6667},
  {x: 180.0000, y: 92.3333},
  {x: 180.0000, y: 92.0000},
  {x: 180.6666, y: 92.0000},
  {x: 181.3334, y: 92.0000},
  {x: 182.0000, y: 92.0000},
  {x: 182.0000, y: 91.6667},
  {x: 182.0000, y: 91.3333},
  {x: 182.0000, y: 91.0000},
  {x: 182.6666, y: 91.0000},
  {x: 183.3334, y: 91.0000},
  {x: 184.0000, y: 91.0000},
  {x: 184.3333, y: 90.3334},
  {x: 184.6667, y: 89.6666},
  {x: 185.0000, y: 89.0000},
  {x: 185.6666, y: 89.0000},
  {x: 186.3334, y: 89.0000},
  {x: 187.0000, y: 89.0000},
  {x: 187.3333, y: 88.3334},
  {x: 187.6667, y: 87.6666},
  {x: 188.0000, y: 87.0000},
  {x: 188.6666, y: 87.0000},
  {x: 189.3334, y: 87.0000},
  {x: 190.0000, y: 87.0000},
  {x: 190.0000, y: 86.6667},
  {x: 190.0000, y: 86.3333},
  {x: 190.0000, y: 86.0000},
  {x: 190.9999, y: 85.6667},
  {x: 192.0001, y: 85.3333},
  {x: 193.0000, y: 85.0000},
  {x: 193.3333, y: 84.3334},
  {x: 193.6667, y: 83.6666},
  {x: 194.0000, y: 83.0000},
  {x: 194.6666, y: 83.0000},
  {x: 195.3334, y: 83.0000},
  {x: 196.0000, y: 83.0000},
  {x: 196.6666, y: 82.0001},
  {x: 197.3334, y: 80.9999},
  {x: 198.0000, y: 80.0000},
  {x: 198.6666, y: 80.0000},
  {x: 199.3334, y: 80.0000},
  {x: 200.0000, y: 80.0000},
  {x: 200.6666, y: 79.0001},
  {x: 201.3334, y: 77.9999},
  {x: 202.0000, y: 77.0000},
  {x: 202.6666, y: 77.0000},
  {x: 203.3334, y: 77.0000},
  {x: 204.0000, y: 77.0000},
  {x: 204.6666, y: 76.0001},
  {x: 205.3334, y: 74.9999},
  {x: 206.0000, y: 74.0000},
  {x: 207.3332, y: 73.6667},
  {x: 208.6668, y: 73.3333},
  {x: 210.0000, y: 73.0000},
  {x: 210.3333, y: 72.3334},
  {x: 210.6667, y: 71.6666},
  {x: 211.0000, y: 71.0000},
  {x: 211.9999, y: 70.6667},
  {x: 213.0001, y: 70.3333},
  {x: 214.0000, y: 70.0000},
  {x: 214.0000, y: 69.6667},
  {x: 214.0000, y: 69.3333},
  {x: 214.0000, y: 69.0000},
  {x: 214.6666, y: 69.0000},
  {x: 215.3334, y: 69.0000},
  {x: 216.0000, y: 69.0000},
  {x: 216.0000, y: 68.6667},
  {x: 216.0000, y: 68.3333},
  {x: 216.0000, y: 68.0000},
  {x: 216.6666, y: 68.0000},
  {x: 217.3334, y: 68.0000},
  {x: 218.0000, y: 68.0000},
  {x: 218.0000, y: 67.6667},
  {x: 218.0000, y: 67.3333},
  {x: 218.0000, y: 67.0000},
  {x: 218.9999, y: 67.0000},
  {x: 220.0001, y: 67.0000},
  {x: 221.0000, y: 67.0000},
  {x: 221.3333, y: 66.3334},
  {x: 221.6667, y: 65.6666},
  {x: 222.0000, y: 65.0000},
  {x: 223.9998, y: 64.3334},
  {x: 226.0002, y: 63.6666},
  {x: 228.0000, y: 63.0000},
  {x: 228.3333, y: 62.3334},
  {x: 228.6667, y: 61.6666},
  {x: 229.0000, y: 61.0000},
  {x: 230.9998, y: 60.3334},
  {x: 233.0002, y: 59.6666},
  {x: 235.0000, y: 59.0000},
  {x: 235.3333, y: 58.3334},
  {x: 235.6667, y: 57.6666},
  {x: 236.0000, y: 57.0000},
  {x: 236.6666, y: 57.0000},
  {x: 237.3334, y: 57.0000},
  {x: 238.0000, y: 57.0000},
  {x: 238.6666, y: 56.0001},
  {x: 239.3334, y: 54.9999},
  {x: 240.0000, y: 54.0000},
  {x: 241.3332, y: 53.6667},
  {x: 242.6668, y: 53.3333},
  {x: 244.0000, y: 53.0000},
  {x: 244.3333, y: 52.3334},
  {x: 244.6667, y: 51.6666},
  {x: 245.0000, y: 51.0000},
  {x: 245.6666, y: 51.0000},
  {x: 246.3334, y: 51.0000},
  {x: 247.0000, y: 51.0000},
  {x: 250.9996, y: 46.6671},
  {x: 255.0004, y: 42.3329},
  {x: 259.0000, y: 38.0000},
  {x: 259.6666, y: 38.0000},
  {x: 260.3334, y: 38.0000},
  {x: 261.0000, y: 38.0000},
  {x: 261.6666, y: 37.0001},
  {x: 262.3334, y: 35.9999},
  {x: 263.0000, y: 35.0000},
  {x: 263.6666, y: 35.0000},
  {x: 264.3334, y: 35.0000},
  {x: 265.0000, y: 35.0000},
  {x: 265.3333, y: 34.3334},
  {x: 265.6667, y: 33.6666},
  {x: 266.0000, y: 33.0000},
  {x: 266.6666, y: 33.0000},
  {x: 267.3334, y: 33.0000},
  {x: 268.0000, y: 33.0000},
  {x: 268.3333, y: 32.3334},
  {x: 268.6667, y: 31.6666},
  {x: 269.0000, y: 31.0000},
  {x: 269.6666, y: 31.0000},
  {x: 270.3334, y: 31.0000},
  {x: 271.0000, y: 31.0000},
  {x: 271.0000, y: 30.6667},
  {x: 271.0000, y: 30.3333},
  {x: 271.0000, y: 30.0000},
  {x: 271.6666, y: 30.0000},
  {x: 272.3334, y: 30.0000},
  {x: 273.0000, y: 30.0000},
  {x: 273.0000, y: 29.6667},
  {x: 273.0000, y: 29.3333},
  {x: 273.0000, y: 29.0000},
  {x: 273.6666, y: 29.0000},
  {x: 274.3334, y: 29.0000},
  {x: 275.0000, y: 29.0000},
  {x: 275.0000, y: 28.6667},
  {x: 275.0000, y: 28.3333},
  {x: 275.0000, y: 28.0000},
  {x: 275.6666, y: 28.0000},
  {x: 276.3334, y: 28.0000},
  {x: 277.0000, y: 28.0000},
  {x: 277.3333, y: 27.3334},
  {x: 277.6667, y: 26.6666},
  {x: 278.0000, y: 26.0000},
  {x: 278.6666, y: 26.0000},
  {x: 279.3334, y: 26.0000},
  {x: 280.0000, y: 26.0000},
  {x: 280.0000, y: 25.6667},
  {x: 280.0000, y: 25.3333},
  {x: 280.0000, y: 25.0000},
  {x: 280.6666, y: 25.0000},
  {x: 281.3334, y: 25.0000},
  {x: 282.0000, y: 25.0000},
  {x: 282.0000, y: 24.6667},
  {x: 282.0000, y: 24.3333},
  {x: 282.0000, y: 24.0000},
  {x: 285.3330, y: 23.3334},
  {x: 288.6670, y: 22.6666},
  {x: 292.0000, y: 22.0000},
  {x: 291.1558, y: 20.8654},
  {x: 292.1268, y: 21.8546},
  {x: 291.0000, y: 21.0000},
  {x: 289.0082, y: 17.5566},
  {x: 287.8784, y: 18.0788},
  {x: 285.0000, y: 16.0000},
  {x: 283.6668, y: 14.3335},
  {x: 282.3332, y: 12.6665},
  {x: 281.0000, y: 11.0000},
  {x: 280.3334, y: 11.0000},
  {x: 279.6666, y: 11.0000},
  {x: 279.0000, y: 11.0000},
  {x: 278.0001, y: 9.6668},
  {x: 276.9999, y: 8.3332},
  {x: 276.0000, y: 7.0000},
  {x: 275.3334, y: 7.0000},
  {x: 274.6666, y: 7.0000},
  {x: 274.0000, y: 7.0000},
  {x: 273.6667, y: 6.3334},
  {x: 273.3333, y: 5.6666},
  {x: 273.0000, y: 5.0000},
  {x: 269.1028, y: 2.4109},
  {x: 265.4183, y: 1.0589},
  {x: 259.0000, y: 1.0000},
  {x: 257.2255, y: 2.9766},
  {x: 257.0521, y: 1.8431},
  {x: 255.0000, y: 3.0000},
  {x: 255.0000, y: 3.3333},
  {x: 255.0000, y: 3.6667},
  {x: 255.0000, y: 4.0000},
  {x: 254.3334, y: 4.0000},
  {x: 253.6666, y: 4.0000},
  {x: 253.0000, y: 4.0000},
  {x: 253.0000, y: 4.3333},
  {x: 253.0000, y: 4.6667},
  {x: 253.0000, y: 5.0000},
  {x: 252.0001, y: 5.3333},
  {x: 250.9999, y: 5.6667},
  {x: 250.0000, y: 6.0000},
  {x: 249.6667, y: 6.6666},
  {x: 249.3333, y: 7.3334},
  {x: 249.0000, y: 8.0000},
  {x: 248.3334, y: 8.0000},
  {x: 247.6666, y: 8.0000},
  {x: 247.0000, y: 8.0000},
  {x: 244.6669, y: 10.6664},
  {x: 242.3331, y: 13.3336},
  {x: 240.0000, y: 16.0000},
  {x: 239.3334, y: 16.0000},
  {x: 238.6666, y: 16.0000},
  {x: 238.0000, y: 16.0000},
  {x: 237.3334, y: 16.9999},
  {x: 236.6666, y: 18.0001},
  {x: 236.0000, y: 19.0000},
  {x: 235.3334, y: 19.0000},
  {x: 234.6666, y: 19.0000},
  {x: 234.0000, y: 19.0000},
  {x: 233.6667, y: 19.6666},
  {x: 233.3333, y: 20.3334},
  {x: 233.0000, y: 21.0000},
  {x: 232.3334, y: 21.0000},
  {x: 231.6666, y: 21.0000},
  {x: 231.0000, y: 21.0000},
  {x: 230.3334, y: 21.9999},
  {x: 229.6666, y: 23.0001},
  {x: 229.0000, y: 24.0000},
  {x: 226.1820, y: 25.9658},
  {x: 223.1214, y: 26.4218},
  {x: 221.0000, y: 29.0000},
  {x: 220.0001, y: 28.6667},
  {x: 218.9999, y: 28.3333},
  {x: 218.0000, y: 28.0000}
];
