var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'button',
    'button--' + _vm.type,
    'button--' + _vm.shape,
    'button--' + _vm.direction,
    !_vm.unpadding
      ? 'button--padding'
      : null,
    _vm.active
      ? 'active'
      : null ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{staticClass:"button__value"},[_vm._v(" "+_vm._s(_vm.value)+" ")]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }