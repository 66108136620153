import ArrowLeft from '@/components/icons/arrowLeft'
import ArrowLeftMobile from '@/components/icons/arrowLeft_mobile'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'
import CloseIcon from '@/components/icons/close'

import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'PickSneakerColor',

  mixins: [Mixins],

  components: {
    ArrowLeft,
    ArrowLeftMobile,
    Button,
    ButtonIcon,
    CloseIcon
  },

  methods: {
    close: function() {
      EventBus.$emit('hidePickSneakerColor');
    },

    showPickerSneakerCustom: function() {
      this.close();
      
      EventBus.$emit('showPickSneakerCustom');
    }
  }
}
