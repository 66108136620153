module.exports = [
  {x: 222.0000, y: 16.0000},
  {x: 223.6583, y: 24.9065},
  {x: 225.4158, y: 36.5176},
  {x: 222.0000, y: 45.0000},
  {x: 222.0000, y: 45.9999},
  {x: 222.0000, y: 47.0001},
  {x: 222.0000, y: 48.0000},
  {x: 221.3334, y: 48.3333},
  {x: 220.6666, y: 48.6667},
  {x: 220.0000, y: 49.0000},
  {x: 218.3364, y: 50.7917},
  {x: 215.2323, y: 53.9913},
  {x: 213.0000, y: 55.0000},
  {x: 212.0001, y: 55.0000},
  {x: 210.9999, y: 55.0000},
  {x: 210.0000, y: 55.0000},
  {x: 210.0000, y: 55.3333},
  {x: 210.0000, y: 55.6667},
  {x: 210.0000, y: 56.0000},
  {x: 209.0001, y: 56.0000},
  {x: 207.9999, y: 56.0000},
  {x: 207.0000, y: 56.0000},
  {x: 207.0000, y: 56.3333},
  {x: 207.0000, y: 56.6667},
  {x: 207.0000, y: 57.0000},
  {x: 205.6668, y: 57.0000},
  {x: 204.3332, y: 57.0000},
  {x: 203.0000, y: 57.0000},
  {x: 203.0000, y: 57.3333},
  {x: 203.0000, y: 57.6667},
  {x: 203.0000, y: 58.0000},
  {x: 201.0002, y: 58.0000},
  {x: 198.9998, y: 58.0000},
  {x: 197.0000, y: 58.0000},
  {x: 197.0000, y: 58.3333},
  {x: 197.0000, y: 58.6667},
  {x: 197.0000, y: 59.0000},
  {x: 194.3336, y: 59.0000},
  {x: 191.6664, y: 59.0000},
  {x: 189.0000, y: 59.0000},
  {x: 189.0000, y: 59.3333},
  {x: 189.0000, y: 59.6667},
  {x: 189.0000, y: 60.0000},
  {x: 185.6670, y: 60.0000},
  {x: 182.3330, y: 60.0000},
  {x: 179.0000, y: 60.0000},
  {x: 179.0000, y: 60.3333},
  {x: 179.0000, y: 60.6667},
  {x: 179.0000, y: 61.0000},
  {x: 174.3338, y: 61.0000},
  {x: 169.6662, y: 61.0000},
  {x: 165.0000, y: 61.0000},
  {x: 157.5763, y: 63.0519},
  {x: 129.4082, y: 63.0825},
  {x: 122.0000, y: 61.0000},
  {x: 118.6670, y: 61.0000},
  {x: 115.3330, y: 61.0000},
  {x: 112.0000, y: 61.0000},
  {x: 106.3339, y: 60.6667},
  {x: 100.6661, y: 60.3333},
  {x: 95.0000, y: 60.0000},
  {x: 95.0000, y: 59.6667},
  {x: 95.0000, y: 59.3333},
  {x: 95.0000, y: 59.0000},
  {x: 84.3344, y: 58.0001},
  {x: 73.6656, y: 56.9999},
  {x: 63.0000, y: 56.0000},
  {x: 56.1277, y: 53.9945},
  {x: 46.6691, y: 53.1926},
  {x: 40.0000, y: 51.0000},
  {x: 38.6668, y: 51.0000},
  {x: 37.3332, y: 51.0000},
  {x: 36.0000, y: 51.0000},
  {x: 36.0000, y: 50.6667},
  {x: 36.0000, y: 50.3333},
  {x: 36.0000, y: 50.0000},
  {x: 35.0001, y: 50.0000},
  {x: 33.9999, y: 50.0000},
  {x: 33.0000, y: 50.0000},
  {x: 33.0000, y: 49.6667},
  {x: 33.0000, y: 49.3333},
  {x: 33.0000, y: 49.0000},
  {x: 32.0001, y: 49.0000},
  {x: 30.9999, y: 49.0000},
  {x: 30.0000, y: 49.0000},
  {x: 28.9775, y: 48.4532},
  {x: 28.6603, y: 45.2102},
  {x: 26.0000, y: 46.0000},
  {x: 26.0000, y: 46.3333},
  {x: 26.0000, y: 46.6667},
  {x: 26.0000, y: 47.0000},
  {x: 23.7663, y: 47.5862},
  {x: 23.4854, y: 47.8326},
  {x: 22.0000, y: 49.0000},
  {x: 22.0000, y: 49.3333},
  {x: 22.0000, y: 49.6667},
  {x: 22.0000, y: 50.0000},
  {x: 21.3334, y: 50.0000},
  {x: 20.6666, y: 50.0000},
  {x: 20.0000, y: 50.0000},
  {x: 19.6667, y: 50.6666},
  {x: 19.3333, y: 51.3334},
  {x: 19.0000, y: 52.0000},
  {x: 18.3334, y: 52.0000},
  {x: 17.6666, y: 52.0000},
  {x: 17.0000, y: 52.0000},
  {x: 16.6667, y: 52.6666},
  {x: 16.3333, y: 53.3334},
  {x: 16.0000, y: 54.0000},
  {x: 14.3335, y: 55.3332},
  {x: 12.6665, y: 56.6668},
  {x: 11.0000, y: 58.0000},
  {x: 11.0000, y: 58.6666},
  {x: 11.0000, y: 59.3334},
  {x: 11.0000, y: 60.0000},
  {x: 10.3334, y: 60.3333},
  {x: 9.6666, y: 60.6667},
  {x: 9.0000, y: 61.0000},
  {x: 8.3334, y: 62.9998},
  {x: 7.6666, y: 65.0002},
  {x: 7.0000, y: 67.0000},
  {x: 6.6667, y: 67.0000},
  {x: 6.3333, y: 67.0000},
  {x: 6.0000, y: 67.0000},
  {x: 6.0000, y: 67.9999},
  {x: 6.0000, y: 69.0001},
  {x: 6.0000, y: 70.0000},
  {x: 4.4769, y: 73.4023},
  {x: 1.0422, y: 75.8872},
  {x: 0.0000, y: 80.0000},
  {x: 1.3332, y: 80.3333},
  {x: 2.6668, y: 80.6667},
  {x: 4.0000, y: 81.0000},
  {x: 4.0000, y: 81.3333},
  {x: 4.0000, y: 81.6667},
  {x: 4.0000, y: 82.0000},
  {x: 6.9997, y: 82.0000},
  {x: 10.0003, y: 82.0000},
  {x: 13.0000, y: 82.0000},
  {x: 24.0014, y: 85.2377},
  {x: 38.0233, y: 85.7052},
  {x: 49.0000, y: 89.0000},
  {x: 50.9998, y: 89.0000},
  {x: 53.0002, y: 89.0000},
  {x: 55.0000, y: 89.0000},
  {x: 60.4142, y: 90.6273},
  {x: 68.5760, y: 91.3987},
  {x: 74.0000, y: 93.0000},
  {x: 76.3331, y: 93.0000},
  {x: 78.6669, y: 93.0000},
  {x: 81.0000, y: 93.0000},
  {x: 81.0000, y: 93.3333},
  {x: 81.0000, y: 93.6667},
  {x: 81.0000, y: 94.0000},
  {x: 83.3331, y: 94.0000},
  {x: 85.6669, y: 94.0000},
  {x: 88.0000, y: 94.0000},
  {x: 88.0000, y: 94.3333},
  {x: 88.0000, y: 94.6667},
  {x: 88.0000, y: 95.0000},
  {x: 90.6664, y: 95.0000},
  {x: 93.3336, y: 95.0000},
  {x: 96.0000, y: 95.0000},
  {x: 96.0000, y: 95.3333},
  {x: 96.0000, y: 95.6667},
  {x: 96.0000, y: 96.0000},
  {x: 99.3330, y: 96.0000},
  {x: 102.6670, y: 96.0000},
  {x: 106.0000, y: 96.0000},
  {x: 106.0000, y: 96.3333},
  {x: 106.0000, y: 96.6667},
  {x: 106.0000, y: 97.0000},
  {x: 112.3327, y: 97.3333},
  {x: 118.6673, y: 97.6667},
  {x: 125.0000, y: 98.0000},
  {x: 135.7254, y: 101.0692},
  {x: 150.2725, y: 99.9349},
  {x: 161.0000, y: 103.0000},
  {x: 164.6663, y: 103.0000},
  {x: 168.3337, y: 103.0000},
  {x: 172.0000, y: 103.0000},
  {x: 172.0000, y: 103.3333},
  {x: 172.0000, y: 103.6667},
  {x: 172.0000, y: 104.0000},
  {x: 175.6663, y: 104.0000},
  {x: 179.3337, y: 104.0000},
  {x: 183.0000, y: 104.0000},
  {x: 183.0000, y: 104.3333},
  {x: 183.0000, y: 104.6667},
  {x: 183.0000, y: 105.0000},
  {x: 200.3316, y: 105.6666},
  {x: 217.6684, y: 106.3334},
  {x: 235.0000, y: 107.0000},
  {x: 245.7301, y: 107.9289},
  {x: 266.5394, y: 109.9627},
  {x: 277.0000, y: 107.0000},
  {x: 282.9994, y: 107.0000},
  {x: 289.0006, y: 107.0000},
  {x: 295.0000, y: 107.0000},
  {x: 295.0000, y: 106.6667},
  {x: 295.0000, y: 106.3333},
  {x: 295.0000, y: 106.0000},
  {x: 298.6663, y: 106.0000},
  {x: 302.3337, y: 106.0000},
  {x: 306.0000, y: 106.0000},
  {x: 306.0000, y: 105.6667},
  {x: 306.0000, y: 105.3333},
  {x: 306.0000, y: 105.0000},
  {x: 308.9997, y: 105.0000},
  {x: 312.0003, y: 105.0000},
  {x: 315.0000, y: 105.0000},
  {x: 315.0000, y: 104.6667},
  {x: 315.0000, y: 104.3333},
  {x: 315.0000, y: 104.0000},
  {x: 317.6664, y: 104.0000},
  {x: 320.3336, y: 104.0000},
  {x: 323.0000, y: 104.0000},
  {x: 323.0000, y: 103.6667},
  {x: 323.0000, y: 103.3333},
  {x: 323.0000, y: 103.0000},
  {x: 325.3331, y: 103.0000},
  {x: 327.6669, y: 103.0000},
  {x: 330.0000, y: 103.0000},
  {x: 330.0000, y: 102.6667},
  {x: 330.0000, y: 102.3333},
  {x: 330.0000, y: 102.0000},
  {x: 331.9998, y: 102.0000},
  {x: 334.0002, y: 102.0000},
  {x: 336.0000, y: 102.0000},
  {x: 336.0000, y: 101.6667},
  {x: 336.0000, y: 101.3333},
  {x: 336.0000, y: 101.0000},
  {x: 337.9998, y: 101.0000},
  {x: 340.0002, y: 101.0000},
  {x: 342.0000, y: 101.0000},
  {x: 342.0000, y: 100.6667},
  {x: 342.0000, y: 100.3333},
  {x: 342.0000, y: 100.0000},
  {x: 345.6567, y: 98.9417},
  {x: 350.3419, y: 99.9806},
  {x: 353.0000, y: 99.0000},
  {x: 351.8806, y: 93.5841},
  {x: 348.3028, y: 89.3026},
  {x: 346.0000, y: 85.0000},
  {x: 345.3334, y: 83.0002},
  {x: 344.6666, y: 80.9998},
  {x: 344.0000, y: 79.0000},
  {x: 343.3334, y: 78.6667},
  {x: 342.6666, y: 78.3333},
  {x: 342.0000, y: 78.0000},
  {x: 341.6667, y: 76.6668},
  {x: 341.3333, y: 75.3332},
  {x: 341.0000, y: 74.0000},
  {x: 340.3334, y: 73.6667},
  {x: 339.6666, y: 73.3333},
  {x: 339.0000, y: 73.0000},
  {x: 339.0000, y: 72.3334},
  {x: 339.0000, y: 71.6666},
  {x: 339.0000, y: 71.0000},
  {x: 338.3334, y: 70.6667},
  {x: 337.6666, y: 70.3333},
  {x: 337.0000, y: 70.0000},
  {x: 337.0000, y: 69.3334},
  {x: 337.0000, y: 68.6666},
  {x: 337.0000, y: 68.0000},
  {x: 336.0001, y: 67.3334},
  {x: 334.9999, y: 66.6666},
  {x: 334.0000, y: 66.0000},
  {x: 334.0000, y: 65.3334},
  {x: 334.0000, y: 64.6666},
  {x: 334.0000, y: 64.0000},
  {x: 333.0001, y: 63.3334},
  {x: 331.9999, y: 62.6666},
  {x: 331.0000, y: 62.0000},
  {x: 331.0000, y: 61.3334},
  {x: 331.0000, y: 60.6666},
  {x: 331.0000, y: 60.0000},
  {x: 328.6669, y: 58.0002},
  {x: 326.3331, y: 55.9998},
  {x: 324.0000, y: 54.0000},
  {x: 320.3337, y: 50.0004},
  {x: 316.6663, y: 45.9996},
  {x: 313.0000, y: 42.0000},
  {x: 312.3334, y: 42.0000},
  {x: 311.6666, y: 42.0000},
  {x: 311.0000, y: 42.0000},
  {x: 310.3334, y: 41.0001},
  {x: 309.6666, y: 39.9999},
  {x: 309.0000, y: 39.0000},
  {x: 308.3334, y: 39.0000},
  {x: 307.6666, y: 39.0000},
  {x: 307.0000, y: 39.0000},
  {x: 306.6667, y: 38.3334},
  {x: 306.3333, y: 37.6666},
  {x: 306.0000, y: 37.0000},
  {x: 305.0001, y: 36.6667},
  {x: 303.9999, y: 36.3333},
  {x: 303.0000, y: 36.0000},
  {x: 303.0000, y: 35.6667},
  {x: 303.0000, y: 35.3333},
  {x: 303.0000, y: 35.0000},
  {x: 302.3334, y: 35.0000},
  {x: 301.6666, y: 35.0000},
  {x: 301.0000, y: 35.0000},
  {x: 300.3334, y: 34.0001},
  {x: 299.6666, y: 32.9999},
  {x: 299.0000, y: 32.0000},
  {x: 298.3334, y: 32.0000},
  {x: 297.6666, y: 32.0000},
  {x: 297.0000, y: 32.0000},
  {x: 295.6668, y: 30.3335},
  {x: 294.3332, y: 28.6665},
  {x: 293.0000, y: 27.0000},
  {x: 292.3334, y: 27.0000},
  {x: 291.6666, y: 27.0000},
  {x: 291.0000, y: 27.0000},
  {x: 289.3335, y: 25.0002},
  {x: 287.6665, y: 22.9998},
  {x: 286.0000, y: 21.0000},
  {x: 285.3334, y: 20.6667},
  {x: 284.6666, y: 20.3333},
  {x: 284.0000, y: 20.0000},
  {x: 284.0000, y: 19.3334},
  {x: 284.0000, y: 18.6666},
  {x: 284.0000, y: 18.0000},
  {x: 283.0001, y: 17.3334},
  {x: 281.9999, y: 16.6666},
  {x: 281.0000, y: 16.0000},
  {x: 281.0000, y: 15.3334},
  {x: 281.0000, y: 14.6666},
  {x: 281.0000, y: 14.0000},
  {x: 280.0001, y: 13.3334},
  {x: 278.9999, y: 12.6666},
  {x: 278.0000, y: 12.0000},
  {x: 278.0000, y: 11.3334},
  {x: 278.0000, y: 10.6666},
  {x: 278.0000, y: 10.0000},
  {x: 277.6667, y: 10.0000},
  {x: 277.3333, y: 10.0000},
  {x: 277.0000, y: 10.0000},
  {x: 275.0838, y: 6.5474},
  {x: 274.8313, y: 2.4468},
  {x: 272.0000, y: 0.0000},
  {x: 269.3794, y: 2.7827},
  {x: 269.4911, y: 0.7840},
  {x: 266.0000, y: 2.0000},
  {x: 266.0000, y: 2.3333},
  {x: 266.0000, y: 2.6667},
  {x: 266.0000, y: 3.0000},
  {x: 264.6668, y: 3.0000},
  {x: 263.3332, y: 3.0000},
  {x: 262.0000, y: 3.0000},
  {x: 262.0000, y: 3.3333},
  {x: 262.0000, y: 3.6667},
  {x: 262.0000, y: 4.0000},
  {x: 260.6668, y: 4.0000},
  {x: 259.3332, y: 4.0000},
  {x: 258.0000, y: 4.0000},
  {x: 258.0000, y: 4.3333},
  {x: 258.0000, y: 4.6667},
  {x: 258.0000, y: 5.0000},
  {x: 256.6668, y: 5.0000},
  {x: 255.3332, y: 5.0000},
  {x: 254.0000, y: 5.0000},
  {x: 243.3344, y: 8.6663},
  {x: 232.6656, y: 12.3337},
  {x: 222.0000, y: 16.0000}
];
