module.exports = [
  {x: 694.0000, y: 206.0000},
  {x: 693.0774, y: 201.9863},
  {x: 690.6456, y: 200.0791},
  {x: 689.0000, y: 197.0000},
  {x: 689.0000, y: 196.3334},
  {x: 689.0000, y: 195.6666},
  {x: 689.0000, y: 195.0000},
  {x: 688.6667, y: 195.0000},
  {x: 688.3333, y: 195.0000},
  {x: 688.0000, y: 195.0000},
  {x: 688.0000, y: 194.3334},
  {x: 688.0000, y: 193.6666},
  {x: 688.0000, y: 193.0000},
  {x: 687.6667, y: 193.0000},
  {x: 687.3333, y: 193.0000},
  {x: 687.0000, y: 193.0000},
  {x: 687.0000, y: 192.3334},
  {x: 687.0000, y: 191.6666},
  {x: 687.0000, y: 191.0000},
  {x: 686.6667, y: 191.0000},
  {x: 686.3333, y: 191.0000},
  {x: 686.0000, y: 191.0000},
  {x: 686.0000, y: 190.3334},
  {x: 686.0000, y: 189.6666},
  {x: 686.0000, y: 189.0000},
  {x: 685.6667, y: 189.0000},
  {x: 685.3333, y: 189.0000},
  {x: 685.0000, y: 189.0000},
  {x: 685.0000, y: 188.3334},
  {x: 685.0000, y: 187.6666},
  {x: 685.0000, y: 187.0000},
  {x: 684.6667, y: 187.0000},
  {x: 684.3333, y: 187.0000},
  {x: 684.0000, y: 187.0000},
  {x: 684.0000, y: 186.3334},
  {x: 684.0000, y: 185.6666},
  {x: 684.0000, y: 185.0000},
  {x: 683.3334, y: 184.6667},
  {x: 682.6666, y: 184.3333},
  {x: 682.0000, y: 184.0000},
  {x: 682.0000, y: 183.3334},
  {x: 682.0000, y: 182.6666},
  {x: 682.0000, y: 182.0000},
  {x: 681.0001, y: 181.3334},
  {x: 679.9999, y: 180.6666},
  {x: 679.0000, y: 180.0000},
  {x: 679.0000, y: 179.6667},
  {x: 679.0000, y: 179.3333},
  {x: 679.0000, y: 179.0000},
  {x: 678.3334, y: 179.0000},
  {x: 677.6666, y: 179.0000},
  {x: 677.0000, y: 179.0000},
  {x: 676.6667, y: 178.3334},
  {x: 676.3333, y: 177.6666},
  {x: 676.0000, y: 177.0000},
  {x: 675.3334, y: 177.0000},
  {x: 674.6666, y: 177.0000},
  {x: 674.0000, y: 177.0000},
  {x: 673.3334, y: 176.0001},
  {x: 672.6666, y: 174.9999},
  {x: 672.0000, y: 174.0000},
  {x: 671.3334, y: 174.0000},
  {x: 670.6666, y: 174.0000},
  {x: 670.0000, y: 174.0000},
  {x: 670.0000, y: 173.6667},
  {x: 670.0000, y: 173.3333},
  {x: 670.0000, y: 173.0000},
  {x: 668.6668, y: 172.6667},
  {x: 667.3332, y: 172.3333},
  {x: 666.0000, y: 172.0000},
  {x: 666.0000, y: 171.6667},
  {x: 666.0000, y: 171.3333},
  {x: 666.0000, y: 171.0000},
  {x: 658.6674, y: 170.6667},
  {x: 651.3326, y: 170.3333},
  {x: 644.0000, y: 170.0000},
  {x: 644.0000, y: 169.6667},
  {x: 644.0000, y: 169.3333},
  {x: 644.0000, y: 169.0000},
  {x: 640.3337, y: 169.0000},
  {x: 636.6663, y: 169.0000},
  {x: 633.0000, y: 169.0000},
  {x: 633.0000, y: 168.6667},
  {x: 633.0000, y: 168.3333},
  {x: 633.0000, y: 168.0000},
  {x: 628.6671, y: 168.0000},
  {x: 624.3329, y: 168.0000},
  {x: 620.0000, y: 168.0000},
  {x: 620.0000, y: 167.6667},
  {x: 620.0000, y: 167.3333},
  {x: 620.0000, y: 167.0000},
  {x: 617.0003, y: 167.0000},
  {x: 613.9997, y: 167.0000},
  {x: 611.0000, y: 167.0000},
  {x: 611.0000, y: 166.6667},
  {x: 611.0000, y: 166.3333},
  {x: 611.0000, y: 166.0000},
  {x: 608.0003, y: 166.0000},
  {x: 604.9997, y: 166.0000},
  {x: 602.0000, y: 166.0000},
  {x: 602.0000, y: 165.6667},
  {x: 602.0000, y: 165.3333},
  {x: 602.0000, y: 165.0000},
  {x: 598.6670, y: 165.0000},
  {x: 595.3330, y: 165.0000},
  {x: 592.0000, y: 165.0000},
  {x: 592.0000, y: 164.6667},
  {x: 592.0000, y: 164.3333},
  {x: 592.0000, y: 164.0000},
  {x: 588.0004, y: 164.0000},
  {x: 583.9996, y: 164.0000},
  {x: 580.0000, y: 164.0000},
  {x: 580.0000, y: 163.6667},
  {x: 580.0000, y: 163.3333},
  {x: 580.0000, y: 163.0000},
  {x: 575.3338, y: 163.0000},
  {x: 570.6662, y: 163.0000},
  {x: 566.0000, y: 163.0000},
  {x: 566.0000, y: 162.6667},
  {x: 566.0000, y: 162.3333},
  {x: 566.0000, y: 162.0000},
  {x: 562.6670, y: 162.0000},
  {x: 559.3330, y: 162.0000},
  {x: 556.0000, y: 162.0000},
  {x: 556.0000, y: 161.6667},
  {x: 556.0000, y: 161.3333},
  {x: 556.0000, y: 161.0000},
  {x: 553.6669, y: 161.0000},
  {x: 551.3331, y: 161.0000},
  {x: 549.0000, y: 161.0000},
  {x: 549.0000, y: 160.6667},
  {x: 549.0000, y: 160.3333},
  {x: 549.0000, y: 160.0000},
  {x: 547.6668, y: 160.0000},
  {x: 546.3332, y: 160.0000},
  {x: 545.0000, y: 160.0000},
  {x: 545.0000, y: 159.6667},
  {x: 545.0000, y: 159.3333},
  {x: 545.0000, y: 159.0000},
  {x: 543.6668, y: 159.0000},
  {x: 542.3332, y: 159.0000},
  {x: 541.0000, y: 159.0000},
  {x: 541.0000, y: 158.6667},
  {x: 541.0000, y: 158.3333},
  {x: 541.0000, y: 158.0000},
  {x: 540.0001, y: 158.0000},
  {x: 538.9999, y: 158.0000},
  {x: 538.0000, y: 158.0000},
  {x: 538.0000, y: 157.6667},
  {x: 538.0000, y: 157.3333},
  {x: 538.0000, y: 157.0000},
  {x: 537.0001, y: 157.0000},
  {x: 535.9999, y: 157.0000},
  {x: 535.0000, y: 157.0000},
  {x: 535.0000, y: 156.6667},
  {x: 535.0000, y: 156.3333},
  {x: 535.0000, y: 156.0000},
  {x: 533.0002, y: 155.6667},
  {x: 530.9998, y: 155.3333},
  {x: 529.0000, y: 155.0000},
  {x: 529.0000, y: 154.6667},
  {x: 529.0000, y: 154.3333},
  {x: 529.0000, y: 154.0000},
  {x: 527.6388, y: 152.8345},
  {x: 527.4719, y: 152.1755},
  {x: 527.0000, y: 150.0000},
  {x: 523.4834, y: 149.2503},
  {x: 521.5930, y: 147.3426},
  {x: 519.0000, y: 146.0000},
  {x: 518.3334, y: 146.0000},
  {x: 517.6666, y: 146.0000},
  {x: 517.0000, y: 146.0000},
  {x: 517.0000, y: 145.6667},
  {x: 517.0000, y: 145.3333},
  {x: 517.0000, y: 145.0000},
  {x: 513.6670, y: 144.0001},
  {x: 510.3330, y: 142.9999},
  {x: 507.0000, y: 142.0000},
  {x: 507.0000, y: 141.6667},
  {x: 507.0000, y: 141.3333},
  {x: 507.0000, y: 141.0000},
  {x: 506.3334, y: 141.0000},
  {x: 505.6666, y: 141.0000},
  {x: 505.0000, y: 141.0000},
  {x: 505.0000, y: 140.6667},
  {x: 505.0000, y: 140.3333},
  {x: 505.0000, y: 140.0000},
  {x: 504.2403, y: 139.4705},
  {x: 500.6483, y: 137.2826},
  {x: 499.0000, y: 138.0000},
  {x: 499.0000, y: 138.3333},
  {x: 499.0000, y: 138.6667},
  {x: 499.0000, y: 139.0000},
  {x: 501.3930, y: 139.6070},
  {x: 500.8584, y: 139.3175},
  {x: 502.0000, y: 141.0000},
  {x: 500.0002, y: 141.0000},
  {x: 497.9998, y: 141.0000},
  {x: 496.0000, y: 141.0000},
  {x: 496.0000, y: 141.3333},
  {x: 496.0000, y: 141.6667},
  {x: 496.0000, y: 142.0000},
  {x: 494.6668, y: 142.3333},
  {x: 493.3332, y: 142.6667},
  {x: 492.0000, y: 143.0000},
  {x: 492.0000, y: 142.6667},
  {x: 492.0000, y: 142.3333},
  {x: 492.0000, y: 142.0000},
  {x: 491.0001, y: 141.6667},
  {x: 489.9999, y: 141.3333},
  {x: 489.0000, y: 141.0000},
  {x: 489.0000, y: 140.6667},
  {x: 489.0000, y: 140.3333},
  {x: 489.0000, y: 140.0000},
  {x: 488.3334, y: 140.0000},
  {x: 487.6666, y: 140.0000},
  {x: 487.0000, y: 140.0000},
  {x: 487.0000, y: 139.6667},
  {x: 487.0000, y: 139.3333},
  {x: 487.0000, y: 139.0000},
  {x: 485.5187, y: 137.6502},
  {x: 485.6275, y: 138.5097},
  {x: 485.0000, y: 136.0000},
  {x: 482.1604, y: 133.2599},
  {x: 483.6842, y: 130.3673},
  {x: 484.0000, y: 126.0000},
  {x: 480.0267, y: 123.7849},
  {x: 479.3387, y: 121.1778},
  {x: 473.0000, y: 121.0000},
  {x: 469.9772, y: 124.1631},
  {x: 462.2970, y: 122.8742},
  {x: 458.0000, y: 122.0000},
  {x: 457.0951, y: 120.2241},
  {x: 455.5181, y: 117.2792},
  {x: 454.0000, y: 116.0000},
  {x: 454.6666, y: 113.6669},
  {x: 455.3334, y: 111.3331},
  {x: 456.0000, y: 109.0000},
  {x: 456.3333, y: 109.0000},
  {x: 456.6667, y: 109.0000},
  {x: 457.0000, y: 109.0000},
  {x: 458.1582, y: 107.4690},
  {x: 458.3716, y: 107.2466},
  {x: 459.0000, y: 105.0000},
  {x: 454.5783, y: 104.5543},
  {x: 453.4601, y: 103.1156},
  {x: 448.0000, y: 103.0000},
  {x: 445.9199, y: 106.0792},
  {x: 442.1860, y: 105.9344},
  {x: 441.0000, y: 110.0000},
  {x: 438.7024, y: 110.3051},
  {x: 438.4548, y: 110.1860},
  {x: 437.0000, y: 111.0000},
  {x: 437.0000, y: 111.3333},
  {x: 437.0000, y: 111.6667},
  {x: 437.0000, y: 112.0000},
  {x: 433.4476, y: 113.4655},
  {x: 427.3682, y: 111.4387},
  {x: 425.0000, y: 111.0000},
  {x: 424.0951, y: 109.2241},
  {x: 422.5181, y: 106.2792},
  {x: 421.0000, y: 105.0000},
  {x: 421.5736, y: 102.9902},
  {x: 420.8800, y: 104.1348},
  {x: 422.0000, y: 103.0000},
  {x: 422.7915, y: 98.8677},
  {x: 424.6551, y: 97.5183},
  {x: 428.0000, y: 96.0000},
  {x: 427.1558, y: 94.8654},
  {x: 428.1268, y: 95.8546},
  {x: 427.0000, y: 95.0000},
  {x: 425.1576, y: 92.8398},
  {x: 419.6945, y: 90.3713},
  {x: 416.0000, y: 90.0000},
  {x: 414.5068, y: 91.8794},
  {x: 414.0116, y: 90.8748},
  {x: 412.0000, y: 92.0000},
  {x: 412.0000, y: 92.3333},
  {x: 412.0000, y: 92.6667},
  {x: 412.0000, y: 93.0000},
  {x: 411.3334, y: 93.0000},
  {x: 410.6666, y: 93.0000},
  {x: 410.0000, y: 93.0000},
  {x: 409.6667, y: 93.6666},
  {x: 409.3333, y: 94.3334},
  {x: 409.0000, y: 95.0000},
  {x: 406.3594, y: 96.5405},
  {x: 398.3816, y: 96.3280},
  {x: 397.0000, y: 94.0000},
  {x: 397.0000, y: 93.3334},
  {x: 397.0000, y: 92.6666},
  {x: 397.0000, y: 92.0000},
  {x: 395.2957, y: 90.1574},
  {x: 395.7296, y: 81.9607},
  {x: 397.0000, y: 80.0000},
  {x: 397.0529, y: 79.9183},
  {x: 401.8590, y: 77.3323},
  {x: 401.0000, y: 77.0000},
  {x: 400.3334, y: 77.0000},
  {x: 399.6666, y: 77.0000},
  {x: 399.0000, y: 77.0000},
  {x: 396.5479, y: 73.9820},
  {x: 393.8389, y: 74.2809},
  {x: 390.0000, y: 75.0000},
  {x: 389.6667, y: 75.9999},
  {x: 389.3333, y: 77.0001},
  {x: 389.0000, y: 78.0000},
  {x: 386.7921, y: 78.6485},
  {x: 386.4892, y: 78.8168},
  {x: 385.0000, y: 80.0000},
  {x: 385.0000, y: 80.3333},
  {x: 385.0000, y: 80.6667},
  {x: 385.0000, y: 81.0000},
  {x: 384.0001, y: 81.3333},
  {x: 382.9999, y: 81.6667},
  {x: 382.0000, y: 82.0000},
  {x: 381.6667, y: 82.6666},
  {x: 381.3333, y: 83.3334},
  {x: 381.0000, y: 84.0000},
  {x: 380.3334, y: 84.0000},
  {x: 379.6666, y: 84.0000},
  {x: 379.0000, y: 84.0000},
  {x: 378.3334, y: 84.9999},
  {x: 377.6666, y: 86.0001},
  {x: 377.0000, y: 87.0000},
  {x: 376.3334, y: 87.0000},
  {x: 375.6666, y: 87.0000},
  {x: 375.0000, y: 87.0000},
  {x: 375.0000, y: 87.3333},
  {x: 375.0000, y: 87.6667},
  {x: 375.0000, y: 88.0000},
  {x: 370.7013, y: 89.5760},
  {x: 363.8198, y: 85.6960},
  {x: 363.0000, y: 84.0000},
  {x: 363.0000, y: 81.0003},
  {x: 363.0000, y: 77.9997},
  {x: 363.0000, y: 75.0000},
  {x: 364.9998, y: 72.6669},
  {x: 367.0002, y: 70.3331},
  {x: 369.0000, y: 68.0000},
  {x: 369.3333, y: 68.0000},
  {x: 369.6667, y: 68.0000},
  {x: 370.0000, y: 68.0000},
  {x: 370.6721, y: 66.7557},
  {x: 369.4088, y: 67.3784},
  {x: 369.0000, y: 67.0000},
  {x: 365.0039, y: 62.1115},
  {x: 359.4798, y: 62.6761},
  {x: 353.0000, y: 65.0000},
  {x: 353.0000, y: 65.3333},
  {x: 353.0000, y: 65.6667},
  {x: 353.0000, y: 66.0000},
  {x: 350.6669, y: 66.0000},
  {x: 348.3331, y: 66.0000},
  {x: 346.0000, y: 66.0000},
  {x: 345.7317, y: 66.0603},
  {x: 345.6937, y: 67.3244},
  {x: 344.0000, y: 67.0000},
  {x: 344.0000, y: 66.6667},
  {x: 344.0000, y: 66.3333},
  {x: 344.0000, y: 66.0000},
  {x: 341.9782, y: 65.3924},
  {x: 341.5114, y: 65.5823},
  {x: 340.0000, y: 65.0000},
  {x: 339.0821, y: 62.5633},
  {x: 338.9632, y: 58.8068},
  {x: 339.0000, y: 55.0000},
  {x: 340.3332, y: 54.0001},
  {x: 341.6668, y: 52.9999},
  {x: 343.0000, y: 52.0000},
  {x: 343.0000, y: 51.6667},
  {x: 343.0000, y: 51.3333},
  {x: 343.0000, y: 51.0000},
  {x: 343.6666, y: 51.0000},
  {x: 344.3334, y: 51.0000},
  {x: 345.0000, y: 51.0000},
  {x: 345.0000, y: 50.6667},
  {x: 345.0000, y: 50.3333},
  {x: 345.0000, y: 50.0000},
  {x: 345.9999, y: 49.6667},
  {x: 347.0001, y: 49.3333},
  {x: 348.0000, y: 49.0000},
  {x: 347.1558, y: 47.8654},
  {x: 348.1268, y: 48.8546},
  {x: 347.0000, y: 48.0000},
  {x: 345.0582, y: 44.4509},
  {x: 342.6409, y: 43.0024},
  {x: 337.0000, y: 43.0000},
  {x: 334.9386, y: 45.4206},
  {x: 331.8252, y: 45.2362},
  {x: 329.0000, y: 47.0000},
  {x: 328.6667, y: 47.6666},
  {x: 328.3333, y: 48.3334},
  {x: 328.0000, y: 49.0000},
  {x: 326.6668, y: 49.3333},
  {x: 325.3332, y: 49.6667},
  {x: 324.0000, y: 50.0000},
  {x: 324.0000, y: 50.3333},
  {x: 324.0000, y: 50.6667},
  {x: 324.0000, y: 51.0000},
  {x: 322.3335, y: 51.0000},
  {x: 320.6665, y: 51.0000},
  {x: 319.0000, y: 51.0000},
  {x: 319.0000, y: 51.3333},
  {x: 319.0000, y: 51.6667},
  {x: 319.0000, y: 52.0000},
  {x: 317.6668, y: 52.3333},
  {x: 316.3332, y: 52.6667},
  {x: 315.0000, y: 53.0000},
  {x: 315.0000, y: 52.6667},
  {x: 315.0000, y: 52.3333},
  {x: 315.0000, y: 52.0000},
  {x: 312.6070, y: 51.3930},
  {x: 313.1416, y: 51.6825},
  {x: 312.0000, y: 50.0000},
  {x: 308.8685, y: 46.9915},
  {x: 310.6546, y: 42.6168},
  {x: 311.0000, y: 38.0000},
  {x: 314.2800, y: 36.0952},
  {x: 315.7515, y: 33.0947},
  {x: 320.0000, y: 32.0000},
  {x: 319.6667, y: 31.0001},
  {x: 319.3333, y: 29.9999},
  {x: 319.0000, y: 29.0000},
  {x: 318.0001, y: 29.0000},
  {x: 316.9999, y: 29.0000},
  {x: 316.0000, y: 29.0000},
  {x: 316.0000, y: 28.6667},
  {x: 316.0000, y: 28.3333},
  {x: 316.0000, y: 28.0000},
  {x: 314.6668, y: 27.6667},
  {x: 313.3332, y: 27.3333},
  {x: 312.0000, y: 27.0000},
  {x: 312.0000, y: 27.3333},
  {x: 312.0000, y: 27.6667},
  {x: 312.0000, y: 28.0000},
  {x: 310.6668, y: 28.3333},
  {x: 309.3332, y: 28.6667},
  {x: 308.0000, y: 29.0000},
  {x: 308.0000, y: 28.6667},
  {x: 308.0000, y: 28.3333},
  {x: 308.0000, y: 28.0000},
  {x: 307.0001, y: 27.6667},
  {x: 305.9999, y: 27.3333},
  {x: 305.0000, y: 27.0000},
  {x: 304.6667, y: 26.3334},
  {x: 304.3333, y: 25.6666},
  {x: 304.0000, y: 25.0000},
  {x: 303.3334, y: 25.0000},
  {x: 302.6666, y: 25.0000},
  {x: 302.0000, y: 25.0000},
  {x: 301.6667, y: 24.3334},
  {x: 301.3333, y: 23.6666},
  {x: 301.0000, y: 23.0000},
  {x: 300.3334, y: 23.0000},
  {x: 299.6666, y: 23.0000},
  {x: 299.0000, y: 23.0000},
  {x: 298.3334, y: 22.0001},
  {x: 297.6666, y: 20.9999},
  {x: 297.0000, y: 20.0000},
  {x: 296.3334, y: 20.0000},
  {x: 295.6666, y: 20.0000},
  {x: 295.0000, y: 20.0000},
  {x: 294.6667, y: 19.3334},
  {x: 294.3333, y: 18.6666},
  {x: 294.0000, y: 18.0000},
  {x: 293.3334, y: 18.0000},
  {x: 292.6666, y: 18.0000},
  {x: 292.0000, y: 18.0000},
  {x: 291.0001, y: 16.6668},
  {x: 289.9999, y: 15.3332},
  {x: 289.0000, y: 14.0000},
  {x: 288.3334, y: 14.0000},
  {x: 287.6666, y: 14.0000},
  {x: 287.0000, y: 14.0000},
  {x: 286.0001, y: 12.6668},
  {x: 284.9999, y: 11.3332},
  {x: 284.0000, y: 10.0000},
  {x: 283.0001, y: 9.3334},
  {x: 281.9999, y: 8.6666},
  {x: 281.0000, y: 8.0000},
  {x: 281.0000, y: 7.6667},
  {x: 281.0000, y: 7.3333},
  {x: 281.0000, y: 7.0000},
  {x: 280.3334, y: 7.0000},
  {x: 279.6666, y: 7.0000},
  {x: 279.0000, y: 7.0000},
  {x: 278.6667, y: 6.3334},
  {x: 278.3333, y: 5.6666},
  {x: 278.0000, y: 5.0000},
  {x: 277.3334, y: 5.0000},
  {x: 276.6666, y: 5.0000},
  {x: 276.0000, y: 5.0000},
  {x: 276.0000, y: 4.6667},
  {x: 276.0000, y: 4.3333},
  {x: 276.0000, y: 4.0000},
  {x: 272.9008, y: 2.2177},
  {x: 270.5647, y: 1.2884},
  {x: 266.0000, y: 1.0000},
  {x: 266.0000, y: 1.3333},
  {x: 266.0000, y: 1.6667},
  {x: 266.0000, y: 2.0000},
  {x: 264.3335, y: 2.3333},
  {x: 262.6665, y: 2.6667},
  {x: 261.0000, y: 3.0000},
  {x: 261.0000, y: 3.3333},
  {x: 261.0000, y: 3.6667},
  {x: 261.0000, y: 4.0000},
  {x: 260.3334, y: 4.0000},
  {x: 259.6666, y: 4.0000},
  {x: 259.0000, y: 4.0000},
  {x: 258.6667, y: 4.6666},
  {x: 258.3333, y: 5.3334},
  {x: 258.0000, y: 6.0000},
  {x: 257.3334, y: 6.0000},
  {x: 256.6666, y: 6.0000},
  {x: 256.0000, y: 6.0000},
  {x: 255.3334, y: 6.9999},
  {x: 254.6666, y: 8.0001},
  {x: 254.0000, y: 9.0000},
  {x: 253.3334, y: 9.0000},
  {x: 252.6666, y: 9.0000},
  {x: 252.0000, y: 9.0000},
  {x: 251.3334, y: 9.9999},
  {x: 250.6666, y: 11.0001},
  {x: 250.0000, y: 12.0000},
  {x: 249.3334, y: 12.0000},
  {x: 248.6666, y: 12.0000},
  {x: 248.0000, y: 12.0000},
  {x: 246.3335, y: 13.9998},
  {x: 244.6665, y: 16.0002},
  {x: 243.0000, y: 18.0000},
  {x: 242.0001, y: 18.3333},
  {x: 240.9999, y: 18.6667},
  {x: 240.0000, y: 19.0000},
  {x: 240.0000, y: 19.3333},
  {x: 240.0000, y: 19.6667},
  {x: 240.0000, y: 20.0000},
  {x: 239.3334, y: 20.3333},
  {x: 238.6666, y: 20.6667},
  {x: 238.0000, y: 21.0000},
  {x: 238.0000, y: 21.6666},
  {x: 238.0000, y: 22.3334},
  {x: 238.0000, y: 23.0000},
  {x: 237.3334, y: 23.3333},
  {x: 236.6666, y: 23.6667},
  {x: 236.0000, y: 24.0000},
  {x: 236.0000, y: 24.3333},
  {x: 236.0000, y: 24.6667},
  {x: 236.0000, y: 25.0000},
  {x: 235.3334, y: 25.0000},
  {x: 234.6666, y: 25.0000},
  {x: 234.0000, y: 25.0000},
  {x: 233.6667, y: 25.6666},
  {x: 233.3333, y: 26.3334},
  {x: 233.0000, y: 27.0000},
  {x: 231.6668, y: 27.9999},
  {x: 230.3332, y: 29.0001},
  {x: 229.0000, y: 30.0000},
  {x: 229.0000, y: 30.6666},
  {x: 229.0000, y: 31.3334},
  {x: 229.0000, y: 32.0000},
  {x: 227.0002, y: 33.6665},
  {x: 224.9998, y: 35.3335},
  {x: 223.0000, y: 37.0000},
  {x: 223.0000, y: 37.6666},
  {x: 223.0000, y: 38.3334},
  {x: 223.0000, y: 39.0000},
  {x: 221.6668, y: 39.9999},
  {x: 220.3332, y: 41.0001},
  {x: 219.0000, y: 42.0000},
  {x: 216.3336, y: 44.9997},
  {x: 213.6664, y: 48.0003},
  {x: 211.0000, y: 51.0000},
  {x: 210.3334, y: 51.0000},
  {x: 209.6666, y: 51.0000},
  {x: 209.0000, y: 51.0000},
  {x: 208.0001, y: 52.3332},
  {x: 206.9999, y: 53.6668},
  {x: 206.0000, y: 55.0000},
  {x: 205.3334, y: 55.0000},
  {x: 204.6666, y: 55.0000},
  {x: 204.0000, y: 55.0000},
  {x: 203.3334, y: 55.9999},
  {x: 202.6666, y: 57.0001},
  {x: 202.0000, y: 58.0000},
  {x: 201.3334, y: 58.0000},
  {x: 200.6666, y: 58.0000},
  {x: 200.0000, y: 58.0000},
  {x: 199.6667, y: 58.6666},
  {x: 199.3333, y: 59.3334},
  {x: 199.0000, y: 60.0000},
  {x: 198.3334, y: 60.0000},
  {x: 197.6666, y: 60.0000},
  {x: 197.0000, y: 60.0000},
  {x: 196.6667, y: 60.6666},
  {x: 196.3333, y: 61.3334},
  {x: 196.0000, y: 62.0000},
  {x: 194.6668, y: 62.3333},
  {x: 193.3332, y: 62.6667},
  {x: 192.0000, y: 63.0000},
  {x: 191.6667, y: 63.6666},
  {x: 191.3333, y: 64.3334},
  {x: 191.0000, y: 65.0000},
  {x: 189.6668, y: 65.3333},
  {x: 188.3332, y: 65.6667},
  {x: 187.0000, y: 66.0000},
  {x: 186.6667, y: 66.6666},
  {x: 186.3333, y: 67.3334},
  {x: 186.0000, y: 68.0000},
  {x: 184.6668, y: 68.3333},
  {x: 183.3332, y: 68.6667},
  {x: 182.0000, y: 69.0000},
  {x: 182.0000, y: 69.3333},
  {x: 182.0000, y: 69.6667},
  {x: 182.0000, y: 70.0000},
  {x: 180.0002, y: 70.6666},
  {x: 177.9998, y: 71.3334},
  {x: 176.0000, y: 72.0000},
  {x: 176.0000, y: 72.3333},
  {x: 176.0000, y: 72.6667},
  {x: 176.0000, y: 73.0000},
  {x: 175.0001, y: 73.0000},
  {x: 173.9999, y: 73.0000},
  {x: 173.0000, y: 73.0000},
  {x: 173.0000, y: 73.3333},
  {x: 173.0000, y: 73.6667},
  {x: 173.0000, y: 74.0000},
  {x: 172.3334, y: 74.0000},
  {x: 171.6666, y: 74.0000},
  {x: 171.0000, y: 74.0000},
  {x: 171.0000, y: 74.3333},
  {x: 171.0000, y: 74.6667},
  {x: 171.0000, y: 75.0000},
  {x: 169.6668, y: 75.3333},
  {x: 168.3332, y: 75.6667},
  {x: 167.0000, y: 76.0000},
  {x: 167.0000, y: 76.3333},
  {x: 167.0000, y: 76.6667},
  {x: 167.0000, y: 77.0000},
  {x: 159.6674, y: 79.3331},
  {x: 152.3326, y: 81.6669},
  {x: 145.0000, y: 84.0000},
  {x: 142.6669, y: 84.0000},
  {x: 140.3331, y: 84.0000},
  {x: 138.0000, y: 84.0000},
  {x: 138.0000, y: 84.3333},
  {x: 138.0000, y: 84.6667},
  {x: 138.0000, y: 85.0000},
  {x: 134.6670, y: 85.0000},
  {x: 131.3330, y: 85.0000},
  {x: 128.0000, y: 85.0000},
  {x: 128.0000, y: 85.3333},
  {x: 128.0000, y: 85.6667},
  {x: 128.0000, y: 86.0000},
  {x: 122.4332, y: 87.5600},
  {x: 112.6584, y: 85.0499},
  {x: 109.0000, y: 84.0000},
  {x: 106.6669, y: 84.0000},
  {x: 104.3331, y: 84.0000},
  {x: 102.0000, y: 84.0000},
  {x: 102.0000, y: 83.6667},
  {x: 102.0000, y: 83.3333},
  {x: 102.0000, y: 83.0000},
  {x: 101.0001, y: 83.0000},
  {x: 99.9999, y: 83.0000},
  {x: 99.0000, y: 83.0000},
  {x: 99.0000, y: 82.6667},
  {x: 99.0000, y: 82.3333},
  {x: 99.0000, y: 82.0000},
  {x: 98.0001, y: 82.0000},
  {x: 96.9999, y: 82.0000},
  {x: 96.0000, y: 82.0000},
  {x: 96.0000, y: 81.6667},
  {x: 96.0000, y: 81.3333},
  {x: 96.0000, y: 81.0000},
  {x: 95.0001, y: 81.0000},
  {x: 93.9999, y: 81.0000},
  {x: 93.0000, y: 81.0000},
  {x: 93.0000, y: 80.6667},
  {x: 93.0000, y: 80.3333},
  {x: 93.0000, y: 80.0000},
  {x: 91.6668, y: 79.6667},
  {x: 90.3332, y: 79.3333},
  {x: 89.0000, y: 79.0000},
  {x: 89.0000, y: 78.6667},
  {x: 89.0000, y: 78.3333},
  {x: 89.0000, y: 78.0000},
  {x: 86.0003, y: 77.0001},
  {x: 82.9997, y: 75.9999},
  {x: 80.0000, y: 75.0000},
  {x: 79.0001, y: 73.6668},
  {x: 77.9999, y: 72.3332},
  {x: 77.0000, y: 71.0000},
  {x: 75.6668, y: 70.6667},
  {x: 74.3332, y: 70.3333},
  {x: 73.0000, y: 70.0000},
  {x: 72.6667, y: 69.3334},
  {x: 72.3333, y: 68.6666},
  {x: 72.0000, y: 68.0000},
  {x: 71.3334, y: 68.0000},
  {x: 70.6666, y: 68.0000},
  {x: 70.0000, y: 68.0000},
  {x: 68.3335, y: 66.0002},
  {x: 66.6665, y: 63.9998},
  {x: 65.0000, y: 62.0000},
  {x: 62.0003, y: 59.3336},
  {x: 58.9997, y: 56.6664},
  {x: 56.0000, y: 54.0000},
  {x: 56.0000, y: 53.3334},
  {x: 56.0000, y: 52.6666},
  {x: 56.0000, y: 52.0000},
  {x: 54.6668, y: 51.0001},
  {x: 53.3332, y: 49.9999},
  {x: 52.0000, y: 49.0000},
  {x: 52.0000, y: 48.3334},
  {x: 52.0000, y: 47.6666},
  {x: 52.0000, y: 47.0000},
  {x: 51.0001, y: 46.3334},
  {x: 49.9999, y: 45.6666},
  {x: 49.0000, y: 45.0000},
  {x: 49.0000, y: 44.3334},
  {x: 49.0000, y: 43.6666},
  {x: 49.0000, y: 43.0000},
  {x: 48.6667, y: 43.0000},
  {x: 48.3333, y: 43.0000},
  {x: 48.0000, y: 43.0000},
  {x: 48.0000, y: 42.3334},
  {x: 48.0000, y: 41.6666},
  {x: 48.0000, y: 41.0000},
  {x: 47.3334, y: 40.6667},
  {x: 46.6666, y: 40.3333},
  {x: 46.0000, y: 40.0000},
  {x: 45.6667, y: 38.6668},
  {x: 45.3333, y: 37.3332},
  {x: 45.0000, y: 36.0000},
  {x: 44.3334, y: 35.6667},
  {x: 43.6666, y: 35.3333},
  {x: 43.0000, y: 35.0000},
  {x: 43.0000, y: 34.3334},
  {x: 43.0000, y: 33.6666},
  {x: 43.0000, y: 33.0000},
  {x: 42.6667, y: 33.0000},
  {x: 42.3333, y: 33.0000},
  {x: 42.0000, y: 33.0000},
  {x: 42.0000, y: 32.3334},
  {x: 42.0000, y: 31.6666},
  {x: 42.0000, y: 31.0000},
  {x: 41.6667, y: 31.0000},
  {x: 41.3333, y: 31.0000},
  {x: 41.0000, y: 31.0000},
  {x: 41.0000, y: 30.3334},
  {x: 41.0000, y: 29.6666},
  {x: 41.0000, y: 29.0000},
  {x: 40.3334, y: 28.6667},
  {x: 39.6666, y: 28.3333},
  {x: 39.0000, y: 28.0000},
  {x: 39.0000, y: 27.3334},
  {x: 39.0000, y: 26.6666},
  {x: 39.0000, y: 26.0000},
  {x: 38.6667, y: 26.0000},
  {x: 38.3333, y: 26.0000},
  {x: 38.0000, y: 26.0000},
  {x: 38.0000, y: 25.3334},
  {x: 38.0000, y: 24.6666},
  {x: 38.0000, y: 24.0000},
  {x: 37.6667, y: 24.0000},
  {x: 37.3333, y: 24.0000},
  {x: 37.0000, y: 24.0000},
  {x: 37.0000, y: 23.3334},
  {x: 37.0000, y: 22.6666},
  {x: 37.0000, y: 22.0000},
  {x: 35.0002, y: 19.3336},
  {x: 32.9998, y: 16.6664},
  {x: 31.0000, y: 14.0000},
  {x: 30.0001, y: 14.0000},
  {x: 28.9999, y: 14.0000},
  {x: 28.0000, y: 14.0000},
  {x: 26.8713, y: 15.9754},
  {x: 26.5832, y: 16.3379},
  {x: 24.0000, y: 17.0000},
  {x: 23.1448, y: 20.3556},
  {x: 21.3308, y: 20.5220},
  {x: 20.0000, y: 23.0000},
  {x: 19.0001, y: 26.6663},
  {x: 17.9999, y: 30.3337},
  {x: 17.0000, y: 34.0000},
  {x: 16.6667, y: 34.0000},
  {x: 16.3333, y: 34.0000},
  {x: 16.0000, y: 34.0000},
  {x: 16.0000, y: 34.6666},
  {x: 16.0000, y: 35.3334},
  {x: 16.0000, y: 36.0000},
  {x: 15.6667, y: 36.0000},
  {x: 15.3333, y: 36.0000},
  {x: 15.0000, y: 36.0000},
  {x: 14.6667, y: 39.6663},
  {x: 14.3333, y: 43.3337},
  {x: 14.0000, y: 47.0000},
  {x: 13.6667, y: 47.0000},
  {x: 13.3333, y: 47.0000},
  {x: 13.0000, y: 47.0000},
  {x: 13.0000, y: 49.3331},
  {x: 13.0000, y: 51.6669},
  {x: 13.0000, y: 54.0000},
  {x: 12.6667, y: 54.0000},
  {x: 12.3333, y: 54.0000},
  {x: 12.0000, y: 54.0000},
  {x: 12.0000, y: 55.6665},
  {x: 12.0000, y: 57.3335},
  {x: 12.0000, y: 59.0000},
  {x: 11.6667, y: 59.0000},
  {x: 11.3333, y: 59.0000},
  {x: 11.0000, y: 59.0000},
  {x: 11.0000, y: 60.3332},
  {x: 11.0000, y: 61.6668},
  {x: 11.0000, y: 63.0000},
  {x: 10.6667, y: 63.0000},
  {x: 10.3333, y: 63.0000},
  {x: 10.0000, y: 63.0000},
  {x: 10.0000, y: 64.3332},
  {x: 10.0000, y: 65.6668},
  {x: 10.0000, y: 67.0000},
  {x: 9.6667, y: 67.0000},
  {x: 9.3333, y: 67.0000},
  {x: 9.0000, y: 67.0000},
  {x: 9.0000, y: 70.9996},
  {x: 9.0000, y: 75.0004},
  {x: 9.0000, y: 79.0000},
  {x: 8.6667, y: 79.0000},
  {x: 8.3333, y: 79.0000},
  {x: 8.0000, y: 79.0000},
  {x: 8.0000, y: 80.3332},
  {x: 8.0000, y: 81.6668},
  {x: 8.0000, y: 83.0000},
  {x: 7.6667, y: 83.0000},
  {x: 7.3333, y: 83.0000},
  {x: 7.0000, y: 83.0000},
  {x: 7.0000, y: 84.6665},
  {x: 7.0000, y: 86.3335},
  {x: 7.0000, y: 88.0000},
  {x: 6.6667, y: 88.0000},
  {x: 6.3333, y: 88.0000},
  {x: 6.0000, y: 88.0000},
  {x: 6.0000, y: 91.6663},
  {x: 6.0000, y: 95.3337},
  {x: 6.0000, y: 99.0000},
  {x: 5.6667, y: 99.0000},
  {x: 5.3333, y: 99.0000},
  {x: 5.0000, y: 99.0000},
  {x: 5.0000, y: 100.9998},
  {x: 5.0000, y: 103.0002},
  {x: 5.0000, y: 105.0000},
  {x: 4.6667, y: 105.0000},
  {x: 4.3333, y: 105.0000},
  {x: 4.0000, y: 105.0000},
  {x: 4.0000, y: 107.6664},
  {x: 4.0000, y: 110.3336},
  {x: 4.0000, y: 113.0000},
  {x: 3.6667, y: 113.0000},
  {x: 3.3333, y: 113.0000},
  {x: 3.0000, y: 113.0000},
  {x: 3.3333, y: 121.3325},
  {x: 3.6667, y: 129.6675},
  {x: 4.0000, y: 138.0000},
  {x: 3.6667, y: 138.0000},
  {x: 3.3333, y: 138.0000},
  {x: 3.0000, y: 138.0000},
  {x: 3.0000, y: 143.3328},
  {x: 3.0000, y: 148.6672},
  {x: 3.0000, y: 154.0000},
  {x: 2.6667, y: 154.0000},
  {x: 2.3333, y: 154.0000},
  {x: 2.0000, y: 154.0000},
  {x: 2.0000, y: 158.6662},
  {x: 2.0000, y: 163.3338},
  {x: 2.0000, y: 168.0000},
  {x: 1.9996, y: 177.9019},
  {x: 1.4877, y: 188.5291},
  {x: 1.0000, y: 197.0000},
  {x: 2.6665, y: 196.6667},
  {x: 4.3335, y: 196.3333},
  {x: 6.0000, y: 196.0000},
  {x: 6.0000, y: 195.6667},
  {x: 6.0000, y: 195.3333},
  {x: 6.0000, y: 195.0000},
  {x: 9.9996, y: 195.3333},
  {x: 14.0004, y: 195.6667},
  {x: 18.0000, y: 196.0000},
  {x: 18.0000, y: 196.3333},
  {x: 18.0000, y: 196.6667},
  {x: 18.0000, y: 197.0000},
  {x: 24.3327, y: 197.0000},
  {x: 30.6673, y: 197.0000},
  {x: 37.0000, y: 197.0000},
  {x: 36.9427, y: 198.2813},
  {x: 39.0000, y: 198.0000},
  {x: 39.0000, y: 197.6667},
  {x: 39.0000, y: 197.3333},
  {x: 39.0000, y: 197.0000},
  {x: 40.6665, y: 197.0000},
  {x: 42.3335, y: 197.0000},
  {x: 44.0000, y: 197.0000},
  {x: 44.0000, y: 197.3333},
  {x: 44.0000, y: 197.6667},
  {x: 44.0000, y: 198.0000},
  {x: 47.3330, y: 198.0000},
  {x: 50.6670, y: 198.0000},
  {x: 54.0000, y: 198.0000},
  {x: 60.3327, y: 198.3333},
  {x: 66.6673, y: 198.6667},
  {x: 73.0000, y: 199.0000},
  {x: 73.0000, y: 199.3333},
  {x: 73.0000, y: 199.6667},
  {x: 73.0000, y: 200.0000},
  {x: 77.6662, y: 200.0000},
  {x: 82.3338, y: 200.0000},
  {x: 87.0000, y: 200.0000},
  {x: 87.0000, y: 200.3333},
  {x: 87.0000, y: 200.6667},
  {x: 87.0000, y: 201.0000},
  {x: 93.9993, y: 201.3333},
  {x: 101.0007, y: 201.6667},
  {x: 108.0000, y: 202.0000},
  {x: 111.3330, y: 202.0000},
  {x: 114.6670, y: 202.0000},
  {x: 118.0000, y: 202.0000},
  {x: 118.0000, y: 202.3333},
  {x: 118.0000, y: 202.6667},
  {x: 118.0000, y: 203.0000},
  {x: 123.6661, y: 203.0000},
  {x: 129.3339, y: 203.0000},
  {x: 135.0000, y: 203.0000},
  {x: 135.0000, y: 203.3333},
  {x: 135.0000, y: 203.6667},
  {x: 135.0000, y: 204.0000},
  {x: 139.9995, y: 204.0000},
  {x: 145.0005, y: 204.0000},
  {x: 150.0000, y: 204.0000},
  {x: 150.0000, y: 204.3333},
  {x: 150.0000, y: 204.6667},
  {x: 150.0000, y: 205.0000},
  {x: 153.9996, y: 205.0000},
  {x: 158.0004, y: 205.0000},
  {x: 162.0000, y: 205.0000},
  {x: 162.0000, y: 205.3333},
  {x: 162.0000, y: 205.6667},
  {x: 162.0000, y: 206.0000},
  {x: 163.6665, y: 206.0000},
  {x: 165.3335, y: 206.0000},
  {x: 167.0000, y: 206.0000},
  {x: 167.0000, y: 206.3333},
  {x: 167.0000, y: 206.6667},
  {x: 167.0000, y: 207.0000},
  {x: 169.3331, y: 207.0000},
  {x: 171.6669, y: 207.0000},
  {x: 174.0000, y: 207.0000},
  {x: 174.0000, y: 207.3333},
  {x: 174.0000, y: 207.6667},
  {x: 174.0000, y: 208.0000},
  {x: 182.6658, y: 208.0000},
  {x: 191.3342, y: 208.0000},
  {x: 200.0000, y: 208.0000},
  {x: 200.0000, y: 208.3333},
  {x: 200.0000, y: 208.6667},
  {x: 200.0000, y: 209.0000},
  {x: 206.6660, y: 209.0000},
  {x: 213.3340, y: 209.0000},
  {x: 220.0000, y: 209.0000},
  {x: 220.0000, y: 209.3333},
  {x: 220.0000, y: 209.6667},
  {x: 220.0000, y: 210.0000},
  {x: 223.6663, y: 210.0000},
  {x: 227.3337, y: 210.0000},
  {x: 231.0000, y: 210.0000},
  {x: 231.0000, y: 210.3333},
  {x: 231.0000, y: 210.6667},
  {x: 231.0000, y: 211.0000},
  {x: 234.3330, y: 211.0000},
  {x: 237.6670, y: 211.0000},
  {x: 241.0000, y: 211.0000},
  {x: 241.0000, y: 211.3333},
  {x: 241.0000, y: 211.6667},
  {x: 241.0000, y: 212.0000},
  {x: 244.6663, y: 212.0000},
  {x: 248.3337, y: 212.0000},
  {x: 252.0000, y: 212.0000},
  {x: 252.0000, y: 212.3333},
  {x: 252.0000, y: 212.6667},
  {x: 252.0000, y: 213.0000},
  {x: 255.3330, y: 213.0000},
  {x: 258.6670, y: 213.0000},
  {x: 262.0000, y: 213.0000},
  {x: 262.0000, y: 213.3333},
  {x: 262.0000, y: 213.6667},
  {x: 262.0000, y: 214.0000},
  {x: 264.6664, y: 214.0000},
  {x: 267.3336, y: 214.0000},
  {x: 270.0000, y: 214.0000},
  {x: 270.0000, y: 214.3333},
  {x: 270.0000, y: 214.6667},
  {x: 270.0000, y: 215.0000},
  {x: 272.6664, y: 215.0000},
  {x: 275.3336, y: 215.0000},
  {x: 278.0000, y: 215.0000},
  {x: 278.0000, y: 215.3333},
  {x: 278.0000, y: 215.6667},
  {x: 278.0000, y: 216.0000},
  {x: 281.6663, y: 216.0000},
  {x: 285.3337, y: 216.0000},
  {x: 289.0000, y: 216.0000},
  {x: 289.0000, y: 216.3333},
  {x: 289.0000, y: 216.6667},
  {x: 289.0000, y: 217.0000},
  {x: 291.3331, y: 217.0000},
  {x: 293.6669, y: 217.0000},
  {x: 296.0000, y: 217.0000},
  {x: 296.0000, y: 217.3333},
  {x: 296.0000, y: 217.6667},
  {x: 296.0000, y: 218.0000},
  {x: 298.3331, y: 218.0000},
  {x: 300.6669, y: 218.0000},
  {x: 303.0000, y: 218.0000},
  {x: 303.0000, y: 218.3333},
  {x: 303.0000, y: 218.6667},
  {x: 303.0000, y: 219.0000},
  {x: 305.3331, y: 219.0000},
  {x: 307.6669, y: 219.0000},
  {x: 310.0000, y: 219.0000},
  {x: 310.0000, y: 219.3333},
  {x: 310.0000, y: 219.6667},
  {x: 310.0000, y: 220.0000},
  {x: 312.3331, y: 220.0000},
  {x: 314.6669, y: 220.0000},
  {x: 317.0000, y: 220.0000},
  {x: 317.0000, y: 220.3333},
  {x: 317.0000, y: 220.6667},
  {x: 317.0000, y: 221.0000},
  {x: 320.3330, y: 221.3333},
  {x: 323.6670, y: 221.6667},
  {x: 327.0000, y: 222.0000},
  {x: 327.0000, y: 222.3333},
  {x: 327.0000, y: 222.6667},
  {x: 327.0000, y: 223.0000},
  {x: 327.9999, y: 223.0000},
  {x: 329.0001, y: 223.0000},
  {x: 330.0000, y: 223.0000},
  {x: 330.0000, y: 223.3333},
  {x: 330.0000, y: 223.6667},
  {x: 330.0000, y: 224.0000},
  {x: 331.3332, y: 224.0000},
  {x: 332.6668, y: 224.0000},
  {x: 334.0000, y: 224.0000},
  {x: 334.0000, y: 224.3333},
  {x: 334.0000, y: 224.6667},
  {x: 334.0000, y: 225.0000},
  {x: 336.9997, y: 225.0000},
  {x: 340.0003, y: 225.0000},
  {x: 343.0000, y: 225.0000},
  {x: 355.3321, y: 226.9998},
  {x: 367.6679, y: 229.0002},
  {x: 380.0000, y: 231.0000},
  {x: 386.9993, y: 231.3333},
  {x: 394.0007, y: 231.6667},
  {x: 401.0000, y: 232.0000},
  {x: 401.0000, y: 232.3333},
  {x: 401.0000, y: 232.6667},
  {x: 401.0000, y: 233.0000},
  {x: 406.9994, y: 233.0000},
  {x: 413.0006, y: 233.0000},
  {x: 419.0000, y: 233.0000},
  {x: 419.0000, y: 233.3333},
  {x: 419.0000, y: 233.6667},
  {x: 419.0000, y: 234.0000},
  {x: 422.6663, y: 234.0000},
  {x: 426.3337, y: 234.0000},
  {x: 430.0000, y: 234.0000},
  {x: 438.3179, y: 234.0036},
  {x: 449.3537, y: 234.8720},
  {x: 456.0000, y: 233.0000},
  {x: 463.9992, y: 233.0000},
  {x: 472.0008, y: 233.0000},
  {x: 480.0000, y: 233.0000},
  {x: 480.0000, y: 232.6667},
  {x: 480.0000, y: 232.3333},
  {x: 480.0000, y: 232.0000},
  {x: 485.3328, y: 232.0000},
  {x: 490.6672, y: 232.0000},
  {x: 496.0000, y: 232.0000},
  {x: 496.0000, y: 231.6667},
  {x: 496.0000, y: 231.3333},
  {x: 496.0000, y: 231.0000},
  {x: 500.6662, y: 231.0000},
  {x: 505.3338, y: 231.0000},
  {x: 510.0000, y: 231.0000},
  {x: 511.3604, y: 230.6135},
  {x: 509.6043, y: 230.2283},
  {x: 511.0000, y: 230.0000},
  {x: 514.6663, y: 230.0000},
  {x: 518.3337, y: 230.0000},
  {x: 522.0000, y: 230.0000},
  {x: 522.0000, y: 229.6667},
  {x: 522.0000, y: 229.3333},
  {x: 522.0000, y: 229.0000},
  {x: 525.3330, y: 229.0000},
  {x: 528.6670, y: 229.0000},
  {x: 532.0000, y: 229.0000},
  {x: 532.0000, y: 228.6667},
  {x: 532.0000, y: 228.3333},
  {x: 532.0000, y: 228.0000},
  {x: 535.6663, y: 228.0000},
  {x: 539.3337, y: 228.0000},
  {x: 543.0000, y: 228.0000},
  {x: 553.6982, y: 224.9453},
  {x: 568.2438, y: 226.0597},
  {x: 579.0000, y: 223.0000},
  {x: 584.9994, y: 222.6667},
  {x: 591.0006, y: 222.3333},
  {x: 597.0000, y: 222.0000},
  {x: 605.4464, y: 219.5616},
  {x: 616.7566, y: 219.4123},
  {x: 625.0000, y: 217.0000},
  {x: 627.3331, y: 217.0000},
  {x: 629.6669, y: 217.0000},
  {x: 632.0000, y: 217.0000},
  {x: 632.0000, y: 216.6667},
  {x: 632.0000, y: 216.3333},
  {x: 632.0000, y: 216.0000},
  {x: 634.3331, y: 216.0000},
  {x: 636.6669, y: 216.0000},
  {x: 639.0000, y: 216.0000},
  {x: 639.0000, y: 215.6667},
  {x: 639.0000, y: 215.3333},
  {x: 639.0000, y: 215.0000},
  {x: 640.9998, y: 215.0000},
  {x: 643.0002, y: 215.0000},
  {x: 645.0000, y: 215.0000},
  {x: 645.0000, y: 214.6667},
  {x: 645.0000, y: 214.3333},
  {x: 645.0000, y: 214.0000},
  {x: 646.9998, y: 214.0000},
  {x: 649.0002, y: 214.0000},
  {x: 651.0000, y: 214.0000},
  {x: 660.0665, y: 211.2905},
  {x: 671.9053, y: 210.6411},
  {x: 681.0000, y: 208.0000},
  {x: 683.6664, y: 208.0000},
  {x: 686.3336, y: 208.0000},
  {x: 689.0000, y: 208.0000},
  {x: 689.0000, y: 207.6667},
  {x: 689.0000, y: 207.3333},
  {x: 689.0000, y: 207.0000},
  {x: 690.6665, y: 206.6667},
  {x: 692.3335, y: 206.3333},
  {x: 694.0000, y: 206.0000}
];
