import SneakerIcon from '@/components/icons/custom/sneaker'
import ChevronDown from '@/components/icons/chevronDown'
import EyeIcon from '@/components/icons/eye'
import EyeOffIcon from '@/components/icons/eyeOff'
import TrashIcon from '@/components/icons/trash'

export default {
  name: 'AreaDropdown',

  components: {
    SneakerIcon,
    ChevronDown,
    EyeIcon,
    EyeOffIcon,
    TrashIcon
  },

  props: {
    joinOptions: {
      type: Object,
      required: true
    },

    singleOptions: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      section: 'single',

      option: 'swoosh',
      isOptions: false
    }
  },

  methods: {
    switchOptions: function() {
      this.isOptions = !this.isOptions;
    },

    selectOption: function(key, section) {
      this.option = key;
      this.isOptions = false;

      this.section = section;

      this.$emit('change', key);
    },

    restartArea: function(key) {
      this.$emit('restart', key);
    },

    switchVisibility: function(key) {
      this.$emit('switchVisibility', key);
    }
  },

  mounted() {
    let self = this;

    window.addEventListener('click', function(e) {
      if (typeof self.$refs.areaDropdown !== 'undefined' &&
        !self.$refs.areaDropdown.contains(e.target)
      ) {
        self.isOptions = false;
      }
    });
  }
}
