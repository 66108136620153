import SneakerIcon from '@/components/icons/custom/sneaker'
import ChevronDown from '@/components/icons/chevronDown'

export default {
  name: 'SideDropdown',

  components: {
    SneakerIcon,
    ChevronDown
  },

  props: {
    side: {
      type: String,
      required: true
    },

    sideData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      options: {
        inner: {
          name: 'Inner Side',
          hasData: false
        },
        outer: {
          name: 'Outer Side',
          hasData: false
        }
      },

      isOptions: false
    }
  },

  methods: {
    switchOptions: function() {
      this.isOptions = !this.isOptions;
    },

    selectOption: function(key) {
      this.option = key;
      this.isOptions = false;

      this.$emit('change', key);
    }
  },

  mounted() {
    let self = this;

    window.addEventListener('click', function(e) {
      if (typeof self.$refs.sideDropdown !== 'undefined' &&
        !self.$refs.sideDropdown.contains(e.target)
      ) {
        self.isOptions = false;
      }
    });
  },

  watch: {
    'sideData.inner': function(val) {
      this.options.inner.hasData = val;
    },

    'sideData.outer': function(val) {
      this.options.outer.hasData = val;
    }
  }
}
