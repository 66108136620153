import axios from 'axios'
import api from '@/config/apiServer.js'

import CloseIcon from '@/components/icons/close'
import LogoWhite from '@/components/icons/logoWhite'
import DownloadIcon from '@/components/icons/download'
import FacebookIcon from '@/components/icons/social/facebook'
import TwitterIcon from '@/components/icons/social/twitter'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'
import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Share',

  mixins: [Mixins],

  components: {
    CloseIcon,
    LogoWhite,
    DownloadIcon,
    FacebookIcon,
    TwitterIcon,

    Button,
    ButtonIcon
  },

  props: {
    side: {
      type: String,
      required: true
    },

    images: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      image: null,

      share: '',
      download: ''
    }
  },

  computed: {
    scale: function() {
      return window.innerWidth <= 768
        ? 0.42
        : 1;
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hidePreviewShare');
    },

    bgImgStyle: function(src) {
      let style = '';

      style += 'background-image: url(' + src + ');';
      style += 'background-size: cover;';
      style += 'background-position: center center;';
      style += 'background-repeat: no-repeat;';

      return style;
    },

    getImageStyle: function(image) {
      let response = '';

      response += 'top:' + image.top * this.scale + 'px;';
      response += 'left:' + image.left * this.scale + 'px;';

      return response;
    },

    getImage: async function() {
      this.image = await this.$html2canvas(this.$refs.preview, {
        type: 'dataURL',
        useCORS: true,
        logging: false,
        allowTaint: true,
        onclone: function(doc) {
          doc.getElementById('preview').style.display = 'block';
        }
      });
    },

    getDownload: async function() {
      let response;

      let base64 = await this.$html2canvas(this.$refs.download, {
        type: 'dataURL',
        useCORS: true,
        logging: false,
        allowTaint: true
      });
      let name = Date.now();
      let type = base64.split(';')[0].split(':')[1];

      let file = await this.urlToFile(base64, name, type);
      let form = new FormData();

      form.append('image', file);

      await axios
        .post(api + '/preview', form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => response = res)
        .catch(err => console.log(err));

      if (typeof response !== 'undefined' && response.status === 200) {
        this.share = response.data;
        this.download = base64;
      }
    },

    urlToFile: async function(url, fileName, mimeType) {
      let file = await fetch(url)
        .then(res => {
          return res.arrayBuffer()
        })
        .then(buf => {
          return new File([buf], fileName, {
            type: mimeType
          })
        });

      return file;
    },
  },

  mounted: async function() {
    await this.getImage();
    await this.getDownload();
  }
}
