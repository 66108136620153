module.exports = [
  {x: 353.0000, y: 80.0000},
  {x: 352.1098, y: 76.3361},
  {x: 349.5049, y: 74.8093},
  {x: 348.0000, y: 72.0000},
  {x: 347.6667, y: 70.3335},
  {x: 347.3333, y: 68.6665},
  {x: 347.0000, y: 67.0000},
  {x: 346.6667, y: 67.0000},
  {x: 346.3333, y: 67.0000},
  {x: 346.0000, y: 67.0000},
  {x: 345.3334, y: 65.0002},
  {x: 344.6666, y: 62.9998},
  {x: 344.0000, y: 61.0000},
  {x: 343.3334, y: 60.6667},
  {x: 342.6666, y: 60.3333},
  {x: 342.0000, y: 60.0000},
  {x: 342.0000, y: 59.3334},
  {x: 342.0000, y: 58.6666},
  {x: 342.0000, y: 58.0000},
  {x: 340.3335, y: 56.6668},
  {x: 338.6665, y: 55.3332},
  {x: 337.0000, y: 54.0000},
  {x: 336.6667, y: 53.3334},
  {x: 336.3333, y: 52.6666},
  {x: 336.0000, y: 52.0000},
  {x: 335.3334, y: 52.0000},
  {x: 334.6666, y: 52.0000},
  {x: 334.0000, y: 52.0000},
  {x: 333.6667, y: 51.3334},
  {x: 333.3333, y: 50.6666},
  {x: 333.0000, y: 50.0000},
  {x: 330.6694, y: 48.4234},
  {x: 328.7306, y: 48.0639},
  {x: 327.0000, y: 46.0000},
  {x: 324.7061, y: 46.6117},
  {x: 324.5886, y: 46.8770},
  {x: 323.0000, y: 48.0000},
  {x: 323.0000, y: 48.3333},
  {x: 323.0000, y: 48.6667},
  {x: 323.0000, y: 49.0000},
  {x: 322.0001, y: 49.0000},
  {x: 320.9999, y: 49.0000},
  {x: 320.0000, y: 49.0000},
  {x: 320.0000, y: 49.3333},
  {x: 320.0000, y: 49.6667},
  {x: 320.0000, y: 50.0000},
  {x: 319.0001, y: 50.0000},
  {x: 317.9999, y: 50.0000},
  {x: 317.0000, y: 50.0000},
  {x: 317.0000, y: 50.3333},
  {x: 317.0000, y: 50.6667},
  {x: 317.0000, y: 51.0000},
  {x: 315.6668, y: 51.0000},
  {x: 314.3332, y: 51.0000},
  {x: 313.0000, y: 51.0000},
  {x: 313.0000, y: 51.3333},
  {x: 313.0000, y: 51.6667},
  {x: 313.0000, y: 52.0000},
  {x: 309.3337, y: 52.3333},
  {x: 305.6663, y: 52.6667},
  {x: 302.0000, y: 53.0000},
  {x: 294.3266, y: 55.2496},
  {x: 283.8502, y: 55.7798},
  {x: 276.0000, y: 58.0000},
  {x: 273.0003, y: 58.0000},
  {x: 269.9997, y: 58.0000},
  {x: 267.0000, y: 58.0000},
  {x: 267.0000, y: 58.3333},
  {x: 267.0000, y: 58.6667},
  {x: 267.0000, y: 59.0000},
  {x: 264.0003, y: 59.0000},
  {x: 260.9997, y: 59.0000},
  {x: 258.0000, y: 59.0000},
  {x: 258.0000, y: 59.3333},
  {x: 258.0000, y: 59.6667},
  {x: 258.0000, y: 60.0000},
  {x: 254.0004, y: 60.0000},
  {x: 249.9996, y: 60.0000},
  {x: 246.0000, y: 60.0000},
  {x: 246.0000, y: 60.3333},
  {x: 246.0000, y: 60.6667},
  {x: 246.0000, y: 61.0000},
  {x: 241.0005, y: 61.0000},
  {x: 235.9995, y: 61.0000},
  {x: 231.0000, y: 61.0000},
  {x: 223.5983, y: 63.0635},
  {x: 195.3983, y: 63.0702},
  {x: 188.0000, y: 61.0000},
  {x: 183.3338, y: 61.0000},
  {x: 178.6662, y: 61.0000},
  {x: 174.0000, y: 61.0000},
  {x: 174.0000, y: 60.6667},
  {x: 174.0000, y: 60.3333},
  {x: 174.0000, y: 60.0000},
  {x: 170.6670, y: 60.0000},
  {x: 167.3330, y: 60.0000},
  {x: 164.0000, y: 60.0000},
  {x: 152.1659, y: 56.6621},
  {x: 137.6228, y: 57.5397},
  {x: 132.0000, y: 48.0000},
  {x: 126.5117, y: 42.6242},
  {x: 129.5506, y: 23.4603},
  {x: 131.0000, y: 16.0000},
  {x: 127.0315, y: 15.3252},
  {x: 124.1377, y: 13.2616},
  {x: 121.0000, y: 12.0000},
  {x: 119.0002, y: 11.6667},
  {x: 116.9998, y: 11.3333},
  {x: 115.0000, y: 11.0000},
  {x: 115.0000, y: 10.6667},
  {x: 115.0000, y: 10.3333},
  {x: 115.0000, y: 10.0000},
  {x: 114.3334, y: 10.0000},
  {x: 113.6666, y: 10.0000},
  {x: 113.0000, y: 10.0000},
  {x: 113.0000, y: 9.6667},
  {x: 113.0000, y: 9.3333},
  {x: 113.0000, y: 9.0000},
  {x: 111.6668, y: 9.0000},
  {x: 110.3332, y: 9.0000},
  {x: 109.0000, y: 9.0000},
  {x: 109.0000, y: 8.6667},
  {x: 109.0000, y: 8.3333},
  {x: 109.0000, y: 8.0000},
  {x: 108.0001, y: 8.0000},
  {x: 106.9999, y: 8.0000},
  {x: 106.0000, y: 8.0000},
  {x: 106.0000, y: 7.6667},
  {x: 106.0000, y: 7.3333},
  {x: 106.0000, y: 7.0000},
  {x: 105.0001, y: 7.0000},
  {x: 103.9999, y: 7.0000},
  {x: 103.0000, y: 7.0000},
  {x: 103.0000, y: 6.6667},
  {x: 103.0000, y: 6.3333},
  {x: 103.0000, y: 6.0000},
  {x: 101.6668, y: 6.0000},
  {x: 100.3332, y: 6.0000},
  {x: 99.0000, y: 6.0000},
  {x: 99.0000, y: 5.6667},
  {x: 99.0000, y: 5.3333},
  {x: 99.0000, y: 5.0000},
  {x: 97.6668, y: 5.0000},
  {x: 96.3332, y: 5.0000},
  {x: 95.0000, y: 5.0000},
  {x: 90.4749, y: 3.4489},
  {x: 84.2262, y: 1.9303},
  {x: 80.0000, y: 0.0000},
  {x: 79.1378, y: 4.1155},
  {x: 76.7738, y: 6.9081},
  {x: 75.0000, y: 10.0000},
  {x: 75.0000, y: 10.6666},
  {x: 75.0000, y: 11.3334},
  {x: 75.0000, y: 12.0000},
  {x: 74.0001, y: 12.6666},
  {x: 72.9999, y: 13.3334},
  {x: 72.0000, y: 14.0000},
  {x: 72.0000, y: 14.6666},
  {x: 72.0000, y: 15.3334},
  {x: 72.0000, y: 16.0000},
  {x: 71.0001, y: 16.6666},
  {x: 69.9999, y: 17.3334},
  {x: 69.0000, y: 18.0000},
  {x: 69.0000, y: 18.6666},
  {x: 69.0000, y: 19.3334},
  {x: 69.0000, y: 20.0000},
  {x: 68.3334, y: 20.3333},
  {x: 67.6666, y: 20.6667},
  {x: 67.0000, y: 21.0000},
  {x: 65.3335, y: 22.9998},
  {x: 63.6665, y: 25.0002},
  {x: 62.0000, y: 27.0000},
  {x: 61.3334, y: 27.0000},
  {x: 60.6666, y: 27.0000},
  {x: 60.0000, y: 27.0000},
  {x: 58.6668, y: 28.6665},
  {x: 57.3332, y: 30.3335},
  {x: 56.0000, y: 32.0000},
  {x: 55.3334, y: 32.0000},
  {x: 54.6666, y: 32.0000},
  {x: 54.0000, y: 32.0000},
  {x: 53.3334, y: 32.9999},
  {x: 52.6666, y: 34.0001},
  {x: 52.0000, y: 35.0000},
  {x: 51.3334, y: 35.0000},
  {x: 50.6666, y: 35.0000},
  {x: 50.0000, y: 35.0000},
  {x: 49.6667, y: 35.6666},
  {x: 49.3333, y: 36.3334},
  {x: 49.0000, y: 37.0000},
  {x: 48.0001, y: 37.3333},
  {x: 46.9999, y: 37.6667},
  {x: 46.0000, y: 38.0000},
  {x: 46.0000, y: 38.3333},
  {x: 46.0000, y: 38.6667},
  {x: 46.0000, y: 39.0000},
  {x: 45.3334, y: 39.0000},
  {x: 44.6666, y: 39.0000},
  {x: 44.0000, y: 39.0000},
  {x: 43.3334, y: 39.9999},
  {x: 42.6666, y: 41.0001},
  {x: 42.0000, y: 42.0000},
  {x: 41.3334, y: 42.0000},
  {x: 40.6666, y: 42.0000},
  {x: 40.0000, y: 42.0000},
  {x: 38.0002, y: 44.3331},
  {x: 35.9998, y: 46.6669},
  {x: 34.0000, y: 49.0000},
  {x: 30.0004, y: 52.6663},
  {x: 25.9996, y: 56.3337},
  {x: 22.0000, y: 60.0000},
  {x: 22.0000, y: 60.6666},
  {x: 22.0000, y: 61.3334},
  {x: 22.0000, y: 62.0000},
  {x: 21.0001, y: 62.6666},
  {x: 19.9999, y: 63.3334},
  {x: 19.0000, y: 64.0000},
  {x: 19.0000, y: 64.6666},
  {x: 19.0000, y: 65.3334},
  {x: 19.0000, y: 66.0000},
  {x: 18.0001, y: 66.6666},
  {x: 16.9999, y: 67.3334},
  {x: 16.0000, y: 68.0000},
  {x: 16.0000, y: 68.6666},
  {x: 16.0000, y: 69.3334},
  {x: 16.0000, y: 70.0000},
  {x: 15.3334, y: 70.3333},
  {x: 14.6666, y: 70.6667},
  {x: 14.0000, y: 71.0000},
  {x: 14.0000, y: 71.6666},
  {x: 14.0000, y: 72.3334},
  {x: 14.0000, y: 73.0000},
  {x: 13.3334, y: 73.3333},
  {x: 12.6666, y: 73.6667},
  {x: 12.0000, y: 74.0000},
  {x: 11.6667, y: 75.3332},
  {x: 11.3333, y: 76.6668},
  {x: 11.0000, y: 78.0000},
  {x: 10.3334, y: 78.3333},
  {x: 9.6666, y: 78.6667},
  {x: 9.0000, y: 79.0000},
  {x: 8.3334, y: 80.9998},
  {x: 7.6666, y: 83.0002},
  {x: 7.0000, y: 85.0000},
  {x: 4.6804, y: 89.3414},
  {x: 1.2110, y: 93.5690},
  {x: 0.0000, y: 99.0000},
  {x: 0.3333, y: 99.0000},
  {x: 0.6667, y: 99.0000},
  {x: 1.0000, y: 99.0000},
  {x: 1.0000, y: 99.3333},
  {x: 1.0000, y: 99.6667},
  {x: 1.0000, y: 100.0000},
  {x: 11.6785, y: 99.9808},
  {x: 21.4600, y: 101.5592},
  {x: 30.0000, y: 104.0000},
  {x: 32.6664, y: 104.0000},
  {x: 35.3336, y: 104.0000},
  {x: 38.0000, y: 104.0000},
  {x: 38.0000, y: 104.3333},
  {x: 38.0000, y: 104.6667},
  {x: 38.0000, y: 105.0000},
  {x: 40.9997, y: 105.0000},
  {x: 44.0003, y: 105.0000},
  {x: 47.0000, y: 105.0000},
  {x: 47.0000, y: 105.3333},
  {x: 47.0000, y: 105.6667},
  {x: 47.0000, y: 106.0000},
  {x: 50.6663, y: 106.0000},
  {x: 54.3337, y: 106.0000},
  {x: 58.0000, y: 106.0000},
  {x: 58.0000, y: 106.3333},
  {x: 58.0000, y: 106.6667},
  {x: 58.0000, y: 107.0000},
  {x: 63.9994, y: 107.0000},
  {x: 70.0006, y: 107.0000},
  {x: 76.0000, y: 107.0000},
  {x: 76.0000, y: 107.3333},
  {x: 76.0000, y: 107.6667},
  {x: 76.0000, y: 108.0000},
  {x: 79.6663, y: 108.0000},
  {x: 83.3337, y: 108.0000},
  {x: 87.0000, y: 108.0000},
  {x: 96.2022, y: 108.0038},
  {x: 108.5079, y: 109.1095},
  {x: 116.0000, y: 107.0000},
  {x: 120.3329, y: 107.0000},
  {x: 124.6671, y: 107.0000},
  {x: 129.0000, y: 107.0000},
  {x: 137.9991, y: 106.6667},
  {x: 147.0009, y: 106.3333},
  {x: 156.0000, y: 106.0000},
  {x: 156.0000, y: 105.6667},
  {x: 156.0000, y: 105.3333},
  {x: 156.0000, y: 105.0000},
  {x: 160.6662, y: 105.0000},
  {x: 165.3338, y: 105.0000},
  {x: 170.0000, y: 105.0000},
  {x: 170.0000, y: 104.6667},
  {x: 170.0000, y: 104.3333},
  {x: 170.0000, y: 104.0000},
  {x: 173.6663, y: 104.0000},
  {x: 177.3337, y: 104.0000},
  {x: 181.0000, y: 104.0000},
  {x: 181.0000, y: 103.6667},
  {x: 181.0000, y: 103.3333},
  {x: 181.0000, y: 103.0000},
  {x: 184.6663, y: 103.0000},
  {x: 188.3337, y: 103.0000},
  {x: 192.0000, y: 103.0000},
  {x: 192.0000, y: 102.6667},
  {x: 192.0000, y: 102.3333},
  {x: 192.0000, y: 102.0000},
  {x: 194.9997, y: 102.0000},
  {x: 198.0003, y: 102.0000},
  {x: 201.0000, y: 102.0000},
  {x: 201.0000, y: 101.6667},
  {x: 201.0000, y: 101.3333},
  {x: 201.0000, y: 101.0000},
  {x: 203.9997, y: 101.0000},
  {x: 207.0003, y: 101.0000},
  {x: 210.0000, y: 101.0000},
  {x: 210.0000, y: 100.6667},
  {x: 210.0000, y: 100.3333},
  {x: 210.0000, y: 100.0000},
  {x: 212.9997, y: 100.0000},
  {x: 216.0003, y: 100.0000},
  {x: 219.0000, y: 100.0000},
  {x: 230.2585, y: 96.7734},
  {x: 245.6494, y: 98.2467},
  {x: 257.0000, y: 95.0000},
  {x: 259.6664, y: 95.0000},
  {x: 262.3336, y: 95.0000},
  {x: 265.0000, y: 95.0000},
  {x: 270.8154, y: 93.3146},
  {x: 279.2871, y: 92.6718},
  {x: 285.0000, y: 91.0000},
  {x: 287.3331, y: 91.0000},
  {x: 289.6669, y: 91.0000},
  {x: 292.0000, y: 91.0000},
  {x: 304.9135, y: 87.1981},
  {x: 321.0992, y: 86.8311},
  {x: 334.0000, y: 83.0000},
  {x: 338.9995, y: 82.6667},
  {x: 344.0005, y: 82.3333},
  {x: 349.0000, y: 82.0000},
  {x: 349.0000, y: 81.6667},
  {x: 349.0000, y: 81.3333},
  {x: 349.0000, y: 81.0000},
  {x: 350.3332, y: 80.6667},
  {x: 351.6668, y: 80.3333},
  {x: 353.0000, y: 80.0000}
];
