import Header from '@/components/header'
import Footer from '@/components/footer'

import Cart from '@/components/cart'
import OrderConfirmed from '@/components/orderConfirmed'
import PickSneakerColor from '@/components/pickSneakerColor'
import PickSneakerCustom from '@/components/pickSneakerCustom'
import SneakerCustom from '@/components/sneakerCustom'

import FAQs from '@/components/info/faqs'
import ShippingInfo from '@/components/info/shippingInfo'
import RefoundPolicy from '@/components/info/refoundPolicy'
import PrivatePolicy from '@/components/info/privatePolicy'
import Help from '@/components/info/help'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'App',

  components: {
    Header,
    Footer,

    Cart,
    OrderConfirmed,
    PickSneakerColor,
    PickSneakerCustom,
    SneakerCustom,

    FAQs,
    ShippingInfo,
    RefoundPolicy,
    PrivatePolicy,
    Help
  },

  data() {
    return {
      isScrollable: true,

      isCart: false,
      isOrderConfirmed: false,

      isPickSneakerColor: false,
      isPickSneakerCustom: false,

      isSneakerCustom: false,
      customApplication: null,

      isFAQs: false,
      isShippingInfo: false,
      isRefoundPolicy: false,
      isPrivatePolicy: false,
      isHelp: false
    }
  },

  methods: {
    enableScroll: function() {
      this.isScrollable = true;
    },

    disableScroll: function() {
      this.isScrollable = false;
    },

    scrollBottom: function() {
      setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 50);
    },

    switchCart: function() {
      EventBus.$on('showCart', () => {
        this.isCart = true;
      });

      EventBus.$on('hideCart', () => {
        this.isCart = false;
      });
    },

    switchOrderConfirmed: function() {
      EventBus.$on('showOrderConfirmed', () => {
        this.isOrderConfirmed = true;

        this.disableScroll();
      });

      EventBus.$on('hideOrderConfirmed', () => {
        this.isOrderConfirmed = false;

        this.enableScroll();
      });
    },

    switchPickSneakerColor: function() {
      EventBus.$on('showPickSneakerColor', () => {
        this.isPickSneakerColor = true;

        this.disableScroll();
      });

      EventBus.$on('hidePickSneakerColor', () => {
        this.isPickSneakerColor = false;

        this.enableScroll();
      });
    },

    switchPickSneakerCustom: function() {
      EventBus.$on('showPickSneakerCustom', () => {
        this.isPickSneakerCustom = true;

        this.disableScroll();
      });

      EventBus.$on('hidePickSneakerCustom', () => {
        this.isPickSneakerCustom = false;

        this.enableScroll();
      });
    },

    switchSneakerCustom: function() {
      EventBus.$on('showSneakerCustom', id => {
        this.isSneakerCustom = true;
        this.customApplication = id;

        this.disableScroll();
      });

      EventBus.$on('hideSneakerCustom', () => {
        this.isSneakerCustom = false;
        this.customApplication = null;

        this.enableScroll();
      });
    },

    switchFAQs: function() {
      EventBus.$on('showFAQs', () => {
        this.isFAQs = true;

        this.disableScroll();
      });

      EventBus.$on('hideFAQs', () => {
        this.isFAQs = false;

        this.enableScroll();
        this.scrollBottom();
      });
    },

    switchShippingInfo: function() {
      EventBus.$on('showShippingInfo', () => {
        this.isShippingInfo = true;

        this.disableScroll();
      });

      EventBus.$on('hideShippingInfo', () => {
        this.isShippingInfo = false;

        this.enableScroll();
        this.scrollBottom();
      });
    },

    switchRefoundPolicy: function() {
      EventBus.$on('showRefoundPolicy', () => {
        this.isRefoundPolicy = true;

        this.disableScroll();
      });

      EventBus.$on('hideRefoundPolicy', () => {
        this.isRefoundPolicy = false;

        this.enableScroll();
        this.scrollBottom();
      });
    },

    switchPrivatePolicy: function() {
      EventBus.$on('showPrivatePolicy', () => {
        this.isPrivatePolicy = true;

        this.disableScroll();
      });

      EventBus.$on('hidePrivatePolicy', () => {
        this.isPrivatePolicy = false;

        this.enableScroll();
        this.scrollBottom();
      });
    },

    switchHelp: function() {
      EventBus.$on('showHelp', () => {
        this.isHelp = true;

        this.disableScroll();
      });

      EventBus.$on('hideHelp', () => {
        this.isHelp = false;

        this.enableScroll();
        this.scrollBottom();
      });
    }
  },

  mounted() {
    this.switchCart();
    this.switchOrderConfirmed();

    this.switchPickSneakerColor();
    this.switchPickSneakerCustom();
    this.switchSneakerCustom();

    this.switchFAQs();
    this.switchShippingInfo();
    this.switchRefoundPolicy();
    this.switchPrivatePolicy();
    this.switchHelp();
  }
}
