import CloseIcon from '@/components/icons/close'
import ButtonIcon from '@/components/form/buttonIcon'

import Size from '@/mixins/size'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'SizeGuide',

  mixins: [Size],

  components: {
    CloseIcon,
    ButtonIcon
  },

  data() {
    return {
      columns: [
        {
          name: 'US Men’s',
          color: 'black'
        },
        {
          name: 'US Women’s',
          color: 'black'
        },
        {
          name: 'gb',
          color: 'light'
        },
        {
          name: 'cm',
          color: 'light'
        },
        {
          name: 'ue',
          color: 'light'
        }
      ],

      sizes: []
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideSizeGuide');
    },

    getSizes: async function() {
      let response = await this.getSizeGuide();

      if (response !== null) {
        this.sizes = response;
      } else {
        this.$toast.error('Error al obtener las tallas');
      }
    }
  },

  mounted() {
    this.getSizes();
  }
}
