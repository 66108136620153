import FreeTf from '@/components/sneakerCustom/preview/freeTf'
import FreeTfCtrl from '@/components/sneakerCustom/preview/freeTfCtrl'

import SidebarPreview from '@/components/sneakerCustom/preview/sidebar'
import SidebarSize from '@/components/sneakerCustom/size/sidebar'

import Repeat from '@/components/sneakerCustom/preview/repeat'
import RestartArea from '@/components/sneakerCustom/preview/restartArea'
import Share from '@/components/sneakerCustom/preview/share'
import VerifyNft from '@/components/sneakerCustom/preview/verifyNft'
import TextFont from '@/components/sneakerCustom/preview/textFont'
import SizeGuide from '@/components/sneakerCustom/size/guide'

import sneaker from '@/config/sneaker'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  components: {
    FreeTf,
    FreeTfCtrl,

    SidebarPreview,
    SidebarSize,

    Repeat,
    RestartArea,
    Share,
    VerifyNft,
    TextFont,
    SizeGuide
  },

  props: {
    application: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      side: 'outer',
      sides: [
        'inner',
        'outer'
      ],

      sneaker: sneaker,

      areaId: 'swoosh',
      joinAreas: [
        'top_set',
        'bottom_set'
      ],
      singleAreas: [
        'vamp',
        'tip',
        'quarter',
        'foxing',
        'backtab',
        'eyestay',
        'swoosh'
      ],

      property: 'color',

      textKey: null,
      text: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        value: '',
        font: '',
        visible: true,
        selected: false
      },

      imagesPreview: [],

      isZoom: false,
      isEditable: false,

      isRepeat: false,
      isRestartArea: false,
      isShare: false,
      isVerifyNft: false,
      isTextFont: false,
      isSizeGuide: false
    }
  },

  computed: {
    sideData: function () {
      return {
        inner: this.hasSideData('inner'),
        outer: this.hasSideData('outer')
      };
    },

    joinOptions: function() {
      let response = {};
      let sneaker = this.sneaker[this.side].areas;

      let hasTopData = Boolean(
        sneaker.top_set.color.value !== null ||
        sneaker.top_set.image !== null ||
        sneaker.top_set.text.length
      );

      let hasBottomData = Boolean(
        sneaker.bottom_set.color.value !== null ||
        sneaker.bottom_set.image !== null ||
        sneaker.bottom_set.text.length
      );

      let hasQuarterData = Boolean(
        sneaker.quarter.image !== null ||
        sneaker.quarter.text.length
      );

      let hasTipData = Boolean(
        sneaker.tip.image !== null ||
        sneaker.tip.text.length
      );

      let hasFoxingData = Boolean(
        sneaker.foxing.image !== null ||
        sneaker.foxing.text.length
      );

      let hasSwooshData = Boolean(
        sneaker.swoosh.image !== null ||
        sneaker.swoosh.text.length
      );

      this.joinAreas.forEach(key => {
        let area = sneaker[key];
        let isVisible = true;

        if (key == 'top_set' && ((hasTipData || hasQuarterData) || hasBottomData)) {
          isVisible = false;
        } else if (key == 'bottom_set' && ((hasQuarterData || hasFoxingData || hasSwooshData) || hasTopData)) {
          isVisible = false;
        }

        if (isVisible) {
          response[key] = {
            name: this.getJoinOptionName(key),
            hasData: Boolean(
              area.color.value !== null || area.image !== null || area.text.length
            ),
            visible: area.visible
          };
        }
      });

      return response;
    },

    singleOptions: function() {
      let response = {};
      let sneaker = this.sneaker[this.side].areas;

      let hasTopData = Boolean(
        sneaker.top_set.color.value !== null ||
        sneaker.top_set.image !== null ||
        sneaker.top_set.text.length
      );

      let hasBottomData = Boolean(
        sneaker.bottom_set.color.value !== null ||
        sneaker.bottom_set.image !== null ||
        sneaker.bottom_set.text.length
      );

      this.singleAreas.forEach(key => {
        let area = sneaker[key];
        let isVisible = true;

        if (key == 'quarter' && (hasTopData || hasBottomData)) {
          isVisible = false;
        } else if (key == 'tip' && hasTopData) {
          isVisible = false;
        } else if ((key === 'foxing' || key === 'swoosh') && hasBottomData) {
          isVisible = false;
        }

        if (isVisible) {
          response[key] = {
            name: key,
            hasData: Boolean(
              area.color.value !== null || area.image !== null || area.text.length
            ),
            visible: area.visible
          };
        }
      });

      return response;
    },

    color: function() {
      return this.application === 'full'
        ? this.sneaker[this.side].full.color
        : this.sneaker[this.side].areas[this.areaId].color;
    },

    imageItems: function() {
      let items;

      if (this.application === 'full') {
        items = this.sneaker[this.side].full.images;
      } else {
        let image = this.sneaker[this.side].areas[this.areaId].image;

        items = image !== null
          ? [image]
          : [];
      }

      return items;
    },

    imageLimit: function() {
      return (this.application === 'full')
        ? 3
        : 1;
    },

    textItems: function() {
      return this.application === 'full'
        ? this.sneaker[this.side].full.text
        : this.sneaker[this.side].areas[this.areaId].text;
    },

    textLimit: function() {
      return (this.application === 'full')
        ? 4
        : 2;
    },

    fullMaskStyle: function() {
      let style = '';
      let full = this.sneaker[this.side].full;

      style += "top:" + full.top + "px;";
      style += "left:" + full.left + "px;";
      style += "width:" + full.width + "px;";
      style += "height:" + full.height + "px;";
      style += "mask-image: url('" + require('@/assets/img/' + this.side + '/full.png') + "');";

      return style;
    },

    freeTfCtrl: function() {
      let response;

      let selected;
      let sneaker = this.application === 'full'
        ? this.sneaker[this.side].full
        : this.sneaker[this.side].areas[this.areaId];

      if (this.property === 'image') {
        if (this.application === 'full') {
          this.imageItems.forEach(image => {
            if (image.selected) {
              selected = image;
            }
          });
        } else if (this.imageItems[0] !== null) {
          selected = this.imageItems[0];
        }
      }

      if (this.property === 'text') {
        this.textItems.forEach(text => {
          if (text.selected) {
            selected = text;
          }
        });
      }

      if (typeof selected !== 'undefined') {
        response = {
          x: selected.x,
          y: selected.y,
          top: sneaker.top,
          left: sneaker.left,
          width: sneaker.width,
          height: sneaker.height,
          scaleX: selected.scaleX,
          scaleY: selected.scaleY,
          angle: selected.angle
        };
      } else {
        response = null;
      }

      return response;
    },

    isEditAvail: function() {
      let sneaker = this.application === 'full'
        ? this.sneaker[this.side].full
        : this.sneaker[this.side].areas[this.areaId];

      return this.application === 'full'
        ? sneaker.images.length || sneaker.text.length
        : sneaker.image !== null || sneaker.text.length;
    },

    isTools: function() {
      let response;
      let sneaker = this.application === 'full'
        ? this.sneaker[this.side].full
        : this.sneaker[this.side].areas[this.areaId];

      response = false;

      switch (this.property) {
        case 'color':
          response = false;

          break;
        case 'image':
          if (this.application == 'full') {
            sneaker.images.forEach(image => {
              if (image.selected) {
                response = true;
              }
            });
          } else {
            response = sneaker.image !== null
              ? sneaker.image.selected
              : false;
          }

          break;
        case 'text':
          sneaker.text.forEach(text => {
            if (text.selected) {
              response = true;
            }
          });

          break;
      }

      return response;
    },

    scale: function() {
      return window.innerWidth <= 768
        ? 0.42
        : 1;
    }
  },

  methods: {
    // Data
    hasSideData: function(side) {
      let hasData;
      let sneaker = this.application === 'full'
        ? this.sneaker[side].full
        : this.sneaker[side].areas;

      if (this.application === 'full') {
        hasData = sneaker.color.value !== null || sneaker.images.length || sneaker.text.length;
      } else {
        hasData = false;

        this.joinAreas.forEach(key => {
          let area = sneaker[key];

          if (area.color.value !== null || area.image !== null || area.text.length) {
            hasData = true;
          }
        });

        this.singleAreas.forEach(key => {
          let area = sneaker[key];

          if (area.color.value !== null || area.image !== null || area.text.length) {
            hasData = true;
          }
        });
      }

      return hasData;
    },

    // Side
    selectSide: function(side) {
      this.side = side;

      this.bgImg();
    },

    // Select Area
    selectArea: function(id) {
      this.areaId = id;
      this.isEditable = false;
    },

    getJoinOptionName: function(key) {
      let response;

      switch (key) {
        case 'top_set':
          response = 'Tip & quarter';
          break;
        case 'bottom_set':
          response = 'Quarter, foxing & swoosh';
          break;
      }

      return response;
    },

    // Visible Area
    isAreaVisible: function(id) {
      let response;
      let area = this.sneaker[this.side].areas[id];

      if (area.visible) {
        response = true;
        /*
        let swoosh = this.sneaker[this.side].areas.swoosh;
        let hasData = Boolean(
          swoosh.color.value !== null || swoosh.image !== null || swoosh.text.length
        );

        if (id !== 'swoosh') {
          response = true;
        } else {
          response = hasData;
        }
        */
      } else {
        response = false;
      }

      return response;
    },

    // Property
    putProperty: function(property) {
      this.property = property;
    },

    // Color Functions
    hexToRgba: function(hex, alpha = 1) {
      let response = null;

      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        let color = hex.substring(1).split('');

        if (color.length == 3) {
          color = [color[0], color[0], color[1], color[1], color[2], color[2]];
        }

        color = '0x' + color.join('');

        response = 'rgba(' + [
          (color >> 16) & 255,
          (color >> 8) & 255,
          color & 255
        ].join(', ') + ', ' + alpha +  ')';
      }

      return response;
    },

    getColor: function(color) {
      let rgba = this.hexToRgba(color, 0.7);

      return 'linear-gradient(' + rgba + ',' + rgba + ')';
    },

    // Put Color
    putColor: function(color) {
      let sneaker = this.sneaker[this.side];

      if (this.application === 'full') {
        this.putSneakerColor(sneaker.full, color);
      } else {
        this.putSneakerColor(sneaker.areas[this.areaId], color);
      }
    },

    putSneakerColor: function(piece, color) {
      piece.color = color;

      piece.style = color.value !== null && color.visible
        ? 'background:' + this.getColor(color.value)
        : 'background-color:transparent';
    },

    // Put Image
    putImage: function(image) {
      let sneaker = this.sneaker[this.side];

      if (this.application === 'full') {
        sneaker.full.images.push(image);
      } else {
        sneaker.areas[this.areaId].image = image;
      }
    },

    // Select Image
    selectImage: function(key) {
      let sneaker = this.application === 'full'
        ? this.sneaker[this.side].full
        : this.sneaker[this.side].areas[this.areaId];

      if (this.application === 'full') {
        for (let i = 0; i < sneaker.images.length; i++) {
          if (i === key) {
            sneaker.images[i].selected = !sneaker.images[i].selected;
          } else {
            sneaker.images[i].selected = false;
          }
        }
      } else {
        sneaker.image.selected = !sneaker.image.selected;
      }
    },

    // Delete Image
    deleteImage: function(key) {
      let sneaker = this.sneaker[this.side];

      if (this.application === 'full') {
        sneaker.full.images.splice(key, 1);
      } else {
        sneaker.areas[this.areaId].image = null;
      }
    },

    // Restart Text
    restartText: function() {
      this.textKey = null;

      this.text.x = 0;
      this.text.y = 0;
      this.text.scaleX = 1;
      this.text.scaleY = 1;
      this.text.angle = 0;
      this.text.value = '';
      this.text.font = '';
      this.text.visible = true;
      this.text.selected = false;
    },

    // Put Text
    putText: function(val) {
      let sneaker = this.sneaker[this.side];
      let text = JSON.parse(JSON.stringify(val));

      if (this.application === 'full') {
        sneaker.full.text.push(text);
      } else {
        sneaker.areas[this.areaId].text.push(text);
      }

      this.restartText();
    },

    // Edit Text
    editText: function(key) {
      let text = this.application === 'full'
        ? this.sneaker[this.side].full.text[key]
        : this.sneaker[this.side].areas[this.areaId].text[key];

      this.textKey = key;
      this.text = text;

      this.isTextFont = true;
    },

    // Select Text
    selectText: function(key) {
      let text = this.application === 'full'
        ? this.sneaker[this.side].full.text
        : this.sneaker[this.side].areas[this.areaId].text;

      for (let i = 0; i < text.length; i++) {
        if (i === key) {
          text[i].selected = !text[i].selected;
        } else {
          text[i].selected = false;
        }
      }
    },

    // Update Text
    updateText: function(key, val) {
      let sneaker = this.sneaker[this.side];
      let text = JSON.parse(JSON.stringify(val));

      if (this.application === 'full') {
        sneaker.full.text[key] = text;
      } else {
        sneaker.areas[this.areaId].text = text;
      }

      this.restartText();
    },

    // Delete Text
    deleteText: function(key) {
      let sneaker = this.sneaker[this.side];

      if (this.application === 'full') {
        sneaker.full.text.splice(key, 1);
      } else {
        sneaker.areas[this.areaId].text = null;
      }
    },

    // Visibility
    putAreaVisibility: function(key) {
      let area = this.sneaker[this.side].areas[key];

      area.visible = !area.visible;
    },

    putImageVisibility: function(key) {
      let image = this.application === 'full'
        ? this.sneaker[this.side].full.images[key]
        : this.sneaker[this.side].areas[this.areaId].image;

      image.visible = !image.visible;
    },

    putTextVisibility: function(key) {
      let text = this.application === 'full'
        ? this.sneaker[this.side].full.text[key]
        : this.sneaker[this.side].areas[this.areaId].text[key];

      text.visible = !text.visible;
    },

    // Area Styles
    getAreaMaskStyle: function(id) {
      let style = '';
      let area = this.sneaker[this.side].areas[id];

      style += "top:" + area.top + "px;";
      style += "left:" + area.left + "px;";
      style += "width:" + area.width + "px;";
      style += "height:" + area.height + "px;";
      style += "mask-image: url('" + require('@/assets/img/'+ this.side + '/' + id + '.png') + "');";

      return style;
    },

    // Update Attr
    updateSneakerAttr: function(payload) {
      let selected;

      if (this.property === 'image') {
        if (this.application === 'full') {
          this.imageItems.forEach(image => {
            if (image.selected) {
              selected = image;
            }
          });
        } else if (this.imageItems[0] !== null) {
          selected = this.imageItems[0];
        }
      }

      if (this.property === 'text') {
        this.textItems.forEach(text => {
          if (text.selected) {
            selected = text;
          }
        });
      }

      if (typeof selected !== 'undefined') {
        for (let attr in payload) {
          selected[attr] = payload[attr];
        }
      }
    },

    //Repeat
    repeat: function() {
      this.sides.forEach(side => {
        this.restartSneaker(this.sneaker[side].full);
      });

      this.sides.forEach(side => {
        for (let id in this.sneaker[side].areas) {
          this.restartSneaker(this.sneaker[side].areas[id]);
        }
      });
    },

    restartArea: function(areaId = null) {
      if (areaId === null) {
        if (this.application === 'full') {
          this.restartSneaker(this.sneaker[this.side].full);
        } else {
          this.restartSneaker(this.sneaker[this.side].areas[this.areaId]);
        }
      } else {
        this.restartSneaker(this.sneaker[this.side].areas[areaId]);
      }

      this.hideRestartArea();
    },

    restartSneaker: function(piece) {
      piece.x = 0;
      piece.y = 0;
      piece.scaleX = 1;
      piece.scaleY = 1;
      piece.angle = 0;
      piece.style = null;
      piece.text = [];

      piece.color = {
        value: null,
        visible: true
      };

      if (typeof piece.image !== 'undefined') {
        piece.image = null;
      }

      if (typeof piece.images !== 'undefined') {
        piece.images = [];
      }
    },

    // Images in Share
    getImagesPreview: async function() {
      this.isZoom = false;
      this.imagesPreview = [];

      if (this.application === 'full') {
        let full = this.sneaker[this.side].full;

        this.imagesPreview.push({
          top: full.top,
          left: full.left,
          src: await this.createImagePreview('full', full.points)
        });
      } else {
        for (let areaId in this.sneaker[this.side].areas) {
          let area = this.sneaker[this.side].areas[areaId];

          this.imagesPreview.push({
            top: area.top,
            left: area.left,
            src: await this.createImagePreview(areaId, area.points)
          });
        }
      }
    },

    createImagePreview: async function(ref, points) {
      let htmlElement = ref === 'full'
        ? this.$refs[ref]
        : this.$refs[ref][0];

      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let cw, ch;

      let c = document.createElement('canvas');
      let cx = c.getContext('2d');

      let img = new Image();

      let width;
      let height;

      let minX = 10000;
      let minY = 10000;
      let maxX = -10000;
      let maxY = -10000;

      img.crossOrigin = 'anonymous';

      img.src = await this.$html2canvas(htmlElement, {
        type: 'dataURL',
        useCORS: true,
        logging: false,
        allowTaint: true
      });

      await img.decode();

      cw = canvas.width = img.width;
      ch = canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      for (let i = 1; i < points.length; i++) {
        let p = points[i];

        if (p.x * this.scale < minX) {
          minX = p.x * this.scale;
        }

        if (p.y * this.scale < minY) {
          minY = p.y * this.scale;
        }

        if (p.x * this.scale > maxX) {
          maxX = p.x * this.scale;
        }

        if (p.y * this.scale > maxY) {
          maxY = p.y * this.scale;
        }
      }

      width = maxX - minX;
      height = maxY - minY;

      ctx.save();
      ctx.clearRect(0, 0, cw, ch);
      ctx.beginPath();
      ctx.moveTo(points[0].x * this.scale, points[0].y * this.scale);

      for (let i = 1; i < points.length; i++){
        ctx.lineTo(points[i].x * this.scale, points[i].y * this.scale);
      }

      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0);
      ctx.restore();

      c.width = width;
      c.height = height;

      cx.drawImage(canvas, minX, minY, width, height, 0, 0, width, height);

      return c.toDataURL();
    },

    // Tools
    switchZoom: function() {
      this.isZoom = !this.isZoom;
    },

    switchEdit: function() {
      this.isEditable = !this.isEditable;
    },

    // Modals
    switchRenderImg: function() {
      EventBus.$on('showRenderImg', () => {
        this.isRenderImg = true;
      });

      EventBus.$on('hideRenderImg', () => {
        this.isRenderImg = false;
      });
    },

    showRepeat: function() {
      this.isRepeat = true;
    },

    hideRepeat: function() {
      EventBus.$on('hidePreviewRepeat', () => {
        this.isRepeat = false;
      });
    },

    showRestartArea: function(areaId = null) {
      this.resAreaId = areaId;

      this.isRestartArea = true;
    },

    hideRestartArea: function() {
      this.resAreaId = null;

      EventBus.$on('hideRestartArea', () => {
        this.isRestartArea = false;
      });
    },

    showPreviewShare: async function() {
      await this.getImagesPreview();

      this.isShare = true;
    },

    hidePreviewShare: function() {
      EventBus.$on('hidePreviewShare', () => {
        this.isShare = false;
      });
    },

    switchSizeGuide: function() {
      EventBus.$on('showSizeGuide', () => {
        this.isSizeGuide = true;
      });

      EventBus.$on('hideSizeGuide', () => {
        this.isSizeGuide = false;
      });
    },

    switchVerifyNft: function() {
      EventBus.$on('showVerifyNft', () => {
        this.isVerifyNft = true;
      });

      EventBus.$on('hideVerifyNft', () => {
        this.isVerifyNft = false;
      });
    },

    switchTextFont: function() {
      EventBus.$on('showTextFont', () => {
        this.isTextFont = true;
      });

      EventBus.$on('hideTextFont', () => {
        this.isTextFont = false;
      });
    }
  },

  mounted() {
    this.repeat();

    this.switchRenderImg();
    this.hideRepeat();
    this.hidePreviewShare();
    this.switchSizeGuide();
    this.switchVerifyNft();
    this.switchTextFont();
  }
}
