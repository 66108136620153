var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'button-icon',
    'button-icon--' + _vm.type,
    'button-icon--' + _vm.shape,
    'button-icon--' + _vm.size,
    _vm.color,
    _vm.active
      ? 'active'
      : null
  ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon != null)?_c('i',{class:[
      'button-icon__icon',
      _vm.icon
    ]}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }