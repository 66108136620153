import ColorSelectIcon from '@/components/icons/colorSelect'
import EyeIcon from '@/components/icons/eye'
import EyeOffIcon from '@/components/icons/eyeOff'
import TrashIcon from '@/components/icons/trash'

import ButtonIcon from '@/components/form/buttonIcon'

export default {
  name: 'ColorPicker',

  components: {
    ColorSelectIcon,
    EyeIcon,
    EyeOffIcon,
    TrashIcon,

    ButtonIcon
  },

  props: {
    color: {
      type: Object,
      default: null
    }
  },

  computed: {
    isWhite: function() {
      return this.isWhiteColor(this.color.value);
    }
  },

  data() {
    return {
      colors: [
        '#40C381',
        '#2871C8',
        '#B288DE',
        '#F26D68',
        '#FFCD5D',
        '#4A5267',
        '#FFFFFF',
        '#F79319',
        '#F84F31',
        '#ECB63C',
        '#C7241E',
        '#373737',
        '#D0ADF4',
        '#000000'
      ],

      colorPanel: '#0626EB',
      dropperHover: false
    }
  },

  methods: {
    changeDropperHover: function(val) {
      this.dropperHover = val;
    },

    isWhiteColor: function(color) {
      return color === '#FFF' || color === '#FFFFFF';
    },

    getStyle: function(color) {
      return color !== null
        ? 'background-color:' + color + ';'
        : null;
    },

    selectColor: function(color) {
      this.$emit('change', {
        value: color,
        visible: this.color.visible
      });
    },

    deleteColor: function() {
      this.$emit('change', {
        value: null,
        visible: this.color.visible
      });
    },

    switchVisibility: function() {
      this.$emit('change', {
        value: this.color.value,
        visible: !this.color.visible
      });
    }
  },

  watch: {
    colorPanel: function(val) {
      this.selectColor(val);
    }
  }
}
