import Input from '@/components/form/input'
import Select from '@/components/form/select'

import countries from '@/config/countries.js'
import vmodel from '@/mixins/vmodel.js'

export default {
  name: 'SidebarContact',

  mixins: [vmodel],

  components: {
    Input,
    Select
  },

  data() {
    return {
      countryOptions: []
    }
  },

  methods: {
    getCountries: function() {
      this.countryOptions = countries;
    }
  },

  mounted() {
    this.getCountries();
  }
}
