var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"color-picker"},[_c('div',{staticClass:"color-picker__controls"},[_c('div',{staticClass:"controls__input"},[_c('div',{staticClass:"input__color",style:(_vm.getStyle(_vm.color.value))},[_c('span',{class:[
            'color__value',
            _vm.isWhite
              ? 'color__value--dark'
              : 'color__value--white'
          ]},[_vm._v(" "+_vm._s(_vm.color.value)+" ")])]),_c('div',{staticClass:"input__dropper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.colorPanel),expression:"colorPanel"}],staticClass:"dropper__input",attrs:{"type":"color"},domProps:{"value":(_vm.colorPanel)},on:{"mouseover":function($event){return _vm.changeDropperHover(true)},"mouseleave":function($event){return _vm.changeDropperHover(false)},"input":function($event){if($event.target.composing){ return; }_vm.colorPanel=$event.target.value}}}),_c('ButtonIcon',{attrs:{"type":"light","shape":"square","active":_vm.dropperHover}},[_c('ColorSelectIcon',{staticClass:"dropper__icon"})],1)],1)]),_c('div',{staticClass:"controls__actions"},[_c('div',{staticClass:"actions__item",on:{"click":function($event){return _vm.switchVisibility()}}},[(_vm.color.visible)?_c('EyeOffIcon',{staticClass:"item__icon"}):_c('EyeIcon',{staticClass:"item__icon"})],1),_c('div',{staticClass:"actions__item",on:{"click":function($event){return _vm.deleteColor()}}},[_c('TrashIcon',{staticClass:"item__icon"})],1)])]),_c('div',{staticClass:"color-picker__colors"},_vm._l((_vm.colors),function(colorItem){return _c('div',{class:[
        'colors__item',
        _vm.isWhiteColor(colorItem)
          ? 'colors__item--white'
          : null,
        colorItem === _vm.color
          ? 'colors__item--active'
          : null
      ],style:(_vm.getStyle(colorItem)),on:{"click":function($event){return _vm.selectColor(colorItem)}}})}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }