module.exports=[
  {x:2652.0000,y:790.0000},
  {x:2650.3335,y:784.0006},
  {x:2648.6665,y:777.9994},
  {x:2647.0000,y:772.0000},
  {x:2646.6667,y:772.0000},
  {x:2646.3333,y:772.0000},
  {x:2646.0000,y:772.0000},
  {x:2646.0000,y:771.3334},
  {x:2646.0000,y:770.6666},
  {x:2646.0000,y:770.0000},
  {x:2645.6667,y:770.0000},
  {x:2645.3333,y:770.0000},
  {x:2645.0000,y:770.0000},
  {x:2645.0000,y:769.0001},
  {x:2645.0000,y:767.9999},
  {x:2645.0000,y:767.0000},
  {x:2644.6667,y:767.0000},
  {x:2644.3333,y:767.0000},
  {x:2644.0000,y:767.0000},
  {x:2644.0000,y:766.3334},
  {x:2644.0000,y:765.6666},
  {x:2644.0000,y:765.0000},
  {x:2643.6667,y:765.0000},
  {x:2643.3333,y:765.0000},
  {x:2643.0000,y:765.0000},
  {x:2642.6667,y:763.3335},
  {x:2642.3333,y:761.6665},
  {x:2642.0000,y:760.0000},
  {x:2641.6667,y:760.0000},
  {x:2641.3333,y:760.0000},
  {x:2641.0000,y:760.0000},
  {x:2641.0000,y:759.0001},
  {x:2641.0000,y:757.9999},
  {x:2641.0000,y:757.0000},
  {x:2640.6667,y:757.0000},
  {x:2640.3333,y:757.0000},
  {x:2640.0000,y:757.0000},
  {x:2640.0000,y:756.3334},
  {x:2640.0000,y:755.6666},
  {x:2640.0000,y:755.0000},
  {x:2639.6667,y:755.0000},
  {x:2639.3333,y:755.0000},
  {x:2639.0000,y:755.0000},
  {x:2639.0000,y:754.0001},
  {x:2639.0000,y:752.9999},
  {x:2639.0000,y:752.0000},
  {x:2638.6667,y:752.0000},
  {x:2638.3333,y:752.0000},
  {x:2638.0000,y:752.0000},
  {x:2637.6667,y:750.6668},
  {x:2637.3333,y:749.3332},
  {x:2637.0000,y:748.0000},
  {x:2636.6667,y:748.0000},
  {x:2636.3333,y:748.0000},
  {x:2636.0000,y:748.0000},
  {x:2636.0000,y:747.0001},
  {x:2636.0000,y:745.9999},
  {x:2636.0000,y:745.0000},
  {x:2635.6667,y:745.0000},
  {x:2635.3333,y:745.0000},
  {x:2635.0000,y:745.0000},
  {x:2634.6667,y:743.6668},
  {x:2634.3333,y:742.3332},
  {x:2634.0000,y:741.0000},
  {x:2633.6667,y:741.0000},
  {x:2633.3333,y:741.0000},
  {x:2633.0000,y:741.0000},
  {x:2633.0000,y:740.0001},
  {x:2633.0000,y:738.9999},
  {x:2633.0000,y:738.0000},
  {x:2632.6667,y:738.0000},
  {x:2632.3333,y:738.0000},
  {x:2632.0000,y:738.0000},
  {x:2632.0000,y:737.3334},
  {x:2632.0000,y:736.6666},
  {x:2632.0000,y:736.0000},
  {x:2631.6667,y:736.0000},
  {x:2631.3333,y:736.0000},
  {x:2631.0000,y:736.0000},
  {x:2630.6667,y:734.6668},
  {x:2630.3333,y:733.3332},
  {x:2630.0000,y:732.0000},
  {x:2629.6667,y:732.0000},
  {x:2629.3333,y:732.0000},
  {x:2629.0000,y:732.0000},
  {x:2629.0000,y:731.0001},
  {x:2629.0000,y:729.9999},
  {x:2629.0000,y:729.0000},
  {x:2628.6667,y:729.0000},
  {x:2628.3333,y:729.0000},
  {x:2628.0000,y:729.0000},
  {x:2628.0000,y:728.3334},
  {x:2628.0000,y:727.6666},
  {x:2628.0000,y:727.0000},
  {x:2627.6667,y:727.0000},
  {x:2627.3333,y:727.0000},
  {x:2627.0000,y:727.0000},
  {x:2626.3334,y:725.0002},
  {x:2625.6666,y:722.9998},
  {x:2625.0000,y:721.0000},
  {x:2624.6667,y:721.0000},
  {x:2624.3333,y:721.0000},
  {x:2624.0000,y:721.0000},
  {x:2624.0000,y:720.0001},
  {x:2624.0000,y:718.9999},
  {x:2624.0000,y:718.0000},
  {x:2623.6667,y:718.0000},
  {x:2623.3333,y:718.0000},
  {x:2623.0000,y:718.0000},
  {x:2623.0000,y:717.3334},
  {x:2623.0000,y:716.6666},
  {x:2623.0000,y:716.0000},
  {x:2622.6667,y:716.0000},
  {x:2622.3333,y:716.0000},
  {x:2622.0000,y:716.0000},
  {x:2621.6667,y:714.6668},
  {x:2621.3333,y:713.3332},
  {x:2621.0000,y:712.0000},
  {x:2620.6667,y:712.0000},
  {x:2620.3333,y:712.0000},
  {x:2620.0000,y:712.0000},
  {x:2619.6667,y:710.6668},
  {x:2619.3333,y:709.3332},
  {x:2619.0000,y:708.0000},
  {x:2618.6667,y:708.0000},
  {x:2618.3333,y:708.0000},
  {x:2618.0000,y:708.0000},
  {x:2618.0000,y:707.3334},
  {x:2618.0000,y:706.6666},
  {x:2618.0000,y:706.0000},
  {x:2617.6667,y:706.0000},
  {x:2617.3333,y:706.0000},
  {x:2617.0000,y:706.0000},
  {x:2617.0000,y:705.3334},
  {x:2617.0000,y:704.6666},
  {x:2617.0000,y:704.0000},
  {x:2616.6667,y:704.0000},
  {x:2616.3333,y:704.0000},
  {x:2616.0000,y:704.0000},
  {x:2616.0000,y:703.3334},
  {x:2616.0000,y:702.6666},
  {x:2616.0000,y:702.0000},
  {x:2615.6667,y:702.0000},
  {x:2615.3333,y:702.0000},
  {x:2615.0000,y:702.0000},
  {x:2615.0000,y:701.3334},
  {x:2615.0000,y:700.6666},
  {x:2615.0000,y:700.0000},
  {x:2614.6667,y:700.0000},
  {x:2614.3333,y:700.0000},
  {x:2614.0000,y:700.0000},
  {x:2614.0000,y:699.3334},
  {x:2614.0000,y:698.6666},
  {x:2614.0000,y:698.0000},
  {x:2613.6667,y:698.0000},
  {x:2613.3333,y:698.0000},
  {x:2613.0000,y:698.0000},
  {x:2612.3334,y:696.0002},
  {x:2611.6666,y:693.9998},
  {x:2611.0000,y:692.0000},
  {x:2610.6667,y:692.0000},
  {x:2610.3333,y:692.0000},
  {x:2610.0000,y:692.0000},
  {x:2609.6667,y:690.6668},
  {x:2609.3333,y:689.3332},
  {x:2609.0000,y:688.0000},
  {x:2608.6667,y:688.0000},
  {x:2608.3333,y:688.0000},
  {x:2608.0000,y:688.0000},
  {x:2607.6667,y:686.6668},
  {x:2607.3333,y:685.3332},
  {x:2607.0000,y:684.0000},
  {x:2606.6667,y:684.0000},
  {x:2606.3333,y:684.0000},
  {x:2606.0000,y:684.0000},
  {x:2606.0000,y:683.3334},
  {x:2606.0000,y:682.6666},
  {x:2606.0000,y:682.0000},
  {x:2605.6667,y:682.0000},
  {x:2605.3333,y:682.0000},
  {x:2605.0000,y:682.0000},
  {x:2605.0000,y:681.3334},
  {x:2605.0000,y:680.6666},
  {x:2605.0000,y:680.0000},
  {x:2604.6667,y:680.0000},
  {x:2604.3333,y:680.0000},
  {x:2604.0000,y:680.0000},
  {x:2604.0000,y:679.3334},
  {x:2604.0000,y:678.6666},
  {x:2604.0000,y:678.0000},
  {x:2603.6667,y:678.0000},
  {x:2603.3333,y:678.0000},
  {x:2603.0000,y:678.0000},
  {x:2603.0000,y:677.3334},
  {x:2603.0000,y:676.6666},
  {x:2603.0000,y:676.0000},
  {x:2602.6667,y:676.0000},
  {x:2602.3333,y:676.0000},
  {x:2602.0000,y:676.0000},
  {x:2602.0000,y:675.3334},
  {x:2602.0000,y:674.6666},
  {x:2602.0000,y:674.0000},
  {x:2601.6667,y:674.0000},
  {x:2601.3333,y:674.0000},
  {x:2601.0000,y:674.0000},
  {x:2600.3334,y:672.0002},
  {x:2599.6666,y:669.9998},
  {x:2599.0000,y:668.0000},
  {x:2598.3334,y:667.6667},
  {x:2597.6666,y:667.3333},
  {x:2597.0000,y:667.0000},
  {x:2597.0000,y:666.3334},
  {x:2597.0000,y:665.6666},
  {x:2597.0000,y:665.0000},
  {x:2596.6667,y:665.0000},
  {x:2596.3333,y:665.0000},
  {x:2596.0000,y:665.0000},
  {x:2596.0000,y:664.3334},
  {x:2596.0000,y:663.6666},
  {x:2596.0000,y:663.0000},
  {x:2595.6667,y:663.0000},
  {x:2595.3333,y:663.0000},
  {x:2595.0000,y:663.0000},
  {x:2595.0000,y:662.3334},
  {x:2595.0000,y:661.6666},
  {x:2595.0000,y:661.0000},
  {x:2594.6667,y:661.0000},
  {x:2594.3333,y:661.0000},
  {x:2594.0000,y:661.0000},
  {x:2594.0000,y:660.3334},
  {x:2594.0000,y:659.6666},
  {x:2594.0000,y:659.0000},
  {x:2593.6667,y:659.0000},
  {x:2593.3333,y:659.0000},
  {x:2593.0000,y:659.0000},
  {x:2593.0000,y:658.3334},
  {x:2593.0000,y:657.6666},
  {x:2593.0000,y:657.0000},
  {x:2592.3334,y:656.6667},
  {x:2591.6666,y:656.3333},
  {x:2591.0000,y:656.0000},
  {x:2591.0000,y:655.3334},
  {x:2591.0000,y:654.6666},
  {x:2591.0000,y:654.0000},
  {x:2590.6667,y:654.0000},
  {x:2590.3333,y:654.0000},
  {x:2590.0000,y:654.0000},
  {x:2590.0000,y:653.3334},
  {x:2590.0000,y:652.6666},
  {x:2590.0000,y:652.0000},
  {x:2589.6667,y:652.0000},
  {x:2589.3333,y:652.0000},
  {x:2589.0000,y:652.0000},
  {x:2589.0000,y:651.3334},
  {x:2589.0000,y:650.6666},
  {x:2589.0000,y:650.0000},
  {x:2588.6667,y:650.0000},
  {x:2588.3333,y:650.0000},
  {x:2588.0000,y:650.0000},
  {x:2588.0000,y:649.3334},
  {x:2588.0000,y:648.6666},
  {x:2588.0000,y:648.0000},
  {x:2587.3334,y:647.6667},
  {x:2586.6666,y:647.3333},
  {x:2586.0000,y:647.0000},
  {x:2586.0000,y:646.3334},
  {x:2586.0000,y:645.6666},
  {x:2586.0000,y:645.0000},
  {x:2585.6667,y:645.0000},
  {x:2585.3333,y:645.0000},
  {x:2585.0000,y:645.0000},
  {x:2585.0000,y:644.3334},
  {x:2585.0000,y:643.6666},
  {x:2585.0000,y:643.0000},
  {x:2584.6667,y:643.0000},
  {x:2584.3333,y:643.0000},
  {x:2584.0000,y:643.0000},
  {x:2584.0000,y:642.3334},
  {x:2584.0000,y:641.6666},
  {x:2584.0000,y:641.0000},
  {x:2583.6667,y:641.0000},
  {x:2583.3333,y:641.0000},
  {x:2583.0000,y:641.0000},
  {x:2583.0000,y:640.3334},
  {x:2583.0000,y:639.6666},
  {x:2583.0000,y:639.0000},
  {x:2582.3334,y:638.6667},
  {x:2581.6666,y:638.3333},
  {x:2581.0000,y:638.0000},
  {x:2581.0000,y:637.3334},
  {x:2581.0000,y:636.6666},
  {x:2581.0000,y:636.0000},
  {x:2580.6667,y:636.0000},
  {x:2580.3333,y:636.0000},
  {x:2580.0000,y:636.0000},
  {x:2580.0000,y:635.3334},
  {x:2580.0000,y:634.6666},
  {x:2580.0000,y:634.0000},
  {x:2579.3334,y:633.6667},
  {x:2578.6666,y:633.3333},
  {x:2578.0000,y:633.0000},
  {x:2577.3334,y:631.0002},
  {x:2576.6666,y:628.9998},
  {x:2576.0000,y:627.0000},
  {x:2575.3334,y:626.6667},
  {x:2574.6666,y:626.3333},
  {x:2574.0000,y:626.0000},
  {x:2573.6667,y:624.6668},
  {x:2573.3333,y:623.3332},
  {x:2573.0000,y:622.0000},
  {x:2572.3334,y:621.6667},
  {x:2571.6666,y:621.3333},
  {x:2571.0000,y:621.0000},
  {x:2570.6667,y:619.6668},
  {x:2570.3333,y:618.3332},
  {x:2570.0000,y:617.0000},
  {x:2569.3334,y:616.6667},
  {x:2568.6666,y:616.3333},
  {x:2568.0000,y:616.0000},
  {x:2567.6667,y:614.6668},
  {x:2567.3333,y:613.3332},
  {x:2567.0000,y:612.0000},
  {x:2566.3334,y:611.6667},
  {x:2565.6666,y:611.3333},
  {x:2565.0000,y:611.0000},
  {x:2565.0000,y:610.3334},
  {x:2565.0000,y:609.6666},
  {x:2565.0000,y:609.0000},
  {x:2564.6667,y:609.0000},
  {x:2564.3333,y:609.0000},
  {x:2564.0000,y:609.0000},
  {x:2564.0000,y:608.3334},
  {x:2564.0000,y:607.6666},
  {x:2564.0000,y:607.0000},
  {x:2563.6667,y:607.0000},
  {x:2563.3333,y:607.0000},
  {x:2563.0000,y:607.0000},
  {x:2562.6667,y:606.0001},
  {x:2562.3333,y:604.9999},
  {x:2562.0000,y:604.0000},
  {x:2561.3334,y:603.6667},
  {x:2560.6666,y:603.3333},
  {x:2560.0000,y:603.0000},
  {x:2560.0000,y:602.3334},
  {x:2560.0000,y:601.6666},
  {x:2560.0000,y:601.0000},
  {x:2559.6667,y:601.0000},
  {x:2559.3333,y:601.0000},
  {x:2559.0000,y:601.0000},
  {x:2559.0000,y:600.3334},
  {x:2559.0000,y:599.6666},
  {x:2559.0000,y:599.0000},
  {x:2558.3334,y:598.6667},
  {x:2557.6666,y:598.3333},
  {x:2557.0000,y:598.0000},
  {x:2557.0000,y:597.3334},
  {x:2557.0000,y:596.6666},
  {x:2557.0000,y:596.0000},
  {x:2556.3334,y:595.6667},
  {x:2555.6666,y:595.3333},
  {x:2555.0000,y:595.0000},
  {x:2554.6667,y:593.6668},
  {x:2554.3333,y:592.3332},
  {x:2554.0000,y:591.0000},
  {x:2553.3334,y:590.6667},
  {x:2552.6666,y:590.3333},
  {x:2552.0000,y:590.0000},
  {x:2552.0000,y:589.3334},
  {x:2552.0000,y:588.6666},
  {x:2552.0000,y:588.0000},
  {x:2551.6667,y:588.0000},
  {x:2551.3333,y:588.0000},
  {x:2551.0000,y:588.0000},
  {x:2550.6667,y:587.0001},
  {x:2550.3333,y:585.9999},
  {x:2550.0000,y:585.0000},
  {x:2549.3334,y:584.6667},
  {x:2548.6666,y:584.3333},
  {x:2548.0000,y:584.0000},
  {x:2548.0000,y:583.3334},
  {x:2548.0000,y:582.6666},
  {x:2548.0000,y:582.0000},
  {x:2547.3334,y:581.6667},
  {x:2546.6666,y:581.3333},
  {x:2546.0000,y:581.0000},
  {x:2546.0000,y:580.3334},
  {x:2546.0000,y:579.6666},
  {x:2546.0000,y:579.0000},
  {x:2545.3334,y:578.6667},
  {x:2544.6666,y:578.3333},
  {x:2544.0000,y:578.0000},
  {x:2544.0000,y:577.3334},
  {x:2544.0000,y:576.6666},
  {x:2544.0000,y:576.0000},
  {x:2543.3334,y:575.6667},
  {x:2542.6666,y:575.3333},
  {x:2542.0000,y:575.0000},
  {x:2542.0000,y:574.3334},
  {x:2542.0000,y:573.6666},
  {x:2542.0000,y:573.0000},
  {x:2541.3334,y:572.6667},
  {x:2540.6666,y:572.3333},
  {x:2540.0000,y:572.0000},
  {x:2540.0000,y:571.3334},
  {x:2540.0000,y:570.6666},
  {x:2540.0000,y:570.0000},
  {x:2539.3334,y:569.6667},
  {x:2538.6666,y:569.3333},
  {x:2538.0000,y:569.0000},
  {x:2538.0000,y:568.3334},
  {x:2538.0000,y:567.6666},
  {x:2538.0000,y:567.0000},
  {x:2537.3334,y:566.6667},
  {x:2536.6666,y:566.3333},
  {x:2536.0000,y:566.0000},
  {x:2536.0000,y:565.3334},
  {x:2536.0000,y:564.6666},
  {x:2536.0000,y:564.0000},
  {x:2535.3334,y:563.6667},
  {x:2534.6666,y:563.3333},
  {x:2534.0000,y:563.0000},
  {x:2534.0000,y:562.3334},
  {x:2534.0000,y:561.6666},
  {x:2534.0000,y:561.0000},
  {x:2533.6667,y:561.0000},
  {x:2533.3333,y:561.0000},
  {x:2533.0000,y:561.0000},
  {x:2532.6667,y:560.0001},
  {x:2532.3333,y:558.9999},
  {x:2532.0000,y:558.0000},
  {x:2531.3334,y:557.6667},
  {x:2530.6666,y:557.3333},
  {x:2530.0000,y:557.0000},
  {x:2530.0000,y:556.3334},
  {x:2530.0000,y:555.6666},
  {x:2530.0000,y:555.0000},
  {x:2529.0001,y:554.3334},
  {x:2527.9999,y:553.6666},
  {x:2527.0000,y:553.0000},
  {x:2527.0000,y:552.3334},
  {x:2527.0000,y:551.6666},
  {x:2527.0000,y:551.0000},
  {x:2526.6667,y:551.0000},
  {x:2526.3333,y:551.0000},
  {x:2526.0000,y:551.0000},
  {x:2525.6667,y:550.0001},
  {x:2525.3333,y:548.9999},
  {x:2525.0000,y:548.0000},
  {x:2524.3334,y:547.6667},
  {x:2523.6666,y:547.3333},
  {x:2523.0000,y:547.0000},
  {x:2523.0000,y:546.3334},
  {x:2523.0000,y:545.6666},
  {x:2523.0000,y:545.0000},
  {x:2522.0001,y:544.3334},
  {x:2520.9999,y:543.6666},
  {x:2520.0000,y:543.0000},
  {x:2520.0000,y:542.3334},
  {x:2520.0000,y:541.6666},
  {x:2520.0000,y:541.0000},
  {x:2519.0001,y:540.3334},
  {x:2517.9999,y:539.6666},
  {x:2517.0000,y:539.0000},
  {x:2517.0000,y:538.3334},
  {x:2517.0000,y:537.6666},
  {x:2517.0000,y:537.0000},
  {x:2516.3334,y:536.6667},
  {x:2515.6666,y:536.3333},
  {x:2515.0000,y:536.0000},
  {x:2515.0000,y:535.3334},
  {x:2515.0000,y:534.6666},
  {x:2515.0000,y:534.0000},
  {x:2514.0001,y:533.3334},
  {x:2512.9999,y:532.6666},
  {x:2512.0000,y:532.0000},
  {x:2512.0000,y:531.3334},
  {x:2512.0000,y:530.6666},
  {x:2512.0000,y:530.0000},
  {x:2511.0001,y:529.3334},
  {x:2509.9999,y:528.6666},
  {x:2509.0000,y:528.0000},
  {x:2508.6667,y:527.0001},
  {x:2508.3333,y:525.9999},
  {x:2508.0000,y:525.0000},
  {x:2507.3334,y:524.6667},
  {x:2506.6666,y:524.3333},
  {x:2506.0000,y:524.0000},
  {x:2506.0000,y:523.3334},
  {x:2506.0000,y:522.6666},
  {x:2506.0000,y:522.0000},
  {x:2505.3334,y:521.6667},
  {x:2504.6666,y:521.3333},
  {x:2504.0000,y:521.0000},
  {x:2504.0000,y:520.3334},
  {x:2504.0000,y:519.6666},
  {x:2504.0000,y:519.0000},
  {x:2503.0001,y:518.3334},
  {x:2501.9999,y:517.6666},
  {x:2501.0000,y:517.0000},
  {x:2501.0000,y:516.3334},
  {x:2501.0000,y:515.6666},
  {x:2501.0000,y:515.0000},
  {x:2500.3334,y:514.6667},
  {x:2499.6666,y:514.3333},
  {x:2499.0000,y:514.0000},
  {x:2498.6667,y:513.3334},
  {x:2498.3333,y:512.6666},
  {x:2498.0000,y:512.0000},
  {x:2496.8189,y:511.9775},
  {x:2496.1169,y:513.9834},
  {x:2494.0000,y:513.0000},
  {x:2492.6668,y:511.3335},
  {x:2491.3332,y:509.6665},
  {x:2490.0000,y:508.0000},
  {x:2488.0002,y:506.3335},
  {x:2485.9998,y:504.6665},
  {x:2484.0000,y:503.0000},
  {x:2483.3334,y:502.0001},
  {x:2482.6666,y:500.9999},
  {x:2482.0000,y:500.0000},
  {x:2481.3334,y:500.0000},
  {x:2480.6666,y:500.0000},
  {x:2480.0000,y:500.0000},
  {x:2471.6675,y:491.3342},
  {x:2463.3325,y:482.6658},
  {x:2455.0000,y:474.0000},
  {x:2447.6674,y:467.0007},
  {x:2440.3326,y:459.9993},
  {x:2433.0000,y:453.0000},
  {x:2433.0000,y:452.3334},
  {x:2433.0000,y:451.6666},
  {x:2433.0000,y:451.0000},
  {x:2432.6667,y:451.0000},
  {x:2432.3333,y:451.0000},
  {x:2432.0000,y:451.0000},
  {x:2431.3334,y:450.0001},
  {x:2430.6666,y:448.9999},
  {x:2430.0000,y:448.0000},
  {x:2425.6671,y:444.0004},
  {x:2421.3329,y:439.9996},
  {x:2417.0000,y:436.0000},
  {x:2417.0000,y:435.3334},
  {x:2417.0000,y:434.6666},
  {x:2417.0000,y:434.0000},
  {x:2413.0004,y:430.3337},
  {x:2408.9996,y:426.6663},
  {x:2405.0000,y:423.0000},
  {x:2405.0000,y:422.3334},
  {x:2405.0000,y:421.6666},
  {x:2405.0000,y:421.0000},
  {x:2402.3336,y:418.6669},
  {x:2399.6664,y:416.3331},
  {x:2397.0000,y:414.0000},
  {x:2396.3334,y:413.0001},
  {x:2395.6666,y:411.9999},
  {x:2395.0000,y:411.0000},
  {x:2394.6667,y:411.0000},
  {x:2394.3333,y:411.0000},
  {x:2394.0000,y:411.0000},
  {x:2394.0000,y:410.3334},
  {x:2394.0000,y:409.6666},
  {x:2394.0000,y:409.0000},
  {x:2391.0003,y:406.3336},
  {x:2387.9997,y:403.6664},
  {x:2385.0000,y:401.0000},
  {x:2385.0000,y:400.3334},
  {x:2385.0000,y:399.6666},
  {x:2385.0000,y:399.0000},
  {x:2382.0003,y:396.3336},
  {x:2378.9997,y:393.6664},
  {x:2376.0000,y:391.0000},
  {x:2376.0000,y:390.3334},
  {x:2376.0000,y:389.6666},
  {x:2376.0000,y:389.0000},
  {x:2373.3336,y:386.6669},
  {x:2370.6664,y:384.3331},
  {x:2368.0000,y:382.0000},
  {x:2368.0000,y:381.3334},
  {x:2368.0000,y:380.6666},
  {x:2368.0000,y:380.0000},
  {x:2365.6669,y:378.0002},
  {x:2363.3331,y:375.9998},
  {x:2361.0000,y:374.0000},
  {x:2361.0000,y:373.3334},
  {x:2361.0000,y:372.6666},
  {x:2361.0000,y:372.0000},
  {x:2358.3336,y:369.6669},
  {x:2355.6664,y:367.3331},
  {x:2353.0000,y:365.0000},
  {x:2353.0000,y:364.3334},
  {x:2353.0000,y:363.6666},
  {x:2353.0000,y:363.0000},
  {x:2352.3334,y:362.6667},
  {x:2351.6666,y:362.3333},
  {x:2351.0000,y:362.0000},
  {x:2351.0000,y:361.6667},
  {x:2351.0000,y:361.3333},
  {x:2351.0000,y:361.0000},
  {x:2350.3334,y:361.0000},
  {x:2349.6666,y:361.0000},
  {x:2349.0000,y:361.0000},
  {x:2348.6667,y:360.3334},
  {x:2348.3333,y:359.6666},
  {x:2348.0000,y:359.0000},
  {x:2347.0001,y:358.6667},
  {x:2345.9999,y:358.3333},
  {x:2345.0000,y:358.0000},
  {x:2345.0000,y:357.6667},
  {x:2345.0000,y:357.3333},
  {x:2345.0000,y:357.0000},
  {x:2344.3334,y:357.0000},
  {x:2343.6666,y:357.0000},
  {x:2343.0000,y:357.0000},
  {x:2342.6667,y:356.3334},
  {x:2342.3333,y:355.6666},
  {x:2342.0000,y:355.0000},
  {x:2341.3334,y:355.0000},
  {x:2340.6666,y:355.0000},
  {x:2340.0000,y:355.0000},
  {x:2339.6667,y:354.3334},
  {x:2339.3333,y:353.6666},
  {x:2339.0000,y:353.0000},
  {x:2338.3334,y:353.0000},
  {x:2337.6666,y:353.0000},
  {x:2337.0000,y:353.0000},
  {x:2336.3334,y:352.0001},
  {x:2335.6666,y:350.9999},
  {x:2335.0000,y:350.0000},
  {x:2334.3334,y:350.0000},
  {x:2333.6666,y:350.0000},
  {x:2333.0000,y:350.0000},
  {x:2332.6667,y:349.3334},
  {x:2332.3333,y:348.6666},
  {x:2332.0000,y:348.0000},
  {x:2331.3334,y:348.0000},
  {x:2330.6666,y:348.0000},
  {x:2330.0000,y:348.0000},
  {x:2329.0001,y:346.6668},
  {x:2327.9999,y:345.3332},
  {x:2327.0000,y:344.0000},
  {x:2326.3334,y:344.0000},
  {x:2325.6666,y:344.0000},
  {x:2325.0000,y:344.0000},
  {x:2324.3334,y:343.0001},
  {x:2323.6666,y:341.9999},
  {x:2323.0000,y:341.0000},
  {x:2322.3334,y:341.0000},
  {x:2321.6666,y:341.0000},
  {x:2321.0000,y:341.0000},
  {x:2320.0001,y:339.6668},
  {x:2318.9999,y:338.3332},
  {x:2318.0000,y:337.0000},
  {x:2317.3334,y:337.0000},
  {x:2316.6666,y:337.0000},
  {x:2316.0000,y:337.0000},
  {x:2314.6668,y:335.3335},
  {x:2313.3332,y:333.6665},
  {x:2312.0000,y:332.0000},
  {x:2311.3334,y:332.0000},
  {x:2310.6666,y:332.0000},
  {x:2310.0000,y:332.0000},
  {x:2308.6668,y:330.3335},
  {x:2307.3332,y:328.6665},
  {x:2306.0000,y:327.0000},
  {x:2305.3334,y:327.0000},
  {x:2304.6666,y:327.0000},
  {x:2304.0000,y:327.0000},
  {x:2301.6669,y:324.3336},
  {x:2299.3331,y:321.6664},
  {x:2297.0000,y:319.0000},
  {x:2296.3334,y:319.0000},
  {x:2295.6666,y:319.0000},
  {x:2295.0000,y:319.0000},
  {x:2291.6670,y:315.3337},
  {x:2288.3330,y:311.6663},
  {x:2285.0000,y:308.0000},
  {x:2284.3334,y:308.0000},
  {x:2283.6666,y:308.0000},
  {x:2283.0000,y:308.0000},
  {x:2277.3339,y:302.0006},
  {x:2271.6661,y:295.9994},
  {x:2266.0000,y:290.0000},
  {x:2263.0003,y:287.3336},
  {x:2259.9997,y:284.6664},
  {x:2257.0000,y:282.0000},
  {x:2257.0000,y:281.3334},
  {x:2257.0000,y:280.6666},
  {x:2257.0000,y:280.0000},
  {x:2252.3338,y:275.6671},
  {x:2247.6662,y:271.3329},
  {x:2243.0000,y:267.0000},
  {x:2243.0000,y:266.3334},
  {x:2243.0000,y:265.6666},
  {x:2243.0000,y:265.0000},
  {x:2240.0003,y:262.3336},
  {x:2236.9997,y:259.6664},
  {x:2234.0000,y:257.0000},
  {x:2234.0000,y:256.3334},
  {x:2234.0000,y:255.6666},
  {x:2234.0000,y:255.0000},
  {x:2231.6669,y:253.0002},
  {x:2229.3331,y:250.9998},
  {x:2227.0000,y:249.0000},
  {x:2227.0000,y:248.3334},
  {x:2227.0000,y:247.6666},
  {x:2227.0000,y:247.0000},
  {x:2225.0002,y:245.3335},
  {x:2222.9998,y:243.6665},
  {x:2221.0000,y:242.0000},
  {x:2221.0000,y:241.3334},
  {x:2221.0000,y:240.6666},
  {x:2221.0000,y:240.0000},
  {x:2219.3335,y:238.6668},
  {x:2217.6665,y:237.3332},
  {x:2216.0000,y:236.0000},
  {x:2216.0000,y:235.3334},
  {x:2216.0000,y:234.6666},
  {x:2216.0000,y:234.0000},
  {x:2214.3335,y:232.6668},
  {x:2212.6665,y:231.3332},
  {x:2211.0000,y:230.0000},
  {x:2211.0000,y:229.3334},
  {x:2211.0000,y:228.6666},
  {x:2211.0000,y:228.0000},
  {x:2209.6668,y:227.0001},
  {x:2208.3332,y:225.9999},
  {x:2207.0000,y:225.0000},
  {x:2207.0000,y:224.3334},
  {x:2207.0000,y:223.6666},
  {x:2207.0000,y:223.0000},
  {x:2205.3335,y:221.6668},
  {x:2203.6665,y:220.3332},
  {x:2202.0000,y:219.0000},
  {x:2202.0000,y:218.3334},
  {x:2202.0000,y:217.6666},
  {x:2202.0000,y:217.0000},
  {x:2201.3334,y:216.6667},
  {x:2200.6666,y:216.3333},
  {x:2200.0000,y:216.0000},
  {x:2199.6667,y:215.0001},
  {x:2199.3333,y:213.9999},
  {x:2199.0000,y:213.0000},
  {x:2197.6668,y:212.0001},
  {x:2196.3332,y:210.9999},
  {x:2195.0000,y:210.0000},
  {x:2195.0000,y:209.3334},
  {x:2195.0000,y:208.6666},
  {x:2195.0000,y:208.0000},
  {x:2193.6668,y:207.0001},
  {x:2192.3332,y:205.9999},
  {x:2191.0000,y:205.0000},
  {x:2191.0000,y:204.3334},
  {x:2191.0000,y:203.6666},
  {x:2191.0000,y:203.0000},
  {x:2190.0001,y:202.3334},
  {x:2188.9999,y:201.6666},
  {x:2188.0000,y:201.0000},
  {x:2188.0000,y:200.3334},
  {x:2188.0000,y:199.6666},
  {x:2188.0000,y:199.0000},
  {x:2187.0001,y:198.3334},
  {x:2185.9999,y:197.6666},
  {x:2185.0000,y:197.0000},
  {x:2185.0000,y:196.3334},
  {x:2185.0000,y:195.6666},
  {x:2185.0000,y:195.0000},
  {x:2184.0001,y:194.3334},
  {x:2182.9999,y:193.6666},
  {x:2182.0000,y:193.0000},
  {x:2182.0000,y:192.3334},
  {x:2182.0000,y:191.6666},
  {x:2182.0000,y:191.0000},
  {x:2181.0001,y:190.3334},
  {x:2179.9999,y:189.6666},
  {x:2179.0000,y:189.0000},
  {x:2179.0000,y:188.3334},
  {x:2179.0000,y:187.6666},
  {x:2179.0000,y:187.0000},
  {x:2178.0001,y:186.3334},
  {x:2176.9999,y:185.6666},
  {x:2176.0000,y:185.0000},
  {x:2176.0000,y:184.3334},
  {x:2176.0000,y:183.6666},
  {x:2176.0000,y:183.0000},
  {x:2175.0001,y:182.3334},
  {x:2173.9999,y:181.6666},
  {x:2173.0000,y:181.0000},
  {x:2172.6667,y:180.0001},
  {x:2172.3333,y:178.9999},
  {x:2172.0000,y:178.0000},
  {x:2171.3334,y:177.6667},
  {x:2170.6666,y:177.3333},
  {x:2170.0000,y:177.0000},
  {x:2170.0000,y:176.3334},
  {x:2170.0000,y:175.6666},
  {x:2170.0000,y:175.0000},
  {x:2169.3334,y:174.6667},
  {x:2168.6666,y:174.3333},
  {x:2168.0000,y:174.0000},
  {x:2168.0000,y:173.3334},
  {x:2168.0000,y:172.6666},
  {x:2168.0000,y:172.0000},
  {x:2167.0001,y:171.3334},
  {x:2165.9999,y:170.6666},
  {x:2165.0000,y:170.0000},
  {x:2165.0000,y:169.3334},
  {x:2165.0000,y:168.6666},
  {x:2165.0000,y:168.0000},
  {x:2164.6667,y:168.0000},
  {x:2164.3333,y:168.0000},
  {x:2164.0000,y:168.0000},
  {x:2163.6667,y:167.0001},
  {x:2163.3333,y:165.9999},
  {x:2163.0000,y:165.0000},
  {x:2162.3334,y:164.6667},
  {x:2161.6666,y:164.3333},
  {x:2161.0000,y:164.0000},
  {x:2161.0000,y:163.3334},
  {x:2161.0000,y:162.6666},
  {x:2161.0000,y:162.0000},
  {x:2160.0001,y:161.3334},
  {x:2158.9999,y:160.6666},
  {x:2158.0000,y:160.0000},
  {x:2158.0000,y:159.3334},
  {x:2158.0000,y:158.6666},
  {x:2158.0000,y:158.0000},
  {x:2157.3334,y:157.6667},
  {x:2156.6666,y:157.3333},
  {x:2156.0000,y:157.0000},
  {x:2155.6667,y:156.0001},
  {x:2155.3333,y:154.9999},
  {x:2155.0000,y:154.0000},
  {x:2154.6667,y:154.0000},
  {x:2154.3333,y:154.0000},
  {x:2154.0000,y:154.0000},
  {x:2154.0000,y:153.3334},
  {x:2154.0000,y:152.6666},
  {x:2154.0000,y:152.0000},
  {x:2153.3334,y:151.6667},
  {x:2152.6666,y:151.3333},
  {x:2152.0000,y:151.0000},
  {x:2152.0000,y:150.3334},
  {x:2152.0000,y:149.6666},
  {x:2152.0000,y:149.0000},
  {x:2151.3334,y:148.6667},
  {x:2150.6666,y:148.3333},
  {x:2150.0000,y:148.0000},
  {x:2150.0000,y:147.3334},
  {x:2150.0000,y:146.6666},
  {x:2150.0000,y:146.0000},
  {x:2149.3334,y:145.6667},
  {x:2148.6666,y:145.3333},
  {x:2148.0000,y:145.0000},
  {x:2148.0000,y:144.3334},
  {x:2148.0000,y:143.6666},
  {x:2148.0000,y:143.0000},
  {x:2147.3334,y:142.6667},
  {x:2146.6666,y:142.3333},
  {x:2146.0000,y:142.0000},
  {x:2146.0000,y:141.3334},
  {x:2146.0000,y:140.6666},
  {x:2146.0000,y:140.0000},
  {x:2145.3334,y:139.6667},
  {x:2144.6666,y:139.3333},
  {x:2144.0000,y:139.0000},
  {x:2144.0000,y:138.3334},
  {x:2144.0000,y:137.6666},
  {x:2144.0000,y:137.0000},
  {x:2143.3334,y:136.6667},
  {x:2142.6666,y:136.3333},
  {x:2142.0000,y:136.0000},
  {x:2142.0000,y:135.3334},
  {x:2142.0000,y:134.6666},
  {x:2142.0000,y:134.0000},
  {x:2141.3334,y:133.6667},
  {x:2140.6666,y:133.3333},
  {x:2140.0000,y:133.0000},
  {x:2139.6667,y:131.6668},
  {x:2139.3333,y:130.3332},
  {x:2139.0000,y:129.0000},
  {x:2138.0001,y:128.3334},
  {x:2136.9999,y:127.6666},
  {x:2136.0000,y:127.0000},
  {x:2136.0000,y:126.3334},
  {x:2136.0000,y:125.6666},
  {x:2136.0000,y:125.0000},
  {x:2135.3334,y:124.6667},
  {x:2134.6666,y:124.3333},
  {x:2134.0000,y:124.0000},
  {x:2133.6667,y:123.0001},
  {x:2133.3333,y:121.9999},
  {x:2133.0000,y:121.0000},
  {x:2132.6667,y:121.0000},
  {x:2132.3333,y:121.0000},
  {x:2132.0000,y:121.0000},
  {x:2132.0000,y:120.3334},
  {x:2132.0000,y:119.6666},
  {x:2132.0000,y:119.0000},
  {x:2131.3334,y:118.6667},
  {x:2130.6666,y:118.3333},
  {x:2130.0000,y:118.0000},
  {x:2130.0000,y:117.3334},
  {x:2130.0000,y:116.6666},
  {x:2130.0000,y:116.0000},
  {x:2129.3334,y:115.6667},
  {x:2128.6666,y:115.3333},
  {x:2128.0000,y:115.0000},
  {x:2128.0000,y:114.3334},
  {x:2128.0000,y:113.6666},
  {x:2128.0000,y:113.0000},
  {x:2127.3334,y:112.6667},
  {x:2126.6666,y:112.3333},
  {x:2126.0000,y:112.0000},
  {x:2126.0000,y:111.3334},
  {x:2126.0000,y:110.6666},
  {x:2126.0000,y:110.0000},
  {x:2125.3334,y:109.6667},
  {x:2124.6666,y:109.3333},
  {x:2124.0000,y:109.0000},
  {x:2124.0000,y:108.3334},
  {x:2124.0000,y:107.6666},
  {x:2124.0000,y:107.0000},
  {x:2123.3334,y:106.6667},
  {x:2122.6666,y:106.3333},
  {x:2122.0000,y:106.0000},
  {x:2122.0000,y:105.3334},
  {x:2122.0000,y:104.6666},
  {x:2122.0000,y:104.0000},
  {x:2121.3334,y:103.6667},
  {x:2120.6666,y:103.3333},
  {x:2120.0000,y:103.0000},
  {x:2120.0000,y:102.3334},
  {x:2120.0000,y:101.6666},
  {x:2120.0000,y:101.0000},
  {x:2119.3334,y:100.6667},
  {x:2118.6666,y:100.3333},
  {x:2118.0000,y:100.0000},
  {x:2118.0000,y:99.3334},
  {x:2118.0000,y:98.6666},
  {x:2118.0000,y:98.0000},
  {x:2117.6667,y:98.0000},
  {x:2117.3333,y:98.0000},
  {x:2117.0000,y:98.0000},
  {x:2117.0000,y:97.3334},
  {x:2117.0000,y:96.6666},
  {x:2117.0000,y:96.0000},
  {x:2116.3334,y:95.6667},
  {x:2115.6666,y:95.3333},
  {x:2115.0000,y:95.0000},
  {x:2114.6667,y:93.6668},
  {x:2114.3333,y:92.3332},
  {x:2114.0000,y:91.0000},
  {x:2113.6667,y:91.0000},
  {x:2113.3333,y:91.0000},
  {x:2113.0000,y:91.0000},
  {x:2112.6667,y:90.0001},
  {x:2112.3333,y:88.9999},
  {x:2112.0000,y:88.0000},
  {x:2111.3334,y:87.6667},
  {x:2110.6666,y:87.3333},
  {x:2110.0000,y:87.0000},
  {x:2109.6667,y:85.6668},
  {x:2109.3333,y:84.3332},
  {x:2109.0000,y:83.0000},
  {x:2108.3334,y:82.6667},
  {x:2107.6666,y:82.3333},
  {x:2107.0000,y:82.0000},
  {x:2106.6667,y:80.6668},
  {x:2106.3333,y:79.3332},
  {x:2106.0000,y:78.0000},
  {x:2105.3334,y:77.6667},
  {x:2104.6666,y:77.3333},
  {x:2104.0000,y:77.0000},
  {x:2103.3334,y:75.0002},
  {x:2102.6666,y:72.9998},
  {x:2102.0000,y:71.0000},
  {x:2101.3334,y:70.6667},
  {x:2100.6666,y:70.3333},
  {x:2100.0000,y:70.0000},
  {x:2099.3334,y:68.0002},
  {x:2098.6666,y:65.9998},
  {x:2098.0000,y:64.0000},
  {x:2097.3334,y:63.6667},
  {x:2096.6666,y:63.3333},
  {x:2096.0000,y:63.0000},
  {x:2095.3334,y:61.0002},
  {x:2094.6666,y:58.9998},
  {x:2094.0000,y:57.0000},
  {x:2093.3334,y:56.6667},
  {x:2092.6666,y:56.3333},
  {x:2092.0000,y:56.0000},
  {x:2092.0000,y:55.3334},
  {x:2092.0000,y:54.6666},
  {x:2092.0000,y:54.0000},
  {x:2091.6667,y:54.0000},
  {x:2091.3333,y:54.0000},
  {x:2091.0000,y:54.0000},
  {x:2091.0000,y:53.3334},
  {x:2091.0000,y:52.6666},
  {x:2091.0000,y:52.0000},
  {x:2090.6667,y:52.0000},
  {x:2090.3333,y:52.0000},
  {x:2090.0000,y:52.0000},
  {x:2090.0000,y:51.3334},
  {x:2090.0000,y:50.6666},
  {x:2090.0000,y:50.0000},
  {x:2089.6667,y:50.0000},
  {x:2089.3333,y:50.0000},
  {x:2089.0000,y:50.0000},
  {x:2089.0000,y:49.3334},
  {x:2089.0000,y:48.6666},
  {x:2089.0000,y:48.0000},
  {x:2088.6667,y:48.0000},
  {x:2088.3333,y:48.0000},
  {x:2088.0000,y:48.0000},
  {x:2088.0000,y:47.3334},
  {x:2088.0000,y:46.6666},
  {x:2088.0000,y:46.0000},
  {x:2087.3334,y:45.6667},
  {x:2086.6666,y:45.3333},
  {x:2086.0000,y:45.0000},
  {x:2085.6667,y:43.6668},
  {x:2085.3333,y:42.3332},
  {x:2085.0000,y:41.0000},
  {x:2084.6667,y:41.0000},
  {x:2084.3333,y:41.0000},
  {x:2084.0000,y:41.0000},
  {x:2084.0000,y:40.3334},
  {x:2084.0000,y:39.6666},
  {x:2084.0000,y:39.0000},
  {x:2083.6667,y:39.0000},
  {x:2083.3333,y:39.0000},
  {x:2083.0000,y:39.0000},
  {x:2083.0000,y:38.3334},
  {x:2083.0000,y:37.6666},
  {x:2083.0000,y:37.0000},
  {x:2082.6667,y:37.0000},
  {x:2082.3333,y:37.0000},
  {x:2082.0000,y:37.0000},
  {x:2082.0000,y:36.3334},
  {x:2082.0000,y:35.6666},
  {x:2082.0000,y:35.0000},
  {x:2081.6667,y:35.0000},
  {x:2081.3333,y:35.0000},
  {x:2081.0000,y:35.0000},
  {x:2081.0000,y:34.3334},
  {x:2081.0000,y:33.6666},
  {x:2081.0000,y:33.0000},
  {x:2080.6667,y:33.0000},
  {x:2080.3333,y:33.0000},
  {x:2080.0000,y:33.0000},
  {x:2080.0000,y:32.3334},
  {x:2080.0000,y:31.6666},
  {x:2080.0000,y:31.0000},
  {x:2079.6667,y:31.0000},
  {x:2079.3333,y:31.0000},
  {x:2079.0000,y:31.0000},
  {x:2079.0000,y:30.3334},
  {x:2079.0000,y:29.6666},
  {x:2079.0000,y:29.0000},
  {x:2078.6667,y:29.0000},
  {x:2078.3333,y:29.0000},
  {x:2078.0000,y:29.0000},
  {x:2078.0000,y:28.3334},
  {x:2078.0000,y:27.6666},
  {x:2078.0000,y:27.0000},
  {x:2077.3334,y:26.6667},
  {x:2076.6666,y:26.3333},
  {x:2076.0000,y:26.0000},
  {x:2076.0000,y:25.3334},
  {x:2076.0000,y:24.6666},
  {x:2076.0000,y:24.0000},
  {x:2075.6667,y:24.0000},
  {x:2075.3333,y:24.0000},
  {x:2075.0000,y:24.0000},
  {x:2075.0000,y:23.3334},
  {x:2075.0000,y:22.6666},
  {x:2075.0000,y:22.0000},
  {x:2074.6667,y:22.0000},
  {x:2074.3333,y:22.0000},
  {x:2074.0000,y:22.0000},
  {x:2074.0000,y:21.3334},
  {x:2074.0000,y:20.6666},
  {x:2074.0000,y:20.0000},
  {x:2073.6667,y:20.0000},
  {x:2073.3333,y:20.0000},
  {x:2073.0000,y:20.0000},
  {x:2073.0000,y:19.3334},
  {x:2073.0000,y:18.6666},
  {x:2073.0000,y:18.0000},
  {x:2072.6667,y:18.0000},
  {x:2072.3333,y:18.0000},
  {x:2072.0000,y:18.0000},
  {x:2070.4236,y:15.0582},
  {x:2070.3754,y:12.0359},
  {x:2068.0000,y:10.0000},
  {x:2068.0000,y:10.3333},
  {x:2068.0000,y:10.6667},
  {x:2068.0000,y:11.0000},
  {x:2066.6668,y:11.6666},
  {x:2065.3332,y:12.3334},
  {x:2064.0000,y:13.0000},
  {x:2063.6667,y:13.6666},
  {x:2063.3333,y:14.3334},
  {x:2063.0000,y:15.0000},
  {x:2062.3334,y:15.0000},
  {x:2061.6666,y:15.0000},
  {x:2061.0000,y:15.0000},
  {x:2060.6667,y:15.6666},
  {x:2060.3333,y:16.3334},
  {x:2060.0000,y:17.0000},
  {x:2059.3334,y:17.0000},
  {x:2058.6666,y:17.0000},
  {x:2058.0000,y:17.0000},
  {x:2057.6667,y:17.6666},
  {x:2057.3333,y:18.3334},
  {x:2057.0000,y:19.0000},
  {x:2056.3334,y:19.0000},
  {x:2055.6666,y:19.0000},
  {x:2055.0000,y:19.0000},
  {x:2054.3334,y:19.9999},
  {x:2053.6666,y:21.0001},
  {x:2053.0000,y:22.0000},
  {x:2052.3334,y:22.0000},
  {x:2051.6666,y:22.0000},
  {x:2051.0000,y:22.0000},
  {x:2050.6667,y:22.6666},
  {x:2050.3333,y:23.3334},
  {x:2050.0000,y:24.0000},
  {x:2049.3334,y:24.0000},
  {x:2048.6666,y:24.0000},
  {x:2048.0000,y:24.0000},
  {x:2047.3334,y:24.9999},
  {x:2046.6666,y:26.0001},
  {x:2046.0000,y:27.0000},
  {x:2045.3334,y:27.0000},
  {x:2044.6666,y:27.0000},
  {x:2044.0000,y:27.0000},
  {x:2044.0000,y:27.3333},
  {x:2044.0000,y:27.6667},
  {x:2044.0000,y:28.0000},
  {x:2043.3334,y:28.0000},
  {x:2042.6666,y:28.0000},
  {x:2042.0000,y:28.0000},
  {x:2041.6667,y:28.6666},
  {x:2041.3333,y:29.3334},
  {x:2041.0000,y:30.0000},
  {x:2039.0002,y:30.6666},
  {x:2036.9998,y:31.3334},
  {x:2035.0000,y:32.0000},
  {x:2034.6667,y:32.6666},
  {x:2034.3333,y:33.3334},
  {x:2034.0000,y:34.0000},
  {x:2032.6668,y:34.3333},
  {x:2031.3332,y:34.6667},
  {x:2030.0000,y:35.0000},
  {x:2029.6667,y:35.6666},
  {x:2029.3333,y:36.3334},
  {x:2029.0000,y:37.0000},
  {x:2027.6668,y:37.3333},
  {x:2026.3332,y:37.6667},
  {x:2025.0000,y:38.0000},
  {x:2024.6667,y:38.6666},
  {x:2024.3333,y:39.3334},
  {x:2024.0000,y:40.0000},
  {x:2022.0002,y:40.6666},
  {x:2019.9998,y:41.3334},
  {x:2018.0000,y:42.0000},
  {x:2017.6667,y:42.6666},
  {x:2017.3333,y:43.3334},
  {x:2017.0000,y:44.0000},
  {x:2016.3334,y:44.0000},
  {x:2015.6666,y:44.0000},
  {x:2015.0000,y:44.0000},
  {x:2015.0000,y:44.3333},
  {x:2015.0000,y:44.6667},
  {x:2015.0000,y:45.0000},
  {x:2014.3334,y:45.0000},
  {x:2013.6666,y:45.0000},
  {x:2013.0000,y:45.0000},
  {x:2012.6667,y:45.6666},
  {x:2012.3333,y:46.3334},
  {x:2012.0000,y:47.0000},
  {x:2010.6668,y:47.3333},
  {x:2009.3332,y:47.6667},
  {x:2008.0000,y:48.0000},
  {x:2008.0000,y:48.3333},
  {x:2008.0000,y:48.6667},
  {x:2008.0000,y:49.0000},
  {x:2007.3334,y:49.0000},
  {x:2006.6666,y:49.0000},
  {x:2006.0000,y:49.0000},
  {x:2005.6667,y:49.6666},
  {x:2005.3333,y:50.3334},
  {x:2005.0000,y:51.0000},
  {x:2003.0002,y:51.6666},
  {x:2000.9998,y:52.3334},
  {x:1999.0000,y:53.0000},
  {x:1998.6667,y:53.6666},
  {x:1998.3333,y:54.3334},
  {x:1998.0000,y:55.0000},
  {x:1996.0002,y:55.6666},
  {x:1993.9998,y:56.3334},
  {x:1992.0000,y:57.0000},
  {x:1991.6667,y:57.6666},
  {x:1991.3333,y:58.3334},
  {x:1991.0000,y:59.0000},
  {x:1989.6668,y:59.3333},
  {x:1988.3332,y:59.6667},
  {x:1987.0000,y:60.0000},
  {x:1987.0000,y:60.3333},
  {x:1987.0000,y:60.6667},
  {x:1987.0000,y:61.0000},
  {x:1986.3334,y:61.0000},
  {x:1985.6666,y:61.0000},
  {x:1985.0000,y:61.0000},
  {x:1984.6667,y:61.6666},
  {x:1984.3333,y:62.3334},
  {x:1984.0000,y:63.0000},
  {x:1983.3334,y:63.0000},
  {x:1982.6666,y:63.0000},
  {x:1982.0000,y:63.0000},
  {x:1982.0000,y:63.3333},
  {x:1982.0000,y:63.6667},
  {x:1982.0000,y:64.0000},
  {x:1981.3334,y:64.0000},
  {x:1980.6666,y:64.0000},
  {x:1980.0000,y:64.0000},
  {x:1980.0000,y:64.3333},
  {x:1980.0000,y:64.6667},
  {x:1980.0000,y:65.0000},
  {x:1979.3334,y:65.0000},
  {x:1978.6666,y:65.0000},
  {x:1978.0000,y:65.0000},
  {x:1978.0000,y:65.3333},
  {x:1978.0000,y:65.6667},
  {x:1978.0000,y:66.0000},
  {x:1977.3334,y:66.0000},
  {x:1976.6666,y:66.0000},
  {x:1976.0000,y:66.0000},
  {x:1976.0000,y:66.3333},
  {x:1976.0000,y:66.6667},
  {x:1976.0000,y:67.0000},
  {x:1975.3334,y:67.0000},
  {x:1974.6666,y:67.0000},
  {x:1974.0000,y:67.0000},
  {x:1973.6667,y:67.6666},
  {x:1973.3333,y:68.3334},
  {x:1973.0000,y:69.0000},
  {x:1971.0002,y:69.6666},
  {x:1968.9998,y:70.3334},
  {x:1967.0000,y:71.0000},
  {x:1967.0000,y:71.3333},
  {x:1967.0000,y:71.6667},
  {x:1967.0000,y:72.0000},
  {x:1966.3334,y:72.0000},
  {x:1965.6666,y:72.0000},
  {x:1965.0000,y:72.0000},
  {x:1965.0000,y:72.3333},
  {x:1965.0000,y:72.6667},
  {x:1965.0000,y:73.0000},
  {x:1964.3334,y:73.0000},
  {x:1963.6666,y:73.0000},
  {x:1963.0000,y:73.0000},
  {x:1963.0000,y:73.3333},
  {x:1963.0000,y:73.6667},
  {x:1963.0000,y:74.0000},
  {x:1961.0002,y:74.6666},
  {x:1958.9998,y:75.3334},
  {x:1957.0000,y:76.0000},
  {x:1957.0000,y:76.3333},
  {x:1957.0000,y:76.6667},
  {x:1957.0000,y:77.0000},
  {x:1956.0001,y:77.0000},
  {x:1954.9999,y:77.0000},
  {x:1954.0000,y:77.0000},
  {x:1954.0000,y:77.3333},
  {x:1954.0000,y:77.6667},
  {x:1954.0000,y:78.0000},
  {x:1953.3334,y:78.0000},
  {x:1952.6666,y:78.0000},
  {x:1952.0000,y:78.0000},
  {x:1952.0000,y:78.3333},
  {x:1952.0000,y:78.6667},
  {x:1952.0000,y:79.0000},
  {x:1951.0001,y:79.0000},
  {x:1949.9999,y:79.0000},
  {x:1949.0000,y:79.0000},
  {x:1949.0000,y:79.3333},
  {x:1949.0000,y:79.6667},
  {x:1949.0000,y:80.0000},
  {x:1947.0002,y:80.6666},
  {x:1944.9998,y:81.3334},
  {x:1943.0000,y:82.0000},
  {x:1943.0000,y:82.3333},
  {x:1943.0000,y:82.6667},
  {x:1943.0000,y:83.0000},
  {x:1942.3334,y:83.0000},
  {x:1941.6666,y:83.0000},
  {x:1941.0000,y:83.0000},
  {x:1941.0000,y:83.3333},
  {x:1941.0000,y:83.6667},
  {x:1941.0000,y:84.0000},
  {x:1939.6668,y:84.3333},
  {x:1938.3332,y:84.6667},
  {x:1937.0000,y:85.0000},
  {x:1936.6667,y:85.6666},
  {x:1936.3333,y:86.3334},
  {x:1936.0000,y:87.0000},
  {x:1934.6668,y:87.3333},
  {x:1933.3332,y:87.6667},
  {x:1932.0000,y:88.0000},
  {x:1931.6667,y:88.6666},
  {x:1931.3333,y:89.3334},
  {x:1931.0000,y:90.0000},
  {x:1930.3334,y:90.0000},
  {x:1929.6666,y:90.0000},
  {x:1929.0000,y:90.0000},
  {x:1928.6667,y:90.6666},
  {x:1928.3333,y:91.3334},
  {x:1928.0000,y:92.0000},
  {x:1927.3334,y:92.0000},
  {x:1926.6666,y:92.0000},
  {x:1926.0000,y:92.0000},
  {x:1925.3334,y:92.9999},
  {x:1924.6666,y:94.0001},
  {x:1924.0000,y:95.0000},
  {x:1923.3334,y:95.0000},
  {x:1922.6666,y:95.0000},
  {x:1922.0000,y:95.0000},
  {x:1921.6667,y:95.6666},
  {x:1921.3333,y:96.3334},
  {x:1921.0000,y:97.0000},
  {x:1920.3334,y:97.0000},
  {x:1919.6666,y:97.0000},
  {x:1919.0000,y:97.0000},
  {x:1918.6667,y:97.6666},
  {x:1918.3333,y:98.3334},
  {x:1918.0000,y:99.0000},
  {x:1916.6668,y:99.3333},
  {x:1915.3332,y:99.6667},
  {x:1914.0000,y:100.0000},
  {x:1913.6667,y:100.6666},
  {x:1913.3333,y:101.3334},
  {x:1913.0000,y:102.0000},
  {x:1911.6668,y:102.3333},
  {x:1910.3332,y:102.6667},
  {x:1909.0000,y:103.0000},
  {x:1909.0000,y:103.3333},
  {x:1909.0000,y:103.6667},
  {x:1909.0000,y:104.0000},
  {x:1908.0001,y:104.3333},
  {x:1906.9999,y:104.6667},
  {x:1906.0000,y:105.0000},
  {x:1905.6667,y:105.6666},
  {x:1905.3333,y:106.3334},
  {x:1905.0000,y:107.0000},
  {x:1903.6668,y:107.3333},
  {x:1902.3332,y:107.6667},
  {x:1901.0000,y:108.0000},
  {x:1900.6667,y:108.6666},
  {x:1900.3333,y:109.3334},
  {x:1900.0000,y:110.0000},
  {x:1899.3334,y:110.0000},
  {x:1898.6666,y:110.0000},
  {x:1898.0000,y:110.0000},
  {x:1898.0000,y:110.3333},
  {x:1898.0000,y:110.6667},
  {x:1898.0000,y:111.0000},
  {x:1897.0001,y:111.3333},
  {x:1895.9999,y:111.6667},
  {x:1895.0000,y:112.0000},
  {x:1894.6667,y:112.6666},
  {x:1894.3333,y:113.3334},
  {x:1894.0000,y:114.0000},
  {x:1893.3334,y:114.0000},
  {x:1892.6666,y:114.0000},
  {x:1892.0000,y:114.0000},
  {x:1891.6667,y:114.6666},
  {x:1891.3333,y:115.3334},
  {x:1891.0000,y:116.0000},
  {x:1890.3334,y:116.0000},
  {x:1889.6666,y:116.0000},
  {x:1889.0000,y:116.0000},
  {x:1888.3334,y:116.9999},
  {x:1887.6666,y:118.0001},
  {x:1887.0000,y:119.0000},
  {x:1886.3334,y:119.0000},
  {x:1885.6666,y:119.0000},
  {x:1885.0000,y:119.0000},
  {x:1884.3334,y:119.9999},
  {x:1883.6666,y:121.0001},
  {x:1883.0000,y:122.0000},
  {x:1882.3334,y:122.0000},
  {x:1881.6666,y:122.0000},
  {x:1881.0000,y:122.0000},
  {x:1880.3334,y:122.9999},
  {x:1879.6666,y:124.0001},
  {x:1879.0000,y:125.0000},
  {x:1878.3334,y:125.0000},
  {x:1877.6666,y:125.0000},
  {x:1877.0000,y:125.0000},
  {x:1876.3334,y:125.9999},
  {x:1875.6666,y:127.0001},
  {x:1875.0000,y:128.0000},
  {x:1874.3334,y:128.0000},
  {x:1873.6666,y:128.0000},
  {x:1873.0000,y:128.0000},
  {x:1872.6667,y:128.6666},
  {x:1872.3333,y:129.3334},
  {x:1872.0000,y:130.0000},
  {x:1871.3334,y:130.0000},
  {x:1870.6666,y:130.0000},
  {x:1870.0000,y:130.0000},
  {x:1869.3334,y:130.9999},
  {x:1868.6666,y:132.0001},
  {x:1868.0000,y:133.0000},
  {x:1867.3334,y:133.0000},
  {x:1866.6666,y:133.0000},
  {x:1866.0000,y:133.0000},
  {x:1865.3334,y:133.9999},
  {x:1864.6666,y:135.0001},
  {x:1864.0000,y:136.0000},
  {x:1863.3334,y:136.0000},
  {x:1862.6666,y:136.0000},
  {x:1862.0000,y:136.0000},
  {x:1861.3334,y:136.9999},
  {x:1860.6666,y:138.0001},
  {x:1860.0000,y:139.0000},
  {x:1859.3334,y:139.0000},
  {x:1858.6666,y:139.0000},
  {x:1858.0000,y:139.0000},
  {x:1857.3334,y:139.9999},
  {x:1856.6666,y:141.0001},
  {x:1856.0000,y:142.0000},
  {x:1855.3334,y:142.0000},
  {x:1854.6666,y:142.0000},
  {x:1854.0000,y:142.0000},
  {x:1853.6667,y:142.6666},
  {x:1853.3333,y:143.3334},
  {x:1853.0000,y:144.0000},
  {x:1852.3334,y:144.0000},
  {x:1851.6666,y:144.0000},
  {x:1851.0000,y:144.0000},
  {x:1850.3334,y:144.9999},
  {x:1849.6666,y:146.0001},
  {x:1849.0000,y:147.0000},
  {x:1848.3334,y:147.0000},
  {x:1847.6666,y:147.0000},
  {x:1847.0000,y:147.0000},
  {x:1846.6667,y:147.6666},
  {x:1846.3333,y:148.3334},
  {x:1846.0000,y:149.0000},
  {x:1845.3334,y:149.0000},
  {x:1844.6666,y:149.0000},
  {x:1844.0000,y:149.0000},
  {x:1843.3334,y:149.9999},
  {x:1842.6666,y:151.0001},
  {x:1842.0000,y:152.0000},
  {x:1841.3334,y:152.0000},
  {x:1840.6666,y:152.0000},
  {x:1840.0000,y:152.0000},
  {x:1839.6667,y:152.6666},
  {x:1839.3333,y:153.3334},
  {x:1839.0000,y:154.0000},
  {x:1838.3334,y:154.0000},
  {x:1837.6666,y:154.0000},
  {x:1837.0000,y:154.0000},
  {x:1836.3334,y:154.9999},
  {x:1835.6666,y:156.0001},
  {x:1835.0000,y:157.0000},
  {x:1834.3334,y:157.0000},
  {x:1833.6666,y:157.0000},
  {x:1833.0000,y:157.0000},
  {x:1832.6667,y:157.6666},
  {x:1832.3333,y:158.3334},
  {x:1832.0000,y:159.0000},
  {x:1831.3334,y:159.0000},
  {x:1830.6666,y:159.0000},
  {x:1830.0000,y:159.0000},
  {x:1829.3334,y:159.9999},
  {x:1828.6666,y:161.0001},
  {x:1828.0000,y:162.0000},
  {x:1827.3334,y:162.0000},
  {x:1826.6666,y:162.0000},
  {x:1826.0000,y:162.0000},
  {x:1826.0000,y:162.3333},
  {x:1826.0000,y:162.6667},
  {x:1826.0000,y:163.0000},
  {x:1825.0001,y:163.3333},
  {x:1823.9999,y:163.6667},
  {x:1823.0000,y:164.0000},
  {x:1822.6667,y:164.6666},
  {x:1822.3333,y:165.3334},
  {x:1822.0000,y:166.0000},
  {x:1821.3334,y:166.0000},
  {x:1820.6666,y:166.0000},
  {x:1820.0000,y:166.0000},
  {x:1819.3334,y:166.9999},
  {x:1818.6666,y:168.0001},
  {x:1818.0000,y:169.0000},
  {x:1817.3334,y:169.0000},
  {x:1816.6666,y:169.0000},
  {x:1816.0000,y:169.0000},
  {x:1815.6667,y:169.6666},
  {x:1815.3333,y:170.3334},
  {x:1815.0000,y:171.0000},
  {x:1814.0001,y:171.3333},
  {x:1812.9999,y:171.6667},
  {x:1812.0000,y:172.0000},
  {x:1812.0000,y:172.3333},
  {x:1812.0000,y:172.6667},
  {x:1812.0000,y:173.0000},
  {x:1811.3334,y:173.0000},
  {x:1810.6666,y:173.0000},
  {x:1810.0000,y:173.0000},
  {x:1809.6667,y:173.6666},
  {x:1809.3333,y:174.3334},
  {x:1809.0000,y:175.0000},
  {x:1808.3334,y:175.0000},
  {x:1807.6666,y:175.0000},
  {x:1807.0000,y:175.0000},
  {x:1806.6667,y:175.6666},
  {x:1806.3333,y:176.3334},
  {x:1806.0000,y:177.0000},
  {x:1805.3334,y:177.0000},
  {x:1804.6666,y:177.0000},
  {x:1804.0000,y:177.0000},
  {x:1803.6667,y:177.6666},
  {x:1803.3333,y:178.3334},
  {x:1803.0000,y:179.0000},
  {x:1802.3334,y:179.0000},
  {x:1801.6666,y:179.0000},
  {x:1801.0000,y:179.0000},
  {x:1800.6667,y:179.6666},
  {x:1800.3333,y:180.3334},
  {x:1800.0000,y:181.0000},
  {x:1799.3334,y:181.0000},
  {x:1798.6666,y:181.0000},
  {x:1798.0000,y:181.0000},
  {x:1797.6667,y:181.6666},
  {x:1797.3333,y:182.3334},
  {x:1797.0000,y:183.0000},
  {x:1796.3334,y:183.0000},
  {x:1795.6666,y:183.0000},
  {x:1795.0000,y:183.0000},
  {x:1794.6667,y:183.6666},
  {x:1794.3333,y:184.3334},
  {x:1794.0000,y:185.0000},
  {x:1793.3334,y:185.0000},
  {x:1792.6666,y:185.0000},
  {x:1792.0000,y:185.0000},
  {x:1791.6667,y:185.6666},
  {x:1791.3333,y:186.3334},
  {x:1791.0000,y:187.0000},
  {x:1789.6668,y:187.3333},
  {x:1788.3332,y:187.6667},
  {x:1787.0000,y:188.0000},
  {x:1786.6667,y:188.6666},
  {x:1786.3333,y:189.3334},
  {x:1786.0000,y:190.0000},
  {x:1785.3334,y:190.0000},
  {x:1784.6666,y:190.0000},
  {x:1784.0000,y:190.0000},
  {x:1784.0000,y:190.3333},
  {x:1784.0000,y:190.6667},
  {x:1784.0000,y:191.0000},
  {x:1783.3334,y:191.0000},
  {x:1782.6666,y:191.0000},
  {x:1782.0000,y:191.0000},
  {x:1781.6667,y:191.6666},
  {x:1781.3333,y:192.3334},
  {x:1781.0000,y:193.0000},
  {x:1780.3334,y:193.0000},
  {x:1779.6666,y:193.0000},
  {x:1779.0000,y:193.0000},
  {x:1779.0000,y:193.3333},
  {x:1779.0000,y:193.6667},
  {x:1779.0000,y:194.0000},
  {x:1778.3334,y:194.0000},
  {x:1777.6666,y:194.0000},
  {x:1777.0000,y:194.0000},
  {x:1777.0000,y:194.3333},
  {x:1777.0000,y:194.6667},
  {x:1777.0000,y:195.0000},
  {x:1776.3334,y:195.0000},
  {x:1775.6666,y:195.0000},
  {x:1775.0000,y:195.0000},
  {x:1775.0000,y:195.3333},
  {x:1775.0000,y:195.6667},
  {x:1775.0000,y:196.0000},
  {x:1774.3334,y:196.0000},
  {x:1773.6666,y:196.0000},
  {x:1773.0000,y:196.0000},
  {x:1772.6667,y:196.6666},
  {x:1772.3333,y:197.3334},
  {x:1772.0000,y:198.0000},
  {x:1771.3334,y:198.0000},
  {x:1770.6666,y:198.0000},
  {x:1770.0000,y:198.0000},
  {x:1770.0000,y:198.3333},
  {x:1770.0000,y:198.6667},
  {x:1770.0000,y:199.0000},
  {x:1768.3335,y:199.3333},
  {x:1766.6665,y:199.6667},
  {x:1765.0000,y:200.0000},
  {x:1765.0000,y:200.3333},
  {x:1765.0000,y:200.6667},
  {x:1765.0000,y:201.0000},
  {x:1763.6668,y:201.3333},
  {x:1762.3332,y:201.6667},
  {x:1761.0000,y:202.0000},
  {x:1761.0000,y:202.3333},
  {x:1761.0000,y:202.6667},
  {x:1761.0000,y:203.0000},
  {x:1760.3334,y:203.0000},
  {x:1759.6666,y:203.0000},
  {x:1759.0000,y:203.0000},
  {x:1759.0000,y:203.3333},
  {x:1759.0000,y:203.6667},
  {x:1759.0000,y:204.0000},
  {x:1758.3334,y:204.0000},
  {x:1757.6666,y:204.0000},
  {x:1757.0000,y:204.0000},
  {x:1757.0000,y:204.3333},
  {x:1757.0000,y:204.6667},
  {x:1757.0000,y:205.0000},
  {x:1756.3334,y:205.0000},
  {x:1755.6666,y:205.0000},
  {x:1755.0000,y:205.0000},
  {x:1755.0000,y:205.3333},
  {x:1755.0000,y:205.6667},
  {x:1755.0000,y:206.0000},
  {x:1754.3334,y:206.0000},
  {x:1753.6666,y:206.0000},
  {x:1753.0000,y:206.0000},
  {x:1753.0000,y:206.3333},
  {x:1753.0000,y:206.6667},
  {x:1753.0000,y:207.0000},
  {x:1752.3334,y:207.0000},
  {x:1751.6666,y:207.0000},
  {x:1751.0000,y:207.0000},
  {x:1751.0000,y:207.3333},
  {x:1751.0000,y:207.6667},
  {x:1751.0000,y:208.0000},
  {x:1749.6668,y:208.3333},
  {x:1748.3332,y:208.6667},
  {x:1747.0000,y:209.0000},
  {x:1747.0000,y:209.3333},
  {x:1747.0000,y:209.6667},
  {x:1747.0000,y:210.0000},
  {x:1746.3334,y:210.0000},
  {x:1745.6666,y:210.0000},
  {x:1745.0000,y:210.0000},
  {x:1745.0000,y:210.3333},
  {x:1745.0000,y:210.6667},
  {x:1745.0000,y:211.0000},
  {x:1744.0001,y:211.0000},
  {x:1742.9999,y:211.0000},
  {x:1742.0000,y:211.0000},
  {x:1742.0000,y:211.3333},
  {x:1742.0000,y:211.6667},
  {x:1742.0000,y:212.0000},
  {x:1740.6668,y:212.3333},
  {x:1739.3332,y:212.6667},
  {x:1738.0000,y:213.0000},
  {x:1738.0000,y:213.3333},
  {x:1738.0000,y:213.6667},
  {x:1738.0000,y:214.0000},
  {x:1737.3334,y:214.0000},
  {x:1736.6666,y:214.0000},
  {x:1736.0000,y:214.0000},
  {x:1736.0000,y:214.3333},
  {x:1736.0000,y:214.6667},
  {x:1736.0000,y:215.0000},
  {x:1735.3334,y:215.0000},
  {x:1734.6666,y:215.0000},
  {x:1734.0000,y:215.0000},
  {x:1734.0000,y:215.3333},
  {x:1734.0000,y:215.6667},
  {x:1734.0000,y:216.0000},
  {x:1732.0002,y:216.6666},
  {x:1729.9998,y:217.3334},
  {x:1728.0000,y:218.0000},
  {x:1728.0000,y:218.3333},
  {x:1728.0000,y:218.6667},
  {x:1728.0000,y:219.0000},
  {x:1727.0001,y:219.0000},
  {x:1725.9999,y:219.0000},
  {x:1725.0000,y:219.0000},
  {x:1725.0000,y:219.3333},
  {x:1725.0000,y:219.6667},
  {x:1725.0000,y:220.0000},
  {x:1724.3334,y:220.0000},
  {x:1723.6666,y:220.0000},
  {x:1723.0000,y:220.0000},
  {x:1723.0000,y:220.3333},
  {x:1723.0000,y:220.6667},
  {x:1723.0000,y:221.0000},
  {x:1722.3334,y:221.0000},
  {x:1721.6666,y:221.0000},
  {x:1721.0000,y:221.0000},
  {x:1721.0000,y:221.3333},
  {x:1721.0000,y:221.6667},
  {x:1721.0000,y:222.0000},
  {x:1719.0002,y:222.6666},
  {x:1716.9998,y:223.3334},
  {x:1715.0000,y:224.0000},
  {x:1715.0000,y:224.3333},
  {x:1715.0000,y:224.6667},
  {x:1715.0000,y:225.0000},
  {x:1712.0003,y:225.9999},
  {x:1708.9997,y:227.0001},
  {x:1706.0000,y:228.0000},
  {x:1706.0000,y:228.3333},
  {x:1706.0000,y:228.6667},
  {x:1706.0000,y:229.0000},
  {x:1705.3334,y:229.0000},
  {x:1704.6666,y:229.0000},
  {x:1704.0000,y:229.0000},
  {x:1704.0000,y:229.3333},
  {x:1704.0000,y:229.6667},
  {x:1704.0000,y:230.0000},
  {x:1703.0001,y:230.0000},
  {x:1701.9999,y:230.0000},
  {x:1701.0000,y:230.0000},
  {x:1701.0000,y:230.3333},
  {x:1701.0000,y:230.6667},
  {x:1701.0000,y:231.0000},
  {x:1699.6668,y:231.3333},
  {x:1698.3332,y:231.6667},
  {x:1697.0000,y:232.0000},
  {x:1697.0000,y:232.3333},
  {x:1697.0000,y:232.6667},
  {x:1697.0000,y:233.0000},
  {x:1696.0001,y:233.0000},
  {x:1694.9999,y:233.0000},
  {x:1694.0000,y:233.0000},
  {x:1694.0000,y:233.3333},
  {x:1694.0000,y:233.6667},
  {x:1694.0000,y:234.0000},
  {x:1693.3334,y:234.0000},
  {x:1692.6666,y:234.0000},
  {x:1692.0000,y:234.0000},
  {x:1692.0000,y:234.3333},
  {x:1692.0000,y:234.6667},
  {x:1692.0000,y:235.0000},
  {x:1690.3335,y:235.3333},
  {x:1688.6665,y:235.6667},
  {x:1687.0000,y:236.0000},
  {x:1687.0000,y:236.3333},
  {x:1687.0000,y:236.6667},
  {x:1687.0000,y:237.0000},
  {x:1686.0001,y:237.0000},
  {x:1684.9999,y:237.0000},
  {x:1684.0000,y:237.0000},
  {x:1684.0000,y:237.3333},
  {x:1684.0000,y:237.6667},
  {x:1684.0000,y:238.0000},
  {x:1678.6672,y:238.6666},
  {x:1673.3328,y:239.3334},
  {x:1668.0000,y:240.0000},
  {x:1654.3347,y:243.6663},
  {x:1640.6653,y:247.3337},
  {x:1627.0000,y:251.0000},
  {x:1627.0000,y:251.3333},
  {x:1627.0000,y:251.6667},
  {x:1627.0000,y:252.0000},
  {x:1626.3334,y:252.0000},
  {x:1625.6666,y:252.0000},
  {x:1625.0000,y:252.0000},
  {x:1625.0000,y:252.3333},
  {x:1625.0000,y:252.6667},
  {x:1625.0000,y:253.0000},
  {x:1624.0001,y:253.0000},
  {x:1622.9999,y:253.0000},
  {x:1622.0000,y:253.0000},
  {x:1622.0000,y:253.3333},
  {x:1622.0000,y:253.6667},
  {x:1622.0000,y:254.0000},
  {x:1621.3334,y:254.0000},
  {x:1620.6666,y:254.0000},
  {x:1620.0000,y:254.0000},
  {x:1620.0000,y:254.3333},
  {x:1620.0000,y:254.6667},
  {x:1620.0000,y:255.0000},
  {x:1619.0001,y:255.0000},
  {x:1617.9999,y:255.0000},
  {x:1617.0000,y:255.0000},
  {x:1617.0000,y:255.3333},
  {x:1617.0000,y:255.6667},
  {x:1617.0000,y:256.0000},
  {x:1616.3334,y:256.0000},
  {x:1615.6666,y:256.0000},
  {x:1615.0000,y:256.0000},
  {x:1615.0000,y:256.3333},
  {x:1615.0000,y:256.6667},
  {x:1615.0000,y:257.0000},
  {x:1614.0001,y:257.0000},
  {x:1612.9999,y:257.0000},
  {x:1612.0000,y:257.0000},
  {x:1612.0000,y:257.3333},
  {x:1612.0000,y:257.6667},
  {x:1612.0000,y:258.0000},
  {x:1610.0002,y:258.6666},
  {x:1607.9998,y:259.3334},
  {x:1606.0000,y:260.0000},
  {x:1606.0000,y:260.3333},
  {x:1606.0000,y:260.6667},
  {x:1606.0000,y:261.0000},
  {x:1605.3334,y:261.0000},
  {x:1604.6666,y:261.0000},
  {x:1604.0000,y:261.0000},
  {x:1604.0000,y:261.3333},
  {x:1604.0000,y:261.6667},
  {x:1604.0000,y:262.0000},
  {x:1602.0002,y:262.6666},
  {x:1599.9998,y:263.3334},
  {x:1598.0000,y:264.0000},
  {x:1597.6667,y:264.6666},
  {x:1597.3333,y:265.3334},
  {x:1597.0000,y:266.0000},
  {x:1596.3334,y:266.0000},
  {x:1595.6666,y:266.0000},
  {x:1595.0000,y:266.0000},
  {x:1595.0000,y:266.3333},
  {x:1595.0000,y:266.6667},
  {x:1595.0000,y:267.0000},
  {x:1594.0001,y:267.0000},
  {x:1592.9999,y:267.0000},
  {x:1592.0000,y:267.0000},
  {x:1592.0000,y:267.3333},
  {x:1592.0000,y:267.6667},
  {x:1592.0000,y:268.0000},
  {x:1591.3334,y:268.0000},
  {x:1590.6666,y:268.0000},
  {x:1590.0000,y:268.0000},
  {x:1590.0000,y:268.3333},
  {x:1590.0000,y:268.6667},
  {x:1590.0000,y:269.0000},
  {x:1589.3334,y:269.0000},
  {x:1588.6666,y:269.0000},
  {x:1588.0000,y:269.0000},
  {x:1588.0000,y:269.3333},
  {x:1588.0000,y:269.6667},
  {x:1588.0000,y:270.0000},
  {x:1586.0002,y:270.6666},
  {x:1583.9998,y:271.3334},
  {x:1582.0000,y:272.0000},
  {x:1582.0000,y:272.3333},
  {x:1582.0000,y:272.6667},
  {x:1582.0000,y:273.0000},
  {x:1581.0001,y:273.0000},
  {x:1579.9999,y:273.0000},
  {x:1579.0000,y:273.0000},
  {x:1579.0000,y:273.3333},
  {x:1579.0000,y:273.6667},
  {x:1579.0000,y:274.0000},
  {x:1577.6668,y:274.3333},
  {x:1576.3332,y:274.6667},
  {x:1575.0000,y:275.0000},
  {x:1575.0000,y:275.3333},
  {x:1575.0000,y:275.6667},
  {x:1575.0000,y:276.0000},
  {x:1574.0001,y:276.0000},
  {x:1572.9999,y:276.0000},
  {x:1572.0000,y:276.0000},
  {x:1572.0000,y:276.3333},
  {x:1572.0000,y:276.6667},
  {x:1572.0000,y:277.0000},
  {x:1571.3334,y:277.0000},
  {x:1570.6666,y:277.0000},
  {x:1570.0000,y:277.0000},
  {x:1570.0000,y:277.3333},
  {x:1570.0000,y:277.6667},
  {x:1570.0000,y:278.0000},
  {x:1567.0003,y:278.6666},
  {x:1563.9997,y:279.3334},
  {x:1561.0000,y:280.0000},
  {x:1561.0000,y:280.3333},
  {x:1561.0000,y:280.6667},
  {x:1561.0000,y:281.0000},
  {x:1560.3334,y:281.0000},
  {x:1559.6666,y:281.0000},
  {x:1559.0000,y:281.0000},
  {x:1559.0000,y:281.3333},
  {x:1559.0000,y:281.6667},
  {x:1559.0000,y:282.0000},
  {x:1558.0001,y:282.0000},
  {x:1556.9999,y:282.0000},
  {x:1556.0000,y:282.0000},
  {x:1556.0000,y:282.3333},
  {x:1556.0000,y:282.6667},
  {x:1556.0000,y:283.0000},
  {x:1555.3334,y:283.0000},
  {x:1554.6666,y:283.0000},
  {x:1554.0000,y:283.0000},
  {x:1553.6667,y:283.6666},
  {x:1553.3333,y:284.3334},
  {x:1553.0000,y:285.0000},
  {x:1551.6668,y:285.3333},
  {x:1550.3332,y:285.6667},
  {x:1549.0000,y:286.0000},
  {x:1548.3334,y:286.9999},
  {x:1547.6666,y:288.0001},
  {x:1547.0000,y:289.0000},
  {x:1546.3334,y:289.0000},
  {x:1545.6666,y:289.0000},
  {x:1545.0000,y:289.0000},
  {x:1544.3334,y:289.9999},
  {x:1543.6666,y:291.0001},
  {x:1543.0000,y:292.0000},
  {x:1542.3334,y:292.0000},
  {x:1541.6666,y:292.0000},
  {x:1541.0000,y:292.0000},
  {x:1540.0001,y:293.3332},
  {x:1538.9999,y:294.6668},
  {x:1538.0000,y:296.0000},
  {x:1537.3334,y:296.0000},
  {x:1536.6666,y:296.0000},
  {x:1536.0000,y:296.0000},
  {x:1535.0001,y:297.3332},
  {x:1533.9999,y:298.6668},
  {x:1533.0000,y:300.0000},
  {x:1532.3334,y:300.0000},
  {x:1531.6666,y:300.0000},
  {x:1531.0000,y:300.0000},
  {x:1530.0001,y:301.3332},
  {x:1528.9999,y:302.6668},
  {x:1528.0000,y:304.0000},
  {x:1527.3334,y:304.0000},
  {x:1526.6666,y:304.0000},
  {x:1526.0000,y:304.0000},
  {x:1525.0001,y:305.3332},
  {x:1523.9999,y:306.6668},
  {x:1523.0000,y:308.0000},
  {x:1522.3334,y:308.0000},
  {x:1521.6666,y:308.0000},
  {x:1521.0000,y:308.0000},
  {x:1520.6667,y:308.6666},
  {x:1520.3333,y:309.3334},
  {x:1520.0000,y:310.0000},
  {x:1519.0001,y:310.3333},
  {x:1517.9999,y:310.6667},
  {x:1517.0000,y:311.0000},
  {x:1517.0000,y:311.3333},
  {x:1517.0000,y:311.6667},
  {x:1517.0000,y:312.0000},
  {x:1516.0001,y:312.0000},
  {x:1514.9999,y:312.0000},
  {x:1514.0000,y:312.0000},
  {x:1514.0000,y:312.3333},
  {x:1514.0000,y:312.6667},
  {x:1514.0000,y:313.0000},
  {x:1513.3334,y:313.0000},
  {x:1512.6666,y:313.0000},
  {x:1512.0000,y:313.0000},
  {x:1512.0000,y:313.3333},
  {x:1512.0000,y:313.6667},
  {x:1512.0000,y:314.0000},
  {x:1510.3335,y:314.3333},
  {x:1508.6665,y:314.6667},
  {x:1507.0000,y:315.0000},
  {x:1506.6667,y:315.6666},
  {x:1506.3333,y:316.3334},
  {x:1506.0000,y:317.0000},
  {x:1505.3334,y:317.0000},
  {x:1504.6666,y:317.0000},
  {x:1504.0000,y:317.0000},
  {x:1502.6668,y:318.6665},
  {x:1501.3332,y:320.3335},
  {x:1500.0000,y:322.0000},
  {x:1499.0001,y:322.3333},
  {x:1497.9999,y:322.6667},
  {x:1497.0000,y:323.0000},
  {x:1495.3335,y:324.9998},
  {x:1493.6665,y:327.0002},
  {x:1492.0000,y:329.0000},
  {x:1491.0001,y:329.3333},
  {x:1489.9999,y:329.6667},
  {x:1489.0000,y:330.0000},
  {x:1488.6667,y:330.6666},
  {x:1488.3333,y:331.3334},
  {x:1488.0000,y:332.0000},
  {x:1487.3334,y:332.0000},
  {x:1486.6666,y:332.0000},
  {x:1486.0000,y:332.0000},
  {x:1485.6667,y:332.6666},
  {x:1485.3333,y:333.3334},
  {x:1485.0000,y:334.0000},
  {x:1483.6668,y:334.3333},
  {x:1482.3332,y:334.6667},
  {x:1481.0000,y:335.0000},
  {x:1481.0000,y:335.3333},
  {x:1481.0000,y:335.6667},
  {x:1481.0000,y:336.0000},
  {x:1480.0001,y:336.3333},
  {x:1478.9999,y:336.6667},
  {x:1478.0000,y:337.0000},
  {x:1477.6667,y:337.6666},
  {x:1477.3333,y:338.3334},
  {x:1477.0000,y:339.0000},
  {x:1475.6668,y:339.3333},
  {x:1474.3332,y:339.6667},
  {x:1473.0000,y:340.0000},
  {x:1472.6667,y:340.6666},
  {x:1472.3333,y:341.3334},
  {x:1472.0000,y:342.0000},
  {x:1470.6668,y:342.3333},
  {x:1469.3332,y:342.6667},
  {x:1468.0000,y:343.0000},
  {x:1467.6667,y:343.6666},
  {x:1467.3333,y:344.3334},
  {x:1467.0000,y:345.0000},
  {x:1465.6668,y:345.3333},
  {x:1464.3332,y:345.6667},
  {x:1463.0000,y:346.0000},
  {x:1462.6667,y:346.6666},
  {x:1462.3333,y:347.3334},
  {x:1462.0000,y:348.0000},
  {x:1461.3334,y:348.0000},
  {x:1460.6666,y:348.0000},
  {x:1460.0000,y:348.0000},
  {x:1460.0000,y:348.3333},
  {x:1460.0000,y:348.6667},
  {x:1460.0000,y:349.0000},
  {x:1459.3334,y:349.0000},
  {x:1458.6666,y:349.0000},
  {x:1458.0000,y:349.0000},
  {x:1458.0000,y:349.3333},
  {x:1458.0000,y:349.6667},
  {x:1458.0000,y:350.0000},
  {x:1457.3334,y:350.0000},
  {x:1456.6666,y:350.0000},
  {x:1456.0000,y:350.0000},
  {x:1456.0000,y:350.3333},
  {x:1456.0000,y:350.6667},
  {x:1456.0000,y:351.0000},
  {x:1455.3334,y:351.0000},
  {x:1454.6666,y:351.0000},
  {x:1454.0000,y:351.0000},
  {x:1454.0000,y:351.3333},
  {x:1454.0000,y:351.6667},
  {x:1454.0000,y:352.0000},
  {x:1453.3334,y:352.0000},
  {x:1452.6666,y:352.0000},
  {x:1452.0000,y:352.0000},
  {x:1452.0000,y:352.3333},
  {x:1452.0000,y:352.6667},
  {x:1452.0000,y:353.0000},
  {x:1451.3334,y:353.0000},
  {x:1450.6666,y:353.0000},
  {x:1450.0000,y:353.0000},
  {x:1450.0000,y:353.3333},
  {x:1450.0000,y:353.6667},
  {x:1450.0000,y:354.0000},
  {x:1449.3334,y:354.0000},
  {x:1448.6666,y:354.0000},
  {x:1448.0000,y:354.0000},
  {x:1448.0000,y:354.3333},
  {x:1448.0000,y:354.6667},
  {x:1448.0000,y:355.0000},
  {x:1447.3334,y:355.0000},
  {x:1446.6666,y:355.0000},
  {x:1446.0000,y:355.0000},
  {x:1446.0000,y:355.3333},
  {x:1446.0000,y:355.6667},
  {x:1446.0000,y:356.0000},
  {x:1445.3334,y:356.0000},
  {x:1444.6666,y:356.0000},
  {x:1444.0000,y:356.0000},
  {x:1444.0000,y:356.3333},
  {x:1444.0000,y:356.6667},
  {x:1444.0000,y:357.0000},
  {x:1442.6668,y:357.3333},
  {x:1441.3332,y:357.6667},
  {x:1440.0000,y:358.0000},
  {x:1440.0000,y:358.3333},
  {x:1440.0000,y:358.6667},
  {x:1440.0000,y:359.0000},
  {x:1439.3334,y:359.0000},
  {x:1438.6666,y:359.0000},
  {x:1438.0000,y:359.0000},
  {x:1438.0000,y:359.3333},
  {x:1438.0000,y:359.6667},
  {x:1438.0000,y:360.0000},
  {x:1437.0001,y:360.0000},
  {x:1435.9999,y:360.0000},
  {x:1435.0000,y:360.0000},
  {x:1435.0000,y:360.3333},
  {x:1435.0000,y:360.6667},
  {x:1435.0000,y:361.0000},
  {x:1434.3334,y:361.0000},
  {x:1433.6666,y:361.0000},
  {x:1433.0000,y:361.0000},
  {x:1433.0000,y:361.3333},
  {x:1433.0000,y:361.6667},
  {x:1433.0000,y:362.0000},
  {x:1432.0001,y:362.0000},
  {x:1430.9999,y:362.0000},
  {x:1430.0000,y:362.0000},
  {x:1430.0000,y:362.3333},
  {x:1430.0000,y:362.6667},
  {x:1430.0000,y:363.0000},
  {x:1429.3334,y:363.0000},
  {x:1428.6666,y:363.0000},
  {x:1428.0000,y:363.0000},
  {x:1428.0000,y:363.3333},
  {x:1428.0000,y:363.6667},
  {x:1428.0000,y:364.0000},
  {x:1426.0002,y:364.3333},
  {x:1423.9998,y:364.6667},
  {x:1422.0000,y:365.0000},
  {x:1422.0000,y:365.3333},
  {x:1422.0000,y:365.6667},
  {x:1422.0000,y:366.0000},
  {x:1421.0001,y:366.0000},
  {x:1419.9999,y:366.0000},
  {x:1419.0000,y:366.0000},
  {x:1419.0000,y:366.3333},
  {x:1419.0000,y:366.6667},
  {x:1419.0000,y:367.0000},
  {x:1418.3334,y:367.0000},
  {x:1417.6666,y:367.0000},
  {x:1417.0000,y:367.0000},
  {x:1417.0000,y:367.3333},
  {x:1417.0000,y:367.6667},
  {x:1417.0000,y:368.0000},
  {x:1416.0001,y:368.0000},
  {x:1414.9999,y:368.0000},
  {x:1414.0000,y:368.0000},
  {x:1414.0000,y:368.3333},
  {x:1414.0000,y:368.6667},
  {x:1414.0000,y:369.0000},
  {x:1413.0001,y:369.0000},
  {x:1411.9999,y:369.0000},
  {x:1411.0000,y:369.0000},
  {x:1411.0000,y:369.3333},
  {x:1411.0000,y:369.6667},
  {x:1411.0000,y:370.0000},
  {x:1409.3335,y:370.3333},
  {x:1407.6665,y:370.6667},
  {x:1406.0000,y:371.0000},
  {x:1406.0000,y:371.3333},
  {x:1406.0000,y:371.6667},
  {x:1406.0000,y:372.0000},
  {x:1405.3334,y:372.0000},
  {x:1404.6666,y:372.0000},
  {x:1404.0000,y:372.0000},
  {x:1404.0000,y:372.3333},
  {x:1404.0000,y:372.6667},
  {x:1404.0000,y:373.0000},
  {x:1402.3335,y:373.3333},
  {x:1400.6665,y:373.6667},
  {x:1399.0000,y:374.0000},
  {x:1399.0000,y:374.3333},
  {x:1399.0000,y:374.6667},
  {x:1399.0000,y:375.0000},
  {x:1398.3334,y:375.0000},
  {x:1397.6666,y:375.0000},
  {x:1397.0000,y:375.0000},
  {x:1397.0000,y:375.3333},
  {x:1397.0000,y:375.6667},
  {x:1397.0000,y:376.0000},
  {x:1395.3335,y:376.0000},
  {x:1393.6665,y:376.0000},
  {x:1392.0000,y:376.0000},
  {x:1392.0000,y:376.3333},
  {x:1392.0000,y:376.6667},
  {x:1392.0000,y:377.0000},
  {x:1390.6668,y:377.0000},
  {x:1389.3332,y:377.0000},
  {x:1388.0000,y:377.0000},
  {x:1388.0000,y:377.3333},
  {x:1388.0000,y:377.6667},
  {x:1388.0000,y:378.0000},
  {x:1386.3335,y:378.0000},
  {x:1384.6665,y:378.0000},
  {x:1383.0000,y:378.0000},
  {x:1374.3315,y:380.8169},
  {x:1363.6252,y:383.0686},
  {x:1355.0000,y:386.0000},
  {x:1352.3336,y:386.3333},
  {x:1349.6664,y:386.6667},
  {x:1347.0000,y:387.0000},
  {x:1347.0000,y:387.3333},
  {x:1347.0000,y:387.6667},
  {x:1347.0000,y:388.0000},
  {x:1345.6668,y:388.0000},
  {x:1344.3332,y:388.0000},
  {x:1343.0000,y:388.0000},
  {x:1343.0000,y:388.3333},
  {x:1343.0000,y:388.6667},
  {x:1343.0000,y:389.0000},
  {x:1342.0001,y:389.0000},
  {x:1340.9999,y:389.0000},
  {x:1340.0000,y:389.0000},
  {x:1340.0000,y:389.3333},
  {x:1340.0000,y:389.6667},
  {x:1340.0000,y:390.0000},
  {x:1338.6668,y:390.0000},
  {x:1337.3332,y:390.0000},
  {x:1336.0000,y:390.0000},
  {x:1336.0000,y:390.3333},
  {x:1336.0000,y:390.6667},
  {x:1336.0000,y:391.0000},
  {x:1334.6668,y:391.0000},
  {x:1333.3332,y:391.0000},
  {x:1332.0000,y:391.0000},
  {x:1332.0000,y:391.3333},
  {x:1332.0000,y:391.6667},
  {x:1332.0000,y:392.0000},
  {x:1330.6668,y:392.0000},
  {x:1329.3332,y:392.0000},
  {x:1328.0000,y:392.0000},
  {x:1328.0000,y:392.3333},
  {x:1328.0000,y:392.6667},
  {x:1328.0000,y:393.0000},
  {x:1326.6668,y:393.0000},
  {x:1325.3332,y:393.0000},
  {x:1324.0000,y:393.0000},
  {x:1324.0000,y:393.3333},
  {x:1324.0000,y:393.6667},
  {x:1324.0000,y:394.0000},
  {x:1321.6669,y:394.3333},
  {x:1319.3331,y:394.6667},
  {x:1317.0000,y:395.0000},
  {x:1317.0000,y:395.3333},
  {x:1317.0000,y:395.6667},
  {x:1317.0000,y:396.0000},
  {x:1313.0004,y:396.6666},
  {x:1308.9996,y:397.3334},
  {x:1305.0000,y:398.0000},
  {x:1305.0000,y:398.3333},
  {x:1305.0000,y:398.6667},
  {x:1305.0000,y:399.0000},
  {x:1304.0001,y:399.0000},
  {x:1302.9999,y:399.0000},
  {x:1302.0000,y:399.0000},
  {x:1302.0000,y:399.3333},
  {x:1302.0000,y:399.6667},
  {x:1302.0000,y:400.0000},
  {x:1298.3337,y:400.6666},
  {x:1294.6663,y:401.3334},
  {x:1291.0000,y:402.0000},
  {x:1291.0000,y:402.3333},
  {x:1291.0000,y:402.6667},
  {x:1291.0000,y:403.0000},
  {x:1287.3337,y:403.6666},
  {x:1283.6663,y:404.3334},
  {x:1280.0000,y:405.0000},
  {x:1280.0000,y:405.3333},
  {x:1280.0000,y:405.6667},
  {x:1280.0000,y:406.0000},
  {x:1278.6668,y:406.0000},
  {x:1277.3332,y:406.0000},
  {x:1276.0000,y:406.0000},
  {x:1252.3879,y:414.5333},
  {x:1226.2809,y:420.3022},
  {x:1204.0000,y:430.0000},
  {x:1203.0001,y:430.0000},
  {x:1201.9999,y:430.0000},
  {x:1201.0000,y:430.0000},
  {x:1201.0000,y:430.3333},
  {x:1201.0000,y:430.6667},
  {x:1201.0000,y:431.0000},
  {x:1200.0001,y:431.0000},
  {x:1198.9999,y:431.0000},
  {x:1198.0000,y:431.0000},
  {x:1198.0000,y:431.3333},
  {x:1198.0000,y:431.6667},
  {x:1198.0000,y:432.0000},
  {x:1197.0001,y:432.0000},
  {x:1195.9999,y:432.0000},
  {x:1195.0000,y:432.0000},
  {x:1195.0000,y:432.3333},
  {x:1195.0000,y:432.6667},
  {x:1195.0000,y:433.0000},
  {x:1194.0001,y:433.0000},
  {x:1192.9999,y:433.0000},
  {x:1192.0000,y:433.0000},
  {x:1192.0000,y:433.3333},
  {x:1192.0000,y:433.6667},
  {x:1192.0000,y:434.0000},
  {x:1191.3334,y:434.0000},
  {x:1190.6666,y:434.0000},
  {x:1190.0000,y:434.0000},
  {x:1190.0000,y:434.3333},
  {x:1190.0000,y:434.6667},
  {x:1190.0000,y:435.0000},
  {x:1188.0002,y:435.3333},
  {x:1185.9998,y:435.6667},
  {x:1184.0000,y:436.0000},
  {x:1184.0000,y:436.3333},
  {x:1184.0000,y:436.6667},
  {x:1184.0000,y:437.0000},
  {x:1183.3334,y:437.0000},
  {x:1182.6666,y:437.0000},
  {x:1182.0000,y:437.0000},
  {x:1182.0000,y:437.3333},
  {x:1182.0000,y:437.6667},
  {x:1182.0000,y:438.0000},
  {x:1181.0001,y:438.0000},
  {x:1179.9999,y:438.0000},
  {x:1179.0000,y:438.0000},
  {x:1179.0000,y:438.3333},
  {x:1179.0000,y:438.6667},
  {x:1179.0000,y:439.0000},
  {x:1178.0001,y:439.0000},
  {x:1176.9999,y:439.0000},
  {x:1176.0000,y:439.0000},
  {x:1176.0000,y:439.3333},
  {x:1176.0000,y:439.6667},
  {x:1176.0000,y:440.0000},
  {x:1175.3334,y:440.0000},
  {x:1174.6666,y:440.0000},
  {x:1174.0000,y:440.0000},
  {x:1174.0000,y:440.3333},
  {x:1174.0000,y:440.6667},
  {x:1174.0000,y:441.0000},
  {x:1173.0001,y:441.0000},
  {x:1171.9999,y:441.0000},
  {x:1171.0000,y:441.0000},
  {x:1171.0000,y:441.3333},
  {x:1171.0000,y:441.6667},
  {x:1171.0000,y:442.0000},
  {x:1170.3334,y:442.0000},
  {x:1169.6666,y:442.0000},
  {x:1169.0000,y:442.0000},
  {x:1169.0000,y:442.3333},
  {x:1169.0000,y:442.6667},
  {x:1169.0000,y:443.0000},
  {x:1168.0001,y:443.0000},
  {x:1166.9999,y:443.0000},
  {x:1166.0000,y:443.0000},
  {x:1166.0000,y:443.3333},
  {x:1166.0000,y:443.6667},
  {x:1166.0000,y:444.0000},
  {x:1165.3334,y:444.0000},
  {x:1164.6666,y:444.0000},
  {x:1164.0000,y:444.0000},
  {x:1164.0000,y:444.3333},
  {x:1164.0000,y:444.6667},
  {x:1164.0000,y:445.0000},
  {x:1163.0001,y:445.0000},
  {x:1161.9999,y:445.0000},
  {x:1161.0000,y:445.0000},
  {x:1161.0000,y:445.3333},
  {x:1161.0000,y:445.6667},
  {x:1161.0000,y:446.0000},
  {x:1159.3335,y:446.3333},
  {x:1157.6665,y:446.6667},
  {x:1156.0000,y:447.0000},
  {x:1156.0000,y:447.3333},
  {x:1156.0000,y:447.6667},
  {x:1156.0000,y:448.0000},
  {x:1155.3334,y:448.0000},
  {x:1154.6666,y:448.0000},
  {x:1154.0000,y:448.0000},
  {x:1154.0000,y:448.3333},
  {x:1154.0000,y:448.6667},
  {x:1154.0000,y:449.0000},
  {x:1153.0001,y:449.0000},
  {x:1151.9999,y:449.0000},
  {x:1151.0000,y:449.0000},
  {x:1151.0000,y:449.3333},
  {x:1151.0000,y:449.6667},
  {x:1151.0000,y:450.0000},
  {x:1149.6668,y:450.3333},
  {x:1148.3332,y:450.6667},
  {x:1147.0000,y:451.0000},
  {x:1147.0000,y:451.3333},
  {x:1147.0000,y:451.6667},
  {x:1147.0000,y:452.0000},
  {x:1146.0001,y:452.0000},
  {x:1144.9999,y:452.0000},
  {x:1144.0000,y:452.0000},
  {x:1144.0000,y:452.3333},
  {x:1144.0000,y:452.6667},
  {x:1144.0000,y:453.0000},
  {x:1143.3334,y:453.0000},
  {x:1142.6666,y:453.0000},
  {x:1142.0000,y:453.0000},
  {x:1142.0000,y:453.3333},
  {x:1142.0000,y:453.6667},
  {x:1142.0000,y:454.0000},
  {x:1141.3334,y:454.0000},
  {x:1140.6666,y:454.0000},
  {x:1140.0000,y:454.0000},
  {x:1138.6668,y:455.6665},
  {x:1137.3332,y:457.3335},
  {x:1136.0000,y:459.0000},
  {x:1135.3334,y:459.0000},
  {x:1134.6666,y:459.0000},
  {x:1134.0000,y:459.0000},
  {x:1133.3334,y:459.9999},
  {x:1132.6666,y:461.0001},
  {x:1132.0000,y:462.0000},
  {x:1132.6666,y:462.0000},
  {x:1133.3334,y:462.0000},
  {x:1134.0000,y:462.0000},
  {x:1135.2646,y:460.4273},
  {x:1136.6603,y:459.5733},
  {x:1139.0000,y:459.0000},
  {x:1139.9999,y:467.3325},
  {x:1141.0001,y:475.6675},
  {x:1142.0000,y:484.0000},
  {x:1142.3333,y:484.0000},
  {x:1142.6667,y:484.0000},
  {x:1143.0000,y:484.0000},
  {x:1143.0000,y:486.9997},
  {x:1143.0000,y:490.0003},
  {x:1143.0000,y:493.0000},
  {x:1143.3333,y:493.0000},
  {x:1143.6667,y:493.0000},
  {x:1144.0000,y:493.0000},
  {x:1144.0000,y:495.9997},
  {x:1144.0000,y:499.0003},
  {x:1144.0000,y:502.0000},
  {x:1144.3333,y:502.0000},
  {x:1144.6667,y:502.0000},
  {x:1145.0000,y:502.0000},
  {x:1145.3333,y:512.3323},
  {x:1145.6667,y:522.6677},
  {x:1146.0000,y:533.0000},
  {x:1146.3333,y:533.0000},
  {x:1146.6667,y:533.0000},
  {x:1147.0000,y:533.0000},
  {x:1148.6298,y:538.8185},
  {x:1146.7557,y:552.4871},
  {x:1146.0000,y:557.0000},
  {x:1145.6667,y:564.6659},
  {x:1145.3333,y:572.3341},
  {x:1145.0000,y:580.0000},
  {x:1144.6667,y:580.0000},
  {x:1144.3333,y:580.0000},
  {x:1144.0000,y:580.0000},
  {x:1144.0000,y:581.9998},
  {x:1144.0000,y:584.0002},
  {x:1144.0000,y:586.0000},
  {x:1143.6667,y:586.0000},
  {x:1143.3333,y:586.0000},
  {x:1143.0000,y:586.0000},
  {x:1143.0000,y:587.6665},
  {x:1143.0000,y:589.3335},
  {x:1143.0000,y:591.0000},
  {x:1142.6667,y:591.0000},
  {x:1142.3333,y:591.0000},
  {x:1142.0000,y:591.0000},
  {x:1142.0000,y:592.3332},
  {x:1142.0000,y:593.6668},
  {x:1142.0000,y:595.0000},
  {x:1141.6667,y:595.0000},
  {x:1141.3333,y:595.0000},
  {x:1141.0000,y:595.0000},
  {x:1141.0000,y:596.3332},
  {x:1141.0000,y:597.6668},
  {x:1141.0000,y:599.0000},
  {x:1140.6667,y:599.0000},
  {x:1140.3333,y:599.0000},
  {x:1140.0000,y:599.0000},
  {x:1140.0000,y:599.9999},
  {x:1140.0000,y:601.0001},
  {x:1140.0000,y:602.0000},
  {x:1139.6667,y:602.0000},
  {x:1139.3333,y:602.0000},
  {x:1139.0000,y:602.0000},
  {x:1139.0000,y:602.9999},
  {x:1139.0000,y:604.0001},
  {x:1139.0000,y:605.0000},
  {x:1138.6667,y:605.0000},
  {x:1138.3333,y:605.0000},
  {x:1138.0000,y:605.0000},
  {x:1138.0000,y:605.9999},
  {x:1138.0000,y:607.0001},
  {x:1138.0000,y:608.0000},
  {x:1137.6667,y:608.0000},
  {x:1137.3333,y:608.0000},
  {x:1137.0000,y:608.0000},
  {x:1136.3334,y:609.9998},
  {x:1135.6666,y:612.0002},
  {x:1135.0000,y:614.0000},
  {x:1134.6667,y:614.0000},
  {x:1134.3333,y:614.0000},
  {x:1134.0000,y:614.0000},
  {x:1134.0000,y:614.6666},
  {x:1134.0000,y:615.3334},
  {x:1134.0000,y:616.0000},
  {x:1133.6667,y:616.0000},
  {x:1133.3333,y:616.0000},
  {x:1133.0000,y:616.0000},
  {x:1132.6667,y:617.3332},
  {x:1132.3333,y:618.6668},
  {x:1132.0000,y:620.0000},
  {x:1131.0001,y:620.6666},
  {x:1129.9999,y:621.3334},
  {x:1129.0000,y:622.0000},
  {x:1129.0000,y:622.6666},
  {x:1129.0000,y:623.3334},
  {x:1129.0000,y:624.0000},
  {x:1127.6668,y:624.9999},
  {x:1126.3332,y:626.0001},
  {x:1125.0000,y:627.0000},
  {x:1124.6667,y:627.6666},
  {x:1124.3333,y:628.3334},
  {x:1124.0000,y:629.0000},
  {x:1123.3334,y:629.0000},
  {x:1122.6666,y:629.0000},
  {x:1122.0000,y:629.0000},
  {x:1121.3334,y:629.9999},
  {x:1120.6666,y:631.0001},
  {x:1120.0000,y:632.0000},
  {x:1119.3334,y:632.0000},
  {x:1118.6666,y:632.0000},
  {x:1118.0000,y:632.0000},
  {x:1117.3334,y:632.9999},
  {x:1116.6666,y:634.0001},
  {x:1116.0000,y:635.0000},
  {x:1115.3334,y:635.0000},
  {x:1114.6666,y:635.0000},
  {x:1114.0000,y:635.0000},
  {x:1113.3334,y:635.9999},
  {x:1112.6666,y:637.0001},
  {x:1112.0000,y:638.0000},
  {x:1111.3334,y:638.0000},
  {x:1110.6666,y:638.0000},
  {x:1110.0000,y:638.0000},
  {x:1109.6667,y:638.6666},
  {x:1109.3333,y:639.3334},
  {x:1109.0000,y:640.0000},
  {x:1107.6668,y:640.3333},
  {x:1106.3332,y:640.6667},
  {x:1105.0000,y:641.0000},
  {x:1104.6667,y:641.6666},
  {x:1104.3333,y:642.3334},
  {x:1104.0000,y:643.0000},
  {x:1102.6668,y:643.3333},
  {x:1101.3332,y:643.6667},
  {x:1100.0000,y:644.0000},
  {x:1099.6667,y:644.6666},
  {x:1099.3333,y:645.3334},
  {x:1099.0000,y:646.0000},
  {x:1097.6668,y:646.3333},
  {x:1096.3332,y:646.6667},
  {x:1095.0000,y:647.0000},
  {x:1095.0000,y:647.3333},
  {x:1095.0000,y:647.6667},
  {x:1095.0000,y:648.0000},
  {x:1093.6668,y:648.3333},
  {x:1092.3332,y:648.6667},
  {x:1091.0000,y:649.0000},
  {x:1091.0000,y:649.3333},
  {x:1091.0000,y:649.6667},
  {x:1091.0000,y:650.0000},
  {x:1090.0001,y:650.0000},
  {x:1088.9999,y:650.0000},
  {x:1088.0000,y:650.0000},
  {x:1088.0000,y:650.3333},
  {x:1088.0000,y:650.6667},
  {x:1088.0000,y:651.0000},
  {x:1086.6668,y:651.3333},
  {x:1085.3332,y:651.6667},
  {x:1084.0000,y:652.0000},
  {x:1084.0000,y:652.3333},
  {x:1084.0000,y:652.6667},
  {x:1084.0000,y:653.0000},
  {x:1082.3335,y:653.3333},
  {x:1080.6665,y:653.6667},
  {x:1079.0000,y:654.0000},
  {x:1079.0000,y:654.3333},
  {x:1079.0000,y:654.6667},
  {x:1079.0000,y:655.0000},
  {x:1065.0014,y:658.9996},
  {x:1050.9986,y:663.0004},
  {x:1037.0000,y:667.0000},
  {x:1033.3337,y:667.3333},
  {x:1029.6663,y:667.6667},
  {x:1026.0000,y:668.0000},
  {x:1026.0000,y:668.3333},
  {x:1026.0000,y:668.6667},
  {x:1026.0000,y:669.0000},
  {x:1024.3335,y:669.0000},
  {x:1022.6665,y:669.0000},
  {x:1021.0000,y:669.0000},
  {x:1021.0000,y:669.3333},
  {x:1021.0000,y:669.6667},
  {x:1021.0000,y:670.0000},
  {x:1019.0002,y:670.0000},
  {x:1016.9998,y:670.0000},
  {x:1015.0000,y:670.0000},
  {x:1015.0000,y:670.3333},
  {x:1015.0000,y:670.6667},
  {x:1015.0000,y:671.0000},
  {x:1013.0002,y:671.0000},
  {x:1010.9998,y:671.0000},
  {x:1009.0000,y:671.0000},
  {x:1009.0000,y:671.3333},
  {x:1009.0000,y:671.6667},
  {x:1009.0000,y:672.0000},
  {x:1006.6669,y:672.0000},
  {x:1004.3331,y:672.0000},
  {x:1002.0000,y:672.0000},
  {x:1002.0000,y:672.3333},
  {x:1002.0000,y:672.6667},
  {x:1002.0000,y:673.0000},
  {x:999.6669,y:673.0000},
  {x:997.3331,y:673.0000},
  {x:995.0000,y:673.0000},
  {x:995.0000,y:673.3333},
  {x:995.0000,y:673.6667},
  {x:995.0000,y:674.0000},
  {x:992.3336,y:674.0000},
  {x:989.6664,y:674.0000},
  {x:987.0000,y:674.0000},
  {x:987.0000,y:674.3333},
  {x:987.0000,y:674.6667},
  {x:987.0000,y:675.0000},
  {x:984.3336,y:675.0000},
  {x:981.6664,y:675.0000},
  {x:979.0000,y:675.0000},
  {x:979.0000,y:675.3333},
  {x:979.0000,y:675.6667},
  {x:979.0000,y:676.0000},
  {x:976.0003,y:676.0000},
  {x:972.9997,y:676.0000},
  {x:970.0000,y:676.0000},
  {x:970.0000,y:676.3333},
  {x:970.0000,y:676.6667},
  {x:970.0000,y:677.0000},
  {x:967.0003,y:677.0000},
  {x:963.9997,y:677.0000},
  {x:961.0000,y:677.0000},
  {x:961.0000,y:677.3333},
  {x:961.0000,y:677.6667},
  {x:961.0000,y:678.0000},
  {x:957.3337,y:678.0000},
  {x:953.6663,y:678.0000},
  {x:950.0000,y:678.0000},
  {x:950.0000,y:678.3333},
  {x:950.0000,y:678.6667},
  {x:950.0000,y:679.0000},
  {x:946.6670,y:679.0000},
  {x:943.3330,y:679.0000},
  {x:940.0000,y:679.0000},
  {x:940.0000,y:679.3333},
  {x:940.0000,y:679.6667},
  {x:940.0000,y:680.0000},
  {x:918.3355,y:681.3332},
  {x:896.6645,y:682.6668},
  {x:875.0000,y:684.0000},
  {x:875.0000,y:684.3333},
  {x:875.0000,y:684.6667},
  {x:875.0000,y:685.0000},
  {x:868.3340,y:685.3333},
  {x:861.6660,y:685.6667},
  {x:855.0000,y:686.0000},
  {x:851.3337,y:686.0000},
  {x:847.6663,y:686.0000},
  {x:844.0000,y:686.0000},
  {x:844.0000,y:686.3333},
  {x:844.0000,y:686.6667},
  {x:844.0000,y:687.0000},
  {x:838.3339,y:687.0000},
  {x:832.6661,y:687.0000},
  {x:827.0000,y:687.0000},
  {x:827.0000,y:687.3333},
  {x:827.0000,y:687.6667},
  {x:827.0000,y:688.0000},
  {x:821.3339,y:688.0000},
  {x:815.6661,y:688.0000},
  {x:810.0000,y:688.0000},
  {x:802.4963,y:690.0976},
  {x:790.2112,y:688.9990},
  {x:781.0000,y:689.0000},
  {x:775.0006,y:689.0000},
  {x:768.9994,y:689.0000},
  {x:763.0000,y:689.0000},
  {x:763.0000,y:689.3333},
  {x:763.0000,y:689.6667},
  {x:763.0000,y:690.0000},
  {x:751.6678,y:689.6667},
  {x:740.3322,y:689.3333},
  {x:729.0000,y:689.0000},
  {x:712.6683,y:689.0000},
  {x:696.3317,y:689.0000},
  {x:680.0000,y:689.0000},
  {x:680.0000,y:688.6667},
  {x:680.0000,y:688.3333},
  {x:680.0000,y:688.0000},
  {x:670.3343,y:688.0000},
  {x:660.6657,y:688.0000},
  {x:651.0000,y:688.0000},
  {x:651.0000,y:687.6667},
  {x:651.0000,y:687.3333},
  {x:651.0000,y:687.0000},
  {x:637.0014,y:686.6667},
  {x:622.9986,y:686.3333},
  {x:609.0000,y:686.0000},
  {x:609.0000,y:685.6667},
  {x:609.0000,y:685.3333},
  {x:609.0000,y:685.0000},
  {x:601.6674,y:684.6667},
  {x:594.3326,y:684.3333},
  {x:587.0000,y:684.0000},
  {x:583.3337,y:684.0000},
  {x:579.6663,y:684.0000},
  {x:576.0000,y:684.0000},
  {x:576.0000,y:683.6667},
  {x:576.0000,y:683.3333},
  {x:576.0000,y:683.0000},
  {x:571.0005,y:683.0000},
  {x:565.9995,y:683.0000},
  {x:561.0000,y:683.0000},
  {x:561.0000,y:682.6667},
  {x:561.0000,y:682.3333},
  {x:561.0000,y:682.0000},
  {x:560.0001,y:682.0000},
  {x:558.9999,y:682.0000},
  {x:558.0000,y:682.0000},
  {x:554.6670,y:682.0000},
  {x:551.3330,y:682.0000},
  {x:548.0000,y:682.0000},
  {x:548.0000,y:681.6667},
  {x:548.0000,y:681.3333},
  {x:548.0000,y:681.0000},
  {x:543.6671,y:681.0000},
  {x:539.3329,y:681.0000},
  {x:535.0000,y:681.0000},
  {x:535.0000,y:680.6667},
  {x:535.0000,y:680.3333},
  {x:535.0000,y:680.0000},
  {x:531.0004,y:680.0000},
  {x:526.9996,y:680.0000},
  {x:523.0000,y:680.0000},
  {x:523.0000,y:679.6667},
  {x:523.0000,y:679.3333},
  {x:523.0000,y:679.0000},
  {x:519.3337,y:679.0000},
  {x:515.6663,y:679.0000},
  {x:512.0000,y:679.0000},
  {x:512.0000,y:678.6667},
  {x:512.0000,y:678.3333},
  {x:512.0000,y:678.0000},
  {x:508.0004,y:678.0000},
  {x:503.9996,y:678.0000},
  {x:500.0000,y:678.0000},
  {x:500.0000,y:677.6667},
  {x:500.0000,y:677.3333},
  {x:500.0000,y:677.0000},
  {x:496.6670,y:677.0000},
  {x:493.3330,y:677.0000},
  {x:490.0000,y:677.0000},
  {x:490.0000,y:676.6667},
  {x:490.0000,y:676.3333},
  {x:490.0000,y:676.0000},
  {x:486.6670,y:676.0000},
  {x:483.3330,y:676.0000},
  {x:480.0000,y:676.0000},
  {x:480.0000,y:675.6667},
  {x:480.0000,y:675.3333},
  {x:480.0000,y:675.0000},
  {x:473.3340,y:674.6667},
  {x:466.6660,y:674.3333},
  {x:460.0000,y:674.0000},
  {x:460.0000,y:673.6667},
  {x:460.0000,y:673.3333},
  {x:460.0000,y:673.0000},
  {x:457.0003,y:673.0000},
  {x:453.9997,y:673.0000},
  {x:451.0000,y:673.0000},
  {x:451.0000,y:672.6667},
  {x:451.0000,y:672.3333},
  {x:451.0000,y:672.0000},
  {x:448.0003,y:672.0000},
  {x:444.9997,y:672.0000},
  {x:442.0000,y:672.0000},
  {x:442.0000,y:671.6667},
  {x:442.0000,y:671.3333},
  {x:442.0000,y:671.0000},
  {x:439.3336,y:671.0000},
  {x:436.6664,y:671.0000},
  {x:434.0000,y:671.0000},
  {x:434.0000,y:670.6667},
  {x:434.0000,y:670.3333},
  {x:434.0000,y:670.0000},
  {x:431.0003,y:670.0000},
  {x:427.9997,y:670.0000},
  {x:425.0000,y:670.0000},
  {x:425.0000,y:669.6667},
  {x:425.0000,y:669.3333},
  {x:425.0000,y:669.0000},
  {x:422.3336,y:669.0000},
  {x:419.6664,y:669.0000},
  {x:417.0000,y:669.0000},
  {x:417.0000,y:668.6667},
  {x:417.0000,y:668.3333},
  {x:417.0000,y:668.0000},
  {x:414.3336,y:668.0000},
  {x:411.6664,y:668.0000},
  {x:409.0000,y:668.0000},
  {x:409.0000,y:667.6667},
  {x:409.0000,y:667.3333},
  {x:409.0000,y:667.0000},
  {x:406.3336,y:667.0000},
  {x:403.6664,y:667.0000},
  {x:401.0000,y:667.0000},
  {x:388.1596,y:663.2585},
  {x:371.7079,y:663.7032},
  {x:359.0000,y:660.0000},
  {x:350.3342,y:659.0001},
  {x:341.6658,y:657.9999},
  {x:333.0000,y:657.0000},
  {x:288.6711,y:649.3341},
  {x:244.3289,y:641.6659},
  {x:200.0000,y:634.0000},
  {x:200.0000,y:633.6667},
  {x:200.0000,y:633.3333},
  {x:200.0000,y:633.0000},
  {x:197.3336,y:632.6667},
  {x:194.6664,y:632.3333},
  {x:192.0000,y:632.0000},
  {x:192.0000,y:631.6667},
  {x:192.0000,y:631.3333},
  {x:192.0000,y:631.0000},
  {x:189.3336,y:630.6667},
  {x:186.6664,y:630.3333},
  {x:184.0000,y:630.0000},
  {x:184.0000,y:629.6667},
  {x:184.0000,y:629.3333},
  {x:184.0000,y:629.0000},
  {x:183.0001,y:629.0000},
  {x:181.9999,y:629.0000},
  {x:181.0000,y:629.0000},
  {x:181.0000,y:628.6667},
  {x:181.0000,y:628.3333},
  {x:181.0000,y:628.0000},
  {x:180.0001,y:628.0000},
  {x:178.9999,y:628.0000},
  {x:178.0000,y:628.0000},
  {x:178.0000,y:627.6667},
  {x:178.0000,y:627.3333},
  {x:178.0000,y:627.0000},
  {x:176.6668,y:627.0000},
  {x:175.3332,y:627.0000},
  {x:174.0000,y:627.0000},
  {x:174.0000,y:626.6667},
  {x:174.0000,y:626.3333},
  {x:174.0000,y:626.0000},
  {x:172.3335,y:625.6667},
  {x:170.6665,y:625.3333},
  {x:169.0000,y:625.0000},
  {x:169.0000,y:624.6667},
  {x:169.0000,y:624.3333},
  {x:169.0000,y:624.0000},
  {x:168.3334,y:624.0000},
  {x:167.6666,y:624.0000},
  {x:167.0000,y:624.0000},
  {x:167.0000,y:623.6667},
  {x:167.0000,y:623.3333},
  {x:167.0000,y:623.0000},
  {x:166.0001,y:623.0000},
  {x:164.9999,y:623.0000},
  {x:164.0000,y:623.0000},
  {x:164.0000,y:622.6667},
  {x:164.0000,y:622.3333},
  {x:164.0000,y:622.0000},
  {x:162.6668,y:621.6667},
  {x:161.3332,y:621.3333},
  {x:160.0000,y:621.0000},
  {x:160.0000,y:620.6667},
  {x:160.0000,y:620.3333},
  {x:160.0000,y:620.0000},
  {x:159.3334,y:620.0000},
  {x:158.6666,y:620.0000},
  {x:158.0000,y:620.0000},
  {x:157.6667,y:619.3334},
  {x:157.3333,y:618.6666},
  {x:157.0000,y:618.0000},
  {x:156.3334,y:618.0000},
  {x:155.6666,y:618.0000},
  {x:155.0000,y:618.0000},
  {x:154.6667,y:617.3334},
  {x:154.3333,y:616.6666},
  {x:154.0000,y:616.0000},
  {x:153.3334,y:616.0000},
  {x:152.6666,y:616.0000},
  {x:152.0000,y:616.0000},
  {x:152.0000,y:615.6667},
  {x:152.0000,y:615.3333},
  {x:152.0000,y:615.0000},
  {x:150.2377,y:613.5591},
  {x:149.5898,y:612.7369},
  {x:147.0000,y:612.0000},
  {x:147.0000,y:610.6668},
  {x:147.0000,y:609.3332},
  {x:147.0000,y:608.0000},
  {x:147.9999,y:607.6667},
  {x:149.0001,y:607.3333},
  {x:150.0000,y:607.0000},
  {x:150.0000,y:606.6667},
  {x:150.0000,y:606.3333},
  {x:150.0000,y:606.0000},
  {x:148.6668,y:606.0000},
  {x:147.3332,y:606.0000},
  {x:146.0000,y:606.0000},
  {x:146.0000,y:606.3333},
  {x:146.0000,y:606.6667},
  {x:146.0000,y:607.0000},
  {x:144.6668,y:607.0000},
  {x:143.3332,y:607.0000},
  {x:142.0000,y:607.0000},
  {x:142.0000,y:607.3333},
  {x:142.0000,y:607.6667},
  {x:142.0000,y:608.0000},
  {x:140.0002,y:608.6666},
  {x:137.9998,y:609.3334},
  {x:136.0000,y:610.0000},
  {x:136.0000,y:610.3333},
  {x:136.0000,y:610.6667},
  {x:136.0000,y:611.0000},
  {x:134.6668,y:611.3333},
  {x:133.3332,y:611.6667},
  {x:132.0000,y:612.0000},
  {x:131.6667,y:612.6666},
  {x:131.3333,y:613.3334},
  {x:131.0000,y:614.0000},
  {x:130.3334,y:614.0000},
  {x:129.6666,y:614.0000},
  {x:129.0000,y:614.0000},
  {x:129.0000,y:614.3333},
  {x:129.0000,y:614.6667},
  {x:129.0000,y:615.0000},
  {x:128.0001,y:615.3333},
  {x:126.9999,y:615.6667},
  {x:126.0000,y:616.0000},
  {x:125.6667,y:616.6666},
  {x:125.3333,y:617.3334},
  {x:125.0000,y:618.0000},
  {x:124.3334,y:618.0000},
  {x:123.6666,y:618.0000},
  {x:123.0000,y:618.0000},
  {x:122.6667,y:618.6666},
  {x:122.3333,y:619.3334},
  {x:122.0000,y:620.0000},
  {x:121.3334,y:620.0000},
  {x:120.6666,y:620.0000},
  {x:120.0000,y:620.0000},
  {x:119.3334,y:620.9999},
  {x:118.6666,y:622.0001},
  {x:118.0000,y:623.0000},
  {x:117.3334,y:623.0000},
  {x:116.6666,y:623.0000},
  {x:116.0000,y:623.0000},
  {x:115.3334,y:623.9999},
  {x:114.6666,y:625.0001},
  {x:114.0000,y:626.0000},
  {x:113.3334,y:626.0000},
  {x:112.6666,y:626.0000},
  {x:112.0000,y:626.0000},
  {x:111.3334,y:626.9999},
  {x:110.6666,y:628.0001},
  {x:110.0000,y:629.0000},
  {x:109.3334,y:629.0000},
  {x:108.6666,y:629.0000},
  {x:108.0000,y:629.0000},
  {x:107.6667,y:629.6666},
  {x:107.3333,y:630.3334},
  {x:107.0000,y:631.0000},
  {x:106.0001,y:631.3333},
  {x:104.9999,y:631.6667},
  {x:104.0000,y:632.0000},
  {x:104.0000,y:632.3333},
  {x:104.0000,y:632.6667},
  {x:104.0000,y:633.0000},
  {x:102.6668,y:633.3333},
  {x:101.3332,y:633.6667},
  {x:100.0000,y:634.0000},
  {x:99.3334,y:634.9999},
  {x:98.6666,y:636.0001},
  {x:98.0000,y:637.0000},
  {x:97.3334,y:637.0000},
  {x:96.6666,y:637.0000},
  {x:96.0000,y:637.0000},
  {x:95.6667,y:637.6666},
  {x:95.3333,y:638.3334},
  {x:95.0000,y:639.0000},
  {x:94.3334,y:639.0000},
  {x:93.6666,y:639.0000},
  {x:93.0000,y:639.0000},
  {x:92.3334,y:639.9999},
  {x:91.6666,y:641.0001},
  {x:91.0000,y:642.0000},
  {x:90.3334,y:642.0000},
  {x:89.6666,y:642.0000},
  {x:89.0000,y:642.0000},
  {x:88.0001,y:643.3332},
  {x:86.9999,y:644.6668},
  {x:86.0000,y:646.0000},
  {x:85.3334,y:646.0000},
  {x:84.6666,y:646.0000},
  {x:84.0000,y:646.0000},
  {x:81.6669,y:648.6664},
  {x:79.3331,y:651.3336},
  {x:77.0000,y:654.0000},
  {x:74.3336,y:656.3331},
  {x:71.6664,y:658.6669},
  {x:69.0000,y:661.0000},
  {x:69.0000,y:661.6666},
  {x:69.0000,y:662.3334},
  {x:69.0000,y:663.0000},
  {x:67.6668,y:663.9999},
  {x:66.3332,y:665.0001},
  {x:65.0000,y:666.0000},
  {x:65.0000,y:666.6666},
  {x:65.0000,y:667.3334},
  {x:65.0000,y:668.0000},
  {x:64.0001,y:668.6666},
  {x:62.9999,y:669.3334},
  {x:62.0000,y:670.0000},
  {x:62.0000,y:670.6666},
  {x:62.0000,y:671.3334},
  {x:62.0000,y:672.0000},
  {x:61.3334,y:672.3333},
  {x:60.6666,y:672.6667},
  {x:60.0000,y:673.0000},
  {x:60.0000,y:673.6666},
  {x:60.0000,y:674.3334},
  {x:60.0000,y:675.0000},
  {x:59.3334,y:675.3333},
  {x:58.6666,y:675.6667},
  {x:58.0000,y:676.0000},
  {x:57.3334,y:677.9998},
  {x:56.6666,y:680.0002},
  {x:56.0000,y:682.0000},
  {x:55.3334,y:682.3333},
  {x:54.6666,y:682.6667},
  {x:54.0000,y:683.0000},
  {x:53.6667,y:684.3332},
  {x:53.3333,y:685.6668},
  {x:53.0000,y:687.0000},
  {x:52.6667,y:687.0000},
  {x:52.3333,y:687.0000},
  {x:52.0000,y:687.0000},
  {x:52.0000,y:687.6666},
  {x:52.0000,y:688.3334},
  {x:52.0000,y:689.0000},
  {x:51.6667,y:689.0000},
  {x:51.3333,y:689.0000},
  {x:51.0000,y:689.0000},
  {x:51.0000,y:689.6666},
  {x:51.0000,y:690.3334},
  {x:51.0000,y:691.0000},
  {x:50.6667,y:691.0000},
  {x:50.3333,y:691.0000},
  {x:50.0000,y:691.0000},
  {x:50.0000,y:691.6666},
  {x:50.0000,y:692.3334},
  {x:50.0000,y:693.0000},
  {x:49.6667,y:693.0000},
  {x:49.3333,y:693.0000},
  {x:49.0000,y:693.0000},
  {x:49.0000,y:693.6666},
  {x:49.0000,y:694.3334},
  {x:49.0000,y:695.0000},
  {x:48.6667,y:695.0000},
  {x:48.3333,y:695.0000},
  {x:48.0000,y:695.0000},
  {x:47.6667,y:696.3332},
  {x:47.3333,y:697.6668},
  {x:47.0000,y:699.0000},
  {x:46.6667,y:699.0000},
  {x:46.3333,y:699.0000},
  {x:46.0000,y:699.0000},
  {x:45.6667,y:700.3332},
  {x:45.3333,y:701.6668},
  {x:45.0000,y:703.0000},
  {x:44.6667,y:703.0000},
  {x:44.3333,y:703.0000},
  {x:44.0000,y:703.0000},
  {x:44.0000,y:703.6666},
  {x:44.0000,y:704.3334},
  {x:44.0000,y:705.0000},
  {x:43.6667,y:705.0000},
  {x:43.3333,y:705.0000},
  {x:43.0000,y:705.0000},
  {x:43.0000,y:705.6666},
  {x:43.0000,y:706.3334},
  {x:43.0000,y:707.0000},
  {x:42.6667,y:707.0000},
  {x:42.3333,y:707.0000},
  {x:42.0000,y:707.0000},
  {x:42.0000,y:707.6666},
  {x:42.0000,y:708.3334},
  {x:42.0000,y:709.0000},
  {x:41.6667,y:709.0000},
  {x:41.3333,y:709.0000},
  {x:41.0000,y:709.0000},
  {x:41.0000,y:709.6666},
  {x:41.0000,y:710.3334},
  {x:41.0000,y:711.0000},
  {x:40.6667,y:711.0000},
  {x:40.3333,y:711.0000},
  {x:40.0000,y:711.0000},
  {x:39.3334,y:712.9998},
  {x:38.6666,y:715.0002},
  {x:38.0000,y:717.0000},
  {x:37.6667,y:717.0000},
  {x:37.3333,y:717.0000},
  {x:37.0000,y:717.0000},
  {x:37.0000,y:717.9999},
  {x:37.0000,y:719.0001},
  {x:37.0000,y:720.0000},
  {x:36.6667,y:720.0000},
  {x:36.3333,y:720.0000},
  {x:36.0000,y:720.0000},
  {x:35.3334,y:721.9998},
  {x:34.6666,y:724.0002},
  {x:34.0000,y:726.0000},
  {x:33.6667,y:726.0000},
  {x:33.3333,y:726.0000},
  {x:33.0000,y:726.0000},
  {x:33.0000,y:726.6666},
  {x:33.0000,y:727.3334},
  {x:33.0000,y:728.0000},
  {x:32.6667,y:728.0000},
  {x:32.3333,y:728.0000},
  {x:32.0000,y:728.0000},
  {x:32.0000,y:728.6666},
  {x:32.0000,y:729.3334},
  {x:32.0000,y:730.0000},
  {x:31.6667,y:730.0000},
  {x:31.3333,y:730.0000},
  {x:31.0000,y:730.0000},
  {x:31.0000,y:730.6666},
  {x:31.0000,y:731.3334},
  {x:31.0000,y:732.0000},
  {x:30.6667,y:732.0000},
  {x:30.3333,y:732.0000},
  {x:30.0000,y:732.0000},
  {x:30.0000,y:732.6666},
  {x:30.0000,y:733.3334},
  {x:30.0000,y:734.0000},
  {x:29.6667,y:734.0000},
  {x:29.3333,y:734.0000},
  {x:29.0000,y:734.0000},
  {x:29.0000,y:734.6666},
  {x:29.0000,y:735.3334},
  {x:29.0000,y:736.0000},
  {x:28.6667,y:736.0000},
  {x:28.3333,y:736.0000},
  {x:28.0000,y:736.0000},
  {x:28.0000,y:736.6666},
  {x:28.0000,y:737.3334},
  {x:28.0000,y:738.0000},
  {x:27.6667,y:738.0000},
  {x:27.3333,y:738.0000},
  {x:27.0000,y:738.0000},
  {x:27.0000,y:738.6666},
  {x:27.0000,y:739.3334},
  {x:27.0000,y:740.0000},
  {x:26.6667,y:740.0000},
  {x:26.3333,y:740.0000},
  {x:26.0000,y:740.0000},
  {x:26.0000,y:740.6666},
  {x:26.0000,y:741.3334},
  {x:26.0000,y:742.0000},
  {x:25.6667,y:742.0000},
  {x:25.3333,y:742.0000},
  {x:25.0000,y:742.0000},
  {x:24.6667,y:743.3332},
  {x:24.3333,y:744.6668},
  {x:24.0000,y:746.0000},
  {x:23.3334,y:746.3333},
  {x:22.6666,y:746.6667},
  {x:22.0000,y:747.0000},
  {x:22.0000,y:747.6666},
  {x:22.0000,y:748.3334},
  {x:22.0000,y:749.0000},
  {x:21.6667,y:749.0000},
  {x:21.3333,y:749.0000},
  {x:21.0000,y:749.0000},
  {x:21.0000,y:749.6666},
  {x:21.0000,y:750.3334},
  {x:21.0000,y:751.0000},
  {x:20.6667,y:751.0000},
  {x:20.3333,y:751.0000},
  {x:20.0000,y:751.0000},
  {x:20.0000,y:751.6666},
  {x:20.0000,y:752.3334},
  {x:20.0000,y:753.0000},
  {x:19.6667,y:753.0000},
  {x:19.3333,y:753.0000},
  {x:19.0000,y:753.0000},
  {x:19.0000,y:753.6666},
  {x:19.0000,y:754.3334},
  {x:19.0000,y:755.0000},
  {x:18.6667,y:755.0000},
  {x:18.3333,y:755.0000},
  {x:18.0000,y:755.0000},
  {x:18.0000,y:755.6666},
  {x:18.0000,y:756.3334},
  {x:18.0000,y:757.0000},
  {x:17.3334,y:757.3333},
  {x:16.6666,y:757.6667},
  {x:16.0000,y:758.0000},
  {x:16.0000,y:758.6666},
  {x:16.0000,y:759.3334},
  {x:16.0000,y:760.0000},
  {x:15.3334,y:760.3333},
  {x:14.6666,y:760.6667},
  {x:14.0000,y:761.0000},
  {x:14.0000,y:761.6666},
  {x:14.0000,y:762.3334},
  {x:14.0000,y:763.0000},
  {x:10.9396,y:767.6262},
  {x:7.4564,y:770.4191},
  {x:7.0000,y:778.0000},
  {x:7.3333,y:778.0000},
  {x:7.6667,y:778.0000},
  {x:8.0000,y:778.0000},
  {x:8.0000,y:778.6666},
  {x:8.0000,y:779.3334},
  {x:8.0000,y:780.0000},
  {x:8.9999,y:780.6666},
  {x:10.0001,y:781.3334},
  {x:11.0000,y:782.0000},
  {x:11.0000,y:782.3333},
  {x:11.0000,y:782.6667},
  {x:11.0000,y:783.0000},
  {x:11.6666,y:783.0000},
  {x:12.3334,y:783.0000},
  {x:13.0000,y:783.0000},
  {x:13.3333,y:783.6666},
  {x:13.6667,y:784.3334},
  {x:14.0000,y:785.0000},
  {x:14.6666,y:785.0000},
  {x:15.3334,y:785.0000},
  {x:16.0000,y:785.0000},
  {x:16.0000,y:785.3333},
  {x:16.0000,y:785.6667},
  {x:16.0000,y:786.0000},
  {x:17.3332,y:786.3333},
  {x:18.6668,y:786.6667},
  {x:20.0000,y:787.0000},
  {x:20.0000,y:787.3333},
  {x:20.0000,y:787.6667},
  {x:20.0000,y:788.0000},
  {x:21.3332,y:788.0000},
  {x:22.6668,y:788.0000},
  {x:24.0000,y:788.0000},
  {x:24.0000,y:788.3333},
  {x:24.0000,y:788.6667},
  {x:24.0000,y:789.0000},
  {x:35.3322,y:789.6666},
  {x:46.6678,y:790.3334},
  {x:58.0000,y:791.0000},
  {x:71.4791,y:795.1742},
  {x:88.4872,y:795.7957},
  {x:102.0000,y:800.0000},
  {x:103.9998,y:800.0000},
  {x:106.0002,y:800.0000},
  {x:108.0000,y:800.0000},
  {x:108.0000,y:800.3333},
  {x:108.0000,y:800.6667},
  {x:108.0000,y:801.0000},
  {x:109.9998,y:801.0000},
  {x:112.0002,y:801.0000},
  {x:114.0000,y:801.0000},
  {x:114.0000,y:801.3333},
  {x:114.0000,y:801.6667},
  {x:114.0000,y:802.0000},
  {x:115.9998,y:802.0000},
  {x:118.0002,y:802.0000},
  {x:120.0000,y:802.0000},
  {x:120.0000,y:802.3333},
  {x:120.0000,y:802.6667},
  {x:120.0000,y:803.0000},
  {x:121.9998,y:803.0000},
  {x:124.0002,y:803.0000},
  {x:126.0000,y:803.0000},
  {x:130.9725,y:804.5200},
  {x:138.0301,y:805.4729},
  {x:143.0000,y:807.0000},
  {x:150.9992,y:807.9999},
  {x:159.0008,y:809.0001},
  {x:167.0000,y:810.0000},
  {x:167.0000,y:810.3333},
  {x:167.0000,y:810.6667},
  {x:167.0000,y:811.0000},
  {x:170.9996,y:811.3333},
  {x:175.0004,y:811.6667},
  {x:179.0000,y:812.0000},
  {x:192.1105,y:816.0056},
  {x:208.8175,y:815.9507},
  {x:222.0000,y:820.0000},
  {x:223.9998,y:820.0000},
  {x:226.0002,y:820.0000},
  {x:228.0000,y:820.0000},
  {x:228.0000,y:820.3333},
  {x:228.0000,y:820.6667},
  {x:228.0000,y:821.0000},
  {x:229.9998,y:821.0000},
  {x:232.0002,y:821.0000},
  {x:234.0000,y:821.0000},
  {x:234.0000,y:821.3333},
  {x:234.0000,y:821.6667},
  {x:234.0000,y:822.0000},
  {x:235.9998,y:822.0000},
  {x:238.0002,y:822.0000},
  {x:240.0000,y:822.0000},
  {x:240.0000,y:822.3333},
  {x:240.0000,y:822.6667},
  {x:240.0000,y:823.0000},
  {x:241.9998,y:823.0000},
  {x:244.0002,y:823.0000},
  {x:246.0000,y:823.0000},
  {x:246.0000,y:823.3333},
  {x:246.0000,y:823.6667},
  {x:246.0000,y:824.0000},
  {x:248.3331,y:824.0000},
  {x:250.6669,y:824.0000},
  {x:253.0000,y:824.0000},
  {x:253.0000,y:824.3333},
  {x:253.0000,y:824.6667},
  {x:253.0000,y:825.0000},
  {x:254.9998,y:825.0000},
  {x:257.0002,y:825.0000},
  {x:259.0000,y:825.0000},
  {x:259.0000,y:825.3333},
  {x:259.0000,y:825.6667},
  {x:259.0000,y:826.0000},
  {x:260.9998,y:826.0000},
  {x:263.0002,y:826.0000},
  {x:265.0000,y:826.0000},
  {x:270.5779,y:827.6914},
  {x:278.5282,y:828.3197},
  {x:284.0000,y:830.0000},
  {x:285.9998,y:830.0000},
  {x:288.0002,y:830.0000},
  {x:290.0000,y:830.0000},
  {x:297.7593,y:832.3522},
  {x:308.3009,y:832.6683},
  {x:316.0000,y:835.0000},
  {x:318.3331,y:835.0000},
  {x:320.6669,y:835.0000},
  {x:323.0000,y:835.0000},
  {x:332.5963,y:837.8895},
  {x:345.4101,y:838.0962},
  {x:355.0000,y:841.0000},
  {x:359.6662,y:841.3333},
  {x:364.3338,y:841.6667},
  {x:369.0000,y:842.0000},
  {x:381.0163,y:845.6155},
  {x:396.9928,y:845.3651},
  {x:409.0000,y:849.0000},
  {x:413.6662,y:849.3333},
  {x:418.3338,y:849.6667},
  {x:423.0000,y:850.0000},
  {x:423.0000,y:850.3333},
  {x:423.0000,y:850.6667},
  {x:423.0000,y:851.0000},
  {x:427.9995,y:851.3333},
  {x:433.0005,y:851.6667},
  {x:438.0000,y:852.0000},
  {x:453.4436,y:856.5775},
  {x:473.5034,y:855.3748},
  {x:489.0000,y:860.0000},
  {x:491.6664,y:860.0000},
  {x:494.3336,y:860.0000},
  {x:497.0000,y:860.0000},
  {x:497.0000,y:860.3333},
  {x:497.0000,y:860.6667},
  {x:497.0000,y:861.0000},
  {x:499.6664,y:861.0000},
  {x:502.3336,y:861.0000},
  {x:505.0000,y:861.0000},
  {x:505.0000,y:861.3333},
  {x:505.0000,y:861.6667},
  {x:505.0000,y:862.0000},
  {x:510.3328,y:862.3333},
  {x:515.6672,y:862.6667},
  {x:521.0000,y:863.0000},
  {x:534.2817,y:866.9083},
  {x:552.6942,y:865.0725},
  {x:566.0000,y:869.0000},
  {x:569.3330,y:869.0000},
  {x:572.6670,y:869.0000},
  {x:576.0000,y:869.0000},
  {x:576.0000,y:869.3333},
  {x:576.0000,y:869.6667},
  {x:576.0000,y:870.0000},
  {x:579.9996,y:870.0000},
  {x:584.0004,y:870.0000},
  {x:588.0000,y:870.0000},
  {x:588.0000,y:870.3333},
  {x:588.0000,y:870.6667},
  {x:588.0000,y:871.0000},
  {x:598.3323,y:871.6666},
  {x:608.6677,y:872.3334},
  {x:619.0000,y:873.0000},
  {x:619.0000,y:873.3333},
  {x:619.0000,y:873.6667},
  {x:619.0000,y:874.0000},
  {x:621.9997,y:874.0000},
  {x:625.0003,y:874.0000},
  {x:628.0000,y:874.0000},
  {x:628.0000,y:874.3333},
  {x:628.0000,y:874.6667},
  {x:628.0000,y:875.0000},
  {x:630.9997,y:875.0000},
  {x:634.0003,y:875.0000},
  {x:637.0000,y:875.0000},
  {x:637.0000,y:875.3333},
  {x:637.0000,y:875.6667},
  {x:637.0000,y:876.0000},
  {x:639.9997,y:876.0000},
  {x:643.0003,y:876.0000},
  {x:646.0000,y:876.0000},
  {x:646.0000,y:876.3333},
  {x:646.0000,y:876.6667},
  {x:646.0000,y:877.0000},
  {x:648.9997,y:877.0000},
  {x:652.0003,y:877.0000},
  {x:655.0000,y:877.0000},
  {x:667.5609,y:880.6916},
  {x:684.4525,y:879.2820},
  {x:697.0000,y:883.0000},
  {x:699.9997,y:883.0000},
  {x:703.0003,y:883.0000},
  {x:706.0000,y:883.0000},
  {x:706.0000,y:883.3333},
  {x:706.0000,y:883.6667},
  {x:706.0000,y:884.0000},
  {x:708.9997,y:884.0000},
  {x:712.0003,y:884.0000},
  {x:715.0000,y:884.0000},
  {x:715.0000,y:884.3333},
  {x:715.0000,y:884.6667},
  {x:715.0000,y:885.0000},
  {x:717.9997,y:885.0000},
  {x:721.0003,y:885.0000},
  {x:724.0000,y:885.0000},
  {x:724.0000,y:885.3333},
  {x:724.0000,y:885.6667},
  {x:724.0000,y:886.0000},
  {x:726.9997,y:886.0000},
  {x:730.0003,y:886.0000},
  {x:733.0000,y:886.0000},
  {x:733.0000,y:886.3333},
  {x:733.0000,y:886.6667},
  {x:733.0000,y:887.0000},
  {x:735.9997,y:887.0000},
  {x:739.0003,y:887.0000},
  {x:742.0000,y:887.0000},
  {x:742.0000,y:887.3333},
  {x:742.0000,y:887.6667},
  {x:742.0000,y:888.0000},
  {x:744.9997,y:888.0000},
  {x:748.0003,y:888.0000},
  {x:751.0000,y:888.0000},
  {x:759.1574,y:890.3902},
  {x:770.7911,y:889.5739},
  {x:779.0000,y:892.0000},
  {x:781.9997,y:892.0000},
  {x:785.0003,y:892.0000},
  {x:788.0000,y:892.0000},
  {x:799.8507,y:895.4669},
  {x:816.1624,y:893.5086},
  {x:828.0000,y:897.0000},
  {x:831.3330,y:897.0000},
  {x:834.6670,y:897.0000},
  {x:838.0000,y:897.0000},
  {x:838.0000,y:897.3333},
  {x:838.0000,y:897.6667},
  {x:838.0000,y:898.0000},
  {x:841.3330,y:898.0000},
  {x:844.6670,y:898.0000},
  {x:848.0000,y:898.0000},
  {x:857.6284,y:900.8075},
  {x:871.3846,y:899.1684},
  {x:881.0000,y:902.0000},
  {x:884.6663,y:902.0000},
  {x:888.3337,y:902.0000},
  {x:892.0000,y:902.0000},
  {x:892.0000,y:902.3333},
  {x:892.0000,y:902.6667},
  {x:892.0000,y:903.0000},
  {x:895.9996,y:903.0000},
  {x:900.0004,y:903.0000},
  {x:904.0000,y:903.0000},
  {x:914.4997,y:906.0538},
  {x:929.5169,y:903.9163},
  {x:940.0000,y:907.0000},
  {x:944.3329,y:907.0000},
  {x:948.6671,y:907.0000},
  {x:953.0000,y:907.0000},
  {x:953.0000,y:907.3333},
  {x:953.0000,y:907.6667},
  {x:953.0000,y:908.0000},
  {x:957.3329,y:908.0000},
  {x:961.6671,y:908.0000},
  {x:966.0000,y:908.0000},
  {x:966.0000,y:908.3333},
  {x:966.0000,y:908.6667},
  {x:966.0000,y:909.0000},
  {x:970.6662,y:909.0000},
  {x:975.3338,y:909.0000},
  {x:980.0000,y:909.0000},
  {x:980.0000,y:909.3333},
  {x:980.0000,y:909.6667},
  {x:980.0000,y:910.0000},
  {x:984.6662,y:910.0000},
  {x:989.3338,y:910.0000},
  {x:994.0000,y:910.0000},
  {x:994.0000,y:910.3333},
  {x:994.0000,y:910.6667},
  {x:994.0000,y:911.0000},
  {x:997.3330,y:911.0000},
  {x:1000.6670,y:911.0000},
  {x:1004.0000,y:911.0000},
  {x:1010.6660,y:911.3333},
  {x:1017.3340,y:911.6667},
  {x:1024.0000,y:912.0000},
  {x:1024.0000,y:912.3333},
  {x:1024.0000,y:912.6667},
  {x:1024.0000,y:913.0000},
  {x:1028.9995,y:913.0000},
  {x:1034.0005,y:913.0000},
  {x:1039.0000,y:913.0000},
  {x:1039.0000,y:913.3333},
  {x:1039.0000,y:913.6667},
  {x:1039.0000,y:914.0000},
  {x:1044.6661,y:914.0000},
  {x:1050.3339,y:914.0000},
  {x:1056.0000,y:914.0000},
  {x:1056.0000,y:914.3333},
  {x:1056.0000,y:914.6667},
  {x:1056.0000,y:915.0000},
  {x:1061.6661,y:915.0000},
  {x:1067.3339,y:915.0000},
  {x:1073.0000,y:915.0000},
  {x:1073.0000,y:915.3333},
  {x:1073.0000,y:915.6667},
  {x:1073.0000,y:916.0000},
  {x:1078.4302,y:917.5959},
  {x:1087.1438,y:915.5729},
  {x:1092.0000,y:917.0000},
  {x:1105.6653,y:917.3333},
  {x:1119.3347,y:917.6667},
  {x:1133.0000,y:918.0000},
  {x:1133.0000,y:918.3333},
  {x:1133.0000,y:918.6667},
  {x:1133.0000,y:919.0000},
  {x:1140.9992,y:919.0000},
  {x:1149.0008,y:919.0000},
  {x:1157.0000,y:919.0000},
  {x:1157.0000,y:919.3333},
  {x:1157.0000,y:919.6667},
  {x:1157.0000,y:920.0000},
  {x:1164.9992,y:920.0000},
  {x:1173.0008,y:920.0000},
  {x:1181.0000,y:920.0000},
  {x:1182.6665,y:920.3333},
  {x:1184.3335,y:920.6667},
  {x:1186.0000,y:921.0000},
  {x:1194.1044,y:921.3581},
  {x:1204.5266,y:920.0980},
  {x:1211.0000,y:922.0000},
  {x:1221.3323,y:922.0000},
  {x:1231.6677,y:922.0000},
  {x:1242.0000,y:922.0000},
  {x:1250.0247,y:924.3099},
  {x:1263.2314,y:922.9994},
  {x:1273.0000,y:923.0000},
  {x:1293.9979,y:923.0000},
  {x:1315.0021,y:923.0000},
  {x:1336.0000,y:923.0000},
  {x:1352.9983,y:923.0000},
  {x:1370.0017,y:923.0000},
  {x:1387.0000,y:923.0000},
  {x:1387.0000,y:922.6667},
  {x:1387.0000,y:922.3333},
  {x:1387.0000,y:922.0000},
  {x:1397.3323,y:922.0000},
  {x:1407.6677,y:922.0000},
  {x:1418.0000,y:922.0000},
  {x:1418.0000,y:921.6667},
  {x:1418.0000,y:921.3333},
  {x:1418.0000,y:921.0000},
  {x:1424.2096,y:919.1767},
  {x:1434.8621,y:921.8026},
  {x:1441.0000,y:920.0000},
  {x:1446.9994,y:920.0000},
  {x:1453.0006,y:920.0000},
  {x:1459.0000,y:920.0000},
  {x:1459.3333,y:920.0000},
  {x:1459.6667,y:920.0000},
  {x:1460.0000,y:920.0000},
  {x:1460.0000,y:919.6667},
  {x:1460.0000,y:919.3333},
  {x:1460.0000,y:919.0000},
  {x:1465.6661,y:919.0000},
  {x:1471.3339,y:919.0000},
  {x:1477.0000,y:919.0000},
  {x:1477.0000,y:918.6667},
  {x:1477.0000,y:918.3333},
  {x:1477.0000,y:918.0000},
  {x:1482.3328,y:918.0000},
  {x:1487.6672,y:918.0000},
  {x:1493.0000,y:918.0000},
  {x:1493.0000,y:917.6667},
  {x:1493.0000,y:917.3333},
  {x:1493.0000,y:917.0000},
  {x:1497.6662,y:917.0000},
  {x:1502.3338,y:917.0000},
  {x:1507.0000,y:917.0000},
  {x:1507.0000,y:916.6667},
  {x:1507.0000,y:916.3333},
  {x:1507.0000,y:916.0000},
  {x:1511.3329,y:916.0000},
  {x:1515.6671,y:916.0000},
  {x:1520.0000,y:916.0000},
  {x:1520.0000,y:915.6667},
  {x:1520.0000,y:915.3333},
  {x:1520.0000,y:915.0000},
  {x:1524.3329,y:915.0000},
  {x:1528.6671,y:915.0000},
  {x:1533.0000,y:915.0000},
  {x:1533.0000,y:914.6667},
  {x:1533.0000,y:914.3333},
  {x:1533.0000,y:914.0000},
  {x:1536.6663,y:914.0000},
  {x:1540.3337,y:914.0000},
  {x:1544.0000,y:914.0000},
  {x:1544.0000,y:913.6667},
  {x:1544.0000,y:913.3333},
  {x:1544.0000,y:913.0000},
  {x:1547.6663,y:913.0000},
  {x:1551.3337,y:913.0000},
  {x:1555.0000,y:913.0000},
  {x:1555.0000,y:912.6667},
  {x:1555.0000,y:912.3333},
  {x:1555.0000,y:912.0000},
  {x:1570.9984,y:910.6668},
  {x:1587.0016,y:909.3332},
  {x:1603.0000,y:908.0000},
  {x:1612.8871,y:905.0928},
  {x:1626.2023,y:905.9152},
  {x:1636.0000,y:903.0000},
  {x:1638.6664,y:903.0000},
  {x:1641.3336,y:903.0000},
  {x:1644.0000,y:903.0000},
  {x:1644.0000,y:902.6667},
  {x:1644.0000,y:902.3333},
  {x:1644.0000,y:902.0000},
  {x:1646.6664,y:902.0000},
  {x:1649.3336,y:902.0000},
  {x:1652.0000,y:902.0000},
  {x:1652.0000,y:901.6667},
  {x:1652.0000,y:901.3333},
  {x:1652.0000,y:901.0000},
  {x:1654.3331,y:901.0000},
  {x:1656.6669,y:901.0000},
  {x:1659.0000,y:901.0000},
  {x:1659.0000,y:900.6667},
  {x:1659.0000,y:900.3333},
  {x:1659.0000,y:900.0000},
  {x:1661.3331,y:900.0000},
  {x:1663.6669,y:900.0000},
  {x:1666.0000,y:900.0000},
  {x:1666.0000,y:899.6667},
  {x:1666.0000,y:899.3333},
  {x:1666.0000,y:899.0000},
  {x:1668.3331,y:899.0000},
  {x:1670.6669,y:899.0000},
  {x:1673.0000,y:899.0000},
  {x:1673.0000,y:898.6667},
  {x:1673.0000,y:898.3333},
  {x:1673.0000,y:898.0000},
  {x:1675.3331,y:898.0000},
  {x:1677.6669,y:898.0000},
  {x:1680.0000,y:898.0000},
  {x:1680.0000,y:897.6667},
  {x:1680.0000,y:897.3333},
  {x:1680.0000,y:897.0000},
  {x:1682.3331,y:897.0000},
  {x:1684.6669,y:897.0000},
  {x:1687.0000,y:897.0000},
  {x:1687.0000,y:896.6667},
  {x:1687.0000,y:896.3333},
  {x:1687.0000,y:896.0000},
  {x:1691.3329,y:895.6667},
  {x:1695.6671,y:895.3333},
  {x:1700.0000,y:895.0000},
  {x:1713.2205,y:891.0189},
  {x:1729.8915,y:891.0198},
  {x:1743.0000,y:887.0000},
  {x:1747.9995,y:886.6667},
  {x:1753.0005,y:886.3333},
  {x:1758.0000,y:886.0000},
  {x:1758.0000,y:885.6667},
  {x:1758.0000,y:885.3333},
  {x:1758.0000,y:885.0000},
  {x:1761.9996,y:884.6667},
  {x:1766.0004,y:884.3333},
  {x:1770.0000,y:884.0000},
  {x:1770.0000,y:883.6667},
  {x:1770.0000,y:883.3333},
  {x:1770.0000,y:883.0000},
  {x:1771.6665,y:883.0000},
  {x:1773.3335,y:883.0000},
  {x:1775.0000,y:883.0000},
  {x:1775.0000,y:882.6667},
  {x:1775.0000,y:882.3333},
  {x:1775.0000,y:882.0000},
  {x:1775.3333,y:882.0000},
  {x:1775.6667,y:882.0000},
  {x:1776.0000,y:882.0000},
  {x:1776.0000,y:882.3333},
  {x:1776.0000,y:882.6667},
  {x:1776.0000,y:883.0000},
  {x:1777.9998,y:883.0000},
  {x:1780.0002,y:883.0000},
  {x:1782.0000,y:883.0000},
  {x:1782.0000,y:883.3333},
  {x:1782.0000,y:883.6667},
  {x:1782.0000,y:884.0000},
  {x:1786.9995,y:883.6667},
  {x:1792.0005,y:883.3333},
  {x:1797.0000,y:883.0000},
  {x:1797.0000,y:882.6667},
  {x:1797.0000,y:882.3333},
  {x:1797.0000,y:882.0000},
  {x:1797.6666,y:882.0000},
  {x:1798.3334,y:882.0000},
  {x:1799.0000,y:882.0000},
  {x:1799.9999,y:880.6668},
  {x:1801.0001,y:879.3332},
  {x:1802.0000,y:878.0000},
  {x:1802.3333,y:878.0000},
  {x:1802.6667,y:878.0000},
  {x:1803.0000,y:878.0000},
  {x:1803.0000,y:878.3333},
  {x:1803.0000,y:878.6667},
  {x:1803.0000,y:879.0000},
  {x:1808.5351,y:879.7930},
  {x:1813.7179,y:878.1856},
  {x:1818.0000,y:878.0000},
  {x:1821.3330,y:878.0000},
  {x:1824.6670,y:878.0000},
  {x:1828.0000,y:878.0000},
  {x:1828.0000,y:877.6667},
  {x:1828.0000,y:877.3333},
  {x:1828.0000,y:877.0000},
  {x:1831.6663,y:877.0000},
  {x:1835.3337,y:877.0000},
  {x:1839.0000,y:877.0000},
  {x:1853.7445,y:872.4128},
  {x:1870.6717,y:868.9357},
  {x:1885.0000,y:864.0000},
  {x:1888.6663,y:863.6667},
  {x:1892.3337,y:863.3333},
  {x:1896.0000,y:863.0000},
  {x:1903.4249,y:860.7012},
  {x:1912.7526,y:859.2556},
  {x:1920.0000,y:857.0000},
  {x:1924.3329,y:856.6667},
  {x:1928.6671,y:856.3333},
  {x:1933.0000,y:856.0000},
  {x:1943.1317,y:852.9517},
  {x:1956.7736,y:853.0759},
  {x:1967.0000,y:850.0000},
  {x:1971.6662,y:849.6667},
  {x:1976.3338,y:849.3333},
  {x:1981.0000,y:849.0000},
  {x:1996.1656,y:844.4726},
  {x:2015.7981,y:845.5382},
  {x:2031.0000,y:841.0000},
  {x:2033.6664,y:841.0000},
  {x:2036.3336,y:841.0000},
  {x:2039.0000,y:841.0000},
  {x:2039.0000,y:840.6667},
  {x:2039.0000,y:840.3333},
  {x:2039.0000,y:840.0000},
  {x:2041.3331,y:840.0000},
  {x:2043.6669,y:840.0000},
  {x:2046.0000,y:840.0000},
  {x:2046.0000,y:839.6667},
  {x:2046.0000,y:839.3333},
  {x:2046.0000,y:839.0000},
  {x:2050.9995,y:838.6667},
  {x:2056.0005,y:838.3333},
  {x:2061.0000,y:838.0000},
  {x:2073.5475,y:834.2735},
  {x:2090.3257,y:835.7386},
  {x:2103.0000,y:832.0000},
  {x:2106.6663,y:832.0000},
  {x:2110.3337,y:832.0000},
  {x:2114.0000,y:832.0000},
  {x:2114.0000,y:831.6667},
  {x:2114.0000,y:831.3333},
  {x:2114.0000,y:831.0000},
  {x:2121.3326,y:830.6667},
  {x:2128.6674,y:830.3333},
  {x:2136.0000,y:830.0000},
  {x:2136.0000,y:829.6667},
  {x:2136.0000,y:829.3333},
  {x:2136.0000,y:829.0000},
  {x:2138.6664,y:829.0000},
  {x:2141.3336,y:829.0000},
  {x:2144.0000,y:829.0000},
  {x:2144.0000,y:828.6667},
  {x:2144.0000,y:828.3333},
  {x:2144.0000,y:828.0000},
  {x:2146.6664,y:828.0000},
  {x:2149.3336,y:828.0000},
  {x:2152.0000,y:828.0000},
  {x:2163.1797,y:824.7026},
  {x:2177.9233,y:825.3051},
  {x:2189.0000,y:822.0000},
  {x:2191.6664,y:822.0000},
  {x:2194.3336,y:822.0000},
  {x:2197.0000,y:822.0000},
  {x:2197.0000,y:821.6667},
  {x:2197.0000,y:821.3333},
  {x:2197.0000,y:821.0000},
  {x:2199.6664,y:821.0000},
  {x:2202.3336,y:821.0000},
  {x:2205.0000,y:821.0000},
  {x:2205.0000,y:820.6667},
  {x:2205.0000,y:820.3333},
  {x:2205.0000,y:820.0000},
  {x:2207.9997,y:820.0000},
  {x:2211.0003,y:820.0000},
  {x:2214.0000,y:820.0000},
  {x:2214.0000,y:819.6667},
  {x:2214.0000,y:819.3333},
  {x:2214.0000,y:819.0000},
  {x:2217.6663,y:819.0000},
  {x:2221.3337,y:819.0000},
  {x:2225.0000,y:819.0000},
  {x:2225.0000,y:818.6667},
  {x:2225.0000,y:818.3333},
  {x:2225.0000,y:818.0000},
  {x:2228.6663,y:818.0000},
  {x:2232.3337,y:818.0000},
  {x:2236.0000,y:818.0000},
  {x:2236.0000,y:817.6667},
  {x:2236.0000,y:817.3333},
  {x:2236.0000,y:817.0000},
  {x:2239.6663,y:817.0000},
  {x:2243.3337,y:817.0000},
  {x:2247.0000,y:817.0000},
  {x:2247.0000,y:816.6667},
  {x:2247.0000,y:816.3333},
  {x:2247.0000,y:816.0000},
  {x:2250.3330,y:816.0000},
  {x:2253.6670,y:816.0000},
  {x:2257.0000,y:816.0000},
  {x:2257.0000,y:815.6667},
  {x:2257.0000,y:815.3333},
  {x:2257.0000,y:815.0000},
  {x:2267.9989,y:814.3334},
  {x:2279.0011,y:813.6666},
  {x:2290.0000,y:813.0000},
  {x:2290.0000,y:812.6667},
  {x:2290.0000,y:812.3333},
  {x:2290.0000,y:812.0000},
  {x:2293.3330,y:812.0000},
  {x:2296.6670,y:812.0000},
  {x:2300.0000,y:812.0000},
  {x:2300.0000,y:811.6667},
  {x:2300.0000,y:811.3333},
  {x:2300.0000,y:811.0000},
  {x:2303.6663,y:811.0000},
  {x:2307.3337,y:811.0000},
  {x:2311.0000,y:811.0000},
  {x:2311.0000,y:810.6667},
  {x:2311.0000,y:810.3333},
  {x:2311.0000,y:810.0000},
  {x:2314.3330,y:810.0000},
  {x:2317.6670,y:810.0000},
  {x:2321.0000,y:810.0000},
  {x:2332.5627,y:806.6259},
  {x:2348.5178,y:808.3960},
  {x:2360.0000,y:805.0000},
  {x:2366.3327,y:804.6667},
  {x:2372.6673,y:804.3333},
  {x:2379.0000,y:804.0000},
  {x:2388.5718,y:801.2142},
  {x:2402.3442,y:802.8399},
  {x:2412.0000,y:800.0000},
  {x:2416.6662,y:800.0000},
  {x:2421.3338,y:800.0000},
  {x:2426.0000,y:800.0000},
  {x:2426.0000,y:799.6667},
  {x:2426.0000,y:799.3333},
  {x:2426.0000,y:799.0000},
  {x:2431.3328,y:799.0000},
  {x:2436.6672,y:799.0000},
  {x:2442.0000,y:799.0000},
  {x:2442.0000,y:798.6667},
  {x:2442.0000,y:798.3333},
  {x:2442.0000,y:798.0000},
  {x:2448.6660,y:798.0000},
  {x:2455.3340,y:798.0000},
  {x:2462.0000,y:798.0000},
  {x:2467.6095,y:796.3775},
  {x:2477.2768,y:798.6544},
  {x:2483.0000,y:797.0000},
  {x:2489.1509,y:795.2220},
  {x:2499.7514,y:797.8053},
  {x:2506.0000,y:796.0000},
  {x:2516.1529,y:793.0668},
  {x:2534.7677,y:795.0723},
  {x:2547.0000,y:795.0000},
  {x:2548.9998,y:795.0000},
  {x:2551.0002,y:795.0000},
  {x:2553.0000,y:795.0000},
  {x:2553.0000,y:794.6667},
  {x:2553.0000,y:794.3333},
  {x:2553.0000,y:794.0000},
  {x:2563.3323,y:794.0000},
  {x:2573.6677,y:794.0000},
  {x:2584.0000,y:794.0000},
  {x:2589.9994,y:794.0000},
  {x:2596.0006,y:794.0000},
  {x:2602.0000,y:794.0000},
  {x:2616.3063,y:789.8600},
  {x:2635.8378,y:791.0256},
  {x:2654.0000,y:791.0000},
  {x:2654.0000,y:790.6667},
  {x:2654.0000,y:790.3333},
  {x:2654.0000,y:790.0000},
  {x:2653.3334,y:790.0000},
  {x:2652.6666,y:790.0000},
  {x:2652.0000,y:790.0000}
];
