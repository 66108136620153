import CloseIcon from '@/components/icons/close'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'OrderConfirmed',

  components: {
    CloseIcon,

    Button,
    ButtonIcon
  },

  methods: {
    close: function() {
      EventBus.$emit('hideOrderConfirmed');
    }
  }
}
