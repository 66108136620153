export default {
  name: 'ButtonIcon',

  props: {
    type: {
      type: String,
      validator: (val) => [
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'dark',
        'gray',
        'light',
        'render'
      ].includes(val)
    },

    shape: {
      type: String,
      default: 'circle',
      validator: (val) => [
        'circle',
        'square'
      ].includes(val)
    },

    size: {
      type: String,
      default: 'small',
      validator: (val) => [
        'small',
        'big',
        'xs'
      ].includes(val)
    },

    color: {
      type: String,
      default: 'stroke',
      validator: (val) => [
        'fill',
        'stroke'
      ]
    },

    icon: {
      type: String,
      default: null
    },

    active: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: null
    }
  }
}
