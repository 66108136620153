import EyeIcon from '@/components/icons/eye'
import EyeOffIcon from '@/components/icons/eyeOff'
import vmodel from '@/mixins/vmodel.js'

export default {
  name: 'Input',

  mixins: [vmodel],

  components: {
    EyeIcon,
    EyeOffIcon
  },

  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (val) => [
        'primary',
        'secondary',
        'light'
      ].includes(val)
    },

    type: {
      type: String,
      default: 'text',
      validator: (val) => [
        'text',
        'number',
        'email',
        'password'
      ].includes(val)
    },

    size: {
      type: String,
      default: 'big',
      validator: (val) => [
        'big',
        'small'
      ].includes(val)
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: null
    },

    readonly: {
      type: Boolean,
      default: null
    }
  },

  data() {
    return {
      isShow: false
    }
  },

  methods: {
    switchPassword() {
      this.isShow = !this.isShow;
    }
  }
}
