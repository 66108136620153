import { styler, translate, scale, rotate } from 'free-transform'

export default {
  name: 'FreeTf',

  props: {
    x: {
      type: Number,
      default: 0
    },

    y: {
      type: Number,
      default: 0
    },

    width: {
      type: Number,
      required: true
    },

    height: {
      type: Number,
      required: true
    },

    scaleX: {
      type: Number,
      default: 1
    },

    scaleY: {
      type: Number,
      default: 1
    },

    angle: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      disableScale: false
    }
  },

  computed: {
    style: function() {
      const { element } = styler({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        angle: this.angle,
        disableScale: this.disableScale
      });

      return {
        ...element,
        width: element.width
          ? `${element.width}px`
          : null,
        height: element.height
          ? `${element.height}px`
          : null
      }
    }
  }
}
