import CloseIcon from '@/components/icons/close'
import SneakerIcon from '@/components/icons/custom/sneaker'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Repeat',

  components: {
    CloseIcon,
    SneakerIcon,

    Button,
    ButtonIcon
  },

  props: {
    area: {
      type: String,
      default: null
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideRestartArea');
    },

    restart: function() {
      this.$emit('restart', this.area);

      this.close();
    }
  }
}
