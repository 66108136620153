import size from '@/mixins/size'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  mixins: [size],

  data() {
    return {
      store: 'viking-studio',
      emptyItems: []
    }
  },

  methods: {
    show: function() {
      EventBus.$emit('showCart');
    },

    initCart: function() {
      localStorage.setItem(this.store, JSON.stringify(this.emptyItems));
    },

    getCartItems: async function() {
      let response;
      let cart = localStorage.getItem(this.store) || null;

      if (cart !== null) {
        response = JSON.parse(cart);

        for (let i = 0; i < response.length; i++) {
          let item = response[i];

          item.sizeName = await this.getSizeItem(item.size);
        }
      } else {
        response = [];
        this.initCart();
      }

      return response;
    },

    addCartItem: function(item) {
      let items;
      let cart = localStorage.getItem(this.store) || null;

      if (cart !== null) {
        items = JSON.parse(cart);
        items.push(item);
      } else {
        items = [item];
      }

      localStorage.setItem(this.store, JSON.stringify(items));

      this.show();
    },

    editCartItem: function(key, body) {
      let items;
      let cart = localStorage.getItem(this.store) || null;

      if (cart !== null) {
        items = JSON.parse(cart);

        if (typeof items[key] !== 'undefined') {
          if (body.amount > 0) {
            items[key] = body;

            localStorage.setItem(this.store, JSON.stringify(items));
          } else {
            this.deleteCartItem(key);
          }
        }
      }
    },

    deleteCartItem: function(key) {
      let items;
      let cart = localStorage.getItem(this.store) || null;

      if (cart !== null) {
        items = JSON.parse(cart);

        if (typeof items[key] !== 'undefined') {
          items.splice(key, 1);

          localStorage.setItem(this.store, JSON.stringify(items));
        }
      }
    }
  }
}
