module.exports = [
  // First Part
  {x: 116.0000, y: 187.0000},
  {x: 121.8756, y: 186.8966},
  {x: 128.9850, y: 185.4160},
  {x: 134.0000, y: 184.0000},
  {x: 150.3317, y: 183.3334},
  {x: 166.6683, y: 182.6666},
  {x: 183.0000, y: 182.0000},
  {x: 183.0000, y: 181.6667},
  {x: 183.0000, y: 181.3333},
  {x: 183.0000, y: 181.0000},
  {x: 187.9995, y: 181.0000},
  {x: 193.0005, y: 181.0000},
  {x: 198.0000, y: 181.0000},
  {x: 198.0000, y: 180.6667},
  {x: 198.0000, y: 180.3333},
  {x: 198.0000, y: 180.0000},
  {x: 198.9999, y: 180.0000},
  {x: 200.0001, y: 180.0000},
  {x: 201.0000, y: 180.0000},
  {x: 204.3330, y: 180.0000},
  {x: 207.6670, y: 180.0000},
  {x: 211.0000, y: 180.0000},
  {x: 211.0000, y: 179.6667},
  {x: 211.0000, y: 179.3333},
  {x: 211.0000, y: 179.0000},
  {x: 216.9994, y: 178.6667},
  {x: 223.0006, y: 178.3333},
  {x: 229.0000, y: 178.0000},
  {x: 232.3330, y: 178.0000},
  {x: 235.6670, y: 178.0000},
  {x: 239.0000, y: 178.0000},
  {x: 239.0000, y: 177.6667},
  {x: 239.0000, y: 177.3333},
  {x: 239.0000, y: 177.0000},
  {x: 247.9991, y: 177.0000},
  {x: 257.0009, y: 177.0000},
  {x: 266.0000, y: 177.0000},
  {x: 266.0000, y: 176.6667},
  {x: 266.0000, y: 176.3333},
  {x: 266.0000, y: 176.0000},
  {x: 268.9997, y: 176.0000},
  {x: 272.0003, y: 176.0000},
  {x: 275.0000, y: 176.0000},
  {x: 275.9999, y: 176.0000},
  {x: 277.0001, y: 176.0000},
  {x: 278.0000, y: 176.0000},
  {x: 278.0000, y: 175.6667},
  {x: 278.0000, y: 175.3333},
  {x: 278.0000, y: 175.0000},
  {x: 281.9996, y: 175.3333},
  {x: 286.0004, y: 175.6667},
  {x: 290.0000, y: 176.0000},
  {x: 289.6667, y: 172.0004},
  {x: 289.3333, y: 167.9996},
  {x: 289.0000, y: 164.0000},
  {x: 289.3333, y: 164.0000},
  {x: 289.6667, y: 164.0000},
  {x: 290.0000, y: 164.0000},
  {x: 290.0000, y: 162.0002},
  {x: 290.0000, y: 159.9998},
  {x: 290.0000, y: 158.0000},
  {x: 289.6667, y: 158.0000},
  {x: 289.3333, y: 158.0000},
  {x: 289.0000, y: 158.0000},
  {x: 289.0000, y: 155.6669},
  {x: 289.0000, y: 153.3331},
  {x: 289.0000, y: 151.0000},
  {x: 289.0000, y: 145.6672},
  {x: 289.0000, y: 140.3328},
  {x: 289.0000, y: 135.0000},
  {x: 288.6667, y: 135.0000},
  {x: 288.3333, y: 135.0000},
  {x: 288.0000, y: 135.0000},
  {x: 288.0000, y: 129.6672},
  {x: 288.0000, y: 124.3328},
  {x: 288.0000, y: 119.0000},
  {x: 287.6667, y: 119.0000},
  {x: 287.3333, y: 119.0000},
  {x: 287.0000, y: 119.0000},
  {x: 287.0000, y: 114.3338},
  {x: 287.0000, y: 109.6662},
  {x: 287.0000, y: 105.0000},
  {x: 286.6667, y: 105.0000},
  {x: 286.3333, y: 105.0000},
  {x: 286.0000, y: 105.0000},
  {x: 286.0000, y: 104.3334},
  {x: 286.0000, y: 103.6666},
  {x: 286.0000, y: 103.0000},
  {x: 285.3334, y: 102.6667},
  {x: 284.6666, y: 102.3333},
  {x: 284.0000, y: 102.0000},
  {x: 283.6667, y: 100.6668},
  {x: 283.3333, y: 99.3332},
  {x: 283.0000, y: 98.0000},
  {x: 282.3334, y: 97.6667},
  {x: 281.6666, y: 97.3333},
  {x: 281.0000, y: 97.0000},
  {x: 280.6667, y: 95.6668},
  {x: 280.3333, y: 94.3332},
  {x: 280.0000, y: 93.0000},
  {x: 279.3334, y: 92.6667},
  {x: 278.6666, y: 92.3333},
  {x: 278.0000, y: 92.0000},
  {x: 277.6667, y: 90.6668},
  {x: 277.3333, y: 89.3332},
  {x: 277.0000, y: 88.0000},
  {x: 276.3334, y: 87.6667},
  {x: 275.6666, y: 87.3333},
  {x: 275.0000, y: 87.0000},
  {x: 275.0000, y: 86.3334},
  {x: 275.0000, y: 85.6666},
  {x: 275.0000, y: 85.0000},
  {x: 274.6667, y: 85.0000},
  {x: 274.3333, y: 85.0000},
  {x: 274.0000, y: 85.0000},
  {x: 273.6667, y: 84.0001},
  {x: 273.3333, y: 82.9999},
  {x: 273.0000, y: 82.0000},
  {x: 272.3334, y: 81.6667},
  {x: 271.6666, y: 81.3333},
  {x: 271.0000, y: 81.0000},
  {x: 271.0000, y: 80.3334},
  {x: 271.0000, y: 79.6666},
  {x: 271.0000, y: 79.0000},
  {x: 270.3334, y: 78.6667},
  {x: 269.6666, y: 78.3333},
  {x: 269.0000, y: 78.0000},
  {x: 267.3335, y: 76.0002},
  {x: 265.6665, y: 73.9998},
  {x: 264.0000, y: 72.0000},
  {x: 262.0194, y: 72.9883},
  {x: 259.9332, y: 75.1203},
  {x: 258.0000, y: 76.0000},
  {x: 256.3335, y: 76.3333},
  {x: 254.6665, y: 76.6667},
  {x: 253.0000, y: 77.0000},
  {x: 253.0000, y: 77.3333},
  {x: 253.0000, y: 77.6667},
  {x: 253.0000, y: 78.0000},
  {x: 252.3334, y: 78.0000},
  {x: 251.6666, y: 78.0000},
  {x: 251.0000, y: 78.0000},
  {x: 251.0000, y: 78.3333},
  {x: 251.0000, y: 78.6667},
  {x: 251.0000, y: 79.0000},
  {x: 250.0001, y: 79.0000},
  {x: 248.9999, y: 79.0000},
  {x: 248.0000, y: 79.0000},
  {x: 248.0000, y: 79.3333},
  {x: 248.0000, y: 79.6667},
  {x: 248.0000, y: 80.0000},
  {x: 247.3334, y: 80.0000},
  {x: 246.6666, y: 80.0000},
  {x: 246.0000, y: 80.0000},
  {x: 246.0000, y: 80.3333},
  {x: 246.0000, y: 80.6667},
  {x: 246.0000, y: 81.0000},
  {x: 245.0001, y: 81.0000},
  {x: 243.9999, y: 81.0000},
  {x: 243.0000, y: 81.0000},
  {x: 243.0000, y: 81.3333},
  {x: 243.0000, y: 81.6667},
  {x: 243.0000, y: 82.0000},
  {x: 242.3334, y: 82.0000},
  {x: 241.6666, y: 82.0000},
  {x: 241.0000, y: 82.0000},
  {x: 241.0000, y: 82.3333},
  {x: 241.0000, y: 82.6667},
  {x: 241.0000, y: 83.0000},
  {x: 240.0001, y: 83.0000},
  {x: 238.9999, y: 83.0000},
  {x: 238.0000, y: 83.0000},
  {x: 238.0000, y: 83.3333},
  {x: 238.0000, y: 83.6667},
  {x: 238.0000, y: 84.0000},
  {x: 237.3334, y: 84.0000},
  {x: 236.6666, y: 84.0000},
  {x: 236.0000, y: 84.0000},
  {x: 236.0000, y: 84.3333},
  {x: 236.0000, y: 84.6667},
  {x: 236.0000, y: 85.0000},
  {x: 235.0001, y: 85.0000},
  {x: 233.9999, y: 85.0000},
  {x: 233.0000, y: 85.0000},
  {x: 233.0000, y: 85.3333},
  {x: 233.0000, y: 85.6667},
  {x: 233.0000, y: 86.0000},
  {x: 232.3334, y: 86.0000},
  {x: 231.6666, y: 86.0000},
  {x: 231.0000, y: 86.0000},
  {x: 231.0000, y: 86.3333},
  {x: 231.0000, y: 86.6667},
  {x: 231.0000, y: 87.0000},
  {x: 230.0001, y: 87.0000},
  {x: 228.9999, y: 87.0000},
  {x: 228.0000, y: 87.0000},
  {x: 228.0000, y: 87.3333},
  {x: 228.0000, y: 87.6667},
  {x: 228.0000, y: 88.0000},
  {x: 227.3334, y: 88.0000},
  {x: 226.6666, y: 88.0000},
  {x: 226.0000, y: 88.0000},
  {x: 226.0000, y: 88.3333},
  {x: 226.0000, y: 88.6667},
  {x: 226.0000, y: 89.0000},
  {x: 225.0001, y: 89.0000},
  {x: 223.9999, y: 89.0000},
  {x: 223.0000, y: 89.0000},
  {x: 223.0000, y: 89.3333},
  {x: 223.0000, y: 89.6667},
  {x: 223.0000, y: 90.0000},
  {x: 222.3334, y: 90.0000},
  {x: 221.6666, y: 90.0000},
  {x: 221.0000, y: 90.0000},
  {x: 221.0000, y: 90.3333},
  {x: 221.0000, y: 90.6667},
  {x: 221.0000, y: 91.0000},
  {x: 219.0002, y: 91.3333},
  {x: 216.9998, y: 91.6667},
  {x: 215.0000, y: 92.0000},
  {x: 215.0000, y: 92.3333},
  {x: 215.0000, y: 92.6667},
  {x: 215.0000, y: 93.0000},
  {x: 214.3334, y: 93.0000},
  {x: 213.6666, y: 93.0000},
  {x: 213.0000, y: 93.0000},
  {x: 213.0000, y: 93.3333},
  {x: 213.0000, y: 93.6667},
  {x: 213.0000, y: 94.0000},
  {x: 211.0002, y: 94.3333},
  {x: 208.9998, y: 94.6667},
  {x: 207.0000, y: 95.0000},
  {x: 207.0000, y: 95.3333},
  {x: 207.0000, y: 95.6667},
  {x: 207.0000, y: 96.0000},
  {x: 206.0001, y: 96.0000},
  {x: 204.9999, y: 96.0000},
  {x: 204.0000, y: 96.0000},
  {x: 204.0000, y: 96.3333},
  {x: 204.0000, y: 96.6667},
  {x: 204.0000, y: 97.0000},
  {x: 203.3334, y: 97.0000},
  {x: 202.6666, y: 97.0000},
  {x: 202.0000, y: 97.0000},
  {x: 202.0000, y: 97.3333},
  {x: 202.0000, y: 97.6667},
  {x: 202.0000, y: 98.0000},
  {x: 201.0001, y: 98.0000},
  {x: 199.9999, y: 98.0000},
  {x: 199.0000, y: 98.0000},
  {x: 199.0000, y: 98.3333},
  {x: 199.0000, y: 98.6667},
  {x: 199.0000, y: 99.0000},
  {x: 198.0001, y: 99.0000},
  {x: 196.9999, y: 99.0000},
  {x: 196.0000, y: 99.0000},
  {x: 196.0000, y: 99.3333},
  {x: 196.0000, y: 99.6667},
  {x: 196.0000, y: 100.0000},
  {x: 195.3334, y: 100.0000},
  {x: 194.6666, y: 100.0000},
  {x: 194.0000, y: 100.0000},
  {x: 194.0000, y: 100.3333},
  {x: 194.0000, y: 100.6667},
  {x: 194.0000, y: 101.0000},
  {x: 191.0003, y: 101.6666},
  {x: 187.9997, y: 102.3334},
  {x: 185.0000, y: 103.0000},
  {x: 185.0000, y: 103.3333},
  {x: 185.0000, y: 103.6667},
  {x: 185.0000, y: 104.0000},
  {x: 184.3334, y: 104.0000},
  {x: 183.6666, y: 104.0000},
  {x: 183.0000, y: 104.0000},
  {x: 183.0000, y: 104.3333},
  {x: 183.0000, y: 104.6667},
  {x: 183.0000, y: 105.0000},
  {x: 181.6668, y: 105.0000},
  {x: 180.3332, y: 105.0000},
  {x: 179.0000, y: 105.0000},
  {x: 150.5644, y: 116.4932},
  {x: 118.9082, y: 126.3138},
  {x: 86.0000, y: 133.0000},
  {x: 86.0000, y: 133.3333},
  {x: 86.0000, y: 133.6667},
  {x: 86.0000, y: 134.0000},
  {x: 86.3333, y: 134.0000},
  {x: 86.6667, y: 134.0000},
  {x: 87.0000, y: 134.0000},
  {x: 87.6656, y: 135.2762},
  {x: 88.5770, y: 135.3652},
  {x: 89.0000, y: 136.0000},
  {x: 89.0000, y: 136.6666},
  {x: 89.0000, y: 137.3334},
  {x: 89.0000, y: 138.0000},
  {x: 89.6666, y: 138.3333},
  {x: 90.3334, y: 138.6667},
  {x: 91.0000, y: 139.0000},
  {x: 91.0000, y: 139.6666},
  {x: 91.0000, y: 140.3334},
  {x: 91.0000, y: 141.0000},
  {x: 91.9999, y: 141.6666},
  {x: 93.0001, y: 142.3334},
  {x: 94.0000, y: 143.0000},
  {x: 94.0000, y: 143.6666},
  {x: 94.0000, y: 144.3334},
  {x: 94.0000, y: 145.0000},
  {x: 94.6666, y: 145.3333},
  {x: 95.3334, y: 145.6667},
  {x: 96.0000, y: 146.0000},
  {x: 96.3333, y: 147.3332},
  {x: 96.6667, y: 148.6668},
  {x: 97.0000, y: 150.0000},
  {x: 97.6666, y: 150.3333},
  {x: 98.3334, y: 150.6667},
  {x: 99.0000, y: 151.0000},
  {x: 99.0000, y: 151.6666},
  {x: 99.0000, y: 152.3334},
  {x: 99.0000, y: 153.0000},
  {x: 99.6666, y: 153.3333},
  {x: 100.3334, y: 153.6667},
  {x: 101.0000, y: 154.0000},
  {x: 101.6666, y: 155.9998},
  {x: 102.3334, y: 158.0002},
  {x: 103.0000, y: 160.0000},
  {x: 103.6666, y: 160.3333},
  {x: 104.3334, y: 160.6667},
  {x: 105.0000, y: 161.0000},
  {x: 105.6666, y: 162.9998},
  {x: 106.3334, y: 165.0002},
  {x: 107.0000, y: 167.0000},
  {x: 107.3333, y: 167.0000},
  {x: 107.6667, y: 167.0000},
  {x: 108.0000, y: 167.0000},
  {x: 108.3333, y: 168.3332},
  {x: 108.6667, y: 169.6668},
  {x: 109.0000, y: 171.0000},
  {x: 109.3333, y: 171.0000},
  {x: 109.6667, y: 171.0000},
  {x: 110.0000, y: 171.0000},
  {x: 110.3333, y: 172.3332},
  {x: 110.6667, y: 173.6668},
  {x: 111.0000, y: 175.0000},
  {x: 111.3333, y: 175.0000},
  {x: 111.6667, y: 175.0000},
  {x: 112.0000, y: 175.0000},
  {x: 112.0000, y: 175.9999},
  {x: 112.0000, y: 177.0001},
  {x: 112.0000, y: 178.0000},
  {x: 112.3333, y: 178.0000},
  {x: 112.6667, y: 178.0000},
  {x: 113.0000, y: 178.0000},
  {x: 113.9999, y: 180.9997},
  {x: 115.0001, y: 184.0003},
  {x: 116.0000, y: 187.0000},

  // Second Part
  {x: 56.0000, y: 103.0000},
  {x: 64.5308, y: 103.1863},
  {x: 73.2609, y: 101.0440},
  {x: 80.0000, y: 99.0000},
  {x: 81.9998, y: 99.0000},
  {x: 84.0002, y: 99.0000},
  {x: 86.0000, y: 99.0000},
  {x: 96.7365, y: 95.6762},
  {x: 110.5085, y: 94.3994},
  {x: 121.0000, y: 91.0000},
  {x: 122.6665, y: 91.0000},
  {x: 124.3335, y: 91.0000},
  {x: 126.0000, y: 91.0000},
  {x: 126.0000, y: 90.6667},
  {x: 126.0000, y: 90.3333},
  {x: 126.0000, y: 90.0000},
  {x: 127.6665, y: 90.0000},
  {x: 129.3335, y: 90.0000},
  {x: 131.0000, y: 90.0000},
  {x: 131.0000, y: 89.6667},
  {x: 131.0000, y: 89.3333},
  {x: 131.0000, y: 89.0000},
  {x: 132.3332, y: 89.0000},
  {x: 133.6668, y: 89.0000},
  {x: 135.0000, y: 89.0000},
  {x: 135.0000, y: 88.6667},
  {x: 135.0000, y: 88.3333},
  {x: 135.0000, y: 88.0000},
  {x: 136.3332, y: 88.0000},
  {x: 137.6668, y: 88.0000},
  {x: 139.0000, y: 88.0000},
  {x: 139.0000, y: 87.6667},
  {x: 139.0000, y: 87.3333},
  {x: 139.0000, y: 87.0000},
  {x: 140.6665, y: 87.0000},
  {x: 142.3335, y: 87.0000},
  {x: 144.0000, y: 87.0000},
  {x: 144.0000, y: 86.6667},
  {x: 144.0000, y: 86.3333},
  {x: 144.0000, y: 86.0000},
  {x: 150.6660, y: 84.6668},
  {x: 157.3340, y: 83.3332},
  {x: 164.0000, y: 82.0000},
  {x: 164.0000, y: 81.6667},
  {x: 164.0000, y: 81.3333},
  {x: 164.0000, y: 81.0000},
  {x: 165.3332, y: 81.0000},
  {x: 166.6668, y: 81.0000},
  {x: 168.0000, y: 81.0000},
  {x: 168.0000, y: 80.6667},
  {x: 168.0000, y: 80.3333},
  {x: 168.0000, y: 80.0000},
  {x: 169.3332, y: 80.0000},
  {x: 170.6668, y: 80.0000},
  {x: 172.0000, y: 80.0000},
  {x: 172.0000, y: 79.6667},
  {x: 172.0000, y: 79.3333},
  {x: 172.0000, y: 79.0000},
  {x: 173.3332, y: 79.0000},
  {x: 174.6668, y: 79.0000},
  {x: 176.0000, y: 79.0000},
  {x: 176.0000, y: 78.6667},
  {x: 176.0000, y: 78.3333},
  {x: 176.0000, y: 78.0000},
  {x: 177.3332, y: 78.0000},
  {x: 178.6668, y: 78.0000},
  {x: 180.0000, y: 78.0000},
  {x: 191.0464, y: 74.2116},
  {x: 204.1560, y: 70.9105},
  {x: 215.0000, y: 67.0000},
  {x: 216.3332, y: 67.0000},
  {x: 217.6668, y: 67.0000},
  {x: 219.0000, y: 67.0000},
  {x: 219.0000, y: 66.6667},
  {x: 219.0000, y: 66.3333},
  {x: 219.0000, y: 66.0000},
  {x: 219.9999, y: 66.0000},
  {x: 221.0001, y: 66.0000},
  {x: 222.0000, y: 66.0000},
  {x: 224.6664, y: 65.0001},
  {x: 227.3336, y: 63.9999},
  {x: 230.0000, y: 63.0000},
  {x: 230.0000, y: 62.6667},
  {x: 230.0000, y: 62.3333},
  {x: 230.0000, y: 62.0000},
  {x: 221.1775, y: 61.2753},
  {x: 217.8757, y: 55.0226},
  {x: 210.0000, y: 54.0000},
  {x: 208.0043, y: 56.3436},
  {x: 204.9823, y: 56.5472},
  {x: 202.0000, y: 58.0000},
  {x: 202.0000, y: 58.3333},
  {x: 202.0000, y: 58.6667},
  {x: 202.0000, y: 59.0000},
  {x: 201.0001, y: 59.0000},
  {x: 199.9999, y: 59.0000},
  {x: 199.0000, y: 59.0000},
  {x: 199.0000, y: 59.3333},
  {x: 199.0000, y: 59.6667},
  {x: 199.0000, y: 60.0000},
  {x: 198.3334, y: 60.0000},
  {x: 197.6666, y: 60.0000},
  {x: 197.0000, y: 60.0000},
  {x: 197.0000, y: 60.3333},
  {x: 197.0000, y: 60.6667},
  {x: 197.0000, y: 61.0000},
  {x: 196.0001, y: 61.0000},
  {x: 194.9999, y: 61.0000},
  {x: 194.0000, y: 61.0000},
  {x: 183.8546, y: 65.0032},
  {x: 166.3450, y: 67.6044},
  {x: 154.0000, y: 64.0000},
  {x: 152.0002, y: 64.0000},
  {x: 149.9998, y: 64.0000},
  {x: 148.0000, y: 64.0000},
  {x: 142.0006, y: 62.3335},
  {x: 135.9994, y: 60.6665},
  {x: 130.0000, y: 59.0000},
  {x: 130.0000, y: 58.6667},
  {x: 130.0000, y: 58.3333},
  {x: 130.0000, y: 58.0000},
  {x: 129.3334, y: 58.0000},
  {x: 128.6666, y: 58.0000},
  {x: 128.0000, y: 58.0000},
  {x: 128.0000, y: 57.6667},
  {x: 128.0000, y: 57.3333},
  {x: 128.0000, y: 57.0000},
  {x: 126.0002, y: 56.6667},
  {x: 123.9998, y: 56.3333},
  {x: 122.0000, y: 56.0000},
  {x: 122.0000, y: 55.6667},
  {x: 122.0000, y: 55.3333},
  {x: 122.0000, y: 55.0000},
  {x: 120.0002, y: 54.3334},
  {x: 117.9998, y: 53.6666},
  {x: 116.0000, y: 53.0000},
  {x: 116.0000, y: 52.6667},
  {x: 116.0000, y: 52.3333},
  {x: 116.0000, y: 52.0000},
  {x: 115.3334, y: 52.0000},
  {x: 114.6666, y: 52.0000},
  {x: 114.0000, y: 52.0000},
  {x: 114.0000, y: 51.6667},
  {x: 114.0000, y: 51.3333},
  {x: 114.0000, y: 51.0000},
  {x: 113.3334, y: 51.0000},
  {x: 112.6666, y: 51.0000},
  {x: 112.0000, y: 51.0000},
  {x: 112.0000, y: 50.6667},
  {x: 112.0000, y: 50.3333},
  {x: 112.0000, y: 50.0000},
  {x: 111.3334, y: 50.0000},
  {x: 110.6666, y: 50.0000},
  {x: 110.0000, y: 50.0000},
  {x: 110.0000, y: 49.6667},
  {x: 110.0000, y: 49.3333},
  {x: 110.0000, y: 49.0000},
  {x: 109.3334, y: 49.0000},
  {x: 108.6666, y: 49.0000},
  {x: 108.0000, y: 49.0000},
  {x: 108.0000, y: 48.6667},
  {x: 108.0000, y: 48.3333},
  {x: 108.0000, y: 48.0000},
  {x: 107.3334, y: 48.0000},
  {x: 106.6666, y: 48.0000},
  {x: 106.0000, y: 48.0000},
  {x: 106.0000, y: 47.6667},
  {x: 106.0000, y: 47.3333},
  {x: 106.0000, y: 47.0000},
  {x: 105.3334, y: 47.0000},
  {x: 104.6666, y: 47.0000},
  {x: 104.0000, y: 47.0000},
  {x: 104.0000, y: 46.6667},
  {x: 104.0000, y: 46.3333},
  {x: 104.0000, y: 46.0000},
  {x: 103.3334, y: 46.0000},
  {x: 102.6666, y: 46.0000},
  {x: 102.0000, y: 46.0000},
  {x: 101.6667, y: 45.3334},
  {x: 101.3333, y: 44.6666},
  {x: 101.0000, y: 44.0000},
  {x: 99.6668, y: 43.6667},
  {x: 98.3332, y: 43.3333},
  {x: 97.0000, y: 43.0000},
  {x: 97.0000, y: 42.6667},
  {x: 97.0000, y: 42.3333},
  {x: 97.0000, y: 42.0000},
  {x: 96.0001, y: 41.6667},
  {x: 94.9999, y: 41.3333},
  {x: 94.0000, y: 41.0000},
  {x: 93.6667, y: 40.3334},
  {x: 93.3333, y: 39.6666},
  {x: 93.0000, y: 39.0000},
  {x: 92.3334, y: 39.0000},
  {x: 91.6666, y: 39.0000},
  {x: 91.0000, y: 39.0000},
  {x: 90.6667, y: 38.3334},
  {x: 90.3333, y: 37.6666},
  {x: 90.0000, y: 37.0000},
  {x: 89.3334, y: 37.0000},
  {x: 88.6666, y: 37.0000},
  {x: 88.0000, y: 37.0000},
  {x: 87.3334, y: 36.0001},
  {x: 86.6666, y: 34.9999},
  {x: 86.0000, y: 34.0000},
  {x: 85.3334, y: 34.0000},
  {x: 84.6666, y: 34.0000},
  {x: 84.0000, y: 34.0000},
  {x: 82.6668, y: 32.3335},
  {x: 81.3332, y: 30.6665},
  {x: 80.0000, y: 29.0000},
  {x: 79.3334, y: 29.0000},
  {x: 78.6666, y: 29.0000},
  {x: 78.0000, y: 29.0000},
  {x: 76.0002, y: 26.6669},
  {x: 73.9998, y: 24.3331},
  {x: 72.0000, y: 22.0000},
  {x: 71.0001, y: 21.3334},
  {x: 69.9999, y: 20.6666},
  {x: 69.0000, y: 20.0000},
  {x: 69.0000, y: 19.3334},
  {x: 69.0000, y: 18.6666},
  {x: 69.0000, y: 18.0000},
  {x: 67.0002, y: 16.3335},
  {x: 64.9998, y: 14.6665},
  {x: 63.0000, y: 13.0000},
  {x: 63.0000, y: 12.3334},
  {x: 63.0000, y: 11.6666},
  {x: 63.0000, y: 11.0000},
  {x: 61.0002, y: 9.3335},
  {x: 58.9998, y: 7.6665},
  {x: 57.0000, y: 6.0000},
  {x: 56.6667, y: 5.3334},
  {x: 56.3333, y: 4.6666},
  {x: 56.0000, y: 4.0000},
  {x: 54.3319, y: 2.8440},
  {x: 53.7129, y: 4.0092},
  {x: 52.0000, y: 2.0000},
  {x: 49.7951, y: 2.7908},
  {x: 46.3610, y: 2.9594},
  {x: 44.0000, y: 4.0000},
  {x: 44.0000, y: 4.3333},
  {x: 44.0000, y: 4.6667},
  {x: 44.0000, y: 5.0000},
  {x: 42.6668, y: 5.3333},
  {x: 41.3332, y: 5.6667},
  {x: 40.0000, y: 6.0000},
  {x: 40.0000, y: 6.3333},
  {x: 40.0000, y: 6.6667},
  {x: 40.0000, y: 7.0000},
  {x: 38.6668, y: 7.3333},
  {x: 37.3332, y: 7.6667},
  {x: 36.0000, y: 8.0000},
  {x: 35.6667, y: 8.6666},
  {x: 35.3333, y: 9.3334},
  {x: 35.0000, y: 10.0000},
  {x: 33.0002, y: 10.6666},
  {x: 30.9998, y: 11.3334},
  {x: 29.0000, y: 12.0000},
  {x: 28.6667, y: 12.6666},
  {x: 28.3333, y: 13.3334},
  {x: 28.0000, y: 14.0000},
  {x: 27.3334, y: 14.0000},
  {x: 26.6666, y: 14.0000},
  {x: 26.0000, y: 14.0000},
  {x: 25.6667, y: 14.6666},
  {x: 25.3333, y: 15.3334},
  {x: 25.0000, y: 16.0000},
  {x: 24.3334, y: 16.0000},
  {x: 23.6666, y: 16.0000},
  {x: 23.0000, y: 16.0000},
  {x: 22.0001, y: 17.3332},
  {x: 20.9999, y: 18.6668},
  {x: 20.0000, y: 20.0000},
  {x: 19.3334, y: 20.0000},
  {x: 18.6666, y: 20.0000},
  {x: 18.0000, y: 20.0000},
  {x: 16.0002, y: 22.3331},
  {x: 13.9998, y: 24.6669},
  {x: 12.0000, y: 27.0000},
  {x: 10.6668, y: 27.9999},
  {x: 9.3332, y: 29.0001},
  {x: 8.0000, y: 30.0000},
  {x: 7.6667, y: 30.6666},
  {x: 7.3333, y: 31.3334},
  {x: 7.0000, y: 32.0000},
  {x: 6.3334, y: 32.0000},
  {x: 5.6666, y: 32.0000},
  {x: 5.0000, y: 32.0000},
  {x: 5.0000, y: 32.3333},
  {x: 5.0000, y: 32.6667},
  {x: 5.0000, y: 33.0000},
  {x: 2.3008, y: 34.7294},
  {x: 3.1451, y: 32.0330},
  {x: 2.0000, y: 36.0000},
  {x: 4.4786, y: 38.2060},
  {x: 4.2406, y: 41.1968},
  {x: 6.0000, y: 44.0000},
  {x: 6.6666, y: 44.3333},
  {x: 7.3334, y: 44.6667},
  {x: 8.0000, y: 45.0000},
  {x: 8.6666, y: 46.9998},
  {x: 9.3334, y: 49.0002},
  {x: 10.0000, y: 51.0000},
  {x: 10.6666, y: 51.3333},
  {x: 11.3334, y: 51.6667},
  {x: 12.0000, y: 52.0000},
  {x: 12.0000, y: 52.6666},
  {x: 12.0000, y: 53.3334},
  {x: 12.0000, y: 54.0000},
  {x: 12.6666, y: 54.3333},
  {x: 13.3334, y: 54.6667},
  {x: 14.0000, y: 55.0000},
  {x: 14.0000, y: 55.6666},
  {x: 14.0000, y: 56.3334},
  {x: 14.0000, y: 57.0000},
  {x: 14.6666, y: 57.3333},
  {x: 15.3334, y: 57.6667},
  {x: 16.0000, y: 58.0000},
  {x: 16.0000, y: 58.6666},
  {x: 16.0000, y: 59.3334},
  {x: 16.0000, y: 60.0000},
  {x: 16.3333, y: 60.0000},
  {x: 16.6667, y: 60.0000},
  {x: 17.0000, y: 60.0000},
  {x: 17.3333, y: 60.9999},
  {x: 17.6667, y: 62.0001},
  {x: 18.0000, y: 63.0000},
  {x: 18.6666, y: 63.3333},
  {x: 19.3334, y: 63.6667},
  {x: 20.0000, y: 64.0000},
  {x: 20.0000, y: 64.6666},
  {x: 20.0000, y: 65.3334},
  {x: 20.0000, y: 66.0000},
  {x: 20.9999, y: 66.6666},
  {x: 22.0001, y: 67.3334},
  {x: 23.0000, y: 68.0000},
  {x: 23.0000, y: 68.6666},
  {x: 23.0000, y: 69.3334},
  {x: 23.0000, y: 70.0000},
  {x: 23.9999, y: 70.6666},
  {x: 25.0001, y: 71.3334},
  {x: 26.0000, y: 72.0000},
  {x: 26.0000, y: 72.6666},
  {x: 26.0000, y: 73.3334},
  {x: 26.0000, y: 74.0000},
  {x: 27.6665, y: 75.3332},
  {x: 29.3335, y: 76.6668},
  {x: 31.0000, y: 78.0000},
  {x: 31.0000, y: 78.6666},
  {x: 31.0000, y: 79.3334},
  {x: 31.0000, y: 80.0000},
  {x: 32.9998, y: 81.6665},
  {x: 35.0002, y: 83.3335},
  {x: 37.0000, y: 85.0000},
  {x: 40.6663, y: 88.9996},
  {x: 44.3337, y: 93.0004},
  {x: 48.0000, y: 97.0000},
  {x: 50.3912, y: 99.3160},
  {x: 53.8615, y: 100.4291},
  {x: 56.0000, y: 103.0000}
];
