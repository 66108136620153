module.exports = [
  {x: 279.0000, y: 95.0000},
  {x: 305.5289, y: 95.2677},
  {x: 325.4787, y: 90.6872},
  {x: 335.0000, y: 74.0000},
  {x: 335.3333, y: 74.0000},
  {x: 335.6667, y: 74.0000},
  {x: 336.0000, y: 74.0000},
  {x: 337.2596, y: 71.7007},
  {x: 341.5460, y: 59.3926},
  {x: 340.0000, y: 55.0000},
  {x: 339.6667, y: 55.0000},
  {x: 339.3333, y: 55.0000},
  {x: 339.0000, y: 55.0000},
  {x: 339.0000, y: 54.3334},
  {x: 339.0000, y: 53.6666},
  {x: 339.0000, y: 53.0000},
  {x: 338.6667, y: 53.0000},
  {x: 338.3333, y: 53.0000},
  {x: 338.0000, y: 53.0000},
  {x: 338.0000, y: 51.6668},
  {x: 338.0000, y: 50.3332},
  {x: 338.0000, y: 49.0000},
  {x: 337.6667, y: 49.0000},
  {x: 337.3333, y: 49.0000},
  {x: 337.0000, y: 49.0000},
  {x: 337.0000, y: 48.0001},
  {x: 337.0000, y: 46.9999},
  {x: 337.0000, y: 46.0000},
  {x: 336.6667, y: 46.0000},
  {x: 336.3333, y: 46.0000},
  {x: 336.0000, y: 46.0000},
  {x: 336.0000, y: 45.3334},
  {x: 336.0000, y: 44.6666},
  {x: 336.0000, y: 44.0000},
  {x: 335.6667, y: 44.0000},
  {x: 335.3333, y: 44.0000},
  {x: 335.0000, y: 44.0000},
  {x: 335.0000, y: 43.3334},
  {x: 335.0000, y: 42.6666},
  {x: 335.0000, y: 42.0000},
  {x: 334.3334, y: 41.6667},
  {x: 333.6666, y: 41.3333},
  {x: 333.0000, y: 41.0000},
  {x: 333.0000, y: 40.3334},
  {x: 333.0000, y: 39.6666},
  {x: 333.0000, y: 39.0000},
  {x: 332.3334, y: 38.6667},
  {x: 331.6666, y: 38.3333},
  {x: 331.0000, y: 38.0000},
  {x: 329.0002, y: 35.6669},
  {x: 326.9998, y: 33.3331},
  {x: 325.0000, y: 31.0000},
  {x: 324.3334, y: 31.0000},
  {x: 323.6666, y: 31.0000},
  {x: 323.0000, y: 31.0000},
  {x: 322.0001, y: 29.6668},
  {x: 320.9999, y: 28.3332},
  {x: 320.0000, y: 27.0000},
  {x: 319.3334, y: 27.0000},
  {x: 318.6666, y: 27.0000},
  {x: 318.0000, y: 27.0000},
  {x: 317.3334, y: 26.0001},
  {x: 316.6666, y: 24.9999},
  {x: 316.0000, y: 24.0000},
  {x: 315.3334, y: 24.0000},
  {x: 314.6666, y: 24.0000},
  {x: 314.0000, y: 24.0000},
  {x: 313.6667, y: 23.3334},
  {x: 313.3333, y: 22.6666},
  {x: 313.0000, y: 22.0000},
  {x: 312.0001, y: 21.6667},
  {x: 310.9999, y: 21.3333},
  {x: 310.0000, y: 21.0000},
  {x: 310.0000, y: 20.6667},
  {x: 310.0000, y: 20.3333},
  {x: 310.0000, y: 20.0000},
  {x: 309.3334, y: 20.0000},
  {x: 308.6666, y: 20.0000},
  {x: 308.0000, y: 20.0000},
  {x: 307.6667, y: 19.3334},
  {x: 307.3333, y: 18.6666},
  {x: 307.0000, y: 18.0000},
  {x: 306.0001, y: 17.6667},
  {x: 304.9999, y: 17.3333},
  {x: 304.0000, y: 17.0000},
  {x: 304.0000, y: 16.6667},
  {x: 304.0000, y: 16.3333},
  {x: 304.0000, y: 16.0000},
  {x: 302.6668, y: 15.6667},
  {x: 301.3332, y: 15.3333},
  {x: 300.0000, y: 15.0000},
  {x: 299.6667, y: 14.3334},
  {x: 299.3333, y: 13.6666},
  {x: 299.0000, y: 13.0000},
  {x: 298.0001, y: 12.6667},
  {x: 296.9999, y: 12.3333},
  {x: 296.0000, y: 12.0000},
  {x: 296.0000, y: 11.6667},
  {x: 296.0000, y: 11.3333},
  {x: 296.0000, y: 11.0000},
  {x: 294.6668, y: 10.6667},
  {x: 293.3332, y: 10.3333},
  {x: 292.0000, y: 10.0000},
  {x: 291.6667, y: 9.3334},
  {x: 291.3333, y: 8.6666},
  {x: 291.0000, y: 8.0000},
  {x: 289.6668, y: 7.6667},
  {x: 288.3332, y: 7.3333},
  {x: 287.0000, y: 7.0000},
  {x: 286.6667, y: 6.3334},
  {x: 286.3333, y: 5.6666},
  {x: 286.0000, y: 5.0000},
  {x: 284.6668, y: 4.6667},
  {x: 283.3332, y: 4.3333},
  {x: 282.0000, y: 4.0000},
  {x: 281.6667, y: 3.3334},
  {x: 281.3333, y: 2.6666},
  {x: 281.0000, y: 2.0000},
  {x: 280.3334, y: 2.0000},
  {x: 279.6666, y: 2.0000},
  {x: 279.0000, y: 2.0000},
  {x: 279.0000, y: 1.6667},
  {x: 279.0000, y: 1.3333},
  {x: 279.0000, y: 1.0000},
  {x: 278.0001, y: 0.6667},
  {x: 276.9999, y: 0.3333},
  {x: 276.0000, y: 0.0000},
  {x: 276.0000, y: 0.3333},
  {x: 276.0000, y: 0.6667},
  {x: 276.0000, y: 1.0000},
  {x: 275.6667, y: 1.0000},
  {x: 275.3333, y: 1.0000},
  {x: 275.0000, y: 1.0000},
  {x: 275.0000, y: 2.9998},
  {x: 275.0000, y: 5.0002},
  {x: 275.0000, y: 7.0000},
  {x: 275.3333, y: 7.0000},
  {x: 275.6667, y: 7.0000},
  {x: 276.0000, y: 7.0000},
  {x: 276.3333, y: 9.3331},
  {x: 276.6667, y: 11.6669},
  {x: 277.0000, y: 14.0000},
  {x: 278.2390, y: 17.7149},
  {x: 281.9986, y: 27.7523},
  {x: 280.0000, y: 34.0000},
  {x: 279.6667, y: 34.0000},
  {x: 279.3333, y: 34.0000},
  {x: 279.0000, y: 34.0000},
  {x: 278.6667, y: 35.6665},
  {x: 278.3333, y: 37.3335},
  {x: 278.0000, y: 39.0000},
  {x: 277.0001, y: 39.6666},
  {x: 275.9999, y: 40.3334},
  {x: 275.0000, y: 41.0000},
  {x: 275.0000, y: 41.3333},
  {x: 275.0000, y: 41.6667},
  {x: 275.0000, y: 42.0000},
  {x: 274.3334, y: 42.0000},
  {x: 273.6666, y: 42.0000},
  {x: 273.0000, y: 42.0000},
  {x: 272.6667, y: 42.6666},
  {x: 272.3333, y: 43.3334},
  {x: 272.0000, y: 44.0000},
  {x: 270.0002, y: 44.6666},
  {x: 267.9998, y: 45.3334},
  {x: 266.0000, y: 46.0000},
  {x: 266.0000, y: 46.3333},
  {x: 266.0000, y: 46.6667},
  {x: 266.0000, y: 47.0000},
  {x: 265.0001, y: 47.0000},
  {x: 263.9999, y: 47.0000},
  {x: 263.0000, y: 47.0000},
  {x: 263.0000, y: 47.3333},
  {x: 263.0000, y: 47.6667},
  {x: 263.0000, y: 48.0000},
  {x: 261.6668, y: 48.0000},
  {x: 260.3332, y: 48.0000},
  {x: 259.0000, y: 48.0000},
  {x: 249.2822, y: 51.0761},
  {x: 230.8765, y: 49.8562},
  {x: 221.0000, y: 47.0000},
  {x: 218.3336, y: 47.0000},
  {x: 215.6664, y: 47.0000},
  {x: 213.0000, y: 47.0000},
  {x: 213.0000, y: 46.6667},
  {x: 213.0000, y: 46.3333},
  {x: 213.0000, y: 46.0000},
  {x: 211.0002, y: 46.0000},
  {x: 208.9998, y: 46.0000},
  {x: 207.0000, y: 46.0000},
  {x: 207.0000, y: 45.6667},
  {x: 207.0000, y: 45.3333},
  {x: 207.0000, y: 45.0000},
  {x: 205.0002, y: 45.0000},
  {x: 202.9998, y: 45.0000},
  {x: 201.0000, y: 45.0000},
  {x: 201.0000, y: 44.6667},
  {x: 201.0000, y: 44.3333},
  {x: 201.0000, y: 44.0000},
  {x: 198.6669, y: 44.0000},
  {x: 196.3331, y: 44.0000},
  {x: 194.0000, y: 44.0000},
  {x: 185.9134, y: 41.5622},
  {x: 175.0032, y: 40.4373},
  {x: 167.0000, y: 38.0000},
  {x: 165.0002, y: 38.0000},
  {x: 162.9998, y: 38.0000},
  {x: 161.0000, y: 38.0000},
  {x: 161.0000, y: 37.6667},
  {x: 161.0000, y: 37.3333},
  {x: 161.0000, y: 37.0000},
  {x: 159.3335, y: 37.0000},
  {x: 157.6665, y: 37.0000},
  {x: 156.0000, y: 37.0000},
  {x: 156.0000, y: 36.6667},
  {x: 156.0000, y: 36.3333},
  {x: 156.0000, y: 36.0000},
  {x: 154.6668, y: 36.0000},
  {x: 153.3332, y: 36.0000},
  {x: 152.0000, y: 36.0000},
  {x: 152.0000, y: 35.6667},
  {x: 152.0000, y: 35.3333},
  {x: 152.0000, y: 35.0000},
  {x: 150.3335, y: 35.0000},
  {x: 148.6665, y: 35.0000},
  {x: 147.0000, y: 35.0000},
  {x: 147.0000, y: 34.6667},
  {x: 147.0000, y: 34.3333},
  {x: 147.0000, y: 34.0000},
  {x: 145.3335, y: 34.0000},
  {x: 143.6665, y: 34.0000},
  {x: 142.0000, y: 34.0000},
  {x: 142.0000, y: 33.6667},
  {x: 142.0000, y: 33.3333},
  {x: 142.0000, y: 33.0000},
  {x: 140.6668, y: 33.0000},
  {x: 139.3332, y: 33.0000},
  {x: 138.0000, y: 33.0000},
  {x: 138.0000, y: 32.6667},
  {x: 138.0000, y: 32.3333},
  {x: 138.0000, y: 32.0000},
  {x: 136.3335, y: 32.0000},
  {x: 134.6665, y: 32.0000},
  {x: 133.0000, y: 32.0000},
  {x: 114.0270, y: 25.8731},
  {x: 92.3133, y: 22.5232},
  {x: 74.0000, y: 16.0000},
  {x: 72.6668, y: 16.0000},
  {x: 71.3332, y: 16.0000},
  {x: 70.0000, y: 16.0000},
  {x: 70.0000, y: 15.6667},
  {x: 70.0000, y: 15.3333},
  {x: 70.0000, y: 15.0000},
  {x: 68.6668, y: 15.0000},
  {x: 67.3332, y: 15.0000},
  {x: 66.0000, y: 15.0000},
  {x: 66.0000, y: 14.6667},
  {x: 66.0000, y: 14.3333},
  {x: 66.0000, y: 14.0000},
  {x: 65.0001, y: 14.0000},
  {x: 63.9999, y: 14.0000},
  {x: 63.0000, y: 14.0000},
  {x: 63.0000, y: 13.6667},
  {x: 63.0000, y: 13.3333},
  {x: 63.0000, y: 13.0000},
  {x: 61.6668, y: 13.0000},
  {x: 60.3332, y: 13.0000},
  {x: 59.0000, y: 13.0000},
  {x: 59.0000, y: 12.6667},
  {x: 59.0000, y: 12.3333},
  {x: 59.0000, y: 12.0000},
  {x: 56.6669, y: 11.6667},
  {x: 54.3331, y: 11.3333},
  {x: 52.0000, y: 11.0000},
  {x: 52.0000, y: 10.6667},
  {x: 52.0000, y: 10.3333},
  {x: 52.0000, y: 10.0000},
  {x: 51.0001, y: 10.0000},
  {x: 49.9999, y: 10.0000},
  {x: 49.0000, y: 10.0000},
  {x: 49.0000, y: 9.6667},
  {x: 49.0000, y: 9.3333},
  {x: 49.0000, y: 9.0000},
  {x: 46.6669, y: 8.6667},
  {x: 44.3331, y: 8.3333},
  {x: 42.0000, y: 8.0000},
  {x: 42.0000, y: 7.6667},
  {x: 42.0000, y: 7.3333},
  {x: 42.0000, y: 7.0000},
  {x: 40.0002, y: 6.6667},
  {x: 37.9998, y: 6.3333},
  {x: 36.0000, y: 6.0000},
  {x: 36.0000, y: 5.6667},
  {x: 36.0000, y: 5.3333},
  {x: 36.0000, y: 5.0000},
  {x: 34.3522, y: 4.5839},
  {x: 24.6530, y: 7.5194},
  {x: 23.0000, y: 8.0000},
  {x: 20.3336, y: 8.0000},
  {x: 17.6664, y: 8.0000},
  {x: 15.0000, y: 8.0000},
  {x: 9.8790, y: 9.5151},
  {x: 3.6479, y: 10.9598},
  {x: 1.0000, y: 15.0000},
  {x: 4.5237, y: 15.6314},
  {x: 6.4435, y: 17.6594},
  {x: 9.0000, y: 19.0000},
  {x: 9.6666, y: 19.0000},
  {x: 10.3334, y: 19.0000},
  {x: 11.0000, y: 19.0000},
  {x: 11.0000, y: 19.3333},
  {x: 11.0000, y: 19.6667},
  {x: 11.0000, y: 20.0000},
  {x: 11.9999, y: 20.0000},
  {x: 13.0001, y: 20.0000},
  {x: 14.0000, y: 20.0000},
  {x: 14.0000, y: 20.3333},
  {x: 14.0000, y: 20.6667},
  {x: 14.0000, y: 21.0000},
  {x: 15.3332, y: 21.3333},
  {x: 16.6668, y: 21.6667},
  {x: 18.0000, y: 22.0000},
  {x: 18.0000, y: 22.3333},
  {x: 18.0000, y: 22.6667},
  {x: 18.0000, y: 23.0000},
  {x: 18.9999, y: 23.0000},
  {x: 20.0001, y: 23.0000},
  {x: 21.0000, y: 23.0000},
  {x: 21.0000, y: 23.3333},
  {x: 21.0000, y: 23.6667},
  {x: 21.0000, y: 24.0000},
  {x: 21.6666, y: 24.0000},
  {x: 22.3334, y: 24.0000},
  {x: 23.0000, y: 24.0000},
  {x: 23.0000, y: 24.3333},
  {x: 23.0000, y: 24.6667},
  {x: 23.0000, y: 25.0000},
  {x: 23.9999, y: 25.0000},
  {x: 25.0001, y: 25.0000},
  {x: 26.0000, y: 25.0000},
  {x: 26.0000, y: 25.3333},
  {x: 26.0000, y: 25.6667},
  {x: 26.0000, y: 26.0000},
  {x: 26.6666, y: 26.0000},
  {x: 27.3334, y: 26.0000},
  {x: 28.0000, y: 26.0000},
  {x: 28.0000, y: 26.3333},
  {x: 28.0000, y: 26.6667},
  {x: 28.0000, y: 27.0000},
  {x: 28.9999, y: 27.0000},
  {x: 30.0001, y: 27.0000},
  {x: 31.0000, y: 27.0000},
  {x: 31.0000, y: 27.3333},
  {x: 31.0000, y: 27.6667},
  {x: 31.0000, y: 28.0000},
  {x: 31.6666, y: 28.0000},
  {x: 32.3334, y: 28.0000},
  {x: 33.0000, y: 28.0000},
  {x: 33.0000, y: 28.3333},
  {x: 33.0000, y: 28.6667},
  {x: 33.0000, y: 29.0000},
  {x: 33.9999, y: 29.0000},
  {x: 35.0001, y: 29.0000},
  {x: 36.0000, y: 29.0000},
  {x: 36.0000, y: 29.3333},
  {x: 36.0000, y: 29.6667},
  {x: 36.0000, y: 30.0000},
  {x: 36.6666, y: 30.0000},
  {x: 37.3334, y: 30.0000},
  {x: 38.0000, y: 30.0000},
  {x: 38.0000, y: 30.3333},
  {x: 38.0000, y: 30.6667},
  {x: 38.0000, y: 31.0000},
  {x: 39.6665, y: 31.3333},
  {x: 41.3335, y: 31.6667},
  {x: 43.0000, y: 32.0000},
  {x: 43.0000, y: 32.3333},
  {x: 43.0000, y: 32.6667},
  {x: 43.0000, y: 33.0000},
  {x: 44.9998, y: 33.3333},
  {x: 47.0002, y: 33.6667},
  {x: 49.0000, y: 34.0000},
  {x: 49.0000, y: 34.3333},
  {x: 49.0000, y: 34.6667},
  {x: 49.0000, y: 35.0000},
  {x: 49.6666, y: 35.0000},
  {x: 50.3334, y: 35.0000},
  {x: 51.0000, y: 35.0000},
  {x: 51.0000, y: 35.3333},
  {x: 51.0000, y: 35.6667},
  {x: 51.0000, y: 36.0000},
  {x: 52.9998, y: 36.3333},
  {x: 55.0002, y: 36.6667},
  {x: 57.0000, y: 37.0000},
  {x: 57.0000, y: 37.3333},
  {x: 57.0000, y: 37.6667},
  {x: 57.0000, y: 38.0000},
  {x: 57.6666, y: 38.0000},
  {x: 58.3334, y: 38.0000},
  {x: 59.0000, y: 38.0000},
  {x: 59.0000, y: 38.3333},
  {x: 59.0000, y: 38.6667},
  {x: 59.0000, y: 39.0000},
  {x: 61.9997, y: 39.6666},
  {x: 65.0003, y: 40.3334},
  {x: 68.0000, y: 41.0000},
  {x: 68.0000, y: 41.3333},
  {x: 68.0000, y: 41.6667},
  {x: 68.0000, y: 42.0000},
  {x: 68.6666, y: 42.0000},
  {x: 69.3334, y: 42.0000},
  {x: 70.0000, y: 42.0000},
  {x: 70.0000, y: 42.3333},
  {x: 70.0000, y: 42.6667},
  {x: 70.0000, y: 43.0000},
  {x: 72.9997, y: 43.6666},
  {x: 76.0003, y: 44.3334},
  {x: 79.0000, y: 45.0000},
  {x: 79.0000, y: 45.3333},
  {x: 79.0000, y: 45.6667},
  {x: 79.0000, y: 46.0000},
  {x: 79.6666, y: 46.0000},
  {x: 80.3334, y: 46.0000},
  {x: 81.0000, y: 46.0000},
  {x: 81.0000, y: 46.3333},
  {x: 81.0000, y: 46.6667},
  {x: 81.0000, y: 47.0000},
  {x: 82.3332, y: 47.0000},
  {x: 83.6668, y: 47.0000},
  {x: 85.0000, y: 47.0000},
  {x: 85.0000, y: 47.3333},
  {x: 85.0000, y: 47.6667},
  {x: 85.0000, y: 48.0000},
  {x: 85.6666, y: 48.0000},
  {x: 86.3334, y: 48.0000},
  {x: 87.0000, y: 48.0000},
  {x: 87.0000, y: 48.3333},
  {x: 87.0000, y: 48.6667},
  {x: 87.0000, y: 49.0000},
  {x: 87.9999, y: 49.0000},
  {x: 89.0001, y: 49.0000},
  {x: 90.0000, y: 49.0000},
  {x: 90.0000, y: 49.3333},
  {x: 90.0000, y: 49.6667},
  {x: 90.0000, y: 50.0000},
  {x: 90.9999, y: 50.0000},
  {x: 92.0001, y: 50.0000},
  {x: 93.0000, y: 50.0000},
  {x: 93.0000, y: 50.3333},
  {x: 93.0000, y: 50.6667},
  {x: 93.0000, y: 51.0000},
  {x: 93.9999, y: 51.0000},
  {x: 95.0001, y: 51.0000},
  {x: 96.0000, y: 51.0000},
  {x: 96.0000, y: 51.3333},
  {x: 96.0000, y: 51.6667},
  {x: 96.0000, y: 52.0000},
  {x: 96.9999, y: 52.0000},
  {x: 98.0001, y: 52.0000},
  {x: 99.0000, y: 52.0000},
  {x: 99.0000, y: 52.3333},
  {x: 99.0000, y: 52.6667},
  {x: 99.0000, y: 53.0000},
  {x: 99.9999, y: 53.0000},
  {x: 101.0001, y: 53.0000},
  {x: 102.0000, y: 53.0000},
  {x: 119.2284, y: 59.8893},
  {x: 138.9134, y: 64.8194},
  {x: 157.0000, y: 71.0000},
  {x: 158.6665, y: 71.0000},
  {x: 160.3335, y: 71.0000},
  {x: 162.0000, y: 71.0000},
  {x: 162.0000, y: 71.3333},
  {x: 162.0000, y: 71.6667},
  {x: 162.0000, y: 72.0000},
  {x: 164.9997, y: 72.3333},
  {x: 168.0003, y: 72.6667},
  {x: 171.0000, y: 73.0000},
  {x: 171.0000, y: 73.3333},
  {x: 171.0000, y: 73.6667},
  {x: 171.0000, y: 74.0000},
  {x: 172.3332, y: 74.0000},
  {x: 173.6668, y: 74.0000},
  {x: 175.0000, y: 74.0000},
  {x: 175.0000, y: 74.3333},
  {x: 175.0000, y: 74.6667},
  {x: 175.0000, y: 75.0000},
  {x: 177.9997, y: 75.3333},
  {x: 181.0003, y: 75.6667},
  {x: 184.0000, y: 76.0000},
  {x: 184.0000, y: 76.3333},
  {x: 184.0000, y: 76.6667},
  {x: 184.0000, y: 77.0000},
  {x: 186.9997, y: 77.3333},
  {x: 190.0003, y: 77.6667},
  {x: 193.0000, y: 78.0000},
  {x: 193.0000, y: 78.3333},
  {x: 193.0000, y: 78.6667},
  {x: 193.0000, y: 79.0000},
  {x: 195.9997, y: 79.3333},
  {x: 199.0003, y: 79.6667},
  {x: 202.0000, y: 80.0000},
  {x: 213.3136, y: 83.8551},
  {x: 227.3391, y: 85.4340},
  {x: 239.0000, y: 89.0000},
  {x: 240.9998, y: 89.0000},
  {x: 243.0002, y: 89.0000},
  {x: 245.0000, y: 89.0000},
  {x: 245.0000, y: 89.3333},
  {x: 245.0000, y: 89.6667},
  {x: 245.0000, y: 90.0000},
  {x: 246.9998, y: 90.0000},
  {x: 249.0002, y: 90.0000},
  {x: 251.0000, y: 90.0000},
  {x: 251.0000, y: 90.3333},
  {x: 251.0000, y: 90.6667},
  {x: 251.0000, y: 91.0000},
  {x: 252.9998, y: 91.0000},
  {x: 255.0002, y: 91.0000},
  {x: 257.0000, y: 91.0000},
  {x: 257.0000, y: 91.3333},
  {x: 257.0000, y: 91.6667},
  {x: 257.0000, y: 92.0000},
  {x: 259.3331, y: 92.0000},
  {x: 261.6669, y: 92.0000},
  {x: 264.0000, y: 92.0000},
  {x: 264.0000, y: 92.3333},
  {x: 264.0000, y: 92.6667},
  {x: 264.0000, y: 93.0000},
  {x: 269.2379, y: 94.5226},
  {x: 275.5152, y: 91.8089},
  {x: 279.0000, y: 95.0000}
];
