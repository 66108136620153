var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal text-font"},[_c('div',{staticClass:"text-font__container"},[_c('ButtonIcon',{staticClass:"text-font__close",attrs:{"type":"gray","shape":"circle"},on:{"click":function($event){return _vm.close()}}},[_c('CloseIcon',{staticClass:"close__icon"})],1),(_vm.textKey === null)?_c('span',{staticClass:"text-font__title"},[_vm._v(" Add Text ")]):_c('span',{staticClass:"text-font__title"},[_vm._v(" Edit Text ")]),_c('div',{staticClass:"text-font__value"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text.value),expression:"text.value"}],staticClass:"value__input",attrs:{"type":"text","maxlength":"24"},domProps:{"value":(_vm.text.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.text, "value", $event.target.value)}}}),_c('span',{staticClass:"value__characters"},[_vm._v(" "+_vm._s(_vm.text.value.length)+"/24 ")])]),_c('div',{staticClass:"text-font__fonts"},_vm._l((_vm.fonts),function(font){return _c('div',{class:[
          'fonts__item',
          font.key === _vm.text.font
            ? 'fonts__item--active'
            : null
        ],on:{"click":function($event){return _vm.selectFont(font.key)}}},[_c('span',{class:[
            'item__name',
            'item__name--' + font.key
          ]},[_vm._v(" "+_vm._s(font.name)+" ")])])}),0),_c('Button',{staticClass:"text-font__submit",attrs:{"type":"primary","shape":"square","value":"Apply"},on:{"click":function($event){return _vm.sendText()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }