var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sideDropdown",staticClass:"side-dropdown"},[_c('span',{staticClass:"side-dropdown__name"},[_vm._v(" "+_vm._s(_vm.options[_vm.side].name)+" ")]),_c('div',{class:[
      'side-dropdown__chevron',
      _vm.isOptions
        ? 'side-dropdown__chevron--active'
        : null
    ],on:{"click":function($event){return _vm.switchOptions()}}},[_c('ChevronDown',{staticClass:"chevron__icon"})],1),_c('div',{class:[
      'side-dropdown__options',
      _vm.isOptions
        ? 'side-dropdown__options--active'
        : null
    ]},_vm._l((_vm.options),function(option,key){return _c('div',{key:'side-dropdown-' + key,staticClass:"side-dropdown__option",on:{"click":function($event){return _vm.selectOption(key)}}},[_c('SneakerIcon',{staticClass:"option__icon",attrs:{"area":"full","side":key,"active":option.hasData}}),_c('span',{staticClass:"option__name"},[_vm._v(" "+_vm._s(option.name)+" ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }