import ChevronDown from '@/components/icons/chevronDown'

import vmodel from '@/mixins/vmodel.js'

export default {
  name: 'Select',

  mixins: [vmodel],

  components: {
    ChevronDown
  },

  props: {
    options: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      isOptions: false,
      currentOption: null
    }
  },

  methods: {
    switchOptions: function() {
      this.isOptions = !this.isOptions;
    },

    selectOption: function(option) {
      this.localValue = option;

      this.switchOptions();
    },

    closeOptions: function() {
      let self = this;
      let formSelect = this.$refs.formSelect;

      window.addEventListener('click', function(e) {
        if (typeof formSelect !== 'undefined' && !formSelect.contains(e.target)) {
          self.isOptions = false;
        }
      });
    }
  },

  mounted() {
    this.closeOptions();
  }
}
