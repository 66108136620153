export const Mixins = {
  methods: {
    bgImg: async function() {
      let containers = document.getElementsByClassName('bg-img');
      let numContainers = containers.length;

      if (numContainers) {
        for (let i = 0; i < numContainers; i++) {
          let container = containers[i];
          let img = container.querySelector('img');

          img.onload = await this.loadImg(container, img);
        }
			}
    },

    loadImg: async function(container, img) {
      let imgSrc = await img.getAttribute('src');
      let imgWidth = await img.width;
      let imgHeight = await img.height;

      let bgImgStyle = 'background-image: url(' + imgSrc + '); ' +
        'background-size: cover; ' +
        'background-position: center center; ' +
        'background-repeat: no-repeat; ';

      img.style.display = 'none';

      if (container.getAttribute('style') !== null) {
        await container.setAttribute('style', container.getAttribute('style') + bgImgStyle);
      } else {
        await container.setAttribute('style', bgImgStyle);
      }
    }
  },

  mounted: async function() {
    await this.bgImg();
  }
}
