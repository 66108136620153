var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'input',
    'input--' + _vm.size,
    'input--' + _vm.variant
  ]},[(((_vm.type === 'password' && _vm.isShow)
      ? 'text'
      : _vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"input__value",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):(((_vm.type === 'password' && _vm.isShow)
      ? 'text'
      : _vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"input__value",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"input__value",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":(_vm.type === 'password' && _vm.isShow)
      ? 'text'
      : _vm.type},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}}),(_vm.type === 'password' && !_vm.isShow)?_c('EyeIcon',{staticClass:"input__icon",on:{"click":function($event){return _vm.switchPassword()}}}):_vm._e(),(_vm.type === 'password' && _vm.isShow)?_c('EyeOffIcon',{staticClass:"input__icon",on:{"click":function($event){return _vm.switchPassword()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }