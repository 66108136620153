import CloseIcon from '@/components/icons/close'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'TextFont',

  components: {
    CloseIcon,

    Button,
    ButtonIcon
  },

  props: {
    textKey: {
      type: Number,
      default: null
    },

    text: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      fonts: [
        {
          key: 'streamster',
          name: 'Streamster'
        },
        {
          key: 'dharma',
          name: 'Dharma Gothic'
        },
        {
          key: 'millie',
          name: 'Millie Round'
        },
        {
          key: 'svang',
          name: 'Svang'
        },
        {
          key: 'burtons',
          name: 'Burtons Script'
        }
      ]
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideTextFont');
    },

    selectFont: function(font) {
      this.text.font = font;
    },

    sendText: function() {
      if (this.textKey === null) {
        this.$emit('putText', this.text);
      } else {
        this.$emit('updateText', this.textKey, this.text);
      }

      this.close();
    }
  }
}
