import axios from 'axios'
import api from '@/config/apiServer.js'

export default {
  methods: {
    getSizeItem: async function(id) {
      let response;

      await axios
        .get(api + '/size/' + id)
        .then(res => response = res)
        .catch(err => console.log(err));

      return (typeof response !== 'undefined' && response.status === 200)
        ? 'M ' + response.data.usa_man + ' / ' + 'W ' + response.data.usa_woman
        : null;
    },

    getSizeItems: async function() {
      let response;

      await axios
        .get(api + '/size')
        .then(res => response = res)
        .catch(err => console.log(err));

      return (typeof response !== 'undefined' && response.status === 200)
        ? response.data
        : null;
    },

    getSizeGuide: async function() {
      let response;

      await axios
        .get(api + '/size/chart/guide')
        .then(res => response = res)
        .catch(err => console.log(err));

      return (typeof response !== 'undefined' && response.status === 200)
        ? response.data
        : null;
    }
  }
}
