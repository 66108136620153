import Button from '@/components/form/button'

import size from '@/mixins/size'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'SidebarSize',

  mixins: [size],

  components: {
    Button
  },

  data() {
    return {
      itemId: null,
      items: []
    }
  },

  methods: {
    getItems: async function() {
      let response = await this.getSizeItems();

      if (response !== null) {
        this.items = response;
      } else {
        this.$toast.error('Error al obtener las tallas');
      }
    },

    selectItem: function(id) {
      this.itemId = id;
    },

    showSizeGuide: function() {
      EventBus.$emit('showSizeGuide');
    }
  },

  mounted() {
    this.getItems();
  },

  watch: {
    itemId: function(val) {
      this.$emit('change', val);
    }
  }
}
