import CloseIcon from '@/components/icons/close'
import DownloadIcon from '@/components/icons/download'
import CheckIcon from '@/components/icons/check'
import TrashIcon from '@/components/icons/trash'

import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import { Mixins } from '@/mixins/bgImg'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'VerifyNFT',

  mixins: [Mixins],

  components: {
    CloseIcon,
    DownloadIcon,
    CheckIcon,
    TrashIcon,

    Button,
    ButtonIcon
  },

  data() {
    return {
      step: 'load',
      steps: [
        'load',
        'success',
        'error'
      ],

      isPayload: false,
      percentage: 0,
      progress: null,

      file: null,
      image: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        src: '',
        file: null,
        visible: true,
        selected: false
      }
    }
  },

  computed: {
    progressStyle: function() {
      return 'width:' + this.percentage + '%';
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideVerifyNft');
    },

    loadImage: function(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        this.file = files[0];

        this.getImage();
      }
    },

    getImage: function() {
      let reader = new FileReader();

      reader.onload = (e) => {
        this.image.src = e.target.result;

        this.image.file = {
          type: this.file.type,
          name: this.file.name,
          size: this.file.size,
          lastModified: this.file.lastModified,
          lastModifiedDate: this.file.lastModifiedDate
        };

        this.showPayload();
      };

      reader.readAsDataURL(this.file);
    },

    doProgress: function() {
      this.percentage += 10;

      if (this.percentage == 100) {
        clearInterval(this.progress);

        setTimeout(() => this.sendImage(), 1000);
      }
    },

    showPayload: function() {
      this.isPayload = true;

      this.progress = setInterval(() => {
        this.doProgress();
      }, 150);

      setTimeout(() => this.bgImg(), 50);
    },

    sendImage: function() {
      this.step = 'success';

      this.$emit('putImage', this.image);
    }
  }
}
