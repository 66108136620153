import vmodel from '@/mixins/vmodel.js'

export default {
  name: 'Textarea',

  mixins: [vmodel],

  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (val) => [
        'primary',
        'secondary'
      ].includes(val)
    },
    
    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: null
    },

    readonly: {
      type: Boolean,
      default: null
    }
  }
}
