module.exports = [
  {x: 43.0000, y: 95.0000},
  {x: 52.3887, y: 95.2479},
  {x: 60.2258, y: 95.0080},
  {x: 67.0000, y: 93.0000},
  {x: 70.3330, y: 93.0000},
  {x: 73.6670, y: 93.0000},
  {x: 77.0000, y: 93.0000},
  {x: 82.5927, y: 91.3578},
  {x: 90.5241, y: 90.6487},
  {x: 96.0000, y: 89.0000},
  {x: 99.6663, y: 88.6667},
  {x: 103.3337, y: 88.3333},
  {x: 107.0000, y: 88.0000},
  {x: 118.2995, y: 84.4212},
  {x: 131.9552, y: 82.5730},
  {x: 143.0000, y: 79.0000},
  {x: 144.6665, y: 79.0000},
  {x: 146.3335, y: 79.0000},
  {x: 148.0000, y: 79.0000},
  {x: 148.0000, y: 78.6667},
  {x: 148.0000, y: 78.3333},
  {x: 148.0000, y: 78.0000},
  {x: 150.9997, y: 77.6667},
  {x: 154.0003, y: 77.3333},
  {x: 157.0000, y: 77.0000},
  {x: 157.0000, y: 76.6667},
  {x: 157.0000, y: 76.3333},
  {x: 157.0000, y: 76.0000},
  {x: 158.3332, y: 76.0000},
  {x: 159.6668, y: 76.0000},
  {x: 161.0000, y: 76.0000},
  {x: 161.0000, y: 75.6667},
  {x: 161.0000, y: 75.3333},
  {x: 161.0000, y: 75.0000},
  {x: 163.9997, y: 74.6667},
  {x: 167.0003, y: 74.3333},
  {x: 170.0000, y: 74.0000},
  {x: 170.0000, y: 73.6667},
  {x: 170.0000, y: 73.3333},
  {x: 170.0000, y: 73.0000},
  {x: 172.9997, y: 72.6667},
  {x: 176.0003, y: 72.3333},
  {x: 179.0000, y: 72.0000},
  {x: 179.0000, y: 71.6667},
  {x: 179.0000, y: 71.3333},
  {x: 179.0000, y: 71.0000},
  {x: 180.6665, y: 71.0000},
  {x: 182.3335, y: 71.0000},
  {x: 184.0000, y: 71.0000},
  {x: 202.0783, y: 64.7848},
  {x: 221.7583, y: 59.8736},
  {x: 239.0000, y: 53.0000},
  {x: 239.9999, y: 53.0000},
  {x: 241.0001, y: 53.0000},
  {x: 242.0000, y: 53.0000},
  {x: 242.0000, y: 52.6667},
  {x: 242.0000, y: 52.3333},
  {x: 242.0000, y: 52.0000},
  {x: 242.9999, y: 52.0000},
  {x: 244.0001, y: 52.0000},
  {x: 245.0000, y: 52.0000},
  {x: 245.0000, y: 51.6667},
  {x: 245.0000, y: 51.3333},
  {x: 245.0000, y: 51.0000},
  {x: 245.9999, y: 51.0000},
  {x: 247.0001, y: 51.0000},
  {x: 248.0000, y: 51.0000},
  {x: 248.0000, y: 50.6667},
  {x: 248.0000, y: 50.3333},
  {x: 248.0000, y: 50.0000},
  {x: 248.9999, y: 50.0000},
  {x: 250.0001, y: 50.0000},
  {x: 251.0000, y: 50.0000},
  {x: 251.0000, y: 49.6667},
  {x: 251.0000, y: 49.3333},
  {x: 251.0000, y: 49.0000},
  {x: 251.9999, y: 49.0000},
  {x: 253.0001, y: 49.0000},
  {x: 254.0000, y: 49.0000},
  {x: 254.0000, y: 48.6667},
  {x: 254.0000, y: 48.3333},
  {x: 254.0000, y: 48.0000},
  {x: 254.6666, y: 48.0000},
  {x: 255.3334, y: 48.0000},
  {x: 256.0000, y: 48.0000},
  {x: 256.0000, y: 47.6667},
  {x: 256.0000, y: 47.3333},
  {x: 256.0000, y: 47.0000},
  {x: 257.3332, y: 47.0000},
  {x: 258.6668, y: 47.0000},
  {x: 260.0000, y: 47.0000},
  {x: 260.0000, y: 46.6667},
  {x: 260.0000, y: 46.3333},
  {x: 260.0000, y: 46.0000},
  {x: 260.6666, y: 46.0000},
  {x: 261.3334, y: 46.0000},
  {x: 262.0000, y: 46.0000},
  {x: 262.0000, y: 45.6667},
  {x: 262.0000, y: 45.3333},
  {x: 262.0000, y: 45.0000},
  {x: 264.9997, y: 44.3334},
  {x: 268.0003, y: 43.6666},
  {x: 271.0000, y: 43.0000},
  {x: 271.0000, y: 42.6667},
  {x: 271.0000, y: 42.3333},
  {x: 271.0000, y: 42.0000},
  {x: 271.6666, y: 42.0000},
  {x: 272.3334, y: 42.0000},
  {x: 273.0000, y: 42.0000},
  {x: 273.0000, y: 41.6667},
  {x: 273.0000, y: 41.3333},
  {x: 273.0000, y: 41.0000},
  {x: 275.9997, y: 40.3334},
  {x: 279.0003, y: 39.6666},
  {x: 282.0000, y: 39.0000},
  {x: 282.0000, y: 38.6667},
  {x: 282.0000, y: 38.3333},
  {x: 282.0000, y: 38.0000},
  {x: 282.6666, y: 38.0000},
  {x: 283.3334, y: 38.0000},
  {x: 284.0000, y: 38.0000},
  {x: 284.0000, y: 37.6667},
  {x: 284.0000, y: 37.3333},
  {x: 284.0000, y: 37.0000},
  {x: 285.9998, y: 36.6667},
  {x: 288.0002, y: 36.3333},
  {x: 290.0000, y: 36.0000},
  {x: 290.0000, y: 35.6667},
  {x: 290.0000, y: 35.3333},
  {x: 290.0000, y: 35.0000},
  {x: 290.6666, y: 35.0000},
  {x: 291.3334, y: 35.0000},
  {x: 292.0000, y: 35.0000},
  {x: 292.0000, y: 34.6667},
  {x: 292.0000, y: 34.3333},
  {x: 292.0000, y: 34.0000},
  {x: 293.9998, y: 33.6667},
  {x: 296.0002, y: 33.3333},
  {x: 298.0000, y: 33.0000},
  {x: 298.0000, y: 32.6667},
  {x: 298.0000, y: 32.3333},
  {x: 298.0000, y: 32.0000},
  {x: 298.6666, y: 32.0000},
  {x: 299.3334, y: 32.0000},
  {x: 300.0000, y: 32.0000},
  {x: 300.0000, y: 31.6667},
  {x: 300.0000, y: 31.3333},
  {x: 300.0000, y: 31.0000},
  {x: 300.9999, y: 31.0000},
  {x: 302.0001, y: 31.0000},
  {x: 303.0000, y: 31.0000},
  {x: 303.0000, y: 30.6667},
  {x: 303.0000, y: 30.3333},
  {x: 303.0000, y: 30.0000},
  {x: 303.6666, y: 30.0000},
  {x: 304.3334, y: 30.0000},
  {x: 305.0000, y: 30.0000},
  {x: 305.0000, y: 29.6667},
  {x: 305.0000, y: 29.3333},
  {x: 305.0000, y: 29.0000},
  {x: 306.6665, y: 28.6667},
  {x: 308.3335, y: 28.3333},
  {x: 310.0000, y: 28.0000},
  {x: 310.0000, y: 27.6667},
  {x: 310.0000, y: 27.3333},
  {x: 310.0000, y: 27.0000},
  {x: 310.9999, y: 27.0000},
  {x: 312.0001, y: 27.0000},
  {x: 313.0000, y: 27.0000},
  {x: 313.0000, y: 26.6667},
  {x: 313.0000, y: 26.3333},
  {x: 313.0000, y: 26.0000},
  {x: 313.6666, y: 26.0000},
  {x: 314.3334, y: 26.0000},
  {x: 315.0000, y: 26.0000},
  {x: 315.0000, y: 25.6667},
  {x: 315.0000, y: 25.3333},
  {x: 315.0000, y: 25.0000},
  {x: 315.9999, y: 25.0000},
  {x: 317.0001, y: 25.0000},
  {x: 318.0000, y: 25.0000},
  {x: 318.0000, y: 24.6667},
  {x: 318.0000, y: 24.3333},
  {x: 318.0000, y: 24.0000},
  {x: 318.6666, y: 24.0000},
  {x: 319.3334, y: 24.0000},
  {x: 320.0000, y: 24.0000},
  {x: 320.0000, y: 23.6667},
  {x: 320.0000, y: 23.3333},
  {x: 320.0000, y: 23.0000},
  {x: 320.9999, y: 23.0000},
  {x: 322.0001, y: 23.0000},
  {x: 323.0000, y: 23.0000},
  {x: 323.0000, y: 22.6667},
  {x: 323.0000, y: 22.3333},
  {x: 323.0000, y: 22.0000},
  {x: 323.6666, y: 22.0000},
  {x: 324.3334, y: 22.0000},
  {x: 325.0000, y: 22.0000},
  {x: 325.0000, y: 21.6667},
  {x: 325.0000, y: 21.3333},
  {x: 325.0000, y: 21.0000},
  {x: 326.6665, y: 20.6667},
  {x: 328.3335, y: 20.3333},
  {x: 330.0000, y: 20.0000},
  {x: 330.0000, y: 19.6667},
  {x: 330.0000, y: 19.3333},
  {x: 330.0000, y: 19.0000},
  {x: 330.6666, y: 19.0000},
  {x: 331.3334, y: 19.0000},
  {x: 332.0000, y: 19.0000},
  {x: 334.6041, y: 17.6471},
  {x: 336.4951, y: 15.8118},
  {x: 340.0000, y: 15.0000},
  {x: 339.1558, y: 13.8654},
  {x: 340.1268, y: 14.8546},
  {x: 339.0000, y: 14.0000},
  {x: 336.3202, y: 10.6564},
  {x: 330.5936, y: 9.3488},
  {x: 326.0000, y: 8.0000},
  {x: 323.3336, y: 8.0000},
  {x: 320.6664, y: 8.0000},
  {x: 318.0000, y: 8.0000},
  {x: 316.3321, y: 7.5353},
  {x: 306.6427, y: 4.5734},
  {x: 305.0000, y: 5.0000},
  {x: 305.0000, y: 5.3333},
  {x: 305.0000, y: 5.6667},
  {x: 305.0000, y: 6.0000},
  {x: 302.0003, y: 6.6666},
  {x: 298.9997, y: 7.3334},
  {x: 296.0000, y: 8.0000},
  {x: 296.0000, y: 8.3333},
  {x: 296.0000, y: 8.6667},
  {x: 296.0000, y: 9.0000},
  {x: 294.6668, y: 9.0000},
  {x: 293.3332, y: 9.0000},
  {x: 292.0000, y: 9.0000},
  {x: 269.7291, y: 17.0636},
  {x: 244.0813, y: 21.5285},
  {x: 221.0000, y: 29.0000},
  {x: 219.3335, y: 29.0000},
  {x: 217.6665, y: 29.0000},
  {x: 216.0000, y: 29.0000},
  {x: 216.0000, y: 29.3333},
  {x: 216.0000, y: 29.6667},
  {x: 216.0000, y: 30.0000},
  {x: 214.6668, y: 30.0000},
  {x: 213.3332, y: 30.0000},
  {x: 212.0000, y: 30.0000},
  {x: 212.0000, y: 30.3333},
  {x: 212.0000, y: 30.6667},
  {x: 212.0000, y: 31.0000},
  {x: 210.6668, y: 31.0000},
  {x: 209.3332, y: 31.0000},
  {x: 208.0000, y: 31.0000},
  {x: 208.0000, y: 31.3333},
  {x: 208.0000, y: 31.6667},
  {x: 208.0000, y: 32.0000},
  {x: 205.0003, y: 32.3333},
  {x: 201.9997, y: 32.6667},
  {x: 199.0000, y: 33.0000},
  {x: 199.0000, y: 33.3333},
  {x: 199.0000, y: 33.6667},
  {x: 199.0000, y: 34.0000},
  {x: 197.3335, y: 34.0000},
  {x: 195.6665, y: 34.0000},
  {x: 194.0000, y: 34.0000},
  {x: 194.0000, y: 34.3333},
  {x: 194.0000, y: 34.6667},
  {x: 194.0000, y: 35.0000},
  {x: 191.0003, y: 35.3333},
  {x: 187.9997, y: 35.6667},
  {x: 185.0000, y: 36.0000},
  {x: 185.0000, y: 36.3333},
  {x: 185.0000, y: 36.6667},
  {x: 185.0000, y: 37.0000},
  {x: 181.3337, y: 37.3333},
  {x: 177.6663, y: 37.6667},
  {x: 174.0000, y: 38.0000},
  {x: 161.8805, y: 41.6646},
  {x: 146.3919, y: 42.2939},
  {x: 134.0000, y: 46.0000},
  {x: 129.3338, y: 46.3333},
  {x: 124.6662, y: 46.6667},
  {x: 120.0000, y: 47.0000},
  {x: 120.0000, y: 47.3333},
  {x: 120.0000, y: 47.6667},
  {x: 120.0000, y: 48.0000},
  {x: 117.3336, y: 48.0000},
  {x: 114.6664, y: 48.0000},
  {x: 112.0000, y: 48.0000},
  {x: 112.0000, y: 48.3333},
  {x: 112.0000, y: 48.6667},
  {x: 112.0000, y: 49.0000},
  {x: 108.0004, y: 49.0000},
  {x: 103.9996, y: 49.0000},
  {x: 100.0000, y: 49.0000},
  {x: 100.0000, y: 49.3333},
  {x: 100.0000, y: 49.6667},
  {x: 100.0000, y: 50.0000},
  {x: 95.3695, y: 51.3149},
  {x: 77.8708, y: 48.2587},
  {x: 75.0000, y: 47.0000},
  {x: 75.0000, y: 46.6667},
  {x: 75.0000, y: 46.3333},
  {x: 75.0000, y: 46.0000},
  {x: 74.3334, y: 46.0000},
  {x: 73.6666, y: 46.0000},
  {x: 73.0000, y: 46.0000},
  {x: 73.0000, y: 45.6667},
  {x: 73.0000, y: 45.3333},
  {x: 73.0000, y: 45.0000},
  {x: 72.3334, y: 45.0000},
  {x: 71.6666, y: 45.0000},
  {x: 71.0000, y: 45.0000},
  {x: 71.0000, y: 44.6667},
  {x: 71.0000, y: 44.3333},
  {x: 71.0000, y: 44.0000},
  {x: 67.3363, y: 41.9271},
  {x: 64.2280, y: 41.5493},
  {x: 63.0000, y: 37.0000},
  {x: 61.3874, y: 35.4281},
  {x: 59.7768, y: 27.1095},
  {x: 61.0000, y: 23.0000},
  {x: 61.3333, y: 23.0000},
  {x: 61.6667, y: 23.0000},
  {x: 62.0000, y: 23.0000},
  {x: 62.0000, y: 21.3335},
  {x: 62.0000, y: 19.6665},
  {x: 62.0000, y: 18.0000},
  {x: 63.7712, y: 12.5343},
  {x: 65.7295, y: 8.2809},
  {x: 66.0000, y: 1.0000},
  {x: 65.6667, y: 1.0000},
  {x: 65.3333, y: 1.0000},
  {x: 65.0000, y: 1.0000},
  {x: 65.0000, y: 0.6667},
  {x: 65.0000, y: 0.3333},
  {x: 65.0000, y: 0.0000},
  {x: 64.3334, y: 0.0000},
  {x: 63.6666, y: 0.0000},
  {x: 63.0000, y: 0.0000},
  {x: 61.9945, y: 1.5048},
  {x: 58.5515, y: 4.0983},
  {x: 57.0000, y: 5.0000},
  {x: 56.3334, y: 5.0000},
  {x: 55.6666, y: 5.0000},
  {x: 55.0000, y: 5.0000},
  {x: 54.6667, y: 5.6666},
  {x: 54.3333, y: 6.3334},
  {x: 54.0000, y: 7.0000},
  {x: 53.3334, y: 7.0000},
  {x: 52.6666, y: 7.0000},
  {x: 52.0000, y: 7.0000},
  {x: 52.0000, y: 7.3333},
  {x: 52.0000, y: 7.6667},
  {x: 52.0000, y: 8.0000},
  {x: 51.3334, y: 8.0000},
  {x: 50.6666, y: 8.0000},
  {x: 50.0000, y: 8.0000},
  {x: 49.6667, y: 8.6666},
  {x: 49.3333, y: 9.3334},
  {x: 49.0000, y: 10.0000},
  {x: 47.6668, y: 10.3333},
  {x: 46.3332, y: 10.6667},
  {x: 45.0000, y: 11.0000},
  {x: 45.0000, y: 11.3333},
  {x: 45.0000, y: 11.6667},
  {x: 45.0000, y: 12.0000},
  {x: 44.0001, y: 12.3333},
  {x: 42.9999, y: 12.6667},
  {x: 42.0000, y: 13.0000},
  {x: 41.6667, y: 13.6666},
  {x: 41.3333, y: 14.3334},
  {x: 41.0000, y: 15.0000},
  {x: 39.6668, y: 15.3333},
  {x: 38.3332, y: 15.6667},
  {x: 37.0000, y: 16.0000},
  {x: 37.0000, y: 16.3333},
  {x: 37.0000, y: 16.6667},
  {x: 37.0000, y: 17.0000},
  {x: 36.0001, y: 17.3333},
  {x: 34.9999, y: 17.6667},
  {x: 34.0000, y: 18.0000},
  {x: 33.6667, y: 18.6666},
  {x: 33.3333, y: 19.3334},
  {x: 33.0000, y: 20.0000},
  {x: 32.3334, y: 20.0000},
  {x: 31.6666, y: 20.0000},
  {x: 31.0000, y: 20.0000},
  {x: 30.6667, y: 20.6666},
  {x: 30.3333, y: 21.3334},
  {x: 30.0000, y: 22.0000},
  {x: 29.3334, y: 22.0000},
  {x: 28.6666, y: 22.0000},
  {x: 28.0000, y: 22.0000},
  {x: 27.6667, y: 22.6666},
  {x: 27.3333, y: 23.3334},
  {x: 27.0000, y: 24.0000},
  {x: 26.3334, y: 24.0000},
  {x: 25.6666, y: 24.0000},
  {x: 25.0000, y: 24.0000},
  {x: 24.3334, y: 24.9999},
  {x: 23.6666, y: 26.0001},
  {x: 23.0000, y: 27.0000},
  {x: 22.3334, y: 27.0000},
  {x: 21.6666, y: 27.0000},
  {x: 21.0000, y: 27.0000},
  {x: 20.0001, y: 28.3332},
  {x: 18.9999, y: 29.6668},
  {x: 18.0000, y: 31.0000},
  {x: 17.3334, y: 31.0000},
  {x: 16.6666, y: 31.0000},
  {x: 16.0000, y: 31.0000},
  {x: 12.6670, y: 34.6663},
  {x: 9.3330, y: 38.3337},
  {x: 6.0000, y: 42.0000},
  {x: 5.6667, y: 43.3332},
  {x: 5.3333, y: 44.6668},
  {x: 5.0000, y: 46.0000},
  {x: 3.5500, y: 48.9756},
  {x: 0.0000, y: 58.4162},
  {x: 1.0000, y: 64.0000},
  {x: 1.3333, y: 64.0000},
  {x: 1.6667, y: 64.0000},
  {x: 2.0000, y: 64.0000},
  {x: 2.0000, y: 64.6666},
  {x: 2.0000, y: 65.3334},
  {x: 2.0000, y: 66.0000},
  {x: 2.3333, y: 66.0000},
  {x: 2.6667, y: 66.0000},
  {x: 3.0000, y: 66.0000},
  {x: 3.0000, y: 67.3332},
  {x: 3.0000, y: 68.6668},
  {x: 3.0000, y: 70.0000},
  {x: 3.3333, y: 70.0000},
  {x: 3.6667, y: 70.0000},
  {x: 4.0000, y: 70.0000},
  {x: 4.3333, y: 71.3332},
  {x: 4.6667, y: 72.6668},
  {x: 5.0000, y: 74.0000},
  {x: 5.3333, y: 74.0000},
  {x: 5.6667, y: 74.0000},
  {x: 6.0000, y: 74.0000},
  {x: 6.0000, y: 74.6666},
  {x: 6.0000, y: 75.3334},
  {x: 6.0000, y: 76.0000},
  {x: 6.9999, y: 76.6666},
  {x: 8.0001, y: 77.3334},
  {x: 9.0000, y: 78.0000},
  {x: 9.0000, y: 78.6666},
  {x: 9.0000, y: 79.3334},
  {x: 9.0000, y: 80.0000},
  {x: 9.6666, y: 80.3333},
  {x: 10.3334, y: 80.6667},
  {x: 11.0000, y: 81.0000},
  {x: 11.0000, y: 81.3333},
  {x: 11.0000, y: 81.6667},
  {x: 11.0000, y: 82.0000},
  {x: 11.6666, y: 82.0000},
  {x: 12.3334, y: 82.0000},
  {x: 13.0000, y: 82.0000},
  {x: 13.6666, y: 82.9999},
  {x: 14.3334, y: 84.0001},
  {x: 15.0000, y: 85.0000},
  {x: 15.6666, y: 85.0000},
  {x: 16.3334, y: 85.0000},
  {x: 17.0000, y: 85.0000},
  {x: 17.3333, y: 85.6666},
  {x: 17.6667, y: 86.3334},
  {x: 18.0000, y: 87.0000},
  {x: 18.6666, y: 87.0000},
  {x: 19.3334, y: 87.0000},
  {x: 20.0000, y: 87.0000},
  {x: 20.0000, y: 87.3333},
  {x: 20.0000, y: 87.6667},
  {x: 20.0000, y: 88.0000},
  {x: 21.3332, y: 88.3333},
  {x: 22.6668, y: 88.6667},
  {x: 24.0000, y: 89.0000},
  {x: 24.0000, y: 89.3333},
  {x: 24.0000, y: 89.6667},
  {x: 24.0000, y: 90.0000},
  {x: 25.9998, y: 90.3333},
  {x: 28.0002, y: 90.6667},
  {x: 30.0000, y: 91.0000},
  {x: 30.0000, y: 91.3333},
  {x: 30.0000, y: 91.6667},
  {x: 30.0000, y: 92.0000},
  {x: 34.3329, y: 92.6666},
  {x: 38.6671, y: 93.3334},
  {x: 43.0000, y: 94.0000},
  {x: 43.0000, y: 94.3333},
  {x: 43.0000, y: 94.6667},
  {x: 43.0000, y: 95.0000}
];
