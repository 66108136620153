module.exports = [
  {x: 694.0000, y: 197.0000},
  {x: 694.0000, y: 195.6668},
  {x: 694.0000, y: 194.3332},
  {x: 694.0000, y: 193.0000},
  {x: 693.6667, y: 193.0000},
  {x: 693.3333, y: 193.0000},
  {x: 693.0000, y: 193.0000},
  {x: 693.0000, y: 185.0008},
  {x: 693.0000, y: 176.9992},
  {x: 693.0000, y: 169.0000},
  {x: 693.0000, y: 164.0005},
  {x: 693.0000, y: 158.9995},
  {x: 693.0000, y: 154.0000},
  {x: 692.6667, y: 154.0000},
  {x: 692.3333, y: 154.0000},
  {x: 692.0000, y: 154.0000},
  {x: 692.0000, y: 148.6672},
  {x: 692.0000, y: 143.3328},
  {x: 692.0000, y: 138.0000},
  {x: 691.6667, y: 138.0000},
  {x: 691.3333, y: 138.0000},
  {x: 691.0000, y: 138.0000},
  {x: 691.3333, y: 129.6675},
  {x: 691.6667, y: 121.3325},
  {x: 692.0000, y: 113.0000},
  {x: 691.6667, y: 113.0000},
  {x: 691.3333, y: 113.0000},
  {x: 691.0000, y: 113.0000},
  {x: 691.0000, y: 110.3336},
  {x: 691.0000, y: 107.6664},
  {x: 691.0000, y: 105.0000},
  {x: 690.6667, y: 105.0000},
  {x: 690.3333, y: 105.0000},
  {x: 690.0000, y: 105.0000},
  {x: 690.0000, y: 103.0002},
  {x: 690.0000, y: 100.9998},
  {x: 690.0000, y: 99.0000},
  {x: 689.6667, y: 99.0000},
  {x: 689.3333, y: 99.0000},
  {x: 689.0000, y: 99.0000},
  {x: 689.0000, y: 95.3337},
  {x: 689.0000, y: 91.6663},
  {x: 689.0000, y: 88.0000},
  {x: 688.6667, y: 88.0000},
  {x: 688.3333, y: 88.0000},
  {x: 688.0000, y: 88.0000},
  {x: 688.0000, y: 86.3335},
  {x: 688.0000, y: 84.6665},
  {x: 688.0000, y: 83.0000},
  {x: 687.6667, y: 83.0000},
  {x: 687.3333, y: 83.0000},
  {x: 687.0000, y: 83.0000},
  {x: 687.0000, y: 81.6668},
  {x: 687.0000, y: 80.3332},
  {x: 687.0000, y: 79.0000},
  {x: 686.6667, y: 79.0000},
  {x: 686.3333, y: 79.0000},
  {x: 686.0000, y: 79.0000},
  {x: 686.0000, y: 75.0004},
  {x: 686.0000, y: 70.9996},
  {x: 686.0000, y: 67.0000},
  {x: 685.6667, y: 67.0000},
  {x: 685.3333, y: 67.0000},
  {x: 685.0000, y: 67.0000},
  {x: 685.0000, y: 65.6668},
  {x: 685.0000, y: 64.3332},
  {x: 685.0000, y: 63.0000},
  {x: 684.6667, y: 63.0000},
  {x: 684.3333, y: 63.0000},
  {x: 684.0000, y: 63.0000},
  {x: 684.0000, y: 61.6668},
  {x: 684.0000, y: 60.3332},
  {x: 684.0000, y: 59.0000},
  {x: 683.6667, y: 59.0000},
  {x: 683.3333, y: 59.0000},
  {x: 683.0000, y: 59.0000},
  {x: 683.0000, y: 57.3335},
  {x: 683.0000, y: 55.6665},
  {x: 683.0000, y: 54.0000},
  {x: 682.6667, y: 54.0000},
  {x: 682.3333, y: 54.0000},
  {x: 682.0000, y: 54.0000},
  {x: 682.0000, y: 51.6669},
  {x: 682.0000, y: 49.3331},
  {x: 682.0000, y: 47.0000},
  {x: 681.6667, y: 47.0000},
  {x: 681.3333, y: 47.0000},
  {x: 681.0000, y: 47.0000},
  {x: 680.6667, y: 43.3337},
  {x: 680.3333, y: 39.6663},
  {x: 680.0000, y: 36.0000},
  {x: 679.6667, y: 36.0000},
  {x: 679.3333, y: 36.0000},
  {x: 679.0000, y: 36.0000},
  {x: 679.0000, y: 35.3334},
  {x: 679.0000, y: 34.6666},
  {x: 679.0000, y: 34.0000},
  {x: 678.6667, y: 34.0000},
  {x: 678.3333, y: 34.0000},
  {x: 678.0000, y: 34.0000},
  {x: 677.0001, y: 30.3337},
  {x: 675.9999, y: 26.6663},
  {x: 675.0000, y: 23.0000},
  {x: 674.6667, y: 23.0000},
  {x: 674.3333, y: 23.0000},
  {x: 674.0000, y: 23.0000},
  {x: 674.0000, y: 22.3334},
  {x: 674.0000, y: 21.6666},
  {x: 674.0000, y: 21.0000},
  {x: 673.6667, y: 21.0000},
  {x: 673.3333, y: 21.0000},
  {x: 673.0000, y: 21.0000},
  {x: 671.8375, y: 19.4811},
  {x: 671.6222, y: 19.2412},
  {x: 671.0000, y: 17.0000},
  {x: 667.4849, y: 16.0854},
  {x: 668.1468, y: 14.5672},
  {x: 664.0000, y: 14.0000},
  {x: 663.3334, y: 14.9999},
  {x: 662.6666, y: 16.0001},
  {x: 662.0000, y: 17.0000},
  {x: 661.6667, y: 17.0000},
  {x: 661.3333, y: 17.0000},
  {x: 661.0000, y: 17.0000},
  {x: 661.0000, y: 17.6666},
  {x: 661.0000, y: 18.3334},
  {x: 661.0000, y: 19.0000},
  {x: 660.3334, y: 19.3333},
  {x: 659.6666, y: 19.6667},
  {x: 659.0000, y: 20.0000},
  {x: 659.0000, y: 20.6666},
  {x: 659.0000, y: 21.3334},
  {x: 659.0000, y: 22.0000},
  {x: 658.6667, y: 22.0000},
  {x: 658.3333, y: 22.0000},
  {x: 658.0000, y: 22.0000},
  {x: 658.0000, y: 22.6666},
  {x: 658.0000, y: 23.3334},
  {x: 658.0000, y: 24.0000},
  {x: 657.6667, y: 24.0000},
  {x: 657.3333, y: 24.0000},
  {x: 657.0000, y: 24.0000},
  {x: 657.0000, y: 24.6666},
  {x: 657.0000, y: 25.3334},
  {x: 657.0000, y: 26.0000},
  {x: 656.6667, y: 26.0000},
  {x: 656.3333, y: 26.0000},
  {x: 656.0000, y: 26.0000},
  {x: 656.0000, y: 26.6666},
  {x: 656.0000, y: 27.3334},
  {x: 656.0000, y: 28.0000},
  {x: 655.3334, y: 28.3333},
  {x: 654.6666, y: 28.6667},
  {x: 654.0000, y: 29.0000},
  {x: 653.3334, y: 30.9998},
  {x: 652.6666, y: 33.0002},
  {x: 652.0000, y: 35.0000},
  {x: 651.3334, y: 35.3333},
  {x: 650.6666, y: 35.6667},
  {x: 650.0000, y: 36.0000},
  {x: 649.6667, y: 37.3332},
  {x: 649.3333, y: 38.6668},
  {x: 649.0000, y: 40.0000},
  {x: 648.3334, y: 40.3333},
  {x: 647.6666, y: 40.6667},
  {x: 647.0000, y: 41.0000},
  {x: 646.6667, y: 42.3332},
  {x: 646.3333, y: 43.6668},
  {x: 646.0000, y: 45.0000},
  {x: 645.3334, y: 45.3333},
  {x: 644.6666, y: 45.6667},
  {x: 644.0000, y: 46.0000},
  {x: 643.6667, y: 46.9999},
  {x: 643.3333, y: 48.0001},
  {x: 643.0000, y: 49.0000},
  {x: 641.6668, y: 49.9999},
  {x: 640.3332, y: 51.0001},
  {x: 639.0000, y: 52.0000},
  {x: 639.0000, y: 52.6666},
  {x: 639.0000, y: 53.3334},
  {x: 639.0000, y: 54.0000},
  {x: 635.6670, y: 56.9997},
  {x: 632.3330, y: 60.0003},
  {x: 629.0000, y: 63.0000},
  {x: 627.6668, y: 64.6665},
  {x: 626.3332, y: 66.3335},
  {x: 625.0000, y: 68.0000},
  {x: 624.3334, y: 68.0000},
  {x: 623.6666, y: 68.0000},
  {x: 623.0000, y: 68.0000},
  {x: 622.6667, y: 68.6666},
  {x: 622.3333, y: 69.3334},
  {x: 622.0000, y: 70.0000},
  {x: 620.6668, y: 70.3333},
  {x: 619.3332, y: 70.6667},
  {x: 618.0000, y: 71.0000},
  {x: 617.0001, y: 72.3332},
  {x: 615.9999, y: 73.6668},
  {x: 615.0000, y: 75.0000},
  {x: 612.0003, y: 75.9999},
  {x: 608.9997, y: 77.0001},
  {x: 606.0000, y: 78.0000},
  {x: 606.0000, y: 78.3333},
  {x: 606.0000, y: 78.6667},
  {x: 606.0000, y: 79.0000},
  {x: 604.6668, y: 79.3333},
  {x: 603.3332, y: 79.6667},
  {x: 602.0000, y: 80.0000},
  {x: 602.0000, y: 80.3333},
  {x: 602.0000, y: 80.6667},
  {x: 602.0000, y: 81.0000},
  {x: 601.0001, y: 81.0000},
  {x: 599.9999, y: 81.0000},
  {x: 599.0000, y: 81.0000},
  {x: 599.0000, y: 81.3333},
  {x: 599.0000, y: 81.6667},
  {x: 599.0000, y: 82.0000},
  {x: 598.0001, y: 82.0000},
  {x: 596.9999, y: 82.0000},
  {x: 596.0000, y: 82.0000},
  {x: 596.0000, y: 82.3333},
  {x: 596.0000, y: 82.6667},
  {x: 596.0000, y: 83.0000},
  {x: 595.0001, y: 83.0000},
  {x: 593.9999, y: 83.0000},
  {x: 593.0000, y: 83.0000},
  {x: 593.0000, y: 83.3333},
  {x: 593.0000, y: 83.6667},
  {x: 593.0000, y: 84.0000},
  {x: 590.6669, y: 84.0000},
  {x: 588.3331, y: 84.0000},
  {x: 586.0000, y: 84.0000},
  {x: 582.3466, y: 85.0336},
  {x: 572.5545, y: 87.5754},
  {x: 567.0000, y: 86.0000},
  {x: 567.0000, y: 85.6667},
  {x: 567.0000, y: 85.3333},
  {x: 567.0000, y: 85.0000},
  {x: 563.6670, y: 85.0000},
  {x: 560.3330, y: 85.0000},
  {x: 557.0000, y: 85.0000},
  {x: 557.0000, y: 84.6667},
  {x: 557.0000, y: 84.3333},
  {x: 557.0000, y: 84.0000},
  {x: 554.6669, y: 84.0000},
  {x: 552.3331, y: 84.0000},
  {x: 550.0000, y: 84.0000},
  {x: 542.6674, y: 81.6669},
  {x: 535.3326, y: 79.3331},
  {x: 528.0000, y: 77.0000},
  {x: 528.0000, y: 76.6667},
  {x: 528.0000, y: 76.3333},
  {x: 528.0000, y: 76.0000},
  {x: 526.6668, y: 75.6667},
  {x: 525.3332, y: 75.3333},
  {x: 524.0000, y: 75.0000},
  {x: 524.0000, y: 74.6667},
  {x: 524.0000, y: 74.3333},
  {x: 524.0000, y: 74.0000},
  {x: 523.3334, y: 74.0000},
  {x: 522.6666, y: 74.0000},
  {x: 522.0000, y: 74.0000},
  {x: 522.0000, y: 73.6667},
  {x: 522.0000, y: 73.3333},
  {x: 522.0000, y: 73.0000},
  {x: 521.0001, y: 73.0000},
  {x: 519.9999, y: 73.0000},
  {x: 519.0000, y: 73.0000},
  {x: 519.0000, y: 72.6667},
  {x: 519.0000, y: 72.3333},
  {x: 519.0000, y: 72.0000},
  {x: 517.0002, y: 71.3334},
  {x: 514.9998, y: 70.6666},
  {x: 513.0000, y: 70.0000},
  {x: 513.0000, y: 69.6667},
  {x: 513.0000, y: 69.3333},
  {x: 513.0000, y: 69.0000},
  {x: 511.6668, y: 68.6667},
  {x: 510.3332, y: 68.3333},
  {x: 509.0000, y: 68.0000},
  {x: 508.6667, y: 67.3334},
  {x: 508.3333, y: 66.6666},
  {x: 508.0000, y: 66.0000},
  {x: 506.6668, y: 65.6667},
  {x: 505.3332, y: 65.3333},
  {x: 504.0000, y: 65.0000},
  {x: 503.6667, y: 64.3334},
  {x: 503.3333, y: 63.6666},
  {x: 503.0000, y: 63.0000},
  {x: 501.6668, y: 62.6667},
  {x: 500.3332, y: 62.3333},
  {x: 499.0000, y: 62.0000},
  {x: 499.0000, y: 61.6667},
  {x: 499.0000, y: 61.3333},
  {x: 499.0000, y: 61.0000},
  {x: 498.0001, y: 60.6667},
  {x: 496.9999, y: 60.3333},
  {x: 496.0000, y: 60.0000},
  {x: 495.6667, y: 59.3334},
  {x: 495.3333, y: 58.6666},
  {x: 495.0000, y: 58.0000},
  {x: 494.3334, y: 58.0000},
  {x: 493.6666, y: 58.0000},
  {x: 493.0000, y: 58.0000},
  {x: 492.3334, y: 57.0001},
  {x: 491.6666, y: 55.9999},
  {x: 491.0000, y: 55.0000},
  {x: 490.3334, y: 55.0000},
  {x: 489.6666, y: 55.0000},
  {x: 489.0000, y: 55.0000},
  {x: 488.0001, y: 53.6668},
  {x: 486.9999, y: 52.3332},
  {x: 486.0000, y: 51.0000},
  {x: 485.3334, y: 51.0000},
  {x: 484.6666, y: 51.0000},
  {x: 484.0000, y: 51.0000},
  {x: 481.3336, y: 48.0003},
  {x: 478.6664, y: 44.9997},
  {x: 476.0000, y: 42.0000},
  {x: 474.6668, y: 41.0001},
  {x: 473.3332, y: 39.9999},
  {x: 472.0000, y: 39.0000},
  {x: 472.0000, y: 38.3334},
  {x: 472.0000, y: 37.6666},
  {x: 472.0000, y: 37.0000},
  {x: 470.0002, y: 35.3335},
  {x: 467.9998, y: 33.6665},
  {x: 466.0000, y: 32.0000},
  {x: 466.0000, y: 31.3334},
  {x: 466.0000, y: 30.6666},
  {x: 466.0000, y: 30.0000},
  {x: 464.6668, y: 29.0001},
  {x: 463.3332, y: 27.9999},
  {x: 462.0000, y: 27.0000},
  {x: 461.6667, y: 26.3334},
  {x: 461.3333, y: 25.6666},
  {x: 461.0000, y: 25.0000},
  {x: 460.3334, y: 25.0000},
  {x: 459.6666, y: 25.0000},
  {x: 459.0000, y: 25.0000},
  {x: 459.0000, y: 24.6667},
  {x: 459.0000, y: 24.3333},
  {x: 459.0000, y: 24.0000},
  {x: 458.3334, y: 23.6667},
  {x: 457.6666, y: 23.3333},
  {x: 457.0000, y: 23.0000},
  {x: 457.0000, y: 22.3334},
  {x: 457.0000, y: 21.6666},
  {x: 457.0000, y: 21.0000},
  {x: 456.6667, y: 21.0000},
  {x: 456.3333, y: 21.0000},
  {x: 456.0000, y: 21.0000},
  {x: 455.3334, y: 20.0001},
  {x: 454.6666, y: 18.9999},
  {x: 454.0000, y: 18.0000},
  {x: 453.3334, y: 18.0000},
  {x: 452.6666, y: 18.0000},
  {x: 452.0000, y: 18.0000},
  {x: 450.3335, y: 16.0002},
  {x: 448.6665, y: 13.9998},
  {x: 447.0000, y: 12.0000},
  {x: 446.3334, y: 12.0000},
  {x: 445.6666, y: 12.0000},
  {x: 445.0000, y: 12.0000},
  {x: 444.3334, y: 11.0001},
  {x: 443.6666, y: 9.9999},
  {x: 443.0000, y: 9.0000},
  {x: 442.3334, y: 9.0000},
  {x: 441.6666, y: 9.0000},
  {x: 441.0000, y: 9.0000},
  {x: 440.3334, y: 8.0001},
  {x: 439.6666, y: 6.9999},
  {x: 439.0000, y: 6.0000},
  {x: 438.3334, y: 6.0000},
  {x: 437.6666, y: 6.0000},
  {x: 437.0000, y: 6.0000},
  {x: 436.6667, y: 5.3334},
  {x: 436.3333, y: 4.6666},
  {x: 436.0000, y: 4.0000},
  {x: 433.5984, y: 2.4834},
  {x: 428.7054, y: 1.2190},
  {x: 425.0000, y: 1.0000},
  {x: 425.0000, y: 1.3333},
  {x: 425.0000, y: 1.6667},
  {x: 425.0000, y: 2.0000},
  {x: 424.0001, y: 2.0000},
  {x: 422.9999, y: 2.0000},
  {x: 422.0000, y: 2.0000},
  {x: 421.6667, y: 2.6666},
  {x: 421.3333, y: 3.3334},
  {x: 421.0000, y: 4.0000},
  {x: 420.3334, y: 4.0000},
  {x: 419.6666, y: 4.0000},
  {x: 419.0000, y: 4.0000},
  {x: 419.0000, y: 4.3333},
  {x: 419.0000, y: 4.6667},
  {x: 419.0000, y: 5.0000},
  {x: 418.3334, y: 5.0000},
  {x: 417.6666, y: 5.0000},
  {x: 417.0000, y: 5.0000},
  {x: 416.6667, y: 5.6666},
  {x: 416.3333, y: 6.3334},
  {x: 416.0000, y: 7.0000},
  {x: 415.3334, y: 7.0000},
  {x: 414.6666, y: 7.0000},
  {x: 414.0000, y: 7.0000},
  {x: 412.0002, y: 9.3331},
  {x: 409.9998, y: 11.6669},
  {x: 408.0000, y: 14.0000},
  {x: 407.3334, y: 14.0000},
  {x: 406.6666, y: 14.0000},
  {x: 406.0000, y: 14.0000},
  {x: 405.0001, y: 15.3332},
  {x: 403.9999, y: 16.6668},
  {x: 403.0000, y: 18.0000},
  {x: 402.3334, y: 18.0000},
  {x: 401.6666, y: 18.0000},
  {x: 401.0000, y: 18.0000},
  {x: 400.6667, y: 18.6666},
  {x: 400.3333, y: 19.3334},
  {x: 400.0000, y: 20.0000},
  {x: 399.3334, y: 20.0000},
  {x: 398.6666, y: 20.0000},
  {x: 398.0000, y: 20.0000},
  {x: 397.3334, y: 20.9999},
  {x: 396.6666, y: 22.0001},
  {x: 396.0000, y: 23.0000},
  {x: 395.3334, y: 23.0000},
  {x: 394.6666, y: 23.0000},
  {x: 394.0000, y: 23.0000},
  {x: 393.6667, y: 23.6666},
  {x: 393.3333, y: 24.3334},
  {x: 393.0000, y: 25.0000},
  {x: 392.3334, y: 25.0000},
  {x: 391.6666, y: 25.0000},
  {x: 391.0000, y: 25.0000},
  {x: 390.6667, y: 25.6666},
  {x: 390.3333, y: 26.3334},
  {x: 390.0000, y: 27.0000},
  {x: 389.0001, y: 27.3333},
  {x: 387.9999, y: 27.6667},
  {x: 387.0000, y: 28.0000},
  {x: 387.0000, y: 28.3333},
  {x: 387.0000, y: 28.6667},
  {x: 387.0000, y: 29.0000},
  {x: 385.6668, y: 28.6667},
  {x: 384.3332, y: 28.3333},
  {x: 383.0000, y: 28.0000},
  {x: 383.0000, y: 27.6667},
  {x: 383.0000, y: 27.3333},
  {x: 383.0000, y: 27.0000},
  {x: 381.7167, y: 26.7710},
  {x: 376.6750, y: 29.3216},
  {x: 376.0000, y: 30.0000},
  {x: 374.8614, y: 31.1393},
  {x: 375.6016, y: 30.0036},
  {x: 375.0000, y: 32.0000},
  {x: 375.6666, y: 32.0000},
  {x: 376.3334, y: 32.0000},
  {x: 377.0000, y: 32.0000},
  {x: 379.8196, y: 36.1670},
  {x: 383.5917, y: 34.7947},
  {x: 384.0000, y: 42.0000},
  {x: 386.3343, y: 44.4453},
  {x: 383.9971, y: 48.7250},
  {x: 383.0000, y: 51.0000},
  {x: 381.7189, y: 51.5898},
  {x: 380.8595, y: 51.9855},
  {x: 380.0000, y: 53.0000},
  {x: 378.6668, y: 52.6667},
  {x: 377.3332, y: 52.3333},
  {x: 376.0000, y: 52.0000},
  {x: 376.0000, y: 51.6667},
  {x: 376.0000, y: 51.3333},
  {x: 376.0000, y: 51.0000},
  {x: 374.3335, y: 51.0000},
  {x: 372.6665, y: 51.0000},
  {x: 371.0000, y: 51.0000},
  {x: 371.0000, y: 50.6667},
  {x: 371.0000, y: 50.3333},
  {x: 371.0000, y: 50.0000},
  {x: 370.3334, y: 50.0000},
  {x: 369.6666, y: 50.0000},
  {x: 369.0000, y: 50.0000},
  {x: 369.0000, y: 49.6667},
  {x: 369.0000, y: 49.3333},
  {x: 369.0000, y: 49.0000},
  {x: 368.3334, y: 49.0000},
  {x: 367.6666, y: 49.0000},
  {x: 367.0000, y: 49.0000},
  {x: 366.6667, y: 48.3334},
  {x: 366.3333, y: 47.6666},
  {x: 366.0000, y: 47.0000},
  {x: 365.3334, y: 47.0000},
  {x: 364.6666, y: 47.0000},
  {x: 364.0000, y: 47.0000},
  {x: 360.1009, y: 44.8073},
  {x: 359.3581, y: 43.2787},
  {x: 353.0000, y: 43.0000},
  {x: 351.1221, y: 45.2654},
  {x: 348.5691, y: 46.3020},
  {x: 347.0000, y: 49.0000},
  {x: 354.0615, y: 51.9109},
  {x: 356.3227, y: 53.5565},
  {x: 356.0000, y: 64.0000},
  {x: 355.3334, y: 64.3333},
  {x: 354.6666, y: 64.6667},
  {x: 354.0000, y: 65.0000},
  {x: 348.3572, y: 70.4660},
  {x: 335.3098, y: 60.4534},
  {x: 331.0000, y: 64.0000},
  {x: 328.3146, y: 64.5870},
  {x: 326.2675, y: 65.9894},
  {x: 325.0000, y: 68.0000},
  {x: 330.5130, y: 71.0279},
  {x: 332.2026, y: 74.9861},
  {x: 332.0000, y: 84.0000},
  {x: 331.0001, y: 84.6666},
  {x: 329.9999, y: 85.3334},
  {x: 329.0000, y: 86.0000},
  {x: 329.0000, y: 86.3333},
  {x: 329.0000, y: 86.6667},
  {x: 329.0000, y: 87.0000},
  {x: 328.3334, y: 87.0000},
  {x: 327.6666, y: 87.0000},
  {x: 327.0000, y: 87.0000},
  {x: 327.0000, y: 87.3333},
  {x: 327.0000, y: 87.6667},
  {x: 327.0000, y: 88.0000},
  {x: 318.5960, y: 91.0877},
  {x: 311.7651, y: 79.6339},
  {x: 306.0000, y: 78.0000},
  {x: 305.2767, y: 75.2377},
  {x: 305.7209, y: 75.7629},
  {x: 303.0000, y: 75.0000},
  {x: 303.0000, y: 74.6667},
  {x: 303.0000, y: 74.3333},
  {x: 303.0000, y: 74.0000},
  {x: 302.0001, y: 74.0000},
  {x: 300.9999, y: 74.0000},
  {x: 300.0000, y: 74.0000},
  {x: 298.3382, y: 75.8689},
  {x: 295.6276, y: 76.1137},
  {x: 294.0000, y: 78.0000},
  {x: 300.7409, y: 79.5858},
  {x: 299.3300, y: 87.7628},
  {x: 298.0000, y: 94.0000},
  {x: 297.0001, y: 94.3333},
  {x: 295.9999, y: 94.6667},
  {x: 295.0000, y: 95.0000},
  {x: 290.2475, y: 99.4085},
  {x: 281.9851, y: 91.1645},
  {x: 279.0000, y: 90.0000},
  {x: 278.0001, y: 90.0000},
  {x: 276.9999, y: 90.0000},
  {x: 276.0000, y: 90.0000},
  {x: 273.5670, y: 92.6934},
  {x: 269.0477, y: 92.8750},
  {x: 267.0000, y: 96.0000},
  {x: 272.1165, y: 98.3747},
  {x: 270.7176, y: 100.1292},
  {x: 274.0000, y: 103.0000},
  {x: 273.4264, y: 105.0098},
  {x: 274.1200, y: 103.8652},
  {x: 273.0000, y: 105.0000},
  {x: 272.3825, y: 108.4693},
  {x: 271.3522, y: 110.1684},
  {x: 268.0000, y: 111.0000},
  {x: 266.9898, y: 111.9479},
  {x: 260.4581, y: 113.0635},
  {x: 258.0000, y: 112.0000},
  {x: 258.0000, y: 111.6667},
  {x: 258.0000, y: 111.3333},
  {x: 258.0000, y: 111.0000},
  {x: 256.5417, y: 110.1742},
  {x: 256.2831, y: 110.3509},
  {x: 254.0000, y: 110.0000},
  {x: 252.9105, y: 105.8915},
  {x: 251.0216, y: 106.8948},
  {x: 248.0000, y: 105.0000},
  {x: 247.6667, y: 104.3334},
  {x: 247.3333, y: 103.6666},
  {x: 247.0000, y: 103.0000},
  {x: 245.0002, y: 103.0000},
  {x: 242.9998, y: 103.0000},
  {x: 241.0000, y: 103.0000},
  {x: 239.6462, y: 104.5871},
  {x: 238.8309, y: 104.7368},
  {x: 236.0000, y: 105.0000},
  {x: 236.0000, y: 105.6666},
  {x: 236.0000, y: 106.3334},
  {x: 236.0000, y: 107.0000},
  {x: 236.9999, y: 107.6666},
  {x: 238.0001, y: 108.3334},
  {x: 239.0000, y: 109.0000},
  {x: 239.3333, y: 110.6665},
  {x: 239.6667, y: 112.3335},
  {x: 240.0000, y: 114.0000},
  {x: 240.0755, y: 114.2686},
  {x: 241.6650, y: 114.2929},
  {x: 241.0000, y: 116.0000},
  {x: 240.6667, y: 116.0000},
  {x: 240.3333, y: 116.0000},
  {x: 240.0000, y: 116.0000},
  {x: 239.3825, y: 119.4693},
  {x: 238.3522, y: 121.1684},
  {x: 235.0000, y: 122.0000},
  {x: 231.5701, y: 125.1768},
  {x: 221.4541, y: 121.2490},
  {x: 217.0000, y: 121.0000},
  {x: 214.6084, y: 124.8102},
  {x: 211.3639, y: 123.7412},
  {x: 211.0000, y: 130.0000},
  {x: 212.8468, y: 132.1484},
  {x: 210.6646, y: 135.1113},
  {x: 210.0000, y: 138.0000},
  {x: 207.6669, y: 139.6665},
  {x: 205.3331, y: 141.3335},
  {x: 203.0000, y: 143.0000},
  {x: 201.6668, y: 142.6667},
  {x: 200.3332, y: 142.3333},
  {x: 199.0000, y: 142.0000},
  {x: 199.0000, y: 141.6667},
  {x: 199.0000, y: 141.3333},
  {x: 199.0000, y: 141.0000},
  {x: 197.0002, y: 141.0000},
  {x: 194.9998, y: 141.0000},
  {x: 193.0000, y: 141.0000},
  {x: 193.0000, y: 140.6667},
  {x: 193.0000, y: 140.3333},
  {x: 193.0000, y: 140.0000},
  {x: 194.2811, y: 139.4102},
  {x: 195.1405, y: 139.0145},
  {x: 196.0000, y: 138.0000},
  {x: 195.0001, y: 138.0000},
  {x: 193.9999, y: 138.0000},
  {x: 193.0000, y: 138.0000},
  {x: 191.9667, y: 139.2132},
  {x: 187.4452, y: 142.3504},
  {x: 186.0000, y: 143.0000},
  {x: 185.0001, y: 143.0000},
  {x: 183.9999, y: 143.0000},
  {x: 183.0000, y: 143.0000},
  {x: 183.0000, y: 143.3333},
  {x: 183.0000, y: 143.6667},
  {x: 183.0000, y: 144.0000},
  {x: 182.3334, y: 144.0000},
  {x: 181.6666, y: 144.0000},
  {x: 181.0000, y: 144.0000},
  {x: 181.0000, y: 144.3333},
  {x: 181.0000, y: 144.6667},
  {x: 181.0000, y: 145.0000},
  {x: 180.0001, y: 145.0000},
  {x: 178.9999, y: 145.0000},
  {x: 178.0000, y: 145.0000},
  {x: 178.0000, y: 145.3333},
  {x: 178.0000, y: 145.6667},
  {x: 178.0000, y: 146.0000},
  {x: 177.3334, y: 146.0000},
  {x: 176.6666, y: 146.0000},
  {x: 176.0000, y: 146.0000},
  {x: 173.4003, y: 147.3468},
  {x: 171.5109, y: 149.2169},
  {x: 168.0000, y: 150.0000},
  {x: 167.5366, y: 152.3912},
  {x: 166.8417, y: 153.1447},
  {x: 166.0000, y: 155.0000},
  {x: 161.0872, y: 155.4277},
  {x: 157.7038, y: 157.6807},
  {x: 154.0000, y: 159.0000},
  {x: 151.3336, y: 159.3333},
  {x: 148.6664, y: 159.6667},
  {x: 146.0000, y: 160.0000},
  {x: 146.0000, y: 160.3333},
  {x: 146.0000, y: 160.6667},
  {x: 146.0000, y: 161.0000},
  {x: 143.6669, y: 161.0000},
  {x: 141.3331, y: 161.0000},
  {x: 139.0000, y: 161.0000},
  {x: 139.0000, y: 161.3333},
  {x: 139.0000, y: 161.6667},
  {x: 139.0000, y: 162.0000},
  {x: 135.6670, y: 162.0000},
  {x: 132.3330, y: 162.0000},
  {x: 129.0000, y: 162.0000},
  {x: 129.0000, y: 162.3333},
  {x: 129.0000, y: 162.6667},
  {x: 129.0000, y: 163.0000},
  {x: 124.3338, y: 163.0000},
  {x: 119.6662, y: 163.0000},
  {x: 115.0000, y: 163.0000},
  {x: 115.0000, y: 163.3333},
  {x: 115.0000, y: 163.6667},
  {x: 115.0000, y: 164.0000},
  {x: 111.0004, y: 164.0000},
  {x: 106.9996, y: 164.0000},
  {x: 103.0000, y: 164.0000},
  {x: 103.0000, y: 164.3333},
  {x: 103.0000, y: 164.6667},
  {x: 103.0000, y: 165.0000},
  {x: 99.6670, y: 165.0000},
  {x: 96.3330, y: 165.0000},
  {x: 93.0000, y: 165.0000},
  {x: 93.0000, y: 165.3333},
  {x: 93.0000, y: 165.6667},
  {x: 93.0000, y: 166.0000},
  {x: 90.0003, y: 166.0000},
  {x: 86.9997, y: 166.0000},
  {x: 84.0000, y: 166.0000},
  {x: 84.0000, y: 166.3333},
  {x: 84.0000, y: 166.6667},
  {x: 84.0000, y: 167.0000},
  {x: 81.0003, y: 167.0000},
  {x: 77.9997, y: 167.0000},
  {x: 75.0000, y: 167.0000},
  {x: 75.0000, y: 167.3333},
  {x: 75.0000, y: 167.6667},
  {x: 75.0000, y: 168.0000},
  {x: 70.6671, y: 168.0000},
  {x: 66.3329, y: 168.0000},
  {x: 62.0000, y: 168.0000},
  {x: 62.0000, y: 168.3333},
  {x: 62.0000, y: 168.6667},
  {x: 62.0000, y: 169.0000},
  {x: 58.3337, y: 169.0000},
  {x: 54.6663, y: 169.0000},
  {x: 51.0000, y: 169.0000},
  {x: 51.0000, y: 169.3333},
  {x: 51.0000, y: 169.6667},
  {x: 51.0000, y: 170.0000},
  {x: 43.6674, y: 170.3333},
  {x: 36.3326, y: 170.6667},
  {x: 29.0000, y: 171.0000},
  {x: 29.0000, y: 171.3333},
  {x: 29.0000, y: 171.6667},
  {x: 29.0000, y: 172.0000},
  {x: 27.6668, y: 172.3333},
  {x: 26.3332, y: 172.6667},
  {x: 25.0000, y: 173.0000},
  {x: 25.0000, y: 173.3333},
  {x: 25.0000, y: 173.6667},
  {x: 25.0000, y: 174.0000},
  {x: 24.3334, y: 174.0000},
  {x: 23.6666, y: 174.0000},
  {x: 23.0000, y: 174.0000},
  {x: 22.3334, y: 174.9999},
  {x: 21.6666, y: 176.0001},
  {x: 21.0000, y: 177.0000},
  {x: 20.3334, y: 177.0000},
  {x: 19.6666, y: 177.0000},
  {x: 19.0000, y: 177.0000},
  {x: 18.6667, y: 177.6666},
  {x: 18.3333, y: 178.3334},
  {x: 18.0000, y: 179.0000},
  {x: 17.3334, y: 179.0000},
  {x: 16.6666, y: 179.0000},
  {x: 16.0000, y: 179.0000},
  {x: 16.0000, y: 179.3333},
  {x: 16.0000, y: 179.6667},
  {x: 16.0000, y: 180.0000},
  {x: 15.0001, y: 180.6666},
  {x: 13.9999, y: 181.3334},
  {x: 13.0000, y: 182.0000},
  {x: 13.0000, y: 182.6666},
  {x: 13.0000, y: 183.3334},
  {x: 13.0000, y: 184.0000},
  {x: 12.3334, y: 184.3333},
  {x: 11.6666, y: 184.6667},
  {x: 11.0000, y: 185.0000},
  {x: 11.0000, y: 185.6666},
  {x: 11.0000, y: 186.3334},
  {x: 11.0000, y: 187.0000},
  {x: 10.6667, y: 187.0000},
  {x: 10.3333, y: 187.0000},
  {x: 10.0000, y: 187.0000},
  {x: 10.0000, y: 187.6666},
  {x: 10.0000, y: 188.3334},
  {x: 10.0000, y: 189.0000},
  {x: 9.6667, y: 189.0000},
  {x: 9.3333, y: 189.0000},
  {x: 9.0000, y: 189.0000},
  {x: 9.0000, y: 189.6666},
  {x: 9.0000, y: 190.3334},
  {x: 9.0000, y: 191.0000},
  {x: 8.6667, y: 191.0000},
  {x: 8.3333, y: 191.0000},
  {x: 8.0000, y: 191.0000},
  {x: 8.0000, y: 191.6666},
  {x: 8.0000, y: 192.3334},
  {x: 8.0000, y: 193.0000},
  {x: 7.6667, y: 193.0000},
  {x: 7.3333, y: 193.0000},
  {x: 7.0000, y: 193.0000},
  {x: 7.0000, y: 193.6666},
  {x: 7.0000, y: 194.3334},
  {x: 7.0000, y: 195.0000},
  {x: 6.6667, y: 195.0000},
  {x: 6.3333, y: 195.0000},
  {x: 6.0000, y: 195.0000},
  {x: 6.0000, y: 195.6666},
  {x: 6.0000, y: 196.3334},
  {x: 6.0000, y: 197.0000},
  {x: 4.3646, y: 200.0625},
  {x: 1.8611, y: 201.9827},
  {x: 1.0000, y: 206.0000},
  {x: 2.6665, y: 206.3333},
  {x: 4.3335, y: 206.6667},
  {x: 6.0000, y: 207.0000},
  {x: 6.0000, y: 207.3333},
  {x: 6.0000, y: 207.6667},
  {x: 6.0000, y: 208.0000},
  {x: 10.6662, y: 208.3333},
  {x: 15.3338, y: 208.6667},
  {x: 20.0000, y: 209.0000},
  {x: 30.9725, y: 212.3044},
  {x: 45.0014, y: 212.7556},
  {x: 56.0000, y: 216.0000},
  {x: 58.3331, y: 216.0000},
  {x: 60.6669, y: 216.0000},
  {x: 63.0000, y: 216.0000},
  {x: 71.0806, y: 218.3708},
  {x: 81.8708, y: 218.6430},
  {x: 90.0000, y: 221.0000},
  {x: 92.6664, y: 221.0000},
  {x: 95.3336, y: 221.0000},
  {x: 98.0000, y: 221.0000},
  {x: 111.3854, y: 224.8626},
  {x: 129.6041, y: 223.1584},
  {x: 143.0000, y: 227.0000},
  {x: 145.9997, y: 227.0000},
  {x: 149.0003, y: 227.0000},
  {x: 152.0000, y: 227.0000},
  {x: 152.0000, y: 227.3333},
  {x: 152.0000, y: 227.6667},
  {x: 152.0000, y: 228.0000},
  {x: 155.6663, y: 228.0000},
  {x: 159.3337, y: 228.0000},
  {x: 163.0000, y: 228.0000},
  {x: 163.0000, y: 228.3333},
  {x: 163.0000, y: 228.6667},
  {x: 163.0000, y: 229.0000},
  {x: 166.3330, y: 229.0000},
  {x: 169.6670, y: 229.0000},
  {x: 173.0000, y: 229.0000},
  {x: 173.0000, y: 229.3333},
  {x: 173.0000, y: 229.6667},
  {x: 173.0000, y: 230.0000},
  {x: 176.9996, y: 230.0000},
  {x: 181.0004, y: 230.0000},
  {x: 185.0000, y: 230.0000},
  {x: 185.0000, y: 230.3333},
  {x: 185.0000, y: 230.6667},
  {x: 185.0000, y: 231.0000},
  {x: 189.6662, y: 231.0000},
  {x: 194.3338, y: 231.0000},
  {x: 199.0000, y: 231.0000},
  {x: 199.0000, y: 231.3333},
  {x: 199.0000, y: 231.6667},
  {x: 199.0000, y: 232.0000},
  {x: 204.3328, y: 232.0000},
  {x: 209.6672, y: 232.0000},
  {x: 215.0000, y: 232.0000},
  {x: 221.4581, y: 233.8008},
  {x: 232.3852, y: 231.1648},
  {x: 239.0000, y: 233.0000},
  {x: 239.0000, y: 233.3333},
  {x: 239.0000, y: 233.6667},
  {x: 239.0000, y: 234.0000},
  {x: 242.3330, y: 234.0000},
  {x: 245.6670, y: 234.0000},
  {x: 249.0000, y: 234.0000},
  {x: 257.6091, y: 234.0058},
  {x: 269.0763, y: 234.9559},
  {x: 276.0000, y: 233.0000},
  {x: 285.9990, y: 232.6667},
  {x: 296.0010, y: 232.3333},
  {x: 306.0000, y: 232.0000},
  {x: 306.0000, y: 231.6667},
  {x: 306.0000, y: 231.3333},
  {x: 306.0000, y: 231.0000},
  {x: 308.9997, y: 231.0000},
  {x: 312.0003, y: 231.0000},
  {x: 315.0000, y: 231.0000},
  {x: 315.0000, y: 230.6667},
  {x: 315.0000, y: 230.3333},
  {x: 315.0000, y: 230.0000},
  {x: 317.6664, y: 230.0000},
  {x: 320.3336, y: 230.0000},
  {x: 323.0000, y: 230.0000},
  {x: 323.0000, y: 229.6667},
  {x: 323.0000, y: 229.3333},
  {x: 323.0000, y: 229.0000},
  {x: 325.3331, y: 229.0000},
  {x: 327.6669, y: 229.0000},
  {x: 330.0000, y: 229.0000},
  {x: 330.0000, y: 228.6667},
  {x: 330.0000, y: 228.3333},
  {x: 330.0000, y: 228.0000},
  {x: 332.3331, y: 228.0000},
  {x: 334.6669, y: 228.0000},
  {x: 337.0000, y: 228.0000},
  {x: 337.0000, y: 227.6667},
  {x: 337.0000, y: 227.3333},
  {x: 337.0000, y: 227.0000},
  {x: 338.9998, y: 227.0000},
  {x: 341.0002, y: 227.0000},
  {x: 343.0000, y: 227.0000},
  {x: 343.0000, y: 226.6667},
  {x: 343.0000, y: 226.3333},
  {x: 343.0000, y: 226.0000},
  {x: 345.9997, y: 226.0000},
  {x: 349.0003, y: 226.0000},
  {x: 352.0000, y: 226.0000},
  {x: 352.0000, y: 225.6667},
  {x: 352.0000, y: 225.3333},
  {x: 352.0000, y: 225.0000},
  {x: 354.9997, y: 225.0000},
  {x: 358.0003, y: 225.0000},
  {x: 361.0000, y: 225.0000},
  {x: 361.0000, y: 224.6667},
  {x: 361.0000, y: 224.3333},
  {x: 361.0000, y: 224.0000},
  {x: 362.3332, y: 224.0000},
  {x: 363.6668, y: 224.0000},
  {x: 365.0000, y: 224.0000},
  {x: 365.0000, y: 223.6667},
  {x: 365.0000, y: 223.3333},
  {x: 365.0000, y: 223.0000},
  {x: 365.9999, y: 223.0000},
  {x: 367.0001, y: 223.0000},
  {x: 368.0000, y: 223.0000},
  {x: 368.0000, y: 222.6667},
  {x: 368.0000, y: 222.3333},
  {x: 368.0000, y: 222.0000},
  {x: 371.3330, y: 221.6667},
  {x: 374.6670, y: 221.3333},
  {x: 378.0000, y: 221.0000},
  {x: 378.0000, y: 220.6667},
  {x: 378.0000, y: 220.3333},
  {x: 378.0000, y: 220.0000},
  {x: 380.3331, y: 220.0000},
  {x: 382.6669, y: 220.0000},
  {x: 385.0000, y: 220.0000},
  {x: 385.0000, y: 219.6667},
  {x: 385.0000, y: 219.3333},
  {x: 385.0000, y: 219.0000},
  {x: 387.3331, y: 219.0000},
  {x: 389.6669, y: 219.0000},
  {x: 392.0000, y: 219.0000},
  {x: 392.0000, y: 218.6667},
  {x: 392.0000, y: 218.3333},
  {x: 392.0000, y: 218.0000},
  {x: 394.3331, y: 218.0000},
  {x: 396.6669, y: 218.0000},
  {x: 399.0000, y: 218.0000},
  {x: 399.0000, y: 217.6667},
  {x: 399.0000, y: 217.3333},
  {x: 399.0000, y: 217.0000},
  {x: 401.3331, y: 217.0000},
  {x: 403.6669, y: 217.0000},
  {x: 406.0000, y: 217.0000},
  {x: 406.0000, y: 216.6667},
  {x: 406.0000, y: 216.3333},
  {x: 406.0000, y: 216.0000},
  {x: 409.6663, y: 216.0000},
  {x: 413.3337, y: 216.0000},
  {x: 417.0000, y: 216.0000},
  {x: 417.0000, y: 215.6667},
  {x: 417.0000, y: 215.3333},
  {x: 417.0000, y: 215.0000},
  {x: 419.6664, y: 215.0000},
  {x: 422.3336, y: 215.0000},
  {x: 425.0000, y: 215.0000},
  {x: 425.0000, y: 214.6667},
  {x: 425.0000, y: 214.3333},
  {x: 425.0000, y: 214.0000},
  {x: 427.6664, y: 214.0000},
  {x: 430.3336, y: 214.0000},
  {x: 433.0000, y: 214.0000},
  {x: 433.0000, y: 213.6667},
  {x: 433.0000, y: 213.3333},
  {x: 433.0000, y: 213.0000},
  {x: 436.3330, y: 213.0000},
  {x: 439.6670, y: 213.0000},
  {x: 443.0000, y: 213.0000},
  {x: 443.0000, y: 212.6667},
  {x: 443.0000, y: 212.3333},
  {x: 443.0000, y: 212.0000},
  {x: 446.6663, y: 212.0000},
  {x: 450.3337, y: 212.0000},
  {x: 454.0000, y: 212.0000},
  {x: 454.0000, y: 211.6667},
  {x: 454.0000, y: 211.3333},
  {x: 454.0000, y: 211.0000},
  {x: 457.3330, y: 211.0000},
  {x: 460.6670, y: 211.0000},
  {x: 464.0000, y: 211.0000},
  {x: 464.0000, y: 210.6667},
  {x: 464.0000, y: 210.3333},
  {x: 464.0000, y: 210.0000},
  {x: 477.6653, y: 209.3334},
  {x: 491.3347, y: 208.6666},
  {x: 505.0000, y: 208.0000},
  {x: 510.3328, y: 208.0000},
  {x: 515.6672, y: 208.0000},
  {x: 521.0000, y: 208.0000},
  {x: 521.0000, y: 207.6667},
  {x: 521.0000, y: 207.3333},
  {x: 521.0000, y: 207.0000},
  {x: 523.3331, y: 207.0000},
  {x: 525.6669, y: 207.0000},
  {x: 528.0000, y: 207.0000},
  {x: 528.0000, y: 206.6667},
  {x: 528.0000, y: 206.3333},
  {x: 528.0000, y: 206.0000},
  {x: 529.6665, y: 206.0000},
  {x: 531.3335, y: 206.0000},
  {x: 533.0000, y: 206.0000},
  {x: 533.0000, y: 205.6667},
  {x: 533.0000, y: 205.3333},
  {x: 533.0000, y: 205.0000},
  {x: 536.9996, y: 205.0000},
  {x: 541.0004, y: 205.0000},
  {x: 545.0000, y: 205.0000},
  {x: 545.0000, y: 204.6667},
  {x: 545.0000, y: 204.3333},
  {x: 545.0000, y: 204.0000},
  {x: 549.9995, y: 204.0000},
  {x: 555.0005, y: 204.0000},
  {x: 560.0000, y: 204.0000},
  {x: 560.0000, y: 203.6667},
  {x: 560.0000, y: 203.3333},
  {x: 560.0000, y: 203.0000},
  {x: 565.6661, y: 203.0000},
  {x: 571.3339, y: 203.0000},
  {x: 577.0000, y: 203.0000},
  {x: 577.0000, y: 202.6667},
  {x: 577.0000, y: 202.3333},
  {x: 577.0000, y: 202.0000},
  {x: 582.9994, y: 202.0000},
  {x: 589.0006, y: 202.0000},
  {x: 595.0000, y: 202.0000},
  {x: 595.0000, y: 201.6667},
  {x: 595.0000, y: 201.3333},
  {x: 595.0000, y: 201.0000},
  {x: 598.3330, y: 201.0000},
  {x: 601.6670, y: 201.0000},
  {x: 605.0000, y: 201.0000},
  {x: 605.9999, y: 201.0000},
  {x: 607.0001, y: 201.0000},
  {x: 608.0000, y: 201.0000},
  {x: 608.0000, y: 200.6667},
  {x: 608.0000, y: 200.3333},
  {x: 608.0000, y: 200.0000},
  {x: 612.6662, y: 200.0000},
  {x: 617.3338, y: 200.0000},
  {x: 622.0000, y: 200.0000},
  {x: 622.0000, y: 199.6667},
  {x: 622.0000, y: 199.3333},
  {x: 622.0000, y: 199.0000},
  {x: 626.6662, y: 199.0000},
  {x: 631.3338, y: 199.0000},
  {x: 636.0000, y: 199.0000},
  {x: 636.0000, y: 198.6667},
  {x: 636.0000, y: 198.3333},
  {x: 636.0000, y: 198.0000},
  {x: 640.9995, y: 198.0000},
  {x: 646.0005, y: 198.0000},
  {x: 651.0000, y: 198.0000},
  {x: 651.0000, y: 197.6667},
  {x: 651.0000, y: 197.3333},
  {x: 651.0000, y: 197.0000},
  {x: 652.6665, y: 197.0000},
  {x: 654.3335, y: 197.0000},
  {x: 656.0000, y: 197.0000},
  {x: 656.0000, y: 197.3333},
  {x: 656.0000, y: 197.6667},
  {x: 656.0000, y: 198.0000},
  {x: 657.6835, y: 198.2688},
  {x: 657.7248, y: 197.0630},
  {x: 658.0000, y: 197.0000},
  {x: 664.3327, y: 197.0000},
  {x: 670.6673, y: 197.0000},
  {x: 677.0000, y: 197.0000},
  {x: 677.0000, y: 196.6667},
  {x: 677.0000, y: 196.3333},
  {x: 677.0000, y: 196.0000},
  {x: 680.9996, y: 195.6667},
  {x: 685.0004, y: 195.3333},
  {x: 689.0000, y: 195.0000},
  {x: 689.0000, y: 195.3333},
  {x: 689.0000, y: 195.6667},
  {x: 689.0000, y: 196.0000},
  {x: 690.6665, y: 196.3333},
  {x: 692.3335, y: 196.6667},
  {x: 694.0000, y: 197.0000}
];
