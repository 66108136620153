import socialMedia from '@/config/socialMedia'

import ButtonIcon from '@/components/form/buttonIcon'

import FacebookIcon from '@/components/icons/social/facebook'
import InstagramIcon from '@/components/icons/social/instagram'
import TwitterIcon from '@/components/icons/social/twitter'
import DiscordIcon from '@/components/icons/social/discord'

export default {
  name: 'ContactUs',

  components: {
    ButtonIcon,

    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    DiscordIcon
  },

  data() {
    return {
      instagram: socialMedia.instagram,
      facebook: socialMedia.facebook,
      twitter: socialMedia.twitter,
      discord: socialMedia.discord
    }
  }
}
