module.exports = [
  {x: 197.0000, y: 29.0000},
  {x: 189.2053, y: 29.1111},
  {x: 181.9219, y: 28.6825},
  {x: 176.0000, y: 27.0000},
  {x: 172.6670, y: 27.0000},
  {x: 169.3330, y: 27.0000},
  {x: 166.0000, y: 27.0000},
  {x: 166.0000, y: 26.6667},
  {x: 166.0000, y: 26.3333},
  {x: 166.0000, y: 26.0000},
  {x: 162.0004, y: 26.0000},
  {x: 157.9996, y: 26.0000},
  {x: 154.0000, y: 26.0000},
  {x: 154.0000, y: 25.6667},
  {x: 154.0000, y: 25.3333},
  {x: 154.0000, y: 25.0000},
  {x: 150.6670, y: 25.0000},
  {x: 147.3330, y: 25.0000},
  {x: 144.0000, y: 25.0000},
  {x: 144.0000, y: 24.6667},
  {x: 144.0000, y: 24.3333},
  {x: 144.0000, y: 24.0000},
  {x: 141.3336, y: 24.0000},
  {x: 138.6664, y: 24.0000},
  {x: 136.0000, y: 24.0000},
  {x: 136.0000, y: 23.6667},
  {x: 136.0000, y: 23.3333},
  {x: 136.0000, y: 23.0000},
  {x: 132.6670, y: 23.0000},
  {x: 129.3330, y: 23.0000},
  {x: 126.0000, y: 23.0000},
  {x: 126.0000, y: 22.6667},
  {x: 126.0000, y: 22.3333},
  {x: 126.0000, y: 22.0000},
  {x: 111.0015, y: 21.0001},
  {x: 95.9985, y: 19.9999},
  {x: 81.0000, y: 19.0000},
  {x: 73.0008, y: 16.6669},
  {x: 64.9992, y: 14.3331},
  {x: 57.0000, y: 12.0000},
  {x: 57.0000, y: 12.6666},
  {x: 57.0000, y: 13.3334},
  {x: 57.0000, y: 14.0000},
  {x: 55.1988, y: 14.9445},
  {x: 55.4255, y: 15.3847},
  {x: 53.0000, y: 16.0000},
  {x: 53.0000, y: 16.3333},
  {x: 53.0000, y: 16.6667},
  {x: 53.0000, y: 17.0000},
  {x: 48.3338, y: 16.6667},
  {x: 43.6662, y: 16.3333},
  {x: 39.0000, y: 16.0000},
  {x: 39.0000, y: 15.6667},
  {x: 39.0000, y: 15.3333},
  {x: 39.0000, y: 15.0000},
  {x: 38.0001, y: 15.0000},
  {x: 36.9999, y: 15.0000},
  {x: 36.0000, y: 15.0000},
  {x: 36.0000, y: 14.6667},
  {x: 36.0000, y: 14.3333},
  {x: 36.0000, y: 14.0000},
  {x: 35.0001, y: 14.0000},
  {x: 33.9999, y: 14.0000},
  {x: 33.0000, y: 14.0000},
  {x: 33.0000, y: 13.6667},
  {x: 33.0000, y: 13.3333},
  {x: 33.0000, y: 13.0000},
  {x: 32.0001, y: 13.0000},
  {x: 30.9999, y: 13.0000},
  {x: 30.0000, y: 13.0000},
  {x: 30.0000, y: 12.6667},
  {x: 30.0000, y: 12.3333},
  {x: 30.0000, y: 12.0000},
  {x: 29.3334, y: 12.0000},
  {x: 28.6666, y: 12.0000},
  {x: 28.0000, y: 12.0000},
  {x: 28.0000, y: 11.6667},
  {x: 28.0000, y: 11.3333},
  {x: 28.0000, y: 11.0000},
  {x: 26.0002, y: 10.6667},
  {x: 23.9998, y: 10.3333},
  {x: 22.0000, y: 10.0000},
  {x: 22.0000, y: 9.6667},
  {x: 22.0000, y: 9.3333},
  {x: 22.0000, y: 9.0000},
  {x: 21.3334, y: 9.0000},
  {x: 20.6666, y: 9.0000},
  {x: 20.0000, y: 9.0000},
  {x: 20.0000, y: 8.6667},
  {x: 20.0000, y: 8.3333},
  {x: 20.0000, y: 8.0000},
  {x: 19.0001, y: 8.0000},
  {x: 17.9999, y: 8.0000},
  {x: 17.0000, y: 8.0000},
  {x: 17.0000, y: 7.6667},
  {x: 17.0000, y: 7.3333},
  {x: 17.0000, y: 7.0000},
  {x: 15.0002, y: 6.3334},
  {x: 12.9998, y: 5.6666},
  {x: 11.0000, y: 5.0000},
  {x: 11.0000, y: 4.6667},
  {x: 11.0000, y: 4.3333},
  {x: 11.0000, y: 4.0000},
  {x: 10.3334, y: 4.0000},
  {x: 9.6666, y: 4.0000},
  {x: 9.0000, y: 4.0000},
  {x: 9.0000, y: 3.6667},
  {x: 9.0000, y: 3.3333},
  {x: 9.0000, y: 3.0000},
  {x: 8.0001, y: 3.0000},
  {x: 6.9999, y: 3.0000},
  {x: 6.0000, y: 3.0000},
  {x: 5.6667, y: 2.3334},
  {x: 5.3333, y: 1.6666},
  {x: 5.0000, y: 1.0000},
  {x: 4.0001, y: 0.6667},
  {x: 2.9999, y: 0.3333},
  {x: 2.0000, y: 0.0000},
  {x: 2.0000, y: 0.6666},
  {x: 2.0000, y: 1.3334},
  {x: 2.0000, y: 2.0000},
  {x: 1.6667, y: 2.0000},
  {x: 1.3333, y: 2.0000},
  {x: 1.0000, y: 2.0000},
  {x: 1.0000, y: 4.6664},
  {x: 1.0000, y: 7.3336},
  {x: 1.0000, y: 10.0000},
  {x: 0.6667, y: 10.0000},
  {x: 0.3333, y: 10.0000},
  {x: 0.0000, y: 10.0000},
  {x: 0.0000, y: 18.6678},
  {x: 1.7682, y: 27.2259},
  {x: 3.0000, y: 33.0000},
  {x: 5.9997, y: 34.9998},
  {x: 9.0003, y: 37.0002},
  {x: 12.0000, y: 39.0000},
  {x: 12.9999, y: 39.0000},
  {x: 14.0001, y: 39.0000},
  {x: 15.0000, y: 39.0000},
  {x: 15.0000, y: 39.3333},
  {x: 15.0000, y: 39.6667},
  {x: 15.0000, y: 40.0000},
  {x: 15.9999, y: 40.0000},
  {x: 17.0001, y: 40.0000},
  {x: 18.0000, y: 40.0000},
  {x: 18.0000, y: 40.3333},
  {x: 18.0000, y: 40.6667},
  {x: 18.0000, y: 41.0000},
  {x: 19.3332, y: 41.0000},
  {x: 20.6668, y: 41.0000},
  {x: 22.0000, y: 41.0000},
  {x: 22.0000, y: 41.3333},
  {x: 22.0000, y: 41.6667},
  {x: 22.0000, y: 42.0000},
  {x: 23.9998, y: 42.0000},
  {x: 26.0002, y: 42.0000},
  {x: 28.0000, y: 42.0000},
  {x: 28.0000, y: 42.3333},
  {x: 28.0000, y: 42.6667},
  {x: 28.0000, y: 43.0000},
  {x: 30.3331, y: 43.0000},
  {x: 32.6669, y: 43.0000},
  {x: 35.0000, y: 43.0000},
  {x: 35.0000, y: 43.3333},
  {x: 35.0000, y: 43.6667},
  {x: 35.0000, y: 44.0000},
  {x: 43.3325, y: 44.3333},
  {x: 51.6675, y: 44.6667},
  {x: 60.0000, y: 45.0000},
  {x: 67.3282, y: 47.0308},
  {x: 94.6830, y: 47.0539},
  {x: 102.000, y: 45.0000},
  {x: 105.6663, y: 45.0000},
  {x: 109.3337, y: 45.0000},
  {x: 113.0000, y: 45.0000},
  {x: 118.3328, y: 44.6667},
  {x: 123.6672, y: 44.3333},
  {x: 129.0000, y: 44.0000},
  {x: 129.0000, y: 43.6667},
  {x: 129.0000, y: 43.3333},
  {x: 129.0000, y: 43.0000},
  {x: 131.9997, y: 43.0000},
  {x: 135.0003, y: 43.0000},
  {x: 138.0000, y: 43.0000},
  {x: 138.0000, y: 42.6667},
  {x: 138.0000, y: 42.3333},
  {x: 138.0000, y: 42.0000},
  {x: 140.9997, y: 42.0000},
  {x: 144.0003, y: 42.0000},
  {x: 147.0000, y: 42.0000},
  {x: 152.6030, y: 40.4007},
  {x: 160.5207, y: 39.6154},
  {x: 166.0000, y: 38.0000},
  {x: 171.9994, y: 37.3334},
  {x: 178.0006, y: 36.6666},
  {x: 184.0000, y: 36.0000},
  {x: 187.9996, y: 34.6668},
  {x: 192.0004, y: 33.3332},
  {x: 196.0000, y: 32.0000},
  {x: 196.3333, y: 31.0001},
  {x: 196.6667, y: 29.9999},
  {x: 197.0000, y: 29.0000}
];
