import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import VueHtml2Canvas from 'vue-html2canvas'
import VueSocialSharing from 'vue-social-sharing'
import regeneratorRuntime from 'regenerator-runtime'

import App from '@/views/app'
import router from '@/router'

import 'vue-toast-notification/dist/theme-sugar.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@/assets/static'

Vue.use(VueHtml2Canvas)
Vue.use(VueSocialSharing)

Vue.use(VueToast, {
  queue: true,
  position: 'top',
  duration: 3000,
  dismissible: true
})

import ProgressBar from 'vuejs-progress-bar'
Vue.use(ProgressBar)

Vue.config.productionTip = true

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
