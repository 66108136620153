module.exports = [
  // First Part
  {x: 176.0000, y: 187.0000},
  {x: 176.9999, y: 184.0003},
  {x: 178.0001, y: 180.9997},
  {x: 179.0000, y: 178.0000},
  {x: 179.3333, y: 178.0000},
  {x: 179.6667, y: 178.0000},
  {x: 180.0000, y: 178.0000},
  {x: 180.0000, y: 177.0001},
  {x: 180.0000, y: 175.9999},
  {x: 180.0000, y: 175.0000},
  {x: 180.3333, y: 175.0000},
  {x: 180.6667, y: 175.0000},
  {x: 181.0000, y: 175.0000},
  {x: 181.6666, y: 173.0002},
  {x: 182.3334, y: 170.9998},
  {x: 183.0000, y: 169.0000},
  {x: 183.3333, y: 169.0000},
  {x: 183.6667, y: 169.0000},
  {x: 184.0000, y: 169.0000},
  {x: 184.0000, y: 168.3334},
  {x: 184.0000, y: 167.6666},
  {x: 184.0000, y: 167.0000},
  {x: 184.3333, y: 167.0000},
  {x: 184.6667, y: 167.0000},
  {x: 185.0000, y: 167.0000},
  {x: 185.6666, y: 165.0002},
  {x: 186.3334, y: 162.9998},
  {x: 187.0000, y: 161.0000},
  {x: 187.6666, y: 160.6667},
  {x: 188.3334, y: 160.3333},
  {x: 189.0000, y: 160.0000},
  {x: 189.6666, y: 158.0002},
  {x: 190.3334, y: 155.9998},
  {x: 191.0000, y: 154.0000},
  {x: 191.3333, y: 154.0000},
  {x: 191.6667, y: 154.0000},
  {x: 192.0000, y: 154.0000},
  {x: 192.3333, y: 153.0001},
  {x: 192.6667, y: 151.9999},
  {x: 193.0000, y: 151.0000},
  {x: 193.6666, y: 150.6667},
  {x: 194.3334, y: 150.3333},
  {x: 195.0000, y: 150.0000},
  {x: 195.3333, y: 148.6668},
  {x: 195.6667, y: 147.3332},
  {x: 196.0000, y: 146.0000},
  {x: 196.6666, y: 145.6667},
  {x: 197.3334, y: 145.3333},
  {x: 198.0000, y: 145.0000},
  {x: 198.0000, y: 144.3334},
  {x: 198.0000, y: 143.6666},
  {x: 198.0000, y: 143.0000},
  {x: 198.9999, y: 142.3334},
  {x: 200.0001, y: 141.6666},
  {x: 201.0000, y: 141.0000},
  {x: 203.0086, y: 138.1354},
  {x: 202.9315, y: 135.8040},
  {x: 206.0000, y: 134.0000},
  {x: 206.0000, y: 133.6667},
  {x: 206.0000, y: 133.3333},
  {x: 206.0000, y: 133.0000},
  {x: 205.3334, y: 133.0000},
  {x: 204.6666, y: 133.0000},
  {x: 204.0000, y: 133.0000},
  {x: 201.3672, y: 130.7428},
  {x: 198.7279, y: 132.1926},
  {x: 195.0000, y: 131.0000},
  {x: 195.0000, y: 130.6667},
  {x: 195.0000, y: 130.3333},
  {x: 195.0000, y: 130.0000},
  {x: 193.3335, y: 130.0000},
  {x: 191.6665, y: 130.0000},
  {x: 190.0000, y: 130.0000},
  {x: 190.0000, y: 129.6667},
  {x: 190.0000, y: 129.3333},
  {x: 190.0000, y: 129.0000},
  {x: 187.3336, y: 128.6667},
  {x: 184.6664, y: 128.3333},
  {x: 182.0000, y: 128.0000},
  {x: 182.0000, y: 127.6667},
  {x: 182.0000, y: 127.3333},
  {x: 182.0000, y: 127.0000},
  {x: 178.3337, y: 126.3334},
  {x: 174.6663, y: 125.6666},
  {x: 171.0000, y: 125.0000},
  {x: 171.0000, y: 124.6667},
  {x: 171.0000, y: 124.3333},
  {x: 171.0000, y: 124.0000},
  {x: 170.0001, y: 124.0000},
  {x: 168.9999, y: 124.0000},
  {x: 168.0000, y: 124.0000},
  {x: 168.0000, y: 123.6667},
  {x: 168.0000, y: 123.3333},
  {x: 168.0000, y: 123.0000},
  {x: 167.0001, y: 123.0000},
  {x: 165.9999, y: 123.0000},
  {x: 165.0000, y: 123.0000},
  {x: 165.0000, y: 122.6667},
  {x: 165.0000, y: 122.3333},
  {x: 165.0000, y: 122.0000},
  {x: 163.6668, y: 122.0000},
  {x: 162.3332, y: 122.0000},
  {x: 161.0000, y: 122.0000},
  {x: 130.3115, y: 110.8426},
  {x: 97.9461, y: 101.8151},
  {x: 69.0000, y: 89.0000},
  {x: 68.0001, y: 89.0000},
  {x: 66.9999, y: 89.0000},
  {x: 66.0000, y: 89.0000},
  {x: 66.0000, y: 88.6667},
  {x: 66.0000, y: 88.3333},
  {x: 66.0000, y: 88.0000},
  {x: 65.3334, y: 88.0000},
  {x: 64.6666, y: 88.0000},
  {x: 64.0000, y: 88.0000},
  {x: 64.0000, y: 87.6667},
  {x: 64.0000, y: 87.3333},
  {x: 64.0000, y: 87.0000},
  {x: 63.0001, y: 87.0000},
  {x: 61.9999, y: 87.0000},
  {x: 61.0000, y: 87.0000},
  {x: 61.0000, y: 86.6667},
  {x: 61.0000, y: 86.3333},
  {x: 61.0000, y: 86.0000},
  {x: 60.3334, y: 86.0000},
  {x: 59.6666, y: 86.0000},
  {x: 59.0000, y: 86.0000},
  {x: 59.0000, y: 85.6667},
  {x: 59.0000, y: 85.3333},
  {x: 59.0000, y: 85.0000},
  {x: 58.0001, y: 85.0000},
  {x: 56.9999, y: 85.0000},
  {x: 56.0000, y: 85.0000},
  {x: 56.0000, y: 84.6667},
  {x: 56.0000, y: 84.3333},
  {x: 56.0000, y: 84.0000},
  {x: 55.3334, y: 84.0000},
  {x: 54.6666, y: 84.0000},
  {x: 54.0000, y: 84.0000},
  {x: 54.0000, y: 83.6667},
  {x: 54.0000, y: 83.3333},
  {x: 54.0000, y: 83.0000},
  {x: 53.0001, y: 83.0000},
  {x: 51.9999, y: 83.0000},
  {x: 51.0000, y: 83.0000},
  {x: 51.0000, y: 82.6667},
  {x: 51.0000, y: 82.3333},
  {x: 51.0000, y: 82.0000},
  {x: 49.3335, y: 81.6667},
  {x: 47.6665, y: 81.3333},
  {x: 46.0000, y: 81.0000},
  {x: 46.0000, y: 80.6667},
  {x: 46.0000, y: 80.3333},
  {x: 46.0000, y: 80.0000},
  {x: 45.3334, y: 80.0000},
  {x: 44.6666, y: 80.0000},
  {x: 44.0000, y: 80.0000},
  {x: 44.0000, y: 79.6667},
  {x: 44.0000, y: 79.3333},
  {x: 44.0000, y: 79.0000},
  {x: 42.3335, y: 78.6667},
  {x: 40.6665, y: 78.3333},
  {x: 39.0000, y: 78.0000},
  {x: 39.0000, y: 77.6667},
  {x: 39.0000, y: 77.3333},
  {x: 39.0000, y: 77.0000},
  {x: 38.3334, y: 77.0000},
  {x: 37.6666, y: 77.0000},
  {x: 37.0000, y: 77.0000},
  {x: 37.0000, y: 76.6667},
  {x: 37.0000, y: 76.3333},
  {x: 37.0000, y: 76.0000},
  {x: 36.0001, y: 76.0000},
  {x: 34.9999, y: 76.0000},
  {x: 34.0000, y: 76.0000},
  {x: 34.0000, y: 75.6667},
  {x: 34.0000, y: 75.3333},
  {x: 34.0000, y: 75.0000},
  {x: 33.3334, y: 75.0000},
  {x: 32.6666, y: 75.0000},
  {x: 32.0000, y: 75.0000},
  {x: 32.0000, y: 74.6667},
  {x: 32.0000, y: 74.3333},
  {x: 32.0000, y: 74.0000},
  {x: 31.3334, y: 74.0000},
  {x: 30.6666, y: 74.0000},
  {x: 30.0000, y: 74.0000},
  {x: 29.6667, y: 73.3334},
  {x: 29.3333, y: 72.6666},
  {x: 29.0000, y: 72.0000},
  {x: 28.6667, y: 72.0000},
  {x: 28.3333, y: 72.0000},
  {x: 28.0000, y: 72.0000},
  {x: 28.0000, y: 72.3333},
  {x: 28.0000, y: 72.6667},
  {x: 28.0000, y: 73.0000},
  {x: 25.6669, y: 74.9998},
  {x: 23.3331, y: 77.0002},
  {x: 21.0000, y: 79.0000},
  {x: 21.0000, y: 79.6666},
  {x: 21.0000, y: 80.3334},
  {x: 21.0000, y: 81.0000},
  {x: 20.3334, y: 81.3333},
  {x: 19.6666, y: 81.6667},
  {x: 19.0000, y: 82.0000},
  {x: 19.0000, y: 82.6666},
  {x: 19.0000, y: 83.3334},
  {x: 19.0000, y: 84.0000},
  {x: 18.3334, y: 84.3333},
  {x: 17.6666, y: 84.6667},
  {x: 17.0000, y: 85.0000},
  {x: 17.0000, y: 85.6666},
  {x: 17.0000, y: 86.3334},
  {x: 17.0000, y: 87.0000},
  {x: 16.3334, y: 87.3333},
  {x: 15.6666, y: 87.6667},
  {x: 15.0000, y: 88.0000},
  {x: 14.6667, y: 89.3332},
  {x: 14.3333, y: 90.6668},
  {x: 14.0000, y: 92.0000},
  {x: 13.3334, y: 92.3333},
  {x: 12.6666, y: 92.6667},
  {x: 12.0000, y: 93.0000},
  {x: 11.6667, y: 94.3332},
  {x: 11.3333, y: 95.6668},
  {x: 11.0000, y: 97.0000},
  {x: 10.3334, y: 97.3333},
  {x: 9.6666, y: 97.6667},
  {x: 9.0000, y: 98.0000},
  {x: 8.6667, y: 99.3332},
  {x: 8.3333, y: 100.6668},
  {x: 8.0000, y: 102.0000},
  {x: 7.3334, y: 102.3333},
  {x: 6.6666, y: 102.6667},
  {x: 6.0000, y: 103.0000},
  {x: 6.0000, y: 103.6666},
  {x: 6.0000, y: 104.3334},
  {x: 6.0000, y: 105.0000},
  {x: 5.6667, y: 105.0000},
  {x: 5.3333, y: 105.0000},
  {x: 5.0000, y: 105.0000},
  {x: 5.0000, y: 109.6662},
  {x: 5.0000, y: 114.3338},
  {x: 5.0000, y: 119.0000},
  {x: 4.6667, y: 119.0000},
  {x: 4.3333, y: 119.0000},
  {x: 4.0000, y: 119.0000},
  {x: 4.0000, y: 124.3328},
  {x: 4.0000, y: 129.6672},
  {x: 4.0000, y: 135.0000},
  {x: 3.6667, y: 135.0000},
  {x: 3.3333, y: 135.0000},
  {x: 3.0000, y: 135.0000},
  {x: 3.0000, y: 140.3328},
  {x: 3.0000, y: 145.6672},
  {x: 3.0000, y: 151.0000},
  {x: 3.0000, y: 153.3331},
  {x: 3.0000, y: 155.6669},
  {x: 3.0000, y: 158.0000},
  {x: 2.6667, y: 158.0000},
  {x: 2.3333, y: 158.0000},
  {x: 2.0000, y: 158.0000},
  {x: 2.0000, y: 159.9998},
  {x: 2.0000, y: 162.0002},
  {x: 2.0000, y: 164.0000},
  {x: 2.3333, y: 164.0000},
  {x: 2.6667, y: 164.0000},
  {x: 3.0000, y: 164.0000},
  {x: 2.6667, y: 167.9996},
  {x: 2.3333, y: 172.0004},
  {x: 2.0000, y: 176.0000},
  {x: 5.9996, y: 175.6667},
  {x: 10.0004, y: 175.3333},
  {x: 14.0000, y: 175.0000},
  {x: 14.0000, y: 175.3333},
  {x: 14.0000, y: 175.6667},
  {x: 14.0000, y: 176.0000},
  {x: 14.9999, y: 176.0000},
  {x: 16.0001, y: 176.0000},
  {x: 17.0000, y: 176.0000},
  {x: 19.9997, y: 176.0000},
  {x: 23.0003, y: 176.0000},
  {x: 26.0000, y: 176.0000},
  {x: 26.0000, y: 176.3333},
  {x: 26.0000, y: 176.6667},
  {x: 26.0000, y: 177.0000},
  {x: 34.9991, y: 177.0000},
  {x: 44.0009, y: 177.0000},
  {x: 53.0000, y: 177.0000},
  {x: 53.0000, y: 177.3333},
  {x: 53.0000, y: 177.6667},
  {x: 53.0000, y: 178.0000},
  {x: 66.6653, y: 178.6666},
  {x: 80.3347, y: 179.3334},
  {x: 94.0000, y: 180.0000},
  {x: 94.0000, y: 180.3333},
  {x: 94.0000, y: 180.6667},
  {x: 94.0000, y: 181.0000},
  {x: 100.9993, y: 181.3333},
  {x: 108.0007, y: 181.6667},
  {x: 115.0000, y: 182.0000},
  {x: 118.3330, y: 182.0000},
  {x: 121.6670, y: 182.0000},
  {x: 125.0000, y: 182.0000},
  {x: 125.0000, y: 182.3333},
  {x: 125.0000, y: 182.6667},
  {x: 125.0000, y: 183.0000},
  {x: 132.3326, y: 183.3333},
  {x: 139.6674, y: 183.6667},
  {x: 147.0000, y: 184.0000},
  {x: 150.6663, y: 184.0000},
  {x: 154.3337, y: 184.0000},
  {x: 158.0000, y: 184.0000},
  {x: 163.0939, y: 185.4472},
  {x: 170.0401, y: 186.8018},
  {x: 176.0000, y: 187.0000},

  // Second Part
  {x: 229.0000, y: 103.0000},
  {x: 231.3331, y: 103.0000},
  {x: 233.6669, y: 103.0000},
  {x: 236.0000, y: 103.0000},
  {x: 236.0000, y: 102.6667},
  {x: 236.0000, y: 102.3333},
  {x: 236.0000, y: 102.0000},
  {x: 242.2859, y: 100.2751},
  {x: 245.9126, y: 94.0873},
  {x: 250.0000, y: 90.0000},
  {x: 253.6663, y: 86.6670},
  {x: 257.3337, y: 83.3330},
  {x: 261.0000, y: 80.0000},
  {x: 261.0000, y: 79.3334},
  {x: 261.0000, y: 78.6666},
  {x: 261.0000, y: 78.0000},
  {x: 262.6665, y: 76.6668},
  {x: 264.3335, y: 75.3332},
  {x: 266.0000, y: 74.0000},
  {x: 266.0000, y: 73.3334},
  {x: 266.0000, y: 72.6666},
  {x: 266.0000, y: 72.0000},
  {x: 266.9999, y: 71.3334},
  {x: 268.0001, y: 70.6666},
  {x: 269.0000, y: 70.0000},
  {x: 269.0000, y: 69.3334},
  {x: 269.0000, y: 68.6666},
  {x: 269.0000, y: 68.0000},
  {x: 269.9999, y: 67.3334},
  {x: 271.0001, y: 66.6666},
  {x: 272.0000, y: 66.0000},
  {x: 272.0000, y: 65.3334},
  {x: 272.0000, y: 64.6666},
  {x: 272.0000, y: 64.0000},
  {x: 272.6666, y: 63.6667},
  {x: 273.3334, y: 63.3333},
  {x: 274.0000, y: 63.0000},
  {x: 274.3333, y: 62.0001},
  {x: 274.6667, y: 60.9999},
  {x: 275.0000, y: 60.0000},
  {x: 275.3333, y: 60.0000},
  {x: 275.6667, y: 60.0000},
  {x: 276.0000, y: 60.0000},
  {x: 276.0000, y: 59.3334},
  {x: 276.0000, y: 58.6666},
  {x: 276.0000, y: 58.0000},
  {x: 276.6666, y: 57.6667},
  {x: 277.3334, y: 57.3333},
  {x: 278.0000, y: 57.0000},
  {x: 278.0000, y: 56.3334},
  {x: 278.0000, y: 55.6666},
  {x: 278.0000, y: 55.0000},
  {x: 278.6666, y: 54.6667},
  {x: 279.3334, y: 54.3333},
  {x: 280.0000, y: 54.0000},
  {x: 280.3333, y: 53.0001},
  {x: 280.6667, y: 51.9999},
  {x: 281.0000, y: 51.0000},
  {x: 281.3333, y: 51.0000},
  {x: 281.6667, y: 51.0000},
  {x: 282.0000, y: 51.0000},
  {x: 282.0000, y: 50.3334},
  {x: 282.0000, y: 49.6666},
  {x: 282.0000, y: 49.0000},
  {x: 282.3333, y: 49.0000},
  {x: 282.6667, y: 49.0000},
  {x: 283.0000, y: 49.0000},
  {x: 283.0000, y: 48.3334},
  {x: 283.0000, y: 47.6666},
  {x: 283.0000, y: 47.0000},
  {x: 283.3333, y: 47.0000},
  {x: 283.6667, y: 47.0000},
  {x: 284.0000, y: 47.0000},
  {x: 284.0000, y: 46.3334},
  {x: 284.0000, y: 45.6666},
  {x: 284.0000, y: 45.0000},
  {x: 284.6666, y: 44.6667},
  {x: 285.3334, y: 44.3333},
  {x: 286.0000, y: 44.0000},
  {x: 287.7754, y: 41.1724},
  {x: 287.5977, y: 38.0888},
  {x: 290.0000, y: 36.0000},
  {x: 289.4264, y: 33.9902},
  {x: 290.1200, y: 35.1348},
  {x: 289.0000, y: 34.0000},
  {x: 287.2735, y: 32.0092},
  {x: 286.6748, y: 33.1555},
  {x: 285.0000, y: 32.0000},
  {x: 281.3337, y: 28.0004},
  {x: 277.6663, y: 23.9996},
  {x: 274.0000, y: 20.0000},
  {x: 273.3334, y: 20.0000},
  {x: 272.6666, y: 20.0000},
  {x: 272.0000, y: 20.0000},
  {x: 271.0001, y: 18.6668},
  {x: 269.9999, y: 17.3332},
  {x: 269.0000, y: 16.0000},
  {x: 268.3334, y: 16.0000},
  {x: 267.6666, y: 16.0000},
  {x: 267.0000, y: 16.0000},
  {x: 266.6667, y: 15.3334},
  {x: 266.3333, y: 14.6666},
  {x: 266.0000, y: 14.0000},
  {x: 265.0001, y: 13.6667},
  {x: 263.9999, y: 13.3333},
  {x: 263.0000, y: 13.0000},
  {x: 263.0000, y: 12.6667},
  {x: 263.0000, y: 12.3333},
  {x: 263.0000, y: 12.0000},
  {x: 262.3334, y: 12.0000},
  {x: 261.6666, y: 12.0000},
  {x: 261.0000, y: 12.0000},
  {x: 261.0000, y: 11.6667},
  {x: 261.0000, y: 11.3333},
  {x: 261.0000, y: 11.0000},
  {x: 260.3334, y: 11.0000},
  {x: 259.6666, y: 11.0000},
  {x: 259.0000, y: 11.0000},
  {x: 259.0000, y: 10.6667},
  {x: 259.0000, y: 10.3333},
  {x: 259.0000, y: 10.0000},
  {x: 258.3334, y: 10.0000},
  {x: 257.6666, y: 10.0000},
  {x: 257.0000, y: 10.0000},
  {x: 256.6667, y: 9.3334},
  {x: 256.3333, y: 8.6666},
  {x: 256.0000, y: 8.0000},
  {x: 255.3334, y: 8.0000},
  {x: 254.6666, y: 8.0000},
  {x: 254.0000, y: 8.0000},
  {x: 254.0000, y: 7.6667},
  {x: 254.0000, y: 7.3333},
  {x: 254.0000, y: 7.0000},
  {x: 252.0002, y: 6.3334},
  {x: 249.9998, y: 5.6666},
  {x: 248.0000, y: 5.0000},
  {x: 248.0000, y: 4.6667},
  {x: 248.0000, y: 4.3333},
  {x: 248.0000, y: 4.0000},
  {x: 245.3164, y: 2.8388},
  {x: 242.3334, y: 2.9943},
  {x: 240.0000, y: 2.0000},
  {x: 240.0000, y: 2.3333},
  {x: 240.0000, y: 2.6667},
  {x: 240.0000, y: 3.0000},
  {x: 232.6579, y: 4.5828},
  {x: 226.9503, y: 14.5321},
  {x: 223.0000, y: 20.0000},
  {x: 222.0001, y: 20.6666},
  {x: 220.9999, y: 21.3334},
  {x: 220.0000, y: 22.0000},
  {x: 218.0002, y: 24.3331},
  {x: 215.9998, y: 26.6669},
  {x: 214.0000, y: 29.0000},
  {x: 213.3334, y: 29.0000},
  {x: 212.6666, y: 29.0000},
  {x: 212.0000, y: 29.0000},
  {x: 210.6668, y: 30.6665},
  {x: 209.3332, y: 32.3335},
  {x: 208.0000, y: 34.0000},
  {x: 207.3334, y: 34.0000},
  {x: 206.6666, y: 34.0000},
  {x: 206.0000, y: 34.0000},
  {x: 205.3334, y: 34.9999},
  {x: 204.6666, y: 36.0001},
  {x: 204.0000, y: 37.0000},
  {x: 203.3334, y: 37.0000},
  {x: 202.6666, y: 37.0000},
  {x: 202.0000, y: 37.0000},
  {x: 201.6667, y: 37.6666},
  {x: 201.3333, y: 38.3334},
  {x: 201.0000, y: 39.0000},
  {x: 200.0001, y: 39.3333},
  {x: 198.9999, y: 39.6667},
  {x: 198.0000, y: 40.0000},
  {x: 198.0000, y: 40.3333},
  {x: 198.0000, y: 40.6667},
  {x: 198.0000, y: 41.0000},
  {x: 197.3334, y: 41.0000},
  {x: 196.6666, y: 41.0000},
  {x: 196.0000, y: 41.0000},
  {x: 195.6667, y: 41.6666},
  {x: 195.3333, y: 42.3334},
  {x: 195.0000, y: 43.0000},
  {x: 193.6668, y: 43.3333},
  {x: 192.3332, y: 43.6667},
  {x: 191.0000, y: 44.0000},
  {x: 190.6667, y: 44.6666},
  {x: 190.3333, y: 45.3334},
  {x: 190.0000, y: 46.0000},
  {x: 189.3334, y: 46.0000},
  {x: 188.6666, y: 46.0000},
  {x: 188.0000, y: 46.0000},
  {x: 188.0000, y: 46.3333},
  {x: 188.0000, y: 46.6667},
  {x: 188.0000, y: 47.0000},
  {x: 187.3334, y: 47.0000},
  {x: 186.6666, y: 47.0000},
  {x: 186.0000, y: 47.0000},
  {x: 186.0000, y: 47.3333},
  {x: 186.0000, y: 47.6667},
  {x: 186.0000, y: 48.0000},
  {x: 185.3334, y: 48.0000},
  {x: 184.6666, y: 48.0000},
  {x: 184.0000, y: 48.0000},
  {x: 184.0000, y: 48.3333},
  {x: 184.0000, y: 48.6667},
  {x: 184.0000, y: 49.0000},
  {x: 183.3334, y: 49.0000},
  {x: 182.6666, y: 49.0000},
  {x: 182.0000, y: 49.0000},
  {x: 182.0000, y: 49.3333},
  {x: 182.0000, y: 49.6667},
  {x: 182.0000, y: 50.0000},
  {x: 181.3334, y: 50.0000},
  {x: 180.6666, y: 50.0000},
  {x: 180.0000, y: 50.0000},
  {x: 180.0000, y: 50.3333},
  {x: 180.0000, y: 50.6667},
  {x: 180.0000, y: 51.0000},
  {x: 179.3334, y: 51.0000},
  {x: 178.6666, y: 51.0000},
  {x: 178.0000, y: 51.0000},
  {x: 178.0000, y: 51.3333},
  {x: 178.0000, y: 51.6667},
  {x: 178.0000, y: 52.0000},
  {x: 177.3334, y: 52.0000},
  {x: 176.6666, y: 52.0000},
  {x: 176.0000, y: 52.0000},
  {x: 176.0000, y: 52.3333},
  {x: 176.0000, y: 52.6667},
  {x: 176.0000, y: 53.0000},
  {x: 174.0002, y: 53.6666},
  {x: 171.9998, y: 54.3334},
  {x: 170.0000, y: 55.0000},
  {x: 170.0000, y: 55.3333},
  {x: 170.0000, y: 55.6667},
  {x: 170.0000, y: 56.0000},
  {x: 168.0002, y: 56.3333},
  {x: 165.9998, y: 56.6667},
  {x: 164.0000, y: 57.0000},
  {x: 164.0000, y: 57.3333},
  {x: 164.0000, y: 57.6667},
  {x: 164.0000, y: 58.0000},
  {x: 163.3334, y: 58.0000},
  {x: 162.6666, y: 58.0000},
  {x: 162.0000, y: 58.0000},
  {x: 162.0000, y: 58.3333},
  {x: 162.0000, y: 58.6667},
  {x: 162.0000, y: 59.0000},
  {x: 161.0001, y: 59.0000},
  {x: 159.9999, y: 59.0000},
  {x: 159.0000, y: 59.0000},
  {x: 147.1371, y: 63.7468},
  {x: 125.6883, y: 68.2997},
  {x: 111.0000, y: 64.0000},
  {x: 109.0002, y: 64.0000},
  {x: 106.9998, y: 64.0000},
  {x: 105.0000, y: 64.0000},
  {x: 105.0000, y: 63.6667},
  {x: 105.0000, y: 63.3333},
  {x: 105.0000, y: 63.0000},
  {x: 103.6668, y: 63.0000},
  {x: 102.3332, y: 63.0000},
  {x: 101.0000, y: 63.0000},
  {x: 101.0000, y: 62.6667},
  {x: 101.0000, y: 62.3333},
  {x: 101.0000, y: 62.0000},
  {x: 100.0001, y: 62.0000},
  {x: 98.9999, y: 62.0000},
  {x: 98.0000, y: 62.0000},
  {x: 98.0000, y: 61.6667},
  {x: 98.0000, y: 61.3333},
  {x: 98.0000, y: 61.0000},
  {x: 97.0001, y: 61.0000},
  {x: 95.9999, y: 61.0000},
  {x: 95.0000, y: 61.0000},
  {x: 95.0000, y: 60.6667},
  {x: 95.0000, y: 60.3333},
  {x: 95.0000, y: 60.0000},
  {x: 94.3334, y: 60.0000},
  {x: 93.6666, y: 60.0000},
  {x: 93.0000, y: 60.0000},
  {x: 93.0000, y: 59.6667},
  {x: 93.0000, y: 59.3333},
  {x: 93.0000, y: 59.0000},
  {x: 92.0001, y: 59.0000},
  {x: 90.9999, y: 59.0000},
  {x: 90.0000, y: 59.0000},
  {x: 90.0000, y: 58.6667},
  {x: 90.0000, y: 58.3333},
  {x: 90.0000, y: 58.0000},
  {x: 89.3334, y: 58.0000},
  {x: 88.6666, y: 58.0000},
  {x: 88.0000, y: 58.0000},
  {x: 85.0077, y: 56.4509},
  {x: 83.4532, y: 54.5079},
  {x: 79.0000, y: 54.0000},
  {x: 76.1972, y: 57.2413},
  {x: 72.1392, y: 57.9823},
  {x: 68.0000, y: 60.0000},
  {x: 68.0000, y: 60.3333},
  {x: 68.0000, y: 60.6667},
  {x: 68.0000, y: 61.0000},
  {x: 66.0002, y: 61.3333},
  {x: 63.9998, y: 61.6667},
  {x: 62.0000, y: 62.0000},
  {x: 62.0000, y: 62.3333},
  {x: 62.0000, y: 62.6667},
  {x: 62.0000, y: 63.0000},
  {x: 62.3333, y: 63.0000},
  {x: 62.6667, y: 63.0000},
  {x: 63.0000, y: 63.0000},
  {x: 63.0000, y: 63.3333},
  {x: 63.0000, y: 63.6667},
  {x: 63.0000, y: 64.0000},
  {x: 66.3330, y: 64.6666},
  {x: 69.6670, y: 65.3334},
  {x: 73.0000, y: 66.0000},
  {x: 73.0000, y: 66.3333},
  {x: 73.0000, y: 66.6667},
  {x: 73.0000, y: 67.0000},
  {x: 76.3330, y: 67.6666},
  {x: 79.6670, y: 68.3334},
  {x: 83.0000, y: 69.0000},
  {x: 83.0000, y: 69.3333},
  {x: 83.0000, y: 69.6667},
  {x: 83.0000, y: 70.0000},
  {x: 84.3332, y: 70.0000},
  {x: 85.6668, y: 70.0000},
  {x: 87.0000, y: 70.0000},
  {x: 87.0000, y: 70.3333},
  {x: 87.0000, y: 70.6667},
  {x: 87.0000, y: 71.0000},
  {x: 89.3331, y: 71.3333},
  {x: 91.6669, y: 71.6667},
  {x: 94.0000, y: 72.0000},
  {x: 94.0000, y: 72.3333},
  {x: 94.0000, y: 72.6667},
  {x: 94.0000, y: 73.0000},
  {x: 95.3332, y: 73.0000},
  {x: 96.6668, y: 73.0000},
  {x: 98.0000, y: 73.0000},
  {x: 117.4935, y: 80.0609},
  {x: 140.7600, y: 83.4715},
  {x: 161.0000, y: 90.0000},
  {x: 162.6665, y: 90.0000},
  {x: 164.3335, y: 90.0000},
  {x: 166.0000, y: 90.0000},
  {x: 171.6686, y: 91.7909},
  {x: 179.2695, y: 93.2057},
  {x: 185.0000, y: 95.0000},
  {x: 188.6663, y: 95.3333},
  {x: 192.3337, y: 95.6667},
  {x: 196.0000, y: 96.0000},
  {x: 202.4371, y: 97.9722},
  {x: 211.3979, y: 98.9641},
  {x: 218.0000, y: 101.0000},
  {x: 221.7436, y: 102.1544},
  {x: 226.5050, y: 100.7319},
  {x: 229.0000, y: 103.0000}
];
