module.exports = [
  'Afghanistan',
  'Albania',
  'Germany',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Saudi Arabia',
  'Algeria',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'The Bahamas',
  'Bangladesh',
  'Barbados',
  'Bahrain',
  'Belgium',
  'Belize',
  'Benin',
  'Belarus',
  'Burma',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Bhutan',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Qatar',
  'Chad',
  'Chile',
  'China',
  'Cyprus',
  'Holy See',
  'Colombia',
  'Comoros',
  'North Korea',
  'South Korea',
  'Cote d’Ivoire',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curacao',
  'Denmark',
  'Dominica',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'United Arab Emirates',
  'Eritrea',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Palestinian Territories',
  'Micronesia',
  'Estonia',
  'Ethiopia',
  'Philippines',
  'Finland',
  'Fiji',
  'France',
  'Gabon',
  'The Gambia',
  'Georgia',
  'Ghana',
  'Grenada',
  'Greece',
  'Guatemala',
  'Guinea',
  'Equatorial Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'India',
  'Indonesia',
  'Iraq',
  'Iran',
  'Ireland',
  'Iceland',
  'Marshall Islands',
  'Solomon Islands',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kyrgyzstan',
  'Kiribati',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Latvia',
  'Lebanon',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Madagascar',
  'Malaysia',
  'Malawi',
  'Maldives',
  'Mali',
  'Malta',
  'Morocco',
  'Mauritius',
  'Mauritania',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'New Zealand',
  'Oman',
  'Netherlands',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Poland',
  'Portugal',
  'United Kingdom',
  'Central African Republic',
  'Czechia',
  'Kosovo',
  'Macedonia',
  'Republic of the Congo',
  'Democratic Republic of the Congo',
  'Dominican Republic',
  'Rwanda',
  'Romania',
  'Russia',
  'Samoa',
  'Saint Kitts and Nevis',
  'San Marino',
  'Saint Vincent and the Grenadines',
  'Saint Lucia',
  'Sao Tome and Principe',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Syria',
  'Somalia',
  'Sri Lanka',
  'Swaziland',
  'South Africa',
  'Sudan',
  'South Sudan',
  'Sweden',
  'Switzerland',
  'Suriname',
  'Thailand',
  'Taiwan',
  'Tanzania',
  'Tajikistan',
  'East Timor',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkmenistan',
  'Turkey',
  'Tuvalu',
  'Ukraine',
  'Uganda',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Djibouti',
  'Zambia',
  'Zimbabwe',
]
