import ButtonIcon from '@/components/form/buttonIcon'

import CloseIcon from '@/components/icons/close'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'PrivatePolicy',

  components: {
    ButtonIcon,

    CloseIcon
  },

  methods: {
    close: function() {
      EventBus.$emit('hidePrivatePolicy');
    }
  },

  mounted() {
    window.scrollTo(0,0);
  }
}
