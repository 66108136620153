module.exports = [
  {x: 0.0000, y: 59.0000},
  {x: 0.2013, y: 61.3727},
  {x: 0.4145, y: 61.4733},
  {x: 1.0000, y: 63.0000},
  {x: 7.8084, y: 65.9869},
  {x: 14.2902, y: 69.5441},
  {x: 22.0000, y: 72.0000},
  {x: 23.6665, y: 72.0000},
  {x: 25.3335, y: 72.0000},
  {x: 27.0000, y: 72.0000},
  {x: 27.0000, y: 72.3333},
  {x: 27.0000, y: 72.6667},
  {x: 27.0000, y: 73.0000},
  {x: 29.6664, y: 73.0000},
  {x: 32.3336, y: 73.0000},
  {x: 35.0000, y: 73.0000},
  {x: 35.0000, y: 73.3333},
  {x: 35.0000, y: 73.6667},
  {x: 35.0000, y: 74.0000},
  {x: 36.9998, y: 74.0000},
  {x: 39.0002, y: 74.0000},
  {x: 41.0000, y: 74.0000},
  {x: 41.0000, y: 74.3333},
  {x: 41.0000, y: 74.6667},
  {x: 41.0000, y: 75.0000},
  {x: 41.9999, y: 75.0000},
  {x: 43.0001, y: 75.0000},
  {x: 44.0000, y: 75.0000},
  {x: 44.0000, y: 75.3333},
  {x: 44.0000, y: 75.6667},
  {x: 44.0000, y: 76.0000},
  {x: 44.9999, y: 76.0000},
  {x: 46.0001, y: 76.0000},
  {x: 47.0000, y: 76.0000},
  {x: 47.3333, y: 76.6666},
  {x: 47.6667, y: 77.3334},
  {x: 48.0000, y: 78.0000},
  {x: 48.6666, y: 78.0000},
  {x: 49.3334, y: 78.0000},
  {x: 50.0000, y: 78.0000},
  {x: 50.9999, y: 79.3332},
  {x: 52.0001, y: 80.6668},
  {x: 53.0000, y: 82.0000},
  {x: 53.6666, y: 82.3333},
  {x: 54.3334, y: 82.6667},
  {x: 55.0000, y: 83.0000},
  {x: 55.0000, y: 83.6666},
  {x: 55.0000, y: 84.3334},
  {x: 55.0000, y: 85.0000},
  {x: 56.3332, y: 85.9999},
  {x: 57.6668, y: 87.0001},
  {x: 59.0000, y: 88.0000},
  {x: 59.0000, y: 88.6666},
  {x: 59.0000, y: 89.3334},
  {x: 59.0000, y: 90.0000},
  {x: 59.6666, y: 90.3333},
  {x: 60.3334, y: 90.6667},
  {x: 61.0000, y: 91.0000},
  {x: 61.3333, y: 91.9999},
  {x: 61.6667, y: 93.0001},
  {x: 62.0000, y: 94.0000},
  {x: 62.3333, y: 94.0000},
  {x: 62.6667, y: 94.0000},
  {x: 63.0000, y: 94.0000},
  {x: 63.3333, y: 95.3332},
  {x: 63.6667, y: 96.6668},
  {x: 64.0000, y: 98.0000},
  {x: 64.6666, y: 98.3333},
  {x: 65.3334, y: 98.6667},
  {x: 66.0000, y: 99.0000},
  {x: 66.3333, y: 100.3332},
  {x: 66.6667, y: 101.6668},
  {x: 67.0000, y: 103.0000},
  {x: 67.6666, y: 103.3333},
  {x: 68.3334, y: 103.6667},
  {x: 69.0000, y: 104.0000},
  {x: 69.3333, y: 105.3332},
  {x: 69.6667, y: 106.6668},
  {x: 70.0000, y: 108.0000},
  {x: 70.6666, y: 108.3333},
  {x: 71.3334, y: 108.6667},
  {x: 72.0000, y: 109.0000},
  {x: 72.3333, y: 109.9999},
  {x: 72.6667, y: 111.0001},
  {x: 73.0000, y: 112.0000},
  {x: 75.0098, y: 111.4264},
  {x: 73.8652, y: 112.1200},
  {x: 75.0000, y: 111.0000},
  {x: 75.3333, y: 111.0000},
  {x: 75.6667, y: 111.0000},
  {x: 76.0000, y: 111.0000},
  {x: 74.3335, y: 97.3347},
  {x: 72.6665, y: 83.6653},
  {x: 71.0000, y: 70.0000},
  {x: 70.6667, y: 70.0000},
  {x: 70.3333, y: 70.0000},
  {x: 70.0000, y: 70.0000},
  {x: 70.0000, y: 69.0001},
  {x: 70.0000, y: 67.9999},
  {x: 70.0000, y: 67.0000},
  {x: 69.6667, y: 67.0000},
  {x: 69.3333, y: 67.0000},
  {x: 69.0000, y: 67.0000},
  {x: 69.0000, y: 62.6671},
  {x: 69.0000, y: 58.3329},
  {x: 69.0000, y: 54.0000},
  {x: 68.6667, y: 54.0000},
  {x: 68.3333, y: 54.0000},
  {x: 68.0000, y: 54.0000},
  {x: 68.0000, y: 53.0001},
  {x: 68.0000, y: 51.9999},
  {x: 68.0000, y: 51.0000},
  {x: 67.6667, y: 51.0000},
  {x: 67.3333, y: 51.0000},
  {x: 67.0000, y: 51.0000},
  {x: 67.0000, y: 49.6668},
  {x: 67.0000, y: 48.3332},
  {x: 67.0000, y: 47.0000},
  {x: 66.6667, y: 47.0000},
  {x: 66.3333, y: 47.0000},
  {x: 66.0000, y: 47.0000},
  {x: 66.0000, y: 45.0002},
  {x: 66.0000, y: 42.9998},
  {x: 66.0000, y: 41.0000},
  {x: 65.6667, y: 41.0000},
  {x: 65.3333, y: 41.0000},
  {x: 65.0000, y: 41.0000},
  {x: 65.0000, y: 38.6669},
  {x: 65.0000, y: 36.3331},
  {x: 65.0000, y: 34.0000},
  {x: 64.6667, y: 34.0000},
  {x: 64.3333, y: 34.0000},
  {x: 64.0000, y: 34.0000},
  {x: 64.0000, y: 32.0002},
  {x: 64.0000, y: 29.9998},
  {x: 64.0000, y: 28.0000},
  {x: 63.6667, y: 28.0000},
  {x: 63.3333, y: 28.0000},
  {x: 63.0000, y: 28.0000},
  {x: 63.0000, y: 26.6668},
  {x: 63.0000, y: 25.3332},
  {x: 63.0000, y: 24.0000},
  {x: 62.6667, y: 24.0000},
  {x: 62.3333, y: 24.0000},
  {x: 62.0000, y: 24.0000},
  {x: 62.0000, y: 23.3334},
  {x: 62.0000, y: 22.6666},
  {x: 62.0000, y: 22.0000},
  {x: 61.6667, y: 22.0000},
  {x: 61.3333, y: 22.0000},
  {x: 61.0000, y: 22.0000},
  {x: 61.0000, y: 20.6668},
  {x: 61.0000, y: 19.3332},
  {x: 61.0000, y: 18.0000},
  {x: 60.6667, y: 18.0000},
  {x: 60.3333, y: 18.0000},
  {x: 60.0000, y: 18.0000},
  {x: 57.4875, y: 11.5640},
  {x: 55.9467, y: 3.7809},
  {x: 49.0000, y: 2.0000},
  {x: 49.0000, y: 1.6667},
  {x: 49.0000, y: 1.3333},
  {x: 49.0000, y: 1.0000},
  {x: 48.6667, y: 1.0000},
  {x: 48.3333, y: 1.0000},
  {x: 48.0000, y: 1.0000},
  {x: 48.0000, y: 1.3333},
  {x: 48.0000, y: 1.6667},
  {x: 48.0000, y: 2.0000},
  {x: 44.9777, y: 3.8128},
  {x: 42.7282, y: 6.9561},
  {x: 41.0000, y: 10.0000},
  {x: 41.0000, y: 10.6666},
  {x: 41.0000, y: 11.3334},
  {x: 41.0000, y: 12.0000},
  {x: 40.3334, y: 12.3333},
  {x: 39.6666, y: 12.6667},
  {x: 39.0000, y: 13.0000},
  {x: 39.0000, y: 13.6666},
  {x: 39.0000, y: 14.3334},
  {x: 39.0000, y: 15.0000},
  {x: 38.6667, y: 15.0000},
  {x: 38.3333, y: 15.0000},
  {x: 38.0000, y: 15.0000},
  {x: 37.6667, y: 16.3332},
  {x: 37.3333, y: 17.6668},
  {x: 37.0000, y: 19.0000},
  {x: 36.3334, y: 19.3333},
  {x: 35.6666, y: 19.6667},
  {x: 35.0000, y: 20.0000},
  {x: 34.3334, y: 21.9998},
  {x: 33.6666, y: 24.0002},
  {x: 33.0000, y: 26.0000},
  {x: 32.3334, y: 26.3333},
  {x: 31.6666, y: 26.6667},
  {x: 31.0000, y: 27.0000},
  {x: 30.6667, y: 28.3332},
  {x: 30.3333, y: 29.6668},
  {x: 30.0000, y: 31.0000},
  {x: 29.3334, y: 31.3333},
  {x: 28.6666, y: 31.6667},
  {x: 28.0000, y: 32.0000},
  {x: 28.0000, y: 32.6666},
  {x: 28.0000, y: 33.3334},
  {x: 28.0000, y: 34.0000},
  {x: 27.0001, y: 34.6666},
  {x: 25.9999, y: 35.3334},
  {x: 25.0000, y: 36.0000},
  {x: 25.0000, y: 36.6666},
  {x: 25.0000, y: 37.3334},
  {x: 25.0000, y: 38.0000},
  {x: 23.3335, y: 39.3332},
  {x: 21.6665, y: 40.6668},
  {x: 20.0000, y: 42.0000},
  {x: 20.0000, y: 42.6666},
  {x: 20.0000, y: 43.3334},
  {x: 20.0000, y: 44.0000},
  {x: 17.6669, y: 45.9998},
  {x: 15.3331, y: 48.0002},
  {x: 13.0000, y: 50.0000},
  {x: 12.3334, y: 50.9999},
  {x: 11.6666, y: 52.0001},
  {x: 11.0000, y: 53.0000},
  {x: 10.3334, y: 53.0000},
  {x: 9.6666, y: 53.0000},
  {x: 9.0000, y: 53.0000},
  {x: 8.6667, y: 53.6666},
  {x: 8.3333, y: 54.3334},
  {x: 8.0000, y: 55.0000},
  {x: 5.9431, y: 56.8727},
  {x: 3.1998, y: 58.3224},
  {x: 0.0000, y: 59.0000}
];
